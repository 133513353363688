import React from 'react';
import { EuiPage, EuiPageBody } from '@elastic/eui';
import { LoginCard } from '../../components/auth/loginCard/loginCard';
import { Redirect } from 'react-router-dom';

const LoginPage = () => {
    // redirect to home if already logged in
    let authenticationData = localStorage.getItem('authToken');
    if (authenticationData) {
        return <Redirect to={{ pathname: '/' }} />;
    }

    return (
        <EuiPage style={{ height: '100vh' }}>
            <EuiPageBody>
                <LoginCard />
            </EuiPageBody>
        </EuiPage>
    );
};
export default LoginPage;
