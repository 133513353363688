import {
    EuiCollapsibleNav,
    EuiCollapsibleNavGroup,
    EuiFlexItem,
    EuiHeaderSectionItemButton,
    EuiIcon,
    EuiListGroupItem,
    EuiShowFor,
} from '@elastic/eui';
import React, { useState } from 'react';
import { LeftSideBar } from '../leftSideBar';

const CollapsibleNavbar = () => {
    const [navIsOpen, setNavIsOpen] = useState(JSON.parse(String(localStorage.getItem('navIsDocked'))) || false);
    const [navIsDocked, setNavIsDocked] = useState(JSON.parse(String(localStorage.getItem('navIsDocked'))) || false);

    return (
        <>
            <EuiCollapsibleNav
                id='guideHeaderCollapsibleNavExample'
                aria-label='Main navigation'
                isOpen={navIsOpen}
                isDocked={navIsDocked}
                button={
                    <EuiHeaderSectionItemButton
                        aria-label='Toggle main navigation'
                        onClick={() => setNavIsOpen(!navIsOpen)}
                    >
                        <EuiIcon type={'menu'} size='m' aria-hidden='true' />
                    </EuiHeaderSectionItemButton>
                }
                onClose={() => setNavIsOpen(false)}
            >
                <EuiFlexItem className='eui-yScroll'>
                    {/* Docs callout */}
                    <EuiCollapsibleNavGroup className='scrollbar-sm' background='none' title=''>
                        <LeftSideBar />
                    </EuiCollapsibleNavGroup>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    {/* Docking button only for larger screens that can support it*/}
                    <EuiShowFor sizes={['l', 'xl']}>
                        <EuiCollapsibleNavGroup>
                            <EuiListGroupItem
                                size='xs'
                                color='subdued'
                                label={`${navIsDocked ? 'Undock' : 'Dock'} navigation`}
                                onClick={() => {
                                    setNavIsDocked(!navIsDocked);
                                    localStorage.setItem('navIsDocked', JSON.stringify(!navIsDocked));
                                }}
                                iconType={navIsDocked ? 'lock' : 'lockOpen'}
                            />
                        </EuiCollapsibleNavGroup>
                    </EuiShowFor>
                </EuiFlexItem>
            </EuiCollapsibleNav>
        </>
    );
};

export default CollapsibleNavbar;
