import { EuiLink } from '@elastic/eui';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import ItemLinkFlyout from '../../../../components/ItemLinkFlyout/ItemLinkFlyout';
import { linkUnlinkOffers } from '../../../../components/offer/OfferLink/linkUnLinkOffers';
import { toastsErrorMessage, toastSuccessMessage } from '../../../../utils/toasts';

const LinkOfferAction = ({ offer, fetchOffers }) => {
    const dispatch = useDispatch();
    const outletList = useSelector((state) => state.outlet.outletList);
    const currentLanguage = useSelector((state) => state.language.languageId);

    const [isLoading, setIsLoading] = useState(false);
    const [offerLinkFlyout, setOfferLinkFlyout] = useState({
        data: [],
        display: false,
        selectedItem: null,
    });
    const [offerLinkStatus, setOfferLinkStatus] = useState({
        link: [],
        unlink: [],
    });

    const offerOutletLinkedCount = outletList?.filter((outlet) =>
        offerLinkFlyout.selectedItem?.linked_outlets?.some(
            (offerOutletId) => offerOutletId === outlet.outlet_data.outlet_id
        )
    )?.length;

    useEffect(() => {
        if (outletList?.length) {
            setOfferLinkFlyout((prevState) => ({
                ...prevState,
                data: outletList,
            }));
        }
    }, [outletList]);

    const toggleFlyout = (offer) => {
        setOfferLinkFlyout((prevState) => ({
            ...prevState,
            display: !prevState.display,
            selectedItem: offer,
        }));
    };

    const initSelectedItems = useCallback((outlet, setIsSelected, selectedOffer) => {
        if (selectedOffer?.linked_outlets?.length) {
            for (const outletId of selectedOffer.linked_outlets) {
                if (outletId === outlet.outlet_data.outlet_id) {
                    setIsSelected(true);
                }
            }
        }
    }, []);

    const handleOfferUnLink = useCallback(
        async (unlinkIds, selectedOffer) => {
            await linkUnlinkOffers(selectedOffer.id, { outlet_ids: [...unlinkIds] }, 'un-link')
                .then((response) => {
                    setIsLoading(false);
                    if (response.success) {
                        setOfferLinkStatus({
                            link: [],
                            unlink: [],
                        });
                        fetchOffers();
                        toastSuccessMessage('Offer Status Changed Successfully', dispatch);
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    toastsErrorMessage('Oops, there was an error', dispatch);
                });
        },
        [dispatch, fetchOffers]
    );

    const handleOfferLinkChanges = async (selectedItem) => {
        setIsLoading(true);
        if (offerLinkStatus.link?.length) {
            handleOfferLink(offerLinkStatus.link, selectedItem);
        }
        if (offerLinkStatus.unlink?.length) {
            handleOfferUnLink(offerLinkStatus.unlink, selectedItem);
        }
    };

    const handleOfferLink = useCallback(
        async (linkIds, selectedOffer) => {
            await linkUnlinkOffers(selectedOffer.id, { outlet_ids: [...linkIds] }, 'link')
                .then((response) => {
                    setIsLoading(false);
                    if (response.success) {
                        setOfferLinkStatus({
                            link: [],
                            unlink: [],
                        });
                        fetchOffers();
                        toastSuccessMessage('Offer Status Changed Successfully', dispatch);
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    toastsErrorMessage('Oops, there was an error', dispatch);
                });
        },
        [dispatch, fetchOffers]
    );

    const handleOnChange = useCallback(
        (event, selectedDataInfo, setIsSelected, selectedItem) => {
            const isChecked = event.target.checked;
            setIsSelected(isChecked);

            if (isChecked) {
                const doesOutletExist = selectedItem?.linked_outlets?.filter(
                    (outlet_id) => outlet_id === selectedDataInfo?.outlet_data.outlet_id
                );

                if (!doesOutletExist?.length) {
                    setOfferLinkStatus((prevState) => ({
                        ...prevState,
                        link: [...prevState?.link, selectedDataInfo?.outlet_data.outlet_id],
                    }));
                }
            } else {
                const checkedOutletLink = offerLinkStatus.link.filter(
                    (outletId) => outletId !== selectedDataInfo?.outlet_data.outlet_id
                );
                setOfferLinkStatus((prevState) => ({
                    ...prevState,
                    link: [...checkedOutletLink],
                }));
            }

            // Prepare the array for deletion
            if (isChecked) {
                const matchedModes = offerLinkStatus.unlink?.filter((outletId) => {
                    return outletId !== selectedDataInfo?.outlet_data.outlet_id;
                });
                setOfferLinkStatus((prevState) => ({
                    ...prevState,
                    unlink: [...matchedModes],
                }));
            } else {
                const matchedModes = selectedItem?.linked_outlets?.filter((outlet_id) => {
                    return outlet_id === selectedDataInfo?.outlet_data.outlet_id;
                })?.[0];

                if (matchedModes) {
                    setOfferLinkStatus((prevState) => ({
                        ...prevState,
                        unlink: [...prevState.unlink, matchedModes],
                    }));
                } else {
                    setOfferLinkStatus((prevState) => ({
                        ...prevState,
                        unlink: [...prevState.unlink],
                    }));
                }
            }
        },
        [offerLinkStatus.link, offerLinkStatus.unlink]
    );

    return (
        <>
            <ItemLinkFlyout
                isFlyoutOpen={offerLinkFlyout.display}
                data={offerLinkFlyout.data}
                toggleFlyout={toggleFlyout}
                selectedItem={offerLinkFlyout.selectedItem}
                labelStringLvl1={'inputDisplay'}
                appModule='/offers'
                accessLvl='write'
                isLoading={isLoading}
                initSelectedItems={initSelectedItems}
                setOfferLinkStatus={setOfferLinkStatus}
                handleFlyoutSubmitButton={handleOfferLinkChanges}
                statDescription='Linked Outlets'
                statTitle={offerOutletLinkedCount}
                hasStats={true}
                handleOnChange={handleOnChange}
                offerLinkStatus={offerLinkStatus}
                flyoutHeader={`Link ${offerLinkFlyout?.selectedItem?.translations?.title?.[currentLanguage]}`}
            />
            <EuiLink size='s' onClick={() => toggleFlyout(offer)}>
                Link
            </EuiLink>
        </>
    );
};

export default LinkOfferAction;
