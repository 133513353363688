import { EuiButtonIcon, EuiHorizontalRule, EuiText, htmlIdGenerator } from '@elastic/eui';
import React from 'react';

export const RiderRating = ({ data, order }) => {
    return (
        <div key={htmlIdGenerator()()} style={{ paddingLeft: '3px' }}>
            <EuiText size='xs'>
                <h4>Rider Feedback</h4>
            </EuiText>
            {(data.isPast || data.isCustomerOrder) && order.feedback !== null ? (
                <>
                    {[1, 2, 3, 4, 5].map((star) => (
                        <EuiButtonIcon
                            key={star}
                            iconType={order.feedback.delivery_star >= star ? 'starFilled' : 'starEmpty'}
                            color='warning'
                            iconSize='l'
                        />
                    ))}
                    {order.feedback.delivery_comment !== null && (
                        <EuiText size='xs'>{order.feedback.delivery_comment}</EuiText>
                    )}
                </>
            ) : (
                <EuiText>
                    <EuiText size='xs'>Rating not given!</EuiText>
                </EuiText>
            )}
            {(data.isPast || data.isCustomerOrder) && <EuiHorizontalRule margin='s' />}
        </div>
    );
};
