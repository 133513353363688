import { EuiText } from '@elastic/eui';
import React from 'react';

const Tooltip = ({ content }) => {
    return (
        <EuiText
            style={{
                position: 'absolute',
                top: 0,
                left: '50%',
                transform: `translateX(-50%) translateY(-100%)`,
                textAlign: 'center',
                padding: 6,
                background: '#000',
                opacity: 0.7,
                color: '#fff',
                width: '100%',
                borderRadius: '4px',
            }}
            size='s'
        >
            {content}
        </EuiText>
    );
};

export default Tooltip;
