import { EuiLoadingContent, EuiPanel } from '@elastic/eui';
import React, { useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReportFilters from './ReportFilters';
import moment from 'moment';
import { getReportsData } from '../../api/reports/getReportsData';
import ReportsDataGrid from './ReportsDataGrid';
import { ALL_OUTLET } from '../../reduxStore/types/outlet';

//GET OUTLET STRING
export const outletIdArray = (outletArr) => {
    let outletString = '';
    for (let i = 0; i < outletArr.length; i++) {
        if (outletArr.length - 1 === i) {
            outletString += `outlet_ids[${i}]=${outletArr[i].value}`;
        } else {
            outletString += `outlet_ids[${i}]=${outletArr[i].value}&`;
        }
    }

    return outletString;
};

const Reports = () => {
    // ** Pagination config
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const onChangeItemsPerPage = useCallback(
        (pageSize) => setPagination((pagination) => ({ ...pagination, pageSize, pageIndex: 0 })),
        [setPagination]
    );
    const onChangePage = useCallback(
        (pageIndex) => setPagination((pagination) => ({ ...pagination, pageIndex })),
        [setPagination]
    );
    const [loading, setLoading] = useState(true);
    const [reportsData, setReportsData] = useState([]);
    const outletList = useSelector((state) => state.outlet.outletList);
    const selectedOutlet = useSelector((state) => state.outlet.selectedOutlet);
    const [column, setColumn] = useState([]);
    const [stringOfOutlet, setStringOfOutlet] = useState('');
    const dispatch = useDispatch();
    // ** Sorting config
    const [sortingColumns, setSortingColumns] = useState([]);
    const onSort = useCallback(
        (sortingColumns) => {
            setSortingColumns(sortingColumns);
        },
        [setSortingColumns]
    );

    //FILTER OPTION FOR REPORTS
    const [filters, setFilters] = useState([]);
    const options = filters?.map((filter) => {
        return { value: filter, inputDisplay: filter };
    });

    const [selectedFilterType, setSelectedFilterType] = useState(options ? options[0]?.value : null);

    //FILTER OF REPORTS ACCORDING TO DATE
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment());
    let [columnId, setColumnId] = useState([]);

    //Pagination
    const [appState, setAppState] = useState({
        loading: true,
        resportsData: null,
        refetch: false,
        errorState: false,
    });

    //API CALLS FOR REPORTS DATA

    useEffect(() => {
        setLoading(true);
        let outletString = '';
        if (selectedOutlet) {
            if (selectedOutlet.value === ALL_OUTLET) {
                outletString = outletIdArray(outletList);
            } else {
                outletString = outletIdArray([selectedOutlet]);
            }
        }
        if (outletString !== '') {
            setReportsData([]);
            dispatch(
                getReportsData(
                    outletString,
                    startDate,
                    endDate,
                    'date',
                    'asc',
                    selectedFilterType !== undefined ? selectedFilterType : null,
                    pagination
                )
            )
                .then((res) => {
                    if (res?.summary?.data?.length) {
                        setReportsData(res.summary.data);
                        setColumnId(Object.keys(res.summary.data[0]));
                    } else {
                        setColumn([]);
                    }
                    setAppState((preState) => ({
                        ...preState,
                        resportsData: res,
                        loading: false,
                    }));
                    if (selectedFilterType === undefined) {
                        setSelectedFilterType(res?.summary_sections[0]);
                    }
                    setFilters(res?.summary_sections ? res.summary_sections : filters);
                    setLoading(false);
                })
                .catch((error) => {
                    //TODO: handle error state
                    console.log('error in reports', error);
                });
        }
        setStringOfOutlet(outletString);
    }, [selectedOutlet, selectedFilterType, startDate, endDate, pagination]);

    const handleColumnName = useCallback((query) => {
        if (query === 'column-1' || query === 'column-2' || query === 'column-3') {
            return ' ';
        } else {
            return query.charAt(0).toUpperCase() + query.replaceAll('_', ' ').slice(1);
        }
    }, []);

    useEffect(() => {
        let createColumn = [];
        if (columnId) {
            columnId?.map((id) =>
                createColumn.push({
                    id: id,
                    displayAsText: handleColumnName(id),
                })
            );
        }
        if (createColumn?.length) {
            setColumn(createColumn);
        }
    }, [columnId, handleColumnName]);

    return (
        <>
            <EuiPanel>
                <ReportFilters
                    options={options}
                    selectedFilter={selectedFilterType}
                    setSelectedFilter={setSelectedFilterType}
                    endDate={endDate}
                    startDate={startDate}
                    setEndDate={setEndDate}
                    setStartDate={setStartDate}
                    outletString={stringOfOutlet}
                    reportsData={reportsData}
                    column={column}
                />
                {loading ? (
                    <EuiLoadingContent lines={7} />
                ) : (
                    <ReportsDataGrid
                        column={column}
                        sortingColumns={sortingColumns}
                        onSort={onSort}
                        pagination={pagination}
                        onChangeItemsPerPage={onChangeItemsPerPage}
                        onChangePage={onChangePage}
                        reportsData={reportsData}
                        setPagination={setPagination}
                        appState={appState}
                        setAppState={setAppState}
                    />
                )}
            </EuiPanel>
        </>
    );
};

export default React.memo(Reports);
