import React from 'react';
import { MainLayout } from '../../components/layout/mainLayout/mainLayout';
import DeviceSettings from '../../components/deviceSettings/deviceSettings';

const DeviceSettingsPage = () => {
    return (
        <MainLayout title='Device Settings'>
            <DeviceSettings />
        </MainLayout>
    );
};

export default DeviceSettingsPage;
