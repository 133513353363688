import React, { useCallback, useEffect, useState } from 'react';
import { EuiText, EuiButtonEmpty, EuiDraggable, EuiIcon, EuiAccordion, EuiSpacer, EuiPanel } from '@elastic/eui';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { userHasPermission } from '../../../helper/auth';
import { fetchCategoryItem, fetchOutletCategoryItem } from './Api/FetchCatalogueInfo';
import { SET_ACTIVE_CATEGORY_ID, SET_CATALOGUE_ITEM_LIST } from '../Store/MenuTypes';

const CategoryRow = (props) => {
    const { category, isOutletMenu, index } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const permissions = useSelector((state) => state.permission.permission);
    const languageId = useSelector((state) => state.language.languageId);
    const categories = useSelector((state) => state.menuReducer.restaurantCategories);
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);
    const [isOpen, setIsOpen] = useState(false);

    const [listDetails, setListDetails] = useState({
        items: [],
        childCategories: categories.filter((caetgoryItem) => caetgoryItem.parent_id === category.category_id),
        isLoading: false,
    });

    const handleCategoryClick = useCallback(() => {
        if (!listDetails.childCategories.length) {
            if (!isOutletMenu) {
                setListDetails((prevState) => ({ ...prevState, isLoading: true }));
                fetchCategoryItem(category.category_id, languageId).then((response) => {
                    if (response.success) {
                        dispatch({
                            type: SET_CATALOGUE_ITEM_LIST,
                            payload: {
                                [`item_${category.category_id}`]: response.items,
                            },
                        });
                        setListDetails((prevState) => ({ ...prevState, isLoading: false }));
                    }
                });
            } else {
                fetchOutletCategoryItem(category.category_id, languageId, selectedOutletId).then((response) => {
                    setListDetails((prevState) => ({ ...prevState, isLoading: true }));
                    if (response.success) {
                        setListDetails((prevState) => ({ ...prevState, items: [...response.items], isLoading: false }));
                    }
                });
            }
        }

        dispatch({
            type: SET_ACTIVE_CATEGORY_ID,
            payload: category.category_id,
        });
    }, [
        listDetails.childCategories.length,
        dispatch,
        category.category_id,
        isOutletMenu,
        languageId,
        selectedOutletId,
    ]);

    const button = useCallback(
        (provided) => {
            return (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                    id={category.internal_name}
                >
                    <div style={{ display: 'flex', flexDirection: 'row', width: '80%' }}>
                        {userHasPermission(permissions, history.location.pathname, 'write') && (
                            <div
                                style={{
                                    justifyContent: 'center',
                                    display: 'flex',
                                    width: '12%',
                                    alignItems: 'center',
                                }}
                            >
                                <div {...provided.dragHandleProps}>
                                    <EuiIcon type='grab' />
                                </div>
                            </div>
                        )}

                        <div style={{ minWidth: '500px', marginLeft: '12px' }}>
                            <EuiText size='m' style={{ fontWeight: '500' }}>
                                {isOutletMenu ? category.translations.title[languageId] : category.internal_name}
                            </EuiText>
                        </div>
                    </div>
                </div>
            );
        },
        [category.internal_name, category.translations.title, history, isOutletMenu, languageId, permissions]
    );

    const editCategory = useCallback(
        async (id) => {
            // history.push(`/category?category=edit?&id=${id}`);
            history.push(`/category/edit/${id}`);
        },
        [history]
    );

    const action = useCallback(() => {
        return (
            <>
                {/* {userHasPermission(permissions, history.location.pathname, 'write') && !isOutletMenu && ( */}
                <text
                    style={{
                        position: 'absolute',
                        right: '1%',
                        top: '21px',
                    }}
                >
                    {category.category_id}
                </text>
                <EuiButtonEmpty
                    style={{
                        position: 'absolute',
                        right: '5%',
                        top: '8px',
                    }}
                    onClick={() => editCategory(category.category_id)}
                >
                    Edit
                </EuiButtonEmpty>
                {/* )} */}
            </>
        );
    }, [category.category_id, editCategory, history.location.pathname, isOutletMenu, permissions]);

    return (
        <>
            <EuiDraggable
                spacing='m'
                key={category.category_id.toString()}
                index={index}
                draggableId={category.category_id.toString()}
                customDragHandle={true}
                style={{
                    position: 'relative',
                }}
            >
                {(provided) => (
                    <EuiPanel style={{ paddingTop: 4, paddingBottom: 4 }}>
                        <EuiAccordion
                            id={category.category_id.toString()}
                            onToggle={(isOpenRow) => {
                                // setIsOpen(isOpenRow);
                                if (isOpenRow && !listDetails.items.length) {
                                    handleCategoryClick();
                                }
                            }}
                            buttonContent={button(provided)}
                            extraAction={action()}
                            style={{ padding: '12px 0' }}
                            // initialIsOpen={isOpen}
                            forceState={isOpen ? 'open' : undefined}
                        >
                            <EuiSpacer size='s' />
                        </EuiAccordion>
                    </EuiPanel>
                )}
            </EuiDraggable>
        </>
    );
};

export default React.memo(CategoryRow);
