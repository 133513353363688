import { EuiCheckbox, EuiFlexGroup, EuiFlexItem, EuiText, htmlIdGenerator } from '@elastic/eui';
import React, { useState, useCallback, useEffect } from 'react';

const AvailabilityExpose = ({
    orderingModeState,
    setOrderingModeState,
    orderingMode,
    modalResponse,
    state,
    isDisabled,
}) => {
    const isChecked = modalResponse?.data?.outlet_ordering_modes?.filter((defaultMode) => {
        if (defaultMode.stock && defaultMode.restaurant_ordering_mode_id === orderingMode.restaurant_ordering_mode_id) {
            return true;
        }
        return false;
    });

    const [checked, setChecked] = useState(isChecked?.length ? true : false);

    useEffect(() => {
        const isChecked = state?.modalResponse?.data?.outlet_ordering_modes?.filter((defaultMode) => {
            if (
                defaultMode.stock &&
                defaultMode.restaurant_ordering_mode_id === orderingMode.restaurant_ordering_mode_id
            ) {
                return true;
            }
            return false;
        });

        if (isChecked?.length) {
            if (modalResponse.isActive) {
                setOrderingModeState((prevState) => ({
                    ...prevState,
                    available_ordering_mode: [
                        ...prevState.available_ordering_mode,
                        isChecked[0].restaurant_ordering_mode_id,
                    ],
                    default_available_ordering_mode: [
                        ...prevState.available_ordering_mode,
                        isChecked[0].restaurant_ordering_mode_id,
                    ],
                }));
            } else {
                setOrderingModeState((prevState) => ({
                    ...prevState,
                    unavailable_ordering_mode: [
                        ...prevState.unavailable_ordering_mode,
                        isChecked[0].restaurant_ordering_mode_id,
                    ],
                    default_unavailable_ordering_mode: [
                        ...prevState.unavailable_ordering_mode,
                        isChecked[0].restaurant_ordering_mode_id,
                    ],
                }));
            }
        }
    }, []);

    const handleUpdateOrderingModes = useCallback(
        (event) => {
            const isOrderingModeChecked = event.target.checked;
            const selectedModeId = orderingMode.restaurant_ordering_mode_id;
            setChecked(event.target.checked);

            if (isOrderingModeChecked && modalResponse.isActive) {
                setOrderingModeState((prevState) => ({
                    ...prevState,
                    available_ordering_mode: [...prevState.available_ordering_mode, selectedModeId],
                }));
            } else if (!isOrderingModeChecked && modalResponse.isActive) {
                if (orderingModeState.unavailable_ordering_mode?.length) {
                    const availableCheckedModes = orderingModeState.unavailable_ordering_mode.filter((mode) => {
                        return mode === selectedModeId;
                    });
                    const removedModes = orderingModeState.unavailable_ordering_mode.filter((mode) => {
                        return mode !== selectedModeId;
                    });
                    setOrderingModeState((prevState) => ({
                        ...prevState,
                        available_ordering_mode: [...removedModes],
                        unavailable_ordering_mode: [...prevState.unavailable_ordering_mode, ...availableCheckedModes],
                    }));
                } else {
                    const restCheckedModes = orderingModeState.available_ordering_mode.filter((mode) => {
                        return mode !== selectedModeId;
                    });

                    setOrderingModeState((prevState) => ({
                        ...prevState,
                        available_ordering_mode: restCheckedModes,
                    }));
                }
            }

            if (isOrderingModeChecked && !modalResponse.isActive) {
                setOrderingModeState((prevState) => ({
                    ...prevState,
                    unavailable_ordering_mode: [...prevState.unavailable_ordering_mode, selectedModeId],
                }));
            } else if (!isOrderingModeChecked && !modalResponse.isActive) {
                if (orderingModeState.available_ordering_mode?.length) {
                    const availableCheckedModes = orderingModeState.available_ordering_mode.filter((mode) => {
                        return mode === selectedModeId;
                    });
                    const removedModes = orderingModeState.available_ordering_mode.filter((mode) => {
                        return mode !== selectedModeId;
                    });
                    setOrderingModeState((prevState) => ({
                        ...prevState,
                        available_ordering_mode: [...removedModes],
                        unavailable_ordering_mode: [...prevState.unavailable_ordering_mode, ...availableCheckedModes],
                    }));
                } else {
                    const restCheckedModes = orderingModeState.unavailable_ordering_mode.filter((mode) => {
                        return mode !== selectedModeId;
                    });

                    setOrderingModeState((prevState) => ({
                        ...prevState,
                        unavailable_ordering_mode: restCheckedModes,
                    }));
                }
            }
        },
        [modalResponse, orderingMode, orderingModeState, setOrderingModeState]
    );

    return (
        <EuiFlexItem>
            <EuiFlexGroup direction='row' justifyContent='flexStart' alignItems='center'>
                <EuiFlexItem grow={false}>
                    <EuiCheckbox
                        onChange={handleUpdateOrderingModes}
                        id={htmlIdGenerator()()}
                        checked={Boolean(checked)}
                        disabled={isDisabled}
                        compressed
                    />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiText style={{ textTransform: 'capitalize' }}>{orderingMode.display_name}</EuiText>
                </EuiFlexItem>
            </EuiFlexGroup>
        </EuiFlexItem>
    );
};

export default React.memo(AvailabilityExpose);
