import React, { memo, useEffect, useState } from 'react';
import API from '../../../api/axios/API';
import { CSVDownload } from 'react-csv';
import { isEmpty } from 'lodash';

const CustomerCsvDownload = (props) => {
    const { searchText, getAllCustomer } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState({});
    const [customerArray, setCustomerArray] = useState([]);

    const getCustomerData = async () => {
        setIsLoading(true);
        await API.get(
            `restaurants/:restaurantId/customers?search_query=${searchText}&page=1&per_page=${getAllCustomer.customers.total}`
        )
            .then((res) => {
                setData(res);
            })
            .catch((e) => setError(true));
    };

    useEffect(() => {
        getCustomerData();
    }, []);

    useEffect(() => {
        if (!isEmpty(data)) {
            const cs = data.customers.data.map((customer) => {
                return customer;
            });
            setCustomerArray(...customerArray, cs);
        }
    }, [data]);

    useEffect(() => {
        if (customerArray.length > 0) {
            setIsLoading(false);
        }
    }, [customerArray]);

    if (customerArray.length === 0 || isLoading) return null;
    if (error) {
        alert('Error in download..');
    }

    return (
        <>
            <div>
                <CSVDownload data={customerArray} target='_blank' />; {props.csvStateCallback(false)}
            </div>
        </>
    );
};

export default memo(CustomerCsvDownload);
