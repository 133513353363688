import {
    EuiDragDropContext,
    euiDragDropReorder,
    EuiDraggable,
    EuiDroppable,
    EuiEmptyPrompt,
    EuiFlexGroup,
    EuiFlexItem,
    EuiIcon,
    EuiPanel,
    EuiSpacer,
    EuiText,
} from '@elastic/eui';
import React, { useCallback, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { useDispatch, useSelector } from 'react-redux';
import { outletItemListSequenceChange } from '../../../../api/menu/outletItemListSequenceChange';
import { userHasPermission } from '../../../../helper/auth';

const CategoryItemColumn = ({ categoryInternaleName, selectedCategoryItems, setSelectedCategoriesItems }) => {
    const permissions = useSelector((state) => state.permission.permission);
    const outletId = useSelector((state) => state.outlet.selectedOutletId);

    const dispatch = useDispatch();

    const [loaderOverlay, setLoaderOverlay] = useState(false);

    const onDragEndItems = useCallback(
        ({ source, destination }) => {
            if (source && destination) {
                setLoaderOverlay(true);
                const items = euiDragDropReorder(selectedCategoryItems, source.index, destination.index);

                dispatch(outletItemListSequenceChange(outletId, items))
                    .then((res) => {
                        if (res.success) {
                            setLoaderOverlay(false);
                            setSelectedCategoriesItems(items);
                        } else {
                            setLoaderOverlay(false);
                            alert('Error in sequence change');
                        }
                    })
                    .catch(() => setLoaderOverlay(false));
            }
        },
        [dispatch, outletId, selectedCategoryItems, setSelectedCategoriesItems]
    );

    return (
        <>
            <EuiText>
                <b>{`${categoryInternaleName ? categoryInternaleName : ''} catalogue items`}</b>
            </EuiText>
            <EuiDragDropContext onDragEnd={onDragEndItems}>
                <EuiSpacer size='s' />
                <EuiDroppable
                    className='outleLinkStyle scrollbar-sm'
                    droppableId='CUSTOM_HANDLE_DROPPABLE_AREA2'
                    spacing='m'
                    withPanel
                >
                    <LoadingOverlay active={loaderOverlay} text='Re-arranging items'>
                        {selectedCategoryItems?.length ? (
                            selectedCategoryItems.map((item, idx) => (
                                <EuiDraggable
                                    spacing='m'
                                    key={item?.item_id?.toString() + idx.toString()}
                                    index={idx}
                                    draggableId={item?.item_id?.toString()}
                                    customDragHandle={true}
                                >
                                    {(provided) => (
                                        <EuiPanel className='custom' paddingSize='m'>
                                            <EuiFlexGroup alignItems='center'>
                                                <EuiFlexItem grow={false}>
                                                    {userHasPermission(permissions, '/menu', 'write') && (
                                                        <div {...provided.dragHandleProps}>
                                                            <EuiIcon type='grab' />
                                                        </div>
                                                    )}
                                                </EuiFlexItem>
                                                <EuiFlexItem>
                                                    <EuiText> {item.internal_name} </EuiText>
                                                </EuiFlexItem>
                                            </EuiFlexGroup>
                                        </EuiPanel>
                                    )}
                                </EuiDraggable>
                            ))
                        ) : (
                            <EuiEmptyPrompt
                                iconType='editorStrike'
                                title={<h2>No Records</h2>}
                                body={<p>You don't have any records in selected category.</p>}
                            />
                        )}
                    </LoadingOverlay>
                </EuiDroppable>
            </EuiDragDropContext>
        </>
    );
};

export default CategoryItemColumn;
