import {
    EuiDragDropContext,
    euiDragDropReorder,
    EuiDraggable,
    EuiDroppable,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow,
    EuiIcon,
    EuiPanel,
    EuiRadioGroup,
    EuiText,
} from '@elastic/eui';
import { isEmpty } from 'lodash';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import NumberField from '../../Form/NumberField';

const AutoAssignmentSelection = ({ list, setList }) => {
    const {
        watch,
        formState: { errors },
        setValue,
        control,
    } = useFormContext();
    const defaultData = JSON.parse(localStorage.getItem('auto_assignment'));
    const selectedAssignmentType = defaultData?.assignment_selection;

    const assignment_selection = [
        { id: 'priority', label: 'Priority' },
        { id: 'preference', label: 'Preference' },
    ];

    const onDragEnd = ({ source, destination }) => {
        setValue('', '', { shouldDirty: true });
        if (source && destination) {
            const items = euiDragDropReorder(list, source.index, destination.index);
            const updatedItems = items.map((items, idx) => {
                items.preference_rank = idx + 1;
                return items;
            });
            setList(updatedItems);
        }
    };

    return (
        <div style={{ maxWidth: '40vw' }}>
            <EuiFlexGroup direction='column' style={{ marginTop: '35px' }}>
                <EuiFlexItem style={{ fontWeight: '700', fontSize: '20px' }}>
                    <p>Auto Assignment Rider</p>
                </EuiFlexItem>
                <EuiFlexItem>
                    <p style={{ color: '#6a717d' }}>
                        Choose from Priority or Preference for rider assignment. Priority will assign an order to all
                        agency and cancel will continue with the one that accepts the order first and cancel the others
                        whereas Preference selection will assign order in sequence to the preference list.
                    </p>
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiFormRow
                isInvalid={errors.assignment_selection}
                error={errors.assignment_selection ? errors['assignment_selection'].message : ''}
                style={{ marginTop: '12px' }}
            >
                <Controller
                    render={({ field }) => (
                        <EuiRadioGroup
                            options={assignment_selection}
                            idSelected={watch('assignment_selection') ? watch('assignment_selection') : 'priority'}
                            onChange={(id) => {
                                setValue(
                                    'assignment_selection',
                                    id === 'priority' ? 'priority' : id === 'preference' ? 'preference' : 'priority',
                                    { shouldDirty: true }
                                );
                            }}
                            legend={{
                                children: (
                                    <span>Would you like to assign riders preference base or priority base ? </span>
                                ),
                            }}
                        />
                    )}
                    name='assignment_selection'
                    control={control}
                    defaultValue={selectedAssignmentType === 'preference' ? 'preference' : 'priority'}
                    fullWidth={true}
                    rules={{ required: 'Please Select priority or prefrence' }}
                />
            </EuiFormRow>

            {watch('assignment_selection') === 'preference' ? (
                <EuiDragDropContext onDragEnd={onDragEnd}>
                    <EuiDroppable
                        style={{ marginTop: '24px', maxWidth: '40vw' }}
                        droppableId='CUSTOM_HANDLE_DROPPABLE_AREA'
                        spacing='m'
                        withPanel
                    >
                        {list.map((item, idx) => {
                            return (
                                <EuiDraggable
                                    spacing='m'
                                    key={item?.id}
                                    index={idx}
                                    draggableId={item?.id?.toString()}
                                    customDragHandle={true}
                                    style={{ marginTop: '8px' }}
                                >
                                    {(provided) => (
                                        <EuiPanel className='custom' paddingSize='m'>
                                            <EuiFlexGroup
                                                justifyContent='spaceBetween'
                                                style={{ width: '100%' }}
                                                alignItems='start'
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        direction: 'row',
                                                        alignItems: 'center',
                                                        flex: 1,
                                                        marginLeft: '8px',
                                                    }}
                                                    grow={1}
                                                >
                                                    <div {...provided.dragHandleProps}>
                                                        <EuiIcon type='grab' />
                                                    </div>
                                                    <EuiFlexItem style={{ marginLeft: '8px' }}>
                                                        {item?.service_name === 'in_house_rider' ? (
                                                            <EuiText>In House Rider</EuiText>
                                                        ) : item?.service_name === 'wefast' ? (
                                                            <EuiText>Wefast</EuiText>
                                                        ) : item?.service_name === 'dunzo' ? (
                                                            <EuiText>Dunzo</EuiText>
                                                        ) : item?.service_name === 'shadowfax' ? (
                                                            <EuiText>Shadowfax</EuiText>
                                                        ) : item?.service_name === 'elt' ? (
                                                            <EuiText>Elt</EuiText>
                                                        ) : item?.service_name === 'porter' ? (
                                                            <EuiText>Porter</EuiText>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </EuiFlexItem>
                                                </div>
                                                <EuiFlexItem>
                                                    <NumberField
                                                        label={'Waiting time'}
                                                        errors={errors}
                                                        name={`${item?.service_name}`}
                                                        rules={{
                                                            required: `Please Enter Intervention Time For ${item?.service_name}`,
                                                            min: 3,
                                                        }}
                                                        control={control}
                                                        className='input-remove-hyphen'
                                                        prepend='Min'
                                                        min={3}
                                                        defaultValue={item?.waiting_time}
                                                    />
                                                </EuiFlexItem>
                                            </EuiFlexGroup>
                                            <EuiFlexItem>
                                                {!isEmpty(errors) && errors[item?.service_name]?.type === 'min' && (
                                                    <EuiText size='s' style={{ marginTop: '6px', color: 'red' }}>
                                                        Intervention Time can't be below than 3 minutes
                                                    </EuiText>
                                                )}
                                            </EuiFlexItem>
                                        </EuiPanel>
                                    )}
                                </EuiDraggable>
                            );
                        })}
                    </EuiDroppable>
                </EuiDragDropContext>
            ) : null}
        </div>
    );
};

export default AutoAssignmentSelection;
