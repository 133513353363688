import React from 'react';
import { EuiComboBox, EuiFormRow } from '@elastic/eui';

export const SuperSelection = ({ options, valueOfSelected, onChange, isLoading, placeholder, increaseWidth }) => {
    return (
        <>
            <EuiFormRow label=''>
                <div style={{ width: '500px' }}>
                    <EuiComboBox
                        fullWidth={increaseWidth ?? false}
                        aria-label={placeholder}
                        placeholder={placeholder}
                        options={options}
                        selectedOptions={valueOfSelected === undefined || !valueOfSelected ? [] : valueOfSelected}
                        onChange={onChange}
                        singleSelection
                        isLoading={isLoading}
                        isClearable
                    />
                </div>
            </EuiFormRow>
        </>
    );
};
