import * as typesAuth from '../reduxStore/types/auth';
import API from './axios/API';

export const deviceTokenRegister = (deviceId, token) => {
    return async (dispatch) => {
        await API.put(`/devices`, {
            device_id: deviceId,
            platform: 'web_app',
            token: token,
        });
        dispatch({
            type: typesAuth.SET_FIREBASE_TOKEN,
            payload: {
                firebaseToken: token,
            },
        });
    };
};
