import React, { useCallback } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiTextColor, EuiButtonIcon, EuiText } from '@elastic/eui';
import { isEmpty } from 'lodash';
import { useFormContext, useWatch } from 'react-hook-form';

const ItemFormErrors = ({ setShowError, showError, errorMessage }) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();
    const selectedOrderingModes = useWatch({
        control,
        name: 'ordering_modes',
    });

    const displayErrorServer = useCallback(() => {
        let errorsList = [];
        if (!isEmpty(errorMessage)) {
            for (var key in errorMessage) {
                errorsList.push(<EuiTextColor color='danger'>{errorMessage[key]}</EuiTextColor>);
            }
        }
        return errorsList;
    }, [errorMessage]);

    const findOrderingModeError = useCallback(
        (key) => {
            const orderingModeErrors = [];
            for (let index = 0; index < errors[key].length; index++) {
                const element = errors[key][index];
                if (element?.price?.message) {
                    orderingModeErrors.push(element?.price?.message);
                }
            }

            return orderingModeErrors;
        },
        [errors]
    );

    const displayError = useCallback(
        (errors) => {
            let innerArray = [];
            if (!isEmpty(errors)) {
                for (var key in errors) {
                    innerArray.push(
                        <EuiText>
                            <ul>
                                {errors[key]?.message ? (
                                    <li>{errors[key]?.message}</li>
                                ) : findOrderingModeError(key)?.length ? (
                                    findOrderingModeError(key).map((error, idx) => (
                                        <li key={idx.toString()}>{error}</li>
                                    ))
                                ) : null}
                            </ul>
                        </EuiText>
                    );
                }
            }
            return innerArray;
        },
        [findOrderingModeError]
    );

    return (
        <>
            {!isEmpty(errorMessage) && showError && (
                <EuiFlexGroup
                    direction='column'
                    style={{
                        backgroundColor: '#f8e9e9',
                        padding: '15px',
                        margin: '10px',
                    }}
                >
                    <EuiFlexItem
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <EuiTextColor color='danger' style={{ paddingBottom: '10px' }}>
                            <b>Please resolve below validations</b>
                        </EuiTextColor>
                        <EuiButtonIcon
                            color={'warning'}
                            onClick={() => setShowError(false)}
                            iconType='cross'
                            aria-label='cross'
                        />
                    </EuiFlexItem>
                    {displayErrorServer()}
                </EuiFlexGroup>
            )}

            {!selectedOrderingModes?.length ? (
                <EuiTextColor color='danger' style={{ paddingBottom: '10px' }}>
                    <b>Please Select at least one ordering mode</b>
                </EuiTextColor>
            ) : null}

            {!isEmpty(errors) && showError && (
                <EuiFlexGroup
                    direction='column'
                    style={{
                        backgroundColor: '#f8e9e9',
                        padding: '15px',
                        margin: '10px',
                    }}
                >
                    <EuiFlexItem
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <EuiTextColor color='danger' style={{ paddingBottom: '10px' }}>
                            <b>Please resolve below validations</b>
                        </EuiTextColor>
                        <EuiButtonIcon
                            color={'warning'}
                            onClick={() => setShowError(false)}
                            iconType='cross'
                            aria-label='cross'
                        />
                    </EuiFlexItem>
                    {displayError(errors)}
                </EuiFlexGroup>
            )}
        </>
    );
};

export default React.memo(ItemFormErrors);
