import React, { memo, useState } from 'react';
import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui';
import MenuList from '../catalogue/menuList copy';
import { useSelector } from 'react-redux';
import './style.css';

const MenuEditorTab = () => {
    const isOutletMenu = useSelector((state) => state.menuReducer.isOutletMenu);

    const [scrollername, setScrollerName] = useState('');

    return (
        <>
            {/* grow={2} style={{ width: '95%', overflow: 'hidden' }} */}
            {/* Above styles were the issue behind overflowed scroll view */}
            <EuiFlexItem grow={2} style={{ width: '95%', overflow: 'hidden', paddingTop: scrollername ? '22px' : 0 }}>
                {/* Top Header on catelog page that has search component and addon group button with flyout. */}

                <EuiFlexItem style={!isOutletMenu ? { paddingTop: '20px' } : {}}>
                    <EuiFlexGroup className='display_style'>
                        <EuiFlexItem
                        // style={{ height: '100%', overflow: 'auto', paddingBottom: '6%' }}
                        // className='remove-scrollbar'
                        >
                            <MenuList />
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>
            </EuiFlexItem>
        </>
    );
};
export default memo(MenuEditorTab);
