import React, { useEffect, useState } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiButton, EuiLoadingContent } from '@elastic/eui';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { getLiveOrders } from '../../api/liveOrders/getLiveOrderNewAPI';
import { getLiveOrderDetailsAPI } from '../../api/liveOrders/getLiveOrderDetailsAPI';
import { getOrderEstimationTime } from '../../api/riderManagement/getOrderEstimationTime';
import { assignRider } from '../../api/riderManagement/assignRider';

export const AhoyRiderTab = (props) => {
    const { order, onOrderStatusChange } = props;
    const [priceEstimate, setPriceEstimate] = useState({});
    const dispatch = useDispatch();

    const tdStyle = {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    };

    const ahoyCostEstimate = async () => {
        dispatch(getOrderEstimationTime('ahoy', props.orderId)).then((ahoyResponse) => {
            if (ahoyResponse.success) {
                setPriceEstimate(ahoyResponse);
            } else {
                alert('Error in fetching price estimate');
            }
        });
    };

    useEffect(() => {
        ahoyCostEstimate();
    }, []);

    const assignAhoyRider = async () => {
        dispatch(assignRider('ahoy', props.orderId)).then((ahoyResponse) => {
            if (ahoyResponse.success) {
                props.setRiderModalOpen();
                dispatch(getLiveOrders());
                dispatch(getLiveOrderDetailsAPI());
                // onOrderStatusChange();
            } else {
                alert('Error in ashoy assign');
            }
        });
    };

    return (
        <EuiFlexGroup direction='column'>
            <EuiFlexItem>
                {!isEmpty(priceEstimate) ? (
                    <EuiFlexItem>
                        <b>Ahoy Estimates</b>
                        <table style={{ marginTop: '10px' }}>
                            <tr>
                                <td>Estimated price</td>
                                <td>:</td>
                                <td style={tdStyle}>
                                    {priceEstimate.pre_order_response.onDemand.price
                                        ? `${parseFloat(priceEstimate.pre_order_response.onDemand.price).toFixed(2)}`
                                        : ''}
                                </td>
                            </tr>
                        </table>
                    </EuiFlexItem>
                ) : (
                    <EuiLoadingContent lines={5} />
                )}
            </EuiFlexItem>
            <EuiFlexItem style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <EuiButton fullWidth={true} fill onClick={assignAhoyRider}>
                    Assign Ahoy Rider
                </EuiButton>
            </EuiFlexItem>
        </EuiFlexGroup>
    );
};
