import * as types from '../types/addon';

const initialState = {
    addonEditId: null,
    addOnMainflyout: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.SET_ADDON_EDIT_ID:
            return {
                ...state,
                addonEditId: action.payload.addonEditId,
            };
        case types.SET_ADDON_MAIN_FLYOUT:
            return {
                ...state,
                addOnMainflyout: action.payload.addOnMainflyout,
            };
        default:
            return state;
    }
};
