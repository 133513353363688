import React, { useCallback } from 'react';
import NumberField from '../../../components/Form/NumberField';
import { EuiText, EuiFlexItem, EuiForm, EuiToolTip, EuiSpacer } from '@elastic/eui';
import FormGroupDescription from '../../../components/formGroupDescription';
import SelectField from '../../../components/Form/SelectField';
import ButtonGroup from '../../../components/Form/ButtonGroup';
import { useDispatch, useSelector } from 'react-redux';
import { outletSettingsAPI } from '../../../api/outlet/outletSettingsAPI';
import useOutletFormValues from '../Hooks/useOutletFormValues';
import useFormActionsHandler from '../../../hooks/useFormActionsHandler';

const slub = [
    { value: '5', inputDisplay: '5%' },
    { value: '12', inputDisplay: '12%' },
    { value: '18', inputDisplay: '18%' },
    { value: '28', inputDisplay: '28%' },
];

const DeliveryCharges = () => {
    const dispatch = useDispatch();
    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);

    const updateFormValues = useCallback((data) => {
        let defaultValue = {};

        defaultValue.delivery_charge_threshold = data.settings.delivery_charge_threshold;
        defaultValue.delivery_charge_above_threshold = data.settings.delivery_charge_above_threshold;
        defaultValue.delivery_charge_below_threshold = data.settings.delivery_charge_below_threshold;
        defaultValue.delivery_charges_gst_slab = data.settings.delivery_charges_gst_slab
            ? data.settings.delivery_charges_gst_slab.toString()
            : slub[0].value;
        defaultValue.delivery_charge_toggle = data.settings.delivery_charges_gst_slab ? 1 : 0;
        defaultValue.packaging_charge = data.settings.packaging_charge;
        defaultValue.convinience_charge = data.settings.convenience_charge;
        defaultValue.extra_delivery_charge = data.settings.extra_delivery_charge;
        defaultValue.normal_delivery_radius = data.outlet_address.normal_delivery_radius;
        defaultValue.price_per_kilometer = defaultValue.normal_delivery_radius ? 1 : 0;

        return defaultValue;
    }, []);

    const { defaultValues, setDefaultValues, onSuccessCallback } = useOutletFormValues({ updateFormValues });

    const onFormSaveApi = useCallback(
        async (data) => {
            if (!data.price_per_kilometer) {
                data.extra_delivery_charge = 0;
                data.normal_delivery_radius = 0;
            }
            data.delivery_charges_gst_slab = data.delivery_charge_toggle == 1 ? data.delivery_charges_gst_slab : 0;

            const response = dispatch(outletSettingsAPI('delivery_charge', data));
            if (response.success) setDefaultValues(updateFormValues(response.outlet));
            return response;
        },
        [dispatch, updateFormValues]
    );

    const { control, errors, setValue, watch } = useFormActionsHandler({
        onFormSaveApi,
        defaultValues,
        onSuccessCallback,
    });

    return (
        <EuiForm component='form' style={{ marginTop: 20, minWidth: 320 }}>
            <FormGroupDescription
                title={restaurantDescriptions?.delivery_charges?.display_name}
                description={restaurantDescriptions?.delivery_charges?.description}
            >
                <NumberField
                    label='Delivery charge thresold'
                    errors={errors}
                    name='delivery_charge_threshold'
                    placeholder='Please enter delivery charge thresold'
                    rules={{
                        required: 'Please enter delivery charge thresold',
                    }}
                    step='any'
                    control={control}
                />
                <NumberField
                    label={
                        watch('delivery_charge_threshold') && watch('delivery_charge_threshold') !== '0'
                            ? `Delivery charges above ${watch('delivery_charge_threshold')} rupees`
                            : 'Delivery charges above thresold'
                    }
                    errors={errors}
                    name='delivery_charge_above_threshold'
                    placeholder='Please enter delivery above thresold'
                    rules={{
                        required: 'Please enter delivery above thresold',
                    }}
                    step='any'
                    control={control}
                />
                <NumberField
                    label={
                        watch('delivery_charge_threshold') && watch('delivery_charge_threshold') !== '0'
                            ? `Delivery charges below ${watch('delivery_charge_threshold')} rupees`
                            : 'Delivery charges below thresold'
                    }
                    errors={errors}
                    name='delivery_charge_below_threshold'
                    placeholder='Please enter delivery below thresold'
                    rules={{
                        required: 'Please enter delivery below thresold',
                    }}
                    step='any'
                    control={control}
                />
            </FormGroupDescription>

            <FormGroupDescription
                title={restaurantDescriptions?.distance_fee?.display_name}
                description={restaurantDescriptions?.distance_fee?.description}
            >
                <ButtonGroup
                    idSelected={watch('price_per_kilometer') ? '1' : '0'}
                    color='primary'
                    control={control}
                    setValue={setValue}
                    name='price_per_kilometer'
                    watch={watch}
                    label={'Addition charges per kilometer'}
                />

                {watch('price_per_kilometer') ? (
                    <EuiFlexItem>
                        <EuiSpacer />
                        <NumberField
                            label='Normal delivery radius'
                            errors={errors}
                            name='normal_delivery_radius'
                            placeholder='Please enter normal delivery radius'
                            rules={{
                                required: 'Please enter normal delivery radius',
                                validate: (value) => value > 0 || 'Please enter must be greater than 0',
                            }}
                            prepend={
                                <EuiToolTip content='content'>
                                    <EuiText size='s'>In km</EuiText>
                                </EuiToolTip>
                            }
                            step='any'
                            min={0}
                            control={control}
                        />
                        <NumberField
                            label='Extra Charge'
                            errors={errors}
                            name='extra_delivery_charge'
                            placeholder='Please enter extra charge'
                            step='any'
                            control={control}
                            helpText='Charge to customer per kilometer basis'
                        />
                    </EuiFlexItem>
                ) : (
                    ''
                )}
            </FormGroupDescription>

            <FormGroupDescription
                title={restaurantDescriptions?.collect_taxes_on_delivery_charge?.display_name}
                description={restaurantDescriptions?.collect_taxes_on_delivery_charge?.description}
            >
                <ButtonGroup
                    idSelected={watch('delivery_charge_toggle') ? '1' : '0'}
                    color='primary'
                    control={control}
                    setValue={setValue}
                    name='delivery_charge_toggle'
                    watch={watch}
                    label={'Delivery Charges GST slab'}
                />

                {watch('delivery_charge_toggle') ? (
                    <SelectField
                        errors={errors}
                        label='Delivery charge GST Slab'
                        name='delivery_charges_gst_slab'
                        options={slub}
                        valueOfSelected={
                            watch('delivery_charges_gst_slab') && watch('delivery_charges_gst_slab') !== '0'
                                ? watch('delivery_charges_gst_slab')
                                : slub[0].value
                        }
                        onChange={(value) => setValue('delivery_charges_gst_slab', value)}
                        placeholder='Please select gst slub'
                        rules={{
                            required: 'Please enter delivery charge gst slab',
                        }}
                        control={control}
                        defaultValue={
                            watch('delivery_charges_gst_slab') && watch('delivery_charges_gst_slab') !== '0'
                                ? watch('delivery_charges_gst_slab')
                                : slub[0].value
                        }
                    />
                ) : (
                    ''
                )}
            </FormGroupDescription>
        </EuiForm>
    );
};

export default DeliveryCharges;
