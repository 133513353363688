import React, { useEffect, useState } from 'react';
import { MainLayout } from '../../components/layout/mainLayout';
import { useDispatch } from 'react-redux';
import { fetchAllergens } from './Api/fetchAllergens';
import MenuScreen from './MenuScreen';
import { initializeRestaurant } from '../../reduxStore/actions/initilizationActionCreator';

function MenuCategories() {
    const [showOutlet, setShowOutlet] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            await dispatch(fetchAllergens());
        })();
    }, [dispatch]);

    const selectedRestaurantId = localStorage.getItem('selectedRestaurant');

    useEffect(() => {
        dispatch(initializeRestaurant(selectedRestaurantId));
    }, []);

    return (
        <MainLayout title='Menu(List of Categories)' outlet={showOutlet} isSingleScreen={true} backgroundColorChange>
            <MenuScreen setShowOutlet={setShowOutlet} />
        </MainLayout>
    );
}

export default MenuCategories;
