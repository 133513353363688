import Fuse from 'fuse.js';
import { getTaxAmount } from './utils';

export const autoSuggestionItems = (orderingMode, item, petPoojaTaxes, petPoojaItems = [], languageId) => {
    const fuse = new Fuse(petPoojaItems, {
        keys: ['itemname'],
        includeScore: true,
        includeMatches: true,
        isCaseSensitive: false,
    });

    const similarItems = [
        ...fuse.search(item.internal_name)?.filter(({ score }) => score <= 0.3),
        ...fuse.search(item.translations.title[languageId])?.filter(({ score }) => score <= 0.3),
    ];

    // ...fuse.search(item.internal_name)?.filter(({ score }) => score <= 0.2),
    // ...fuse.search(item.translations.title[languageId])?.filter(({ score }) => score <= 0.2),

    if (!similarItems?.length) {
        return {
            items: [],
        };
    }

    if (!orderingMode?.price && !orderingMode?.gst_slab && similarItems?.length) {
        return {
            items: similarItems,
        };
    }

    const priceSimilarity = similarItems.filter(({ item }) => Number(item.price) === Number(orderingMode.price));

    if (!priceSimilarity?.length && !item?.variants?.length) {
        return {
            items: [],
        };
    }

    const systemTaxAmount = (Number(orderingMode.price) * Number(orderingMode.gst_slab)) / 100;

    const taxItems = item?.variants?.length ? similarItems : priceSimilarity;

    const taxSimilarity = taxItems.filter(({ item }) => {
        const { taxArrayObj, taxAmount } = getTaxAmount(item, petPoojaTaxes, orderingMode.price);
        item.tax = taxArrayObj;
        if (Number(taxAmount) === Number(systemTaxAmount)) {
            return item;
        }
    });

    if (!taxSimilarity?.length && !item?.variants?.length) {
        return {
            items: [],
        };
    }

    const items = taxSimilarity?.reduce(
        (acc, curr) => (acc?.find((item) => curr.item.itemid === item.item.itemid) ? acc : [...acc, curr]),
        []
    );

    return {
        items,
    };
};

export const autoSuggestionVariants = (orderingMode, option, petPoojaTaxes, languageId, selectedItemForVariants) => {
    const fuse = new Fuse(selectedItemForVariants?.variation?.length ? selectedItemForVariants?.variation : [], {
        keys: ['name'],
        includeScore: true,
        includeMatches: true,
        isCaseSensitive: false,
    });

    const similarOptions = [...fuse.search(option.translations.title[languageId])?.filter(({ score }) => score <= 0.2)];
    if (!similarOptions?.length) {
        return {
            variants: [],
        };
    }

    if (!orderingMode?.price && !orderingMode?.gst_slab && similarOptions?.length) {
        return {
            items: similarOptions,
        };
    }

    const priceSimilarity = similarOptions.filter(({ item }) => Number(item.price) == Number(orderingMode.price));
    if (!priceSimilarity?.length) {
        return {
            variants: [],
        };
    }

    const systemTaxAmount = (Number(orderingMode.price) * Number(orderingMode.gst_slab)) / 100;
    const taxSimilarity = similarOptions.filter(({ item }) => {
        const { taxArrayObj, taxAmount } = getTaxAmount(selectedItemForVariants, petPoojaTaxes, orderingMode.price);
        item.tax = taxArrayObj;
        if (Number(taxAmount) === Number(systemTaxAmount)) {
            return item;
        }
    });

    if (!taxSimilarity?.length) {
        return {
            variants: [],
        };
    }

    const variants = similarOptions?.reduce(
        (acc, curr) => (acc.find((item) => curr.item.id === item.item.id) ? acc : [...acc, curr]),
        []
    );

    return {
        variants,
    };
};

export const autoSuggestionChoices = (orderingMode, choice, addOnChoices = []) => {
    const fuse = new Fuse(addOnChoices, {
        keys: ['label'],
        includeScore: true,
        includeMatches: true,
        isCaseSensitive: false,
    });

    const similarChoices = [...fuse.search(choice.label)?.filter(({ score }) => score <= 0.2)];
    if (!similarChoices?.length) {
        return {
            choices: [],
        };
    }

    // const priceSimilarity = similarChoices.filter(({ item }) =>
    //     item.orderingModes.some(
    //         (itemOrderingMode) =>
    //             Number(itemOrderingMode.price) === Number(orderingMode.price) &&
    //             itemOrderingMode.restaurant_ordering_mode_id === orderingMode.restaurant_ordering_mode_id
    //     )
    // );

    const priceSimilarity = similarChoices.filter(
        ({ item }) => item?.addonitem_price || item?.price == orderingMode.price
    );

    return { choices: priceSimilarity };
};

export const autoSuggestionAddonGroup = (groupOptions = [], selectedGroup) => {
    const fuse = new Fuse(groupOptions, {
        keys: ['label'],
        includeScore: true,
        includeMatches: true,
        isCaseSensitive: false,
    });

    const similarGroups = [...fuse.search(selectedGroup.label)?.filter(({ score }) => score <= 0.2)];

    if (!similarGroups?.length) {
        return {
            groups: [],
        };
    }

    const groups = similarGroups.filter(
        (similarGroup) => selectedGroup?.addOnChoices?.length >= similarGroup?.item?.addOnChoices?.length
    );

    if (!groups?.length) {
        return {
            groups: [],
        };
    }

    return { groups };
};
