import { EuiComboBox, EuiFormRow } from '@elastic/eui';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const ComboboxWithSearchFieldForAdvanced = ({
    options,
    rules,
    onChange,
    onSearchChange,
    isLoading,
    selectedOptions,
    required,
    placeholder,
    name,
    fullWidth = true,
    errroMessage,
    isInvalid,
    label,
    renderOption,
    isAllOptionRequiredFlagShow,
    singleSelection,
    isClearable,
    increaseWidth,
    onChangeField,
    allowMultipleSelection,
}) => {
    const { control } = useFormContext();
    return (
        <EuiFormRow label={label} isInvalid={isInvalid} error={errroMessage} fullWidth={fullWidth}>
            <Controller
                render={({ field }) => (
                    <div style={{ width: '500px' }}>
                        <EuiComboBox
                            fullWidth={increaseWidth ?? false}
                            className='custom-width'
                            async
                            isClearable={isClearable ?? true}
                            options={options}
                            selectedOptions={
                                isAllOptionRequiredFlagShow
                                    ? [options[field?.value[0]?.value == true ? 0 : 1]]
                                    : field.value
                            }
                            onChange={(selectedOptions) => {
                                if (allowMultipleSelection) {
                                    field.onChange(selectedOptions);
                                } else {
                                    field.onChange(selectedOptions.slice(-1));
                                }
                                if (onChangeField) {
                                    onChangeField(selectedOptions);
                                }
                            }}
                            placeholder={placeholder}
                            onSearchChange={onSearchChange}
                            isLoading={isLoading}
                            renderOption={renderOption}
                            singleSelection={!allowMultipleSelection}
                        />
                    </div>
                )}
                rules={{ ...rules }}
                required={required}
                name={name}
                control={control}
            />
        </EuiFormRow>
    );
};

export default React.memo(ComboboxWithSearchFieldForAdvanced);
