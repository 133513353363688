import { EuiFlexGroup, EuiFlexItem, EuiLoadingSpinner, EuiOverlayMask, EuiSideNav, EuiText } from '@elastic/eui';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { authModule } from '../../helper/authModulePermission';
import { NoOutletMessage } from '../../utils/noOutletMessage';
import InfraCredits from '../../modules/Credits/Tabs/InfraCredits';
import ServiceCredit from '../../modules/Credits/Tabs/ServiceCredit';

const LoadingOverlay = (
    <EuiOverlayMask>
        <EuiFlexGroup direction='column' alignItems='center'>
            <EuiLoadingSpinner size='l' />
            <EuiText>
                <h2> Loading ... </h2>
            </EuiText>
        </EuiFlexGroup>
    </EuiOverlayMask>
);

const Credits = (props) => {
    const themeMode = useSelector((state) => state.theme.theme);
    const outlet_id = useSelector((state) => state.outlet.selectedOutletId);
    const permissions = useSelector((state) => state.permission.permission);
    const [paymentLoader, setPaymentLoader] = useState(false);
    const [isSideNavOpenOnMobile, setisSideNavOpenOnMobile] = useState(false);

    const toggleOpenOnMobile = () => {
        setisSideNavOpenOnMobile(!isSideNavOpenOnMobile);
    };

    const selectedComponent = () => {
        switch (props.selectedTab) {
            case 'infrastructure':
                return (
                    authModule(permissions, 'read', 'infrastructure_credit') && (
                        <InfraCredits title='Infrastructure Credits' setPaymentLoader={setPaymentLoader} />
                    )
                );
            case 'service':
                return outlet_id ? (
                    <ServiceCredit title='Service Credits' setPaymentLoader={setPaymentLoader} />
                ) : (
                    <NoOutletMessage />
                );
            default:
                break;
        }
    };

    const onChangeTab = (id) => {
        props.setSelectedTab(id);
    };

    const sideNavTabs = [
        {
            name: '',
            id: 0,
            items: [
                authModule(permissions, 'read', 'infrastructure_credit') && {
                    id: 'infrastructure',
                    name: 'Infrastructure Credits',
                    onClick: () => onChangeTab('infrastructure'),
                    isSelected: props.selectedTab === 'infrastructure',
                },
                authModule(permissions, 'read', 'service_credit') && {
                    id: 'serive',
                    name: 'Service',
                    onClick: () => onChangeTab('service'),
                    isSelected: props.selectedTab === 'service',
                },
            ],
        },
    ];

    return (
        <EuiFlexGroup style={{ display: 'flex', flexDirection: 'row' }}>
            {paymentLoader && LoadingOverlay}
            <EuiFlexItem grow={2}>
                <EuiSideNav
                    aria-label='Basic example'
                    toggleOpenOnMobile={toggleOpenOnMobile}
                    isOpenOnMobile={isSideNavOpenOnMobile}
                    mobileTitle='Credits Menu'
                    style={{
                        backgroundColor: themeMode === 'light' ? '#fafbfd' : '#1a1b20',
                        padding: '0px 15px 15px',
                    }}
                    items={sideNavTabs}
                />
            </EuiFlexItem>
            <div style={{ backgroundColor: '#D3DAE6', width: '1.1px', margin: '0px 10px' }}></div>
            <EuiFlexItem grow={8}>{selectedComponent()}</EuiFlexItem>
        </EuiFlexGroup>
    );
};

export default Credits;
