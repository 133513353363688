import { SET_AVAILABILITY_CATALOGUE } from '../types/ItemAvailabilityTypes';

const initialState = {
    outlet_categories: null,
};

export default function itemAvailabilityReducer(state = initialState, action) {
    switch (action.type) {
        case SET_AVAILABILITY_CATALOGUE:
            return {
                ...state,
                outlet_categories: [...action.payload],
            };

        default:
            return state;
    }
}
