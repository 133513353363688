import { EuiFilterButton, EuiFilterGroup, EuiFilterSelectItem, EuiFormRow, EuiPopover } from '@elastic/eui';
import React, { useState, useMemo } from 'react';

const DashboardOutletSelection = ({ options, setOptions, setSelectedOptions }) => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const button = useMemo(() => {
        return (
            <EuiFilterButton
                iconType='arrowDown'
                onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                isSelected={isPopoverOpen}
                numFilters={options.length}
                hasActiveFilters={!!options.find((item) => item.checked === 'on')}
                numActiveFilters={options.filter((item) => item.checked === 'on').length}
            >
                {options.filter((item) => item.checked === 'on').length ? 'Selected outlets' : 'select an outlet'}
            </EuiFilterButton>
        );
    }, [isPopoverOpen, options]);

    const updateItem = (index) => {
        if (!options[index]) {
            return;
        }

        const newItems = [...options];

        switch (newItems[index].checked) {
            case 'on':
                newItems[index].checked = 'off';
                break;

            case 'off':
                newItems[index].checked = 'on';
                break;

            default:
                newItems[index].checked = 'on';
        }

        let selectedOptions = options.filter((item) => item.checked === 'on');

        setSelectedOptions(selectedOptions);

        setOptions(newItems);
    };

    return (
        <EuiFormRow label='Outlets' fullWidth={true}>
            <EuiFilterGroup>
                <EuiPopover
                    id='popoverExampleMultiSelect'
                    button={button}
                    isOpen={isPopoverOpen}
                    closePopover={() => setIsPopoverOpen(false)}
                    panelPaddingSize='none'
                >
                    <div className='euiFilterSelect__items'>
                        {options.map((item, index) => (
                            <EuiFilterSelectItem checked={item.checked} key={index} onClick={() => updateItem(index)}>
                                {item.label}
                            </EuiFilterSelectItem>
                        ))}
                    </div>
                </EuiPopover>
            </EuiFilterGroup>
        </EuiFormRow>
    );
};

export default DashboardOutletSelection;
