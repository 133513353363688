import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOutletDetails } from '../../../api/outlet/getOutletDetails';

const useOutletFormValues = ({ updateFormValues, customPayload = null, sideEffectForReset = null }) => {
    const dispatch = useDispatch();
    const outletId = useSelector((state) => state.outlet.selectedOutletId);
    const languageId = useSelector((state) => state.language.languageId);
    const outlet = useSelector((state) => state.outlet.selectedOutletDetails);
    const [defaultValues, setDefaultValues] = useState({});

    useEffect(() => {
        setDefaultValues(updateFormValues(customPayload ? customPayload : outlet));
    }, [outlet, customPayload, sideEffectForReset, outletId]);

    const onSuccessCallback = useCallback(async () => {
        await dispatch(getOutletDetails(outletId, languageId));
    }, [dispatch, outletId]);

    return { defaultValues, setDefaultValues, onSuccessCallback };
};

export default useOutletFormValues;
