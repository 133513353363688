import { EuiPanel, EuiSpacer, EuiTab, EuiTabs } from '@elastic/eui';
import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Banner from '../../components/banner/banner';
import { MainLayout } from '../../components/layout/mainLayout';
import { fetchOrderingModes } from '../../components/menuComponentsRefactorCopy/catalogue/Api/FetchCatalogueInfo';
import { SET_ORDERING_MODES } from '../../components/menuComponentsRefactorCopy/Store/MenuTypes';
import { SET_CURRENT_BANNERS_SCREEN } from '../../reduxStore/types/banner';

const BannersPage = () => {
    const dispatch = useDispatch();
    const [showOutlet, setShowOutlet] = useState(false);
    const currentTab = useSelector((state) => state.banner.bannerType);

    const handleTabSelection = useCallback(
        (screen) => {
            dispatch({
                type: SET_CURRENT_BANNERS_SCREEN,
                payload: screen,
            });
        },
        [dispatch]
    );

    useEffect(() => {
        fetchOrderingModes().then((response) => {
            dispatch({
                type: SET_ORDERING_MODES,
                payload: response.ordering_modes,
            });
        });
    }, [dispatch]);

    return (
        <>
            <MainLayout backgroundColorChange={true} title='Banners' outlet={showOutlet}>
                <EuiPanel style={{ minHeight: '60vh' }}>
                    <EuiTabs>
                        <EuiTab isSelected={currentTab === 'primary'} onClick={() => handleTabSelection('primary')}>
                            Primary Banners
                        </EuiTab>
                        <EuiTab isSelected={currentTab === 'secondary'} onClick={() => handleTabSelection('secondary')}>
                            Secondary Banners
                        </EuiTab>
                        <EuiTab isSelected={currentTab === 'tertiary'} onClick={() => handleTabSelection('tertiary')}>
                            Tertiary Banners
                        </EuiTab>
                    </EuiTabs>
                    <EuiSpacer size='s' />
                    <Banner setShowOutlet={setShowOutlet} />
                </EuiPanel>
            </MainLayout>
        </>
    );
};

export default BannersPage;
