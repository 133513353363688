import { EuiConfirmModal, EuiFlexGroup, EuiFlexItem, EuiPanel } from '@elastic/eui';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import API from '../../../api/axios/API';
import AddOnGroupList from './addOnGroupList';
import AddonLinkFlyout from './AddonLinkFlyout';
import * as types from '../../../reduxStore/types/addon';
import { useDispatch } from 'react-redux';
import { AddOnAddGroupFlyout } from './addOnAddGroupFlyout';
import { SET_SELECTED_ADDON_GROUP_CHOICES, SET_SELECTED_ADDON_GROUP_ID } from '../Store/MenuTypes';
import ChoiceList from './ChoiceList';

const AddonGroupChoiceList = ({ showAddNewAddonFlyout, setShowAddNewAddonFlyout }) => {
    const availableLanguages = useSelector((state) => state.language.availableLanguages);
    const addOnGroupSelected = useSelector((state) => state.menuReducer.addOnGroupSelectedId);
    const dispatch = useDispatch();
    const [apiFetchState, setApiFetchState] = useState(true);
    const [addOnList, setAddOnList] = useState([]);
    const [editAddonObject, setEditAddonObject] = useState({});
    const [selectedGroupIdForDeletion, setgroupIdForDeletion] = useState();
    const [showDeleteConfirmationModal, setShowDeleteConformationModal] = useState(false);
    const [addonLinkFlyout, setAddonLinkFlyout] = useState({
        addon: null,
        display: false,
    });

    const toggleFlyout = useCallback((addon) => {
        setAddonLinkFlyout((prevState) => ({
            display: !prevState.display,
            addon: addon ? addon : null,
        }));
    }, []);

    const editGroupId = useSelector((state) => state.addon.addonEditId);

    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [height, setheight] = useState(400);

    const fetchAddonRecords = useCallback(
        async (currentPage) => {
            let response = '';
            setIsLoading(true);
            try {
                response = await API.get(
                    `/restaurants/:restaurantId/restaurant-addon-groups?per_page=10&page=${currentPage}`
                );
            } catch (error) {
                response = error;
            }

            if (response.success) {
                setIsLoading(false);
                editGroupId
                    ? setAddOnList((prevAddOnList) => [...response?.restaurant_addon_groups?.data])
                    : setAddOnList((prevAddOnList) => [...prevAddOnList, ...response?.restaurant_addon_groups?.data]);
                setLastPage(response?.restaurant_addon_groups?.last_page);
                response.restaurant_addon_groups.data?.map((addOn, index) => {
                    if (addOn.addon_group_id === addOnGroupSelected && addOn?.choices?.length >= 0) {
                        dispatch({
                            type: SET_SELECTED_ADDON_GROUP_CHOICES,
                            payload: addOn.choices,
                        });
                    }
                });
            } else {
                alert('Error in Addon fetch');
            }
        },
        [addOnGroupSelected, dispatch]
    );

    useEffect(() => {
        if (currentPage > lastPage) {
            setCurrentPage(lastPage);
            return;
        }
        fetchAddonRecords(currentPage);
    }, [apiFetchState, currentPage]);

    const onChangeSelectedGroup = useCallback(
        (id) => {
            dispatch({
                type: SET_SELECTED_ADDON_GROUP_ID,
                payload: id,
            });

            addOnList.map((addOn) => {
                if (addOn.addon_group_id === id && addOn?.choices?.length >= 0) {
                    dispatch({
                        type: SET_SELECTED_ADDON_GROUP_CHOICES,
                        payload: addOn.choices,
                    });
                }

                return null;
            });
        },
        [addOnList, dispatch]
    );

    const onDeleteGroup = useCallback((id) => {
        setCurrentPage(1);
        setheight(400);
        setShowDeleteConformationModal(true);
        setgroupIdForDeletion(id);
    }, []);

    const onEditGroup = (id) => {
        let defaultValue = {};
        addOnList.map((addon) => {
            if (id === addon.addon_group_id) {
                availableLanguages.forEach((language, index) => {
                    defaultValue[`title${language.language_id}`] = addon.translations.group_name[language.language_id];
                    defaultValue[`description${language.language_id}`] =
                        addon.translations.description[language.language_id];
                });

                defaultValue['maximum'] = addon.max_addons;
                defaultValue['minimum'] = addon.min_addons;
                defaultValue['internal_name'] = addon.internal_name;
                defaultValue['show_agmark'] = addon.show_agmark;

                if (addon.choices !== undefined && addon.choices.length > 0) {
                    defaultValue['choices'] = [];
                    addon.choices.map((choice) => {
                        let value = {};
                        availableLanguages.map(
                            (language, i) =>
                                (value[`choice_title${language.language_id}`] =
                                    choice.translations.name[language.language_id])
                        );
                        value[`choice_id`] = choice.choice_id;
                        value[`price`] = choice.ordering_modes[0].price;
                        value[`compare_price`] = choice.ordering_modes[0].compare_price;
                        value[`gst_slab`] = choice.ordering_modes[0].gst_slab;
                        value[`food_type`] = choice.food_type;
                        value[`show_agmark`] = choice.show_agmark;
                        value[`image`] = choice.image_url;

                        // Handle Pirce and Expose Changes
                        if (choice?.ordering_modes?.length) {
                            const same_price = choice.ordering_modes[0].price;
                            const same_compare_price = choice.ordering_modes[0].compare_price;
                            const same_gst_slab = choice.ordering_modes[0].gst_slab;
                            for (const orderingMode of choice.ordering_modes) {
                                let isEnabled = [];
                                let isDisabled;
                                // Handle Prices

                                value[`price_${orderingMode.restaurant_ordering_mode_id}`] = orderingMode.price;
                                value[`gst_slab_${orderingMode.restaurant_ordering_mode_id}`] = orderingMode.gst_slab
                                    ? orderingMode.gst_slab
                                    : 0;

                                value[`compare_price_${orderingMode.restaurant_ordering_mode_id}`] =
                                    orderingMode.compare_price ? orderingMode.compare_price : 0;

                                // handle checked marked on Expose
                                value[`${orderingMode.restaurant_ordering_mode_id}`] = true;

                                // Handle "Select Different Price" checkbox state
                                if (
                                    same_price == orderingMode.price &&
                                    same_gst_slab == orderingMode.gst_slab &&
                                    same_compare_price == orderingMode.compare_price
                                ) {
                                    isEnabled.push('enabled');
                                } else {
                                    isEnabled.push('disabled');
                                }
                                isDisabled = isEnabled?.find((enabled) => enabled === 'disabled') === 'disabled';
                                value['is_enabled_same_price_details_for_all_ordering_modes'] = isDisabled
                                    ? true
                                    : false;
                            }
                        }

                        value['ordering_modes'] = choice.ordering_modes;
                        defaultValue['choices'].push(value);

                        return null;
                    });
                }
            }

            return null;
        });
        setEditAddonObject(defaultValue);

        dispatch({
            type: types.SET_ADDON_EDIT_ID,
            payload: {
                addonEditId: id,
            },
        });
        setShowAddNewAddonFlyout(true);
    };

    // Fly out contains info of selected addon group
    let flyout = useMemo(() => {
        return (
            <AddOnAddGroupFlyout
                setIsOpen={setShowAddNewAddonFlyout}
                apiFetch={fetchAddonRecords}
                defaultValue={editAddonObject}
                setEditAddonObject={setEditAddonObject}
            />
        );
    }, [setShowAddNewAddonFlyout, fetchAddonRecords, editAddonObject]);

    const onConfirmDeleteAddonGroup = useCallback(
        async (id) => {
            const response = await API.delete(
                `/restaurants/:restaurantId/restaurant-addon-groups/${selectedGroupIdForDeletion}`
            );
            setApiFetchState((prevState) => !prevState);
            setAddOnList([]);
            if (response.success && addOnGroupSelected === id) {
                dispatch({
                    type: SET_SELECTED_ADDON_GROUP_ID,
                    payload: null,
                });
                dispatch({
                    type: SET_SELECTED_ADDON_GROUP_CHOICES,
                    payload: [],
                });
            }
            setShowDeleteConformationModal(false);
        },
        [addOnGroupSelected, dispatch, selectedGroupIdForDeletion]
    );

    return (
        <>
            {showAddNewAddonFlyout && flyout}
            {addonLinkFlyout.display ? (
                <AddonLinkFlyout
                    addonLinkFlyout={addonLinkFlyout}
                    toggleFlyout={toggleFlyout}
                    fetchAddonRecords={fetchAddonRecords}
                />
            ) : null}

            {showDeleteConfirmationModal && (
                <EuiConfirmModal
                    style={{
                        position: 'fixed',
                        top: '35%',
                        left: '35%',
                        marginTop: '-50px',
                        marginLeft: '-50px',
                        zIndex: 10000,
                    }}
                    title='Are you sure you want to delete this group?'
                    onCancel={() => setShowDeleteConformationModal(false)}
                    onConfirm={onConfirmDeleteAddonGroup}
                    cancelButtonText="No, don't do it"
                    confirmButtonText='Yes, do it'
                    buttonColor='danger'
                    defaultFocusedButton='cancel'
                >
                    <p>You&rsquo;re about to delete this group</p>
                    <p>Are you sure you want to do this?</p>
                </EuiConfirmModal>
            )}

            <EuiFlexGroup alignItems='flexStart' justifyContent='spaceBetween'>
                <EuiFlexItem>
                    <EuiPanel className='scrollbar' paddingSize='s' style={{ maxHeight: '65vh', overflow: 'auto' }}>
                        <EuiFlexItem style={{ overflow: 'auto' }}>
                            <AddOnGroupList
                                toggleFlyout={toggleFlyout}
                                addOnList={addOnList}
                                selectedGroup={addOnGroupSelected}
                                onChangeSelectedGroup={onChangeSelectedGroup}
                                deleteGroup={onDeleteGroup}
                                editGroup={onEditGroup}
                                setCurrentPage={setCurrentPage}
                                lastPage={lastPage}
                                isLoading={isLoading}
                                currentPage={currentPage}
                                height={height}
                                setHeight={setheight}
                            />
                        </EuiFlexItem>
                    </EuiPanel>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiPanel paddingSize='s' className='scrollbar' style={{ maxHeight: '65vh', overflow: 'auto' }}>
                        <ChoiceList
                            setApiFetchState={setApiFetchState}
                            setAddOnList={setAddOnList}
                            setCurrentPage={setCurrentPage}
                            addOnList={addOnList}
                        />
                    </EuiPanel>
                </EuiFlexItem>
            </EuiFlexGroup>
        </>
    );
};

export default React.memo(AddonGroupChoiceList);
