import { isEmpty } from 'lodash';
export const formatedDataForKitchen = (timeSlotData) => {
    let formatedSscheduleMap = {};

    if (timeSlotData && !isEmpty(timeSlotData)) {
        for (let scheduleElement of timeSlotData) {
            if (formatedSscheduleMap.hasOwnProperty(scheduleElement.time_slots.day_of_week)) {
                formatedSscheduleMap[scheduleElement.time_slots.day_of_week].push({
                    start_time: scheduleElement.time_slots.start_time,
                    end_time: scheduleElement.time_slots.end_time,
                    preparation_time_in_rush: scheduleElement.time_slots.preparation_time_in_rush,
                });
            } else {
                formatedSscheduleMap[scheduleElement.time_slots.day_of_week] = [
                    {
                        start_time: scheduleElement.time_slots.start_time,
                        end_time: scheduleElement.time_slots.end_time,
                        preparation_time_in_rush: scheduleElement.time_slots.preparation_time_in_rush,
                    },
                ];
            }
        }
    }

    return formatedSscheduleMap;
};
