import React, { useState, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { EuiFlexGroup, EuiFlexItem, EuiPanel } from '@elastic/eui';
import Catalogue from './Tabs/Catalogue';
import { userHasPermission } from '../../helper/auth';
import { useHistory } from 'react-router-dom';
import CatalogueOutletLink from './Tabs/CatalogueOutletLink';
import { NoOutletMessage } from '../../utils/noOutletMessage';
import PetPoojaItemLink from './Tabs/PetPoojaItemLink';
import PetPoojaAddonLink from './Tabs/PetPoojaAddonLink';
import SideNavMenu from './Components/SideNavMenu';
import OutletCatalogue from './Components/OutletCatalogue/OutletCatalogue';
import useSingleScreenHeightCalc from '../../hooks/useSingleScreenHeightCalc';

const MenuScreen = ({ setShowOutlet }) => {
    const [selectedTab, setSelectedTab] = useState('catalogue');
    const outletId = useSelector((state) => state.outlet.selectedOutletId);
    const permissions = useSelector((state) => state.permission.permission);
    const history = useHistory();

    const { screenHeight } = useSingleScreenHeightCalc();

    const renderTab = useCallback(() => {
        switch (selectedTab) {
            case 'catalogue':
                return (
                    <EuiPanel style={{ height: screenHeight, position: 'relative' }}>
                        <Catalogue />
                    </EuiPanel>
                );
            case 'link_catalogue':
                return (
                    <EuiPanel className='scrollbar' style={{ position: 'relative' }}>
                        <CatalogueOutletLink />
                    </EuiPanel>
                );
            case 'outlet_menu':
                return (
                    <EuiPanel className='scrollbar' style={{ position: 'relative' }}>
                        <OutletCatalogue />
                    </EuiPanel>
                );
            case 'item_link':
                return (
                    <>
                        {outletId ? (
                            <EuiPanel style={{ position: 'relative' }} className='scrollbar'>
                                <PetPoojaItemLink />
                            </EuiPanel>
                        ) : (
                            <EuiPanel style={{ position: 'relative' }}>
                                <NoOutletMessage />
                            </EuiPanel>
                        )}
                    </>
                );
            case 'addon_link':
                return (
                    <>
                        {outletId ? (
                            <EuiPanel style={{ position: 'relative' }} className='scrollbar'>
                                <PetPoojaAddonLink />
                            </EuiPanel>
                        ) : (
                            <EuiPanel style={{ position: 'relative' }}>
                                <NoOutletMessage />
                            </EuiPanel>
                        )}
                    </>
                );
            default:
                return null;
        }
    }, [outletId, screenHeight, selectedTab]);

    const selectedComponent = useMemo(() => {
        return () => renderTab();
    }, [renderTab]);

    return (
        <>
            {!userHasPermission(permissions, history.location.pathname, 'write') &&
            !userHasPermission(permissions, history.location.pathname, 'delete') ? (
                <OutletCatalogue />
            ) : (
                <EuiFlexGroup
                    wrap
                    responsive
                    direction='row'
                    justifyContent='spaceBetween'
                    alignItems='flexStart'
                    gutterSize='none'
                >
                    <EuiFlexItem grow={false} style={{ width: '15%' }}>
                        <EuiPanel>
                            <SideNavMenu
                                selectedTab={selectedTab}
                                setSelectedTab={setSelectedTab}
                                setShowOutlet={setShowOutlet}
                            />
                        </EuiPanel>
                    </EuiFlexItem>

                    <EuiFlexItem
                        grow={false}
                        style={{ width: '83%', height: screenHeight }}
                        className='remove-scrollbar'
                    >
                        {selectedComponent()}
                    </EuiFlexItem>
                </EuiFlexGroup>
            )}
        </>
    );
};

export default MenuScreen;
