import React from 'react';
import {
    EuiButton,
    EuiModal,
    EuiModalBody,
    EuiModalFooter,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiFlexGroup,
    EuiFlexItem,
    EuiText,
} from '@elastic/eui';

export const OrderRatingUserInfoModal = (props) => {
    const closeModal = () => {
        props.closeUserInfoModal();
    };

    return (
        <>
            {props.userModalOpen && (
                <EuiModal
                    onClose={() => {
                        closeModal();
                    }}
                    style={{ zIndex: 11000 }}
                >
                    <EuiModalHeader>
                        <EuiModalHeaderTitle>Customer Information</EuiModalHeaderTitle>
                    </EuiModalHeader>

                    <EuiModalBody>
                        <EuiFlexGroup responsive={false}>
                            <EuiFlexItem grow={false}>
                                <EuiFlexGroup responsive={false} direction='column'>
                                    <EuiFlexItem grow={false}>
                                        <EuiText>
                                            <p>
                                                Name: <b>{props.userName}</b>
                                            </p>
                                        </EuiText>
                                    </EuiFlexItem>
                                    {props.inputAddess ? (
                                        <EuiFlexItem grow={false}>
                                            <EuiText>
                                                <p>
                                                    Address (user input): <b>{props.inputAddess}</b>
                                                </p>
                                            </EuiText>
                                        </EuiFlexItem>
                                    ) : null}
                                    {props.locationPin ? (
                                        <EuiFlexItem grow={false}>
                                            <EuiText>
                                                <p>
                                                    Location Pin: <b>{props.locationPin}</b>
                                                </p>
                                            </EuiText>
                                        </EuiFlexItem>
                                    ) : null}
                                    {props.phoneNumber ? (
                                        <EuiFlexItem grow={false}>
                                            <EuiText>
                                                <p>
                                                    Phone Number:{' '}
                                                    <b>
                                                        {props.countryCode}
                                                        {props.phoneNumber}
                                                    </b>
                                                </p>
                                            </EuiText>
                                        </EuiFlexItem>
                                    ) : null}
                                </EuiFlexGroup>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </EuiModalBody>
                    <EuiModalFooter>
                        <EuiButton onClick={() => closeModal()} fill>
                            Close
                        </EuiButton>
                    </EuiModalFooter>
                </EuiModal>
            )}
        </>
    );
};
