import * as types from '../types/rider';

const initialState = {
    riderList: [],
    specific_Rider_Data: null,
    edit_rider_id: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.SET_RIDER_LIST:
            return {
                ...state,
                riderList: action.payload.riderList,
            };
        case types.SET_SPECIFIC_RIDER_DATA:
            return {
                ...state,
                specific_Rider_Data: action.payload.specific_Rider_Data,
            };
        case types.SET_EDIT_RIDER_ID:
            return {
                ...state,
                edit_rider_id: action.payload.edit_rider_id,
            };
        default:
            return state;
    }
};
