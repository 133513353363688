import React, { useEffect, useState } from 'react';
import { MainLayout } from '../../components/layout/mainLayout/mainLayout';
import { EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiPanel } from '@elastic/eui';
import { useSelector } from 'react-redux';
import moment from 'moment';
import LiveOrdersTableForPast from '../../components/liveOrders/liveOrdersTableForPast';
import LiveOrderDetailsForPast from '../../components/liveOrders/liveOrderDetailsPastOrders';
import '../../components/liveOrders/style.css';
import CustomDatePicker from '../../components/UIComponent/CustomDatePicker';
import useSingleScreenHeightCalc from '../../hooks/useSingleScreenHeightCalc';
import DownloadCSV from './Components/DownloadCSV';
import { NoOutletMessage } from '../../utils/noOutletMessage';

const PastOrdersPage = () => {
    const { screenHeight } = useSingleScreenHeightCalc(320);

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);
    const pastOrders = useSelector((state) => state.orders.pastOrders);

    const [startDateTime, setStartDateTime] = useState(moment());
    const [endDateTime, setEndDateTime] = useState(moment());
    const [state, setState] = useState({
        apiDataLoaded: false,
        last_page: 0,
        current_page: 0,
        page_size: 10,
        loaderPage: false,
        totalCounts: 0,
    });

    return (
        <MainLayout
            allowAllOutletsSelection={true}
            title='Past orders'
            isSingleScreen={true}
            backgroundColorChange={true}
        >
            {!selectedOutletId ? (
                <NoOutletMessage />
            ) : (
                <>
                    <EuiFlexGroup justifyContent='spaceBetween' alignItems='center'>
                        <EuiFlexItem grow={false}>
                            <CustomDatePicker
                                startDate={startDateTime}
                                setStartDate={setStartDateTime}
                                endDate={endDateTime}
                                setEndDate={setEndDateTime}
                                displayFormat='DD-MM-YYYY'
                            />
                        </EuiFlexItem>

                        {pastOrders?.length ? (
                            <DownloadCSV
                                startDateTime={startDateTime}
                                endDateTime={endDateTime}
                                selectedOutletId={selectedOutletId}
                            />
                        ) : null}
                    </EuiFlexGroup>
                    <EuiSpacer />
                    <EuiFlexGroup>
                        <EuiFlexItem grow={6}>
                            <div>
                                <EuiPanel paddingSize='s' style={{ height: screenHeight }} className=' scrollbar-sm'>
                                    <LiveOrdersTableForPast
                                        startDateTime={startDateTime}
                                        endDateTime={endDateTime}
                                        state={state}
                                        setState={setState}
                                        isPast={true}
                                    />
                                </EuiPanel>
                            </div>
                        </EuiFlexItem>
                        <EuiFlexItem grow={4}>
                            <div>
                                <EuiPanel paddingSize='s' style={{ height: screenHeight }} className=' scrollbar-sm'>
                                    <LiveOrderDetailsForPast
                                        startDateTime={startDateTime}
                                        endDateTime={endDateTime}
                                        state={state}
                                        isPast={true}
                                    />
                                </EuiPanel>
                            </div>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </>
            )}
        </MainLayout>
    );
};

export default PastOrdersPage;
