import { EuiComboBox, EuiFormRow } from '@elastic/eui';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import './style.css';

export default function SearchableDropDown(props) {
    const { rules, name, control, defaultValue, errors, label, options } = props;
    const [selectedOptions, setSelectedOptions] = useState(defaultValue ?? []);
    const [searchResults, setSearchResults] = useState(options);

    const onChange = (selectedOptions) => {
        setSelectedOptions(selectedOptions);
        props.onChange(selectedOptions);
    };

    useEffect(() => {
        setSelectedOptions(defaultValue);
    }, [defaultValue]);

    const handleChangeValue = (fieldFunction, value) => {
        fieldFunction(value);
        onChange(value);
    };

    const handleSearch = (searchValue) => {
        if (searchValue) {
            const filteredOptions = options.filter((option) =>
                option.label.toLowerCase().includes(searchValue.toLowerCase())
            );
            setSearchResults(filteredOptions);
        } else {
            setSearchResults(searchResults);
        }
    };

    return (
        <>
            <Controller
                render={({ field }) => {
                    return (
                        <EuiFormRow
                            style={{ width: 700 }}
                            label={label}
                            isInvalid={errors[name] ? true : false}
                            error={errors[name] ? errors[name].message : ''}
                        >
                            <EuiComboBox
                                fullWidth={true}
                                placeholder='Select or create options'
                                options={searchResults}
                                selectedOptions={selectedOptions}
                                isClearable={true}
                                isMulti={true}
                                onChange={(value) => handleChangeValue(field.onChange, value)}
                                onSearchChange={handleSearch}
                            />
                        </EuiFormRow>
                    );
                }}
                rules={rules}
                name={name}
                control={control}
                fullWidth
                defaultValue={defaultValue ? defaultValue : []}
                required={errors[name]}
            />
        </>
    );
}
