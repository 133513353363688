// API.post(`restaurants/:restaurantId/banners`, data)
import API from '../axios/API';

export const editBanner = (bannerId, data) => {
    return async (dispatch, getState) => {
        let response;

        try {
            response = await API.put(`restaurants/:restaurantId/banners/${bannerId}`, data);
        } catch (error) {
            response = error;
        }

        return response;
    };
};
