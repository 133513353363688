import React, { useEffect, useRef } from 'react';
import AddonGroupLink from '../../../../components/PetPoojaLink/PetPoojaAddonLink/AddonGroupLink';

const AddonGroupLinkRow = ({ setRowHeight, index, petpoojaAddonGroups, addonGroups, style }) => {
    const rowRef = useRef({});

    // useEffect(() => {
    //     let isMounted = false;
    //     if (rowRef?.current) {
    //         console.log(`CURRENT rowRef.current`, rowRef.current);
    //         console.log(`rowRef.current.clientHeight`, rowRef.current.clientHeight);
    //         setRowHeight(index, rowRef.current.clientHeight);
    //         isMounted = true;
    //     }
    // }, [rowRef?.current?.clientHeight]);

    useEffect(() => {
        if (rowRef.current) {
            setRowHeight(index, rowRef.current.clientHeight);
        }
        // eslint-disable-next-line
    }, [rowRef?.current?.clientHeight]);

    // rowRef, rowRef?.current?.clientHeight, index

    return (
        <div style={style}>
            <AddonGroupLink
                rowRef={rowRef}
                groupOptions={petpoojaAddonGroups}
                key={addonGroups[index].group?.addonGroupId?.toString()}
                group={addonGroups[index]}
            />
        </div>
    );
};

export default React.memo(AddonGroupLinkRow);
