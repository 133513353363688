import React, { useState, useEffect, useCallback } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiText, EuiTabbedContent, EuiPanel, EuiSpacer } from '@elastic/eui';
import UserTablelist from './userTableList';
import UserAccessPermissions from './userAccessPermission';
import { EuiLoadingContent } from '@elastic/eui';
import UserOutletPermission from './userOutletPermission';
import API from '../../api/axios/API';
import './style.css';
import AddUser from './AddUser';

const selectedUserCallback = (value, setSelectedUser) => {
    setSelectedUser(value);
};

const permissionUpdateCallback = (value, setPermissionUpdate) => {
    setPermissionUpdate(value);
};

const accessPermissionCallback = (value, setAccessPermissionState) => {
    setAccessPermissionState(value);
};

export const UserComponent = (prpos) => {
    const [selectedUserId, setSelectedUser] = useState(1);
    const [accessPermissionState, setAccessPermissionState] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);
    const [useSideEffect, setUseSideEffect] = useState({});

    const setSelectedUserCallback = useCallback((value) => {
        selectedUserCallback(value, setSelectedUser);
    }, []);

    const setPermissionUpdateCallback = useCallback((value) => {
        permissionUpdateCallback(value, setPermissionUpdate);
    }, []);

    const setAccessPermissionStateCallback = useCallback((value) => {
        accessPermissionCallback(value, setAccessPermissionState);
    }, []);

    const [usersList, setUsersList] = useState({
        isLoading: true,
        error: null,
        users: undefined,
    });

    const [userPermissions, setUserPeermissions] = useState({
        isLoading: true,
        error: null,
        userPermissions: undefined,
    });

    const fetchUsersList = async () => {
        let response;
        try {
            response = await API.get(`restaurants/:restaurantId/users`);
        } catch (error) {
            response = error;
        }

        if (response.success) {
            response = {
                ...usersList,
                isLoading: false,
                users: response,
            };
        } else {
            response = {
                ...usersList,
                isLoading: false,
                error: true,
            };
        }
        setUsersList(response);
    };
    const [permissionUpdate, setPermissionUpdate] = useState(false);
    const fetchUserPermissions = async () => {
        let response;
        try {
            response = await API.get(`restaurants/:restaurantId/users/permissions`);
        } catch (error) {
            response = error;
        }

        if (response.success) {
            response = {
                ...usersList,
                isLoading: false,
                userPermissions: response,
            };
        } else {
            response = {
                ...usersList,
                isLoading: false,
                error: true,
            };
        }
        setUserPeermissions(response);
    };

    useEffect(() => {
        fetchUsersList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permissionUpdate]);

    useEffect(() => {
        fetchUserPermissions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let allPermission = [];
    if (usersList.isLoading || userPermissions.isLoading)
        return (
            <>
                <EuiPanel>
                    <EuiLoadingContent lines={7} />
                </EuiPanel>
                <EuiSpacer />
                <EuiPanel>
                    <EuiLoadingContent lines={4} />
                </EuiPanel>
            </>
        );
    if (usersList.error || userPermissions.error) return 'error...';
    let filteredUser = usersList.users;

    if (selectedUserId) {
        filteredUser = usersList.users.users.filter((user) => user.user.id === selectedUserId)[0]; // seleted user = 1
        if (!filteredUser) {
            filteredUser = usersList.users.users[0];
            setSelectedUser(usersList.users.users[0].user.id);
        }
    }

    allPermission = userPermissions.userPermissions;
    const onTabClick = (selectedTab) => {
        setSelectedTab(selectedTab.index);
    };

    const tabs = [
        {
            index: 0,
            id: 'Outlet Permission',
            name: 'Outlet Permission',
            content: filteredUser && (
                <UserOutletPermission
                    setUseSideEffect={setUseSideEffect}
                    useSideEffect={useSideEffect}
                    user={filteredUser}
                />
            ),
        },
        {
            index: 1,
            id: 'Access Permission',
            name: 'Access Permission',
            content: filteredUser && (
                <UserAccessPermissions
                    user={filteredUser}
                    modules={allPermission}
                    selectedUser={selectedUserId}
                    state={accessPermissionState}
                    stateChange={setAccessPermissionStateCallback}
                    setPermissionUpdate={setPermissionUpdateCallback}
                />
            ),
        },
    ];

    return (
        <EuiFlexGroup className='user-main'>
            <EuiFlexItem>
                <EuiPanel>
                    <EuiText>
                        <EuiFlexGroup>
                            <EuiFlexItem grow={3}>
                                <h3>Users</h3>
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <AddUser setPermissionUpdate={setPermissionUpdate} />
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </EuiText>
                    <UserTablelist
                        users={usersList.users}
                        selectedUser={selectedUserId}
                        setSelectedUser={setSelectedUserCallback}
                        setPermissionUpdate={setPermissionUpdate}
                        setUseSideEffect={setUseSideEffect}
                    />
                </EuiPanel>
            </EuiFlexItem>
            <EuiFlexItem>
                <EuiPanel>
                    <EuiText>
                        <h3>Access and Permissions Outlet</h3>
                    </EuiText>
                    <EuiSpacer />
                    <EuiTabbedContent tabs={tabs} selectedTab={tabs[selectedTab]} onTabClick={onTabClick} />
                </EuiPanel>
            </EuiFlexItem>
        </EuiFlexGroup>
    );
};
