import { EuiProgress } from '@elastic/eui';
import React from 'react';
import { useSelector } from 'react-redux';

const HeaderProgressLoader = () => {
    const totalApiRequestInProgress = useSelector((state) => state.loading.totalRequest);

    return <>{totalApiRequestInProgress > 0 ? <EuiProgress size='xs' color='primary' position='fixed' /> : null}</>;
};

export default React.memo(HeaderProgressLoader);
