import React from 'react';
import { EuiFlexItem, EuiText } from '@elastic/eui';
import FabSystemsLogo from '../../assets/img/FabSystemsLogo.png';
import moment from 'moment';
import RupeeSymbol from '../rupeeComponent.js';

export default class BillInvoicePrint extends React.Component {
    render() {
        const details = this.props.details;
        const centerStyle = { textAlign: 'center' };
        const sizeSmall = { fontSize: '14px' };

        return (
            <EuiFlexItem id='printInvoice'>
                <EuiFlexItem
                    grow={false}
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: 10 }}
                >
                    <img
                        src={FabSystemsLogo}
                        height={30}
                        width={70}
                        style={{ backgroundSize: 'cover' }}
                        alt='fab systems'
                    />
                </EuiFlexItem>

                {this.props?.outlet?.translations?.name[this.props.languageId] ? (
                    <EuiFlexItem style={centerStyle}>
                        {this.props.outlet.translations.name[this.props.languageId]}
                    </EuiFlexItem>
                ) : null}
                <br />
                {details?.created_at ? (
                    <EuiFlexItem>
                        <EuiText style={sizeSmall}>
                            Date & Time:{' '}
                            {details?.created_at ? moment(details.created_at).format('DD MMM YYYY, hh:mm a') : ''}
                        </EuiText>
                    </EuiFlexItem>
                ) : null}
                {details?.transaction_id ? (
                    <EuiFlexItem>
                        <EuiText style={sizeSmall}>Transaction ID: {details.transaction_id}</EuiText>
                    </EuiFlexItem>
                ) : null}
                {details?.payment_method?.payment_method ? (
                    <EuiFlexItem>
                        <EuiText style={sizeSmall}>Payment Method: {details.payment_method.payment_method}</EuiText>
                    </EuiFlexItem>
                ) : null}
                <br />
                <table style={{ width: '100%', ...sizeSmall, borderBottom: '1px solid black' }}>
                    <tr>
                        <td>
                            <span>Bill Amount</span>
                        </td>
                        <td className='borderForSecondTd' align='right'>
                            <RupeeSymbol size={'12px'} />
                            {details?.total_amount}
                        </td>
                    </tr>
                    {details?.bill_payment_convenience_charge ? (
                        <tr>
                            <td>
                                <span>Convenience Charge</span>
                            </td>
                            <td className='borderForSecondTd' align='right'>
                                <RupeeSymbol size={'12px'} />
                                {details?.bill_payment_convenience_charge}
                            </td>
                        </tr>
                    ) : null}
                    <tr className='borderColorForTable'>
                        <td className='borderForFirstTd'>Discount Amount</td>
                        <td className='borderForSecondTd' align='right'>
                            <RupeeSymbol size={'12px'} />
                            {details?.discount_amount}
                        </td>
                    </tr>
                    <tr className='borderColorForTable'>
                        <td className='borderForFirstTd'>Net Amount</td>
                        <td className='borderForSecondTd' align='right'>
                            <RupeeSymbol size={'12px'} />
                            {details?.amount}
                        </td>
                    </tr>
                </table>
            </EuiFlexItem>
        );
    }
}
