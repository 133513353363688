import API from '../axios/API';

export const restaurantSettingsAPI = (section, data) => {
    return async (dispatch, getState) => {
        let response;

        try {
            response = await API.put(
                `${process.env.REACT_APP_BASE_URL_V3}/restaurants/:restaurantId?section=${section}`,
                data
            );
        } catch (error) {
            response = error;
        }

        return response;
    };
};
