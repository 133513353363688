import API from '../axios/API';

export const mapItemsToCategory = async (items) => {
    let result;
    result = await API.patch(
        `${process.env.REACT_APP_BASE_URL_V3}/restaurants/:restaurantId/map-items-to-category`,
        items
    );

    return result;
};
