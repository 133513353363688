import React from 'react';
import { useSelector } from 'react-redux';
import { MainLayout } from '../../components/layout/mainLayout/mainLayout';
import { NoOutletMessage } from '../../utils/noOutletMessage';
import PastTableSession from './PastTableSession';

const PastTableSessionPage = () => {
    const outletId = useSelector((state) => state.outlet.selectedOutletId);

    return (
        <MainLayout backgroundColorChange={true} historyBack={true} outlet={true} title='Past Table Session'>
            {outletId ? <PastTableSession /> : <NoOutletMessage />}
        </MainLayout>
    );
};

export default PastTableSessionPage;
