import { GET_CREDIT_ALERTS, UPDATE_DATE_TIME, UPDATE_TRANSACTION_LIST } from '../types/CreditAlert';

const initialState = {
    alerts: {},
    last_update: {},
    transaction_list: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_CREDIT_ALERTS:
            return {
                ...state,
                alerts: action.payload,
            };
        case UPDATE_DATE_TIME:
            return {
                ...state,
                last_update: action.payload,
            };
        case UPDATE_TRANSACTION_LIST:
            return {
                ...state,
                transaction_list: action.payload,
            };
        default:
            return state;
    }
};
