import {
    EuiButton,
    EuiFlexGroup,
    EuiFlexItem,
    EuiForm,
    EuiModal,
    EuiModalBody,
    EuiModalFooter,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiRadioGroup,
    EuiSpacer,
} from '@elastic/eui';
import React, { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import TextField from '../../Form/TextField';
import { createOrderingMode } from './Utils/utils';

const OrderingModeModal = (props) => {
    const { isModalVisible, closeModal, updateOrderingModes } = props;

    const {
        handleSubmit,
        control,
        watch,
        formState: { errors },
        reset,
    } = useForm();

    const createNewOrderingMode = useCallback(
        async (data) => {
            closeModal();
            await createOrderingMode(data).then((response) => {
                if (response.success) {
                    reset({
                        display_name: '',
                        type: '',
                    });
                    updateOrderingModes();
                }
            });
        },
        [closeModal, reset, updateOrderingModes]
    );

    return (
        <>
            {isModalVisible ? (
                <EuiModal onClose={closeModal}>
                    <EuiForm component='form' onSubmit={handleSubmit(createNewOrderingMode)}>
                        <EuiModalHeader>
                            <EuiModalHeaderTitle>
                                <h1>Create Ordering Mode</h1>
                            </EuiModalHeaderTitle>
                        </EuiModalHeader>

                        <EuiModalBody>
                            <EuiFlexGroup
                                gutterSize='s'
                                alignItems='flexStart'
                                justifyContent='flexStart'
                                direction='column'
                            >
                                <EuiFlexItem style={{ width: '100%' }}>
                                    <TextField
                                        control={control}
                                        errors={errors}
                                        name='display_name'
                                        label='Display Name'
                                        placeholder='Enter Display Name'
                                        fullWidth={true}
                                        rules={{ required: 'Its req' }}
                                    />
                                    <EuiSpacer size='s' />
                                </EuiFlexItem>
                                <EuiFlexItem>
                                    <Controller
                                        control={control}
                                        name='type'
                                        render={({ field }) => {
                                            return (
                                                <EuiRadioGroup
                                                    options={types}
                                                    idSelected={field.value}
                                                    onChange={field.onChange}
                                                    legend={{
                                                        children: 'Type',
                                                    }}
                                                />
                                            );
                                        }}
                                    />
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </EuiModalBody>

                        <EuiModalFooter>
                            <EuiButton type='submit' fill isDisabled={!watch('display_name') || !watch('type')}>
                                Save
                            </EuiButton>
                        </EuiModalFooter>
                    </EuiForm>
                </EuiModal>
            ) : null}
        </>
    );
};

const types = [
    { label: 'QSR', id: 'qsr' },
    { label: 'Fine Dine', id: 'fine_dine' },
];

export default React.memo(OrderingModeModal);
