import IncomingOrderSound from '../assets/sound/IncomingOrderSound.wav';
import DispatchSoundFile from '../assets/sound/dispatch.mp3';
import PastOrdersSoundFile from '../assets/sound/past_order.mp3';
import RiderRejectSoundFile from '../assets/sound/rider_reject.wav';
import { getLiveOrders } from '../api/liveOrders/getLiveOrderNewAPI';
import { getLiveOrderDetailsAPI } from '../api/liveOrders/getLiveOrderDetailsAPI';
import { getPastOrdersForNotification } from '../api/pastOrder/getPastOrdersForNotification';
import { getFailedOrdersForNotification } from '../api/failedOrders/getFailedOrdersForNotification';
import { updateCreditAlerts } from '../api/CreditAlert/fetchAlerts';
import moment from 'moment';
import { fetchCreditTransactions } from '../api/topup/TransactionHistory';
import { fetchOutletSections } from '../components/TableManagement/Api/fetchOutletSections';
import { fetchTableOrderDetails } from '../components/TableManagement/Api/tableOperations';
import { fetchOutletOrderingModes } from '../api/outlet/fetchOutletOrderingModes';
import { fetchRestaurantOrderingModes } from '../components/settings/RestaurantSettings/Utils/utils';

export const playNotificationSound = (status) => {
    let soundFile = null;

    if (status === 'rider_picked_up') {
        soundFile = DispatchSoundFile;
    } else if (status === 'rider_delivered' || status === 'customer_picked_up') {
        soundFile = PastOrdersSoundFile;
    } else if (status === 'rider_reject') {
        soundFile = RiderRejectSoundFile;
    } else if (status === 'placed') {
        soundFile = IncomingOrderSound;
    }

    let sound = new Audio(soundFile);
    sound.play();
};

export const serviceWorkerEventListner = (payload) => {
    const startDate = moment();
    const endDate = moment();
    const pagination = {
        pageIndex: 0,
        pageSize: 10,
    };
    return async (dispatch) => {
        let notificationPayload = JSON.parse(payload.data['firebase-messaging-msg-data'].data.param);

        if (window.location.pathname == '/past-orders' && notificationPayload.status === 'order_refunded') {
            dispatch(getPastOrdersForNotification());
        }

        console.log('notificationPayload', notificationPayload);

        // console.log("failed orders service ", notificationPayload)
        if (window.location.pathname === '/failed-orders' && notificationPayload.status === 'order_refunded') {
            dispatch(getFailedOrdersForNotification());
        }

        // if (
        //     window.location.pathname !== '/live-orders' &&
        //     (notificationPayload.status === 'placed' || notificationPayload.status === 'restaurant_accepted')
        // ) {
        //     dispatch(getNewOrdersCount());
        // }

        if (
            window.location.pathname === '/table-management' &&
            (notificationPayload.status === 'placed' ||
                notificationPayload.status === 'restaurant_accepted' ||
                notificationPayload.status === 'restaurant_ready' ||
                notificationPayload.status === 'delivered' ||
                notificationPayload.status === 'restaurant_cancelled' ||
                notificationPayload.status === 'table_settled')
        ) {
            dispatch(fetchOutletSections());
        }

        if (
            (window.location.pathname === '/table-management' && notificationPayload.status === 'placed') ||
            notificationPayload.status === 'table_settled' ||
            notificationPayload.status === 'delivered' ||
            notificationPayload.status === 'payment_processed'
        ) {
            dispatch(fetchTableOrderDetails());
        }

        if (window.location.pathname === '/outlet-settings' && notificationPayload.status === 'outlet_menu_updated') {
            dispatch(fetchOutletOrderingModes());
            dispatch(fetchRestaurantOrderingModes());
        }

        if (
            notificationPayload.status === 'razorpay_order_paid' ||
            notificationPayload.status === 'outlet_settings_updated'
        ) {
            dispatch(updateCreditAlerts());
            dispatch({
                type: 'update_date_time',
                payload: {
                    startDate: startDate,
                    endDate: endDate,
                },
            });
        }

        if (notificationPayload.status === 'infrastructure_credit') {
            dispatch(fetchCreditTransactions(pagination, 'infrastructure', startDate, endDate)).then((res) => {
                if (res.success) {
                    dispatch({
                        type: 'update_transaction_list',
                        payload: res,
                    });
                }
            });
        }
        if (notificationPayload.status === 'service_credit') {
            dispatch(fetchCreditTransactions(pagination, 'services', startDate, endDate)).then((res) => {
                if (res.success) {
                    dispatch({
                        type: 'update_transaction_list',
                        payload: res,
                    });
                }
            });
        }
        if (window.location.pathname === '/live-orders') {
            // window.location.pathname === '/live-orders' && dispatch(getLiveOrders());

            dispatch(getLiveOrders());
            dispatch(getLiveOrderDetailsAPI());
        }

        if (notificationPayload.display_notification) {
            var options = {
                body: payload.data['firebase-messaging-msg-data'].data.body,
                requireInteraction: true,
                icon: payload.data['firebase-messaging-msg-data'].data.icon,
                click_action: payload.data['firebase-messaging-msg-data'].data.click_action,
            };

            if (!notificationPayload.persistent) {
                options.requireInteraction = false;
            }

            if (Notification.permission === 'granted') {
                new Notification(payload.data['firebase-messaging-msg-data'].data.title, options);
            }
        }

        if (notificationPayload.sound) {
            playNotificationSound(notificationPayload.status);
        }
    };
};
