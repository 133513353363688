import API from '../axios/API';

export const getPastOrders = (startDateTime, endDateTime, state, orderingMode, outletString) => {
    return async (dispatch) => {
        let response;

        try {
            if (startDateTime && endDateTime) {
                response = await API.get(
                    `/restaurants/:restaurantId/past-orders?page=${state.current_page + 1}&per_page=${
                        state.page_size
                    }&${outletString}&start_date=${startDateTime
                        .startOf('day')
                        .format('YYYY-MM-DD HH:mm:ss')}&end_date=${endDateTime
                        .endOf('day')
                        .format('YYYY-MM-DD HH:mm:ss')}${
                        orderingMode !== 'all' ? `&ordering_mode=${orderingMode}` : ''
                    }`
                );
            } else {
                response = await API.get(
                    `/restaurants/:restaurantId/past-orders?page=${state.current_page + 1}&per_page=${state.page_size}${
                        orderingMode !== 'all' ? `&ordering_mode=${orderingMode}` : ''
                    }&${outletString}`
                );
            }
        } catch (error) {
            response = error;
        }

        if (response.success) {
            dispatch({
                type: 'set-past-orders',
                payload: response.order_collection.orders.data,
            });
        }

        return response;
    };
};
