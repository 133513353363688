import {
    EuiCollapsibleNavGroup,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow,
    EuiRadioGroup,
    EuiText,
    EuiTextColor,
} from '@elastic/eui';
import React, { useMemo } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import TimeSlot from '../../Form/TimeSlot';
import WeekDaysTimeSlot from '../../menuComponentsRefactorCopy/menuEditorTab/WeekDaysTimeSlot';

const MultipleOutletModes = ({
    orderingMode,
    errors,
    stateUpdate,
    weekDays,
    display_name,
    reset,
    control,
    setValue,
    watch,
}) => {
    const sameTime = `time_${orderingMode.restaurant_ordering_mode_id}`;
    const selectedRadioId = `timer_for_item_${orderingMode.restaurant_ordering_mode_id}`;

    const itemTimeSlot = useMemo(() => {
        return [
            {
                id: `same_day_of_the_week_${orderingMode.restaurant_ordering_mode_id}`,
                label: `Outlet ${display_name} available at for all days of the week `,
            },
            {
                id: `same_time_for_all_days_${orderingMode.restaurant_ordering_mode_id}`,
                label: `Outlet ${display_name} available at same time for all days of the week`,
            },
            {
                id: `different_time_for_different_days_${orderingMode.restaurant_ordering_mode_id}`,
                label: `Outlet ${display_name} available at specific time for specific days of the week`,
            },
        ];
    }, [display_name, orderingMode.restaurant_ordering_mode_id]);

    const isTimeSameForAllDays =
        useWatch({
            control,
            name: `timer_for_item_${orderingMode.restaurant_ordering_mode_id}`,
        }) === `same_time_for_all_days_${orderingMode.restaurant_ordering_mode_id}`;

    const isTimeDifferentForDifferentDays =
        useWatch({
            control,
            name: `timer_for_item_${orderingMode.restaurant_ordering_mode_id}`,
        }) === `different_time_for_different_days_${orderingMode.restaurant_ordering_mode_id}`;
    return (
        <>
            <EuiCollapsibleNavGroup
                key={orderingMode.restaurant_ordering_mode_id.toString()}
                background='light'
                title={
                    <EuiFlexItem>
                        <EuiText>
                            <h3>
                                <span style={{ textTransform: 'capitalize' }}>
                                    {orderingMode?.display_name} Timings
                                </span>{' '}
                            </h3>
                        </EuiText>
                    </EuiFlexItem>
                }
                isCollapsible={true}
                initialIsOpen={false}
            >
                <EuiFlexItem style={{ paddingLeft: '10px' }}>
                    <EuiFlexItem>
                        <EuiText size='s'>
                            <span>Please Specify the timings when this item will be available on fab delivery</span>
                        </EuiText>
                        <EuiText size='s' color='warning'>
                            Each day can have only 6 time slots
                        </EuiText>
                    </EuiFlexItem>
                    <EuiFlexGroup alignItems='center' style={{ marginTop: '10px' }}>
                        <EuiFlexItem grow={false}>
                            <EuiFormRow isInvalid={errors[selectedRadioId]} fullWidth={true}>
                                <Controller
                                    render={({ field }) => {
                                        return (
                                            <>
                                                <EuiRadioGroup
                                                    options={itemTimeSlot}
                                                    idSelected={field.value}
                                                    onChange={(selectedId) => {
                                                        //! Prints only the fist element
                                                        setValue(selectedRadioId, selectedId, { shouldDirty: true });
                                                    }}
                                                    legend={{
                                                        children: <span> Decide this Item lifetime </span>,
                                                    }}
                                                />
                                            </>
                                        );
                                    }}
                                    name={selectedRadioId}
                                    control={control}
                                    fullWidth={true}
                                />
                            </EuiFormRow>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                    <EuiFlexItem>
                        {isTimeSameForAllDays && (
                            <>
                                <EuiFlexItem style={{ padding: '10px' }}>
                                    <TimeSlot
                                        control={control}
                                        setValue={setValue}
                                        name={sameTime}
                                        errors={errors}
                                        watch={watch}
                                    />
                                </EuiFlexItem>
                            </>
                        )}
                        {isTimeDifferentForDifferentDays && (
                            <>
                                {weekDays.map((day, index) => (
                                    <WeekDaysTimeSlot
                                        day={day}
                                        key={index + day}
                                        reset={reset}
                                        control={control}
                                        setValue={setValue}
                                        watch={watch}
                                        name={`week.${day}_${orderingMode.restaurant_ordering_mode_id}`}
                                        component={TimeSlot}
                                    />
                                ))}
                            </>
                        )}
                        {stateUpdate && <EuiTextColor color='danger'>please enter time slot</EuiTextColor>}
                    </EuiFlexItem>
                </EuiFlexItem>
            </EuiCollapsibleNavGroup>
        </>
    );
};

export default MultipleOutletModes;
