import { EuiFieldText, EuiFlexItem, EuiFormRow, EuiSelect, EuiSpacer, EuiText } from '@elastic/eui';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import LanguageBannerImage from '../../../modules/Banners/Components/LanguageBannerImage';
import CheckBoxField from '../../Form/CheckBoxField';
import ChoiceExpose from './ChoiceExpose';
import ChoicePricing from './ChoicePricing';

const AddonChoiceForm = ({ errors, index, field, setValue, watch, control }) => {
    const availableLanguages = useSelector((state) => state.language.availableLanguages);

    const onChangeFoodType = useCallback(
        (value, index) => {
            setValue(`choices[${index}].food_type`, value);
        },
        [setValue]
    );

    const [selectedImages, setSelectedImages] = useState({});
    const image = useWatch({
        control,
        name: `choices[${index}].image`,
    });

    useEffect(() => {
        if (!isEmpty(image)) {
            setSelectedImages(image);
        } else {
            setSelectedImages({});
        }
    }, [image]);

    return (
        <>
            <EuiFlexItem>
                {availableLanguages.map((language, i) => {
                    return (
                        <div key={i}>
                            <EuiFormRow
                                label={`Choice Title - ${language.language_label}`}
                                error={
                                    errors.choices &&
                                    errors.choices.hasOwnProperty(index) &&
                                    errors.choices[index][`choice_title${language.language_id}`]
                                        ? errors.choices[index][`choice_title${language.language_id}`].message
                                        : ''
                                }
                                isInvalid={
                                    errors.choices &&
                                    errors.choices.hasOwnProperty(index) &&
                                    errors.choices[index][`choice_title${language.language_id}`]
                                        ? true
                                        : false
                                }
                            >
                                <Controller
                                    render={({ field }) => (
                                        <EuiFieldText
                                            placeholder='Addon choice title'
                                            value={field.value}
                                            onChange={field.onChange}
                                        />
                                    )}
                                    name={`choices[${index}].choice_title${language.language_id}`}
                                    control={control}
                                    fullWidth={true}
                                    rules={{
                                        required: 'Please enter addon choice title',
                                    }}
                                    isInvalid={
                                        errors.choices &&
                                        errors.choices.hasOwnProperty(index) &&
                                        errors.choices[index][`choice_title${language.language_id}`]
                                            ? true
                                            : false
                                    }
                                    defaultValue={field[`choice_title${language.language_id}`]}
                                />
                            </EuiFormRow>
                            <EuiSpacer />
                        </div>
                    );
                })}
            </EuiFlexItem>

            <ChoiceExpose index={index} setValue={setValue} control={control} watch={watch} field={field} />

            <EuiFlexItem>
                <CheckBoxField
                    name={`choices[${index}].is_enabled_same_price_details_for_all_ordering_modes`}
                    label='Different Price for all ordering mode?'
                    control={control}
                    setValue={setValue}
                />
            </EuiFlexItem>

            <EuiSpacer size='s' />

            <ChoicePricing
                errors={errors}
                index={index}
                control={control}
                field={field}
                watch={watch}
                setValue={setValue}
            />

            <EuiFlexItem>
                <EuiText>
                    <span>Type of food :</span>
                </EuiText>

                <EuiFormRow>
                    <Controller
                        render={() => (
                            <EuiSelect
                                fullWidth={true}
                                options={options}
                                value={watch(`choices[${index}].food_type`)}
                                onChange={(e) => onChangeFoodType(e.target.value, index)}
                            />
                        )}
                        name={`choices[${index}].food_type`}
                        defaultValue={watch(`choices[${index}].food_type`, field[`food_type`])}
                        control={control}
                    />
                </EuiFormRow>
                <EuiSpacer />
                <LanguageBannerImage
                    buttonLabel={'Select an image'}
                    selectedImgButtonLabel={'Change item image'}
                    imageType={'addon_choices'}
                    selectedImages={selectedImages}
                    withRemove={true}
                    name={`choices[${index}].image`}
                    defaultImageValue={watch(`choices[${index}].image`)}
                    setValue={setValue}
                    aspectRatio={{
                        width: 1,
                        height: 1,
                    }}
                    setSelectedImages={setSelectedImages}
                />
            </EuiFlexItem>
            <EuiSpacer size='s' style={{ color: '#e9f1f8' }} />
        </>
    );
};

export default React.memo(AddonChoiceForm);

const options = [
    { value: 'non-vegetarian', text: 'Non Veg' },
    { value: 'vegetarian', text: 'Veg' },
    { value: 'egg', text: 'Eggs' },
];
