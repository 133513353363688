import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { EuiFlexItem, EuiFlexGroup, EuiFormRow, EuiRadioGroup, EuiText } from '@elastic/eui';
import { MENU_DISCOUNT, BOGO_WITH_VIEW } from '../OfferTypes/OfferTypes';
import RadioGroupField from '../../../../components/Form/RadioGroupField';

const Clubbing = ({ selectedOfferType }) => {
    const { control, watch, setValue } = useFormContext();

    return (
        <EuiFlexItem>
            <EuiFormRow fullWidth={true}>
                <EuiFlexGroup alignItems='flexStart' direction='column' gutterSize='s'>
                    <EuiFlexItem grow={false}>
                        <RadioGroupField
                            options={[
                                {
                                    id: 'clubbing_no',
                                    label: 'No',
                                },
                                {
                                    id: 'clubbing_yes',
                                    label: 'Yes',
                                },
                            ]}
                            idSelected={watch('allow_offer_clubbing') === false ? 'clubbing_no' : 'clubbing_yes'}
                            disabled={selectedOfferType === MENU_DISCOUNT}
                            onChange={(id) => {
                                setValue('allow_offer_clubbing', id === 'clubbing_no' ? false : true, {
                                    shouldDirty: true,
                                });
                                if (id === 'clubbing_no') {
                                    setValue('auto_apply', false);
                                }
                            }}
                            legend='Do you want this offer to apply with any other offer?'
                            name='allow_offer_clubbing'
                            defaultValue={true}
                        />
                    </EuiFlexItem>
                    {selectedOfferType === MENU_DISCOUNT ? (
                        <EuiFlexItem>
                            <EuiText size='xs'>NOTE: This offer will be clubbed.</EuiText>
                        </EuiFlexItem>
                    ) : null}
                </EuiFlexGroup>
            </EuiFormRow>
            {watch('allow_offer_clubbing') &&
                selectedOfferType !== MENU_DISCOUNT &&
                selectedOfferType !== BOGO_WITH_VIEW && (
                    <EuiFormRow fullWidth={true} style={{ marginLeft: 20 }}>
                        <EuiFlexGroup alignItems='center'>
                            <EuiFlexItem grow={false}>
                                <RadioGroupField
                                    options={[
                                        {
                                            id: 'auto_apply_no',
                                            label: 'No',
                                        },
                                        {
                                            id: 'auto_apply_yes',
                                            label: 'Yes',
                                        },
                                    ]}
                                    idSelected={watch('auto_apply') === true ? 'auto_apply_yes' : 'auto_apply_no'}
                                    onChange={(id) =>
                                        setValue('auto_apply', id === 'auto_apply_no' ? false : true, {
                                            shouldDirty: true,
                                        })
                                    }
                                    legend='Do you want to auto-apply this offer?'
                                    name='auto_apply'
                                />
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </EuiFormRow>
                )}

            {selectedOfferType === BOGO_WITH_VIEW && watch('allow_offer_clubbing') && (
                <EuiFormRow fullWidth={true} style={{ marginLeft: 600, marginTop: -75.5 }}>
                    <EuiFlexGroup alignItems='center'>
                        <EuiFlexItem grow={false}>
                            <Controller
                                render={() => (
                                    <EuiRadioGroup
                                        options={[
                                            {
                                                id: 'promo_consolidation_no',
                                                label: 'No',
                                            },
                                            {
                                                id: 'promo_consolidation_yes',
                                                label: 'Yes',
                                            },
                                        ]}
                                        idSelected={
                                            watch('promo_consolidation') === false
                                                ? 'promo_consolidation_no'
                                                : 'promo_consolidation_yes'
                                        }
                                        onChange={(id) =>
                                            setValue(
                                                'promo_consolidation',
                                                id === 'promo_consolidation_no' ? false : true,
                                                { shouldDirty: true }
                                            )
                                        }
                                        legend={{
                                            children: <span>Do you want to consolidate this offer with rewards?</span>,
                                        }}
                                    />
                                )}
                                name='promo_consolidation'
                                control={control}
                                fullWidth={true}
                                defaultValue={false}
                            />
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFormRow>
            )}

            {selectedOfferType !== BOGO_WITH_VIEW && watch('auto_apply') && (
                <EuiFormRow fullWidth={true} style={{ marginLeft: 20 }}>
                    <EuiFlexGroup alignItems='center'>
                        <EuiFlexItem grow={false}>
                            <Controller
                                render={() => (
                                    <EuiRadioGroup
                                        options={[
                                            {
                                                id: 'promo_consolidation_no',
                                                label: 'No',
                                            },
                                            {
                                                id: 'promo_consolidation_yes',
                                                label: 'Yes',
                                            },
                                        ]}
                                        idSelected={
                                            watch('promo_consolidation') === false
                                                ? 'promo_consolidation_no'
                                                : 'promo_consolidation_yes'
                                        }
                                        onChange={(id) =>
                                            setValue(
                                                'promo_consolidation',
                                                id === 'promo_consolidation_no' ? false : true,
                                                { shouldDirty: true }
                                            )
                                        }
                                        legend={{
                                            children: <span>Do you want to consolidate this offer with rewards?</span>,
                                        }}
                                    />
                                )}
                                name='promo_consolidation'
                                control={control}
                                fullWidth={true}
                                defaultValue={false}
                            />
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFormRow>
            )}
        </EuiFlexItem>
    );
};

export default React.memo(Clubbing);
