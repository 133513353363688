import React from 'react';
import { EuiEmptyPrompt } from '@elastic/eui';

export const NoOutletMessage = () => {
    return (
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
            <EuiEmptyPrompt
                iconType='editorStrike'
                title={<h2>No outlet selected</h2>}
                body={<p>Please select an outlet.</p>}
            />
        </div>
    );
};
