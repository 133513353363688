import React, { useState } from 'react';
import TextField from '../../Form/TextField';
import {
    EuiButton,
    EuiFlexItem,
    EuiCopy,
    EuiText,
    EuiSpacer,
    EuiFlyout,
    EuiFlyoutHeader,
    EuiFlyoutBody,
    EuiTitle,
} from '@elastic/eui';
import FormGroupDescription from '../../formGroupDescription';
import ButtonGroup from '../../Form/ButtonGroup';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { requestForRiderService } from '../../../api/requestThirdPartyRiderService';
import { getOutletDetails } from '../../../api/outlet/getOutletDetails';

const DunzoRider = () => {
    const {
        formState: { errors },
        control,
        watch,
        setValue,
    } = useFormContext();
    const outletId = useSelector((state) => state.outlet.selectedOutletId);
    const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);
    const dispatch = useDispatch();
    const outlet = useSelector((state) => state.outlet.selectedOutletDetails);
    const approvalDate = outlet?.third_party_rider_setting?.dunzo_request?.approval_date;
    const serviceStatus = outlet?.third_party_rider_setting?.dunzo_request?.status;

    const startService = () => {
        dispatch(requestForRiderService('dunzo', 'rider service request for Dunzo')).then((res) => {
            dispatch(getOutletDetails(outletId));
        });
    };
    let flyout;

    if (isFlyoutVisible) {
        flyout = (
            <EuiFlyout ownFocus onClose={() => setIsFlyoutVisible(false)} aria-labelledby={'simpleFlyoutTitle'}>
                <EuiFlyoutHeader hasBorder>
                    <EuiTitle size='m'>
                        <h2 id={'simpleFlyoutTitle'}>Dunzo riders</h2>
                    </EuiTitle>
                </EuiFlyoutHeader>
                <EuiFlyoutBody>
                    <ButtonGroup
                        idSelected={watch('dunzo_integration_available') ? '1' : '0'}
                        color='primary'
                        control={control}
                        setValue={setValue}
                        name='dunzo_integration_available'
                        watch={watch}
                        label={'Dunzo integration'}
                    />

                    {watch('dunzo_integration_available') ? (
                        <>
                            <TextField
                                errors={errors}
                                label='Dunzo Client Secret'
                                name='dunzo_client_secret'
                                placeholder='Enter Dunzo api key'
                                rules={{ required: 'Please Enter Dunzo api key' }}
                                control={control}
                            />
                            <TextField
                                errors={errors}
                                label='Dunzo Client Id'
                                name='dunzo_client_id'
                                placeholder='Enter primary address id'
                                rules={{ required: 'Please enter primary address id' }}
                                control={control}
                            />
                            <TextField
                                errors={errors}
                                label='Dunzo call back URL'
                                name='dunzo_call_back_url'
                                placeholder='Enter url'
                                control={control}
                                disabled={true}
                                defaultValue={watch('dunzo_call_back_url')}
                                append={
                                    <EuiCopy
                                        textToCopy={watch('dunzo_call_back_url') ? watch('dunzo_call_back_url') : ''}
                                    >
                                        {(copy) => <EuiText onClick={copy}>Copy</EuiText>}
                                    </EuiCopy>
                                }
                            />
                        </>
                    ) : null}
                </EuiFlyoutBody>
            </EuiFlyout>
        );
    }

    return (
        <div style={{ maxWidth: '650px' }}>
            <FormGroupDescription
                title={restaurantDescriptions?.dunzo_riders?.display_name}
                description={restaurantDescriptions?.dunzo_riders?.description}
            >
                <EuiFlexItem>
                    <EuiButton
                        style={{
                            backgroundColor: serviceStatus === 'approved' && '#228B22',
                            color: serviceStatus === 'approved' && 'white',
                        }}
                        fill
                        onClick={startService}
                        disabled={serviceStatus ? true : false}
                    >
                        {serviceStatus ? serviceStatus : 'START SERVICE'}
                    </EuiButton>
                </EuiFlexItem>
                <EuiSpacer style={{ marginTop: '6px' }}>
                    <EuiText textAlign='center' size='xs'>
                        ------ OR ------
                    </EuiText>
                </EuiSpacer>
                <EuiFlexItem>
                    <EuiButton onClick={() => setIsFlyoutVisible(true)}>CONNECT ACCOUNT</EuiButton>
                </EuiFlexItem>
                {flyout}
            </FormGroupDescription>
            {serviceStatus === 'pending' && (
                <EuiText
                    style={{
                        padding: '8px 12px',
                        background: '#BBB',
                        color: 'white',
                        marginBottom: '12px',
                        borderRadius: '4px',
                        // width: '65%',
                    }}
                >
                    <p> Your reqeust has been received and it shall be activated by {approvalDate} </p>
                </EuiText>
            )}
        </div>
    );
};

export default DunzoRider;
