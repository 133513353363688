import React, { useCallback, useEffect, useState } from 'react';
import { EuiForm } from '@elastic/eui';
import FormGroupDescription from '../../../components/formGroupDescription';
import TextAreaField from '../../../components/Form/TextAreaField';
import { useDispatch, useSelector } from 'react-redux';
import { restaurantSettingsAPI } from '../../../api/restaurantSettings/restaurantSettingsAPI';
import useFormActionsHandler from '../../../hooks/useFormActionsHandler';

const LegalSettings = (props) => {
    const { restaurantResponse, fetchRestaurant } = props;
    const dispatch = useDispatch();
    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);

    const [defaultValues, setDefaultValues] = useState({});

    const updateFormValues = useCallback((data) => {
        let defaultValue = {};
        defaultValue.languages = data.languages.map((item) => ({
            label: item.language_label,
            language: item.language,
            language_label: item.language_label,
        }));

        let translationObject = {
            return_policy: {},
        };

        for (const language_label in data.translations.return_policy) {
            translationObject.return_policy[language_label] = data.translations.return_policy[language_label];
        }

        defaultValue.translations = translationObject;

        return defaultValue;
    }, []);

    useEffect(() => {
        setDefaultValues(updateFormValues(restaurantResponse.restaurant));
    }, [restaurantResponse.restaurant, updateFormValues]);

    const onFormSaveApi = useCallback(
        async (data) => {
            const response = await dispatch(restaurantSettingsAPI('legal', data));
            if (response.success) {
                fetchRestaurant();
            } else {
                alert(response.message);
            }

            if (response.success) setDefaultValues(updateFormValues(response.restaurant));
            return response;
        },
        [dispatch, fetchRestaurant, updateFormValues]
    );

    const { errors, control, watch } = useFormActionsHandler({ onFormSaveApi, defaultValues });

    return (
        <EuiForm component='form'>
            <FormGroupDescription
                title={restaurantDescriptions?.return_policy?.display_name}
                description={restaurantDescriptions?.return_policy?.description}
            >
                {watch('languages') &&
                    watch('languages').length > 0 &&
                    watch('languages').map((item, index) => (
                        <TextAreaField
                            errors={errors}
                            isInvalid={
                                errors.translations &&
                                errors.translations.return_policy &&
                                errors.translations.return_policy.hasOwnProperty(item.language)
                            }
                            error={
                                errors.translations &&
                                errors.translations.return_policy &&
                                errors.translations.return_policy.hasOwnProperty(item.language) &&
                                errors.translations.return_policy[item.language]
                                    ? errors.translations.return_policy[item.language].message
                                    : ''
                            }
                            label={index === 0 ? <strong>Return policy</strong> : ''}
                            name={`translations.return_policy.${item.language}`}
                            placeholder={`Write return policy: ${item.language_label}`}
                            rules={{ required: 'Please write return policy' }}
                            control={control}
                            watch={watch}
                            rows={4}
                        />
                    ))}
            </FormGroupDescription>
        </EuiForm>
    );
};

export default LegalSettings;
