import React, { useCallback, useEffect, useState } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiText, EuiCheckbox, htmlIdGenerator, EuiFormRow } from '@elastic/eui';
import { useSelector } from 'react-redux';
import { Controller, useWatch } from 'react-hook-form';
import { isEmpty } from 'lodash';

const VariantExpose = ({ control, setValue, watch, isOutletMenu, index, fieldVariant }) => {
    const orderingModes = useSelector((state) => state.menuReducer.restaurantOrderingModes);
    const itemOrderingModes = useWatch({
        control,
        name: `variantGroup[${index}].ordering_modes`,
    });

    const [orderingModeState, setOrderingModeState] = useState([]);
    const [removedOrderingModes, setRemovedOrderingModes] = useState([]);

    useEffect(() => {
        if (itemOrderingModes?.length) {
            setOrderingModeState(itemOrderingModes);
        }
    }, [itemOrderingModes]);

    const updateOrderingMode = useCallback(
        (mode, event) => {
            const doesHaveDifferentPrices = watch(
                `variantGroup[${index}].is_enabled_same_price_details_for_all_ordering_modes`
            );
            let selectedMode = {};
            selectedMode = {
                compare_price: !doesHaveDifferentPrices ? orderingModeState?.[0]?.compare_price : null,
                gst_slab: !doesHaveDifferentPrices ? orderingModeState?.[0]?.gst_slab : null,
                packaging_charge: !doesHaveDifferentPrices ? orderingModeState?.[0]?.packaging_charge : null,
                price: !doesHaveDifferentPrices ? orderingModeState?.[0]?.price : null,
                [`price_${mode.id}`]: !doesHaveDifferentPrices ? orderingModeState?.[0]?.price : null,
                restaurant_ordering_mode_id: mode?.id,
                restaurant_ordering_mode_name: mode?.display_name,
            };
            const isChecked = event.target.checked;

            if (!isChecked) {
                // removed ordering mode
                const doesModeExisted = fieldVariant?.ordering_modes?.filter(
                    (mode) => mode.restaurant_ordering_mode_id === selectedMode.restaurant_ordering_mode_id
                )?.[0];
                if (!isEmpty(doesModeExisted)) {
                    setRemovedOrderingModes([...removedOrderingModes, doesModeExisted?.restaurant_ordering_mode_id]);
                    const updatedOrderingMode = orderingModeState.filter(
                        (mode) => mode.restaurant_ordering_mode_id !== selectedMode.restaurant_ordering_mode_id
                    );
                    if (updatedOrderingMode.length) {
                        setOrderingModeState(updatedOrderingMode);
                        setValue(`variantGroup[${index}].ordering_modes`, updatedOrderingMode);
                    }
                    setValue(`variantGroup[${index}].delete_ordering_modes`, [
                        ...removedOrderingModes,
                        doesModeExisted?.restaurant_ordering_mode_id,
                    ]);
                } else {
                    const removedFromOrderingState = orderingModeState.filter(
                        (mode) => mode.restaurant_ordering_mode_id !== selectedMode.restaurant_ordering_mode_id
                    );
                    setOrderingModeState(removedFromOrderingState);
                    setValue(`variantGroup[${index}].ordering_modes`, removedFromOrderingState);
                }
            } else {
                setOrderingModeState((prevState) => [...prevState, selectedMode]);

                // check if the id is in removeState
                const destroyFromRemoveState = removedOrderingModes?.filter(
                    (id) => id != selectedMode.restaurant_ordering_mode_id
                );
                setRemovedOrderingModes(destroyFromRemoveState);
                setValue(`variantGroup[${index}].delete_ordering_modes`, destroyFromRemoveState);
                setValue(`variantGroup[${index}].ordering_modes`, [...orderingModeState, selectedMode]);
                // setOrderingModeState([...orderingModeState, modeComeBack]);
            }
        },
        [fieldVariant?.ordering_modes, index, orderingModeState, removedOrderingModes, setValue, watch]
    );

    return (
        <EuiFormRow label='Variant Expose' style={{ minWidth: '95%' }}>
            <EuiFlexGroup
                alignItems='center'
                style={{ maxWidth: '95%' }}
                gutterSize='s'
                justifyContent='flexStart'
                wrap
                responsive
            >
                {orderingModes
                    .filter((orderingMode) => orderingMode.status)
                    .map((mode) => {
                        return (
                            <EuiFlexItem key={mode.id.toString()}>
                                <EuiFlexGroup direction='row' justifyContent='flexStart' alignItems='center'>
                                    <EuiFlexItem grow={false}>
                                        <Controller
                                            render={({ field }) => (
                                                <EuiCheckbox
                                                    onChange={(e) => {
                                                        field.onChange(e.target.checked);
                                                        updateOrderingMode(mode, e);
                                                        setValue(
                                                            `${mode.display_name}_${mode.id}_${index}`,
                                                            e.target.checked
                                                        );
                                                    }}
                                                    id={htmlIdGenerator()()}
                                                    checked={Boolean(field.value)}
                                                    disabled={isOutletMenu}
                                                    compressed
                                                />
                                            )}
                                            name={`${mode.display_name}_${mode.id}_${index}`}
                                            control={control}
                                        />
                                    </EuiFlexItem>
                                    <EuiFlexItem grow={false}>
                                        <EuiText style={{ textTransform: 'capitalize' }}>{mode.display_name}</EuiText>
                                    </EuiFlexItem>
                                </EuiFlexGroup>
                            </EuiFlexItem>
                        );
                    })}
            </EuiFlexGroup>
        </EuiFormRow>
    );
};

export default React.memo(VariantExpose);
