import { EuiButton, EuiSpacer } from '@elastic/eui';
import React, { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { userHasPermission } from '../../../../../helper/auth';
import ItemDeletionModal from './ItemDeletionModal';

const ItemDetailsActions = ({ buttonLoader, setErrorMessage, setShowError, mainSubmitClicked }) => {
    const isOutletMenu = useSelector((state) => state.menuReducer.isOutletMenu);
    const permissions = useSelector((state) => state.permission.permission);
    const buttonDisable = useSelector((state) => state.menu.buttonDisable);
    const orderingModes = useSelector((state) => state.menuReducer.restaurantOrderingModes);
    const [showItemDeletionModal, setShowItemDeletionModal] = useState(false);

    const { control } = useFormContext();

    const selectedOrderingModes = useWatch({
        control,
        name: 'ordering_modes',
    });

    const selectedActiveOrderingModes = selectedOrderingModes?.filter((orderingMode) =>
        orderingModes?.some(
            (restaurantOrderingMode) =>
                restaurantOrderingMode?.id === orderingMode.restaurant_ordering_mode_id && restaurantOrderingMode.status
        )
    );

    return (
        <>
            <ItemDeletionModal
                showItemDeletionModal={showItemDeletionModal}
                setShowItemDeletionModal={setShowItemDeletionModal}
            />
            {!isOutletMenu ? (
                <>
                    <EuiButton
                        onClick={() => {
                            mainSubmitClicked.current = true;
                            setShowError(true);
                            setErrorMessage('');
                        }}
                        type='submit'
                        fill
                        isLoading={buttonLoader || buttonDisable}
                        disabled={
                            !userHasPermission(permissions, '/menu', 'write') ||
                            buttonLoader ||
                            !selectedActiveOrderingModes?.length
                        }
                    >
                        Save
                    </EuiButton>
                    <EuiSpacer size='s' />
                    <EuiButton
                        color='danger'
                        onClick={() => setShowItemDeletionModal(true)}
                        type='submit'
                        disabled={!userHasPermission(permissions, '/menu', 'delete') || buttonLoader}
                    >
                        Delete
                    </EuiButton>
                </>
            ) : null}
        </>
    );
};

export default ItemDetailsActions;
