import API from '../../../api/axios/API';
import { SET_BANNER } from '../../../reduxStore/types/banner';

export const fetchGlobalBanners = () => {
    return async (dispatch, getState) => {
        let response;

        try {
            response = await API.get(`restaurants/:restaurantId/banners`);
        } catch (error) {
            response = error;
        }

        if (response.success) {
            dispatch({
                type: SET_BANNER,
                payload: {
                    banners: response.banners,
                },
            });
        }
    };
};
