import APIV3 from '../axios/APIV3';

export const outletCategorySequenceChange = (id, items) => {
    return async (dispatch, getState) => {
        let response;

        try {
            response = await APIV3.patch(`restaurants/:restaurantId/outlets/${id}/categories/change-sequence`, {
                categories: items,
            });
        } catch (error) {
            response = error;
        }

        return response;
    };
};
