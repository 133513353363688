import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LinkOffer from '../../modules/Offers/Tabs/LinkOffer';
import OfferTable from '../../modules/Offers/Tabs/OfferTable';
import { EuiFlexItem, EuiSpacer, EuiPanel, EuiFlexGroup, EuiTabs, EuiTab } from '@elastic/eui';
import { fetchRestaurantOrderingModes } from '../settings/RestaurantSettings/Utils/utils';
import OfferSelection from '../../modules/Offers/Components/OfferSelection';

const OFFER_LIST_SCREEN = 'offer_list_screen';
const OFFER_LINK_SCREEN = 'offer_link_screen';

const Offer = () => {
    const restaurantOrderingModes = useSelector((state) => state.restaurantReducer.orderingModes);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!restaurantOrderingModes?.length) {
            dispatch(fetchRestaurantOrderingModes());
        }
    }, [dispatch, restaurantOrderingModes]);

    const [currentTab, setCurrentTab] = useState(OFFER_LIST_SCREEN);

    return (
        <>
            <EuiPanel>
                <EuiFlexGroup justifyContent='spaceBetween'>
                    <EuiFlexItem grow={false}>
                        <EuiFlexGroup gutterSize='none'>
                            <EuiFlexItem>
                                <EuiTabs>
                                    <EuiTab
                                        isSelected={currentTab === OFFER_LIST_SCREEN}
                                        onClick={() => setCurrentTab(OFFER_LIST_SCREEN)}
                                        style={{ paddingRight: '18px' }}
                                    >
                                        Offer List
                                    </EuiTab>
                                </EuiTabs>
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <EuiTabs>
                                    <EuiTab
                                        isSelected={currentTab === OFFER_LINK_SCREEN}
                                        onClick={() => setCurrentTab(OFFER_LINK_SCREEN)}
                                        style={{ paddingRight: '18px' }}
                                    >
                                        Link Offer
                                    </EuiTab>
                                </EuiTabs>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </EuiFlexItem>
                    <OfferSelection />
                </EuiFlexGroup>
            </EuiPanel>
            <EuiSpacer />
            <EuiPanel>{currentTab === OFFER_LINK_SCREEN ? <LinkOffer /> : <OfferTable />}</EuiPanel>
        </>
    );
};

export default React.memo(Offer);
