import React, { memo, useCallback } from 'react';
import { EuiDraggable, EuiFlexGroup, EuiFlexItem, EuiText, EuiIcon, EuiPanel, EuiButtonIcon } from '@elastic/eui';
import { useSelector } from 'react-redux';
import { userHasPermission } from '../../../helper/auth';

const AddOnChoiceList = (props) => {
    const languageId = useSelector((state) => state.language.languageId);
    const permissions = useSelector((state) => state.permission.permission);
    const addOnGroupSelected = useSelector((state) => state.menuReducer.addOnGroupSelectedId);

    const onDelete = useCallback(
        async (id) => {
            props.setChoiceDeleteId(id);
            props.setChoiceDeleteConfirmationModal(true);
        },
        [props]
    );

    const renderList = useCallback(() => {
        if (props.choicesList.length < 1) {
            return (
                <EuiPanel>
                    <EuiText>There are no choices in this group.</EuiText>
                </EuiPanel>
            );
        }

        let choiceArray = [];

        props.choicesList?.length
            ? props.choicesList.map((choice, index) => {
                  choiceArray.push(
                      <EuiDraggable
                          spacing='s'
                          key={choice.choice_id.toString()}
                          index={index}
                          draggableId={choice.choice_id.toString()}
                          customDragHandle={true}
                      >
                          {(provided) => (
                              <EuiPanel paddingSize='m'>
                                  <EuiFlexGroup>
                                      <EuiFlexItem grow={false} style={{ justifyContent: 'center' }}>
                                          <div {...provided.dragHandleProps}>
                                              <EuiIcon type='grab' />
                                          </div>
                                      </EuiFlexItem>
                                      <EuiFlexItem
                                          style={{
                                              justifyContent: 'space-between',
                                              flexDirection: 'row',
                                              alignItems: 'center',
                                          }}
                                      >
                                          <EuiFlexItem
                                              grow={9}
                                              style={{
                                                  flexDirection: 'row',
                                                  alignItems: 'center',
                                              }}
                                          >
                                              <EuiText>{choice.translations.name[languageId.toString()]}</EuiText>
                                          </EuiFlexItem>
                                          <EuiFlexItem grow={1}>
                                              {userHasPermission(permissions, '/menu', 'delete') && (
                                                  <EuiButtonIcon
                                                      color='danger'
                                                      style={{ background: 'transparent' }}
                                                      onClick={() => onDelete(choice.choice_id)}
                                                      iconType='trash'
                                                      aria-label='Next'
                                                  />
                                              )}
                                          </EuiFlexItem>
                                      </EuiFlexItem>
                                  </EuiFlexGroup>
                              </EuiPanel>
                          )}
                      </EuiDraggable>
                  );
                  return null;
              })
            : displayMessage();

        return choiceArray;
    }, [languageId, onDelete, permissions, props.choicesList]);

    return <>{addOnGroupSelected ? renderList() : displayMessage()}</>;
};
export default memo(AddOnChoiceList);
const displayMessage = () => {
    return (
        <EuiPanel hasBorder>
            <EuiText>Please select a group.</EuiText>
        </EuiPanel>
    );
};
