import {
    EuiBasicTable,
    EuiCard,
    EuiFlexGroup,
    EuiFlexItem,
    EuiLoadingContent,
    EuiPagination,
    EuiPanel,
    EuiSpacer,
    EuiText,
} from '@elastic/eui';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchSelectedBillDetails } from '../../api/BillPayments/fetchSelectedBillDetails';
import useSingleScreenHeightCalc from '../../hooks/useSingleScreenHeightCalc';
import { paymentMethod } from '../liveOrders/liveOrdersTable';
import { refundMessage } from '../liveOrders/liveOrdersTableForPast';
import RupeeSymbol from '../rupeeComponent.js';
import BillDetails from './BillDetails';

const BillPaymentTable = ({ records, endDate, startDate, fetchPayments }) => {
    const [items, setItems] = useState([]);
    const [columns, setColumns] = useState([]);
    const [selectedPayment, setSelectedPayment] = useState({
        details: null,
        id: null,
        loading: false,
        outlet: null,
        customer: null,
    });
    const themeMode = useSelector((state) => state.theme.theme);
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);
    const { screenHeight } = useSingleScreenHeightCalc(300);

    useEffect(() => {
        let recordItems = [];
        if (records?.data?.length) {
            for (const record of records.data) {
                let recordObj = {
                    name: record,
                    amount: record,
                    time: record?.created_at,
                    id: record?.id,
                    detials: record,
                };
                recordItems.push(recordObj);
            }
            setItems(recordItems);
        } else {
            setItems([]);
            setSelectedPayment({
                details: null,
                id: null,
                loading: false,
                outlet: null,
                customer: null,
            });
        }
    }, [records]);

    const columnsData = useMemo(() => {
        return [
            {
                field: 'name',
                name: 'Name',
                sortable: true,
                width: '40%',
                render: (record) => {
                    return (
                        <EuiFlexGroup
                            direction='column'
                            justifyContent='flexStart'
                            alignItems='flexStart'
                            gutterSize='none'
                        >
                            <EuiFlexItem>
                                <EuiText size='s'>{record?.customer?.name}</EuiText>
                            </EuiFlexItem>
                            <EuiFlexItem>{record?.refunds?.length ? refundMessage(record?.refunds) : ''}</EuiFlexItem>
                        </EuiFlexGroup>
                    );
                },
            },
            {
                field: 'amount',
                name: 'Amount',
                sortable: true,
                width: '20%',
                align: 'center',
                render: (record) => {
                    return (
                        <EuiFlexGroup direction='column' gutterSize='xs'>
                            {record?.amount ? (
                                <EuiFlexItem>
                                    <EuiText size='s'>
                                        <RupeeSymbol size={'12px'} />
                                        {record.amount}
                                    </EuiText>
                                </EuiFlexItem>
                            ) : null}
                            <EuiSpacer size='xs' />
                            {record?.payment_method?.payment_method ? (
                                <div
                                    style={{
                                        border:
                                            record?.payment_method?.payment_method == 'CASH_ON_DELIVERY'
                                                ? '1px solid rgb(246, 131, 15)'
                                                : '1px solid rgb(14, 145, 140)',
                                    }}
                                >
                                    <EuiText style={{ margin: '0 2px' }} size='xs'>
                                        {paymentMethod(record?.payment_method)}
                                    </EuiText>
                                </div>
                            ) : null}
                        </EuiFlexGroup>
                    );
                },
            },
            { field: 'time', name: 'Time', sortable: true, align: 'right', width: '20%' },
        ];
    }, []);

    useEffect(() => {
        setColumns(columnsData);
    }, [columnsData]);

    const getRowProps = useCallback(
        (item) => {
            const { id } = item;
            return {
                'data-test-subj': `row-${id}`,
                className: 'customRowClass',
                style: {
                    // backgroundColor: parseInt(selectedPayment.id) === parseInt(id) ? '#DDE7F9' : '#fff',
                    style: {
                        backgroundColor:
                            parseInt(selectedPayment.id) === parseInt(id)
                                ? themeMode === 'light'
                                    ? '#f5f7fa'
                                    : '#6d737e'
                                : '',
                    },
                },
                onClick: () => {
                    setSelectedPayment((prevState) => ({
                        ...prevState,
                        id: id,
                        loading: true,
                    }));
                    fetchSelectedBillDetails(id, selectedOutletId).then((response) => {
                        if (!response?.isError) {
                            setSelectedPayment((prevState) => ({
                                ...prevState,
                                details: response.customer_bill_payments,
                                outlet: response.outlet,
                                loading: false,
                                customer: response.customer,
                            }));
                        }
                    });
                },
            };
        },
        [selectedPayment.id, themeMode, selectedOutletId]
    );

    const handlePageOnClick = useCallback(
        (activePage) => {
            fetchPayments(startDate, endDate, activePage + 1);
        },
        [endDate, fetchPayments, startDate]
    );

    return (
        <EuiFlexGroup>
            <EuiFlexItem grow={6} style={{ height: screenHeight }} className='scrollbar-sm'>
                <EuiCard
                    className='scrollbar'
                    style={{
                        backgroundColor: '#fff',
                        maxHeight: '95%',
                        padding: '8px',
                        boxShadow: '0 10px 10px -2px rgb(152 162 179 / 30%), 0 1px 24px -2px rgb(152 162 179 / 30%)',
                    }}
                    hasBorder
                    paddingSize='none'
                    layout='horizontal'
                    title={
                        <EuiFlexItem style={{ padding: '5px' }}>
                            <EuiText style={{ width: '50%', fontSize: '20.3px', fontWeight: 'bold' }}>
                                <h3>
                                    <b>Recent Payments</b>
                                </h3>
                            </EuiText>
                        </EuiFlexItem>
                    }
                    children={
                        <>
                            <EuiFlexGroup direction='column' justifyContent='spaceBetween'>
                                <EuiFlexItem grow={10}>
                                    <EuiBasicTable
                                        items={items}
                                        rowHeader='name'
                                        columns={columns}
                                        rowProps={getRowProps}
                                        noItemsMessage='Records Not Found'
                                    />
                                </EuiFlexItem>
                                <EuiFlexItem>
                                    <EuiFlexGroup justifyContent='spaceBetween' alignItems='stretch' direction='row'>
                                        <EuiFlexItem grow={true}></EuiFlexItem>
                                        <EuiFlexItem grow={false}>
                                            <EuiPagination
                                                aria-label='Payment Records'
                                                pageCount={records?.last_page}
                                                activePage={records?.current_page - 1}
                                                onPageClick={handlePageOnClick}
                                            />
                                        </EuiFlexItem>
                                    </EuiFlexGroup>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </>
                    }
                />
            </EuiFlexItem>
            {selectedPayment.loading ? (
                <EuiFlexItem grow={4} style={{ height: screenHeight }} className='scrollbar-sm'>
                    <EuiPanel hasBorder style={{ boxShadow: cardStyles.boxShadow, maxHeight: '95%' }}>
                        <EuiLoadingContent lines={7} />
                    </EuiPanel>
                </EuiFlexItem>
            ) : (
                <>
                    {selectedPayment?.details ? (
                        <EuiFlexItem style={{ height: screenHeight }} className='scrollbar-sm' grow={4}>
                            <BillDetails
                                setSelectedPayment={setSelectedPayment}
                                details={selectedPayment.details}
                                outlet={selectedPayment.outlet}
                                customer={selectedPayment.customer}
                            />
                        </EuiFlexItem>
                    ) : (
                        <EuiFlexItem style={{ height: screenHeight }} className='scrollbar-sm' grow={4}>
                            <EuiCard
                                title='Please select a payment'
                                hasBorder
                                style={{ boxShadow: cardStyles.boxShadow, maxHeight: '95%' }}
                            />
                        </EuiFlexItem>
                    )}
                </>
            )}
        </EuiFlexGroup>
    );
};

export const cardStyles = {
    boxShadow: '0 10px 10px -2px rgb(152 162 179 / 30%), 0 1px 24px -2px rgb(152 162 179 / 30%)',
};

export default React.memo(BillPaymentTable);
