import React from 'react';
import { EuiFormRow, EuiSuperSelect } from '@elastic/eui';

import { Controller } from 'react-hook-form';
const SelectField = (props) => {
    const { label, name, placeholder, rules, errors, control, options, valueOfSelected, defaultValue, fullWidth } =
        props;
    return (
        <Controller
            render={({ field }) => (
                <EuiFormRow
                    label={label}
                    isInvalid={errors[name] ? true : false}
                    error={errors[name] ? errors[name].message : ''}
                    fullWidth={fullWidth ? fullWidth : false}
                >
                    <EuiSuperSelect
                        options={options}
                        valueOfSelected={valueOfSelected ? valueOfSelected : field.value}
                        onChange={props.onChange ? props.onChange : field.onChange}
                        placeholder={placeholder}
                        defaultValue={defaultValue ? defaultValue : []}
                    />
                </EuiFormRow>
            )}
            rules={rules}
            name={name}
            control={control}
            fullWidth
            defaultValue={defaultValue ? defaultValue : []}
            required={errors[name]}
        />
    );
};

export default SelectField;
