import React, { useCallback } from 'react';
import ComboBoxField from '../../../components/Form/ComboBoxField';
import TextField from '../../../components/Form/TextField';
import { currencyData } from '../../../components/settings/countrySymbolAndInforamtion';
import { EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiForm } from '@elastic/eui';
import FormGroupDescription from '../../../components/formGroupDescription';
import { useDispatch, useSelector } from 'react-redux';
import { outletSettingsAPI } from '../../../api/outlet/outletSettingsAPI';
import useOutletFormValues from '../Hooks/useOutletFormValues';
import useFormActionsHandler from '../../../hooks/useFormActionsHandler';

const PaymentSetting = () => {
    const dispatch = useDispatch();

    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);

    const selectedOutletDetails = useSelector((state) => state.outlet.selectedOutletDetails);

    const payment_provider_options = [
        { label: 'paytm' },
        { label: 'stripe' },
        ...(selectedOutletDetails.settings.is_ccavenue_enabled === 1 ? [{ label: 'ccavenue' }] : []),
    ];

    const updateFormValues = useCallback((data) => {
        let paymentProvider = [];
        let cardProvider = [];

        let defaultValue = {};

        defaultValue.gst_applicable = data.settings.gst_applicable ? 'get_yes' : 'get_no';
        defaultValue.delivery_charges_gst_slab = data.settings.delivery_charges_gst_slab.toString();
        defaultValue.gst_number = data.settings.gst_number;

        //set payment provider
        if (data.payment_settings.paytm) {
            paymentProvider.push({
                label: 'paytm',
            });
        }

        if (data.payment_settings.stripe) {
            paymentProvider.push({
                label: 'stripe',
            });
        }

        if (data.payment_settings.ccavenue) {
            paymentProvider.push({
                label: 'ccavenue',
            });
        }

        defaultValue.payment_provider = paymentProvider;

        if (data.payment_settings) {
            if (data.payment_settings.paytm) {
                for (const [key, value] of Object.entries(data.payment_settings.paytm)) {
                    if (key === 'PAYTM_MERCHANT_KEY') {
                        defaultValue.paytm_merchent_key = value;
                    } else if (!defaultValue.paytm_merchent_key) {
                        defaultValue.paytm_merchent_key = '';
                    }
                    if (key === 'PAYTM_MERCHANT_ID') {
                        defaultValue.paytm_merchent_id = value;
                    } else if (!defaultValue.paytm_merchent_id) {
                        defaultValue.paytm_merchent_id = '';
                    }
                    if (key === 'PAYTM_CHILD_MERCHANT_ID') {
                        defaultValue.paytm_child_merchent_id = value;
                    } else if (!defaultValue.paytm_child_merchent_id) {
                        defaultValue.paytm_child_merchent_id = '';
                    }
                    if (key === 'PAYTM_INDUSTRY_TYPE_ID') {
                        defaultValue.paytm_industry_type_id = value;
                    } else if (!defaultValue.paytm_industry_type_id) {
                        defaultValue.paytm_industry_type_id = '';
                    }
                    if (key === 'PAYTM_WEBSITE') {
                        defaultValue.paytm_website = value;
                    } else if (!defaultValue.paytm_website) {
                        defaultValue.paytm_website = '';
                    }
                    if (key === 'PAYTM_BASE_API_URL') {
                        defaultValue.paytm_base_api_url = value;
                    } else if (!defaultValue.paytm_base_api_url) {
                        defaultValue.paytm_base_api_url = '';
                    }
                }
            }

            if (!data.payment_settings?.paytm?.hasOwnProperty('PAYTM_CHILD_MERCHANT_ID')) {
                defaultValue.paytm_child_merchent_id = '';
            }
            if (data.payment_settings.stripe) {
                for (const [key, value] of Object.entries(data.payment_settings.stripe)) {
                    if (key === 'STRIPE_KEY') {
                        defaultValue.stripe_key = value;
                    } else if (!defaultValue.stripe_key) {
                        defaultValue.stripe_key = '';
                    }
                    if (key === 'STRIPE_SECRET') {
                        defaultValue.stripe_secret = value;
                    } else if (!defaultValue.stripe_secret) {
                        defaultValue.stripe_secret = '';
                    }
                    if (key === 'STRIPE_WEBHOOK') {
                        defaultValue.stripe_webhook = value;
                    } else if (!defaultValue.stripe_webhook) {
                        defaultValue.stripe_webhook = '';
                    }
                    if (key === 'STRIPE_WEBHOOK_SECRET') {
                        defaultValue.stripe_webhook_secret = value;
                    } else if (!defaultValue.stripe_webhook_secret) {
                        defaultValue.stripe_webhook_secret = '';
                    }
                }
            }
            if (data.payment_settings.ccavenue) {
                for (const [key, value] of Object.entries(data.payment_settings.ccavenue)) {
                    if (key === 'working_key') {
                        defaultValue.ccavenue_working_key = value;
                    } else if (!defaultValue.ccavenue_working_key) {
                        defaultValue.ccavenue_working_key = '';
                    }

                    if (key === 'access_code') {
                        defaultValue.ccavenue_access_code = value;
                    } else if (!defaultValue.ccavenue_access_code) {
                        defaultValue.ccavenue_access_code = '';
                    }

                    if (key === 'merchant_id') {
                        defaultValue.ccavenue_merchant_id = value;
                    } else if (!defaultValue.ccavenue_merchant_id) {
                        defaultValue.ccavenue_merchant_id = '';
                    }

                    if (key === 'url') {
                        defaultValue.ccavenue_url = value;
                    } else if (!defaultValue.ccavenue_url) {
                        defaultValue.ccavenue_url = '';
                    }

                    if (key === 'redirect_url') {
                        defaultValue.ccavenue_redirect_url = value;
                    } else if (!defaultValue.ccavenue_redirect_url) {
                        defaultValue.ccavenue_redirect_url = '';
                    }
                }
            }
        }

        if (paymentProvider.length > 0) {
            // eslint-disable-next-line
            paymentProvider.map((item) => {
                if (item.label === 'paytm') {
                    cardProvider.push({
                        value: 'paytm',
                        inputDisplay: 'Paytm',
                    });
                } else if (item.label === 'stripe') {
                    cardProvider.push({
                        value: 'stripe',
                        inputDisplay: 'Stripe',
                    });
                } else {
                    cardProvider.push({
                        value: 'ccavenue',
                        inputDisplay: 'ccavenue',
                    });
                }
                return null;
            });
        }

        defaultValue.cardProvider = cardProvider;
        defaultValue.credit_card = cardProvider.length === 1 ? cardProvider[0].value : null;

        return defaultValue;
    }, []);

    const { defaultValues, setDefaultValues, onSuccessCallback } = useOutletFormValues({ updateFormValues });

    let currencyDataSymbol = [];
    let currencyDataCode = [];
    for (const key in currencyData) {
        let value1 = currencyData[key];
        currencyDataSymbol.push({
            value: value1.symbol_native,
            label: `${value1.name} - ${value1.symbol} - ${value1.symbol_native} `,
        });
    }

    for (const key in currencyData) {
        let value1 = currencyData[key];
        currencyDataCode.push({
            value: value1.code,
            label: value1.code,
        });
    }

    const onChangePaymentProvider = (selectedProvider) => {
        setValue('payment_provider', selectedProvider);
        let paymentOptions = [];

        selectedProvider.map((item) => {
            if (item.label === 'paytm') {
                paymentOptions.push({
                    value: 'paytm',
                    inputDisplay: 'Paytm',
                });
            }
            if (item.label === 'stripe') {
                paymentOptions.push({
                    value: 'stripe',
                    inputDisplay: 'Stripe',
                });
            }
            if (item.label === 'ccavenue') {
                paymentOptions.push({
                    value: 'ccavenue',
                    inputDisplay: 'ccavenue',
                });
            }

            return null;
        });

        const data = paymentOptions.length > 0 ? paymentOptions[0].value : 'paytm';

        setValue('credit_card', data);
        watch('cardProvider', paymentOptions);
    };

    const onFormSaveApi = useCallback(
        async (data) => {
            const paytm = data.payment_provider.find((item) => {
                if (item.label === 'paytm') {
                    return 'paytm';
                }
                return null;
            });
            const stripe = data.payment_provider.find((item) => {
                if (item.label === 'stripe') {
                    return 'stripe';
                }
                return null;
            });
            const ccAvenue = data.payment_provider.find((item) => {
                if (item.label === 'ccavenue') {
                    return 'ccavenue';
                }
                return null;
            });

            let payment_settings = {};
            if (paytm) {
                if (
                    watch()?.paytm_child_merchent_id !== '' &&
                    watch()?.paytm_merchent_key !== '' &&
                    watch()?.paytm_merchent_id !== '' &&
                    watch()?.paytm_industry_type_id !== '' &&
                    watch()?.paytm_website !== '' &&
                    watch()?.paytm_base_api_url !== '' &&
                    paytm.label === 'paytm'
                ) {
                    payment_settings.paytm = {
                        PAYTM_MERCHANT_KEY: data.paytm_merchent_key,
                        PAYTM_MERCHANT_ID: data.paytm_merchent_id,
                        PAYTM_INDUSTRY_TYPE_ID: data.paytm_industry_type_id,
                        PAYTM_WEBSITE: data.paytm_website,
                        PAYTM_BASE_API_URL: data.paytm_base_api_url,
                        PAYTM_CHILD_MERCHANT_ID: data.paytm_child_merchent_id,
                    };
                } else if (
                    watch()?.paytm_merchent_key === '' &&
                    watch()?.paytm_merchent_id === '' &&
                    watch()?.paytm_industry_type_id === '' &&
                    watch()?.paytm_website === '' &&
                    watch()?.paytm_base_api_url === '' &&
                    watch()?.paytm_child_merchent_id !== ''
                ) {
                    payment_settings.paytm = {
                        PAYTM_CHILD_MERCHANT_ID: data.paytm_child_merchent_id,
                    };
                } else {
                    payment_settings.paytm = {
                        PAYTM_MERCHANT_KEY: data.paytm_merchent_key,
                        PAYTM_MERCHANT_ID: data.paytm_merchent_id,
                        PAYTM_INDUSTRY_TYPE_ID: data.paytm_industry_type_id,
                        PAYTM_WEBSITE: data.paytm_website,
                        PAYTM_BASE_API_URL: data.paytm_base_api_url,
                    };
                }
            }
            if (stripe) {
                if (stripe.label === 'stripe') {
                    payment_settings.stripe = {
                        STRIPE_KEY: data.stripe_key,
                        STRIPE_SECRET: data.stripe_secret,
                        STRIPE_WEBHOOK: data.stripe_webhook,
                        STRIPE_WEBHOOK_SECRET: data.stripe_webhook_secret,
                    };
                }
            }

            if (ccAvenue) {
                if (ccAvenue.label === 'ccavenue') {
                    payment_settings.ccavenue = {
                        working_key: data.ccavenue_working_key,
                        access_code: data.ccavenue_access_code,
                        merchant_id: data.ccavenue_merchant_id,
                        url: data.ccavenue_url,
                        redirect_url: data.ccavenue_redirect_url,
                    };
                }
            }

            data.payment_settings = payment_settings;

            Object.entries(data).forEach(([key, value]) => {
                if (key !== 'payment_settings') {
                    delete data[key];
                }
            });

            console.log('data ==>', data);

            const response = await dispatch(outletSettingsAPI('payment_settings', data));
            if (response.success) setDefaultValues(updateFormValues(response.outlet));
            return response;
        },
        [dispatch, updateFormValues]
    );

    const { control, errors, watch, setValue } = useFormActionsHandler({
        onFormSaveApi,
        defaultValues,
        onSuccessCallback,
    });

    return (
        <EuiForm component='form'>
            <FormGroupDescription
                title={restaurantDescriptions?.select_a_payment_gateway?.display_name}
                description={restaurantDescriptions?.select_a_payment_gateway?.description}
            >
                <ComboBoxField
                    options={payment_provider_options}
                    selectedOptions={watch('payment_provider') ? watch('payment_provider') : payment_provider_options}
                    onChange={onChangePaymentProvider}
                    errors={errors}
                    label='Select payment provider'
                    name='payment_provider'
                    placeholder='Select one or more payment provider'
                    rules={{
                        validate: (value) =>
                            (watch('payment_provider') && watch('payment_provider').length > 0) ||
                            'Please select atleast one payment provider',
                    }}
                    control={control}
                />
            </FormGroupDescription>

            <EuiFormRow fullWidth>
                <EuiFlexGroup direction='column'>
                    {watch('payment_provider') &&
                        watch('payment_provider').map((item, index) => {
                            if (item.label === 'paytm') {
                                return (
                                    <EuiFlexItem
                                        key={index}
                                        style={{
                                            width: '100%',
                                            minWidth: '40%',
                                            flex: '0 1 40%',
                                        }}
                                    >
                                        <FormGroupDescription
                                            title={restaurantDescriptions?.paytm?.display_name}
                                            description={restaurantDescriptions?.paytm?.description}
                                        >
                                            <TextField
                                                errors={errors}
                                                label='Paytm merchent key'
                                                name='paytm_merchent_key'
                                                placeholder='Please enter paytm merchent key'
                                                rules={{
                                                    required: !watch('paytm_child_merchent_id')
                                                        ? 'Please enter paytm merchent key'
                                                        : false,
                                                }}
                                                control={control}
                                            />
                                            <TextField
                                                errors={errors}
                                                label='Paytm merchent id'
                                                name='paytm_merchent_id'
                                                placeholder='Please enter paytm merchent id'
                                                rules={{
                                                    required: !watch('paytm_child_merchent_id')
                                                        ? 'Please enter paytm merchent key'
                                                        : false,
                                                }}
                                                control={control}
                                            />
                                            <TextField
                                                errors={errors}
                                                label='Paytm child merchent id'
                                                name='paytm_child_merchent_id'
                                                placeholder='Please enter paytm child merchent id'
                                                rules={{
                                                    required:
                                                        watch('paytm_merchent_key') &&
                                                        watch('paytm_merchent_id') &&
                                                        watch('paytm_industry_type_id') &&
                                                        watch('paytm_website') &&
                                                        watch('paytm_base_api_url')
                                                            ? false
                                                            : 'Please Enter Paytm child id',
                                                }}
                                                control={control}
                                            />
                                            <TextField
                                                errors={errors}
                                                label='Paytm industry type id'
                                                name='paytm_industry_type_id'
                                                placeholder='Please enter paytm industry type id'
                                                rules={{
                                                    required: !watch('paytm_child_merchent_id')
                                                        ? 'Please enter paytm merchent key'
                                                        : false,
                                                }}
                                                control={control}
                                            />
                                            <TextField
                                                errors={errors}
                                                label='Paytm website'
                                                name='paytm_website'
                                                placeholder='Please enter paytm website'
                                                rules={{
                                                    required: !watch('paytm_child_merchent_id')
                                                        ? 'Please enter paytm merchent key'
                                                        : false,
                                                }}
                                                control={control}
                                            />
                                            <TextField
                                                errors={errors}
                                                label='Paytm base api url'
                                                name='paytm_base_api_url'
                                                placeholder='Please enter paytm base api url'
                                                rules={{
                                                    required: !watch('paytm_child_merchent_id')
                                                        ? 'Please enter paytm merchent key'
                                                        : false,
                                                }}
                                                control={control}
                                            />
                                        </FormGroupDescription>
                                    </EuiFlexItem>
                                );
                            }
                            if (item.label === 'stripe') {
                                return (
                                    <EuiFlexItem
                                        key={`${index}9876`}
                                        style={{
                                            width: '100%',
                                            minWidth: '40%',
                                            flex: '0 1 40%',
                                        }}
                                    >
                                        <FormGroupDescription
                                            title={restaurantDescriptions?.stripe?.display_name}
                                            description={restaurantDescriptions?.stripe?.description}
                                        >
                                            <TextField
                                                errors={errors}
                                                label='Stripe key'
                                                name='stripe_key'
                                                placeholder='Please enter stripe key'
                                                rules={{ required: 'Please enter stripe key' }}
                                                control={control}
                                            />
                                            <TextField
                                                errors={errors}
                                                label='Stripe secret'
                                                name='stripe_secret'
                                                placeholder='Please enter stripe secret'
                                                rules={{ required: 'Please enter stripe secret' }}
                                                control={control}
                                            />
                                            <TextField
                                                errors={errors}
                                                label='Stripe webhook'
                                                name='stripe_webhook'
                                                placeholder='Please enter stripe webhook'
                                                rules={{ required: 'Please enter stripe webhook' }}
                                                control={control}
                                            />
                                            <TextField
                                                errors={errors}
                                                label='Stripe webhook secret'
                                                name='stripe_webhook_secret'
                                                placeholder='Please enter webhook secret'
                                                rules={{ required: 'Please enter webhook secret' }}
                                                control={control}
                                            />
                                        </FormGroupDescription>
                                    </EuiFlexItem>
                                );
                            }
                            if (item.label === 'ccavenue') {
                                return (
                                    <EuiFlexItem
                                        key={`${index}ccavenue`}
                                        style={{
                                            width: '100%',
                                            minWidth: '40%',
                                            flex: '0 1 40%',
                                        }}
                                    >
                                        <FormGroupDescription
                                            title={'CCAvenue'}
                                            description={'Enter your CCAvenue payment gateway API details'}
                                        >
                                            <TextField
                                                errors={errors}
                                                label='Working Key'
                                                name='ccavenue_working_key'
                                                placeholder='Please enter working key'
                                                rules={{ required: 'Please Enter working key' }}
                                                control={control}
                                            />
                                            <TextField
                                                errors={errors}
                                                label='Access code'
                                                name='ccavenue_access_code'
                                                placeholder='Please enter access code'
                                                rules={{ required: 'Please enter access code' }}
                                                control={control}
                                            />
                                            <TextField
                                                errors={errors}
                                                label='Merchant id'
                                                name='ccavenue_merchant_id'
                                                placeholder='Please enter merchant id'
                                                rules={{ required: 'Please enter merchant id' }}
                                                control={control}
                                            />
                                            <TextField
                                                errors={errors}
                                                label='URL'
                                                name='ccavenue_url'
                                                placeholder='Please enter URL'
                                                rules={{ required: 'Please enter URL' }}
                                                control={control}
                                            />
                                            <TextField
                                                errors={errors}
                                                label='Redirect URL'
                                                name='ccavenue_redirect_url'
                                                placeholder='Please enter redirect URL'
                                                rules={{ required: 'Please enter redirect URL' }}
                                                control={control}
                                            />
                                        </FormGroupDescription>
                                    </EuiFlexItem>
                                );
                            }
                            return null;
                        })}
                </EuiFlexGroup>
            </EuiFormRow>
        </EuiForm>
    );
};

export default PaymentSetting;
