import React, { useCallback, useEffect, useState } from 'react';
import { EuiForm } from '@elastic/eui';
import TextField from '../../../components/Form/TextField';
import FormGroupDescription from '../../../components/formGroupDescription';
import { useDispatch, useSelector } from 'react-redux';
import { restaurantSettingsAPI } from '../../../api/restaurantSettings/restaurantSettingsAPI';
import useFormActionsHandler from '../../../hooks/useFormActionsHandler';

const TwoFactorRestaurantSettings = (props) => {
    const { restaurantResponse, fetchRestaurant } = props;
    const dispatch = useDispatch();
    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);

    const [defaultValues, setDefaultValues] = useState({});

    const updateFormValues = useCallback((data) => {
        let defaultValue = {};
        defaultValue.two_factor_api_key = data?.settings.two_factor_api_key ? data.settings.two_factor_api_key : '';

        return defaultValue;
    }, []);

    useEffect(() => {
        setDefaultValues(updateFormValues(restaurantResponse.restaurant));
    }, [restaurantResponse.restaurant, updateFormValues]);

    const onFormSaveApi = useCallback(
        async (data) => {
            const response = await dispatch(restaurantSettingsAPI('two_factor_settings', data));
            if (response.success) {
                fetchRestaurant();
            } else {
                alert(response.message);
            }

            if (response.success) setDefaultValues(updateFormValues(response.restaurant));
            return response;
        },
        [dispatch, fetchRestaurant, updateFormValues]
    );

    const { errors, control, watch } = useFormActionsHandler({ onFormSaveApi, defaultValues });

    return (
        <>
            <EuiForm component='form'>
                <FormGroupDescription
                    title={restaurantDescriptions?.['2_factor_sms_gateway']?.display_name}
                    description={restaurantDescriptions?.['2_factor_sms_gateway']?.description}
                >
                    <TextField
                        errors={errors}
                        label='2Factor SMS API key'
                        name='two_factor_api_key'
                        placeholder='Enter 2Factor SMS API key'
                        // rules={{ required: 'Please Enter Two factor api key' }}
                        control={control}
                        watch={watch}
                    />
                </FormGroupDescription>
            </EuiForm>
        </>
    );
};

export default React.memo(TwoFactorRestaurantSettings);
