import API from '../axios/API';

export const copyItemList = (items) => {
    return async () => {
        let response;

        try {
            response = await API.patch(
                `${process.env.REACT_APP_BASE_URL_V3}/restaurants/:restaurantId/copy-items`,
                items
            );
        } catch (error) {
            response = error;
        }

        return response;
    };
};
