import React, { useEffect, useState } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiButton, EuiLoadingContent, EuiText, EuiSpacer } from '@elastic/eui';
import API from '../../api/axios/API';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { getLiveOrders } from '../../api/liveOrders/getLiveOrderNewAPI';
import { getLiveOrderDetailsAPI } from '../../api/liveOrders/getLiveOrderDetailsAPI';

export const PorterRiderTab = (props) => {
    const { order, onOrderStatusChange } = props;
    const [priceEstimate, setPriceEstimate] = useState({});
    const dispatch = useDispatch();
    const [porterError, setPorterError] = useState({});

    const tdStyle = {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    };

    const porterCostEstimate = async () => {
        let porterResponse;
        try {
            porterResponse = await API.get(
                `/restaurants/:restaurantId/porter/get-order-estimation-time?orderId=${props.orderId}`
            );
        } catch (error) {
            porterResponse = error;
        }

        if (porterResponse.success) {
            setPriceEstimate(porterResponse);
        } else {
            setPorterError({});
            alert(porterResponse.message);
        }
    };

    useEffect(() => {
        porterCostEstimate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const assignPorterRider = async () => {
        let porterResponse = '';
        props.setRiderModalOpen();
        try {
            porterResponse = await API.post(`/restaurants/:restaurantId/porter/assign-order `, {
                orderId: props.orderId,
            });
        } catch (error) {
            porterResponse = error;
        }

        if (porterResponse.success) {
            dispatch(getLiveOrders());
            dispatch(getLiveOrderDetailsAPI());
        } else {
            alert(porterResponse.message);
        }
    };

    const getMinuteEstimation = async (vehicles) => {
        let minuteEstimationCost = vehicles[0]?.fare?.minor_amount;
        let minuteEstimation = vehicles[0]?.eta?.value;
        vehicles.map((vehicle) => {
            if (minuteEstimation > vehicle.fare.minor_amount) {
                minuteEstimationCost = vehicle.fare?.minor_amount;
                minuteEstimation = vehicle.eta?.value;
            }
        });

        return minuteEstimation;
    };

    return (
        <EuiFlexGroup direction='column'>
            <EuiFlexItem>
                {!isEmpty(priceEstimate) ? (
                    <>
                        {priceEstimate?.porter_order_estimation?.vehicles ? (
                            <EuiFlexItem>
                                <b>porter Estimates</b>
                                <table style={{ marginTop: '10px' }}>
                                    <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                        <td style={{ borderRight: '1px solid #D3DAE6' }}>Estimated Delivery Cost</td>

                                        <td style={tdStyle}>
                                            <td style={tdStyle}>
                                                {`₹${parseFloat(
                                                    Object.values(
                                                        priceEstimate.porter_order_estimation?.vehicles
                                                    ).reduce(
                                                        (t, { fare }) => Math.min(t, fare.minor_amount),
                                                        Infinity
                                                    ) / 100
                                                ).toFixed(2)}`}
                                            </td>
                                        </td>
                                    </tr>
                                </table>
                            </EuiFlexItem>
                        ) : (
                            <EuiFlexItem>
                                <b>Porter Estimates</b>
                                <table style={{ marginTop: '10px' }}>
                                    <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                        <td style={{ borderRight: '1px solid #D3DAE6' }}>Estimated Delivery Cost</td>

                                        <td style={tdStyle}>
                                            {priceEstimate.porter_order_estimation?.vehicles[0].fare.minor_amount
                                                ? `₹${parseFloat(
                                                      priceEstimate.porter_order_estimation?.vehicles[0].fare
                                                          .minor_amount
                                                  ).toFixed(2)}`
                                                : ''}
                                        </td>
                                    </tr>
                                </table>
                            </EuiFlexItem>
                        )}
                    </>
                ) : (
                    <>{isEmpty(porterError) ? <EuiLoadingContent lines={5} /> : null}</>
                )}
                {!isEmpty(porterError) ? (
                    <EuiFlexItem style={{ maxWidth: 320 }}>
                        <EuiSpacer size='s' />
                        <EuiText color='warning'>{porterError.title}</EuiText>
                        <EuiText style={{}} size='s' color='warning'>
                            {porterError.body}
                        </EuiText>
                    </EuiFlexItem>
                ) : null}
            </EuiFlexItem>
            <EuiFlexItem style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <EuiButton disabled={!isEmpty(porterError)} fullWidth={true} fill onClick={assignPorterRider}>
                    Assign Porter Rider
                </EuiButton>
            </EuiFlexItem>
        </EuiFlexGroup>
    );
};
