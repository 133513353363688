import React, { useEffect, useState } from 'react';
import { EuiFormRow, EuiButtonGroup } from '@elastic/eui';
import { Controller } from 'react-hook-form';

const ButtonGroup = (props) => {
    const { label, name, control, idSelected, legend } = props;
    const [options, setOptions] = useState([
        {
            id: '1',
            label: 'Enable',
            iconType: 'check',
        },
        {
            id: '0',
            label: 'Disable',
            iconType: 'cross',
        },
    ]);

    useEffect(() => {
        let innerOptions = [...options];
        if (idSelected === '1') {
            innerOptions[0].label = 'Enabled';
            innerOptions[1].label = 'Disable';
        } else {
            innerOptions[1].label = 'Disabled';
            innerOptions[0].label = 'Enable';
        }
        setOptions(innerOptions);
    }, [idSelected]);

    return (
        <>
            <Controller
                render={({ field }) => (
                    <EuiFormRow label={label}>
                        <EuiButtonGroup
                            options={options}
                            idSelected={field?.value?.toString()}
                            onChange={field.onChange}
                            // (id) => onChangeButton(id)
                            color='primary'
                            value={idSelected}
                            legend={legend}
                        />
                    </EuiFormRow>
                )}
                name={name}
                control={control}
            />
        </>
    );
};

export default ButtonGroup;
