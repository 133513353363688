import API from '../axios/API';

export const updateCreditAlerts = () => async (dispatch) => {
    let res;
    try {
        res = await API.get(`restaurants/:restaurantId/low-credit-alert`);
        dispatch({ type: 'get_credit_alerts', payload: res });
    } catch (error) {
        console.log('error credits', error);
    }
};
