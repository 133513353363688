import React, { useCallback, memo } from 'react';
import { EuiRadioGroup, EuiFormRow, EuiForm, EuiSpacer } from '@elastic/eui';
import { Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import FormGroupDescription from '../../../components/formGroupDescription';
import useFormActionsHandler from '../../../hooks/useFormActionsHandler';
import { outletSettingsAPI } from '../../../api/outlet/outletSettingsAPI';
import useOutletFormValues from '../Hooks/useOutletFormValues';
import TextField from '../../../components/Form/TextField';

const gst_applicable = [
    { id: 'get_no', label: 'No' },
    { id: 'get_yes', label: 'Yes' },
];

const OutletTaxes = () => {
    const dispatch = useDispatch();
    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);

    const updateFormValues = useCallback((data) => {
        return {
            gst_applicable: data?.settings?.gst_applicable ? true : false,
            gst_number: data?.settings?.gst_number,
        };
    }, []);

    const { defaultValues, setDefaultValues, onSuccessCallback } = useOutletFormValues({ updateFormValues });

    const onFormSaveApi = useCallback(
        async (data) => {
            const response = await dispatch(outletSettingsAPI('taxes', data));
            if (response.success) setDefaultValues(updateFormValues(response.outlet));
            return response;
        },
        [dispatch, updateFormValues]
    );

    const { errors, control, watch, setValue } = useFormActionsHandler({
        onFormSaveApi,
        defaultValues,
        onSuccessCallback,
    });

    return (
        <EuiForm component='form'>
            <FormGroupDescription
                title={restaurantDescriptions?.taxes?.display_name}
                description={restaurantDescriptions?.taxes?.description}
            >
                <EuiFormRow isInvalid={errors.gst_applicable} fullWidth={true}>
                    <Controller
                        render={({}) => (
                            <EuiRadioGroup
                                options={gst_applicable}
                                idSelected={watch('gst_applicable') ? 'get_yes' : 'get_no'}
                                onChange={(id) =>
                                    setValue('gst_applicable', id === 'get_no' ? false : true, {
                                        shouldDirty: true,
                                    })
                                }
                                legend={{
                                    children: <span> Do you charge goods and service tax ? </span>,
                                }}
                            />
                        )}
                        name='gst_applicable'
                        control={control}
                        fullWidth={true}
                    />
                </EuiFormRow>

                {watch('gst_applicable') && (
                    <>
                        <TextField
                            errors={errors}
                            label='Goods and service tax number'
                            name='gst_number'
                            placeholder='Please enter goods and service tax number'
                            rules={{ required: 'Please enter goods and service tax number' }}
                            control={control}
                        />
                        <EuiSpacer />
                    </>
                )}
            </FormGroupDescription>
        </EuiForm>
    );
};

export default memo(OutletTaxes);
