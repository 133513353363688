import React from 'react';
import { MainLayout } from '../../components/layout/mainLayout/mainLayout';
import RiderDetails from '../../components/riders/riderDetails';

const RiderDetailsPage = () => {
    return (
        <MainLayout>
            <RiderDetails />
        </MainLayout>
    );
};

export default RiderDetailsPage;
