import React, { useState } from 'react';
import { EuiFlexItem, EuiSwitch, EuiButton } from '@elastic/eui';
import { NotificationToggle } from '../notificationToggle';
import FormGroupDescription from '../formGroupDescription';
import { RestaurantSelection } from '../restaurant';
import { LanguageDropDown } from '../languageDropDown';
import { useDispatch, useSelector } from 'react-redux';
import { capitalize } from '../../helper/capitalize';
import { logoutCall } from '../../helper/logout';
import { useHistory } from 'react-router-dom';
import NotificationLoopSound from '../notificationLoopSound/';

const DeviceSettings = () => {
    const history = useHistory();
    const themeMode = useSelector((state) => state.theme.theme);
    const [checked, setChecked] = useState(themeMode === 'dark');
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);

    const logout = async () => {
        await logoutCall(auth.token);
        history.push('/login');
    };

    const onChange = (e) => {
        setChecked(e.target.checked);
        if (themeMode === 'light') {
            dispatch({ type: 'set-theme', payload: 'dark' });
        } else if (themeMode === 'dark') {
            dispatch({ type: 'set-theme', payload: 'light' });
        }
        window.location.reload();
    };

    return (
        <EuiFlexItem>
            <NotificationToggle />

            <FormGroupDescription title='Restaurant selection' description=''>
                <RestaurantSelection />
            </FormGroupDescription>

            <FormGroupDescription title='Language selection' description=''>
                <LanguageDropDown />
            </FormGroupDescription>

            <NotificationLoopSound />

            <FormGroupDescription title='Theme selection' description=''>
                <EuiFlexItem grow={5}>
                    <EuiSwitch
                        label={capitalize(themeMode.toString())}
                        checked={checked}
                        onChange={(e) => onChange(e)}
                    />
                </EuiFlexItem>
            </FormGroupDescription>

            <FormGroupDescription title='Logout' description=''>
                <EuiFlexItem grow={5}>
                    <EuiButton size='s' fill onClick={() => logout()}>
                        Log out
                    </EuiButton>
                </EuiFlexItem>
            </FormGroupDescription>
        </EuiFlexItem>
    );
};

export default DeviceSettings;
