import React, { useCallback, useEffect, useState } from 'react';
import { EuiForm } from '@elastic/eui';
import FormGroupDescription from '../../../components/formGroupDescription';
import TextField from '../../../components/Form/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { restaurantSettingsAPI } from '../../../api/restaurantSettings/restaurantSettingsAPI';
import { toastsErrorMessage } from '../../../utils/toasts';
import useFormActionsHandler from '../../../hooks/useFormActionsHandler';

const GoogleMapsRestaurantSettings = (props) => {
    const { restaurantResponse, fetchRestaurant } = props;

    const dispatch = useDispatch();
    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);
    const [defaultValues, setDefaultValues] = useState({});

    const updateFormValues = useCallback((data) => {
        let defaultValue = {};
        defaultValue.google_maps_api_key = data?.settings?.google_maps_api_key ? data.settings.google_maps_api_key : '';

        return defaultValue;
    }, []);

    useEffect(() => {
        setDefaultValues(updateFormValues(restaurantResponse.restaurant));
    }, [restaurantResponse.restaurant, updateFormValues]);

    const onFormSaveApi = useCallback(
        async (data) => {
            const response = await dispatch(restaurantSettingsAPI('google_maps_settings', data));
            if (response.success) {
                fetchRestaurant();
            } else {
                toastsErrorMessage(response?.message, dispatch);
            }

            if (response.success) setDefaultValues(updateFormValues(response.restaurant));
            return response;
        },
        [dispatch, fetchRestaurant, updateFormValues]
    );

    const { errors, control, watch } = useFormActionsHandler({ onFormSaveApi, defaultValues });

    return (
        <>
            <EuiForm component='form'>
                <FormGroupDescription
                    title={restaurantDescriptions?.google_maps?.display_name}
                    description={restaurantDescriptions?.google_maps?.description}
                >
                    <TextField
                        errors={errors}
                        label='Google API key'
                        name='google_maps_api_key'
                        placeholder='Enter Google maps API key'
                        control={control}
                        watch={watch}
                    />
                </FormGroupDescription>
            </EuiForm>
        </>
    );
};

export default React.memo(GoogleMapsRestaurantSettings);
