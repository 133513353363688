import APIV3 from '../../../../api/axios/APIV3';

export const fetchVariantsOptions = async (outletId, itemId) => {
    let response;

    try {
        response = await APIV3.get(
            `/restaurants/:restaurantId/outlets/${outletId}/items/${itemId}/variant-options/stock`
        );
    } catch (error) {
        response = {
            isEror: error,
        };
    }

    return response;
};
