import API from '../../../api/axios/API';

export const fetchOfferDetials = async (offerId) => {
    let response = null;

    try {
        response = API.get(`restaurants/:restaurantId/offers/${offerId}`);
    } catch (error) {
        response = error;
    }

    return response;
};
