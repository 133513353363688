import React, { useState, useEffect } from 'react';
import { EuiDragDropContext, euiDragDropReorder, EuiDroppable } from '@elastic/eui';
import { useSelector } from 'react-redux';
import CategoryRow from './categoryRow';
import { getFlatToTreeStructureNew } from '../../../helper/getFlatToTreeStructureNew';
import {
    fetchOutletCatalogue,
    fetchRestaurantCatalogue,
    updateCategorySequence,
    updateCategorySequenceForOutlet,
} from './Api/FetchCatalogueInfo';
import LoadingOverlay from 'react-loading-overlay';
import { SET_RESTAURANT_CATALOGUE } from '../Store/MenuTypes';
import { useDispatch } from 'react-redux';
import { toastsErrorMessage } from '../../../utils/toasts';

const CategoryChildRow = (props) => {
    const { category, isOutletMenu } = props;
    const categories = useSelector((state) => state.menuReducer.restaurantCategories);
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);
    const languageId = useSelector((state) => state.language.languageId);

    const [childCategories, setChildCategories] = useState(
        categories.filter((caetgoryItem) => caetgoryItem.parent_id === category.category_id)
    );

    const [loaderOverlay, setLoaderOverlay] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        setChildCategories(categories.filter((caetgoryItem) => caetgoryItem.parent_id === category.category_id));
    }, [categories, category]);

    const onChildCategoryDragEnd = ({ source, destination }) => {
        const newReorderedCategories = euiDragDropReorder(childCategories, source.index, destination.index);
        let treeStructuredCategories = getFlatToTreeStructureNew(categories);

        for (let treeStructuredCategory of treeStructuredCategories) {
            if (category.category_id === treeStructuredCategory.category_id) {
                treeStructuredCategory.sub_categories = newReorderedCategories;
            }
        }

        if (source && destination) {
            if (!isOutletMenu) {
                setLoaderOverlay(true);
                updateCategorySequence(treeStructuredCategories).then((res) => {
                    if (res.success) {
                        fetchRestaurantCatalogue(languageId).then((response) => {
                            dispatch({
                                type: SET_RESTAURANT_CATALOGUE,
                                payload: response.categories,
                            });
                        });
                        setLoaderOverlay(false);
                    } else {
                        setLoaderOverlay(false);
                        toastsErrorMessage(`Error in sequence change`, dispatch);
                    }
                });
            } else {
                // outletCategorySequenceChange
                setLoaderOverlay(true);
                updateCategorySequenceForOutlet(selectedOutletId, treeStructuredCategories).then((res) => {
                    if (res.success) {
                        fetchOutletCatalogue(selectedOutletId, languageId).then((response) => {
                            dispatch({
                                type: SET_RESTAURANT_CATALOGUE,
                                payload: response.categories,
                            });
                        });
                        setLoaderOverlay(false);
                    } else {
                        setLoaderOverlay(false);
                        toastsErrorMessage(`Error in sequence change`, dispatch);
                    }
                });
            }
        }
    };

    return (
        <>
            <EuiDragDropContext onDragEnd={onChildCategoryDragEnd}>
                <LoadingOverlay active={loaderOverlay} spinner text='Re-arranging sequence'>
                    <EuiDroppable droppableId='DROPPABLE_AREA_FOR_SUBCATEGORIES'>
                        {childCategories.map((subCategory, index) => (
                            <CategoryRow
                                key={subCategory.category_id}
                                isOutletMenu={isOutletMenu}
                                category={subCategory}
                                index={index}
                            />
                        ))}
                    </EuiDroppable>
                </LoadingOverlay>
            </EuiDragDropContext>
        </>
    );
};

export default CategoryChildRow;
