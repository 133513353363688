import React from 'react';
import { EuiFlexItem, EuiText, EuiButtonIcon, EuiDraggable, EuiPanel, EuiIcon } from '@elastic/eui';

const UpSellingItemCard = ({ disabled, itemList, onItemDelete }) => {
    return (
        <>
            {itemList?.map((item, index) => {
                return (
                    <EuiDraggable
                        spacing='m'
                        key={index}
                        index={index}
                        draggableId={index.toString()}
                        customDragHandle={true}
                    >
                        {(provided) => (
                            <EuiPanel className='custom' paddingSize='m' style={{ width: '280px', height: 'auto' }}>
                                {!disabled && (
                                    <EuiFlexItem grow={false} style={{ justifyContent: 'center' }}>
                                        <div {...provided.dragHandleProps}>
                                            <EuiIcon type='grab' />
                                        </div>
                                    </EuiFlexItem>
                                )}

                                <EuiFlexItem grow={1} style={{ flexDirection: 'row' }}>
                                    <EuiFlexItem>
                                        <EuiText>
                                            <h4>{item.internal_name}</h4>
                                        </EuiText>
                                    </EuiFlexItem>
                                    {!disabled && (
                                        <>
                                            <EuiButtonIcon
                                                aria-label='trash'
                                                color={'danger'}
                                                // style={{ height: '0px' }}
                                                onClick={() => onItemDelete(index, item)}
                                                iconType='trash'
                                            />
                                        </>
                                    )}
                                </EuiFlexItem>
                            </EuiPanel>
                        )}
                    </EuiDraggable>
                );
            })}
        </>
    );
};

export default React.memo(UpSellingItemCard);
