import React from 'react';
import { EuiFlexItem } from '@elastic/eui';
import { useSelector } from 'react-redux';
import CategoryList from './CategoryList copy';
import './style.css';
import CataloguePanelHeader from '../../../modules/Menu/Components/Catalogue/CataloguePanelHeader';

const MenuList = () => {
    const isOutletMenu = useSelector((state) => state.menuReducer.isOutletMenu);

    return (
        <EuiFlexItem>
            <CataloguePanelHeader panelTitle='Menu' />

            <CategoryList isOutletMenu={isOutletMenu} />
        </EuiFlexItem>
    );
};

export default MenuList;
