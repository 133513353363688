import API from '../axios/API';

export const downloadReportsData = (outletString, startDate, endDate, sort, direction, type) => {
    return async () => {
        const min = 10000;
        const max = 99999;
        const uniqueNumber = Math.floor(Math.random() * (max - min + 1)) + min;

        const formattedStartDate = startDate.format('YYYY-MM-DD');
        const formattedEndDate = endDate.format('YYYY-MM-DD');
        let response;
        try {
            const link = document.createElement('a');
            link.target = '_blank';
            link.download = type ? `${formattedStartDate} ${formattedEndDate} ${type} ${uniqueNumber}` : 'sales';
            response = await API(
                `restaurants/:restaurantId/summary/download?${outletString}&start_date=${formattedStartDate}&end_date=${formattedEndDate}&sort_by=${sort}&sort_direction=${direction}&type=${type}`,
                { responseType: 'blob' }
            ).then((res) => {
                link.href = URL.createObjectURL(new Blob([res], { type: 'text/csv' }));
                link.click();
            });
        } catch (error) {
            response = error;
        }
        return response;
    };
};
