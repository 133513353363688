import { SET_SECTION_LIST, SET_TABLE_ORDER_DETAILS, SET_ACTIVE_TABLE_ID } from '../types/tableManagement';

const initialState = {
    sectionList: [],
    tableOrderDetails: {},
    activeTableId: null,
};

export default function tableManagement(state = initialState, action) {
    switch (action.type) {
        case SET_SECTION_LIST:
            return {
                ...state,
                sectionList: action.payload,
            };
        case SET_TABLE_ORDER_DETAILS:
            return {
                ...state,
                tableOrderDetails: action.payload,
            };
        case SET_ACTIVE_TABLE_ID:
            return {
                ...state,
                activeTableId: action.payload,
            };
        default:
            return state;
    }
}
