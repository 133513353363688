import API from '../axios/API';

export const moveItemList = (items) => {
    return async () => {
        let response;

        try {
            response = await API.patch(
                `${process.env.REACT_APP_BASE_URL_V4}/restaurants/:restaurantId/move-items`,
                items
            );
        } catch (error) {
            response = error;
        }
        return response;
    };
};
