import { GET_OUTLET_ORDERING_MODES, SET_OUTLET_DATA, SET_SELECTED_OUTLET } from '../../reduxStore/types/outlet';
import API from '../axios/API';

export const getOutletDetails = (outletId, languageId) => async (dispatch) => {
    let response = null;
    try {
        response = await API.get(`${process.env.REACT_APP_BASE_URL_V4}/restaurants/:restaurantId/outlets/${outletId}`);

        dispatch({
            type: SET_OUTLET_DATA,
            payload: { selectedOutlet: response.outlet },
        });

        dispatch({
            type: SET_SELECTED_OUTLET,
            payload: {
                selectedOutlet: {
                    value: response.outlet.outlet_id,
                    inputDisplay: response.outlet.translations.name[languageId],
                    outlet_data: response.outlet,
                },
            },
        });
        dispatch({
            type: GET_OUTLET_ORDERING_MODES,
            payload: response.outlet,
        });
    } catch (error) {
        //TODO handle error
        console.error('getOutletDetails', error);
    }

    return response;
};
