import React, { useCallback, useEffect, useState } from 'react';
import { EuiForm } from '@elastic/eui';
import TextField from '../../../components/Form/TextField';
import NumberField from '../../../components/Form/NumberField';
import ButtonGroup from '../../../components/Form/ButtonGroup';
import FormGroupDescription from '../../../components/formGroupDescription';
import { useDispatch, useSelector } from 'react-redux';
import { restaurantSettingsAPI } from '../../../api/restaurantSettings/restaurantSettingsAPI';
import useFormActionsHandler from '../../../hooks/useFormActionsHandler';

const AppSettings = (props) => {
    const { restaurantResponse, fetchRestaurant } = props;
    const [defaultValues, setDefaultValues] = useState({});

    const dispatch = useDispatch();
    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);
    const availableLanguages = useSelector((state) => state.language.availableLanguages);

    const updateFormValues = useCallback((data) => {
        let defaultValue = {};

        defaultValue.name = data.name;
        defaultValue.app_name = data.settings && data.settings.app_name;
        defaultValue.apply_mask = data.settings.apply_mask;
        defaultValue.logo_height = data.settings ? data.settings.logo_height : null;
        defaultValue.is_customer_email_required = data.settings.is_customer_email_required;
        defaultValue.repeat_usage_time = data.settings.repeat_usage_time;

        defaultValue.languages = data.languages.map((item, index) => ({
            label: item.language_label,
            language: item.language,
            language_label: item.language_label,
        }));

        let obj = {};

        watch('languages')?.forEach((language) => {
            if (language.language_label != 'English') {
                obj[language.language] = '';
            }
        });

        let translationObject = {
            restaurant_name: obj,
        };

        for (const language_label in data.translations.restaurant_name) {
            translationObject.restaurant_name[language_label] = data.translations.restaurant_name[language_label];
        }

        defaultValue.translations = translationObject;

        return defaultValue;
    }, []);

    useEffect(() => {
        setDefaultValues(updateFormValues(restaurantResponse.restaurant));
    }, [restaurantResponse.restaurant, updateFormValues]);

    const onFormSaveApi = useCallback(
        async (data) => {
            if (data.translations) {
                availableLanguages.map((language) => {
                    if (language.language_label === 'English') {
                        data.translations.restaurant_name[language.language] = data.name;
                    }
                });
            } else {
                data.translations = { restaurant_name: { en: data.name } };
            }

            const response = await dispatch(restaurantSettingsAPI('app_settings', data));
            if (response.success) {
                fetchRestaurant();
            } else {
                alert(response.message);
            }

            if (response.success) setDefaultValues(updateFormValues(response.restaurant));
            return response;
        },
        [dispatch, updateFormValues]
    );

    const { errors, control, watch, setValue } = useFormActionsHandler({
        onFormSaveApi: onFormSaveApi,
        defaultValues: defaultValues,
    });

    return (
        <>
            <EuiForm component='form'>
                <FormGroupDescription
                    title={restaurantDescriptions?.name_of_restaurant?.display_name}
                    description={restaurantDescriptions?.name_of_restaurant?.description}
                >
                    <TextField
                        errors={errors}
                        name='name'
                        placeholder='Restaurant name: English'
                        rules={{ required: 'Please enter name' }}
                        control={control}
                        watch={watch}
                    />
                    {watch('languages') &&
                        watch('languages').length > 0 &&
                        watch('languages').map((item, index) => (
                            <>
                                {item.language_label != 'English' ? (
                                    <TextField
                                        errors={errors}
                                        isInvalid={
                                            errors.translations &&
                                            errors.translations.restaurant_name &&
                                            errors.translations.restaurant_name.hasOwnProperty(item.language)
                                        }
                                        error={
                                            errors.translations &&
                                            errors.translations.restaurant_name &&
                                            errors.translations.restaurant_name.hasOwnProperty(item.language) &&
                                            errors.translations.restaurant_name[item.language]
                                                ? errors.translations.restaurant_name[item.language].message
                                                : ''
                                        }
                                        // label={index === 0 ? <strong>Restaurant name</strong> : ''}
                                        name={`translations.restaurant_name.${item.language}`}
                                        placeholder={`Restaurant name: ${item.language_label} `}
                                        rules={{ required: 'Please write restaurant name ' }}
                                        control={control}
                                        watch={watch}
                                        rows={4}
                                    />
                                ) : (
                                    ''
                                )}
                            </>
                        ))}
                </FormGroupDescription>
                <FormGroupDescription
                    title={restaurantDescriptions?.application_display_name?.display_name}
                    description={restaurantDescriptions?.application_display_name?.description}
                >
                    <TextField
                        errors={errors}
                        // label='Application display name'
                        name='app_name'
                        placeholder='Enter the display name of the application'
                        rules={{ required: 'Please Enter your application name' }}
                        control={control}
                        watch={watch}
                    />
                </FormGroupDescription>
                <FormGroupDescription
                    title={restaurantDescriptions?.customer_email_compulsory?.display_name}
                    description={restaurantDescriptions?.customer_email_compulsory?.description}
                >
                    <ButtonGroup
                        idSelected={watch('is_customer_email_required') ? '1' : '0'}
                        color='primary'
                        control={control}
                        setValue={setValue}
                        name='is_customer_email_required'
                        watch={watch}
                    />
                </FormGroupDescription>
                <FormGroupDescription
                    title={restaurantDescriptions?.apply_mask_on_category_images?.display_name}
                    description={restaurantDescriptions?.apply_mask_on_category_images?.description}
                >
                    <ButtonGroup
                        idSelected={watch('apply_mask') ? '1' : '0'}
                        color='primary'
                        control={control}
                        setValue={setValue}
                        name='apply_mask'
                        watch={watch}
                    />
                </FormGroupDescription>
                <FormGroupDescription
                    title={restaurantDescriptions?.logo_height?.display_name}
                    description={restaurantDescriptions?.logo_height?.description}
                >
                    <NumberField
                        errors={errors}
                        name='logo_height'
                        placeholder='Please Enter logo height'
                        control={control}
                    />
                </FormGroupDescription>
                <FormGroupDescription
                    title={restaurantDescriptions?.offer_usage_time?.display_name}
                    description={restaurantDescriptions?.offer_usage_time?.description}
                >
                    <NumberField
                        errors={errors}
                        name='repeat_usage_time'
                        placeholder='Please Enter usage time'
                        control={control}
                        prepend='Mins'
                    />
                </FormGroupDescription>
            </EuiForm>
        </>
    );
};

export default AppSettings;
