import { EuiRadioGroup } from '@elastic/eui';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const RadioGroupField = ({ options, idSelected, onChange, legend, name, defaultValue, disabled }) => {
    const { control } = useFormContext();
    return (
        <Controller
            render={() => (
                <EuiRadioGroup
                    options={options}
                    idSelected={idSelected}
                    onChange={onChange}
                    legend={{
                        children: <span>{legend}</span>,
                    }}
                    disabled={disabled}
                />
            )}
            name={name}
            defaultValue={defaultValue}
            control={control}
            fullWidth={true}
        />
    );
};

export default RadioGroupField;
