import { EuiButton, EuiFlexItem } from '@elastic/eui';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import AddRiderForm from './AddRiderForm';
import { userHasPermission } from '../../../helper/auth';

const AddRiderAction = ({ setRefetch }) => {
    const permissions = useSelector((state) => state.permission.permission);
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);

    const dispatch = useDispatch();

    const showFlyoutCallback = () => {
        setIsFlyoutVisible(true);
    };

    const closeFlyout = () => {
        setIsFlyoutVisible(false);
        dispatch({
            type: 'set-edit-rider-id',
            payload: {
                edit_rider_id: null,
            },
        });
    };

    return (
        <>
            {isFlyoutVisible && <AddRiderForm setRefetch={setRefetch} closeFlyout={closeFlyout} />}
            <EuiFlexItem
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginBottom: '10px' }}
            >
                {userHasPermission(permissions, '/riders', 'write') && selectedOutletId && (
                    <EuiButton fill iconType='plusInCircleFilled' onClick={showFlyoutCallback}>
                        Add Rider
                    </EuiButton>
                )}
            </EuiFlexItem>
        </>
    );
};

export default AddRiderAction;
