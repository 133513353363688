import React, { useEffect, useState } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiButtonEmpty, EuiPanel, EuiIcon } from '@elastic/eui';
import { useSelector } from 'react-redux';
import * as typesOrder from '../../reduxStore/types/orders';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PastOrderCsvDownload } from '../pastOrderCsv';
import '../liveOrders/style.css';
import LiveOrdersTableForPast from '../liveOrders/liveOrdersTableForPast';
import LiveOrderDetailsForPast from '../liveOrders/liveOrderDetailsPastOrders';
import CustomDatePicker from '../UIComponent/CustomDatePicker';

export const CustomerOrderList = (props) => {
    const [startDateTime, setStartDateTime] = useState('');
    const [endDateTime, setEndDateTime] = useState('');
    const [csvState, setCsvState] = useState(false);
    const [state, setState] = useState({
        apiDataLoaded: false,
        last_page: 0,
        current_page: 0,
        loaderPage: false,
        totalCounts: 0,
        page_size: 10,
    });
    const dispatch = useDispatch();
    const customerOrderDetails = useSelector((state) => state.customer.customerOrderDetails);
    const selectedOrderId = useSelector((state) => state.orders.selectedOrderId);
    const history = useHistory();

    const filteredOrder = customerOrderDetails.filter((data) => data.order_id === selectedOrderId);

    useEffect(() => {
        (() => {
            if (filteredOrder.length < 1 && customerOrderDetails.length > 0) {
                dispatch({
                    type: typesOrder.SET_SELECTED_ORDER,
                    payload: customerOrderDetails[0].order_id,
                });
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredOrder]);

    return (
        <>
            <EuiFlexGroup justifyContent='spaceBetween' alignItems='center'>
                <EuiFlexItem style={{ alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row' }}>
                    <EuiIcon
                        style={{
                            padding: 5,
                            border: '1px solid black',
                            borderRadius: '50%',
                            marginRight: '10px',
                            background: 'white',
                        }}
                        onClick={() => history.goBack()}
                        type='arrowLeft'
                        size='xl'
                    />
                    <CustomDatePicker
                        startDate={startDateTime}
                        setStartDate={setStartDateTime}
                        endDate={endDateTime}
                        setEndDate={setEndDateTime}
                        displayFormat='DD-MM-YYYY'
                        startDatePlaceholder='Start Date'
                        endDatePlaceholder='End Date'
                    />
                </EuiFlexItem>
                <EuiFlexItem style={{ flexDirection: 'row-reverse' }}>
                    <EuiButtonEmpty onClick={() => setCsvState(true)}>Download CSV</EuiButtonEmpty>
                </EuiFlexItem>

                {csvState && <PastOrderCsvDownload orders={customerOrderDetails} csvStateCallback={setCsvState} />}
            </EuiFlexGroup>
            <EuiSpacer />
            <EuiFlexGroup direction={props.isOrderFlyout ? 'column' : 'row'}>
                <EuiFlexItem grow={6}>
                    <EuiPanel paddingSize='s' className='customerOrderPanel'>
                        <LiveOrdersTableForPast
                            startDateTime={startDateTime}
                            endDateTime={endDateTime}
                            state={state}
                            setState={setState}
                            isCustomerOrder={true}
                        />
                    </EuiPanel>
                </EuiFlexItem>
                <EuiFlexItem grow={4}>
                    <div>
                        <EuiPanel paddingSize='s' className='customerOrderPanel'>
                            <LiveOrderDetailsForPast isCustomerOrder={true} />
                        </EuiPanel>
                    </div>
                </EuiFlexItem>
            </EuiFlexGroup>
        </>
    );
};
