import React from 'react';
import { EuiStat, EuiFlexItem, EuiFlexGroup, EuiPanel } from '@elastic/eui';
const RiderInformation = ({ riderDetails }) => {
    return (
        <div>
            <EuiFlexGroup>
                <EuiFlexItem>
                    <EuiPanel>
                        <EuiStat title={riderDetails.rider_order_details.rider_order_count} description='Order' />
                    </EuiPanel>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiPanel>
                        <EuiStat
                            title={riderDetails.rider_order_details.total_distance}
                            description='Killometer Traveller'
                        />
                    </EuiPanel>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiPanel>
                        <EuiStat title={riderDetails.rider_order_details.total_earning} description='Total earn' />
                    </EuiPanel>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiPanel>
                        <EuiFlexGroup>
                            <EuiFlexItem>
                                <EuiStat
                                    title={riderDetails.rider_order_details.normal_earning}
                                    description='Normal earn'
                                />
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <EuiStat
                                    title={riderDetails.rider_order_details.extra_earning}
                                    description='Extra earn'
                                />
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </EuiPanel>
                </EuiFlexItem>
            </EuiFlexGroup>
        </div>
    );
};

export default RiderInformation;
