import { EuiIcon } from '@elastic/eui';
import React from 'react';

const filterOptions = [
    { value: 'name', inputDisplay: 'Name' },
    { value: 'order_count', inputDisplay: 'Total order count' },
    { value: 'first_order', inputDisplay: 'First order date' },
    { value: 'last_order', inputDisplay: 'Last order date' },
    { value: 'join_date', inputDisplay: 'Join date' },
    { value: 'life_time_value', inputDisplay: 'Life time value' },
    { value: 'order_ratings', inputDisplay: 'Order ratings' },
    { value: 'delivery_ratings', inputDisplay: 'Delivery Ratings' },
];

const filterDirection = [
    {
        value: 'asc',
        inputDisplay: (
            <div>
                <span>Ascending</span> <EuiIcon type='sortUp' />
            </div>
        ),
    },
    {
        value: 'desc',
        inputDisplay: (
            <div>
                <span>Descending</span> <EuiIcon type='sortDown' />
            </div>
        ),
    },
];

export { filterOptions, filterDirection };
