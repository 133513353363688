import React, { useCallback, useEffect, useRef, useState } from 'react';
import { EuiDragDropContext, EuiDroppable, euiDragDropReorder, EuiFlexItem, EuiSpacer } from '@elastic/eui';
import { useDispatch, useSelector } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import CategoryRow from './categoryRow';
import './style.css';
import {
    fetchOutletCatalogue,
    fetchRestaurantCatalogue,
    updateCategorySequence,
    updateCategorySequenceForOutlet,
} from './Api/FetchCatalogueInfo';
import { SET_RESTAURANT_CATALOGUE } from '../Store/MenuTypes';
import useSingleScreenHeightCalc from '../../../hooks/useSingleScreenHeightCalc';

LoadingOverlay.propTypes = undefined;

const CategoryList = ({ isOutletMenu }) => {
    const dispatch = useDispatch();
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);
    const languageId = useSelector((state) => state.language.languageId);
    const categories = useSelector((state) => state.menuReducer.restaurantCategories);
    const [loaderOverlay, setLoaderOverlay] = useState(false);
    const { screenHeight } = useSingleScreenHeightCalc(400);
    const filteredCategories = categories.filter((category) => category.parent_id === null);
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [visibleCategories, setVisibleCategories] = useState([]);
    const listRef = useRef(null);

    const onCategoryDragEnd = ({ source, destination }, currentCategory = null) => {
        if (source && destination) {
            let reorderedCategories = euiDragDropReorder(
                categories.filter((category) => {
                    return category.parent_id === null;
                }),
                source.index,
                destination.index
            );

            if (!isOutletMenu) {
                setLoaderOverlay(true);
                updateCategorySequence(reorderedCategories).then((res) => {
                    if (res.success) {
                        fetchRestaurantCatalogue(languageId).then((response) => {
                            dispatch({
                                type: SET_RESTAURANT_CATALOGUE,
                                payload: response.categories,
                            });
                        });

                        setLoaderOverlay(false);
                    } else {
                        setLoaderOverlay(false);
                        alert('Error in sequence change');
                    }
                });
            } else {
                // outletCategorySequenceChange
                setLoaderOverlay(true);
                updateCategorySequenceForOutlet(selectedOutletId, reorderedCategories).then((res) => {
                    if (res.success) {
                        fetchOutletCatalogue(selectedOutletId, languageId).then((response) => {
                            dispatch({
                                type: SET_RESTAURANT_CATALOGUE,
                                payload: response.categories,
                            });
                        });
                        setLoaderOverlay(false);
                    } else {
                        setLoaderOverlay(false);
                        alert('Error in sequence change');
                    }
                });
            }
        }
    };

    const handleScroll = useCallback(() => {
        if (listRef.current) {
            const { scrollTop, clientHeight } = listRef.current;

            // Check if the user is near the bottom of the list
            if (scrollTop + clientHeight >= listRef.current.scrollHeight - 100) {
                const newPage = currentPage + 1;
                const newVisibleCategories = categories.slice(0, newPage * itemsPerPage);
                setVisibleCategories(newVisibleCategories);
                setCurrentPage(newPage);
            }

            // Check if the user is near the top of the list
            if (scrollTop === 0 && currentPage > 1) {
                const newPage = currentPage - 1;
                const newVisibleCategories = categories.slice(0, newPage * itemsPerPage);
                setVisibleCategories(newVisibleCategories);
                setCurrentPage(newPage);
            }
        }
    }, [currentPage, categories]);

    useEffect(() => {
        if (listRef.current) {
            listRef.current.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (listRef.current) {
                listRef.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, [handleScroll]);

    useEffect(() => {
        // Initial load of data
        const initialVisibleCategories = filteredCategories.slice(0, currentPage * itemsPerPage);
        setVisibleCategories(initialVisibleCategories);
    }, [categories, currentPage]);
    return (
        <EuiFlexItem>
            <EuiDragDropContext onDragEnd={(dragProp) => onCategoryDragEnd(dragProp)}>
                <LoadingOverlay active={loaderOverlay} spinner text='Re-arranging sequence'>
                    <EuiDroppable
                        style={{ height: screenHeight }}
                        // className=' scrollbar-sm'
                        droppableId='DROPPABLE_AREA_CATEGORY_LIST'
                    >
                        <div ref={listRef} style={{ height: screenHeight, overflowY: 'auto' }}>
                            {visibleCategories.map((category, index) => (
                                <CategoryRow
                                    key={category.category_id}
                                    isOutletMenu={isOutletMenu}
                                    index={index}
                                    category={category}
                                />
                            ))}
                        </div>

                        {/* {categories
                            .filter((category) => {
                                return category.parent_id === null;
                            })
                            .map((category, index) => {
                                return (
                                    <CategoryRow
                                        key={category.category_id}
                                        isOutletMenu={isOutletMenu}
                                        index={index}
                                        category={category}
                                    />
                                );
                            })} */}
                        <EuiSpacer size='s' />
                    </EuiDroppable>
                </LoadingOverlay>
            </EuiDragDropContext>
        </EuiFlexItem>
    );
};

export default CategoryList;
