import React, { useCallback, useEffect, useState } from 'react';
import { EuiForm } from '@elastic/eui';
import FormGroupDescription from '../../../components/formGroupDescription';
import ComboBoxField from '../../../components/Form/ComboBoxField';
import { languages } from '../../../components/settings/allLanguages';
import { useDispatch, useSelector } from 'react-redux';
import { restaurantSettingsAPI } from '../../../api/restaurantSettings/restaurantSettingsAPI';
import useFormActionsHandler from '../../../hooks/useFormActionsHandler';

const RestaurantLanguageSettings = (props) => {
    const { restaurantResponse, fetchRestaurant } = props;

    const availableLanguage = languages;
    const dispatch = useDispatch();
    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);

    const [defaultValues, setDefaultValues] = useState({});

    const updateFormValues = useCallback(
        (data) => {
            let defaultValue = {};
            let restaurantData = restaurantResponse.restaurant;
            defaultValue.languages = restaurantData.languages.map((item, index) => ({
                label: item.language_label,
                language: item.language,
                language_label: item.language_label,
            }));

            return defaultValue;
        },
        [restaurantResponse.restaurant]
    );

    useEffect(() => {
        setDefaultValues(updateFormValues(restaurantResponse.restaurant));
    }, [restaurantResponse.restaurant, updateFormValues]);

    const onFormSaveApi = useCallback(
        async (data) => {
            const response = await dispatch(restaurantSettingsAPI('language_settings', data));
            if (response.success) {
                fetchRestaurant();
            } else {
                alert(response.message);
            }

            if (response.success) setDefaultValues(updateFormValues(response.restaurant));
            return response;
        },
        [dispatch, fetchRestaurant, updateFormValues]
    );

    const { errors, control, watch, setValue } = useFormActionsHandler({ onFormSaveApi, defaultValues });

    const onChangeLanguage = (selectedLanguage) => {
        setValue('languages', selectedLanguage);
    };

    return (
        <EuiForm component='form'>
            <FormGroupDescription
                title={restaurantDescriptions?.languages_in_app?.display_name}
                description={restaurantDescriptions?.languages_in_app?.description}
            >
                <ComboBoxField
                    options={availableLanguage}
                    selectedOptions={watch('languages') ? watch('languages') : []}
                    onChange={onChangeLanguage}
                    errors={errors}
                    label='Select languages'
                    name='languages'
                    placeholder='Select one or more languages for the application'
                    rules={{
                        validate: (value) =>
                            (watch('languages') && watch('languages').length > 0) ||
                            'Please select atleast one language',
                    }}
                    control={control}
                />
            </FormGroupDescription>
        </EuiForm>
    );
};

export default RestaurantLanguageSettings;
