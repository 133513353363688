import {
    EuiPanel,
    EuiFlexGroup,
    EuiFlexItem,
    EuiText,
    EuiDragDropContext,
    EuiDroppable,
    EuiDraggable,
    EuiIcon,
} from '@elastic/eui/';
import React from 'react';
import { useSelector } from 'react-redux';
import { userHasPermission } from '../../../helper/auth';

const RestaurantSubCategoryList = (props) => {
    const { item } = props;
    const permissions = useSelector((state) => state.permission.permission);

    const onDragEnd = ({ source, destination }) => {
        if (source && destination) {
        }
    };

    return (
        <>
            <EuiDragDropContext onDragEnd={onDragEnd}>
                <EuiDroppable
                    style={{ minHeigth: '0px' }}
                    className='outleLinkStyleForItem'
                    droppableId='CUSTOM_HANDLE_DROPPABLE_SUB_CATEGORY'
                    spacing='m'
                >
                    {item.sub_categories.map((item, idx) => (
                        <EuiDraggable
                            spacing='m'
                            key={item?.category_id?.toString()}
                            index={idx}
                            draggableId={item?.category_id?.toString()}
                            customDragHandle={true}
                        >
                            {(provided) => (
                                <EuiPanel className='custom' paddingSize='m'>
                                    <EuiFlexGroup>
                                        <EuiFlexItem
                                            grow={false}
                                            style={{
                                                justifyContent: 'center',
                                            }}
                                        >
                                            {userHasPermission(permissions, '/menu', 'write') && (
                                                <div {...provided.dragHandleProps}>
                                                    <EuiIcon type='grab' />
                                                </div>
                                            )}
                                        </EuiFlexItem>

                                        <EuiFlexItem
                                            style={{
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <EuiText> {item.internal_name} </EuiText>
                                        </EuiFlexItem>
                                    </EuiFlexGroup>
                                </EuiPanel>
                            )}
                        </EuiDraggable>
                    ))}
                </EuiDroppable>
            </EuiDragDropContext>
        </>
    );
};

export default RestaurantSubCategoryList;
