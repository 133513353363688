import { EuiFlexItem } from '@elastic/eui';
import React, { useState } from 'react';
import UploadButton from '../../../components/UIComponent/ImageSelection/UploadButton';
import UploadImageDropZone from './UploadImageDropZone';
import UploadImageDropZoneNotification from './UploadImageDropZoneNotification';

const UploadMedia = ({ fetchImages, selectedTab, aspectRatio }) => {
    const [showUploadImageModal, setShowUploadImageModal] = useState(false);
    const handleUploadImageModalToggle = () => {
        setShowUploadImageModal((prevState) => !prevState);
    };
    const condition = false;

    return (
        <>
            {showUploadImageModal ? (
                selectedTab === 'notifications' ? (
                    <UploadImageDropZoneNotification
                        condition={condition}
                        imageType={selectedTab}
                        handleUploadImageModalToggle={handleUploadImageModalToggle}
                        fetchImages={fetchImages}
                        aspectRatio={aspectRatio}
                    />
                ) : (
                    <UploadImageDropZone
                        condition={condition}
                        fetchImages={fetchImages}
                        imageType={selectedTab}
                        handleUploadImageModalToggle={handleUploadImageModalToggle}
                        aspectRatio={aspectRatio ? aspectRatio : aspectRatio == null ? null : 1}
                    />
                )
            ) : null}
            <EuiFlexItem grow={false}>
                <UploadButton handleUploadImageModalToggle={handleUploadImageModalToggle} />
            </EuiFlexItem>
        </>
    );
};

export default UploadMedia;
