import APIV3 from '../../../../api/axios/APIV3';

export const fetchSearchedItems = async ({ query, pageNumber, outlet_id, category_id }) => {
    let url = `restaurants/:restaurantId/items?keyword=${query}&per_page=10&page=${pageNumber}`;

    if (outlet_id) {
        url += `&outlet_id=${outlet_id}`;
    }

    if (category_id) {
        url += `&category_id=${category_id}`;
    }

    const response = await APIV3.get(url);

    return response;
};
