import React, { useState } from 'react';
import { EuiPopover, EuiIcon, EuiButtonEmpty } from '@elastic/eui';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import { useSelector } from 'react-redux';

export const EmojiPicker = (props) => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const theme = useSelector((state) => state.theme.theme);

    const onButtonClick = () => setIsPopoverOpen((isPopoverOpen) => !isPopoverOpen);
    const closePopover = () => setIsPopoverOpen(false);

    const button = (
        <EuiButtonEmpty role='button' size='xs' onClick={onButtonClick} aria-label='dropdown'>
            <EuiIcon type='faceHappy' />
        </EuiButtonEmpty>
    );

    const onSelectEmoji = (emoji) => {
        props.onSelectEmoji(emoji);
        closePopover();
    };
    return (
        <>
            <EuiPopover button={button} isOpen={isPopoverOpen} closePopover={closePopover}>
                <Picker set='apple' onSelect={onSelectEmoji} theme={theme} />
            </EuiPopover>
        </>
    );
};
