export const popOverItems = (onChangeRowSize) => {
    return [
        {
            key: '10 rows',
            onClick: (closePopover) => {
                closePopover();
                onChangeRowSize(10);
            },
            iconSize: 10,
        },
        {
            key: '20 rows',
            onClick: (closePopover) => {
                closePopover();
                onChangeRowSize(20);
            },
            iconSize: 20,
        },
        {
            key: '50 rows',
            onClick: (closePopover) => {
                closePopover();
                onChangeRowSize(50);
            },
            iconSize: 50,
        },
        {
            key: '100 rows',
            onClick: (closePopover) => {
                closePopover();
                onChangeRowSize(100);
            },
            iconSize: 100,
        },
    ];
};
