import {
    EuiComboBox,
    EuiFormRow,
    EuiSpacer,
    EuiTextColor,
    EuiFlexItem,
    EuiText,
    EuiFlexGroup,
    EuiLink,
} from '@elastic/eui';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { autoSuggestionChoices } from '../utils/autoSuggestionItems';

const AddonChoiceOrderingModes = ({
    orderingMode,
    addonGroupId,
    modeIndex,
    choiceIndex,
    groupName,
    setSelectedChoices,
    petpoojaAddonChoices,
    choice,
    selectedItemForChoices,
    selectedAddonForChoices,
}) => {
    const {
        formState: { errors, isDirty },
        control,
        watch,
        setValue,
    } = useFormContext();
    const [errorMessageCheck, setErrorMessageCheck] = useState('');
    const choiceName = `addon_${addonGroupId}[0].addOnChoices[${choiceIndex}].orderingModes[${modeIndex}].selectedChoice`;
    const linkedItems = useSelector((state) => state.petPoojaReducer.petPoojaLinkedAddons);
    const selectedOutlet = useSelector((state) => state.outlet.selectedOutlet);

    const [suggestionChoices, setSuggestionItems] = useState([]);

    const isGroupSelected = useWatch({
        control,
        name: groupName,
    });

    useEffect(() => {
        if (!isDirty) {
            setErrorMessageCheck([]);
        }
    }, [isDirty]);

    useEffect(() => {
        if (!isEmpty(selectedItemForChoices)) {
            const addonChoices = selectedItemForChoices[`group_${addonGroupId}`]?.petPoojaChoices?.length
                ? selectedItemForChoices[`group_${addonGroupId}`]?.petPoojaChoices
                : selectedAddonForChoices;

            const choices = autoSuggestionChoices(orderingMode, choice, addonChoices).choices;
            setSuggestionItems(choices);
        }
    }, [selectedItemForChoices, addonGroupId, choice, isGroupSelected, petpoojaAddonChoices, selectedAddonForChoices]);

    const handleChoiceSelection = useCallback(
        (selectedValue, item) => {
            if (selectedValue?.length) {
                selectedValue[0].choice_id = choice.choice_id;
                selectedValue[0].module_id = choice.choice_id;
                selectedValue[0].restaurant_ordering_mode_id = orderingMode.restaurant_ordering_mode_id;
                selectedValue[0].third_party_module_id = selectedValue[0]?.addonitemid
                    ? selectedValue[0]?.addonitemid
                    : selectedValue[0]?.third_party_module_id
                    ? selectedValue[0].third_party_module_id
                    : selectedValue[0]?.selectedPetpoojaAddonChoice?.addonitemid; // adOnChoices option structure
                // selectedValue[0].third_party_module_id = selectedValue[0].addonitemid;
            } else {
                setErrorMessageCheck([]);
            }
            setValue(choiceName, selectedValue, { shouldDirty: true });
            setSelectedChoices(selectedValue);

            // UNLINK LOGIC FOR  addOnChoices options
            if (!selectedValue?.length && item?.length && linkedItems?.length) {
                const currentRemovedItem = item[0];
                for (const linkedItem of linkedItems) {
                    if (
                        linkedItem.third_party_module_id == currentRemovedItem.third_party_module_id &&
                        linkedItem.restaurant_ordering_mode_id === currentRemovedItem.restaurant_ordering_mode_id &&
                        currentRemovedItem.module_id == linkedItem.module_id
                    ) {
                        if (watch('menu_unlinks')?.length) {
                            setValue('menu_unlinks', [...watch('menu_unlinks'), currentRemovedItem], {
                                shouldDirty: true,
                            });
                        } else {
                            setValue('menu_unlinks', [currentRemovedItem], { shouldDirty: true });
                        }
                    }
                }
            }
            // REMOVE UNLINK ITEMS FROM UNLINK STATE IF LINKED BACK

            if (selectedValue?.length && watch('menu_unlinks')?.length) {
                const removeItemFromUnlinkState = watch('menu_unlinks')?.filter((menu_unlink) => {
                    return !(
                        menu_unlink.third_party_module_id === selectedValue[0].third_party_module_id &&
                        menu_unlink.restaurant_ordering_mode_id === selectedValue[0].restaurant_ordering_mode_id &&
                        selectedValue[0].module_id === menu_unlink.module_id
                    );
                });

                setValue('menu_unlinks', removeItemFromUnlinkState, { shouldDirty: true });
            }
        },
        [orderingMode, setValue, choiceName, setSelectedChoices, linkedItems, watch, choice.choice_id]
    );

    const choiceErrorCheck = useCallback(
        (selectedItem) => {
            if (selectedItem?.length) {
                let petpoojaPrice = selectedItem[0]?.addonitem_price
                    ? selectedItem[0].addonitem_price
                    : selectedItem[0].price;
                if (selectedItem.length && parseInt(orderingMode.price) != parseInt(petpoojaPrice)) {
                    setErrorMessageCheck(`Fab system price ${orderingMode.price} ≠ ${petpoojaPrice} Pet Pooja Price`);
                    return true;
                } else {
                    setErrorMessageCheck([]);
                }
            }
            return false;
        },
        [orderingMode.price]
    );

    const handleRegisterSuggestionAction = useCallback(
        (selectedValue) => {
            if (!isEmpty(selectedValue)) {
                choiceErrorCheck([selectedValue]);
                selectedValue.choice_id = choice.choice_id;
                selectedValue.module_id = choice.choice_id;
                selectedValue.restaurant_ordering_mode_id = orderingMode.restaurant_ordering_mode_id;
                selectedValue.third_party_module_id = selectedValue?.addonitemid
                    ? selectedValue?.addonitemid
                    : selectedValue?.third_party_module_id
                    ? selectedValue.third_party_module_id
                    : selectedValue?.selectedPetpoojaAddonChoice?.addonitemid; // adOnChoices option structure
                // selectedValue[0].third_party_module_id = selectedValue[0].addonitemid;
                setValue(choiceName, [selectedValue], { shouldDirty: true });
            }
        },
        [choice.choice_id, orderingMode.restaurant_ordering_mode_id, setValue, choiceName]
    );

    return (
        <>
            <EuiFormRow
                label={orderingMode.restaurant_ordering_mode_name}
                display={errors.message}
                error={errors[choiceName] ? errors[choiceName]?.message : null}
            >
                <Controller
                    control={control}
                    name={choiceName}
                    rules={{
                        validate: {
                            positive: (selectedItem) => {
                                return choiceErrorCheck(selectedItem) ? errorMessageCheck : null;
                            },
                        },
                    }}
                    render={({ field }) => {
                        return (
                            <EuiComboBox
                                // options={choiceOptions}
                                options={isGroupSelected ? petpoojaAddonChoices : []}
                                placeholder={
                                    isGroupSelected
                                        ? petpoojaAddonChoices.length
                                            ? 'Select choice'
                                            : 'No choices found for this group'
                                        : 'Select group first'
                                }
                                isClearable={true}
                                singleSelection={true}
                                selectedOptions={field.value}
                                // onChange={(value) => handleChoiceSelection(value, field.value)}
                                onChange={(value) => {
                                    field.onChange(value);
                                    handleChoiceSelection(value, field.value);
                                }}
                                onBlur={field.onBlur}
                            />
                        );
                    }}
                />
            </EuiFormRow>
            {suggestionChoices?.length ? (
                <>
                    <EuiSpacer size='s' />
                    <EuiFlexItem grow={false}>
                        <EuiText size='xs' style={{ fontWeight: 600 }}>
                            Suggested Match
                        </EuiText>
                    </EuiFlexItem>
                </>
            ) : null}
            {!isGroupSelected?.length ? (
                <>
                    <EuiFlexGroup style={{ maxWidth: 300 }} wrap>
                        <EuiFlexItem grow={false}>
                            <EuiText size='s' style={{ cursor: 'pointer' }}>
                                Please select group first
                            </EuiText>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                    <EuiSpacer size='xs' />
                </>
            ) : (
                <>
                    {suggestionChoices?.length && isGroupSelected?.length ? (
                        <>
                            <EuiFlexGroup style={{ maxWidth: 300 }} wrap>
                                {suggestionChoices?.map(({ item }, index) => {
                                    return (
                                        <EuiFlexItem
                                            key={index.toString()}
                                            onClick={() => handleRegisterSuggestionAction(item)}
                                            grow={false}
                                        >
                                            <EuiLink style={{ cursor: 'pointer' }} key={index.toString()}>
                                                {item.label} ({orderingMode.price}{' '}
                                                {selectedOutlet.outlet_data?.settings?.currency_code})
                                            </EuiLink>
                                        </EuiFlexItem>
                                    );
                                })}
                            </EuiFlexGroup>
                            <EuiSpacer size='xs' />
                        </>
                    ) : null}
                </>
            )}

            <EuiSpacer size='s' />
            <EuiTextColor color='danger'>{errorMessageCheck}</EuiTextColor>
        </>
    );
};

export default React.memo(AddonChoiceOrderingModes);
