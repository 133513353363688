import { EuiFormRow, EuiRadioGroup, EuiSpacer } from '@elastic/eui';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import NumberField from '../../Form/NumberField';
import SwitchField from '../../Form/SwitchField';

const ConvenienceCharge = () => {
    const {
        setValue,
        control,
        watch,
        formState: { errors },
    } = useFormContext();

    return (
        <React.Fragment>
            <EuiSpacer />
            <SwitchField
                label='Enable/Disable Convenience Charge'
                setValue={setValue}
                control={control}
                name='is_convenience_charge_enable'
            />
            <EuiSpacer />
            {watch('is_convenience_charge_enable') ? (
                <>
                    <Controller
                        control={control}
                        name='bill_payment_convenience_charge_type'
                        rules={{
                            required: 'Please select any option to continue',
                        }}
                        render={({ field }) => (
                            <EuiFormRow
                                isInvalid={errors['bill_payment_convenience_charge_type']}
                                error={
                                    errors['bill_payment_convenience_charge_type']
                                        ? errors['bill_payment_convenience_charge_type'].message
                                        : ''
                                }
                            >
                                <EuiRadioGroup
                                    options={[
                                        { id: 'percentage_charge', label: 'Percentage Charge' },
                                        { id: 'fixed_charge', label: 'Fixed Charge' },
                                    ]}
                                    name='bill_payment_convenience_charge_type'
                                    idSelected={field.value}
                                    onChange={field.onChange}
                                    legend={{
                                        children: <span> {'Select a type of Convenience Charge'} </span>,
                                    }}
                                />
                            </EuiFormRow>
                        )}
                    />
                    <EuiSpacer />
                    {watch('bill_payment_convenience_charge_type') ? (
                        <NumberField
                            errors={errors}
                            label='Convenience Amount'
                            prepend={watch('bill_payment_convenience_charge_type') === 'fixed_charge' ? '₹' : null}
                            append={watch('bill_payment_convenience_charge_type') === 'percentage_charge' ? '%' : null}
                            control={control}
                            name='bill_payment_convenience_charge'
                            placeholder='Enter Convenience Amount'
                            rules={{
                                required: 'Please Enter Convenience Amount',
                            }}
                        />
                    ) : null}
                </>
            ) : null}
        </React.Fragment>
    );
};

export default React.memo(ConvenienceCharge);
