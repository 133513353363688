import React, { useState } from 'react';
import { EuiDescribedFormGroup, EuiCard, EuiText, EuiLink, EuiPopover, EuiFlexItem } from '@elastic/eui';

export const FormGroupDescription = (props) => {
    const { title, description, styles, mode_name = '' } = props;
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const functionCall = () => {
        if (description && description.includes('@@')) {
            let text = '';
            let linkText = '';
            text = description.split('@@');
            linkText = text[1];
            return (
                <EuiFlexItem style={{ display: 'flex', flexDirection: 'row' }}>
                    <EuiText>{text[0]}</EuiText>
                    <EuiPopover
                        button={
                            <EuiText>
                                &nbsp;<EuiLink onClick={() => setIsPopoverOpen(!isPopoverOpen)}>{linkText}</EuiLink>
                                &nbsp;
                            </EuiText>
                        }
                        isOpen={isPopoverOpen}
                        closePopover={() => setIsPopoverOpen(false)}
                    >
                        <EuiFlexItem>
                            <EuiCard
                                style={{ maxHeight: '350px', maxWidth: '350px' }}
                                textAlign='left'
                                // image={PILOTLOGO}
                                image='https://fab-delivery-staging.s3.ap-south-1.amazonaws.com/optimized-images/2d764ee7-1b0a-4e59-abd8-34ac98ce73c9.png'
                                // title="App look for this image"
                            />
                            {/* <iframe src="https://giphy.com/embed/3o6ZtkFhTIbYAarxQs" width="480" height="270" frameBorder="0" class="giphy-embed" allowFullScreen></iframe><p><a href="https://giphy.com/gifs/nyre-3o6ZtkFhTIbYAarxQs">via GIPHY</a></p> */}
                            <EuiText>App look for this image</EuiText>
                        </EuiFlexItem>
                    </EuiPopover>

                    <EuiText>{text[2]}</EuiText>
                </EuiFlexItem>
            );
        } else {
            return (
                <EuiFlexItem style={{ display: 'flex', flexDirection: 'row' }}>
                    <EuiText>{description}</EuiText>
                </EuiFlexItem>
            );
        }
    };
    return (
        <>
            <EuiDescribedFormGroup
                title={
                    <EuiText>
                        <h3>
                            <b>{mode_name ? mode_name : title}</b>
                        </h3>
                    </EuiText>
                }
                description={functionCall()}
            >
                <>{props.children}</>
            </EuiDescribedFormGroup>
        </>
    );
};
