import React, { useState, useEffect, useCallback } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiSideNav } from '@elastic/eui';
import { EuiLoadingContent } from '@elastic/eui';
import { useDispatch, useSelector } from 'react-redux';
import AppSettings from '../Tabs/AppSettings';
import BrandRestaurantSettings from '../Tabs/BrandRestaurantSettings';
import TwoFactorRestaurantSettings from '../Tabs/TwoFactorRestaurantSettings';
import GoogleMapsRestaurantSettings from '../Tabs/GoogleMapsRestaurantSettings';
import RestaurantLanguageSettings from '../Tabs/RestaurantLanguageSettings';
import AboutUs from '../Tabs/AboutUs';
import ContactUs from '../Tabs/ContactUs';
import LegalSettings from '../Tabs/LegalSettings';
import CashbackSettings from '../Tabs/CashbackSettings';
import OrderingModes from '../Tabs/OrderingModes';
import API from '../../../api/axios/API';
import { TOGGLE_UNSAVED_PORTAL } from '../../../reduxStore/types/Global/Global';

const RestaurantSettingsForm = () => {
    const [selectedTab, setSelectedTab] = useState('app_settings');
    const showFormHandlerPortal = useSelector((state) => state.uiPortalStateReducer.portalState.showFormHandlerPortal);
    const themeMode = useSelector((state) => state.theme.theme);
    const dispatch = useDispatch();

    const [restaurant, setRestaurant] = useState({
        isLoading: true,
        error: null,
        restaurantResponse: undefined,
    });
    useEffect(() => {
        fetchRestaurant();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchRestaurant = useCallback(async () => {
        let response;
        try {
            response = await API.get('restaurants/:restaurantId');
        } catch (error) {
            response = error;
        }

        if (response.success) {
            response = {
                ...restaurant,
                isLoading: false,
                restaurantResponse: response,
            };
        } else {
            response = {
                ...restaurant,
                isLoading: false,
                error: true,
            };
        }

        setRestaurant(response);
    }, [restaurant]);

    const onChangeTab = useCallback(
        (id) => {
            if (showFormHandlerPortal) {
                dispatch({
                    type: TOGGLE_UNSAVED_PORTAL,
                    payload: true,
                });
            } else {
                setSelectedTab(id);
            }
        },
        [dispatch, showFormHandlerPortal]
    );

    const sideNav = [
        {
            name: '',
            id: 0,
            items: [
                {
                    id: 'app_settings',
                    name: 'App settings',
                    onClick: () => onChangeTab('app_settings'),
                    isSelected: selectedTab === 'app_settings',
                },
                {
                    id: 'brand',
                    name: 'Brand',
                    onClick: () => onChangeTab('brand'),
                    isSelected: selectedTab === 'brand',
                },
                {
                    id: 'sms_gateway',
                    name: 'SMS gateway',
                    onClick: () => onChangeTab('sms_gateway'),
                    isSelected: selectedTab === 'sms_gateway',
                },
                {
                    id: 'maps',
                    name: 'Maps',
                    onClick: () => onChangeTab('maps'),
                    isSelected: selectedTab === 'maps',
                },
                {
                    id: 'languages',
                    name: 'Languages',
                    onClick: () => onChangeTab('languages'),
                    isSelected: selectedTab === 'languages',
                },
                {
                    id: 'about_us',
                    name: 'About Us',
                    onClick: () => onChangeTab('about_us'),
                    isSelected: selectedTab === 'about_us',
                },
                {
                    id: 'contact_us',
                    name: 'Contact Us',
                    onClick: () => onChangeTab('contact_us'),
                    isSelected: selectedTab === 'contact_us',
                },
                {
                    id: 'legal',
                    name: 'Legal',
                    onClick: () => onChangeTab('legal'),
                    isSelected: selectedTab === 'legal',
                },
                {
                    id: 'reward_settings',
                    name: 'Cashback settings',
                    onClick: () => onChangeTab('reward_settings'),
                    isSelected: selectedTab === 'reward_settings',
                },
                {
                    id: 'ordering_mode',
                    name: 'Ordering Mode',
                    onClick: () => onChangeTab('ordering_mode'),
                    isSelected: selectedTab === 'ordering_mode',
                },
            ],
        },
    ];

    const selectedComponent = useCallback(() => {
        switch (selectedTab) {
            case 'app_settings':
                return (
                    <AppSettings restaurantResponse={restaurant.restaurantResponse} fetchRestaurant={fetchRestaurant} />
                );
            case 'brand':
                return (
                    <BrandRestaurantSettings
                        restaurantResponse={restaurant.restaurantResponse}
                        fetchRestaurant={fetchRestaurant}
                    />
                );
            case 'sms_gateway':
                return (
                    <TwoFactorRestaurantSettings
                        restaurantResponse={restaurant.restaurantResponse}
                        fetchRestaurant={fetchRestaurant}
                    />
                );
            case 'maps':
                return (
                    <GoogleMapsRestaurantSettings
                        restaurantResponse={restaurant.restaurantResponse}
                        fetchRestaurant={fetchRestaurant}
                    />
                );
            case 'languages':
                return (
                    <RestaurantLanguageSettings
                        restaurantResponse={restaurant.restaurantResponse}
                        fetchRestaurant={fetchRestaurant}
                    />
                );
            case 'about_us':
                return <AboutUs restaurantResponse={restaurant.restaurantResponse} fetchRestaurant={fetchRestaurant} />;
            case 'contact_us':
                return (
                    <ContactUs restaurantResponse={restaurant.restaurantResponse} fetchRestaurant={fetchRestaurant} />
                );
            case 'legal':
                return (
                    <LegalSettings
                        restaurantResponse={restaurant.restaurantResponse}
                        fetchRestaurant={fetchRestaurant}
                    />
                );
            case 'reward_settings':
                return (
                    <CashbackSettings
                        restaurantResponse={restaurant.restaurantResponse}
                        fetchRestaurant={fetchRestaurant}
                    />
                );
            case 'ordering_mode':
                return (
                    <OrderingModes
                        restaurantResponse={restaurant.restaurantResponse}
                        fetchRestaurant={fetchRestaurant}
                    />
                );
            default:
                return null;
        }
    }, [fetchRestaurant, restaurant, selectedTab]);

    if (restaurant.isLoading)
        return (
            <EuiFlexGroup>
                <EuiFlexItem grow={2}>
                    <EuiLoadingContent lines={8} />
                </EuiFlexItem>
                <EuiFlexItem grow={10}>
                    <EuiLoadingContent lines={4} />
                </EuiFlexItem>
            </EuiFlexGroup>
        );
    if (restaurant.error) return <h1> Error in fetching Global Settings</h1>;

    return (
        <EuiFlexGroup style={{ display: 'flex', flexDirection: 'row' }}>
            <EuiFlexItem grow={2}>
                <EuiSideNav
                    aria-label='Restaurant settings'
                    mobileTitle='Restaurant navigation title'
                    style={{ backgroundColor: themeMode === 'light' ? '#fafbfd' : '#1a1b20', padding: '0px 15px 15px' }}
                    items={sideNav}
                />
            </EuiFlexItem>
            <div style={{ backgroundColor: '#D3DAE6', width: '1.1px', margin: '0px 10px' }}></div>
            <EuiFlexItem grow={8}>{selectedComponent()}</EuiFlexItem>
        </EuiFlexGroup>
    );
};

export default RestaurantSettingsForm;
