import API from '../axios/API';
import * as types from '../../reduxStore/types/orders';

export const getFailedOrders = (startDateTime, endDateTime, state, orderingMode) => {
    return async (dispatch, getState) => {
        let response;
        let { orders } = getState();

        try {
            if (startDateTime && endDateTime) {
                response = await API.get(
                    `/restaurants/:restaurantId/failed-orders?page=${state.current_page + 1}&per_page=${
                        state.page_size
                    }&start_date=${startDateTime.startOf('day').format('YYYY-MM-DD')}&end_date=${endDateTime
                        .endOf('day')
                        .format('YYYY-MM-DD')}${orderingMode !== 'all' ? `&ordering_mode=${orderingMode}` : ''}`
                );
            } else {
                response = await API.get(
                    `/restaurants/:restaurantId/failed-orders?page=${state.current_page + 1}&per_page=${
                        state.page_size
                    }${orderingMode !== 'all' ? `&ordering_mode=${orderingMode}` : ''}`
                );
            }
        } catch (error) {
            response = error;
        }

        // console.log("failed order response ", response.order_collection.orders.data)
        if (response.success) {
            dispatch({
                type: types.SET_FAILED_ORDERS,
                payload: response.order_collection.orders.data,
            });
        }

        return response;
    };
};
