import React from 'react';
import { EuiFormRow, EuiFieldText } from '@elastic/eui';
import { Controller } from 'react-hook-form';

const FieldArrayTextField = (props) => {
    const { label, name, placeholder, rules, errors, control, isInvalid, error, fullWidth } = props;
    return (
        <>
            <EuiFormRow
                label={label}
                isInvalid={isInvalid}
                error={error ? error : ''}
                fullWidth={fullWidth ? fullWidth : false}
            >
                <Controller
                    {...props}
                    render={({ field }) => <EuiFieldText {...props} value={field.value} onChange={field.onChange} />}
                    name={name}
                    control={control}
                    rules={rules}
                    fullWidth={true}
                    placeholder={placeholder}
                />
            </EuiFormRow>
        </>
    );
};

export default FieldArrayTextField;
