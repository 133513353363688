import { EuiSideNav } from '@elastic/eui';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TOGGLE_UNSAVED_PORTAL } from '../../../reduxStore/types/Global/Global';

const SideNavMenu = ({ selectedTab, setSelectedTab, setShowOutlet }) => {
    const showFormHandlerPortal = useSelector((state) => state.uiPortalStateReducer.portalState.showFormHandlerPortal);
    const [isSideNavOpenOnMobile, setisSideNavOpenOnMobile] = useState(false);
    const dispatch = useDispatch();

    const onChangeTab = useCallback(
        (id) => {
            if (showFormHandlerPortal) {
                dispatch({
                    type: TOGGLE_UNSAVED_PORTAL,
                    payload: true,
                });
            } else {
                setSelectedTab(id);

                if (id === 'item_link' || id === 'addon_link' || id === 'link_catalogue' || id === 'outlet_menu') {
                    setShowOutlet(true);
                } else {
                    setSelectedTab(id);
                    setShowOutlet(false);
                }
            }
        },
        [dispatch, setSelectedTab, setShowOutlet, showFormHandlerPortal]
    );

    const sideNav = useMemo(() => {
        return [
            {
                name: '',
                id: 0,
                items: [
                    {
                        id: 'catalogue',
                        name: 'Catalogue',
                        onClick: () => onChangeTab('catalogue'),
                        isSelected: selectedTab === 'catalogue',
                    },
                    {
                        id: 'link_catalogue',
                        name: 'Link Catalogue',
                        onClick: () => onChangeTab('link_catalogue'),
                        isSelected: selectedTab === 'link_catalogue',
                    },
                    {
                        id: 'outlet_menu',
                        name: 'Outlet Menu',
                        onClick: () => onChangeTab('outlet_menu'),
                        isSelected: selectedTab === 'outlet_menu',
                    },
                    {
                        name: 'Pet Pooja Link',
                        id: 1,
                        forceOpen: true,
                        items: [
                            {
                                id: 'item_link',
                                name: 'Item Link',
                                onClick: () => onChangeTab('item_link'),
                                isSelected: selectedTab === 'item_link',
                            },
                            {
                                id: 'addon_link',
                                name: 'Addon Link',
                                onClick: () => onChangeTab('addon_link'),
                                isSelected: selectedTab === 'addon_link',
                            },
                        ],
                    },
                ],
            },
        ];
    }, [onChangeTab, selectedTab]);

    const toggleOpenOnMobile = () => {
        setisSideNavOpenOnMobile(!isSideNavOpenOnMobile);
    };

    return (
        <EuiSideNav
            aria-label='Menu Navigation'
            mobileTitle='Menu Navigation'
            toggleOpenOnMobile={toggleOpenOnMobile}
            isOpenOnMobile={isSideNavOpenOnMobile}
            items={sideNav}
        />
    );
};

export default React.memo(SideNavMenu);
