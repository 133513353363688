import React, { useCallback } from 'react';
import ComboBoxField from '../../../components/Form/ComboBoxField';
import { currencyData } from '../../../components/settings/countrySymbolAndInforamtion';
import FormGroupDescription from '../../../components/formGroupDescription';
import { EuiForm } from '@elastic/eui';
import { useDispatch, useSelector } from 'react-redux';
import { outletSettingsAPI } from '../../../api/outlet/outletSettingsAPI';
import useOutletFormValues from '../Hooks/useOutletFormValues';
import useFormActionsHandler from '../../../hooks/useFormActionsHandler';

const CurrencySettings = () => {
    const dispatch = useDispatch();

    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);

    const updateFormValues = useCallback((data) => {
        let defaultValue = {};
        defaultValue.currency_symbol = [
            {
                value: data.settings.currency_symbol,
                label: `${data.settings.currency_symbol} - ${data.settings.currency_code}`,
            },
        ];

        return defaultValue;
    }, []);

    const { defaultValues, setDefaultValues, onSuccessCallback } = useOutletFormValues({ updateFormValues });

    let currencyDataSymbol = [];
    // let currencyDataCode = [];

    for (const key in currencyData) {
        let value1 = currencyData[key];
        currencyDataSymbol.push({
            value: value1.symbol_native,
            label: `${value1.name} - ${value1.code} - ${value1.symbol_native} `,
        });
        // - ${value1.symbol}
    }

    // for (const key in currencyData) {
    //     let value1 = currencyData[key];
    //     currencyDataCode.push({
    //         value: value1.code,
    //         label: value1.code,
    //     });
    // }

    const onFormSaveApi = useCallback(
        async (data) => {
            data.currency_code = data.currency_symbol[0].label.split('-')[1].trim();
            data.currency_symbol = data.currency_symbol[0].value;
            const response = await dispatch(outletSettingsAPI('currency_settings', data));
            if (response.success) setDefaultValues(updateFormValues(response.outlet));
            return response;
        },
        [dispatch, updateFormValues]
    );

    const { control, errors, watch, setValue } = useFormActionsHandler({
        onFormSaveApi,
        defaultValues,
        onSuccessCallback,
    });

    return (
        <>
            <EuiForm component='form'>
                <FormGroupDescription
                    title={restaurantDescriptions?.currency?.display_name}
                    description={restaurantDescriptions?.currency?.description}
                >
                    <ComboBoxField
                        options={currencyDataSymbol}
                        selectedOptions={watch('currency_symbol') ? watch('currency_symbol') : []}
                        onChange={(value) => setValue('currency_symbol', value)}
                        errors={errors}
                        label='Currency Symbol'
                        name='currency_symbol'
                        placeholder='Please enter your currency symbol'
                        rules={{
                            validate: (value) =>
                                (watch('currency_symbol') && watch('currency_symbol').length > 0) ||
                                'Please select currency symbol',
                        }}
                        control={control}
                        singleSelection={{ asPlainText: true }}
                    />
                    {/* <ComboBoxField
                        options={currencyDataCode}
                        selectedOptions={watch('currency_code') ? watch('currency_code') : []}
                        onChange={(value) => {
                            setValue('currency_code', value);
                            delete errors['currency_code'];
                        }}
                        errors={errors}
                        label='Currency Code'
                        name='currency_code'
                        placeholder='Please enter your currency code'
                        rules={{
                            validate: (value) =>
                                (watch('currency_code') && watch('currency_code').length > 0) || 'Please select currency code',
                        }}
                        control={control}
                        singleSelection={{ asPlainText: true }}
                    /> */}
                    {/* {
                        isDirty ? (
                            <EuiFormRow>
                                <EuiFlexGroup alignItems="center">
                                    <LoaderButton isLoading={true}/>
                                    <EuiFlexItem>
                                        <EuiButtonEmpty onClick={resetChanges}>Discard changes</EuiButtonEmpty>
                                    </EuiFlexItem>
                                </EuiFlexGroup>
                            </EuiFormRow>
                        ): ''
                    } */}
                </FormGroupDescription>
            </EuiForm>
        </>
    );
};

export default CurrencySettings;
