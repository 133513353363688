import React, { useCallback } from 'react';
import NumberField from '../../../components/Form/NumberField';
import FormGroupDescription from '../../../components/formGroupDescription';
import { EuiForm } from '@elastic/eui';
import { useSelector, useDispatch } from 'react-redux';
import { outletSettingsAPI } from '../../../api/outlet/outletSettingsAPI';
import useOutletFormValues from '../Hooks/useOutletFormValues';
import useFormActionsHandler from '../../../hooks/useFormActionsHandler';

const CODLimit = () => {
    const dispatch = useDispatch();
    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);

    const updateFormValues = useCallback((data) => {
        return {
            cash_payment_limit_on_takeaway_order: data.settings.cash_payment_limit_on_takeaway_order,
            cash_payment_limit_on_dine_in_order: data.settings.cash_payment_limit_on_dine_in_order,
            cash_payment_limit_on_delivery_order: data.settings.cash_payment_limit_on_delivery_order,
        };
    }, []);

    const { defaultValues, setDefaultValues, onSuccessCallback } = useOutletFormValues({ updateFormValues });

    const onFormSaveApi = useCallback(
        async (data) => {
            const response = await dispatch(outletSettingsAPI('order_configuration', data));
            if (response.success) setDefaultValues(updateFormValues(response.outlet));
            return response;
        },
        [dispatch, updateFormValues, setDefaultValues]
    );

    const { errors, control } = useFormActionsHandler({ onFormSaveApi, defaultValues, onSuccessCallback });

    return (
        <EuiForm component='form' style={{ marginTop: 20 }}>
            <FormGroupDescription
                title={restaurantDescriptions.cash_payment_limit_on_delivery_order.display_name}
                description={restaurantDescriptions.cash_payment_limit_on_delivery_order.description}
            >
                <NumberField
                    label={<strong></strong>}
                    errors={errors}
                    name='cash_payment_limit_on_delivery_order'
                    placeholder='COD limit for delivery'
                    rules={{
                        required: 'Please enter COD limit',
                    }}
                    prepend='₹'
                    step='any'
                    control={control}
                />
            </FormGroupDescription>
            <FormGroupDescription
                title={restaurantDescriptions.cash_payment_limit_on_takeaway_order.display_name}
                description={restaurantDescriptions.cash_payment_limit_on_takeaway_order.description}
            >
                <NumberField
                    label={<strong></strong>}
                    errors={errors}
                    name='cash_payment_limit_on_takeaway_order'
                    placeholder='COD limit for Takeaway'
                    rules={{
                        required: 'Please enter COD limit',
                    }}
                    prepend='₹'
                    step='any'
                    control={control}
                />
            </FormGroupDescription>
            <FormGroupDescription
                title={restaurantDescriptions.cash_payment_limit_on_dine_in_order.display_name}
                description={restaurantDescriptions.cash_payment_limit_on_dine_in_order.description}
            >
                <NumberField
                    label={<strong></strong>}
                    errors={errors}
                    name='cash_payment_limit_on_dine_in_order'
                    placeholder='COD limit for Dine In'
                    rules={{
                        required: 'Please enter COD limit',
                    }}
                    prepend='₹'
                    step='any'
                    control={control}
                />
            </FormGroupDescription>
        </EuiForm>
    );
};

export default CODLimit;
