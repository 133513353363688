import { EuiButton, EuiDatePicker, EuiDatePickerRange, EuiFlexGroup, EuiFlexItem } from '@elastic/eui';
import React, { useState, useEffect } from 'react';
import CommnlyUsedDateOptions from './CommnlyUsedDateOptions';

const CustomDatePicker = (props) => {
    const {
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        displayFormat,
        startDatePlaceholder,
        endDatePlaceholder,
        isLoading,
    } = props;

    const [selectedDate, setSelectedDate] = useState({
        startDate: startDate || '',
        endDate: endDate || '',
    });
    const [endDateAutoFocus, setEndDateAutoFocus] = useState(false);

    useEffect(() => {
        setSelectedDate((prevState) => ({
            ...prevState,
            startDate: startDate,
            endDate: endDate,
        }));
    }, [startDate, endDate]);

    const handleStartDateSelection = (date) => {
        // setStartDate(date);
        setSelectedDate((prevState) => ({
            ...prevState,
            startDate: date,
        }));
        setEndDateAutoFocus(true);
        const endDateInput = document.querySelector('#endDateInput');
        endDateInput.focus();
    };

    const handleDateSubmission = () => {
        setStartDate(selectedDate.startDate);
        setEndDate(selectedDate.endDate);
    };

    return (
        <EuiFlexGroup gutterSize='s' alignItems='center' justifyContent='flexStart' direction='row'>
            <EuiFlexItem grow={false}>
                <CommnlyUsedDateOptions setStartDate={setStartDate} setEndDate={setEndDate} isLoading={isLoading} />
            </EuiFlexItem>
            <EuiFlexItem style={{ minWidth: 200, maxWidth: 300 }} grow={false}>
                <EuiDatePickerRange
                    startDateControl={
                        <EuiDatePicker
                            selected={selectedDate.startDate}
                            onChange={handleStartDateSelection}
                            startDate={selectedDate.startDate}
                            endDate={selectedDate.endDate}
                            isInvalid={props.isInvalid && selectedDate.startDate > selectedDate.endDate}
                            aria-label='Start date'
                            dateFormat={displayFormat}
                            placeholder={startDatePlaceholder}
                            adjustDateOnChange={false}
                        />
                    }
                    endDateControl={
                        <EuiDatePicker
                            selected={selectedDate.endDate}
                            onChange={(date) => {
                                setEndDateAutoFocus(false);
                                setSelectedDate((prevState) => ({
                                    ...prevState,
                                    endDate: date,
                                }));
                            }}
                            startOpen={endDateAutoFocus}
                            autoFocus={endDateAutoFocus}
                            startDate={selectedDate.startDate}
                            endDate={selectedDate.endDate}
                            id='endDateInput'
                            isInvalid={props.isInvalid && selectedDate.startDate > selectedDate.endDate}
                            aria-label='End date'
                            dateFormat={displayFormat}
                            placeholder={endDatePlaceholder}
                            adjustDateOnChange={false}
                        />
                    }
                />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
                <EuiButton onClick={handleDateSubmission}>Apply</EuiButton>
            </EuiFlexItem>
        </EuiFlexGroup>
    );
};

export default React.memo(CustomDatePicker);
