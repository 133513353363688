import React, { memo } from 'react';
import { EuiFlexItem, EuiText, EuiButtonIcon, EuiProgress } from '@elastic/eui';

const StarRatingComponent = (props) => {
    const { orderList } = props;
    return (
        <>
            {[5, 4, 3, 2, 1].map((star) => (
                <EuiFlexItem key={star} style={{ flexDirection: 'row', alignItems: 'center' }} grow={2}>
                    <EuiFlexItem style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <EuiText>{star}</EuiText>
                        <EuiButtonIcon
                            aria-label='starFilled'
                            key={star}
                            iconType={'starFilled'}
                            // 'starEmpty'
                            color='warning'
                            iconSize='l'
                        />
                    </EuiFlexItem>
                    <EuiFlexItem grow={9}>
                        <EuiProgress
                            value={orderList.order_collection.average_rating_per_star[star]}
                            max={100}
                            color="#FF0000"
                            size='m'
                        />
                    </EuiFlexItem>
                </EuiFlexItem>
            ))}
        </>
    );
};

export default memo(StarRatingComponent);
