import { SET_ACTIVE_CATEGORY_ID } from '../../../reduxStore/types/menu';
import {
    SET_ADDONS,
    SET_RESTAURANT_CATALOGUE,
    SET_ACTIVE_ITEM_ID,
    SET_ACTIVE_ADDON_GROUP_ID,
    SET_ACTIVE_ITEM_DETAILS,
    ADD_SELECTED_ITEM_ADDON_GROUP,
    SET_RESTAURANT_BADGES,
    SET_ORDERING_MODES,
    SET_BADGES_IDS,
    SET_SAVED_VARIANT_GROUP,
    SET_QUANTITY_UNITS,
    UPDATE_ITEM_LIST,
    UNLINK_BADGES_IDS,
    SET_OUTLET_MENU,
    SET_CATALOGUE_ITEMS,
    SET_CATALOGUE_ALLERGENS,
    SET_CATALOGUE_ITEM_LIST,
    REMOVE_CATALOGUE_ITEM_LIST,
    SET_CATALOGUE_LINK_LOADER,
    SET_SELECTED_ADDON_GROUP_CHOICES,
    SET_SELECTED_ADDON_GROUP_ID,
} from './MenuTypes';

const initialState = {
    restaurantCategories: [],
    addons: [],
    activeItemId: 0,
    activeAddonGroupId: 0,
    activeItemDetails: {},
    activeCategoryId: 0,
    selectedAddonGroup: [],
    restaurantBadges: [],
    restaurantOrderingModes: [],
    badgesIds: [],
    savedVariantGroup: {},
    quantityUnits: [],
    shouldUpdateItem: null,
    unLinkBadgesIds: [],
    isOutletMenu: false,
    allItems: [],
    allergens: [],
    catalogueItemList: {},
    linkCatalogueLoader: { loader: false, error: false },
    selectedAddonGroupChoices: [],
    addOnGroupSelectedId: null,
};

export default function menuReducer(state = initialState, action) {
    switch (action.type) {
        case SET_RESTAURANT_CATALOGUE:
            return {
                ...state,
                restaurantCategories: [...action.payload],
            };
        case SET_ADDONS:
            return {
                ...state,
                addons: action.payload,
            };
        case SET_ACTIVE_ITEM_ID:
            return {
                ...state,
                activeItemId: action.payload,
            };
        case SET_ACTIVE_ADDON_GROUP_ID:
            return {
                ...state,
                activeAddonGroupId: action.payload,
            };
        case SET_ACTIVE_ITEM_DETAILS:
            return {
                ...state,
                activeItemDetails: { details: action.payload.details, isLoading: action.payload.isLoading },
            };
        case SET_ACTIVE_CATEGORY_ID:
            return {
                ...state,
                activeCategoryId: action.payload,
            };
        case ADD_SELECTED_ITEM_ADDON_GROUP:
            return {
                ...state,
                selectedAddonGroup: action.payload,
            };
        case SET_RESTAURANT_BADGES:
            return {
                ...state,
                restaurantBadges: action.payload,
            };
        case SET_ORDERING_MODES:
            return {
                ...state,
                restaurantOrderingModes: action.payload,
            };
        case SET_BADGES_IDS:
            return {
                ...state,
                badgesIds: action.payload,
            };
        case UNLINK_BADGES_IDS:
            return {
                ...state,
                unLinkBadgesIds: action.payload,
            };
        case SET_SAVED_VARIANT_GROUP:
            return {
                ...state,
                savedVariantGroup: action.payload,
            };
        case SET_QUANTITY_UNITS:
            return {
                ...state,
                quantityUnits: action.payload,
            };
        case UPDATE_ITEM_LIST:
            return {
                ...state,
                shouldUpdateItem: action.payload,
            };
        case SET_OUTLET_MENU:
            return {
                ...state,
                isOutletMenu: action.payload,
            };
        case SET_CATALOGUE_ITEMS:
            return {
                ...state,
                allItems: action.payload,
            };
        case SET_CATALOGUE_ALLERGENS:
            return {
                ...state,
                allergens: action.payload,
            };
        case SET_CATALOGUE_ITEM_LIST:
            return {
                ...state,
                catalogueItemList: { ...state.catalogueItemList, ...action.payload },
            };
        case REMOVE_CATALOGUE_ITEM_LIST:
            return {
                ...state,
                catalogueItemList: {},
            };
        case SET_CATALOGUE_LINK_LOADER:
            return {
                ...state,
                linkCatalogueLoader: action.payload,
            };
        case SET_SELECTED_ADDON_GROUP_CHOICES:
            return {
                ...state,
                selectedAddonGroupChoices: action.payload,
            };
        case SET_SELECTED_ADDON_GROUP_ID:
            return {
                ...state,
                addOnGroupSelectedId: action.payload,
            };
        default:
            return state;
    }
}
