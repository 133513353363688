import React from 'react';
import { MainLayout } from '../../components/layout/mainLayout/mainLayout';
import RiderDataGridList from './Components/RiderDataGridList';

const RidersPage = () => {
    return (
        <MainLayout title='Riders' outlet={true}>
            <RiderDataGridList />
        </MainLayout>
    );
};

export default RidersPage;
