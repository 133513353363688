import { EuiButtonEmpty } from '@elastic/eui';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { UserInfoModal } from '../userInfoModal';

const ShowCustomerInfo = ({ selectedOrder }) => {
    const [showUserModal, setShowUserModal] = useState(false);

    const openUserInfoModal = () => {
        setShowUserModal(true);
    };

    const closeUserInfoModal = () => {
        setShowUserModal(false);
    };

    return (
        <>
            {!isEmpty(selectedOrder.customer) || !isEmpty(selectedOrder.customer_address) ? (
                <UserInfoModal
                    userModalOpen={showUserModal}
                    closeUserInfoModal={closeUserInfoModal}
                    userInfo={selectedOrder.customer_address}
                    userName={selectedOrder.customer.name}
                    inputAddess={
                        selectedOrder.customer_address &&
                        selectedOrder.customer_address.block_number + ', ' + selectedOrder.customer_address.land_mark
                    }
                    locationPin={
                        selectedOrder.customer_address &&
                        selectedOrder.customer_address.latitude + ', ' + selectedOrder.customer_address.longitude
                    }
                    phoneNumber={selectedOrder.customer.mobile_number}
                    email={selectedOrder.customer.email}
                    countryCode={selectedOrder.customer.country_code}
                    customerOrderCount={selectedOrder.order_count}
                    lifeTimeValue={selectedOrder.life_time_value}
                />
            ) : (
                ''
            )}

            {!isEmpty(selectedOrder.customer) || !isEmpty(selectedOrder.customer_address) ? (
                <EuiButtonEmpty onClick={openUserInfoModal} style={{ fontSize: '12px', marginLeft: '-6px' }} size='s'>
                    Show more{' '}
                </EuiButtonEmpty>
            ) : null}
        </>
    );
};

export default ShowCustomerInfo;
