import React, { useEffect, useState } from 'react';
import {
    EuiFlexItem,
    EuiLoadingContent,
    EuiComboBox,
    EuiSpacer,
    EuiButton,
    EuiFlexGroup,
    EuiHealth,
    EuiHighlight,
} from '@elastic/eui';
import API from '../../api/axios/API';
import { useDispatch } from 'react-redux';
import { getLiveOrders } from '../../api/liveOrders/getLiveOrderNewAPI';
import { getLiveOrderDetailsAPI } from '../../api/liveOrders/getLiveOrderDetailsAPI';
import { getFabDeliveryRiders } from '../../api/getFabDeliveryRiders';

export const FabDeliveryManagment = (props) => {
    const dispatch = useDispatch();

    const getRidersLists = async () => {
        dispatch(getFabDeliveryRiders(props.id)).then((response) => {
            if (response.success) {
                let riderOptions = [];
                response.riders.map((rider) =>
                    riderOptions.push({
                        ...rider,
                        label: `${rider.first_name} -  ${rider.middle_name} - ${rider.last_name}`,
                        color: rider.online ? 'green' : '#bb2205',
                        online: rider.online,
                        disabled: rider.online ? false : true,
                    })
                );

                let onlineRiders = riderOptions.filter((rider) => rider.online === 1);
                let offlineRiders = riderOptions.filter((rider) => rider.online === 0);
                riderOptions = onlineRiders.concat(offlineRiders);

                setState({
                    ...state,
                    riderList: response.riders.data,
                    apiDataLoaded: true,
                    riderOptions,
                });
            } else {
                alert(response.message);
            }
        });
    };

    useEffect(() => {
        getRidersLists();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [state, setState] = useState({
        selectedRider: [],
        riderOptions: [],
        assignLoading: false,
        apiDataLoaded: false,
    });

    // const [normalRadius, setNormalRadius] = useState(0);
    const [extraRadius, setExtraRadius] = useState(0);
    const onChnageCombo = (options) => {
        if (props.distance > parseInt(options[0].normal_service_radius)) {
            let extrakillometer = 0;
            extrakillometer = parseInt(props.distance) - parseInt(options[0].normal_service_radius);
            setExtraRadius(Math.ceil(extrakillometer));
            // setNormalRadius(parseInt(options[0].normal_service_radius));
        } else {
            // setNormalRadius(parseInt(props.distance));
            setExtraRadius(0);
        }

        setState({
            ...state,
            selectedRider: options,
        });
    };

    // const closeModal = () => {
    //     props.closeRiderModal();
    // };

    const assignRider = async () => {
        setState({
            ...state,
            assignLoading: true,
        });

        const riderId = state.selectedRider ? state.selectedRider[0].rider_id : null;

        try {
            const assignOrder = await API.patch(
                `restaurants/:restaurantId/orders/${props.orderId}/riders/${riderId}/assign`
            );
            if (assignOrder) {
                setState({
                    ...state,
                    assignLoading: false,
                });
            }
            alert('Rider assign successfully!!');
            dispatch(getLiveOrders());
            dispatch(getLiveOrderDetailsAPI());
            // props.onOrderStatusChange();
            props.setRiderModalOpen();
        } catch (e) {
            alert(`${e.message}`);
            setState({
                ...state,
                assignLoading: false,
            });
        }

        props.setAssignRider(riderId);
    };

    const renderOption = (option, searchValue, contentClassName) => {
        const { color, label } = option;
        return (
            <EuiHealth color={color}>
                <span className={contentClassName}>
                    <EuiHighlight search={searchValue}>{label}</EuiHighlight>
                </span>
            </EuiHealth>
        );
    };

    // const buttonDisable = () => {
    //     if(state.selectedRider.length > 0){
    //         if(state.selectedRider[0].online === 1){
    //             return false
    //         }else{
    //             true
    //         }
    //     }

    //     return true
    // }

    return (
        <>
            <EuiFlexGroup direction='column'>
                <EuiFlexItem>
                    {!state.apiDataLoaded && <EuiLoadingContent lines={4} />}
                    {state.apiDataLoaded && (
                        <>
                            <EuiComboBox
                                placeholder='Select a Rider'
                                singleSelection={{
                                    asPlainText: true,
                                }}
                                options={state.riderOptions}
                                selectedOptions={state.selectedRider}
                                onChange={(option) => onChnageCombo(option)}
                                isClearable={false}
                                renderOption={renderOption}
                            />

                            <EuiSpacer size='m' />
                            {state.selectedRider.length !== 0 && (
                                <>
                                    <table>
                                        <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                            <td style={{ borderRight: '1px solid #D3DAE6' }}>Rider Name </td>
                                            {/* <td> : </td> */}
                                            <td>
                                                <b>
                                                    {state.selectedRider.length !== 0
                                                        ? state.selectedRider[0].label
                                                        : '-'}
                                                </b>
                                            </td>
                                        </tr>
                                        <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                            <td style={{ borderRight: '1px solid #D3DAE6' }}>Mobile number </td>
                                            {/* <td> : </td> */}
                                            <td>
                                                <b>
                                                    {state.selectedRider.length !== 0
                                                        ? state.selectedRider[0].mobile_number
                                                        : '-'}
                                                </b>{' '}
                                            </td>
                                        </tr>
                                        <tr style={{ padding: 5, borderBottom: '1px solid #D3DAE6' }}>
                                            <td style={{ borderRight: '1px solid #D3DAE6' }}>Normal Charge </td>
                                            {/* <td> : </td> */}
                                            <td>
                                                <b>
                                                    {state.selectedRider.length !== 0
                                                        ? state.selectedRider[0].delivery_rate
                                                        : '-'}
                                                </b>
                                            </td>
                                        </tr>
                                        <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                            <td style={{ borderRight: '1px solid #D3DAE6' }}>Extra Charge </td>
                                            {/* <td> : </td> */}
                                            <td>
                                                <b>
                                                    {state.selectedRider.length !== 0
                                                        ? extraRadius * state.selectedRider[0].extra_delivery_rate
                                                        : '-'}
                                                </b>
                                            </td>
                                        </tr>
                                        <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                            <td style={{ borderRight: '1px solid #D3DAE6' }}>Total Amount </td>
                                            {/* <td> : </td> */}
                                            <td>
                                                <b>
                                                    {state.selectedRider.length !== 0
                                                        ? state.selectedRider[0].delivery_rate +
                                                          extraRadius * state.selectedRider[0].extra_delivery_rate
                                                        : '-'}
                                                </b>
                                            </td>
                                        </tr>
                                    </table>
                                </>
                            )}
                        </>
                    )}
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiButton
                        isLoading={state.assignLoading}
                        onClick={() => assignRider()}
                        disabled={state.selectedRider.length > 0 && state.selectedRider[0].online === 1 ? false : true}
                        fill
                    >
                        {state.selectedRider.length > 0 && state.selectedRider[0].online === 1
                            ? 'Assign'
                            : state.selectedRider.length === 0
                            ? 'Assign'
                            : 'Rider offline'}
                    </EuiButton>
                </EuiFlexItem>
            </EuiFlexGroup>
        </>
    );
};
