export const offerTypes = [
    {
        value: 'flat_discount',
        displayName: 'Flat Discount',
    },
    {
        value: 'percentage_discount',
        displayName: 'Percentage Discount',
    },
    {
        value: 'free_delivery',
        displayName: 'Free Delivery',
    },
    {
        value: 'free_items',
        displayName: 'Get an item',
    },
    {
        value: 'buy_x_get_y',
        displayName: 'Buy X Get Y free',
    },
    {
        value: 'menu_discount',
        displayName: 'Menu Discount',
    },
    {
        value: 'buy_one_get_one_free',
        displayName: 'BOGO',
    },
    {
        value: 'coupled_offer',
        displayName: 'Coupled Offer',
    },
    {
        value: 'combo_item_price_deal',
        displayName: 'Value Deal',
    },
    {
        value: 'bogo_with_view',
        displayName: 'BOGO with view',
    },
];
