import React from 'react';
import { MainLayout } from '../../components/layout/mainLayout';
import MediaLibraryScreen from './Components/MediaLibraryScreen';

const MediaLibraryPage = () => {
    return (
        <MainLayout title='Media Library' backgroundColorChange>
            <MediaLibraryScreen />
        </MainLayout>
    );
};

export default MediaLibraryPage;
