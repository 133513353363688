import { EuiCheckbox, EuiFlexGroup, EuiFlexItem, EuiPanel, EuiSpacer, htmlIdGenerator } from '@elastic/eui';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { userHasPermission } from '../../helper/auth';

const ItemLinkCheckboxList = ({
    appModule,
    accessLvl,
    label,
    dataInfo,
    handleOnChange,
    isLoading,
    initSelectedItems,
    selectedItem,
    setOfferLinkStatus,
}) => {
    const permissions = useSelector((state) => state.permission.permission);
    const [isSelected, setIsSelected] = useState(false);

    useEffect(() => {
        initSelectedItems(dataInfo, setIsSelected, selectedItem);
        return function cleanup() {
            setOfferLinkStatus({
                link: [],
                unlink: [],
            });
        };
    }, [dataInfo, initSelectedItems, selectedItem, setOfferLinkStatus]);

    return (
        <>
            <EuiPanel className='custom' paddingSize='m'>
                <EuiFlexGroup>
                    <EuiFlexItem grow={false}>
                        <EuiCheckbox
                            id={htmlIdGenerator()()}
                            label={label}
                            checked={isSelected}
                            onChange={(event) => handleOnChange(event, dataInfo, setIsSelected, selectedItem)}
                            disabled={!userHasPermission(permissions, appModule, accessLvl)}
                            selectedItem={selectedItem}
                        />
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiPanel>
            <EuiSpacer size='s' />
        </>
    );
};

export default React.memo(ItemLinkCheckboxList);
