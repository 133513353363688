import APIV3 from '../../../api/axios/APIV3';

export const fetchImageList = async (imageType, setState, activePage, rowSize, setTotalEntries) => {
    setState((prevState) => ({
        ...prevState,
        isLoading: true,
    }));

    let response;
    try {
        // response = await APIV3.get(`restaurants/:restaurantId/images`);
        response = await APIV3.get(
            `restaurants/:restaurantId/images?image_type=${imageType}&per_page=${rowSize || 100}&page=${
                activePage || 1
            }`
        );

        setState((prevState) => ({
            ...prevState,
            isLoading: false,
        }));
        if (response?.success) {
            setState((prevState) => ({
                ...prevState,
                images: [...response?.uploads],
            }));

            setTotalEntries(response.images.total);
        }
    } catch (error) {
        response = error;
        setState((prevState) => ({
            ...prevState,
            isLoading: false,
        }));
    }

    return response;
};
