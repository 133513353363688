import React from 'react';
import { MainLayout } from '../../components/layout/mainLayout/mainLayout';
import RestaurantSettingsForm from './Components/RestaurantSettingsForm';

const RestaurantSettingsPage = () => {
    return (
        <MainLayout title={'Edit restaurant Information'}>
            <RestaurantSettingsForm />
        </MainLayout>
    );
};

export default RestaurantSettingsPage;
