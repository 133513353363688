import React, { useEffect, useState } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiLoadingContent, EuiText, EuiPanel } from '@elastic/eui';
import { useSelector } from 'react-redux';
import noOrderSvg from '../../assets/img/soup.svg';
import { useDispatch } from 'react-redux';
import { getLiveOrders } from '../../api/liveOrders/getLiveOrderNewAPI';
import LiveOrdersTable from './liveOrdersTable';
import LiveOrderDetails from './liveOrderDetails';
import './style.css';
import * as types from '../../reduxStore/types/orders';
import useSingleScreenHeightCalc from '../../hooks/useSingleScreenHeightCalc';

export const LiveOrdersComponent = () => {
    const authUser = useSelector((state) => state.auth.user);
    const currentOrderState = useSelector((state) => state.orders.currentOrderState);
    const currentOrderingMode = useSelector((state) => state.orders.currentOrderingMode);
    const dispatch = useDispatch();
    const { screenHeight } = useSingleScreenHeightCalc();

    const [state, setState] = useState({
        apiDataLoaded: false,
    });

    const getLiveOrder = async () => {
        dispatch({
            type: 'set-table-loader',
            payload: { loader: true, error: false },
        });
        await dispatch(getLiveOrders()).then((liveOrders) => {
            if (liveOrders.success) {
                setState({
                    ...state,
                    apiDataLoaded: true,
                });
            }
        });
        dispatch({
            type: 'set-table-loader',
            payload: { loader: false, error: false },
        });
    };

    useEffect(() => {
        if (authUser.accepting_orders) {
            getLiveOrder();
        }
    }, [currentOrderState, currentOrderingMode]);

    useEffect(() => {
        return () => {
            dispatch({
                type: types.SET_SELECTED_ORDER_DETAILS,
                payload: {},
            });
            dispatch({
                type: types.SET_SELECTED_ORDER,
                payload: '',
            });
        };
    }, []);

    const displayInformativeMessage = () => {
        if (!authUser.accepting_orders) {
            return (
                <div>
                    <div className='no-order-section-child'>
                        <img src={noOrderSvg} className='no-order-image' alt='' />
                        <br />
                        <EuiText>
                            <h3 style={{ textAlign: 'center' }}>Please on Notification toggle to see Orders</h3>
                        </EuiText>
                    </div>
                </div>
            );
        }
    };

    const loaderContent = () => {
        let accepting_orders = localStorage.getItem('user')
            ? JSON.parse(localStorage.getItem('user')).accepting_orders
            : 1;

        if (accepting_orders) {
            return !state.apiDataLoaded ? (
                <EuiPanel>
                    <EuiLoadingContent lines={10} />
                </EuiPanel>
            ) : null;
        }
    };

    return (
        <>
            {
                //Message for No order or show order toggle
                displayInformativeMessage()
            }
            <EuiFlexGroup>
                <EuiFlexItem grow={6}>
                    {loaderContent()}
                    {state.apiDataLoaded && (
                        <>
                            <EuiPanel paddingSize='s' style={{ height: screenHeight }} className=' scrollbar-sm'>
                                <LiveOrdersTable />
                            </EuiPanel>
                        </>
                    )}
                </EuiFlexItem>
                <EuiFlexItem grow={4}>
                    {loaderContent()}
                    {state.apiDataLoaded && (
                        <div>
                            <EuiPanel paddingSize='s' style={{ height: screenHeight }} className=' scrollbar-sm'>
                                <LiveOrderDetails isLiveOrder={true} />
                            </EuiPanel>
                        </div>
                    )}
                </EuiFlexItem>
            </EuiFlexGroup>
        </>
    );
};
