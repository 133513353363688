import React, { memo, useEffect, useState } from 'react';
import API from '../../api/axios/API';
import { CSVDownload } from 'react-csv';

const OrderRatingCsvDownload = (props) => {
    const { selectedOutlet, order } = props;
    const [orderArray, setOrderArray] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);

    const getOrderRatings = async () => {
        await API.get(
            `/restaurants/:restaurantId/outlets/${selectedOutlet}/orders?page=1&per_page=${order.order_collection.orders.total}&start_date=${props.startDate}&end_date=${props.endDate}`
        )
            .then((res) => {
                const orderArray = [];

                res.order_collection.orders.data.map((order) =>
                    orderArray.push({
                        Name: order.customer.name,
                        Date: order.status.placed_at,
                        'Order id': order.order_label,
                        'Order value': order.net_total.toFixed(),
                        'Order Rating': order.feedback && order.feedback.star.toFixed(2),
                        'Rider Rating': order.feedback && order.feedback.delivery_star.toFixed(2),
                    })
                );

                setOrderArray(orderArray);
                setIsLoading(false);
            })
            .catch(() => {
                setError(true);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getOrderRatings();
    }, []);

    useEffect(() => {
        if (!isLoading) {
            props.csvStateCallback(false);
        }
    }, [isLoading]);

    if (isLoading) return null;
    if (error) {
        alert('Error in downlaod..');
        // return
    }

    if (!orderArray?.length) {
        return;
    }
    return (
        <div>
            <CSVDownload data={orderArray} target='_blank' />;
        </div>
    );
};

export default memo(OrderRatingCsvDownload);
