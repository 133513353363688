import React, { useCallback, useEffect, useState } from 'react';
import { EuiForm } from '@elastic/eui';
import FormGroupDescription from '../../../components/formGroupDescription';
import TextAreaField from '../../../components/Form/TextAreaField';
import { useDispatch, useSelector } from 'react-redux';
import { restaurantSettingsAPI } from '../../../api/restaurantSettings/restaurantSettingsAPI';
import useFormActionsHandler from '../../../hooks/useFormActionsHandler';

const AboutUs = (props) => {
    const { restaurantResponse, fetchRestaurant } = props;
    const dispatch = useDispatch();
    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);
    const [defaultValues, setDefaultValues] = useState({});

    const updateFormValues = useCallback((data) => {
        let defaultValue = {};

        defaultValue.languages = data.languages.map((item, index) => ({
            label: item.language_label,
            language: item.language,
            language_label: item.language_label,
        }));

        let translationObject = {
            about_us: {},
        };

        for (const language_label in data.translations.about_us) {
            translationObject.about_us[language_label] = data.translations.about_us[language_label];
        }

        defaultValue.translations = translationObject;

        return defaultValue;
    }, []);

    useEffect(() => {
        setDefaultValues(updateFormValues(restaurantResponse.restaurant));
    }, [restaurantResponse.restaurant, updateFormValues]);

    const onFormSaveApi = useCallback(
        async (data) => {
            const response = await dispatch(restaurantSettingsAPI('about_us', data));
            if (response.success) {
                fetchRestaurant();
            } else {
                alert(response.message);
            }

            if (response.success) setDefaultValues(updateFormValues(response.restaurant));
            return response;
        },
        [dispatch, fetchRestaurant, updateFormValues]
    );

    const { errors, control, watch } = useFormActionsHandler({ onFormSaveApi, defaultValues });

    return (
        <EuiForm component='form'>
            <FormGroupDescription
                title={restaurantDescriptions?.about_us?.display_name}
                description={restaurantDescriptions?.about_us?.description}
            >
                {watch('languages') &&
                    watch('languages').length > 0 &&
                    watch('languages').map((item, index) => (
                        <TextAreaField
                            errors={errors}
                            isInvalid={errors?.translations?.about_us?.hasOwnProperty(item.language)}
                            error={
                                errors?.translations?.about_us?.hasOwnProperty(item.language) &&
                                errors.translations.about_us[item.language]
                                    ? errors.translations.about_us[item.language].message
                                    : ''
                            }
                            label={index === 0 ? <strong>About us</strong> : ''}
                            name={`translations.about_us.${item.language}`}
                            placeholder={`Write about us: ${item.language_label} `}
                            rules={{ required: 'Please write about us' }}
                            control={control}
                            watch={watch}
                            rows={4}
                        />
                    ))}
            </FormGroupDescription>
        </EuiForm>
    );
};

export default AboutUs;
