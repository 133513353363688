import { EuiFormRow, EuiRadioGroup } from '@elastic/eui';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const FormRadioGroup = ({ isInvalid, error, options, label, name, rules, disabled }) => {
    const { control } = useFormContext();

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field }) => (
                <EuiFormRow isInvalid={isInvalid} error={error}>
                    <EuiRadioGroup
                        options={options}
                        idSelected={field.value}
                        onChange={field.onChange}
                        disabled={disabled}
                        legend={{
                            children: <span> {label} </span>,
                        }}
                    />
                </EuiFormRow>
            )}
        />
    );
};

export default FormRadioGroup;
