import React, { useState } from 'react';
import {
    EuiFlyout,
    EuiFlyoutHeader,
    EuiFlyoutFooter,
    EuiFlexGroup,
    EuiFlexItem,
    EuiButtonEmpty,
    EuiTitle,
    EuiFlyoutBody,
} from '@elastic/eui';
import OrderSummaryCard from '../orderSummaryCard';
// import {OrderDetailsCard} from "../orderDetailsCard"
// import {OrderRatingDetailCard} from "../orderRating/orderRatingDetailCard"
import { OrderRatingDetailCard } from './orderRatingDetailCard';
import { OrderRatingUserInfoModal } from './orderRatingUserInfoModal';
import { RiderInfoModal } from './orderRiderInfoModal';
export const OrderFlyout = (props) => {
    const { setSelectedOrder, setFlyoutOpen, order } = props;

    const onCloseFlyout = () => {
        setFlyoutOpen(false);
        setSelectedOrder({});
    };

    let selectedOrder = order.order_id;

    const [userInfoModal, setUserInfoModal] = useState(false);
    const [riderInfoModal, setRiderInfoModal] = useState(false);

    const closeUserInfoModal = () => {
        setUserInfoModal(!userInfoModal);
    };

    const closeRiderInfoModal = () => {
        setRiderInfoModal(false);
    };

    return (
        <>
            {userInfoModal && (
                <OrderRatingUserInfoModal
                    userModalOpen={userInfoModal}
                    closeUserInfoModal={closeUserInfoModal}
                    userName={order.customer.name}
                    inputAddess={
                        order.customer_address &&
                        order.customer_address.block_number + ', ' + order.customer_address.land_mark
                    }
                    locationPin={
                        order.customer_address &&
                        order.customer_address.latitude + ', ' + order.customer_address.longitude
                    }
                    phoneNumber={order.customer.mobile_number}
                    countryCode={order.customer.country_code}
                />
            )}
            {riderInfoModal && <RiderInfoModal order={order} closeRiderInfoModal={closeRiderInfoModal} />}
            <EuiFlyout
                ownFocus
                onClose={onCloseFlyout}
                hideCloseButton
                aria-labelledby='flyoutComplicatedTitle'
                style={{ zIndex: 6500 }}
                headerzindexlocation='below'
            >
                <EuiFlyoutHeader hasBorder>
                    <EuiTitle size='m'>
                        <h2 id='flyoutTitle'>Order Details</h2>
                    </EuiTitle>
                </EuiFlyoutHeader>
                <EuiFlyoutBody>
                    <EuiFlexGroup direction={'column'}>
                        <EuiFlexItem grow={3}>
                            <OrderSummaryCard
                                key={order.order_id}
                                isActive={selectedOrder === order.order_id}
                                orderId={order.order_id}
                                name={order.customer.name}
                                address={
                                    order.customer_address &&
                                    order.customer_address.block_number + ', ' + order.customer_address.land_mark
                                }
                                orderTotal={order.net_total.toFixed(2)}
                                setSelected={(id) => {}}
                                paymentMode={order.payment_method ? order.payment_method.payment_method : 'PENDING'}
                                orderItemCount={order.cart.cart_items.length}
                                outlet={order.outlet}
                                isPast
                                time={order.status.placed_at}
                                orderType={order.ordering_mode}
                                tableNumber={order.cart.table_number}
                                customerOrderCount={order.order_count}
                                order={order}
                            />
                        </EuiFlexItem>
                        <EuiFlexItem grow={7}>
                            <div>
                                <OrderRatingDetailCard
                                    isPast
                                    order={order}
                                    isOrderFlyout
                                    orderId={selectedOrder}
                                    setUserInfoModal={setUserInfoModal}
                                    setRiderInfoModal={setRiderInfoModal}
                                />
                            </div>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlyoutBody>
                <EuiFlyoutFooter>
                    <EuiFlexGroup justifyContent='spaceBetween'>
                        <EuiFlexItem grow={false}>
                            <EuiButtonEmpty iconType='cross' onClick={onCloseFlyout} flush='left'>
                                Close
                            </EuiButtonEmpty>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlyoutFooter>
            </EuiFlyout>
        </>
    );
};
