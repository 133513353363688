import { EuiConfirmModal, EuiOverlayMask } from '@elastic/eui';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import API from '../../../../../api/axios/API';
import {
    SET_ACTIVE_ITEM_DETAILS,
    UPDATE_ITEM_LIST,
} from '../../../../../components/menuComponentsRefactorCopy/Store/MenuTypes';
import * as types from '../../../../../reduxStore/types/menu';

const ItemDeletionModal = ({ showItemDeletionModal, setShowItemDeletionModal }) => {
    const languageId = useSelector((state) => state.language.languageId);
    const activeCategoryId = useSelector((state) => state.menu.activeCategoryId);
    const activeItemId = useSelector((state) => state.menu.activeItemId);
    const editItem = useSelector((state) => state.menuReducer.activeItemDetails.details);
    const newIdItemDelete = useSelector((state) => state.menu.newIdItemDelete);

    const dispatch = useDispatch();

    const onConfirmItemDelete = async () => {
        if (activeItemId !== 'newId') {
            let deleteItem = await API.delete(
                `restaurants/:restaurantId/categories/${activeCategoryId}/items/${editItem.item_id}`
            );

            if (deleteItem.success) {
                // fetchCategoryItem(activeCategoryId, languageId).then((response) => {
                //     if (response.success) {
                //         dispatch({
                //             type: SET_CATALOGUE_ITEM_LIST,
                //             payload: {
                //                 [`item_${activeCategoryId}`]: response.items,
                //             },
                //         });
                //     }
                // });

                dispatch({
                    type: types.SET_CATEGORY_STATE,
                    payload: {
                        categoryState: {},
                    },
                });
                dispatch({
                    type: UPDATE_ITEM_LIST,
                    payload: { update: true, details: editItem },
                });
                dispatch({
                    type: SET_ACTIVE_ITEM_DETAILS,
                    payload: { details: {}, isLoading: false },
                });
                if (editItem.item_id === activeItemId) {
                    dispatch({
                        type: types.EDIT_ITEM,
                        payload: {
                            editItem: {},
                        },
                    });
                }
            }

            setShowItemDeletionModal(false);
        } else {
            dispatch({
                type: SET_ACTIVE_ITEM_DETAILS,
                payload: { details: {}, isLoading: false },
            });
            dispatch({
                type: types.SET_NEW_ITEM_ID_DELETE,
                payload: {
                    newIdItemDelete: !newIdItemDelete,
                },
            });
            setShowItemDeletionModal(false);
        }
    };

    return (
        <>
            {showItemDeletionModal ? (
                <EuiOverlayMask>
                    <EuiConfirmModal
                        title={<p>Are you sure you want to delete this item?</p>}
                        onCancel={() => {
                            setShowItemDeletionModal(false);
                        }}
                        onConfirm={onConfirmItemDelete}
                        cancelButtonText="No, don't do it"
                        confirmButtonText='Yes, do it'
                        buttonColor='danger'
                        defaultFocusedButton='cancel'
                    >
                        <p>You&rsquo;re about to delete this item</p>
                        <p>Are you sure you want to do this?</p>
                        {editItem?.is_mapped_item ? (
                            <p>(Note: Deleting this item will also remove this item linked in any other categories)</p>
                        ) : null}
                    </EuiConfirmModal>
                </EuiOverlayMask>
            ) : null}
        </>
    );
};

export default ItemDeletionModal;
