import React from 'react';
import {
    EuiButton,
    EuiModal,
    EuiModalBody,
    EuiModalFooter,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiBadge,
} from '@elastic/eui';
import moment from 'moment';

export const RiderInfoModal = (props) => {
    const { order, closeRiderInfoModal } = props;
    return (
        <>
            <EuiModal
                onClose={() => {
                    closeRiderInfoModal();
                }}
                style={{ zIndex: 11000 }}
            >
                <EuiModalHeader>
                    <EuiModalHeaderTitle>Rider Information</EuiModalHeaderTitle>
                </EuiModalHeader>

                <EuiModalBody>
                    <>
                        <table>
                            <tr>
                                <td>Rider Name </td>
                                <td> : </td>
                                <td>
                                    <b>
                                        {order.rider.first_name} &nbsp; {order.rider.middle_name} &nbsp;{' '}
                                        {order.rider.last_name}
                                    </b>
                                </td>
                            </tr>
                            <tr>
                                <td>Mobile number </td>
                                <td> : </td>
                                <td>
                                    <b>{order.rider.mobile_number}</b>
                                </td>
                            </tr>
                            <tr>
                                <td>Address </td>
                                <td> : </td>
                                <td>
                                    <b>{order.rider.address}</b>
                                </td>
                            </tr>
                            {order.rider_order.payment_mode === 'cash_on_delivery' ? (
                                <tr>
                                    <td>Rider to Restaurant settlement </td>
                                    <td> : </td>
                                    <td>
                                        <EuiBadge
                                            className='custom-badge'
                                            color='danger'
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '30%',
                                            }}
                                        >
                                            <b style={{ color: 'white' }}>
                                                {order.rider_order.rider_restaurant_cod_settlement}
                                            </b>
                                        </EuiBadge>
                                    </td>
                                </tr>
                            ) : (
                                <tr>
                                    <td>Restaurant to Rider settlement </td>
                                    <td> : </td>
                                    <td>
                                        <EuiBadge
                                            className='custom-badge'
                                            color='secondary'
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '30%',
                                            }}
                                        >
                                            <b style={{ color: 'white' }}>
                                                {order.rider_order.rider_restaurant_online_settlement}
                                            </b>
                                        </EuiBadge>
                                    </td>
                                </tr>
                            )}

                            <tr>
                                <td>Rider accepted at </td>
                                <td> : </td>
                                <td>
                                    <b>{moment(order.status.rider_accept_at).format('DD MMM yyy hh:mm:ss A')}</b>
                                </td>
                            </tr>
                            <tr>
                                <td>Rider reached restaurant at </td>
                                <td> : </td>
                                <td>
                                    <b>
                                        {moment(order.status.rider_reached_restaurant_at).format(
                                            'DD MMM yyy hh:mm:ss A'
                                        )}
                                    </b>
                                </td>
                            </tr>
                            <tr>
                                <td>Rider picked up at </td>
                                <td> : </td>
                                <td>
                                    <b>{moment(order.status.rider_picked_up_at).format('DD MMM yyy hh:mm:ss A')}</b>
                                </td>
                            </tr>

                            <tr>
                                <td>Rider reaced location at </td>
                                <td> : </td>
                                <td>
                                    <b>
                                        {moment(order.status.rider_reached_to_location_at).format(
                                            'DD MMM yyy hh:mm:ss A'
                                        )}
                                    </b>
                                </td>
                            </tr>

                            <tr>
                                <td>Rider delivered at </td>
                                <td> : </td>
                                <td>
                                    <b>{moment(order.status.rider_delivered_at).format('DD MMM yyy hh:mm:ss A')}</b>
                                </td>
                            </tr>
                        </table>
                    </>
                </EuiModalBody>

                <EuiModalFooter>
                    <EuiButton onClick={() => closeRiderInfoModal()} fill>
                        Close
                    </EuiButton>
                </EuiModalFooter>
            </EuiModal>
        </>
    );
};
