import React, { useState, useEffect, memo } from 'react';
import RiderDataGrid from './riderDataGrid';
import API from '../../api/axios/API';
import { useDispatch } from 'react-redux';
import Pagination from '../Form/Pagination';
import { EuiFlexItem, EuiLoadingContent } from '@elastic/eui';

const RiderList = ({ setRefetch, refetch, id, showFlyout }) => {
    // state for api calll
    const [appState, setAppState] = useState({
        loading: true,
        riderList: [],
        refetch: false,
        errorState: false,
    });
    // pagination state
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    // api call
    useEffect(() => {
        const riderList = async () => {
            try {
                const rider = await API.get(
                    `restaurants/:restaurantId/outlets/${id}/riders?page=${pagination.pageIndex + 1}&per_page=${
                        pagination.pageSize
                    }`
                );
                setAppState((preState) => ({
                    ...preState,
                    loading: false,
                    riderList: rider,
                }));
            } catch (e) {
                setAppState((preState) => ({
                    ...preState,
                    loading: false,
                    errorState: true,
                }));
            }
        };
        riderList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetch, id, pagination.pageIndex]);

    const dispatch = useDispatch();

    if (appState.loading) return <EuiLoadingContent lines={10} />;
    if (appState.error) return 'error..';

    // store in reducer
    dispatch({
        type: 'set-rider-list',
        payload: {
            riderList:
                appState.riderList && appState.riderList.riders && appState.riderList.riders.data.length > 0
                    ? appState.riderList.riders.data
                    : [],
        },
    });
    // handle pagination
    const handlePageChange = (page) => {
        setPagination({
            ...pagination,
            pageIndex: page,
        });
    };

    return (
        <>
            <EuiFlexItem>
                <RiderDataGrid
                    allRider={appState.riderList}
                    pagination={pagination}
                    setPagination={setPagination}
                    showFlyout={showFlyout}
                    setRefetch={setRefetch}
                />
                {/* pagination for rider */}
                {appState.riderList.riders.total > appState.riderList.riders.per_page && (
                    <Pagination
                        totalRecord={appState.riderList.riders.total}
                        pageSize={appState.riderList.riders.per_page}
                        activePage={appState.riderList.riders.current_page - 1}
                        handlePageChange={handlePageChange}
                    />
                )}
            </EuiFlexItem>
        </>
    );
};
export default memo(RiderList);
