import React, { useCallback, useEffect, useState } from 'react';
import {
    EuiButton,
    EuiComboBox,
    EuiFieldText,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFlyout,
    EuiFlyoutBody,
    EuiFlyoutHeader,
    EuiForm,
    EuiFormRow,
    EuiSpacer,
    EuiTitle,
} from '@elastic/eui';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';

import { fetchOutletSections } from './Api/fetchOutletSections';
import { createSection, deleteSection, updateSection } from './Api/sectionOperations';
import { userHasPermission } from '../../helper/auth';
import { useLocation } from 'react-router-dom';

const SectionCreationFlyout = ({ closeFlyout, sectionState, selectedSection }) => {
    const {
        handleSubmit,
        control,
        formState: { errors },
        reset,
    } = useForm();
    const dispatch = useDispatch();
    const location = useLocation();
    const outletId = useSelector((state) => state.outlet.selectedOutletId);
    const outletOrderingModes = useSelector((state) => state.outlet.orderingModes);
    const languageId = useSelector((state) => state.language.languageId);
    const permission = useSelector((state) => state.permission.permission);
    const [orderingModeOptions, setOrderingModeOptions] = useState([]);

    useEffect(() => {
        if (outletOrderingModes?.length) {
            let modes = [];
            for (const orderingMode of outletOrderingModes) {
                if (
                    (orderingMode.restaurant_ordering_mode.type === 'fine_dine' ||
                        orderingMode.restaurant_ordering_mode.type === 'qsr') &&
                    orderingMode.restaurant_ordering_mode.status
                ) {
                    modes.push({
                        label: orderingMode.restaurant_ordering_mode.display_name,
                        value: orderingMode.id,
                    });
                }
            }
            setOrderingModeOptions(modes);
        }
    }, [languageId, outletOrderingModes]);

    useEffect(() => {
        if (sectionState === 'update') {
            const ordering_mode = orderingModeOptions.filter(
                (orderingMode) => orderingMode.value == selectedSection.outlet_ordering_mode_id
            );
            let existingData = {
                internal_name: selectedSection.internal_name,
                ordering_mode: ordering_mode,
            };

            reset({ ...existingData });
        }
    }, [sectionState, selectedSection, reset, orderingModeOptions]);

    const handleSectionDeletion = useCallback(async () => {
        closeFlyout();
        await deleteSection(outletId, selectedSection.id).then((response) => {
            if (response.success) {
                dispatch(fetchOutletSections());
            }
        });
    }, [dispatch, outletId, selectedSection, closeFlyout]);

    const onSubmit = useCallback(
        async (data) => {
            closeFlyout();
            let sectionData = {
                outlet_ordering_mode_id: data.ordering_mode[0].value,
                internal_name: data.internal_name,
            };
            if (sectionState === 'update') {
                await updateSection(outletId, selectedSection.id, sectionData).then((response) => {
                    if (response.success) {
                        dispatch(fetchOutletSections());
                    }
                });
            } else {
                await createSection(outletId, sectionData).then((response) => {
                    if (response.success) {
                        dispatch(fetchOutletSections());
                    }
                });
            }
        },
        [closeFlyout, sectionState, outletId, dispatch, selectedSection]
    );

    return (
        <EuiFlyout onClose={closeFlyout}>
            <EuiForm component='form' onSubmit={handleSubmit(onSubmit)}>
                <EuiFlyoutHeader hasBorder>
                    <EuiTitle>
                        <h2>{sectionState === 'update' ? 'Edit' : 'Create a'} Section</h2>
                    </EuiTitle>
                </EuiFlyoutHeader>
                <EuiFlyoutBody>
                    <Controller
                        render={({ field }) => (
                            <EuiFormRow
                                label={'Internal Name'}
                                isInvalid={
                                    errors.internal_name ? errors.internal_name.message : errors['internal_name']
                                }
                                error={errors['internal_name'] ? errors['internal_name'].message : ''}
                            >
                                <EuiFieldText placeholder={'A7'} value={field.value} onChange={field.onChange} />
                            </EuiFormRow>
                        )}
                        name={'internal_name'}
                        control={control}
                        fullWidth={true}
                        rules={{ required: 'Please Enter Internal Name' }}
                    />
                    <Controller
                        render={({ field }) => (
                            <EuiFormRow
                                label={'Outlet Ordering Mode'}
                                isInvalid={
                                    errors.ordering_mode ? errors.ordering_mode.message : errors['ordering_mode']
                                }
                                error={errors['ordering_mode'] ? errors['ordering_mode'].message : ''}
                            >
                                <EuiComboBox
                                    placeholder='Select an ordering mode'
                                    options={orderingModeOptions}
                                    onChange={field.onChange}
                                    selectedOptions={field.value}
                                    singleSelection={true}
                                    isClearable={true}
                                />
                            </EuiFormRow>
                        )}
                        rules={{ required: 'Please add an ordering mode' }}
                        name={'ordering_mode'}
                        control={control}
                        fullWidth={true}
                    />
                    <EuiSpacer />
                    <EuiFlexGroup direction='row'>
                        <EuiFlexItem>
                            <EuiButton
                                isDisabled={!userHasPermission(permission, location.pathname, 'write')}
                                type='submit'
                                fill
                            >
                                Save
                            </EuiButton>
                        </EuiFlexItem>
                        {sectionState === 'update' ? (
                            <EuiFlexItem>
                                <EuiButton
                                    color='danger'
                                    isDisabled={!userHasPermission(permission, location.pathname, 'delete')}
                                    onClick={handleSectionDeletion}
                                    fill
                                >
                                    Delete
                                </EuiButton>
                            </EuiFlexItem>
                        ) : null}
                    </EuiFlexGroup>
                </EuiFlyoutBody>
            </EuiForm>
        </EuiFlyout>
    );
};

export default React.memo(SectionCreationFlyout);
