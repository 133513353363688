import React, { useState, useEffect } from 'react';
import MenuEditorTab from '../../../components/menuComponentsRefactorCopy/menuEditorTab/MenuEditorTab copy';
import { useSelector, useDispatch, batch } from 'react-redux';
import { SET_RESTAURANT_CATALOGUE } from '../../../components/menuComponentsRefactorCopy/Store/MenuTypes';
import {
    fetchOutletCatalogue,
    fetchRestaurantCatalogue,
} from '../../../components/menuComponentsRefactorCopy/catalogue/Api/FetchCatalogueInfo';
import { EuiLoadingContent } from '@elastic/eui';

const Catalogue = () => {
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);
    const isOutletMenu = useSelector((state) => state.menuReducer.isOutletMenu);
    const languageId = useSelector((state) => state.language.languageId);
    const dispatch = useDispatch();
    const [uiState, setUiState] = useState({
        isLoading: true,
        error: null,
    });

    useEffect(() => {
        let isCancelled = false;
        if (!isCancelled) {
            (async () => {
                try {
                    const responses = await Promise.all([
                        isOutletMenu
                            ? fetchOutletCatalogue(selectedOutletId, languageId)
                            : fetchRestaurantCatalogue(languageId),
                    ]);

                    if (!isCancelled) {
                        batch(() => {
                            dispatch({
                                type: SET_RESTAURANT_CATALOGUE,
                                payload: responses[0].categories,
                            });
                        });

                        setUiState((prevState) => ({
                            ...prevState,
                            isLoading: false,
                            error: null,
                        }));
                    }

                    return responses;
                } catch (error) {
                    setUiState((prevState) => ({
                        ...prevState,
                        isLoading: false,
                        error: error,
                    }));
                }
            })();
        }

        return () => {
            isCancelled = true;
        };
    }, [isOutletMenu, selectedOutletId, languageId, dispatch]);

    if (uiState.isLoading) {
        return <EuiLoadingContent lines={5} />;
    }

    return <MenuEditorTab />;
};

export default React.memo(Catalogue);
