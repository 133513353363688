import API from '../axios/API';

export const refundAmount = (selectedOrderId) => {
    return async (dispatch, getState) => {
        let response;

        try {
            response = await API.post(`restaurants/:restaurantId/orders/${selectedOrderId}/refund`);
        } catch (error) {
            response = error;
        }

        return response;
    };
};
