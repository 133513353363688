import { EuiButtonEmpty, EuiTableRowCell } from '@elastic/eui';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import API from '../../api/axios/API';
import AddUserFlyout from './addUserFlyout';

const EditUserSettings = ({ user, setPermissionUpdate }) => {
    const dispatch = useDispatch();
    const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const editUser = async (id) => {
        setIsLoading(true);
        const allUser = await API.get('restaurants/:restaurantId/users');
        let filterUser = null;
        filterUser =
            allUser &&
            allUser.users &&
            allUser.users.filter((item, index) => {
                if (item.user.id === id) {
                    return item.user;
                }
                return null;
            })[0];
        dispatch({
            type: 'edit_user',
            payload: {
                editUser: filterUser,
            },
        });
        setIsLoading(false);
        setIsFlyoutVisible(true);
    };

    return (
        <>
            {isFlyoutVisible && (
                <AddUserFlyout setIsFlyoutVisible={setIsFlyoutVisible} setPermissionUpdate={setPermissionUpdate} />
            )}

            <EuiTableRowCell onClick={() => editUser(user['id'])}>
                <EuiButtonEmpty isLoading={isLoading} size='s' iconType='documentEdit'>
                    {' '}
                    Edit{' '}
                </EuiButtonEmpty>
            </EuiTableRowCell>
        </>
    );
};

export default EditUserSettings;
