import API from '../axios/API';
import * as types from '../../reduxStore/types/customer';

export const getCustomerOrders = (startDateTime, endDateTime, state, orderingMode) => {
    return async (dispatch, getState) => {
        let response;
        let { customer } = getState();

        try {
            if (startDateTime && endDateTime) {
                response = await API.get(
                    `/restaurants/:restaurantId/customers/${customer.customerDetails.id}/orders?page=${
                        state.current_page + 1
                    }&per_page=${state.page_size}&tart_date=${startDateTime.format(
                        'YYYY-MM-DD'
                    )}&end_date=${endDateTime.format('YYYY-MM-DD')}${
                        orderingMode !== 'all' ? `&ordering_mode=${orderingMode}` : ''
                    }`
                );
            } else {
                response = await API.get(
                    `/restaurants/:restaurantId/customers/${customer.customerDetails.id}/orders?page=${
                        state.current_page + 1
                    }&per_page=${state.page_size}${orderingMode !== 'all' ? `&ordering_mode=${orderingMode}` : ''}`
                );
            }

            if (response.success) {
                dispatch({
                    type: types.SET_CUSTOMER_ORDER_DETAILS,
                    payload: {
                        customerOrderDetails: response.order_collection.orders.data,
                    },
                });
            }
        } catch (error) {
            response = error;
        }

        return response;
    };
};
