import { htmlIdGenerator } from '@elastic/eui';
import React from 'react';
import { CSVDownload } from 'react-csv';
import { useSelector } from 'react-redux';

export const PastOrderCsvDownload = (props) => {
    const { orders } = props;

    const languageId = useSelector((state) => state.language.languageId);
    let orderArray = [];

    orders.map((order) => {
        let innerObj = {
            'Order id': order.order_label,
            'Customer Name': order.customer.name,
            'Customer number': order.customer.mobile_number,
            'Customer email': order?.customer?.email,
            'Ordering Mode': order.ordering_mode,
            'Net total': order.net_total,
            'Packaging charge': order.cart.packaging_charge,
            Convenience: order.cart.convenience_charge,
            'Delivery charge': order.cart.delivery_charge,
            'GST amount': order.gst_amount,
            'Order placed at': order.status.place_at,
            'Payment method':
                order?.payment_method?.payment_method === 'CASH_ON_DELIVERY' ? 'Cash on delivery' : 'Online',
            'Outlet name': order?.outlet?.translations?.name[languageId],
            'Outlet address': order?.outlet?.outlet_address?.translations?.address_line_1[languageId],
            'Paid at': order.paid_at,
            'Restaurant accepted at': order.status.restaurant_accepted_at,
            'Restaurant ready at': order.status.restaurant_ready_at,
            'Rider accepted at': order.status.rider_accept_at,
            'Rider reached restaurant at': order.status.rider_reached_restaurant_at,
            'Rider picked up at': order.status.rider_picked_up_at,
            'Rider reached location at': order.status.rider_reached_to_location_at,
            'Rider delivered at': order.status.rider_delivered_at,
        };

        if (order.customer_address) {
            innerObj['CustomeraAddress'] =
                order.customer_address.block_number +
                ', ' +
                order.customer_address.city +
                ', ' +
                order.customer_address.state;
        }

        innerObj['Item count'] = order.cart.cart_items.length;

        order.cart.cart_items.map((item, index) => {
            innerObj[`item-${index + 1} name`] = '' + item.item.translations.title[languageId];
            innerObj[`item-${index + 1} quantity`] = item.quantity;

            item.customizations.map(
                (addon, i) =>
                    (innerObj[`item-${index + 1} Addon-${i + 1} + price`] =
                        addon.translations.name[languageId] + '_' + addon.price)
            );
            return null;
        });

        orderArray.push(innerObj);
        return null;
    });

    return (
        <div key={htmlIdGenerator()()}>
            <CSVDownload data={orderArray} />;
            {
                // setCsvState(false)
                props.csvStateCallback(false)
            }
        </div>
    );
};
