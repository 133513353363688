import React, { useState } from 'react';
import { EuiFormRow, EuiTextArea, EuiFlexItem } from '@elastic/eui';
import { Controller } from 'react-hook-form';
import './mobileNumberStyle.css';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const MobileNumber = (props) => {
    const { label, name, placeholder, rules, errors, isInvalid, control, setValue, watch } = props;

    const splitMethod = (code, number) => {
        return number.substring(code.length, number.length);
    };

    const handleOnChange = (value, data, event, formattedValue) => {
        let plainNumber = '';
        if (value) {
            let splittedData = splitMethod(data.dialCode, value);
            if (splittedData.length > 1) {
                setValue('selectedCountryNumber', {
                    dialCode: data.dialCode,
                    number: splittedData,
                });
                plainNumber = splittedData;
            } else {
                setValue('selectedCountryNumber', '');
            }
        }

        setValue(name, value, { shouldDirty: true });
    };

    const [inputStyle, setInputStyle] = useState({
        borderColor: '#e3e6ef',
        width: '98%',
        height: '40px',
        borderRadius: '1px',
        backgroundColor: '#fbfcfd',
    });

    const blurChange = () => {
        setInputStyle({ ...inputStyle, backgroundColor: '#fbfcfd', borderBottom: '' });
    };

    const onFocusChange = () => {
        setInputStyle({ ...inputStyle, backgroundColor: 'white', borderBottom: '2px solid blue' });
    };

    return (
        <EuiFlexItem style={{ marginLeft: '5px' }}>
            <Controller
                render={({ field }) => (
                    <EuiFormRow
                        style={{ width: '100%' }}
                        label={label}
                        isInvalid={isInvalid ? isInvalid : errors[name]}
                        error={errors[name] ? errors[name].message : ''}
                    >
                        <PhoneInput
                            onChange={handleOnChange}
                            value={field.value}
                            // isValid={validityCheck}
                            enableSearch={true}
                            country={'in'}
                            disableSearchIcon={true}
                            countryCodeEditable={false}
                            inputClass='mobileNumberContainerClass'
                            inputStyle={{
                                width: '98%',
                                height: '40px',
                                borderRadius: '1px',
                            }}
                            onBlur={blurChange}
                            onFocus={onFocusChange}
                        />
                    </EuiFormRow>
                )}
                name={name}
                control={control}
                rules={rules ? { required: 'Please Enter your contact number' } : {}}
            />
            <EuiFormRow label={''} style={{ display: 'none' }}>
                <Controller
                    render={({ field }) => <EuiTextArea value={field.value} onChange={field.onChange} />}
                    name={'selectedCountryNumber'}
                    control={control}
                />
            </EuiFormRow>
        </EuiFlexItem>
    );
};

export default MobileNumber;
