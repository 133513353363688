import React from 'react';
import { EuiFlexItem, EuiText, EuiButtonEmpty } from '@elastic/eui';
import { useSelector } from 'react-redux';
import { userHasPermission } from '../../../helper/auth';
import CategoryList from './CategoryList';
import { useHistory } from 'react-router';
import './style.css';
import CataloguePanelHeader from '../../../modules/Menu/Components/Catalogue/CataloguePanelHeader';

const MenuList = () => {
    const permissions = useSelector((state) => state.permission.permission);
    const history = useHistory();
    const isOutletMenu = useSelector((state) => state.menuReducer.isOutletMenu);

    return (
        <EuiFlexItem>
            <CataloguePanelHeader panelTitle='Menu'>
                <EuiText size='s'>
                    {userHasPermission(permissions, '/menu', 'write') && !isOutletMenu && (
                        <EuiButtonEmpty
                            size='s'
                            style={{ background: 'transparent' }}
                            onClick={() => history.push('/category/add')}
                        >
                            ADD NEW
                        </EuiButtonEmpty>
                    )}
                </EuiText>
            </CataloguePanelHeader>

            <CategoryList isOutletMenu={isOutletMenu} />
        </EuiFlexItem>
    );
};

export default MenuList;
