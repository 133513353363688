import { EuiButton, EuiFlexItem, EuiPopover, EuiSuperSelect } from '@elastic/eui';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import APIV3 from '../../api/axios/APIV3';
import { toastsErrorMessage, toastSuccessMessage } from '../../utils/toasts';

const ShiftTable = ({ selectedTable }) => {
    const sections = useSelector((state) => state.tableManagement.sectionList);
    const tableDetails = useSelector((state) => state.tableManagement.tableOrderDetails);
    const tableSessionDetails = useSelector((state) => state.tableManagement.tableOrderDetails?.current_session);
    const outletId = useSelector((state) => state.outlet.selectedOutletId);
    const dispatch = useDispatch();

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [tableOptions, setTableOptions] = useState([
        {
            value: '0',
            inputDisplay: 'Select table',
        },
    ]);

    useEffect(() => {
        if (sections?.length && !isEmpty(tableDetails) && !isEmpty(selectedTable)) {
            const tables = [];
            for (const section of sections) {
                if (tableDetails.ordering_mode_type === section.ordering_mode.ordering_mode_type) {
                    if (section?.tables?.length) {
                        for (const table of section.tables) {
                            if (table.id !== selectedTable.id) {
                                tables.push({
                                    value: table.id,
                                    inputDisplay: table.internal_name,
                                });
                            }
                        }
                    }
                }
            }
            setTableOptions([
                {
                    value: '0',
                    inputDisplay: 'Select table',
                },
                ...tables,
            ]);
        }
    }, [sections, tableDetails, selectedTable]);

    const onClosePopover = () => {
        setIsPopoverOpen(!isPopoverOpen);
    };

    const onTableChange = async (destination_table_id) => {
        setIsPopoverOpen(false);

        if (destination_table_id !== '0') {
            try {
                const response = await APIV3.patch(
                    `/restaurants/:restaurantId/outlets/${outletId}/outlet-tables/table-shift`,
                    {
                        destination_table_id: destination_table_id,
                        session_id: tableSessionDetails.id,
                    }
                );

                toastSuccessMessage(response?.message ? response?.message : 'Orders Shifted Successfully', dispatch);
            } catch (error) {
                toastsErrorMessage(
                    error?.message ? error?.message : 'Error Occured, Please try again later.',
                    dispatch
                );
            }
        }
    };

    return (
        <>
            <EuiPopover
                button={
                    <EuiButton fill size='s' iconType='kqlOperand' onClick={() => setIsPopoverOpen(true)}>
                        Shift Table
                    </EuiButton>
                }
                isOpen={isPopoverOpen}
                closePopover={onClosePopover}
            >
                <EuiFlexItem style={{ width: 300 }}>
                    <EuiSuperSelect options={tableOptions} value={tableOptions[0].value} onChange={onTableChange} />
                </EuiFlexItem>
            </EuiPopover>
        </>
    );
};

export default ShiftTable;
