import React, { useState, Fragment, useEffect, memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SuperSelection as SelectOutlet } from '../Components/SuperSelection';
import { SuperSelection as SelectOffer } from '../Components/SuperSelection';
import OutletOfferList from '../../../components/offer/OfferLink/outletOfferList';
import API from '../../../api/axios/API';
import { EuiSpacer, EuiFlexGroup, EuiFlexItem, EuiLoadingContent } from '@elastic/eui';
import { SET_SELECTED_OUTLET_ID } from '../../../reduxStore/types/outlet';
import { toastsErrorMessage, toastSuccessMessage } from '../../../utils/toasts';
import { isEmpty } from 'lodash';

const LinkOffer = () => {
    const outlets = useSelector((state) => state.outlet.outletList);
    const languageID = useSelector((state) => state.language.languageId);
    const dispatch = useDispatch();

    const [outletList, setOutletList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (outlets?.length) {
            const outletList = [];

            outlets?.forEach((item) =>
                outletList.push({
                    value: item.value,
                    label: `${item.inputDisplay} `,
                })
            );

            setOutletList(outletList);
        }
    }, [outlets]);

    const [selectedOutlet, setSelectedOffer] = useState([]);
    const [offerList, setOfferList] = useState([]);
    const [outletOfferList, setOutletOfferList] = useState({
        totalPages: null,
        offers: null,
    });

    const updateOfferData = useCallback(
        async (outletID) => {
            setIsLoading(true);
            const outletOfferList = await getOutletOfferList(outletID);

            try {
                const response = await API.get(`restaurants/:restaurantId/offers-titles`);
                let offerListForSelect = [];
                response.offers.forEach((item) =>
                    offerListForSelect.push({
                        value: item.offer_id,
                        label: item?.translations?.title?.[languageID] ? item?.translations?.title?.[languageID] : '',
                        offer_id: item.offer_id,
                    })
                );

                const offers = [];

                const filterOffers = response.offers.filter(
                    (offer) => !outletOfferList.offers?.some((outletOffer) => outletOffer.id === offer.offer_id)
                );

                if (filterOffers?.length) {
                    for (const offer of filterOffers) {
                        offers.push({
                            value: offer.offer_id,
                            label: offer?.translations?.title?.[languageID]
                                ? offer?.translations?.title?.[languageID]
                                : '',
                            offer_id: offer.offer_id,
                        });
                    }
                } else if (response?.offers?.length) {
                    response.offers.forEach((item) =>
                        offers.push({
                            value: item.offer_id,
                            label: item?.translations?.title?.[languageID]
                                ? item?.translations?.title?.[languageID]
                                : '',
                            offer_id: item.offer_id,
                        })
                    );
                }

                setOfferList(offers);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
            }
            setIsLoading(false);
        },
        [languageID]
    );

    const getOutletOfferList = async (outletID) => {
        let response = '';
        try {
            response = await API.get(`restaurants/:restaurantId/outlets/${outletID}/offers`);

            setOutletOfferList((prevState) => ({
                ...prevState,
                offers: response.offers,
                totalPages: response.offers,
            }));
        } catch (e) {
            alert('error');
        }

        return response;
    };

    const onChangeOutlet = async (value) => {
        const outlet = value?.[0];
        if (!isEmpty(outlet) && outlet?.value !== selectedOutlet?.[0]?.value) {
            setSelectedOffer([outlet]);
            dispatch({
                type: SET_SELECTED_OUTLET_ID,
                payload: {
                    selectedOutletId: outlet.value,
                },
            });
            await updateOfferData(outlet.value);
        } else if (isEmpty(outlet)) {
            setSelectedOffer([]);
        }
    };

    const onChangeOffer = async (value) => {
        try {
            await API.patch(
                `restaurants/:restaurantId/outlets/${selectedOutlet[0].value}/offers/${value[0].value}/link`
            );
            toastSuccessMessage('Offer Linked Successfully', dispatch);

            await getOutletOfferList(selectedOutlet[0].value);
        } catch (error) {
            toastsErrorMessage(error?.message, dispatch);
        }
    };

    const handleUnlink = useCallback(
        async (id) => {
            try {
                await API.patch(`restaurants/:restaurantId/outlets/${selectedOutlet[0].value}/offers/${id}/un-link`);
                toastSuccessMessage('Offer Unlinked Successfully', dispatch);

                await updateOfferData(selectedOutlet[0].value);
            } catch (e) {
                toastsErrorMessage('Please try again later.', dispatch);
            }
        },
        [dispatch, selectedOutlet, updateOfferData]
    );

    return (
        <Fragment>
            <EuiFlexGroup wrap style={{ marginTop: 10 }}>
                <EuiFlexItem>
                    <EuiFlexGroup alignItems='center'>
                        <EuiFlexItem grow={false}>Select Outlet</EuiFlexItem>
                        <EuiFlexItem>
                            <SelectOutlet
                                isLoading={isLoading}
                                options={outletList}
                                valueOfSelected={selectedOutlet}
                                onChange={onChangeOutlet}
                                placeholder='Please select an outlet'
                            />
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>
                <EuiFlexItem style={{ marginTop: 10 }}>
                    <EuiFlexGroup alignItems='center'>
                        <EuiFlexItem grow={false}>Select Offer</EuiFlexItem>
                        <EuiFlexItem>
                            <SelectOffer
                                isLoading={isLoading}
                                options={offerList}
                                outlet={selectedOutlet}
                                onChange={onChangeOffer}
                                placeholder={!selectedOutlet?.length ? 'Select an outlet first' : 'Select an offer'}
                            />
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer />

            {/* display offer list */}
            {!isLoading ? (
                <>
                    {outletOfferList.offers?.length && (
                        <OutletOfferList
                            outletOfferList={outletOfferList.offers}
                            setOutletOfferList={setOutletOfferList}
                            totalPageCount={outletOfferList.totalPages}
                            handleUnlink={handleUnlink}
                        />
                    )}
                </>
            ) : (
                <EuiLoadingContent lines={4} />
            )}
        </Fragment>
    );
};

export default memo(LinkOffer);
