import React, { useCallback, useEffect, useState } from 'react';
import { EuiFlexItem, EuiText, EuiFlexGroup, EuiPanel, EuiSpacer } from '@elastic/eui';
import FormGroupDescription from '../../../../components/formGroupDescription';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOutletOrderingModes } from '../../../../api/outlet/fetchOutletOrderingModes';
import { fetchRestaurantOrderingModes } from '../../../../components/settings/RestaurantSettings/Utils/utils';
import OutletOrderingModeSwitch from '../../../../components/settings/RestaurantSettings/OutletOrderingModeSwitch';
import { updateOutletOrderingModes } from '../../../../components/settings/outlet-form/utils';
import CheckBoxField from '../../../../components/Form/CheckBoxField';
import useOutletFormValues from '../../Hooks/useOutletFormValues';
import useFormActionsHandler from '../../../../hooks/useFormActionsHandler';

const OrderingMode = () => {
    const dispatch = useDispatch();
    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);
    const outletId = useSelector((state) => state.outlet.selectedOutletId);
    const orderingModes = useSelector((state) => state.restaurantReducer.orderingModes);
    const availableLanguages = useSelector((state) => state.language.availableLanguages);
    const outletOrderingModes = useSelector((state) => state?.outlet?.outletOrderingModes?.ordering_modes);
    const languageId = useSelector((state) => state.language.languageId);
    const [list, setList] = useState([]);
    const doesDineInTabExist = list?.filter((mode) => mode.tab === 'dine_in' && mode.status);

    useEffect(() => {
        if (orderingModes?.length) {
            setList([...orderingModes]);
        }
    }, [orderingModes]);

    const updateFormValues = useCallback(
        (data) => {
            let defaultValue = {};
            defaultValue['ordering_modes'] = data;
            for (const mode of orderingModes) {
                for (const outletMode of outletOrderingModes) {
                    if (outletMode.restaurant_ordering_mode_id === mode.id) {
                        defaultValue[`${mode.type}_${mode.id}`] = outletMode.is_enabled;
                        defaultValue[`is_processing_${mode.type}_${mode.id}`] = outletMode.is_processing;
                        if (outletMode.translations.display_name[languageId] === 'In Store') {
                            defaultValue['in_store_for_dine_in'] = true;
                        }
                    }
                }
            }

            return defaultValue;
        },
        [languageId, orderingModes, outletOrderingModes]
    );

    const { defaultValues, setDefaultValues, onSuccessCallback } = useOutletFormValues({
        updateFormValues,
        customPayload: orderingModes,
        sideEffectForReset: outletOrderingModes,
    });

    const onFormSaveApi = async (data) => {
        let structuredModes = [];
        data.ordering_modes.forEach((orderingMode, index) => {
            let languageObj = {};
            for (const language of availableLanguages) {
                if (orderingMode.tab === 'dine_in') {
                    if (data.in_store_for_dine_in) {
                        languageObj[language.language_id] = 'In Store';
                    } else {
                        languageObj[language.language_id] = 'Dine In';
                    }
                } else {
                    languageObj[language.language_id] = orderingMode.display_name;
                }
            }

            let obj = {
                restaurant_ordering_mode_id: orderingMode.id,
                is_enabled: watch(`${orderingMode.type}_${orderingMode.id}`)
                    ? watch(`${orderingMode.type}_${orderingMode.id}`)
                    : false,
                translations: {
                    display_name: languageObj,
                },
                show_on_home_screen: true,
            };

            structuredModes.push(obj);
        });

        const response = await updateOutletOrderingModes(
            outletId,
            { ordering_modes: structuredModes },
            'ordering_mode'
        );

        if (response.success) {
            // setDefaultValues(updateFormValues(response.outlet));
            getOrderingModes();
        }

        return response;
    };

    const { control, setValue, watch } = useFormActionsHandler({ onFormSaveApi, defaultValues, onSuccessCallback });

    const getOrderingModes = useCallback(async () => {
        dispatch(fetchOutletOrderingModes(outletId));
        dispatch(fetchRestaurantOrderingModes()).then((response) => {
            if (response.success) {
                setDefaultValues(updateFormValues(response.ordering_modes));
            }
        });
    }, [dispatch, outletId]);

    useEffect(() => {
        getOrderingModes();
    }, [getOrderingModes, outletId]);

    const handleChange = useCallback(
        async (event, id, setIsActive, selectedMode) => {
            const list = watch('ordering_modes');
            const isChecked = event.target.checked;
            for (let index = 0; index < list.length; index++) {
                const element = list[index];

                if (selectedMode.type === 'self_serve') {
                    if (element.type === 'qsr' || element.type === 'fine_dine') {
                        setValue(`${element.type}_${element.id}`, false, { shouldDirty: true });
                        setValue(`ordering_modes[${index}].status`, false, { shouldDirty: true });
                    } else if (element.id === id) {
                        setValue(`${element.type}_${element.id}`, isChecked, { shouldDirty: true });
                        setValue(`ordering_modes[${index}].status`, isChecked, { shouldDirty: true });
                    }
                } else if (selectedMode.type === 'qsr' || selectedMode.type === 'fine_dine') {
                    if (element.type === 'self_serve') {
                        setValue(`${element.type}_${element.id}`, false, { shouldDirty: true });
                        setValue(`ordering_modes[${index}].status`, false, { shouldDirty: true });
                    } else if (element.id === id) {
                        setValue(`${element.type}_${element.id}`, isChecked, { shouldDirty: true });
                        setValue(`ordering_modes[${index}].status`, isChecked, { shouldDirty: true });
                    }
                } else {
                    if (element.id === id) {
                        setValue(`${element.type}_${element.id}`, isChecked, { shouldDirty: true });
                        setValue(`ordering_modes[${index}].status`, isChecked, { shouldDirty: true });
                    }
                }
            }

            setIsActive(isChecked);
        },
        [setValue, watch]
    );

    return (
        <>
            <FormGroupDescription
                title={restaurantDescriptions?.ordering_mode?.display_name}
                description={restaurantDescriptions?.ordering_mode?.description}
            >
                {/* <LoadingOverlay active={isProcessing} spinner text='Processing...'> */}
                {list
                    .filter((orderingMode) => orderingMode.status)
                    .map((mode, idx) => {
                        return (
                            <React.Fragment key={mode.id.toString()}>
                                <EuiSpacer size={`${idx !== 0 ? 's' : null}`} />
                                <EuiPanel paddingSize='s'>
                                    <EuiFlexGroup justifyContent='spaceBetween' alignItems='center' gutterSize='s'>
                                        <EuiFlexItem>
                                            <EuiFlexGroup
                                                justifyContent='spaceBetween'
                                                alignItems='center'
                                                gutterSize='s'
                                            >
                                                <EuiFlexItem grow={false}></EuiFlexItem>
                                                <EuiFlexItem>
                                                    <EuiText style={{ textTransform: 'capitalize' }}>
                                                        {mode.display_name?.includes('_')
                                                            ? mode.display_name.replace('_', ' ')
                                                            : mode.display_name}
                                                    </EuiText>
                                                </EuiFlexItem>
                                            </EuiFlexGroup>
                                        </EuiFlexItem>

                                        <EuiFlexItem grow={false}>
                                            <OutletOrderingModeSwitch
                                                watch={watch}
                                                mode={mode}
                                                control={control}
                                                handleChange={handleChange}
                                            />
                                        </EuiFlexItem>
                                    </EuiFlexGroup>
                                </EuiPanel>
                            </React.Fragment>
                        );
                    })}
                {/* </LoadingOverlay> */}
            </FormGroupDescription>
            {doesDineInTabExist?.length ? (
                <CheckBoxField
                    control={control}
                    name='in_store_for_dine_in'
                    setValue={setValue}
                    label='Use In Store Instead of Dine In'
                />
            ) : null}
        </>
    );
};

export default React.memo(OrderingMode);
