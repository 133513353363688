import { REMOVE_TOAST, SET_TOAST_STATE } from '../types/Global/Global';

const initialState = {
    toasts: [],
};

export default function toastReducer(state = initialState, action) {
    switch (action.type) {
        case SET_TOAST_STATE:
            return {
                ...state,
                toasts: [...state.toasts, action.payload],
            };
        case REMOVE_TOAST:
            const toasts = state.toasts.filter((toast) => toast.id !== action.payload.id);

            return {
                ...state,
                toasts: toasts,
            };

        default:
            return state;
    }
}
