import React from 'react';
import { EuiPopover, EuiForm, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiButton } from '@elastic/eui';
import TimePicker from 'react-time-picker';
import { Controller, useForm } from 'react-hook-form';

const TimingPopover = (props) => {
    const { append, setIsPopoverOpen, isPopoverOpen, setTimeSlotSave } = props;
    const {
        handleSubmit,
        control,
        watch,
        setValue,

        formState: { errors },
    } = useForm();

    const submitValues = (data) => {};

    return (
        <>
            <EuiPopover
                button={props.button}
                isOpen={isPopoverOpen}
                closePopover={() => setIsPopoverOpen(false)}
                anchorPosition='upCenter'
            >
                <EuiForm component='form' onSubmit={handleSubmit(submitValues)}>
                    <EuiFlexGroup alignItems='center' style={{ margin: '5px' }}>
                        <EuiFlexItem grow={8}>
                            <EuiFlexGroup>
                                <EuiFlexItem>
                                    <EuiFormRow>
                                        <Controller
                                            render={({}) => (
                                                <TimePicker
                                                    onChange={(time) => {
                                                        setValue('start_time', time, { shouldDirty: true });
                                                    }}
                                                    format='hh:mm a'
                                                    amPmAriaLabel='Select AM/PM'
                                                    closeClock={true}
                                                    disableClock={true}
                                                    required={true}
                                                />
                                            )}
                                            name={'start_time'}
                                            control={control}
                                            fullWidth={true}
                                            rules={{ required: 'Please enter proper date' }}
                                            errors={errors}
                                        />
                                    </EuiFormRow>
                                </EuiFlexItem>
                                <EuiFlexItem>
                                    <EuiFormRow>
                                        <Controller
                                            render={({}) => (
                                                <TimePicker
                                                    onChange={(time) => {
                                                        setValue('end_time', time, { shouldDirty: true });
                                                    }}
                                                    format='hh:mm a'
                                                    amPmAriaLabel='Select AM/PM'
                                                    closeClock={true}
                                                    disableClock={true}
                                                    required={true}
                                                />
                                            )}
                                            name={'end_time'}
                                            control={control}
                                            fullWidth={true}
                                            rules={{ required: 'Please enter proper date' }}
                                            errors={errors}
                                        />
                                    </EuiFormRow>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </EuiFlexItem>
                        <EuiFlexItem grow={1}>
                            <EuiFlexGroup alignItems={'center'}>
                                <EuiFlexItem>
                                    <EuiButton
                                        type='submit'
                                        onClick={() => {
                                            // console.log("time ref ", setTimeSlotSave)
                                            // if(setTimeSlotSave) setTimeSlotSave(true);
                                            if (watch('start_time') && watch('end_time')) {
                                                append({
                                                    // id: htmlIdGenerator()(),
                                                    start_time: watch('start_time'),
                                                    end_time: watch('end_time'),
                                                });

                                                setIsPopoverOpen(false);
                                            }
                                        }}
                                    >
                                        Save
                                    </EuiButton>
                                </EuiFlexItem>
                                <EuiFlexItem></EuiFlexItem>
                            </EuiFlexGroup>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiForm>
            </EuiPopover>
        </>
    );
};

export default TimingPopover;
