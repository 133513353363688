import API from '../axios/API';

export const getAbandonedOrders = (startDateTime, endDateTime, state, orderingMode) => {
    return async (dispatch, getState) => {
        let response;
        let { outlet, orders } = getState();

        try {
            if (startDateTime && endDateTime) {
                response = await API.get(
                    `/restaurants/:restaurantId/outlets/${outlet.selectedOutletId}/carts?abandoned=1&page=${
                        state.current_page + 1
                    }&per_page=${state.page_size}&start_date=${startDateTime
                        .startOf('day')
                        .format('YYYY-MM-DD HH:mm:ss')}&end_date=${endDateTime
                        .endOf('day')
                        .format('YYYY-MM-DD HH:mm:ss')}${
                        orderingMode !== 'all' ? `&ordering_mode=${orderingMode}` : ''
                    }`
                );
            } else {
                response = await API.get(
                    `/restaurants/:restaurantId/outlets/${outlet.selectedOutletId}/carts?abandoned=1&page=${
                        state.current_page + 1
                    }&per_page=${state.page_size}${orderingMode !== 'all' ? `&ordering_mode=${orderingMode}` : ''}`
                );
            }
        } catch (error) {
            response = error;
        }

        if (response.success) {
            dispatch({
                type: 'set-abandoned-carts',
                payload: response.carts.data.data,
            });
        }

        return response;
    };
};
