import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiLoadingContent, EuiPanel } from '@elastic/eui';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchOrderingModes } from '../../api/outlet/fetchOrderingModes';
import { userHasPermission } from '../../helper/auth';
import AddSection from './AddSection';
import AddTable from './AddTable';
import { fetchOutletSections } from './Api/fetchOutletSections';
import Sections from './Sections';

const TableManagementScreen = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const outletId = useSelector((state) => state.outlet.selectedOutletId);
    const [isEditTableMode, setIsEditTableMode] = useState(false);
    const sections = useSelector((state) => state.tableManagement.sectionList);
    const permissions = useSelector((state) => state.permission.permission);
    const [isLoading, setIsLoading] = useState(false);

    const handleEditTables = useCallback(() => {
        setIsEditTableMode((prevState) => !prevState);
    }, []);

    useEffect(() => {
        (async () => {
            if (outletId) {
                setIsLoading(true);
                await dispatch(fetchOutletSections());
                await dispatch(fetchOrderingModes());
                setIsLoading(false);
            }
        })();
    }, [dispatch, outletId]);

    useEffect(() => {
        if (!sections?.length) {
            setIsEditTableMode(false);
        }
    }, [sections]);

    if (isLoading) {
        return (
            <EuiPanel>
                <EuiLoadingContent lines={3} />
            </EuiPanel>
        );
    }

    return (
        <>
            <EuiFlexGroup direction='row' justifyContent='flexEnd' gutterSize='s'>
                <EuiFlexItem grow={false}>
                    <AddTable isEditTableMode={isEditTableMode} />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <AddSection isEditTableMode={isEditTableMode} />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiButton
                        fill={!isEditTableMode}
                        disabled={
                            !sections?.length || !userHasPermission(permissions, history.location.pathname, 'write')
                        }
                        onClick={handleEditTables}
                    >
                        {!isEditTableMode ? 'Edit Modes' : 'Cancel Edit'}
                    </EuiButton>
                </EuiFlexItem>
            </EuiFlexGroup>

            <Sections isEditTableMode={isEditTableMode} />
        </>
    );
};

export default React.memo(TableManagementScreen);
