import React from 'react';
import { EuiCard, EuiCollapsibleNavGroup, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiTitle } from '@elastic/eui';

const ItemDetailsCollaspibleNav = (props) => {
    return (
        <>
            <EuiCard
                layout='horizontal'
                style={{ margin: '0 auto', width: '95%', minWidth: '90%', maxWidth: '95%' }}
                title={
                    <EuiFlexGroup direction='row' justifyContent='spaceBetween' alignItems='center' gutterSize='xs'>
                        <EuiFlexItem grow={10}>
                            <EuiFlexGroup justifyContent='flexStart' alignItems='center' direction='row' gutterSize='s'>
                                {props?.icon ? <EuiFlexItem grow={false}>{props.icon}</EuiFlexItem> : null}
                                <EuiFlexItem grow={10}>
                                    <EuiFlexGroup
                                        justifyContent='flexStart'
                                        direction='column'
                                        alignItems='flexStart'
                                        gutterSize='xs'
                                    >
                                        <EuiFlexItem>
                                            <EuiFlexGroup
                                                direction='row'
                                                justifyContent='flexStart'
                                                alignItems='center'
                                                gutterSize='s'
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    props.setIsNavOpen((prevState) => !prevState),
                                                        props.fetchAddons && props.fetchAddons({ keyword: '' });
                                                }}
                                            >
                                                <EuiFlexItem grow={false}>
                                                    <EuiTitle style={{ userSelect: 'none' }} size='s'>
                                                        <h3>{props.cardTitle}</h3>
                                                    </EuiTitle>
                                                </EuiFlexItem>
                                                <EuiFlexItem grow={10}>
                                                    {!props.isNavOpen ? (
                                                        <>
                                                            <EuiIcon
                                                                type='pencil'
                                                                color='primary'
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                        </>
                                                    ) : null}
                                                </EuiFlexItem>
                                            </EuiFlexGroup>
                                        </EuiFlexItem>
                                        <EuiFlexItem>{props.cardDescription}</EuiFlexItem>
                                    </EuiFlexGroup>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </EuiFlexItem>
                        <EuiFlexItem>
                            {props.isNavOpen ? (
                                <EuiFlexItem
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => props.setIsNavOpen((prevState) => !prevState)}
                                >
                                    <EuiIcon type='cross' color='danger' />
                                </EuiFlexItem>
                            ) : null}
                        </EuiFlexItem>
                    </EuiFlexGroup>
                }
            >
                <EuiCollapsibleNavGroup
                    style={{
                        marginBottom: props.isNavOpen ? '0' : '-35px',
                        marginTop: props.isNavOpen ? '-30px' : '0',
                    }}
                    title={<></>}
                    titleSize='xs'
                    isCollapsible={true}
                    initialIsOpen={props.isNavOpen}
                    forceState={props.isNavOpen ? 'open' : 'closed'}
                    arrowDisplay={'none'}
                >
                    {props.children}
                </EuiCollapsibleNavGroup>
            </EuiCard>
        </>
    );
};

export default React.memo(ItemDetailsCollaspibleNav);
