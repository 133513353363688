import {
    EuiFlexGroup,
    EuiFlexItem,
    EuiSpacer,
    EuiButton,
    EuiCollapsibleNavGroup,
    EuiText,
    EuiLink,
} from '@elastic/eui';
import React, { useState } from 'react';
import { useCallback } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Badge from './Badge';

const Badges = ({ control, setValue, formErrors, register, watch, isOutletMenu }) => {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const availableLanguages = useSelector((state) => state.language.availableLanguages);
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'badges',
    });

    const handleAppend = useCallback(() => {
        if (availableLanguages?.length) {
            let titles = {};
            availableLanguages.forEach((language) => {
                titles[language.language_id] = '';
            });

            let translationObject = {
                display_name: titles,
            };

            const newBadgeObj = {
                translations: translationObject,
            };

            append(newBadgeObj);
        }
    }, [availableLanguages, append]);

    return (
        <>
            <EuiFlexGroup gutterSize='none' direction='column' justifyContent='flexStart' alignItems='flexStart'>
                <EuiFlexItem></EuiFlexItem>
                <EuiFlexItem>
                    <EuiCollapsibleNavGroup
                        title={
                            <EuiLink color='primary'>
                                <EuiText size='xs'>
                                    <span>{isNavOpen ? '-' : '+'} </span> Manage Badges
                                </EuiText>
                            </EuiLink>
                        }
                        titleSize='xxs'
                        titleElement='h6'
                        isCollapsible={true}
                        initialIsOpen={false}
                        arrowDisplay={'none'}
                        onToggle={(isOpen) => setIsNavOpen(isOpen)}
                    >
                        {fields.map((badge, index) => {
                            return (
                                <Badge
                                    watch={watch}
                                    badge={badge}
                                    index={index}
                                    key={badge.id}
                                    control={control}
                                    remove={remove}
                                    setValue={setValue}
                                    formErrors={formErrors}
                                    register={register}
                                    isOutletMenu={isOutletMenu}
                                />
                            );
                        })}

                        {fields?.length ? <EuiSpacer size='s' /> : null}
                        <EuiFlexGroup gutterSize='none'>
                            <EuiFlexItem>
                                <EuiButton disabled={isOutletMenu} color='success' onClick={handleAppend}>
                                    Create new badge
                                </EuiButton>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </EuiCollapsibleNavGroup>
                </EuiFlexItem>
            </EuiFlexGroup>
        </>
    );
};

export default React.memo(Badges);
