import {
    EuiComboBox,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow,
    EuiLink,
    EuiSpacer,
    EuiText,
    EuiTextColor,
} from '@elastic/eui';
import { isEmpty } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { autoSuggestionVariants } from './utils/autoSuggestionItems';
import { getErrorMesage, getItem, getTaxAmount } from './utils/utils';

const VariantLinkOrderingModes = ({
    optionOrderingMode,
    optionIndex,
    category_id,
    fieldIndex,
    selectedParentOption,
    option,
    uniqueVariantOptions,
    modeIndex,
    selectedItemForVariants,
    item,
}) => {
    const [suggestionVariants, setSuggestionVariants] = useState([]);
    const petPoojaItems = useSelector((state) => state.petPoojaReducer.petPoojaItems);
    const selectedOutlet = useSelector((state) => state.outlet.selectedOutlet);

    const {
        control,
        watch,
        formState: { isDirty },
        setValue,
    } = useFormContext();
    const linkedItems = useSelector((state) => state.petPoojaReducer.petPoojaLinkedItems);
    const [amountError, setAmountError] = useState([]);
    const petPoojaTaxes = useSelector((state) => state.petPoojaReducer.petPoojaTaxes);
    const petPoojaRestaurant = useSelector((state) => state.petPoojaReducer.petPoojaRestaurant);
    const languageId = useSelector((state) => state.language.languageId);
    const isItemSelected = useWatch({
        control,
        name: `item_${category_id}[${fieldIndex}.ordering_modes[${modeIndex}].selected_item]`,
    });

    const selectedVariant = useWatch({
        control,
        name: `item_${category_id}[${fieldIndex}].variants[0].options[${optionIndex}].ordering_modes[${modeIndex}].selected_item`,
    });

    // const [isDisabled] = useState(
    //     item.category_ids.findIndex((element) => element === category_id) > 0 && item.is_mapped_item ? true : false
    // );

    useEffect(() => {
        if (selectedItemForVariants?.[0]?.variation?.length) {
            const variants = autoSuggestionVariants(
                optionOrderingMode,
                option,
                petPoojaTaxes,
                languageId,
                selectedItemForVariants[0]
            ).variants;
            setSuggestionVariants(variants);
        }
    }, [selectedItemForVariants, optionOrderingMode, option]);

    useEffect(() => {
        if (!selectedItemForVariants?.[0]?.variation?.length) {
            setValue(
                `item_${category_id}[${fieldIndex}].variants[0].options[${optionIndex}].ordering_modes[${modeIndex}].selected_item`,
                [],
                { shouldDirty: true }
            );
        }
    }, [selectedItemForVariants?.[0]?.variation]);

    useEffect(() => {
        if (selectedVariant?.length && selectedItemForVariants?.[0]?.variation?.length && isDirty) {
            const isVariantLinkedCorrectly = selectedItemForVariants?.[0]?.variation.some(
                (variant) => variant.id === selectedVariant[0].id || variant.id === selectedVariant[0].variant_item_id
            );

            if (!isVariantLinkedCorrectly) {
                setValue(
                    `item_${category_id}[${fieldIndex}].variants[0].options[${optionIndex}].ordering_modes[${modeIndex}].selected_item`,
                    [],
                    { shouldDirty: true }
                );
            }
        }
    }, [selectedVariant, selectedItemForVariants?.[0]?.variation, isDirty]);

    useEffect(() => {
        if (!isDirty) {
            setAmountError([]);
        }
    }, [isDirty]);

    const handleRegisterSuggestionAction = useCallback(
        (selectedVariant) => {
            if (!isEmpty(selectedVariant)) {
                selectedVariant.module_id = option.id;
                selectedVariant.restaurant_ordering_mode_id = optionOrderingMode.restaurant_ordering_mode_id;
                selectedVariant.label = selectedVariant.name;
                setValue(
                    `item_${category_id}[${fieldIndex}].variants[0].options[${optionIndex}].ordering_modes[${modeIndex}].selected_item`,
                    [selectedVariant],
                    {
                        shouldDirty: true,
                    }
                );
            }
        },
        [
            category_id,
            fieldIndex,
            modeIndex,
            option.id,
            optionIndex,
            optionOrderingMode.restaurant_ordering_mode_id,
            setValue,
        ]
    );

    const checkVariantValidation = useCallback(
        (selectedOption) => {
            let isItemValid = false;
            if (!selectedOption?.length) {
                isItemValid = true;
                setAmountError([]);
            } else {
                let itemResponse = getItem(
                    selectedParentOption?.[0]?.itemid || selectedParentOption?.[0]?.id,
                    petPoojaItems
                );
                let { taxArrayObj, taxAmount } = getTaxAmount(itemResponse, petPoojaTaxes, optionOrderingMode.price);
                selectedOption[0] = {
                    ...selectedOption[0],
                    tax: taxArrayObj,
                };
                let fabSystemTaxAmount = (optionOrderingMode.price * optionOrderingMode.gst_slab) / 100;

                let errorCheck = false;
                let errorArray = [];
                for (const orderingMode of option?.ordering_modes) {
                    if (orderingMode?.restaurant_ordering_mode_id === optionOrderingMode.restaurant_ordering_mode_id) {
                        if (
                            parseFloat(orderingMode.price).toFixed(2) !== parseFloat(selectedOption[0].price).toFixed(2)
                        ) {
                            errorCheck = true;
                            errorArray.push(
                                `Fab system price ${orderingMode.price} ≠ Pet Pooja price ${selectedOption[0].price}`
                            );
                        }

                        if (
                            orderingMode.packaging_charge != selectedOption[0].item_packingcharges &&
                            petPoojaRestaurant?.[0]?.details?.packaging_applicable_on === 'ITEM'
                        ) {
                            errorCheck = true;
                            errorArray.push(
                                `Packaging Fab System ${orderingMode.packaging_charge} ≠ Pacakging Pet Pooja ${selectedOption[0].item_packingcharges}`
                            );
                        }

                        if (fabSystemTaxAmount != taxAmount) {
                            errorCheck = true;
                            errorArray.push(`Tax Fab System ${fabSystemTaxAmount} ≠ Tax Pet Pooja ${taxAmount}`);
                        }
                    }
                }
                setAmountError(errorArray);

                if (errorArray?.length || errorCheck) {
                    isItemValid = false;
                } else {
                    isItemValid = true;
                }
            }
            return isItemValid;
        },
        [
            option?.ordering_modes,
            optionOrderingMode.gst_slab,
            optionOrderingMode.price,
            optionOrderingMode.restaurant_ordering_mode_id,
            petPoojaItems,
            petPoojaRestaurant,
            petPoojaTaxes,
            selectedParentOption,
        ]
    );

    const handleVariantLinkChange = useCallback(
        (selectedValue, item) => {
            if (selectedValue?.length) {
                selectedValue[0].module_id = option.id;
                selectedValue[0].restaurant_ordering_mode_id = optionOrderingMode.restaurant_ordering_mode_id;
            }

            setValue(
                `item_${category_id}[${fieldIndex}].variants[0].options[${optionIndex}].ordering_modes[${modeIndex}].selected_item`,
                selectedValue,
                {
                    shouldDirty: true,
                }
            );

            // UNLINK LOGIC
            if (!selectedValue?.length && item?.length && linkedItems?.length) {
                const currentRemovedItem = item[0];
                for (const linkedItem of linkedItems) {
                    if (
                        (linkedItem?.third_party_module_id === currentRemovedItem?.variant_item_id ||
                            linkedItem?.third_party_module_id === currentRemovedItem?.variationid) &&
                        linkedItem?.restaurant_ordering_mode_id === currentRemovedItem?.restaurant_ordering_mode_id &&
                        currentRemovedItem?.module_id === linkedItem?.module_id
                    ) {
                        currentRemovedItem.third_party_module_id = currentRemovedItem.id;

                        const currentRemovedItemWithOption = {
                            ...currentRemovedItem,
                            ...linkedItem,
                            third_party_module_id: currentRemovedItem.variationid,
                            module_name: 'variant_option',
                        };

                        if (watch('menu_unlinks')?.length) {
                            setValue(
                                'menu_unlinks',
                                [
                                    ...watch('menu_unlinks'),
                                    { ...currentRemovedItem, ...linkedItem },
                                    currentRemovedItemWithOption,
                                ],
                                {
                                    shouldDirty: true,
                                }
                            );
                        } else {
                            setValue(
                                'menu_unlinks',
                                [{ ...currentRemovedItem, ...linkedItem }, currentRemovedItemWithOption],
                                {
                                    shouldDirty: true,
                                }
                            );
                        }
                    }
                }
            }

            // REMOVE UNLINK VARAINT FROM UNLINK STATE IF LINKED BACK

            if (selectedValue?.length && watch('menu_unlinks')?.length) {
                const removeItemFromUnlinkState = watch('menu_unlinks')?.filter((menu_unlink) => {
                    return !(
                        menu_unlink.variationid === selectedValue[0].variationid &&
                        menu_unlink.restaurant_ordering_mode_id === optionOrderingMode.restaurant_ordering_mode_id &&
                        selectedValue[0].module_id === menu_unlink.module_id
                    );
                });
                setValue('menu_unlinks', removeItemFromUnlinkState, { shouldDirty: true });
            }
        },
        [category_id, fieldIndex, linkedItems, modeIndex, option?.id, optionIndex, setValue, watch, optionOrderingMode]
    );

    return (
        <div id={`variant_${option.id}`}>
            <EuiFormRow
                label={`${optionOrderingMode.restaurant_ordering_mode_name} (${optionOrderingMode.price} ${selectedOutlet.outlet_data?.settings?.currency_code})`}
            >
                <Controller
                    control={control}
                    name={`item_${category_id}[${fieldIndex}].variants[0].options[${optionIndex}].ordering_modes[${modeIndex}].selected_item`}
                    rules={{
                        validate: {
                            positive: (selectedOption) => {
                                // return checkVariantValidation(selectedOption);
                                checkVariantValidation(selectedOption);
                                return true;
                            },
                        },
                    }}
                    render={({ field }) => {
                        return (
                            <EuiComboBox
                                isClearable={true}
                                singleSelection={true}
                                selectedOptions={field.value}
                                // isDisabled={isDisabled}
                                onBlur={field.onBlur}
                                onChange={(selectedValue) => {
                                    field.onChange(selectedValue);
                                    handleVariantLinkChange(selectedValue, field.value);
                                }}
                                options={isItemSelected?.length ? uniqueVariantOptions : []}
                                placeholder={
                                    isItemSelected?.length
                                        ? uniqueVariantOptions?.length
                                            ? 'Select variant'
                                            : 'No variant found for this item '
                                        : 'Select parent item first'
                                }
                            />
                        );
                    }}
                />
            </EuiFormRow>
            {suggestionVariants?.length && isItemSelected?.length && uniqueVariantOptions?.length ? (
                <>
                    <EuiSpacer size='s' />
                    <EuiFlexItem grow={false}>
                        <EuiText size='xs' style={{ fontWeight: 600 }}>
                            Suggested Match
                        </EuiText>
                    </EuiFlexItem>
                    <EuiFlexGroup style={{ maxWidth: 300 }} wrap>
                        {suggestionVariants?.slice(0, 2)?.map(({ item }, index) => {
                            return (
                                <EuiFlexItem
                                    grow={false}
                                    key={index.toString()}
                                    onClick={() => handleRegisterSuggestionAction(item)}
                                >
                                    <EuiLink style={{ cursor: 'pointer' }} key={index.toString()}>
                                        {item.name} ({item.price} {selectedOutlet.outlet_data?.settings?.currency_code})
                                    </EuiLink>
                                </EuiFlexItem>
                            );
                        })}
                    </EuiFlexGroup>
                    <EuiSpacer size='xs' />
                </>
            ) : null}
            <EuiSpacer size='s' />
            {!isEmpty(amountError) ? <EuiTextColor color='danger'>{getErrorMesage(amountError)}</EuiTextColor> : ''}
            <EuiSpacer size='s' />
        </div>
    );
};

export default React.memo(VariantLinkOrderingModes);
