import API from '../axios/API';
import * as types from '../../reduxStore/types/orders';

export const getNewOrdersCount = () => {
    return async (dispatch, getState) => {
        let liveOrderResponse;

        try {
            liveOrderResponse = await API.get(
                `${process.env.REACT_APP_BASE_URL_V3}/restaurants/:restaurantId/orders?status=placed`
            );
        } catch (error) {
            liveOrderResponse = error;
        }

        if (liveOrderResponse.success) {
            dispatch({
                type: types.SET_ORDERS_COUNT,
                payload: {
                    all: parseInt(liveOrderResponse.all_order_count),
                    placed: liveOrderResponse.new_order_count,
                    restaurant_accepted: liveOrderResponse.preparing_order_count,
                    restaurant_ready: liveOrderResponse.ready_order_count,
                    rider_picked_up: liveOrderResponse.dispatched_order_count,
                },
            });
        }

        return liveOrderResponse;
    };
};
