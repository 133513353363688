import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TOGGLE_UNSAVED_PORTAL } from '../../reduxStore/types/Global/Global';

const Expire = (props) => {
    const [visible, setVisible] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        setTimeout(() => {
            setVisible(false);

            dispatch({
                type: TOGGLE_UNSAVED_PORTAL,
                payload: false,
            });
        }, props.delay);
    }, [dispatch, props.delay]);

    return visible ? <div>{props.children}</div> : <div />;
};

export default Expire;
