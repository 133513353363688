import React, { useEffect, useState } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiButton, EuiLoadingContent } from '@elastic/eui';
import API from '../../api/axios/API';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { getLiveOrders } from '../../api/liveOrders/getLiveOrderNewAPI';
import { getLiveOrderDetailsAPI } from '../../api/liveOrders/getLiveOrderDetailsAPI';

export const DunzoRiderTab = (props) => {
    const { order, onOrderStatusChange } = props;
    const [priceEstimate, setPriceEstimate] = useState({});
    const dispatch = useDispatch();

    const tdStyle = {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    };

    const dunzoCostEstimate = async () => {
        let dunzoResponse;
        try {
            dunzoResponse = await API.get(
                `/restaurants/:restaurantId/dunzo/get-order-estimation-time?orderId=${props.orderId}`
            );
        } catch (error) {
            dunzoResponse = error;
        }

        if (dunzoResponse.success) {
            setPriceEstimate(dunzoResponse);
        } else {
            alert(dunzoResponse.message);
        }
    };

    useEffect(() => {
        dunzoCostEstimate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const assignDunzoRider = async () => {
        let dunzoResponse = '';
        props.setRiderModalOpen();
        try {
            dunzoResponse = await API.post(`/restaurants/:restaurantId/dunzo/assign-order `, {
                orderId: props.orderId,
            });
        } catch (error) {
            dunzoResponse = error;
        }

        if (dunzoResponse.success) {
            // props.stateUpdateData((prevState) => !prevState)
            dispatch(getLiveOrders());
            dispatch(getLiveOrderDetailsAPI());
            // onOrderStatusChange();
        } else {
            alert(dunzoResponse.message);
        }
    };

    return (
        <EuiFlexGroup direction='column'>
            <EuiFlexItem>
                {!isEmpty(priceEstimate) ? (
                    <EuiFlexItem>
                        <b>Dunzo Estimates</b>
                        <table style={{ marginTop: '10px' }}>
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>Estimated Distance</td>
                                {/* <td>:</td> */}
                                <td style={tdStyle}>
                                    {priceEstimate.dunzo_order_estimation.distance
                                        ? `${parseFloat(priceEstimate.dunzo_order_estimation.distance).toFixed(2)} Km`
                                        : ''}
                                </td>
                            </tr>
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>Estimated price</td>
                                {/* <td>:</td> */}
                                <td style={tdStyle}>
                                    {priceEstimate.dunzo_order_estimation.estimated_price
                                        ? `${parseFloat(priceEstimate.dunzo_order_estimation.estimated_price).toFixed(
                                              2
                                          )}`
                                        : ''}
                                </td>
                            </tr>
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>Estimated Pick up time</td>
                                {/* <td>:</td> */}
                                <td style={tdStyle}>
                                    {priceEstimate.dunzo_order_estimation.eta.pickup
                                        ? `${priceEstimate.dunzo_order_estimation.eta.pickup} Mins`
                                        : ''}
                                </td>
                            </tr>
                            <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                <td style={{ borderRight: '1px solid #D3DAE6' }}>Estimated Drop off time</td>
                                {/* <td>:</td> */}
                                <td style={tdStyle}>
                                    {priceEstimate.dunzo_order_estimation.eta.dropoff
                                        ? `${priceEstimate.dunzo_order_estimation.eta.dropoff} Mins`
                                        : ''}
                                </td>
                            </tr>
                        </table>
                    </EuiFlexItem>
                ) : (
                    <EuiLoadingContent lines={5} />
                )}
            </EuiFlexItem>
            <EuiFlexItem style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <EuiButton fullWidth={true} fill onClick={assignDunzoRider} disabled={isEmpty(priceEstimate)}>
                    Assign Dunzo Rider
                </EuiButton>
            </EuiFlexItem>
        </EuiFlexGroup>
    );
};
