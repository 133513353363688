import { EuiSpacer, EuiFlexGroup, EuiDragDropContext, EuiDroppable, euiDragDropReorder } from '@elastic/eui';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import APIV3 from '../../api/axios/APIV3';
import { SET_TABLE_ORDER_DETAILS } from '../../reduxStore/types/tableManagement';
import { fetchOutletSections } from './Api/fetchOutletSections';
import OrdersFlyout from './OrdersFlyout';
import Table from './Table';
import TableCreationFlyout from './TableCreationFlyout';

const TableInfo = ({ tables, isEditTableMode }) => {
    const dispatch = useDispatch();
    const [showFlyout, setShowFlyout] = useState(false);
    const [selectedTable, setSelectedTable] = useState({});
    const [orderFlyout, setOrderFlyout] = useState(false);
    const [list, setList] = useState([]);
    const outletId = useSelector((state) => state.outlet.selectedOutletId);

    const handleFlyoutState = useCallback((table) => {
        setShowFlyout((prevState) => !prevState);
        if (table?.id) {
            setSelectedTable(table);
        } else {
            setSelectedTable({});
        }
    }, []);

    useEffect(() => {
        if (tables?.length) {
            setList(tables);
        }
    }, [tables]);

    const handleOrderFlyoutState = useCallback(() => {
        setOrderFlyout((prevState) => !prevState);
        if (orderFlyout) {
            dispatch({
                type: SET_TABLE_ORDER_DETAILS,
                payload: {},
            });
        }
    }, [dispatch, orderFlyout]);

    const onDragEnd = useCallback(
        async ({ source, destination }) => {
            if (source && destination) {
                const items = euiDragDropReorder(list, source.index, destination.index);
                for (const [index, item] of items.entries()) {
                    item.display_rank = index + 1;
                }
                setList(items);

                if (items?.length) {
                    await APIV3.patch(`/restaurants/:restaurantId/outlets/${outletId}/outlet-tables/change-sequence`, {
                        tables: items,
                    }).then((response) => {
                        if (response.success) dispatch(fetchOutletSections());
                    });
                }
            }
        },
        [list, outletId, dispatch]
    );

    return (
        <>
            <EuiSpacer />
            <EuiDragDropContext onDragEnd={onDragEnd}>
                <EuiDroppable
                    droppableId='DROPPABLE_AREA'
                    isDropDisabled={!isEditTableMode}
                    direction='horizontal'
                    style={{ padding: `${isEditTableMode ? '20px' : '0'} ` }}
                    spacing={isEditTableMode ? 'l' : 'none'}
                    withPanel={isEditTableMode}
                >
                    <EuiFlexGroup
                        gutterSize='s'
                        wrap
                        responsive
                        direction='row'
                        justifyContent='flexStart'
                        alignItems='center'
                    >
                        {list?.map((table, idx) => {
                            return (
                                <Table
                                    key={table.id.toString()}
                                    table={table}
                                    isEditTableMode={isEditTableMode}
                                    idx={idx}
                                    orderFlyout={orderFlyout}
                                    setOrderFlyout={setOrderFlyout}
                                    setSelectedTable={setSelectedTable}
                                    setShowFlyout={setShowFlyout}
                                />
                            );
                        })}
                    </EuiFlexGroup>
                </EuiDroppable>
            </EuiDragDropContext>

            {showFlyout ? (
                <TableCreationFlyout
                    closeFlyout={handleFlyoutState}
                    selectedTable={selectedTable}
                    tableState='update'
                />
            ) : null}
            {orderFlyout ? <OrdersFlyout selectedTable={selectedTable} closeFlyout={handleOrderFlyoutState} /> : null}
        </>
    );
};

export default React.memo(TableInfo);
