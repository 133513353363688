import * as types from '../types/customer';

const initialState = {
    customerDetails: {},
    customerOrderDetails: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.SET_CUSTOMER_DETAILS:
            return {
                ...state,
                customerDetails: action.payload.customerDetails,
            };
        case types.SET_CUSTOMER_ORDER_DETAILS:
            return {
                ...state,
                customerOrderDetails: action.payload.customerOrderDetails,
            };
        default:
            return state;
    }
};
