import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    EuiDragDropContext,
    EuiDroppable,
    EuiFlexGroup,
    EuiFlexItem,
    EuiIcon,
    EuiText,
    euiDragDropReorder,
    euiDragDropMove,
    EuiEmptyPrompt,
    EuiSpacer,
    EuiLoadingContent,
} from '@elastic/eui';
import LoadingOverlay from 'react-loading-overlay';
import { outletCategorySequenceChange } from '../../../../api/menu/outletCategorySequenceChange';
import { isEmpty } from 'lodash';
import RestaurantCategoryComponent from '../../../../components/menuComponentsRefactorCopy/outletLinkage/restaurantCategoryComponent';
import OutletCategoryComponent from '../../../../components/menuComponentsRefactorCopy/outletLinkage/outletCategoryComponent';
import CategoryItemColumn from './CategoryItemColumn';
import { linkCategoryToOutlet } from '../../../../api/menu/linkCategoryToOutlet';
import { toastsErrorMessage } from '../../../../utils/toasts';

const OutletCategoryList = (props) => {
    const { outletCategoryList, setOutletCategoryList, handleUnlink, fetchCategoryData } = props;
    const linkCatalogueLoader = useSelector((state) => state.menuReducer.linkCatalogueLoader);
    const [loaderOverlay, setLoaderOverlay] = useState(false);
    const menuList = useSelector((state) => state.menu.menuList);
    const [restaurantCategoryList, setRestaurantCategoryList] = useState([]);
    const [selectedCategoryItems, setSelectedCategoriesItems] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState({});
    const dispatch = useDispatch();
    const outletId = useSelector((state) => state.outlet.selectedOutletId);

    const onChangeCategories = useCallback(
        async (value) => {
            dispatch(linkCategoryToOutlet(outletId, value)).then((res) => {
                if (!res.success) {
                    fetchCategoryData(value);
                    toastsErrorMessage('Please try again later', dispatch);
                }
            });
        },
        [dispatch, outletId, fetchCategoryData]
    );

    useEffect(() => {
        if (outletCategoryList && outletCategoryList.length) {
            let restaurantCategorySubtractFromOutletCategory = menuList.filter(
                (restaurantCategory) =>
                    !outletCategoryList.filter(
                        (outletCategory) => outletCategory.category_id === restaurantCategory.category_id
                    ).length
            );
            setRestaurantCategoryList([...restaurantCategorySubtractFromOutletCategory]);
        } else {
            setRestaurantCategoryList(!isEmpty(menuList) ? [...menuList] : []);
        }
    }, [outletCategoryList, menuList]);

    const onDragEnd = async ({ source, destination }) => {
        setLoaderOverlay(true);
        const lists = {
            CUSTOM_HANDLE_DROPPABLE_AREA1: restaurantCategoryList,
            CUSTOM_HANDLE_DROPPABLE_AREA2: outletCategoryList,
        };
        const actions = {
            CUSTOM_HANDLE_DROPPABLE_AREA1: setRestaurantCategoryList,
            CUSTOM_HANDLE_DROPPABLE_AREA2: setOutletCategoryList,
        };
        if (source && destination) {
            if (source.droppableId === destination.droppableId) {
                if (source.droppableId !== 'CUSTOM_HANDLE_DROPPABLE_AREA1') {
                    const items = euiDragDropReorder(lists[destination.droppableId], source.index, destination.index);
                    actions[destination.droppableId](items);
                    items.forEach((item) => {
                        item.id = item.category_id;
                        if (item?.sub_categories?.length) {
                            item.sub_categories.forEach((sub_category) => {
                                sub_category.id = sub_category.category_id;
                            });
                        }
                    });

                    await dispatch(outletCategorySequenceChange(outletId, items))
                        .then((response) => {
                            setLoaderOverlay(false);
                            if (response.success) {
                                setOutletCategoryList(items);
                            } else {
                                alert(response.message);
                            }
                        })
                        .catch(() => {
                            setLoaderOverlay(false);
                        });
                }
            } else {
                const sourceId = source.droppableId;
                const destinationId = destination.droppableId;
                const result = euiDragDropMove(lists[sourceId], lists[destinationId], source, destination);
                actions[sourceId](result[sourceId]);
                actions[destinationId](result[destinationId]);

                if (destinationId === 'CUSTOM_HANDLE_DROPPABLE_AREA2') {
                    const updatedOutletSequence = result['CUSTOM_HANDLE_DROPPABLE_AREA2'];
                    await onChangeCategories(lists[sourceId][source.index].category_id)
                        .then(async () => {
                            setLoaderOverlay(false);
                            await dispatch(outletCategorySequenceChange(outletId, updatedOutletSequence))
                                .then((response) => {
                                    setLoaderOverlay(false);
                                    if (response.success) {
                                        setOutletCategoryList(updatedOutletSequence);
                                    } else {
                                        alert(response.message);
                                    }
                                })
                                .catch((err) => {
                                    setLoaderOverlay(false);
                                });
                        })
                        .catch(() => {
                            setLoaderOverlay(false);
                        });
                }

                if (destinationId === 'CUSTOM_HANDLE_DROPPABLE_AREA1') {
                    await handleUnlink(lists[sourceId][source.index].category_id)
                        .then(() => {
                            setLoaderOverlay(false);
                        })
                        .catch(() => {
                            setLoaderOverlay(false);
                        });
                }
            }

            setLoaderOverlay(false);
        }
    };

    return (
        <>
            <EuiFlexGroup style={{ minHeight: '200px', width: '100%' }} direction='row'>
                <EuiDragDropContext onDragEnd={onDragEnd}>
                    {linkCatalogueLoader.loader ? (
                        <EuiFlexItem grow={3}>
                            <EuiLoadingContent lines={10} />
                        </EuiFlexItem>
                    ) : (
                        <EuiFlexItem grow={3} style={{ maxHeight: '80vh' }}>
                            <EuiText>
                                <b>Restaurant Catalogue</b>
                            </EuiText>
                            <EuiSpacer size='s' />
                            <EuiDroppable
                                className='outleLinkStyle scrollbar-sm'
                                droppableId='CUSTOM_HANDLE_DROPPABLE_AREA1'
                                spacing='m'
                                withPanel
                            >
                                <LoadingOverlay active={loaderOverlay}>
                                    {restaurantCategoryList?.length ? (
                                        restaurantCategoryList.map((item, idx) => (
                                            <RestaurantCategoryComponent item={item} index={idx} key={idx} />
                                        ))
                                    ) : (
                                        <EuiFlexGroup wrap style={{ marginTop: 10 }}>
                                            <EuiFlexItem>
                                                <EuiEmptyPrompt
                                                    iconType='editorStrike'
                                                    title={<h2>No Catalogue to Link</h2>}
                                                    body={<p>You have linked all catalogue to this outlet.</p>}
                                                />
                                            </EuiFlexItem>
                                        </EuiFlexGroup>
                                    )}
                                </LoadingOverlay>
                            </EuiDroppable>
                        </EuiFlexItem>
                    )}

                    <div className='arrowStyle'>
                        <EuiIcon type='sortRight' />
                        <EuiIcon type='sortLeft' />
                    </div>

                    {linkCatalogueLoader.loader ? (
                        <EuiFlexItem grow={4}>
                            <EuiLoadingContent lines={10} />
                        </EuiFlexItem>
                    ) : (
                        <EuiFlexItem grow={4} style={{ maxHeight: '80vh' }}>
                            <EuiText>
                                <b>Outlet Linked Catalogue</b>
                            </EuiText>
                            <EuiSpacer size='s' />
                            <EuiDroppable
                                className='outleLinkStyle scrollbar-sm'
                                droppableId='CUSTOM_HANDLE_DROPPABLE_AREA2'
                                spacing='m'
                                withPanel
                            >
                                <LoadingOverlay active={loaderOverlay} spinner text='Re-arranging categories'>
                                    {outletCategoryList?.length ? (
                                        outletCategoryList.map((item, idx) => (
                                            <OutletCategoryComponent
                                                item={item}
                                                key={idx}
                                                selectedCategory={selectedCategory}
                                                setSelectedCategory={setSelectedCategory}
                                                setSelectedCategoriesItems={setSelectedCategoriesItems}
                                                handleUnlink={handleUnlink}
                                                outletCategoryList={outletCategoryList}
                                                setOutletCategoryList={setOutletCategoryList}
                                                index={idx}
                                            />
                                        ))
                                    ) : (
                                        <EuiFlexGroup wrap style={{ marginTop: 10 }}>
                                            <EuiFlexItem>
                                                <EuiEmptyPrompt
                                                    iconType='editorStrike'
                                                    title={<h2>No Catalogue Linked</h2>}
                                                    body={<p>You haven't linked any catalogue to this outlet.</p>}
                                                />
                                            </EuiFlexItem>
                                        </EuiFlexGroup>
                                    )}
                                </LoadingOverlay>
                            </EuiDroppable>
                        </EuiFlexItem>
                    )}
                </EuiDragDropContext>
                {linkCatalogueLoader.loader ? (
                    <EuiFlexItem grow={4}>
                        <EuiLoadingContent lines={10} />
                    </EuiFlexItem>
                ) : (
                    <EuiFlexItem grow={4} style={{ maxHeight: '80vh' }}>
                        <CategoryItemColumn
                            categoryInternaleName={selectedCategory.internal_name}
                            selectedCategoryItems={selectedCategoryItems}
                            setSelectedCategoriesItems={setSelectedCategoriesItems}
                        />
                    </EuiFlexItem>
                )}
            </EuiFlexGroup>
        </>
    );
};

export default React.memo(OutletCategoryList);
