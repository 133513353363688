import React, { useState, useEffect } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiLoadingContent, EuiPanel } from '@elastic/eui';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getAbandonedOrders } from '../../api/abandonedOrders/getAbandonedOrders';
import LiveOrdersTableForPast from '../liveOrders/liveOrdersTableForPast';
import LiveOrderDetailsForPast from '../liveOrders/liveOrderDetailsPastOrders';
import '../liveOrders/style.css';
import CustomDatePicker from '../UIComponent/CustomDatePicker';
import useSingleScreenHeightCalc from '../../hooks/useSingleScreenHeightCalc';

const AbandonedCartComponent = () => {
    const [startDateTime, setStartDateTime] = useState(moment());
    const [endDateTime, setEndDateTime] = useState(moment());
    const [state, setState] = useState({
        apiDataLoaded: false,
        last_page: 0,
        current_page: 0,
        loaderPage: false,
        totalCounts: 0,
        page_size: 10,
    });
    const currentOrderingMode = useSelector((state) => state.orders.currentOrderingMode);
    const dispatch = useDispatch();
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const { screenHeight } = useSingleScreenHeightCalc(300);

    useEffect(() => {
        if (selectedOutletId) {
            dispatch(getAbandonedOrders(startDateTime, endDateTime, state, currentOrderingMode));
        }
    }, [currentOrderingMode, dispatch, endDateTime, selectedOutletId, startDateTime]);

    return (
        <React.Fragment>
            <EuiFlexGroup direction='row' style={{ width: '100%' }}>
                <EuiFlexItem grow={false} style={{ width: '80%' }}>
                    <CustomDatePicker
                        startDate={startDateTime}
                        setStartDate={setStartDateTime}
                        endDate={endDateTime}
                        setEndDate={setEndDateTime}
                        displayFormat='DD-MM-YYYY'
                    />
                </EuiFlexItem>
            </EuiFlexGroup>
            {selectedOutletId ? (
                <>
                    <EuiFlexGroup>
                        <EuiFlexItem grow={6}>
                            <EuiPanel paddingSize='s' style={{ height: screenHeight }} className=' scrollbar-sm'>
                                <LiveOrdersTableForPast
                                    startDateTime={startDateTime}
                                    endDateTime={endDateTime}
                                    state={state}
                                    setState={setState}
                                    isAbandoned={true}
                                />
                            </EuiPanel>
                        </EuiFlexItem>
                        <EuiFlexItem grow={4}>
                            <EuiPanel paddingSize='s' style={{ height: screenHeight }} className=' scrollbar-sm'>
                                <LiveOrderDetailsForPast isAbandoned={true} />
                            </EuiPanel>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </>
            ) : (
                <EuiLoadingContent lines={10} />
            )}
        </React.Fragment>
    );
};

export default AbandonedCartComponent;
