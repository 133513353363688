import React, { Fragment } from 'react';
import moment from 'moment';
import { EuiText, EuiHorizontalRule, EuiFlexGroup, EuiFlexItem, htmlIdGenerator } from '@elastic/eui';
import { ReactComponent as RupeeImage } from '../../assets/img/rupeeImage.svg';
import { isEmpty } from 'lodash';

export default class InvoicePrint extends React.Component {
    getPaymentMethod = (mode) => {
        if (mode === 'CASH_ON_DELIVERY') {
            return 'Cash';
        } else {
            return 'Paid';
        }
    };

    customerAddress = () => {
        if (this.props.order.customer_address) {
            return (
                <EuiText key={htmlIdGenerator()()}>
                    Address: {this.props.order.customer_address.block_number},
                    {this.props.order.customer_address.land_mark},{this.props.order.customer_address.city}
                </EuiText>
            );
        }
    };

    orderingMode = () => {
        if (this.props.order.ordering_mode === 'delivery') {
            return 'Delivery';
        } else if (this.props.order.ordering_mode === 'takeaway') {
            return 'Pick Up';
        }

        return 'Dine In';
    };

    billSettingStrings = (string) => {
        let newLine = '';

        if (string) {
            newLine = string.split('\n');
        }

        return newLine;
    };

    renderItemRowsNew = (orderItem) => {
        let customizationTotal = 0;
        return (
            <React.Fragment key={htmlIdGenerator()()}>
                {/* <span style={{ height: 20, width: '100%', background: 'red' }}></span> */}
                <td key={htmlIdGenerator()()} style={{ paddingTop: '6px', fontSize: 14 }}>
                    {orderItem.item.translations.title[this.props.languageId]}
                    {!isEmpty(orderItem.variant_option_information) &&
                    !isEmpty(orderItem.variant_option_information.variant_group_information) ? (
                        <div>
                            - {orderItem.variant_option_information.translations.title[this.props.languageId]}[
                            {orderItem.variant_option_information.price.toFixed(2)}]
                        </div>
                    ) : (
                        ''
                    )}

                    {orderItem.customizations &&
                        orderItem.customizations.map((addons) => {
                            customizationTotal = customizationTotal + parseFloat(addons.price);
                            return (
                                <span key={htmlIdGenerator()()} style={{ fontSize: 12, display: 'block' }}>
                                    - {addons.translations.name[this.props.languageId]} [{addons.price.toFixed(2)}]
                                </span>
                            );
                        })}
                </td>

                <td
                    style={{ textAlign: 'right', paddingTop: '6px', fontSize: 14, verticalAlign: 'text-top' }}
                    key={htmlIdGenerator()()}
                >
                    {orderItem.promotional_quantity ? orderItem.promotional_quantity : orderItem.quantity}
                </td>

                <td style={{ textAlign: 'right', paddingTop: '6px', fontSize: 14, verticalAlign: 'text-top' }}>
                    {parseFloat(orderItem.price.toFixed(2)) + parseFloat(customizationTotal.toFixed(2))}
                </td>

                {orderItem.promotional_quantity || !orderItem.price ? (
                    <td
                        style={{
                            color: 'green',
                            textAlign: 'right',
                            paddingTop: '6px',
                            fontSize: 14,
                            verticalAlign: 'text-top',
                        }}
                    >
                        Free{' '}
                    </td>
                ) : null}
                {orderItem.price ? (
                    <td
                        style={
                            orderItem.promotional_quantity
                                ? {
                                      textDecoration: 'line-through',
                                      margin: '8px 0',
                                      fontSize: 14,
                                      paddingTop: '6px',
                                      verticalAlign: 'text-top',
                                  }
                                : {
                                      textAlign: 'right',
                                      margin: '8px 0',
                                      fontSize: 14,
                                      paddingTop: '6px',
                                      verticalAlign: 'text-top',
                                  }
                        }
                    >
                        {(parseFloat(orderItem.price.toFixed(2)) + parseFloat(customizationTotal.toFixed(2))) *
                            parseInt(orderItem.quantity)}
                    </td>
                ) : null}
                {/* <br /> */}
            </React.Fragment>
        );
    };

    render() {
        let restaurantName = this.props.restaurantList.restaurants.filter(
            (restaurant) => restaurant.id === parseInt(this.props.selectedRestuarantId)
        );

        let spaceBetweenAlign = { justifyContent: 'space-between', flexDirection: 'row', display: 'flex' };
        let centerAlign = { justifyContent: 'center', alignItems: 'center', display: 'flex', textAlign: 'center' };
        const thStyle = { fontWeight: 'normal', verticalAlign: 'text-top' };

        return (
            <EuiFlexItem
                id='printInvoice'
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '95%',
                    fontFamily: 'sans-serif',
                    fontSize: '12px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}
                key={htmlIdGenerator()()}
            >
                <EuiFlexGroup style={{ maxWidth: '100%', width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                    <EuiFlexItem style={centerAlign}>
                        <span style={{ fontSize: '16px' }}>
                            <b>{restaurantName[0].name}</b>
                        </span>
                    </EuiFlexItem>
                    {this.props.order.outlet.legal_name ? (
                        <EuiFlexItem style={centerAlign}>
                            <span>{this.props.order.outlet.legal_name}</span>
                        </EuiFlexItem>
                    ) : null}
                    <EuiFlexItem style={centerAlign}>
                        <span>
                            {
                                this.props.order?.outlet?.outlet_address?.translations?.address_line_1[
                                    this.props.languageId
                                ]
                            }
                        </span>
                    </EuiFlexItem>
                    {this.props.order?.outlet?.settings && this.props?.order?.outlet?.settings?.gst_number && (
                        <EuiFlexItem style={centerAlign}>
                            <span>GSTIN: {this.props.order.outlet.settings.gst_number}</span>
                        </EuiFlexItem>
                    )}
                    <EuiHorizontalRule />

                    <div>
                        {this.billSettingStrings(this.props.order.outlet.settings.bill_header)?.length ? (
                            this.billSettingStrings(this.props.order.outlet.settings.bill_header)?.map((line, idx) => {
                                return (
                                    <Fragment key={idx}>
                                        <div style={{ display: 'block', whiteSpace: 'pre', ...centerAlign }}>
                                            {line}
                                        </div>
                                        <EuiHorizontalRule />
                                    </Fragment>
                                );
                            })
                        ) : (
                            <>
                                {this.props.order.outlet.settings.bill_header ? (
                                    <>
                                        <div style={{ display: 'block', whiteSpace: 'pre', ...centerAlign }}>
                                            {this.props.order.outlet.settings.bill_header}
                                        </div>
                                        <EuiHorizontalRule />
                                    </>
                                ) : null}
                            </>
                        )}
                    </div>

                    <table style={{ width: '95%', maxWidth: '100%', paddingBottom: '6px' }}>
                        {/* <thead style={{ textAlign: 'left', fontWeight: 'normal' }}>
                            <tr style={{ fontWeight: 'normal', fontSize: 14, width: '50%' }}>
                                <td style={thStyle}>
                                    Date:{' '}
                                    {this.props.order.status.placed_at &&
                                        moment(this.props.order.status.placed_at).format('DD/MM/YY HH:mm')}
                                </td>
                                <td style={thStyle}>
                                    <p>
                                        {this.orderingMode()}{' '}
                                        {this.props.order?.table?.internal_name ? (
                                            <>: {this.props.order?.table?.internal_name}</>
                                        ) : null}
                                    </p>
                                </td>
                            </tr>
                        </thead> */}
                        <tbody>
                            <tr style={{ fontSize: 14 }}>
                                <td style={{ width: '50%', verticalAlign: 'text-top' }}>
                                    Date:{' '}
                                    {this?.props?.order?.status?.placed_at &&
                                        moment(this.props.order.status.placed_at).format('DD/MM/YY HH:mm')}
                                </td>
                                <td style={{ width: '50%', verticalAlign: 'text-top' }}>
                                    <p>
                                        {this.orderingMode()}{' '}
                                        {this.props.order?.table?.internal_name ? (
                                            <>: {this.props.order?.table?.internal_name}</>
                                        ) : null}
                                    </p>
                                </td>
                            </tr>
                            <tr style={{ fontSize: 14 }}>
                                <td style={{ width: '50%', verticalAlign: 'text-top' }}>
                                    {' '}
                                    Payment:{' '}
                                    {this.props.order?.payment_method &&
                                        this.getPaymentMethod(this.props.order.payment_method.payment_method)}
                                </td>

                                {this.props?.order?.customer?.mobile_number ? (
                                    <td>
                                        Number: {this.props?.order?.customer?.country_code}{' '}
                                        {this.props?.order?.customer?.mobile_number}
                                    </td>
                                ) : null}
                            </tr>
                            <tr style={{ fontSize: 14 }}>
                                {this.props?.order?.customer?.name ? (
                                    <td>Name: {this.props.order.customer.name}</td>
                                ) : null}
                                {this.props?.order?.customer?.email ? (
                                    <td style={thStyle}>Email: {this.props.order.customer.email}</td>
                                ) : null}
                            </tr>
                        </tbody>
                    </table>

                    <tr style={{ fontSize: 14, maxWidth: '95%' }}>
                        <td>{this.customerAddress()}</td>
                    </tr>
                    <div style={{ height: '0.5px', borderBottom: '1px solid black' }} />
                    <table
                        style={{
                            width: '100%',
                            borderCollapse: 'collapse',
                            borderBottom: '0.5px solid grey',
                        }}
                    >
                        <thead style={{ textAlign: 'left', width: '100%' }}>
                            <tr style={{ width: '100%' }}>
                                <th
                                    style={{
                                        width: '60%',
                                        borderBottom: '0.5px solid #000',
                                        fontSize: 14,
                                        padding: '8px 0',
                                    }}
                                >
                                    Item(s)
                                </th>
                                <th
                                    style={{
                                        width: '13%',
                                        textAlign: 'right',
                                        borderBottom: '0.5px solid #000',
                                        fontSize: 14,
                                        padding: '8px 0',
                                    }}
                                >
                                    Qty
                                </th>
                                <th
                                    style={{
                                        width: '13%',
                                        textAlign: 'right',
                                        borderBottom: '0.5px solid #000',
                                        fontSize: 14,
                                        padding: '8px 0',
                                    }}
                                >
                                    Price
                                </th>
                                <th
                                    style={{
                                        width: '13%',
                                        textAlign: 'right',
                                        borderBottom: '0.5px solid #000',
                                        fontSize: 14,
                                        padding: '8px 0',
                                    }}
                                >
                                    Amount
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {this.props.order.cart.cart_items.map((orderItem, idx) => {
                                return (
                                    <React.Fragment key={idx}>
                                        <tr>{this.renderItemRowsNew(orderItem)}</tr>
                                        {orderItem?.children?.length
                                            ? orderItem.children.map((childOrderItem, index) => {
                                                  return (
                                                      <tr key={index.toString()}>
                                                          {this.renderItemRowsNew(childOrderItem)}
                                                      </tr>
                                                  );
                                              })
                                            : null}
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                    </table>

                    {/* <EuiHorizontalRule /> */}
                    <span style={{ height: '30px', width: '100%', padding: 8 }}></span>
                    <EuiFlexItem style={spaceBetweenAlign}>
                        <span style={{ fontSize: 14 }}>Gross total</span>
                        <span style={{ fontSize: 14 }}>
                            <RupeeImage style={{ height: '9px', width: '10px' }} />
                            {this.props.order.gross_total.toFixed(2)}
                        </span>
                    </EuiFlexItem>

                    {!isEmpty(this.props.order.cart) &&
                        !isEmpty(this.props.order.cart.reward_points) &&
                        !isEmpty(this.props.order.cart.reward_points.used_reward_points) &&
                        this.props.order.cart.reward_points.used_reward_points.value > 0 && (
                            <EuiFlexItem style={spaceBetweenAlign}>
                                <span style={{ fontSize: 14 }}>Rewards</span>
                                <span style={{ fontSize: 14 }}>
                                    <RupeeImage style={{ height: '9px', width: '10px' }} />
                                    {this.props.order.cart.reward_points.used_reward_points.value}{' '}
                                </span>
                            </EuiFlexItem>
                        )}

                    {parseInt(this.props.order.discount_amount) > 0 && (
                        <EuiFlexItem style={spaceBetweenAlign}>
                            <span style={{ fontSize: 14 }}>Discount</span>
                            <span style={{ fontSize: 14 }}>
                                <RupeeImage style={{ height: '9px', width: '10px' }} />
                                {this.props.order.discount_amount && this.props.order.discount_amount.toFixed(2)}
                            </span>
                        </EuiFlexItem>
                    )}
                    {parseInt(this.props.order.gst_amount) > 0 && (
                        <EuiFlexItem style={spaceBetweenAlign}>
                            <span style={{ fontSize: 14 }}>SGST</span>
                            <span style={{ fontSize: 14 }}>
                                <RupeeImage style={{ height: '9px', width: '10px' }} />
                                {this.props.order.gst_amount / 2}{' '}
                            </span>
                        </EuiFlexItem>
                    )}
                    {parseInt(this.props.order.gst_amount) > 0 && (
                        <EuiFlexItem style={spaceBetweenAlign}>
                            <span style={{ fontSize: 14 }}>CGST</span>
                            <span style={{ fontSize: 14 }}>
                                <RupeeImage style={{ height: '9px', width: '10px' }} />
                                {this.props.order.gst_amount / 2}{' '}
                            </span>
                        </EuiFlexItem>
                    )}
                    {parseInt(this.props.order.cart.packaging_charge) > 0 && (
                        <EuiFlexItem style={spaceBetweenAlign}>
                            <span style={{ fontSize: 14 }}>Packaging Charge</span>
                            <span style={{ fontSize: 14 }}>
                                <RupeeImage style={{ height: '9px', width: '10px' }} />
                                {this.props.order.cart.packaging_charge.toFixed(2)}
                            </span>
                        </EuiFlexItem>
                    )}

                    {parseInt(this.props.order.cart.convenience_charge) > 0 && (
                        <EuiFlexItem style={spaceBetweenAlign}>
                            <span style={{ fontSize: 14 }}>Convenience Charge</span>
                            <span style={{ fontSize: 14 }}>
                                <RupeeImage style={{ height: '9px', width: '10px' }} />
                                {this.props.order.cart.convenience_charge}
                            </span>
                        </EuiFlexItem>
                    )}

                    {parseInt(this.props.order.cart?.cashback.used_cashback) > 0 && (
                        <EuiFlexItem style={spaceBetweenAlign}>
                            <span style={{ fontSize: 14 }}>Cashback</span>
                            <span style={{ fontSize: 14 }}>
                                <RupeeImage style={{ height: '9px', width: '10px' }} />
                                {this.props.order.cart.cashback.used_cashback}
                            </span>
                        </EuiFlexItem>
                    )}

                    {parseInt(this.props.order.cart.delivery_charge) > 0 && (
                        <EuiFlexItem style={spaceBetweenAlign}>
                            <span style={{ fontSize: 14 }}>Delivery Charge</span>
                            <span style={{ fontSize: 14 }}>
                                <RupeeImage style={{ height: '9px', width: '10px' }} />
                                {this.props.order.cart.delivery_charge}
                            </span>
                        </EuiFlexItem>
                    )}

                    <EuiHorizontalRule />
                    <EuiFlexItem style={spaceBetweenAlign}>
                        <b style={{ fontSize: 16 }}>Grand Total</b>
                        <b style={{ fontSize: 16 }}>
                            {' '}
                            <RupeeImage style={{ height: '9px', width: '10px' }} />
                            {this.props.order.net_total.toFixed(2)}
                        </b>
                        {/* <RupeeSymbol size={"12px"}/> */}
                    </EuiFlexItem>
                    <EuiHorizontalRule />

                    <div>
                        {this.billSettingStrings(this.props.order.outlet.settings.bill_footer)?.length ? (
                            this.billSettingStrings(this.props.order.outlet.settings.bill_footer)?.map((line, idx) => {
                                return (
                                    <div style={{ display: 'block', whiteSpace: 'pre', ...centerAlign }} key={idx}>
                                        {line}
                                    </div>
                                );
                            })
                        ) : (
                            <div style={{ display: 'block', whiteSpace: 'pre', ...centerAlign }}>
                                {this.props.order.outlet.settings.bill_footer}
                            </div>
                        )}
                    </div>

                    <EuiHorizontalRule />
                    {/* size='xs' */}
                    <EuiFlexItem style={{ justifyContent: 'center', flexDirection: 'row', display: 'flex' }}>
                        Thank you.
                    </EuiFlexItem>
                    <EuiFlexItem style={centerAlign}>
                        <b>Powered by Fab Systems</b>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiFlexItem>
        );
    }
}
