// import TimeAgo from 'javascript-time-ago'
import { formatDistance } from 'date-fns';

// Load locale-specific relative date/time formatting rules.
// import en from 'javascript-time-ago/locale/en'

// Add locale-specific relative date/time formatting rules.
// TimeAgo.addLocale(en)

// Create relative date/time formatter.
// const timeAgo = new TimeAgo('en-US')

export const getTimeAgo = (dateObj) => {
    // return timeAgo.format(new Date(dateObj))
    let date = formatDistance(new Date(dateObj), new Date(), { addSuffix: false, addPrefix: false, style: 'narrow' });
    return date
        .replace('about', '')
        .replace('ago', '')
        .replace('hours', 'hrs')
        .replace('hour', 'hr')
        .replace('minutes', 'mins')
        .replace('less than a minute', '0 Mins');
};
