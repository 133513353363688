import React, { useState, useCallback } from 'react';
import {
    EuiModal,
    EuiModalBody,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiOverlayMask,
    EuiDatePicker,
    EuiText,
    EuiModalFooter,
    EuiButton,
    EuiTextColor,
    EuiFlexGroup,
    EuiHorizontalRule,
    EuiFlexItem,
    EuiRadio,
} from '@elastic/eui';
import './style.css';
import moment from 'moment';
import API from '../../../api/axios/API';
import { useDispatch } from 'react-redux';
import { getOutletList } from '../../../api/outlet/getOutletList';
import { getNextWorkingDayTime } from '../../../helper/getNextWorkingDayTime';
import APIV3 from '../../../api/axios/APIV3';

const OutletAvailabiliyPopup = ({
    closeModal,
    mode,
    setStateUpdate,
    outlet_details,
    restaurant_ordering_mode_id,
    outletId,
}) => {
    const [startDate, setStartDate] = useState(null);
    const [submitError, setSubmitError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorTimeSlot, setErrorErrorTimeSlot] = useState(null);

    const dispatch = useDispatch();
    const [radioIdSelected, setRadioIdSelected] = useState('');

    const onChange = (optionId) => {
        setRadioIdSelected(optionId.target.id);
    };
    // display error
    const displayError = () => {
        let errorsList = [];
        if (submitError) {
            errorsList.push(
                <EuiTextColor style={{ padding: 5 }} color='danger'>
                    {errorMessage}
                </EuiTextColor>
            );
        }
        return errorsList;
    };
    // display error time slot
    const displayErrorTimeSlot = () => {
        let errorsTiemSlotList = [];
        if (submitError) {
            errorTimeSlot &&
                errorTimeSlot.length > 0 &&
                errorTimeSlot.map((item, index) =>
                    errorsTiemSlotList.push(
                        <EuiTextColor color='danger' style={{ padding: 5 }}>
                            {`${dayName(item.time_slots.day_of_week)} : Start Time :- ${
                                item.time_slots.start_time
                            } , End Time :- ${item.time_slots.end_time}`}
                        </EuiTextColor>
                    )
                );
        }
        return errorsTiemSlotList;
    };

    //check day name
    const dayName = (dayIndex) => {
        let dayName = null;
        if (dayIndex.toString() === '1') {
            dayName = 'Monday';
        } else if (dayIndex.toString() === '2') {
            dayName = 'Tuesday';
        } else if (dayIndex.toString() === '3') {
            dayName = 'Wednesday';
        } else if (dayIndex.toString() === '4') {
            dayName = '	Thursday';
        } else if (dayIndex.toString() === '5') {
            dayName = 'Friday';
        } else if (dayIndex.toString() === '6') {
            dayName = 'Saturday';
        } else if (dayIndex.toString() === '7') {
            dayName = 'Sunday';
        }
        return dayName;
    };

    const nextAvailableTimeCheck = useCallback(() => {
        if (radioIdSelected === 'schedule_time_to_reopen') {
            return startDate ? moment(startDate._d).format('YYYY-MM-DD HH:mm') : null;
        } else if (radioIdSelected === '2_hours') {
            return moment().add(2, 'hours').format('YYYY-MM-DD HH:mm');
        } else if (radioIdSelected === '4_hours') {
            return moment().add(4, 'hours').format('YYYY-MM-DD HH:mm');
        } else if (radioIdSelected === 'next_business_day') {
            return getNextWorkingDayTime(outlet_details.settings.schedules);
        }
        return null;
    }, [outlet_details?.settings?.schedules, radioIdSelected, startDate]);

    // change status api call
    const changeStatus = useCallback(async () => {
        const date = nextAvailableTimeCheck();
        if (mode === 'outlet') {
            try {
                await API.patch(`restaurants/:restaurantId/outlets/${outletId}/update/status`, {
                    is_open: 0,
                    next_available_time: date,
                });
                setErrorMessage('');
                setSubmitError(false);
                setErrorErrorTimeSlot(null);
                dispatch(getOutletList());
                closeModal();
            } catch (e) {
                setErrorMessage(e.message);
                setSubmitError(true);
                setErrorErrorTimeSlot(e.time_slots);
            }

            setStateUpdate((prevState) => !prevState);
        } else {
            try {
                await APIV3.patch(`restaurants/:restaurantId/outlets/${outletId}/update-mode`, {
                    next_available_time: date,
                    restaurant_ordering_mode_id: restaurant_ordering_mode_id,
                    is_available: 0,
                });
                setErrorMessage('');
                setSubmitError(false);
                setErrorErrorTimeSlot(null);
                dispatch(getOutletList());
                closeModal();
            } catch (e) {
                setErrorMessage(e.message);
                setSubmitError(true);
                setErrorErrorTimeSlot(e.time_slots);
            }

            setStateUpdate((prevState) => !prevState);
        }
    }, [closeModal, dispatch, mode, nextAvailableTimeCheck, outletId, restaurant_ordering_mode_id, setStateUpdate]);

    return (
        <EuiOverlayMask>
            <EuiModal onClose={closeModal} style={{ minWidth: 440 }}>
                <EuiModalHeader>
                    <EuiModalHeaderTitle> Turn-on time for selected outlet </EuiModalHeaderTitle>
                </EuiModalHeader>
                <EuiHorizontalRule margin='xs' />
                <EuiModalBody>
                    <EuiFlexItem style={{ margin: '0 -24px', paddingTop: '0' }}>
                        <EuiFlexItem style={{ padding: '0 24px 8px 24px' }}>
                            <EuiRadio
                                id={'2_hours'}
                                label='2 Hours'
                                checked={radioIdSelected === '2_hours'}
                                onChange={(e) => onChange(e)}
                            />
                        </EuiFlexItem>
                        <EuiHorizontalRule margin='xs' />
                        <EuiFlexItem style={{ padding: '8px 24px 8px 24px' }}>
                            <EuiRadio
                                id={'4_hours'}
                                label='4 Hours'
                                checked={radioIdSelected === '4_hours'}
                                onChange={(e) => onChange(e)}
                            />
                        </EuiFlexItem>

                        <EuiHorizontalRule margin='xs' />

                        <EuiFlexItem style={{ padding: '8px 24px 8px 24px' }}>
                            <EuiRadio
                                id={'next_business_day'}
                                label='Next Business Day'
                                checked={radioIdSelected === 'next_business_day'}
                                onChange={(e) => onChange(e)}
                            />
                        </EuiFlexItem>

                        <EuiHorizontalRule margin='xs' />

                        <EuiFlexItem style={{ padding: '8px 24px 14px 24px' }}>
                            <EuiRadio
                                id={'schedule_time_to_reopen'}
                                label='Schedule time to reopen'
                                checked={radioIdSelected === 'schedule_time_to_reopen'}
                                onChange={(e) => onChange(e)}
                            />

                            {radioIdSelected === 'schedule_time_to_reopen' ? (
                                <EuiFlexItem style={{ paddingTop: '8px' }}>
                                    <EuiDatePicker
                                        showTimeSelect
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        timeIntervals={15}
                                        placeholder='Enter next available time'
                                        fullWidth
                                    />
                                </EuiFlexItem>
                            ) : (
                                ''
                            )}
                        </EuiFlexItem>

                        <div style={{ height: '10px', width: '100%', backgroundColor: '#8080803b' }} />

                        <EuiFlexItem style={{ padding: '16px 24px' }}>
                            <EuiRadio
                                id={'manually_turn_on'}
                                label='Manually Turn on'
                                checked={radioIdSelected === 'manually_turn_on'}
                                onChange={(e) => onChange(e)}
                            />
                            <EuiFlexItem style={{ paddingLeft: '25px' }}>
                                <EuiTextColor color='subdued'>
                                    <EuiText size='s'>
                                        This item will not be visible in your app till you switch it on.
                                    </EuiText>
                                </EuiTextColor>
                            </EuiFlexItem>
                        </EuiFlexItem>

                        {submitError && (
                            <EuiFlexGroup
                                direction='column'
                                style={{
                                    backgroundColor: '#f8e9e9',
                                    margin: '10px',
                                    padding: '15px',
                                    borderRadius: '5px',
                                }}
                            >
                                <EuiTextColor color='danger' style={{ paddingBottom: '10px' }}>
                                    <b>Please resolve below validations</b>
                                </EuiTextColor>
                                {displayError()}
                                {displayErrorTimeSlot()}
                            </EuiFlexGroup>
                        )}
                    </EuiFlexItem>
                </EuiModalBody>
                <EuiModalFooter>
                    <EuiButton disabled={!radioIdSelected} fullWidth onClick={changeStatus} fill>
                        Submit
                    </EuiButton>
                </EuiModalFooter>
            </EuiModal>
        </EuiOverlayMask>
    );
};

export default React.memo(OutletAvailabiliyPopup);
