import React, { memo } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiStat, EuiHorizontalRule, EuiPanel } from '@elastic/eui';
import { formatFloat } from '../../../utils/numberFormat';

const DashboardStats = (props) => {
    return (
        <>
            <EuiHorizontalRule />
            <EuiFlexGroup>
                <EuiFlexItem>
                    <EuiPanel>
                        <EuiStat
                            title={'₹' + formatFloat(props.outlet.total_revenue, 0, 'en-IN')}
                            // titleColor='secondary'
                            description='Total Revenue'
                            isLoading={props.isLoading}
                        />
                    </EuiPanel>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiPanel>
                        <EuiStat
                            title={formatFloat(props.outlet.total_orders, 0, 'en-IN')}
                            // titleColor='secondary'
                            description='Total Orders'
                            isLoading={props.isLoading}
                        />
                    </EuiPanel>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiPanel>
                        <EuiStat
                            title={formatFloat(props.outlet.delivered_orders, 0, 'en-IN')}
                            description='Delivered orders'
                            isLoading={props.isLoading}
                        />
                    </EuiPanel>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiPanel>
                        <EuiStat
                            title={formatFloat(props.outlet.cancelled_order, 0, 'en-IN')}
                            description='Cancel Orders'
                            isLoading={props.isLoading}
                        />
                    </EuiPanel>
                </EuiFlexItem>
            </EuiFlexGroup>

            <EuiFlexGroup>
                <EuiFlexItem>
                    <EuiPanel>
                        <EuiStat
                            title={'₹' + formatFloat(props.outlet.revenue_lost, 0, 'en-IN')}
                            description='Revenue lost'
                            isLoading={props.isLoading}
                        />
                    </EuiPanel>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiPanel>
                        <EuiStat
                            title={'₹' + formatFloat(props.outlet.revenue_earned, 0, 'en-IN')}
                            description='Revenue Earned'
                            isLoading={props.isLoading}
                        />
                    </EuiPanel>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiPanel>
                        <EuiStat
                            title={'₹' + formatFloat(props.outlet.gst, 0, 'en-IN')}
                            description='Taxes'
                            isLoading={props.isLoading}
                        />
                    </EuiPanel>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiPanel>
                        <EuiStat
                            title={'₹' + formatFloat(props.outlet.money_saved, 0, 'en-IN')}
                            description='Money saved'
                            isLoading={props.isLoading}
                        />
                    </EuiPanel>
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiHorizontalRule />
        </>
    );
};

export default memo(DashboardStats);
