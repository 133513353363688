import React from 'react';
import RewardsComponent from '../../components/rewardsComponent/rewardsComponent';
import { MainLayout } from '../../components/layout/mainLayout/mainLayout';
import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui';

const RewardsPointsPage = () => {
    return (
        <MainLayout title='Loyalty Cashback Redemption' outlet={true}>
            <EuiFlexGroup direction='column'>
                <EuiFlexItem>
                    <RewardsComponent />
                </EuiFlexItem>
            </EuiFlexGroup>
        </MainLayout>
    );
};

export default RewardsPointsPage;
