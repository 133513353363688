import React from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiPagination } from '@elastic/eui';
const Pagination = ({ totalRecord, pageSize, activePage, handlePageChange }) => {
    const pageCount = Math.ceil(totalRecord / pageSize);

    return (
        <EuiFlexGroup justifyContent='spaceAround'>
            <EuiFlexItem grow={false}>
                <EuiPagination
                    aria-label='Centered pagination example'
                    pageCount={pageCount}
                    activePage={activePage}
                    onPageClick={(activePage) => handlePageChange(activePage)}
                />
            </EuiFlexItem>
        </EuiFlexGroup>
    );
};
export default Pagination;
