import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { userHasPermission } from '../../helper/auth';
import API from '../../api/axios/API';
import { EuiText, EuiDataGrid, EuiButtonEmpty, EuiLink } from '@elastic/eui';
import EditRiderAction from '../../modules/Riders/Components/EditRiderAction';

const RiderDataGrid = ({ setRefetch, allRider, showFlyout }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const permissions = useSelector((state) => state.permission.permission);
    let columns = [
        {
            id: 'name',
            display: 'Name',
            displayAsText: 'Name',
            isSortable: true,
        },
        {
            id: 'contact_number',
            display: 'Phone',
            displayAsText: 'Phone',
            isSortable: false,
        },
        {
            id: 'join_date',
            display: 'Join Date',
            displayAsText: 'Join Date',
        },
        {
            id: 'radius',
            display: 'Radius',
            displayAsText: 'Radius',
        },
        {
            id: 'normal_rate',
            display: 'Normal Rate',
            displayAsText: 'Normal Rate',
        },
        {
            id: 'extra_rate',
            display: 'Extra Rate',
            displayAsText: 'Extra Rate',
        },
        {
            id: 'edit',
            display: 'Edit',
            displayAsText: 'Edit',
        },
        {
            id: 'delete',
            display: 'Delete',
            displayAsText: 'Delete',
        },
    ];

    if (!userHasPermission(permissions, '/riders', 'delete') && !userHasPermission(permissions, '/riders', 'write')) {
        columns = [
            {
                id: 'name',
                display: 'Name',
                displayAsText: 'Name',
                isSortable: true,
            },
            {
                id: 'contact_number',
                display: 'Phone',
                displayAsText: 'Phone',
                isSortable: false,
            },
            {
                id: 'join_date',
                display: 'Join Date',
                displayAsText: 'Join Date',
            },
            {
                id: 'radius',
                display: 'Radius',
                displayAsText: 'Radius',
            },
            {
                id: 'normal_rate',
                display: 'Normal Rate',
                displayAsText: 'Normal Rate',
            },
            {
                id: 'extra_rate',
                display: 'Extra Rate',
                displayAsText: 'Extra Rate',
            },
        ];
    } else if (!userHasPermission(permissions, '/riders', 'delete')) {
        columns = [
            {
                id: 'name',
                display: 'Name',
                displayAsText: 'Name',
                isSortable: true,
            },
            {
                id: 'contact_number',
                display: 'Phone',
                displayAsText: 'Phone',
                isSortable: false,
            },
            {
                id: 'join_date',
                display: 'Join Date',
                displayAsText: 'Join Date',
            },
            {
                id: 'radius',
                display: 'Radius',
                displayAsText: 'Radius',
            },
            {
                id: 'normal_rate',
                display: 'Normal Rate',
                displayAsText: 'Normal Rate',
            },
            {
                id: 'extra_rate',
                display: 'Extra Rate',
                displayAsText: 'Extra Rate',
            },
            {
                id: 'edit',
                display: 'Edit',
                displayAsText: 'Edit',
            },
        ];
    } else if (!userHasPermission(permissions, '/riders', 'write')) {
        columns = [
            {
                id: 'name',
                display: 'Name',
                displayAsText: 'Name',
                isSortable: true,
            },
            {
                id: 'contact_number',
                display: 'Phone',
                displayAsText: 'Phone',
                isSortable: false,
            },
            {
                id: 'join_date',
                display: 'Join Date',
                displayAsText: 'Join Date',
            },
            {
                id: 'radius',
                display: 'Radius',
                displayAsText: 'Radius',
            },
            {
                id: 'normal_rate',
                display: 'Normal Rate',
                displayAsText: 'Normal Rate',
            },
            {
                id: 'extra_rate',
                display: 'Extra Rate',
                displayAsText: 'Extra Rate',
            },
            {
                id: 'delete',
                display: 'Delete',
                displayAsText: 'Delete',
            },
        ];
    } else {
        columns = [
            {
                id: 'name',
                display: 'Name',
                displayAsText: 'Name',
                isSortable: true,
            },
            {
                id: 'contact_number',
                display: 'Phone',
                displayAsText: 'Phone',
                isSortable: false,
            },
            {
                id: 'join_date',
                display: 'Join Date',
                displayAsText: 'Join Date',
            },
            {
                id: 'radius',
                display: 'Radius',
                displayAsText: 'Radius',
            },
            {
                id: 'normal_rate',
                display: 'Normal Rate',
                displayAsText: 'Normal Rate',
            },
            {
                id: 'extra_rate',
                display: 'Extra Rate',
                displayAsText: 'Extra Rate',
            },
            {
                id: 'edit',
                display: 'Edit',
                displayAsText: 'Edit',
            },
            {
                id: 'delete',
                display: 'Delete',
                displayAsText: 'Delete',
            },
        ];
    }

    const [sortingColumns, setSortingColumns] = useState([]);

    const riderDetails = (rider) => {
        dispatch({
            type: 'set-sepcific-rider-data',
            payload: {
                specific_Rider_Data: rider,
            },
        });
        history.push(`/rider/${rider.rider_id}`);
    };

    const onSort = useCallback(
        (sortingColumns) => {
            setSortingColumns(sortingColumns);
        },
        [setSortingColumns]
    );
    const editRider = async (id) => {
        dispatch({
            type: 'set-edit-rider-id',
            payload: {
                edit_rider_id: id,
            },
        });
        showFlyout();
    };
    const deleteRider = async (id) => {
        if (window.confirm('Are you sure you want to delete this record ?')) {
            try {
                const deleteRider = await API.delete(`restaurants/:restaurantId/riders/${id}`);
                if (deleteRider.success) {
                    alert('delete successfully');
                } else {
                    alert('Please try later');
                }
                setRefetch((preState) => !preState);
            } catch (e) {
                alert('Please try later');
            }
        }
    };

    const [visibleColumns, setVisibleColumns] = useState(() => columns.map(({ id }) => id));

    let mappedItems =
        allRider && allRider.riders && allRider.riders.data.length > 0
            ? allRider.riders.data.map((rider) => {
                  return {
                      name: (
                          <>
                              {
                                  <EuiText key={rider.id + 'name'} onClick={() => riderDetails(rider)}>
                                      <EuiLink>
                                          {' '}
                                          {rider.first_name} - {rider.middle_name}{' '}
                                      </EuiLink>
                                  </EuiText>
                              }
                          </>
                      ),
                      contact_number: (
                          <>
                              {
                                  <EuiText key={rider.id + 'contact_number'} onClick={() => riderDetails(rider)}>
                                      <EuiLink>
                                          {' '}
                                          {rider.country_code} {rider.mobile_number}{' '}
                                      </EuiLink>{' '}
                                  </EuiText>
                              }
                          </>
                      ),
                      join_date: <>{<EuiText key={rider.id + 'join_date'}> {rider.join_date} </EuiText>}</>,
                      radius: <>{<EuiText key={rider.id + 'radius'}> {rider.normal_service_radius}</EuiText>}</>,
                      normal_rate: <>{<EuiText key={rider.id + 'normal_rate'}> {rider.delivery_rate} </EuiText>}</>,
                      extra_rate: <>{<EuiText key={rider.id + 'extra_rate'}> {rider.extra_delivery_rate} </EuiText>}</>,
                      edit: (
                          <>
                              <EditRiderAction rider={rider} setRefetch={setRefetch} />
                          </>
                      ),
                      delete: (
                          <>
                              {' '}
                              <EuiButtonEmpty iconType='cross' size='s' onClick={() => deleteRider(rider.rider_id)}>
                                  Delete
                              </EuiButtonEmpty>{' '}
                          </>
                      ),
                  };
              })
            : [];
    const renderCellValue = useMemo(() => {
        return ({ rowIndex, columnId, setCellProps }) => {
            return mappedItems.hasOwnProperty(rowIndex) ? mappedItems[rowIndex][columnId] : null;
        };
    }, [mappedItems]);
    return (
        <EuiDataGrid
            aria-label='Rider List Datagrid'
            columns={columns}
            columnVisibility={{ visibleColumns, setVisibleColumns }}
            rowCount={mappedItems.length}
            inMemory={{ level: 'sorting' }}
            renderCellValue={renderCellValue}
            sorting={{ columns: sortingColumns, onSort }}
            height={mappedItems.length > 1 ? '70vh' : 'auto'}
        />
    );
};

export default RiderDataGrid;
