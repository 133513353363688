import { formatedDataForKitchen } from '../../settings/formatedDataForKitchen';

export const getBannerAvailableTimeSlots = (item) => {
    let time = '';
    let timeSlots = '';
    if (item.time_slot_setting === 'same_time_slot_for_all_days') {
        let formatedTimeSlotInfoRushour = formatedDataForKitchen(item.schedules);

        let fieldData = [];
        formatedTimeSlotInfoRushour &&
            formatedTimeSlotInfoRushour[1] &&
            formatedTimeSlotInfoRushour[1].map((item, index) =>
                fieldData.push({
                    // id: uuid(),
                    start_time: item.start_time,
                    end_time: item.end_time,
                    preparation_time_in_rush: item.preparation_time_in_rush,
                })
            );

        fieldData.map((time) => {
            timeSlots += `${time.start_time} - ${time.end_time}, `;
        });

        time = timeSlots;

        // setTime(timeSlots);
    }

    let defaultValue = {};
    defaultValue.kitchen_time_weak = {
        sunday: [],
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
    };
    if (item.time_slot_setting === 'different_time_slots_for_different_days') {
        let formatedTimeSlotInfo = formatedDataForKitchen(item.schedules);

        for (const key in formatedTimeSlotInfo) {
            if (key == 7) {
                formatedTimeSlotInfo[7].map((item) =>
                    defaultValue.kitchen_time_weak.sunday.push({
                        // id: uuidv4(),
                        start_time: item.start_time,
                        end_time: item.end_time,
                        preparation_time_in_rush: item.preparation_time_in_rush,
                    })
                );
            }
            if (key == 1) {
                formatedTimeSlotInfo[1].forEach((item) =>
                    defaultValue.kitchen_time_weak.monday.push({
                        // id: uuidv4(),
                        start_time: item.start_time,
                        end_time: item.end_time,
                        preparation_time_in_rush: item.preparation_time_in_rush,
                    })
                );
            }

            if (key == 2) {
                formatedTimeSlotInfo[2].map((item) =>
                    defaultValue.kitchen_time_weak.tuesday.push({
                        // id: uuidv4(),
                        start_time: item.start_time,
                        end_time: item.end_time,
                        preparation_time_in_rush: item.preparation_time_in_rush,
                    })
                );
            }

            if (key == 3) {
                formatedTimeSlotInfo[3].map((item) =>
                    defaultValue.kitchen_time_weak.wednesday.push({
                        // id: uuidv4(),
                        start_time: item.start_time,
                        end_time: item.end_time,
                        preparation_time_in_rush: item.preparation_time_in_rush,
                    })
                );
            }

            if (key == 4) {
                formatedTimeSlotInfo[4].map((item) => {
                    defaultValue.kitchen_time_weak.thursday.push({
                        // id: uuidv4(),
                        start_time: item.start_time,
                        end_time: item.end_time,
                        preparation_time_in_rush: item.preparation_time_in_rush,
                    });

                    return null;
                });
            }

            if (key == 5) {
                formatedTimeSlotInfo[5].map((item) =>
                    defaultValue.kitchen_time_weak.friday.push({
                        // id: uuidv4(),
                        start_time: item.start_time,
                        end_time: item.end_time,
                        preparation_time_in_rush: item.preparation_time_in_rush,
                    })
                );
            }

            if (key == 6) {
                formatedTimeSlotInfo[6].map((item) =>
                    defaultValue.kitchen_time_weak.saturday.push({
                        // id: uuidv4(),
                        start_time: item.start_time,
                        end_time: item.end_time,
                        preparation_time_in_rush: item.preparation_time_in_rush,
                    })
                );
            }
        }

        let timeSlots = '';
        for (let key in defaultValue.kitchen_time_weak) {
            if (defaultValue.kitchen_time_weak[key].length) {
                timeSlots += `${key} :- `;
                // timeSlots.push(<ul>{key}</ul>)

                defaultValue.kitchen_time_weak[key].map((time) => {
                    timeSlots += `${time.start_time} - ${time.end_time}, `;
                    // timeSlots.push(<li></li>)
                });
            }
        }

        time = timeSlots;

        // setTime(timeSlots);
    }

    return time;
};
