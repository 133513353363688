import React, { useEffect, useState } from 'react';
import { EuiCollapsibleNavGroup, EuiText, EuiFlexItem, EuiLoadingSpinner } from '@elastic/eui';
import CategoryItems from './CategoryItems';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

const Category = (props) => {
    const { category, setTotalCategoryItems, rowRef } = props;
    const [itemList, setItemList] = useState([]);
    const items = useSelector((state) => state.petPoojaReducer.restaurantItems);
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);
    const { control } = useFormContext();
    const outletOrderingModesEnabled = useSelector((state) => state.outlet.orderingModes)?.filter(
        (orderingMode) => orderingMode?.is_enabled
    );

    const { fields, append } = useFieldArray({
        control,
        name: `item_${category.category_id}`,
    });

    useEffect(() => {
        if (!(!itemList.length && !category?.sub_categories?.length && !fields?.length)) {
            return false;
        }

        const matchedItems = items?.filter((item) =>
            item.category_ids.some((category_id) => category_id === category.category_id)
        );

        setItemList(matchedItems);
    }, [selectedOutletId]);

    useEffect(() => {
        if (itemList?.length) {
            for (const item of itemList) {
                const notFoundElements = outletOrderingModesEnabled.filter(
                    (r) =>
                        !item.ordering_modes.some(
                            (l) => l.restaurant_ordering_mode_id === r.restaurant_ordering_mode_id
                        )
                );

                if (notFoundElements?.length) {
                    item.ordering_modes = [...item.ordering_modes, ...notFoundElements];
                    // notFoundElements.forEach((element) => )
                }

                append({ ...item });
            }

            setTotalCategoryItems((prevItems) => [...prevItems, { [category.category_id]: itemList }]);
        }
    }, [append, itemList, setTotalCategoryItems, selectedOutletId]);

    return (
        <div ref={rowRef}>
            <EuiCollapsibleNavGroup
                key={category.category_id.toString()}
                background='light'
                title={
                    <EuiFlexItem style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <EuiText>
                            <h3>{category.internal_name}</h3>
                        </EuiText>
                        {props.isLoading ? (
                            <EuiFlexItem style={{ paddingLeft: '20px' }}>
                                <EuiLoadingSpinner size='m' />
                            </EuiFlexItem>
                        ) : null}
                    </EuiFlexItem>
                }
                isCollapsible={true}
                initialIsOpen={false}
            >
                {category?.sub_categories?.length ? (
                    <EuiFlexItem style={{ marginLeft: '20px' }}>
                        {category.sub_categories.map((subCategory) => {
                            return (
                                <Category
                                    isLoading={props.isLoading}
                                    category={subCategory}
                                    key={subCategory.category_id.toString()}
                                    setTotalCategoryItems={setTotalCategoryItems}
                                />
                            );
                        })}
                    </EuiFlexItem>
                ) : null}

                {fields.map((item, index) => {
                    return (
                        <CategoryItems
                            category_id={category.category_id}
                            item={item}
                            fieldIndex={index}
                            key={item.item_id.toString() + index.toString()}
                        />
                    );
                })}
            </EuiCollapsibleNavGroup>
        </div>
    );
};

export default React.memo(Category);
