import { EuiFlexItem, EuiText } from '@elastic/eui';
import React from 'react';
import { useSelector } from 'react-redux';

const CataloguePanelHeader = (props) => {
    const themeMode = useSelector((state) => state.theme.theme);

    return (
        <>
            <EuiFlexItem
                grow={false}
                style={{
                    backgroundColor: themeMode === 'light' ? '#f4f6f9' : 'black',
                    justifyContent: 'space-between',
                    padding: '0 12px',
                    alignItems: 'center',
                    alignContent: 'center',
                    flexDirection: 'row',
                    marginBottom: '5px',
                    height: '40px',
                }}
            >
                <EuiText>
                    <span style={{ fontWeight: 600 }}>{props.panelTitle} </span>
                </EuiText>
                <>{props.children}</>
            </EuiFlexItem>
        </>
    );
};

export default CataloguePanelHeader;
