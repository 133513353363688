import { EuiFlexGroup, EuiFlexItem, EuiSwitch } from '@elastic/eui';
import React, { useEffect, useState } from 'react';

const OrderingModeSwitch = (props) => {
    const [isActive, setIsActive] = useState(false);
    useEffect(() => {
        if (props.mode.status) {
            setIsActive(true);
        }
    }, [props.mode]);

    return (
        <>
            <EuiFlexGroup direction='row' gutterSize='s' justifyContent='flexStart' alignItems='center' wrap responsive>
                <EuiFlexItem grow={false}>
                    {/* {userHasPermission(permissions, '/offers', 'write') && ( */}
                    <EuiSwitch
                        compressed
                        label=''
                        key={props.mode.display_name + 'active'}
                        checked={isActive}
                        onChange={(e) => props.handleChange(e, props.mode.id, setIsActive)}
                    />
                    {/* )} */}
                </EuiFlexItem>
            </EuiFlexGroup>
        </>
    );
};

export default React.memo(OrderingModeSwitch);
