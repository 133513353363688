import React, { useEffect, useState } from 'react';
import API from '../../api/axios/API';
import moment from 'moment';
import { CSVDownload } from 'react-csv';
import { isEmpty } from 'lodash';

const RiderInfoCsv = ({ startDateTime, endDateTime, riderId, totalRecord, setCSV }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [RiderDetails, setRiderDetails] = useState({});
    const [error, setError] = useState(false);
    const [csvData, setCsvData] = useState([]);

    const getRiderDetails = async () => {
        setIsLoading(true);
        await API.get(
            `restaurants/:restaurantId/riders/${riderId}/orders?start_date=${moment(startDateTime).format(
                'YYYY-MM-DD'
            )}&end_date=${moment(endDateTime).format('YYYY-MM-DD')}&par_page=${totalRecord}`
        )
            .then((res) => {
                setRiderDetails(res);
            })
            .catch(() => setError(true));
    };

    useEffect(() => {
        getRiderDetails();
    }, []);

    console.log('RiderDetails', RiderDetails);

    useEffect(() => {
        if (!isEmpty(RiderDetails)) {
            const data = RiderDetails?.rider_order_details?.rider_orders?.data?.map((item) => {
                return item;
            });
            setCsvData(...csvData, data);
        }
    }, [RiderDetails]);
    console.log('csv data', csvData);

    useEffect(() => {
        if (csvData.length > 0) {
            setIsLoading(false);
        }
    }, [csvData]);

    if (isLoading || csvData.length === 0) return null;
    if (error) {
        alert('Error in download..');
    }

    return (
        <div>
            <CSVDownload data={csvData} target='_blank' />
            {setCSV(false)}
        </div>
    );
};

export default React.memo(RiderInfoCsv);
