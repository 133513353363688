import API from './axios/API';

export const requestForRiderService = (request_for, message) => async (dispatch, getState) => {
    let res;
    const outlet_id = getState().outlet.selectedOutletId;

    try {
        res = await API.post(`restaurants/:restaurantId/outlets/${outlet_id}/request-third-party-service-topup`, {
            third_party_service: request_for,
            request_for: message,
        });
    } catch (error) {
        console.log(error);
    }
};
