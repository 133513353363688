import React, { useState, useEffect, memo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NoOutletMessage } from '../../../utils/noOutletMessage';
import OutletCategoryList from '../Components/LinkCatalogue/OutletCategoryList';
import { unLinkCategoryFromOutlet } from '../../../api/menu/unLinkCategoryFromOutlet';
import {
    fetchOutletCatalogue,
    fetchRestaurantCatalogue,
} from '../../../components/menuComponentsRefactorCopy/catalogue/Api/FetchCatalogueInfo';
import { getFlatToTreeStructureNew } from '../../../helper/getFlatToTreeStructureNew';
import { SET_MENU_LIST } from '../../../reduxStore/types/menu';
import { toastsErrorMessage } from '../../../utils/toasts';
import { SET_CATALOGUE_LINK_LOADER } from '../../../components/menuComponentsRefactorCopy/Store/MenuTypes';

const CatalogueOutletLink = () => {
    const [outletCategoryList, setOutletCategoryList] = useState(null);
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);
    const [outletCategoryLoaded, setOutletCategoryLoaded] = useState({ restaurant: false, outlet: false });
    const languageId = useSelector((state) => state.language.languageId);
    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedOutletId) {
            fetchRestaurantCatalogue(languageId).then((response) => {
                if (response.success) {
                    setOutletCategoryLoaded({ ...outletCategoryLoaded, restaurant: true });
                    dispatch({
                        type: SET_MENU_LIST,
                        payload: {
                            menuList: getFlatToTreeStructureNew(response.categories),
                        },
                    });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, selectedOutletId, languageId]);

    // method for update catagory
    const fetchCategoryData = useCallback(
        async (outletID) => {
            dispatch({
                type: SET_CATALOGUE_LINK_LOADER,
                payload: { loader: true, error: false },
            });

            fetchOutletCatalogue(outletID, languageId).then((response) => {
                if (response.success) {
                    setOutletCategoryList([...getFlatToTreeStructureNew(response.categories)]);
                    setOutletCategoryLoaded({ ...outletCategoryLoaded, outlet: true });
                    dispatch({
                        type: SET_CATALOGUE_LINK_LOADER,
                        payload: { loader: false, error: false },
                    });
                }
            });
        },
        [dispatch, languageId, outletCategoryLoaded]
    );

    useEffect(() => {
        if (selectedOutletId) {
            fetchCategoryData(selectedOutletId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOutletId]);

    // method handle's for catagory un-link
    const handleUnlink = useCallback(
        async (id) => {
            dispatch(unLinkCategoryFromOutlet(selectedOutletId, id)).then((res) => {
                if (res.success) {
                    let innerArray = [...outletCategoryList];
                    let index = innerArray.findIndex((category) => category.category_id == id);
                    innerArray.splice(index, 1);
                    setOutletCategoryList(innerArray);

                    fetchRestaurantCatalogue(languageId).then((response) => {
                        if (response.success) {
                            setOutletCategoryLoaded({ ...outletCategoryLoaded, restaurant: true });
                            dispatch({
                                type: SET_MENU_LIST,
                                payload: {
                                    menuList: getFlatToTreeStructureNew(response.categories),
                                },
                            });
                        }
                    });
                } else {
                    fetchCategoryData(id);
                    toastsErrorMessage('Please try later', dispatch);
                }
            });
        },
        [dispatch, languageId, outletCategoryList, outletCategoryLoaded, selectedOutletId, fetchCategoryData]
    );

    return (
        <>
            {!selectedOutletId ? (
                <NoOutletMessage />
            ) : (
                <OutletCategoryList
                    outletId={selectedOutletId}
                    setOutletCategoryList={setOutletCategoryList}
                    outletCategoryList={outletCategoryList}
                    handleUnlink={handleUnlink}
                    fetchCategoryData={fetchCategoryData}
                    outletCategoryLoaded={outletCategoryLoaded}
                />
            )}
        </>
    );
};

export default memo(CatalogueOutletLink);
