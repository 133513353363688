import {
    EuiFormRow,
    EuiFlexItem,
    EuiFlexGroup,
    EuiForm,
    EuiEmptyPrompt,
    EuiSpacer,
    EuiButtonGroup,
} from '@elastic/eui';
import React, { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import FormGroupDescription from '../../../components/formGroupDescription';
import { useDispatch, useSelector } from 'react-redux';
import { outletSettingsAPI } from '../../../api/outlet/outletSettingsAPI';
import ComboBoxField from '../../../components/Form/ComboBoxField';
// import { fetchOutletOrderingModes } from '../../../api/outlet/fetchOutletOrderingModes';
import { updateOutletOrderingModes } from '../../../components/settings/outlet-form/utils';
import useOutletFormValues from '../Hooks/useOutletFormValues';
import useFormActionsHandler from '../../../hooks/useFormActionsHandler';

const CODEnablement = () => {
    const dispatch = useDispatch();
    const outlet = useSelector((state) => state.outlet.selectedOutletDetails);

    const outletId = useSelector((state) => state.outlet.selectedOutletId);

    const updateFormValues = useCallback((data) => {
        let defaultValue = {};

        for (const cashOrderingMode of data.payment_availabilities_ordering_mode_wise) {
            if (cashOrderingMode.payment_method === 'CASH_ON_DELIVERY') {
                defaultValue[`${cashOrderingMode.id}_${cashOrderingMode.ordering_mode}`] = cashOrderingMode.is_enabled;
            }
        }

        return defaultValue;
    }, []);

    const { defaultValues, setDefaultValues, onSuccessCallback } = useOutletFormValues({ updateFormValues });

    const onFormSaveApi = useCallback(
        async (data) => {
            let selectedOutletOrderingModeIdsEnable = [];
            let selectedOutletOrderingModeIdsDisable = [];

            for (const cashOrderingMode of outlet.payment_availabilities_ordering_mode_wise) {
                if (cashOrderingMode.payment_method === 'CASH_ON_DELIVERY') {
                    if (data[`${cashOrderingMode.id}_${cashOrderingMode.ordering_mode}`]) {
                        selectedOutletOrderingModeIdsEnable.push(cashOrderingMode.restaurant_ordering_mode_id);
                    } else {
                        selectedOutletOrderingModeIdsDisable.push(cashOrderingMode.restaurant_ordering_mode_id);
                    }
                }
            }

            const requestParams = {
                enable_ordering_mode_ids: selectedOutletOrderingModeIdsEnable,
                disable_ordering_mode_ids: selectedOutletOrderingModeIdsDisable,
            };

            if (selectedOutletOrderingModeIdsEnable.length > 0 || selectedOutletOrderingModeIdsDisable.length > 0) {
                const response = await dispatch(outletSettingsAPI('cod_availabilitie', requestParams));
                if (response.success) setDefaultValues(updateFormValues(response.outlet));
                return response;
            }
        },
        [dispatch, outlet?.payment_availabilities_ordering_mode_wise, outletId, setDefaultValues, updateFormValues]
    );

    const { control, errors, watch, setValue } = useFormActionsHandler({
        onFormSaveApi,
        defaultValues,
        onSuccessCallback,
    });

    return (
        <>
            <EuiForm component='form'>
                <EuiFlexItem style={{ display: 'none' }}></EuiFlexItem>
                <FormGroupDescription
                    title={'Enable availability of ordering modes'}
                    description={'Select available ordering modes for the respective outlet according to requisite'}
                >
                    {outlet?.payment_availabilities_ordering_mode_wise?.map((orderingMode) => {
                        return (
                            <React.Fragment key={orderingMode.id.toString()}>
                                {orderingMode.payment_method === 'CASH_ON_DELIVERY' ? (
                                    <EuiFormRow label={`Cash for ${orderingMode.restaurant_ordering_mode_type}`}>
                                        <Controller
                                            render={({ field }) => {
                                                return (
                                                    <EuiFormRow label={''}>
                                                        <EuiButtonGroup
                                                            options={[
                                                                {
                                                                    id: 1,
                                                                    label: 'Enable',
                                                                    iconType: 'check',
                                                                },
                                                                {
                                                                    id: 0,
                                                                    label: 'Disable',
                                                                    iconType: 'cross',
                                                                },
                                                            ]}
                                                            idSelected={field.value}
                                                            onChange={field.onChange}
                                                            color='primary'
                                                        />
                                                    </EuiFormRow>
                                                );
                                            }}
                                            name={`${orderingMode.id.toString()}_${orderingMode.ordering_mode}`}
                                            control={control}
                                        />
                                    </EuiFormRow>
                                ) : null}
                            </React.Fragment>
                        );
                    })}
                </FormGroupDescription>
            </EuiForm>
        </>
    );
};

export default React.memo(CODEnablement);
