export const authModule = (permissionMap, accesslevel, moduleName) => {
    if (!moduleName) {
        return false;
    }

    if (permissionMap && !permissionMap.hasOwnProperty(moduleName)) {
        return false;
    }

    return permissionMap !== null ? permissionMap[moduleName].includes(accesslevel) : '';
};
