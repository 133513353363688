import {
    EuiButton,
    EuiCard,
    EuiFlyout,
    EuiFlyoutBody,
    EuiFlyoutFooter,
    EuiFlyoutHeader,
    EuiSpacer,
    EuiStat,
    EuiTitle,
    htmlIdGenerator,
} from '@elastic/eui';
import React from 'react';
import { useMemo } from 'react';
import ItemLinkCheckboxList from './ItemLinkCheckboxList';

const ItemLinkFlyout = ({
    isFlyoutOpen,
    toggleFlyout,
    flyoutHeader,
    hasStats,
    statTitle,
    statDescription,
    handleFlyoutSubmitButton,
    missingDataError,
    data,
    labelStringLvl1,
    accessLvl,
    appModule,
    selectedItem,
    handleOnChange,
    setOfferLinkStatus,
    offerLinkStatus,
    initSelectedItems,
    isLoading,
}) => {
    const OfferStats = useMemo(() => {
        return (
            <>
                {hasStats ? (
                    <>
                        <EuiStat title={statTitle} description={statDescription} />
                        <EuiSpacer />
                    </>
                ) : null}
            </>
        );
    }, [hasStats, statDescription, statTitle]);

    const FlyoutHeader = useMemo(() => {
        return (
            <>
                {flyoutHeader ? (
                    <EuiFlyoutHeader hasBorder>
                        <EuiTitle>
                            <h2 id={htmlIdGenerator()()}>{flyoutHeader}</h2>
                        </EuiTitle>
                    </EuiFlyoutHeader>
                ) : null}
            </>
        );
    }, [flyoutHeader]);

    return (
        <>
            {isFlyoutOpen ? (
                <EuiFlyout onClose={toggleFlyout}>
                    {FlyoutHeader}
                    <EuiFlyoutBody>
                        {OfferStats}
                        {data?.length ? (
                            data?.map((item, index) => {
                                return (
                                    <ItemLinkCheckboxList
                                        key={index.toString()}
                                        dataInfo={item}
                                        label={item[labelStringLvl1]}
                                        appModule={appModule}
                                        accessLvl={accessLvl}
                                        selectedItem={selectedItem}
                                        handleOnChange={handleOnChange}
                                        initSelectedItems={initSelectedItems}
                                        setOfferLinkStatus={setOfferLinkStatus}
                                    />
                                );
                            })
                        ) : (
                            <EuiCard title={missingDataError} />
                        )}
                    </EuiFlyoutBody>
                    <EuiFlyoutFooter>
                        <EuiButton
                            fill
                            fullWidth
                            onClick={() => handleFlyoutSubmitButton(selectedItem)}
                            disabled={(!offerLinkStatus.link.length && !offerLinkStatus.unlink.length) || isLoading}
                        >
                            Save Changes
                        </EuiButton>
                    </EuiFlyoutFooter>
                </EuiFlyout>
            ) : null}
        </>
    );
};

export default React.memo(ItemLinkFlyout);
