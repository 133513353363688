import { EuiButton } from '@elastic/eui';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PaymentModal from '../../../components/Credits/PaymentModal';
import { authModule } from '../../../helper/authModulePermission';

const AddCredits = ({
    moduleName = 'infrastructure_credit',
    creditType = 'infrastructure',
    setUpdateTopupData,
    setLoader,
    setPaymentLoader,
}) => {
    const permissions = useSelector((state) => state.permission.permission);
    const [isOpen, setIsOpen] = useState(false);

    const onClose = () => {
        setIsOpen(false);
    };

    return (
        <>
            {isOpen && (
                <PaymentModal
                    closeModal={onClose}
                    setShowPaymentModal={setIsOpen}
                    creditType={creditType}
                    setUpdateTopupData={setUpdateTopupData}
                    setPaymentLoader={setPaymentLoader}
                    setLoader={setLoader}
                />
            )}
            <EuiButton onClick={() => setIsOpen(true)} fill disabled={!authModule(permissions, 'write', moduleName)}>
                Add Credits
            </EuiButton>
        </>
    );
};

export default AddCredits;
