import { EuiButton, EuiFlyout, EuiFlyoutBody, EuiFlyoutFooter, EuiFlyoutHeader, EuiTitle } from '@elastic/eui';
import React, { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { SET_UPSELLING_ITEM_EDIT_SELECTED_LIST } from '../../../reduxStore/types/menu';
import ShiftItemList from './shiftItemList';

const UpSellingFlyout = ({ flyoutState, setFlyoutState }) => {
    const isOutletMenu = useSelector((state) => state.menuReducer.isOutletMenu);
    const categories = useSelector((state) => state.menuReducer.restaurantCategories);
    const activeCategoryId = useSelector((state) => state.menu.activeCategoryId);
    const [category, setCategory] = useState({});
    const dispatch = useDispatch();

    const closeFlyout = useCallback(() => {
        setFlyoutState({ isOpen: false, action: '' });
        // remove up selling items
        dispatch({
            type: SET_UPSELLING_ITEM_EDIT_SELECTED_LIST,
            payload: {
                selectedItemEditUpsellingList: [],
            },
        });
    }, [dispatch, setFlyoutState]);

    useEffect(() => {
        if (activeCategoryId && categories?.length) {
            const selectedCategory = categories.filter((category) => category.category_id === activeCategoryId);
            if (selectedCategory?.length) {
                setCategory(selectedCategory[0]);
            }
        }
    }, [activeCategoryId, categories]);

    const handleAddUpSelling = useCallback(() => {
        setFlyoutState({ isOpen: false, action: '' });
    }, [setFlyoutState]);

    return (
        <>
            <div>
                {flyoutState.isOpen && !isOutletMenu ? (
                    <EuiFlyout onClose={closeFlyout} aria-labelledby='flyout for upselling item'>
                        <EuiFlyoutHeader hasBorder>
                            <EuiTitle size='m'>
                                <h2>Select {flyoutState.action} items</h2>
                            </EuiTitle>
                        </EuiFlyoutHeader>

                        <EuiFlyoutBody>
                            {categories.map((categoryData) =>
                                category?.category_id !== categoryData.category_id ? (
                                    <ShiftItemList
                                        isOutletMenu={isOutletMenu}
                                        category={categoryData}
                                        categoryValue={category}
                                        upSellingSelection={true}
                                    />
                                ) : null
                            )}
                        </EuiFlyoutBody>
                        <EuiFlyoutFooter>
                            <EuiButton onClick={handleAddUpSelling} fill fullWidth>
                                Add
                            </EuiButton>
                        </EuiFlyoutFooter>
                    </EuiFlyout>
                ) : null}
            </div>
        </>
    );
};

export default React.memo(UpSellingFlyout);
