import React, { memo } from 'react';
import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui';

const WeekDaysTimeSlot = ({ day, reset, control, setValue, watch, name, component: Component }) => {
    return (
        <>
            <EuiFlexGroup style={{ marginTop: '20px', marginBottom: '20px', borderBottom: '1px solid black' }}>
                <EuiFlexItem>
                    <EuiFlexGroup style={{ flexDirection: 'column', textAlign: 'center' }}>
                        <EuiFlexItem style={{ textAlign: 'left', marginLeft: '3%' }}>
                            <strong style={{ textTransform: 'capitalize' }}> {day} </strong>
                        </EuiFlexItem>
                        <Component reset={reset} control={control} setValue={setValue} name={name} watch={watch} />
                    </EuiFlexGroup>
                </EuiFlexItem>
            </EuiFlexGroup>
        </>
    );
};

export default memo(WeekDaysTimeSlot);
