/* global localStorage JSON */
import * as types from '../types/auth';
import { v4 as uuidv4 } from 'uuid';

const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;

// Check if this brwoser has UUID,
let deviceId = localStorage.getItem('deviceId') ? localStorage.getItem('deviceId') : null;
if (!deviceId) {
    // generate New device ID and also store it in localStorage.
    deviceId = uuidv4();
    localStorage.setItem('deviceId', deviceId);
}

const initialState = {
    deviceId,
    initializingApp: false,
    selectedRestaurantId: null,
    redemePointValueOnPurchase: 0,
    restaurantLoader: false,
    user,
    firebaseToken: '',
    restaurantList: [],
    restaurantDescriptions: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case 'initialize_app':
            return {
                ...action.payload,
            };
        case types.SET_AUTH:
            return {
                ...state,
                user: { ...action.payload },
            };
        case types.SET_RESTAURANT_ID:
            return {
                ...state,
                selectedRestaurantId: action.payload.restaurantId,
            };
        case types.SET_RESTAURANT_LOADER:
            return {
                ...state,
                restaurantLoader: action.payload.restaurantLoader,
            };
        case types.SET_FIREBASE_TOKEN:
            return {
                ...state,
                firebaseToken: action.payload.firebaseToken,
            };
        case types.SET_RESTAURANT_LIST:
            return {
                ...state,
                restaurantList: action.payload.restaurantList,
            };
        case types.SET_REDEME_POINTS_ON_PURCHASE:
            return {
                ...state,
                redemePointValueOnPurchase: action.payload.redemePointValueOnPurchase,
            };
        case types.SET_RESTAURANT_DESCRIPTIONS:
            return {
                ...state,
                restaurantDescriptions: action.payload.restaurantDescriptions,
            };
        default:
            return state;
    }
};
