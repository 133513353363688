import React, { memo } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiPanel, EuiText, EuiSpacer, EuiStat, EuiHorizontalRule } from '@elastic/eui';
import { numberFormat } from '../../../utils/numberFormat';

const VisitorInfo = (props) => {
    return (
        <EuiFlexGroup direction={'column'} gutterSize='s'>
            <EuiFlexItem>
                <EuiFlexGroup gutterSize='none' direction={'column'}>
                    <EuiFlexItem>
                        <EuiText>
                            <h4>Cart</h4>
                        </EuiText>
                    </EuiFlexItem>
                </EuiFlexGroup>
                <EuiHorizontalRule margin={'s'} />
            </EuiFlexItem>
            <EuiFlexItem>
                <EuiFlexGroup direction={'row'} style={{ width: '50%' }}>
                    <EuiFlexItem>
                        <EuiPanel>
                            <EuiStat
                                title={numberFormat(props.outlet.cart_count, 0, 'en-IN')}
                                // titleColor='secondary'
                                description='Cart count'
                                isLoading={props.isLoading}
                            />
                        </EuiPanel>
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <EuiPanel>
                            <EuiStat
                                title={
                                    props.outlet.cart_amount
                                        ? '₹' + numberFormat(props.outlet.cart_amount, 0, 'en-IN')
                                        : '₹' + 0
                                }
                                description='Cart Amount'
                                isLoading={props.isLoading}
                            />
                        </EuiPanel>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiFlexItem>

            <EuiSpacer size='s' />

            <EuiFlexItem>
                <EuiFlexGroup gutterSize='none' direction={'column'}>
                    <EuiFlexItem>
                        <EuiText>
                            <h4>Delivery</h4>
                        </EuiText>
                        <EuiHorizontalRule margin={'s'} />
                    </EuiFlexItem>

                    <EuiFlexItem>
                        <EuiFlexGroup direction={'row'} style={{ width: '50%' }}>
                            <EuiFlexItem>
                                <EuiPanel>
                                    <EuiStat
                                        title={
                                            props.outlet.total_delivery_cost
                                                ? '₹' + numberFormat(props.outlet.total_delivery_cost, 0, 'en-IN')
                                                : '₹' + 0
                                        }
                                        description='Delivery cost'
                                        isLoading={props.isLoading}
                                    />
                                </EuiPanel>
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <EuiPanel>
                                    <EuiStat
                                        title={
                                            props.outlet.cost_per_order
                                                ? '₹' + numberFormat(props.outlet.cost_per_order, 0, 'en-IN')
                                                : '₹' + 0
                                        }
                                        // titleColor='secondary'
                                        description='Cost per order'
                                        isLoading={props.isLoading}
                                    />
                                </EuiPanel>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiFlexItem>
        </EuiFlexGroup>
    );
};

export default memo(VisitorInfo);
