import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { MainLayout } from '../../components/layout/mainLayout/mainLayout';
import {
    EuiText,
    EuiFlexGroup,
    EuiFlexItem,
    EuiSpacer,
    EuiLoadingContent,
    EuiFormRow,
    EuiEmptyPrompt,
} from '@elastic/eui';
import DashboardStats from '../../components/dashboardComponents/outletOrders/DashboardStats';
import SuccessOrders from '../../components/dashboardComponents/successOrders/successOrders';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import VisitorInfo from '../../components/dashboardComponents/VisitorInfo/visitorInfo';
import { getDashboardCompareData } from '../../api/getDashboardData';
import { isEmpty } from 'lodash';
import DownloadStat from '../../components/dashboardComponents/DownloadStat';
import TopOrdersChart from '../../components/dashboardComponents/Charts/TopOrdersChart';
import RevenueChart from '../../components/dashboardComponents/Charts/RevenueChart';
import { userHasPermission } from '../../helper/auth';
import CustomDatePicker from '../../components/UIComponent/CustomDatePicker';
import DashboardOutletSelection from './Components/DashboardOutletSelection';

const DashboardPage = () => {
    const restaurantId = useSelector((state) => state.auth.selectedRestaurantId);
    const languageId = useSelector((state) => state.language.languageId);
    const permissions = useSelector((state) => state.permission.permission);
    const outletList = useSelector((state) => state.outlet.outletList);

    const [options, setOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedOutletDetails, setSelectedOutletDetails] = useState({});

    const [startDate1, setStartDate1] = useState(moment());
    const [endDate1, setEndDate1] = useState(moment());
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    const outletIdArray = useCallback(() => {
        let outletString = '';

        for (let i = 0; i < selectedOptions.length; i++) {
            if (selectedOptions.length - 1 === i) {
                outletString += `outlet_ids[${i}]=${selectedOptions[i].value}`;
            } else {
                outletString += `outlet_ids[${i}]=${selectedOptions[i].value}&`;
            }
        }

        return outletString;
    }, [selectedOptions]);

    const fetchUniversalOutletDetails = useCallback(
        async (startDate, endDate) => {
            let outletString = outletIdArray();
            if (startDate && endDate && outletString) {
                dispatch(getDashboardCompareData(outletString, startDate, endDate))
                    .then((response) => {
                        setIsLoading(false);
                        if (response.success) {
                            setSelectedOutletDetails(response.data);
                        }
                    })
                    .catch(() => setIsLoading(false));
            }
        },
        [dispatch, outletIdArray]
    );

    const fetchOutletFunction = async () => {
        let outlets = [];

        outletList.forEach((outlet) => {
            outlets.push({
                value: outlet.value,
                label: outlet.inputDisplay ? outlet.inputDisplay : ' ',
                translations: outlet.outlet_data.translations,
                checked: 'on',
            });
        });
        // let outletResponse;

        // try {
        //     outletResponse = await API.get('/restaurants/:restaurantId/users/outlets');
        // } catch (error) {
        //     outletResponse = error;
        // }

        // outletResponse.outlet &&
        //     outletResponse.outlet.map((outlet) =>
        //         outlets.push({
        //             value: outlet.outlet_id,
        //             label: outlet.translations.name[languageId],
        //             translations: outlet.translations,
        //         })
        //     );

        setOptions(outlets);
        if (outlets.length > 0) {
            setSelectedOptions([...outlets]);
        } else {
            setSelectedOptions([]);
        }
    };

    useEffect(() => {
        fetchOutletFunction();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restaurantId]);

    useEffect(() => {
        if (localStorage.getItem('language')) {
            let optionsArray = [...outletList];

            optionsArray.map((option) => {
                option.label = option.outlet_data.translations.name[languageId]
                    ? option.outlet_data.translations.name[languageId]
                    : '';
                option.checked = 'on';
            });

            setOptions(optionsArray);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [languageId]);

    useEffect(() => {
        if (!isEmpty(startDate1) && !isEmpty(endDate1)) {
            setIsLoading(true);
            fetchUniversalOutletDetails(startDate1, endDate1);
        }
    }, [startDate1, endDate1, fetchUniversalOutletDetails, selectedOptions.length]);

    const header = useMemo(() => {
        return (
            <>
                <EuiFlexGroup justifyContent={'spaceEvenly'}>
                    {userHasPermission(permissions, '/restaurant-settings', 'write') && (
                        <EuiFlexItem>
                            <EuiFormRow label='&nbsp;' fullWidth={true}>
                                <DownloadStat
                                    isLoading={isEmpty(selectedOutletDetails)}
                                    activeUsers={selectedOutletDetails ? selectedOutletDetails.active_users : 0}
                                />
                            </EuiFormRow>
                        </EuiFlexItem>
                    )}

                    <EuiFlexItem>
                        <EuiFormRow label='Date Selection' fullWidth={true}>
                            <CustomDatePicker
                                startDate={startDate1}
                                setStartDate={setStartDate1}
                                endDate={endDate1}
                                setEndDate={setEndDate1}
                                displayFormat='DD-MM-YYYY'
                                isLoading={isLoading}
                            />
                        </EuiFormRow>
                    </EuiFlexItem>

                    <EuiFlexItem>
                        <DashboardOutletSelection
                            options={options}
                            setOptions={setOptions}
                            setSelectedOptions={setSelectedOptions}
                        />
                    </EuiFlexItem>
                </EuiFlexGroup>
            </>
        );
    }, [endDate1, isLoading, options, permissions, selectedOutletDetails, startDate1]);

    // If no outlets are selected render empty component.
    if (selectedOptions.length < 1) {
        return (
            <MainLayout title='Dashboard'>
                {header}
                {options.length && !selectedOptions.length ? noOutletSelected() : emptyLayout()}
                {/* {emptyLayout()} */}
            </MainLayout>
        );
    }

    return (
        <>
            <MainLayout title='Dashboard'>
                {/*Header*/}
                {header}
                <EuiSpacer size='xs' />

                <DashboardStats outlet={selectedOutletDetails} isLoading={isEmpty(selectedOutletDetails)} />

                <EuiSpacer size='xs' />

                {/* Top Order and Revenue Chart*/}

                {isEmpty(selectedOutletDetails) ? (
                    <EuiLoadingContent lines={3} />
                ) : (
                    selectedOutletDetails && (
                        <EuiFlexGroup direction='row' alignItems={'flexStart'}>
                            <EuiFlexItem>
                                <TopOrdersChart outlet={selectedOutletDetails} outlet_id={selectedOptions[0].value} />
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <RevenueChart outlet={selectedOutletDetails} outlet_id={selectedOptions[0].value} />
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    )
                )}

                {isEmpty(selectedOutletDetails) ? (
                    <EuiLoadingContent lines={5} />
                ) : (
                    selectedOutletDetails && <VisitorInfo outlet={selectedOutletDetails} />
                )}

                {isEmpty(selectedOutletDetails) ? (
                    <EuiLoadingContent lines={5} />
                ) : (
                    selectedOutletDetails && <SuccessOrders outlet={selectedOutletDetails} />
                )}
            </MainLayout>
        </>
    );
};

export default DashboardPage;

const emptyLayout = () => {
    return (
        <>
            <EuiFlexItem style={{ position: 'absolute', top: '50%', left: '50%' }}>
                <EuiFlexItem>
                    <br />
                    <EuiText>
                        <h3 style={{ textAlign: 'center' }}>Empty Dashboard</h3>
                    </EuiText>
                </EuiFlexItem>
            </EuiFlexItem>
        </>
    );
};

const noOutletSelected = () => {
    return (
        <div style={{ position: 'absolute', top: '38%', left: '38%' }}>
            <EuiEmptyPrompt
                iconType='editorStrike'
                title={<h2>No outlet selected</h2>}
                body={<p>Please select an outlet.</p>}
            />
        </div>
    );
};
