import APIV3 from '../../../api/axios/APIV3';

export const sessionSettle = async (session_id) => {
    let response;
    try {
        response = await APIV3.patch(`restaurants/:restaurantId/session-settle`, { session_id: session_id });
    } catch (error) {
        response = { ...error, isError: true };
    }
    return response;
};
