import API from '../../../api/axios/API';
import { GET_PET_POOJA_LINKED_ITEMS, SET_PET_POOJA_LINKED_ADDONS } from '../../../reduxStore/types/PetPoojaItemTypes';

export const fetchUpdatedLinkedItems = (dispatch, outletId) => {
    return new Promise(async (resolve, reject) => {
        let response = await API.get(`restaurants/:restaurantId/outlets/${outletId}/pet-pooja/menu-links`);
        if (response?.order_response?.length >= 0) {
            dispatch({
                type: GET_PET_POOJA_LINKED_ITEMS,
                payload: response.order_response,
            });
            dispatch({
                type: SET_PET_POOJA_LINKED_ADDONS,
                payload: response.order_response,
            });
            resolve(response.order_response);
        } else {
            reject();
        }
    });
};
