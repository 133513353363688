import { useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { userHasPermission } from '../helper/auth';
import { TOGGLE_FORM_HANDLER_PORTAL, TOGGLE_UNSAVED_PORTAL } from '../reduxStore/types/Global/Global';
import { toastsErrorMessage } from '../utils/toasts';

const useFormActionsHandler = ({
    onFormSaveApi,
    defaultValues,
    onSuccessCallback = null,
    resetFormManually = null,
    mode = undefined,
}) => {
    const dispatch = useDispatch();
    const permissions = useSelector((state) => state.permission.permission);
    const saveState = useSelector((state) => state.uiPortalStateReducer.portalState.saveStateHandler);
    const discardState = useSelector((state) => state.uiPortalStateReducer.portalState.discardStateHandler);
    const {
        handleSubmit,
        control,
        reset,
        watch,
        setValue,
        formState: { isDirty, errors },
        setError,
        clearErrors,
    } = useForm({
        mode: mode ? mode : 'onSubmit',
        // defaultValues: useMemo(() => {
        //     return defaultValues;
        // }, [defaultValues]),
    });
    const location = useLocation();

    const onSubmit = useCallback(
        (data) => {
            onFormSaveApi(data).then((response) => {
                if (response.success) {
                    if (onSuccessCallback) onSuccessCallback();
                    dispatch({
                        type: TOGGLE_FORM_HANDLER_PORTAL,
                        payload: false,
                    });
                    dispatch({
                        type: TOGGLE_UNSAVED_PORTAL,
                        payload: false,
                    });
                } else {
                    toastsErrorMessage(response?.message ? response?.message : 'Oops, There was error.', dispatch);
                }
            });
        },
        [dispatch, onFormSaveApi, onSuccessCallback]
    );

    const resetChanges = useCallback(() => {
        // console.log('trigger', defaultValues);
        reset({
            ...defaultValues,
        });
    }, [reset, defaultValues]);

    // Checks for if the form is dirty and user has clicked on discard changes PORTAL
    useEffect(() => {
        if (resetFormManually && isDirty) {
            resetFormManually();
        } else if (isDirty) {
            resetChanges();
        }
    }, [discardState]);

    // Checks for if the form is dirty and user has clicked on Save PORTAL
    useEffect(() => {
        if (resetFormManually && isDirty) {
            onSubmit();
        } else if (isDirty) {
            handleSubmit(onSubmit)();
        }
    }, [saveState]);

    useEffect(() => {
        resetChanges();
    }, [resetChanges]);

    useEffect(() => {
        if (!userHasPermission(permissions, location.pathname, 'write')) return;

        if (isDirty) {
            dispatch({
                type: TOGGLE_FORM_HANDLER_PORTAL,
                payload: true,
            });
        } else {
            dispatch({
                type: TOGGLE_FORM_HANDLER_PORTAL,
                payload: false,
            });
            dispatch({
                type: TOGGLE_UNSAVED_PORTAL,
                payload: false,
            });
        }
    }, [isDirty, dispatch, permissions]);

    return { errors, control, watch, setValue, reset, isDirty, setError, clearErrors };
};

export default useFormActionsHandler;
