import { EuiConfirmModal, EuiDragDropContext, euiDragDropReorder, EuiDroppable } from '@elastic/eui';
import React, { useCallback, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import API from '../../../api/axios/API';
import { toastsErrorMessage } from '../../../utils/toasts';
import { SET_SELECTED_ADDON_GROUP_CHOICES } from '../Store/MenuTypes';
import AddOnChoiceList from './addOnChoiceList';

const ChoiceList = ({ setApiFetchState, setAddOnList, setCurrentPage }) => {
    const addOnGroupSelectedId = useSelector((state) => state.menuReducer.addOnGroupSelectedId);
    const choiceList = useSelector((state) => state.menuReducer.selectedAddonGroupChoices);

    const dispatch = useDispatch();

    const [loaderOverlay, setLoaderOverlay] = useState(false);
    const [choiceDeleteId, setChoiceDeleteId] = useState('');
    const [choiceDeleteConfirmationModal, setChoiceDeleteConfirmationModal] = useState(false);

    const choiceDeleteIdCallback = useCallback((value) => {
        setChoiceDeleteId(value);
    }, []);

    const choiceDeleteConfirmModalCallback = useCallback((value) => {
        setChoiceDeleteConfirmationModal(value);
    }, []);

    const onDragEnd = useCallback(
        async ({ source, destination }) => {
            if (source && destination) {
                setLoaderOverlay(true);
                const items = euiDragDropReorder(choiceList, source.index, destination.index);

                try {
                    const response = await API.patch(
                        `/restaurants/:restaurantId/restaurant-addon-groups/${addOnGroupSelectedId}/change-sequence`,
                        {
                            choices: items,
                        }
                    );
                    if (response.success) {
                        setAddOnList([]);
                        setCurrentPage(1);
                        dispatch({
                            type: SET_SELECTED_ADDON_GROUP_CHOICES,
                            payload: items,
                        });
                        setApiFetchState((prevState) => !prevState);
                        setLoaderOverlay(false);
                    } else {
                        setLoaderOverlay(false);
                    }
                } catch (error) {
                    setLoaderOverlay(false);
                    toastsErrorMessage('Oops, There was an error. Please try again', dispatch);
                }
            }
        },
        [addOnGroupSelectedId, choiceList, dispatch, setApiFetchState]
    );

    const onChoiceConfirmationDeleteModal = useCallback(async () => {
        if (choiceDeleteId) {
            await API.delete(`/restaurants/:restaurantId/restaurant-addon-group-choice/${choiceDeleteId}`);

            setApiFetchState((prevState) => !prevState);

            setChoiceDeleteConfirmationModal(false);
        }
    }, [choiceDeleteId, setApiFetchState]);

    return (
        <>
            {choiceDeleteConfirmationModal && (
                <EuiConfirmModal
                    style={{
                        position: 'fixed',
                        top: '35%',
                        left: '35%',
                        marginTop: '-50px',
                        marginLeft: '-50px',
                        zIndex: 10000,
                    }}
                    title='Are you sure you want to delete this choice?'
                    onCancel={() => setChoiceDeleteConfirmationModal(false)}
                    onConfirm={onChoiceConfirmationDeleteModal}
                    cancelButtonText="No, don't do it"
                    confirmButtonText='Yes, do it'
                    buttonColor='danger'
                    defaultFocusedButton='cancel'
                >
                    <p>You&rsquo;re about to delete this choice</p>
                    <p>Are you sure you want to do this?</p>
                </EuiConfirmModal>
            )}

            <EuiDragDropContext onDragEnd={onDragEnd}>
                <EuiDroppable droppableId='CUSTOM_HANDLE_DROPPABLE_AREA' spacing='none'>
                    <LoadingOverlay active={loaderOverlay} spinner text='Re-arranging sequence'>
                        <AddOnChoiceList
                            setChoiceDeleteId={choiceDeleteIdCallback}
                            setChoiceDeleteConfirmationModal={choiceDeleteConfirmModalCallback}
                            choicesList={choiceList}
                        />
                    </LoadingOverlay>
                </EuiDroppable>
            </EuiDragDropContext>
        </>
    );
};

export default React.memo(ChoiceList);
