export const timeSlotDataOffers = (data) => {
    let schedules;
    if (data.timer_for_item === 'no_time_slots_offer' || data.timer_for_item === true) {
        schedules = null;
        data.time_slot_setting = 'no_time_slots';
    } else if (data.timer_for_item === 'same_time_slot_for_all_days_offer') {
        data.time_slot_setting = 'same_time_slot_for_all_days';
        let time =
            data.time &&
            data.time.length > 0 &&
            data.time.filter((item) => {
                if (item.start_time !== '' && item.end_time !== '') {
                    return item;
                }
                return null;
            });
        if (time) {
            schedules = [
                {
                    days_of_week: [1, 2, 3, 4, 5, 6, 7],
                    time_slots: time,
                },
            ];
        } else {
            schedules = [];
        }
    } else {
        schedules = [];
        data.time_slot_setting = 'different_time_slots_for_different_days';
        let sunday =
            data.week &&
            data.week.sunday &&
            data.week.sunday.filter((item) => {
                if (item.start_time !== '' && item.end_time !== '') {
                    return item;
                }
                return null;
            });
        sunday &&
            sunday.length > 0 &&
            schedules.push({
                days_of_week: [7],
                time_slots: sunday,
            });
        let monday =
            data.week &&
            data.week.monday &&
            data.week.monday.filter((item) => {
                if (item.start_time !== '' && item.end_time !== '') {
                    return item;
                }
                return null;
            });
        monday &&
            monday.length > 0 &&
            schedules.push({
                days_of_week: [1],
                time_slots: monday,
            });
        let tuesday =
            data.week &&
            data.week.tuesday &&
            data.week.tuesday.filter((item) => {
                if (item.start_time !== '' && item.end_time !== '') {
                    return item;
                }
                return null;
            });
        tuesday &&
            tuesday.length > 0 &&
            schedules.push({
                days_of_week: [2],
                time_slots: tuesday,
            });
        let wednesday =
            data.week &&
            data.week.wednesday &&
            data.week.wednesday.filter((item) => {
                if (item.start_time !== '' && item.end_time !== '') {
                    return item;
                }
                return null;
            });
        wednesday &&
            wednesday.length > 0 &&
            schedules.push({
                days_of_week: [3],
                time_slots: wednesday,
            });
        let thursday =
            data.week &&
            data.week.thursday &&
            data.week.thursday.filter((item) => {
                if (item.start_time !== '' && item.end_time !== '') {
                    return item;
                }
                return null;
            });
        thursday &&
            thursday.length > 0 &&
            schedules.push({
                days_of_week: [4],
                time_slots: thursday,
            });
        let friday =
            data.week &&
            data.week.friday &&
            data.week.friday.filter((item) => {
                if (item.start_time !== '' && item.end_time !== '') {
                    return item;
                }
                return null;
            });
        friday &&
            friday.length > 0 &&
            schedules.push({
                days_of_week: [5],
                time_slots: friday,
            });
        let saturday =
            data.week &&
            data.week.saturday &&
            data.week.saturday.filter((item) => {
                if (item.start_time !== '' && item.end_time !== '') {
                    return item;
                }
                return null;
            });
        saturday &&
            saturday.length > 0 &&
            schedules.push({
                days_of_week: [6],
                time_slots: saturday,
            });
    }
    data.schedules = schedules;
};

export const getItemOrVariantName = (itemData, items, languageId) => {
    let name = '';

    if (itemData.module_name === 'variant') {
        items.map((item) => {
            if (item?.variants?.length && item?.variants[0]?.options?.length) {
                item.variants[0].options.map((option) => {
                    if (option.id === itemData.module_id) {
                        name = option.translations.title[languageId];
                    }
                });
            }
        });
    } else {
        if (items?.length > 0) {
            items?.forEach((item) => {
                if (item.item_id === itemData.module_id) {
                    name = item.internal_name;
                }
            });
        }
    }

    return name;
};
