import React, { useState } from 'react';
import Credits from '../../components/Credits/Credits';
import { MainLayout } from '../../components/layout/mainLayout/mainLayout';

const CreditsPage = () => {
    const [selectedTab, setSelectedTab] = useState('infrastructure');

    return (
        <MainLayout title='Fab Credits' outlet={selectedTab === 'service' && true}>
            <Credits selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        </MainLayout>
    );
};

export default CreditsPage;
