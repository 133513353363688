import React from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiPanel, EuiSpacer, htmlIdGenerator } from '@elastic/eui';
import LiveOrderCustomerInformation from '../liveOrders/liveOrderCustomerInformation';
import LiveOrderDetailsTable from '../liveOrders/liveOrderDetailsTable';
import OrderTotalBill from '../liveOrders/orderTotalBill';
import LiveOrderDetailsAction from '../liveOrders/liveOrderDetailsActions';

const OrderDetialsTable = ({ order, isPastTableSession }) => {
    return (
        <>
            <React.Fragment key={htmlIdGenerator()()}>
                <EuiPanel style={{ height: '64vh' }} paddingSize='s' className='scrollbar'>
                    <EuiFlexGroup direction='column' justifyContent='spaceBetween' style={{ height: '100%' }}>
                        <EuiFlexItem grow={false}>
                            <LiveOrderCustomerInformation
                                isPastTableSession={isPastTableSession}
                                isFromTableManagement={true}
                                selectedOrder={order}
                            />
                            <EuiSpacer />

                            <LiveOrderDetailsTable selectedOrder={order} />
                            <EuiSpacer />
                            <OrderTotalBill selectedOrder={order} isFromTableManagement={true} />
                            <EuiSpacer />
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiFlexGroup>
                                <EuiFlexItem>
                                    <LiveOrderDetailsAction isFromTableManagement={true} selectedOrder={order} />
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiPanel>
            </React.Fragment>
        </>
    );
};

export default React.memo(OrderDetialsTable);
