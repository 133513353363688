import { EuiCard, EuiFlexItem, EuiIcon, EuiSpacer, EuiText } from '@elastic/eui';
import React from 'react';

const PaymentDetailsCard = ({ order }) => {
    const { bank_transaction_id } = order;

    return bank_transaction_id ? (
        <EuiCard
            display='plain'
            layout='horizontal'
            paddingSize='s'
            style={{ backgroundColor: '#a9a9a9', marginBottom: '5px' }}
            title={
                <EuiFlexItem style={{ alignItems: 'center', flexDirection: 'row' }}>
                    <EuiIcon color='#FFFFF0' type='arrowRight' />
                    <div style={{ marginLeft: '10px', color: '#FFFFF0' }}>
                        <span>Payment Details</span>
                    </div>
                </EuiFlexItem>
            }
            description={
                <>
                    {bank_transaction_id && (
                        <EuiText size='s' style={{ color: '#FFFFF0' }}>
                            <p>Bank Transaction ID: {bank_transaction_id}</p>
                        </EuiText>
                    )}
                </>
            }
        />
    ) : null;
};

export default PaymentDetailsCard;
