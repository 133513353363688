import { EuiFlexGroup, EuiFlexItem, EuiSwitch, EuiText } from '@elastic/eui';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';

const OutletOrderingModeSwitch = ({ mode, handleChange, control, watch }) => {
    const [isActive, setIsActive] = useState(false);
    const outletOrderingModes = useSelector((state) => state.outlet?.outletOrderingModes?.ordering_modes);
    const name = `${mode.type}_${mode.id}`;
    const checkForAvaialbility = useCallback(() => {
        if (outletOrderingModes?.length) {
            for (const outletMode of outletOrderingModes) {
                if (outletMode.restaurant_ordering_mode_id === mode.id) {
                    setIsActive(true);
                }
                // else {
                //     setIsActive(false);
                // }
            }
        } else {
            setIsActive(false);
        }
    }, [mode.id, outletOrderingModes]);

    useEffect(() => {
        checkForAvaialbility();
    }, [checkForAvaialbility]);

    return (
        <>
            <EuiFlexGroup direction='row' gutterSize='s' justifyContent='flexStart' alignItems='center' wrap responsive>
                <EuiFlexItem
                    grow={false}
                    style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}
                >
                    <Controller
                        name={name}
                        control={control}
                        render={({ field }) => {
                            return (
                                <EuiSwitch
                                    compressed
                                    label=''
                                    checked={field.value || false}
                                    disabled={Boolean(watch(`is_processing_${mode.type}_${mode.id}`))}
                                    onChange={(e) => handleChange(e, mode.id, setIsActive, mode)}
                                    key={mode.display_name + 'active' + mode.id}
                                />
                            );
                        }}
                    />
                    <EuiFlexItem>
                        {watch(`is_processing_${mode.type}_${mode.id}`) ? (
                            <EuiText size='xs'>Under Process</EuiText>
                        ) : null}
                    </EuiFlexItem>
                </EuiFlexItem>
            </EuiFlexGroup>
        </>
    );
};

export default React.memo(OutletOrderingModeSwitch);
