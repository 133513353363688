import React, { useCallback, useMemo, useState, memo } from 'react';
import { EuiText, EuiDataGrid, EuiLink, EuiPagination, EuiFlexItem, EuiFlexGroup } from '@elastic/eui';
import moment from 'moment';
import ContextMenu from '../Global/Pagination/ContextMenu';

const OrderRatingTable = (props) => {
    const { orderList, activePage, rowSize } = props;

    const columns = useMemo(() => {
        return [
            {
                id: 'name',
                display: 'Name',
                displayAsText: 'Name',
                isSortable: true,
            },
            {
                id: 'date',
                display: 'Date',
                displayAsText: 'Date',
                isSortable: true,
            },
            {
                id: 'order_id',
                display: 'Order ID',
                displayAsText: 'Order ID',
                isSortable: false,
            },
            {
                id: 'order_value',
                display: 'Order Value',
                displayAsText: 'Order Value',
            },
            {
                id: 'order_rating',
                display: 'Order Rating',
                displayAsText: 'Order Rating',
            },
            {
                id: 'rider_rating',
                display: 'Rider Rating',
                displayAsText: 'Rider Rating',
            },
        ];
    }, []);

    const [sortingColumns, setSortingColumns] = useState([]);

    const orderFlyoutOpen = useCallback(
        (order) => {
            props.flyoutCallback(true);
            props.selectedOrderCallback(order);
        },
        [props]
    );

    const onSort = useCallback(
        (sortingColumns) => {
            setSortingColumns(sortingColumns);
        },
        [setSortingColumns]
    );

    const [visibleColumns, setVisibleColumns] = useState(() => columns.map(({ id }) => id));

    let mappedItems = useMemo(() => {
        return orderList?.order_collection?.orders?.data?.length > 0
            ? orderList.order_collection.orders.data.map((order) => {
                  return {
                      name: (
                          <>
                              {
                                  <EuiText key={order.order_id + 'name'} onClick={() => orderFlyoutOpen(order)}>
                                      <EuiLink> {order?.customer?.name} </EuiLink>{' '}
                                  </EuiText>
                              }
                          </>
                      ),
                      date: (
                          <>
                              {
                                  <EuiText key={order.outlet_id + 'date'}>
                                      {/* onClick={() => orderFlyoutOpen(order)} */}
                                      {order.status.placed_at &&
                                          moment(order.status.placed_at).format('DD-MM-YYYY hh:mm:ss A')}{' '}
                                  </EuiText>
                              }
                          </>
                      ),
                      order_id: <>{<EuiText key={order.order_id + 'order_id'}> {order.order_label}</EuiText>}</>,
                      order_value: (
                          <>{<EuiText key={order.order_id + 'order_value'}> {order.net_total.toFixed()}</EuiText>}</>
                      ),
                      order_rating: (
                          <>
                              {
                                  <EuiText key={order.order_id + 'order_rating'}>
                                      {' '}
                                      {order.feedback ? order.feedback.star.toFixed(2) : 'Not applicable'}
                                  </EuiText>
                              }
                          </>
                      ),
                      rider_rating: (
                          <>
                              {
                                  <EuiText key={order.order_id + 'rider_rating'}>
                                      {' '}
                                      {order.feedback ? order.feedback.delivery_star.toFixed(2) : 'Not applicable'}
                                  </EuiText>
                              }
                          </>
                      ),
                  };
              })
            : [];
    }, [orderFlyoutOpen, orderList.order_collection.orders.data]);

    const renderCellValue = useMemo(() => {
        return ({ rowIndex, columnId, setCellProps }) => {
            return mappedItems.hasOwnProperty(rowIndex) ? mappedItems[rowIndex][columnId] : null;
        };
    }, [mappedItems]);

    const PAGE_COUNT = Math.ceil(orderList.order_collection.orders.total / rowSize);

    const onChangeRowSize = useCallback(
        (row_size) => {
            // setActivePage(0);
            props.activePageCallback(0);
            // setRowSize(row_size);
            props.rowSizeCallback(row_size);
        },
        [props]
    );

    const goToPage = (pageNumber) => props.activePageCallback(pageNumber);

    const popOverItems = useMemo(() => {
        return [
            {
                key: '10 rows',
                onClick: (closePopover) => {
                    closePopover();
                    onChangeRowSize(10);
                },
                iconSize: 10,
            },
            {
                key: '20 rows',
                onClick: (closePopover) => {
                    closePopover();
                    onChangeRowSize(20);
                },
                iconSize: 20,
            },
            {
                key: '50 rows',
                onClick: (closePopover) => {
                    closePopover();
                    onChangeRowSize(50);
                },
                iconSize: 50,
            },
            {
                key: '100 rows',
                onClick: (closePopover) => {
                    closePopover();
                    onChangeRowSize(100);
                },
                iconSize: 100,
            },
        ];
    }, [onChangeRowSize]);

    return (
        <>
            <EuiDataGrid
                aria-label='Order List Table'
                columns={columns}
                columnVisibility={{ visibleColumns, setVisibleColumns }}
                rowCount={mappedItems.length}
                inMemory={{ level: 'sorting' }}
                renderCellValue={renderCellValue}
                sorting={{ columns: sortingColumns, onSort }}
            />
            <EuiFlexGroup justifyContent='spaceBetween' alignItems='center'>
                <EuiFlexItem grow={false}>
                    <ContextMenu rowSize={rowSize} popOverItems={popOverItems} />
                </EuiFlexItem>

                <EuiFlexItem grow={false}>
                    <EuiPagination
                        aria-label='Custom pagination example'
                        pageCount={PAGE_COUNT}
                        activePage={activePage}
                        onPageClick={goToPage}
                    />
                </EuiFlexItem>
            </EuiFlexGroup>
        </>
    );
};

export default memo(OrderRatingTable);
