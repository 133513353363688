import { EuiCard, EuiEmptyPrompt, EuiFlexGroup, EuiFlexItem, EuiLoadingContent, EuiSpacer } from '@elastic/eui';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import API from '../../../api/axios/API';
import { petPoojaSyncValidate } from '../../../api/menu/petPoojaSyncValidate';
import { fetchOrderingModes } from '../../../api/outlet/fetchOrderingModes';
import {
    SET_PET_POOJA_ADDON_GROUPS,
    SET_PET_POOJA_LINKED_ADDONS,
    SET_SYSTEM_ADDON_GROUPS,
} from '../../../reduxStore/types/PetPoojaItemTypes';
import { linkItemToPetPooja } from '../../../components/PetPoojaLink/Api/linkSelectedToPetPooja';
import PetPoojaSynceButton from '../../../components/PetPoojaLink/PetPoojaSynceButton';
import {
    getLinkedPetpoojaAddonChoice,
    getLinkedPetpoojaAddonGroup,
    getPetpoojaChoices,
} from '../../../components/PetPoojaLink/utils/utils';
import useFormActionsHandler from '../../../hooks/useFormActionsHandler';
import { toastsErrorMessage, toastSuccessMessage } from '../../../utils/toasts';
import { fetchUpdatedLinkedItems } from '../Utils/utils';
import { variantLinkingResetValue } from '../../../components/PetPoojaLink/utils/itemLinkingResetValue';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import 'react-virtualized/styles.css';
import AddonGroupLinkRow from '../Components/PetPoojaLink/AddonGroupLinkRow';
import UnlinkedItems from '../../../components/PetPoojaLink/UnlinkedItems';

const PetPoojaAddonLink = () => {
    const linkedItems = useSelector((state) => state.petPoojaReducer.petPoojaLinkedAddons);
    const dispatch = useDispatch();
    const [validationFailedItems, setValidationFailedItem] = useState({
        addon: {},
        items: {},
        category: {},
        variants: {},
    });
    const selectedOutlet = useSelector((state) => state.outlet.selectedOutlet);
    const [addonLoader, setAddonLoader] = useState({ loader: true, error: true });
    const [petPoojaSyncLoader, setPetPoojaSyncLoader] = useState(false);
    const [addonGroups, setAddonGroups] = useState([]);
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);
    const [isPetpoojaLinkEnable, setPetpoojaLinkEnable] = useState(false);
    const languageId = useSelector((state) => state.language.languageId);
    const [petpoojaAddonGroups, setPetpoojaAddonGroups] = useState([]);

    const outletOrderingModesEnabled = useSelector((state) => state.outlet.orderingModes)?.filter(
        (orderingMode) => orderingMode?.is_enabled
    );

    const [defaultValues, setDefaultValues] = useState({});

    const clearValidationFormError = useCallback(() => {
        setValidationFailedItem({ items: {}, category: {}, variants: {}, addon: {} });
    }, []);

    const fetchRecords = useCallback(() => {
        setAddonLoader((prevState) => ({ ...prevState, loader: true }));
        dispatch(fetchOrderingModes());
        Promise.all([
            API.get(`restaurants/:restaurantId/outlets/${selectedOutletId}/restaurant-addon-groups`),
            API.get(`restaurants/:restaurantId/outlets/${selectedOutletId}/pet-pooja/menu-fetch`),
            API.get(`restaurants/:restaurantId/outlets/${selectedOutletId}/pet-pooja/menu-links`),
        ]).then((response) => {
            if (!response[0].success || !response[1].success || !response[2].success) {
                setAddonLoader({ loader: false, error: true });
                return;
            }

            if (
                response[0].restaurant_addon_groups?.length &&
                response?.[2]?.order_response?.length &&
                response[1]?.pet_pooja_menu?.addongroups?.length
            ) {
                const linkedItems = response?.[2]?.order_response;
                const petpoojaMenu = response[1].pet_pooja_menu.addongroups;
                dispatch({
                    type: SET_SYSTEM_ADDON_GROUPS,
                    payload: response[0].restaurant_addon_groups,
                });

                let linkedAddons = [];
                response[0]?.restaurant_addon_groups?.forEach((addonGroup) => {
                    let addOnChoices = [];
                    if (addonGroup.choices.length) {
                        addonGroup.choices.forEach((addonChoiceItem) => {
                            const notFoundElements = outletOrderingModesEnabled.filter(
                                (r) =>
                                    !addonChoiceItem.ordering_modes.some(
                                        (l) => l.restaurant_ordering_mode_id === r.restaurant_ordering_mode_id
                                    )
                            );

                            if (notFoundElements?.length) {
                                addonChoiceItem.ordering_modes = [
                                    ...addonChoiceItem.ordering_modes,
                                    ...notFoundElements,
                                ];
                            }
                            addOnChoices.push({
                                choice_id: addonChoiceItem.choice_id,
                                name: addonChoiceItem.translations.name[languageId],
                                label: addonChoiceItem.translations.name[languageId],
                                price: addonChoiceItem.price,
                                orderingModes: addonChoiceItem.ordering_modes,
                                selectedPetpoojaAddonChoice: getLinkedPetpoojaAddonChoice(
                                    addonChoiceItem.choice_id,
                                    linkedItems,
                                    petpoojaMenu
                                ),
                            });
                        });
                    }

                    linkedAddons.push({
                        addonGroupId: addonGroup.addon_group_id,
                        internal_name: addonGroup.internal_name,
                        addOnChoices: addOnChoices,
                        label: addonGroup.internal_name,
                        petPoojaChoices: getPetpoojaChoices(addonGroup.addon_group_id, linkedItems, petpoojaMenu),
                        selectedPetpoojaAddonGroup: getLinkedPetpoojaAddonGroup(
                            addonGroup.addon_group_id,
                            linkedItems,
                            petpoojaMenu
                        ),
                    });
                });

                setAddonGroups(linkedAddons);
            }

            if (response[1]?.success && response[1]?.pet_pooja_menu?.success != '0') {
                dispatch({
                    type: SET_PET_POOJA_ADDON_GROUPS,
                    payload: response[1].pet_pooja_menu.addongroups,
                });

                let petpoojaAddonGroups = [];
                response[1].pet_pooja_menu.addongroups?.forEach((petpoojaAddonItem) => {
                    let addOnChoices = [];
                    if (petpoojaAddonItem.addongroupitems.length) {
                        petpoojaAddonItem.addongroupitems.forEach((addonChoiceItem) => {
                            addOnChoices.push({
                                // couldn't find the choice_id
                                // corrent payload would be
                                // choice_id: addonChoiceItem.choice_id,
                                // the corrent choice_id is inserted when its being selected
                                choice_id: addonChoiceItem.addonitemid, // is equals to third_party_module_id
                                name: addonChoiceItem.addonitem_name,
                                third_party_module_id: addonChoiceItem.addonitemid,
                                addonitemid: addonChoiceItem.addonitemid,
                                label: addonChoiceItem.addonitem_name,
                                price: addonChoiceItem.addonitem_price,
                            });
                        });
                    }
                    petpoojaAddonGroups.push({
                        petpoojaAddonGroupId: petpoojaAddonItem.addongroupid,
                        petpoojaAddonGroupName: petpoojaAddonItem.addongroup_name,
                        addOnChoices: addOnChoices,
                        label: petpoojaAddonItem.addongroup_name,
                        // module_id: petpoojaAddonItem.addongroupid,
                        third_party_module_id: petpoojaAddonItem.addongroupid,
                    });
                });

                setPetpoojaAddonGroups(petpoojaAddonGroups);
            } else if (response[1]?.pet_pooja_menu?.success == '0') {
                toastsErrorMessage(response[1]?.pet_pooja_menu?.message, dispatch);
            }

            if (response[2].order_response) {
                dispatch({
                    type: SET_PET_POOJA_LINKED_ADDONS,
                    payload: response[2].order_response,
                });
            }
            setAddonLoader({ loader: false, error: false });
        });
    }, [dispatch, languageId, selectedOutletId]);

    const validateSync = useCallback(() => {
        setPetPoojaSyncLoader(true);
        dispatch(petPoojaSyncValidate()).then((response) => {
            if (response.success) {
                clearValidationFormError();
                toastSuccessMessage('This outlet Fully Synced', dispatch);
            } else {
                const unlinkedAddonGroup = addonGroups?.filter(
                    (group) => group.addonGroupId === response?.unlinked_item_addon_groups?.restaurant_addon_group_id
                );

                setValidationFailedItem((prevState) => ({
                    ...prevState,
                    addon: unlinkedAddonGroup[0],
                    items: response?.unlinked_items,
                    variants: response?.unLinked_item_variant_option,
                }));
                toastsErrorMessage('This outlet is not Fully Synced', dispatch);
            }
            setPetPoojaSyncLoader(false);
        });
    }, [addonGroups, dispatch, clearValidationFormError]);

    const updatedLinkedItems = useCallback(async () => {
        await fetchUpdatedLinkedItems(dispatch, selectedOutletId);
    }, [dispatch, selectedOutletId, addonGroups]);

    const updateFormValues = useCallback(() => {
        let defaultValue = {};

        variantLinkingResetValue(defaultValue, addonGroups, linkedItems);
        return defaultValue;
    }, [addonGroups, linkedItems]);

    const onFormSaveApi = useCallback(
        async (data) => {
            if (!isEmpty(errors)) {
                return;
            }
            if (addonGroups?.length && data) {
                const menu_links = [];
                for (const { addonGroupId } of addonGroups) {
                    if (data[`addon_${addonGroupId}`]?.length) {
                        const addonGroup = data[`addon_${addonGroupId}`][0];
                        const modeId = addonGroup?.addOnChoices?.[0]?.orderingModes?.[0]?.restaurant_ordering_mode_id
                            ? addonGroup?.addOnChoices?.[0]?.orderingModes?.[0]?.restaurant_ordering_mode_id
                            : 141;
                        // SELF ADDON GROUP
                        if (addonGroup?.selectedGroup?.length) {
                            menu_links.push({
                                module_id: addonGroup.addonGroupId,
                                module_name: 'restaurant_addon_group',
                                third_party_service_provider: 'pet-pooja',
                                third_party_module_id: addonGroup?.selectedGroup?.[0]?.petpoojaAddonGroupId,
                                outlet_id: selectedOutletId,
                                title: addonGroup?.selectedGroup?.[0]?.label,
                                linkedItem: addonGroup?.selectedGroup?.[0]?.linkedItem,
                                restaurant_ordering_mode_id: modeId,
                                id: modeId,
                            });
                        }

                        if (addonGroup?.addOnChoices?.length) {
                            // HANDLE CHOICE DATA
                            for (const addonChoice of addonGroup.addOnChoices) {
                                if (addonChoice?.orderingModes?.length) {
                                    for (const orderingMode of addonChoice.orderingModes) {
                                        if (orderingMode?.selectedChoice?.length) {
                                            const selectedChoice = orderingMode.selectedChoice?.[0];
                                            menu_links.push({
                                                module_id: selectedChoice.choice_id,
                                                module_name: 'restaurant_addon_groups_choice',
                                                third_party_service_provider: 'pet-pooja',
                                                linkedItem: selectedChoice?.linkedItem,
                                                third_party_module_id: selectedChoice?.third_party_module_id
                                                    ? selectedChoice.third_party_module_id
                                                    : selectedChoice?.addonitemid,
                                                outlet_id: selectedOutletId,
                                                title: selectedChoice?.label,
                                                price: selectedChoice?.price,
                                                restaurant_ordering_mode_id:
                                                    // selectedChoice?.restaurant_ordering_mode_id
                                                    //     ? selectedChoice?.restaurant_ordering_mode_id
                                                    //     :
                                                    orderingMode.restaurant_ordering_mode_id,
                                            });
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                const uniqueUnlinkedItems = data?.menu_unlinks?.reduce(
                    (acc, curr) =>
                        acc?.find(
                            (item) =>
                                item.third_party_module_id === curr.third_party_module_id &&
                                curr.module_id === item.module_id &&
                                curr.module_name === item.module_name &&
                                curr.restaurant_ordering_mode_id === item.restaurant_ordering_mode_id
                        )
                            ? acc
                            : [...acc, curr],
                    []
                );

                const removedAlreadyLinkedItems = menu_links?.filter((item) => !item?.linkedItem);
                data = {
                    ...data,
                    external_menu_links: removedAlreadyLinkedItems?.length ? removedAlreadyLinkedItems : [],
                    external_menu_unlink: uniqueUnlinkedItems?.length ? uniqueUnlinkedItems : [],
                };

                const response = await linkItemToPetPooja(data, selectedOutletId);
                if (!response?.error) {
                    toastSuccessMessage('Changes Saved Successfully', dispatch);
                }

                return response;
            }
        },
        [addonGroups, dispatch, selectedOutletId]
    );

    useEffect(() => {
        if (addonGroups?.length && linkedItems?.length) {
            setDefaultValues(updateFormValues());
        }
    }, [addonGroups, updateFormValues, linkedItems]);

    useEffect(() => {
        if (!selectedOutlet?.outlet_data?.outlet_third_party_menu_setting?.is_pet_pooja_integration_enabled) {
            setPetpoojaLinkEnable(false);
            return;
        }

        setPetpoojaLinkEnable(true);
    }, [selectedOutletId, selectedOutlet]);

    useEffect(() => {
        if (isPetpoojaLinkEnable) {
            fetchRecords();
        }

        return function cleanup() {
            clearValidationFormError();
        };
    }, [selectedOutletId, clearValidationFormError, isPetpoojaLinkEnable, fetchRecords]);

    const { errors, control, watch, reset, setValue, isDirty } = useFormActionsHandler({
        onFormSaveApi,
        defaultValues,
        mode: 'all',
        onSuccessCallback: () => updatedLinkedItems(),
    });

    const methods = {
        control,
        watch,
        reset,
        setValue,
        formState: { isDirty, errors },
    };

    // HANDLES INFINITE SCROLL

    const listRef = useRef({});
    const rowHeights = useRef({});

    const getRowHeight = useCallback((index) => {
        return rowHeights.current[index] + 8 || 450;
    }, []);

    const setRowHeight = useCallback((index, size) => {
        listRef.current.resetAfterIndex(0);
        rowHeights.current = { ...rowHeights.current, [index]: size };
    }, []);

    const Row = useCallback(
        ({ index, style }) => {
            return (
                <AddonGroupLinkRow
                    index={index}
                    style={style}
                    setRowHeight={setRowHeight}
                    petpoojaAddonGroups={petpoojaAddonGroups}
                    addonGroups={addonGroups}
                />
            );
        },
        [addonGroups, petpoojaAddonGroups, setRowHeight, selectedOutletId]
    );

    if (!isPetpoojaLinkEnable) {
        return (
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: `translate(-50%, -50%)` }}>
                <EuiEmptyPrompt
                    iconType='editorStrike'
                    title={<h2>PetPooja Settings</h2>}
                    body={<p>Please enable this from outlet setings - Third party POS.</p>}
                />
            </div>
        );
    }

    if (addonLoader.loader) return <EuiLoadingContent lines={4} />;

    return (
        <FormProvider {...methods}>
            <EuiFlexGroup style={{ height: '100%' }} alignItems='flexEnd' justifyContent='flexStart' direction='column'>
                <EuiFlexItem grow={false}>
                    <PetPoojaSynceButton
                        isDisabled={!addonGroups?.length}
                        isLoading={petPoojaSyncLoader}
                        handleOnClick={validateSync}
                    />
                </EuiFlexItem>

                {!isEmpty(validationFailedItems.addon) && (
                    <EuiFlexItem grow={false} style={{ width: '95%' }}>
                        <UnlinkedItems validationFailedItems={validationFailedItems} />
                    </EuiFlexItem>
                )}

                <EuiFlexItem grow={10} style={{ width: '97%' }}>
                    <EuiFlexGroup direction='column'>
                        <EuiFlexItem as='div' style={{ height: '100%', flex: 1 }}>
                            {addonGroups?.length ? (
                                <>
                                    <AutoSizer>
                                        {({ height, width }) => (
                                            <List
                                                className='List'
                                                height={height}
                                                itemCount={addonGroups.length}
                                                itemSize={getRowHeight}
                                                width={width}
                                                ref={listRef}
                                            >
                                                {Row}
                                            </List>
                                        )}
                                    </AutoSizer>
                                </>
                            ) : (
                                <EuiCard title='Addon Groups Not Found.' />
                            )}
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer />
        </FormProvider>
    );
};

export default React.memo(PetPoojaAddonLink);
