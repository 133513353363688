import API from '../../../api/axios/API';

export const linkUnlinkOffers = async (offerId, payload, offerStatus) => {
    let response;

    try {
        response = await API.patch(`restaurants/:restaurantId/offers/${offerId}/${offerStatus}`, payload);
    } catch (error) {
        response = error;
    }

    return response;
};
