import React from 'react';
import { EuiFormRow, EuiTextArea } from '@elastic/eui';
import { Controller } from 'react-hook-form';

const TextAreaField = (props) => {
    const { label, name, placeholder, rules, errors, control, isInvalid, error, fullWidth, defaultValue } = props;
    return (
        <>
            <Controller
                render={({ field }) => (
                    <EuiFormRow
                        label={label ? label : ''}
                        isInvalid={isInvalid ? isInvalid : errors[name]}
                        error={error ? error : errors[name] ? errors[name].message : ''}
                        fullWidth={fullWidth ? fullWidth : false}
                    >
                        <EuiTextArea
                            className='scrollbar-sm'
                            compressed={props.compressed}
                            placeholder={props?.placeholder}
                            disabled={props?.disabled}
                            value={field.value}
                            onChange={field.onChange}
                        />
                    </EuiFormRow>
                )}
                name={name}
                control={control}
                rules={rules}
                fullWidth={true}
                placeholder={placeholder}
                defaultValue={defaultValue ? defaultValue : ''}
            />
        </>
    );
};

export default TextAreaField;
