import React, { useState, useEffect } from 'react';
import MenuEditorTab from '../../../components/menuComponentsRefactorCopy/menuEditorTab/MenuEditorTab';
import { useSelector, useDispatch, batch } from 'react-redux';
import {
    SET_ADDONS,
    SET_RESTAURANT_BADGES,
    SET_RESTAURANT_CATALOGUE,
    SET_ORDERING_MODES,
    SET_ACTIVE_ITEM_DETAILS,
    SET_QUANTITY_UNITS,
    REMOVE_CATALOGUE_ITEM_LIST,
} from '../../../components/menuComponentsRefactorCopy/Store/MenuTypes';
import {
    fetchAddons,
    fetchOutletCatalogue,
    fetchRestaurantCatalogue,
    fetchOrderingModes,
    fetchRestaurantBadges,
    fetchQuantityUnits,
} from '../../../components/menuComponentsRefactorCopy/catalogue/Api/FetchCatalogueInfo';
import { EuiLoadingContent } from '@elastic/eui';

const Catalogue = () => {
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);
    const isOutletMenu = useSelector((state) => state.menuReducer.isOutletMenu);
    const languageId = useSelector((state) => state.language.languageId);
    const dispatch = useDispatch();
    const [uiState, setUiState] = useState({
        isLoading: true,
        error: null,
    });

    useEffect(() => {
        if (isOutletMenu) {
            setUiState({
                isLoading: true,
                error: null,
            });

            dispatch({
                type: 'set-active-item-id',
                payload: {
                    activeItemId: false,
                },
            });
            dispatch({
                type: 'edit-item',
                payload: {
                    editIem: {},
                },
            });
        }

        return function cleanup() {
            dispatch({
                type: SET_ACTIVE_ITEM_DETAILS,
                payload: {},
            });
            dispatch({
                type: REMOVE_CATALOGUE_ITEM_LIST,
            });
        };
    }, [selectedOutletId, isOutletMenu, dispatch]);

    useEffect(() => {
        let isCancelled = false;
        if (!isCancelled) {
            (async () => {
                try {
                    const responses = await Promise.all([
                        isOutletMenu
                            ? fetchOutletCatalogue(selectedOutletId, languageId)
                            : fetchRestaurantCatalogue(languageId),
                        fetchOrderingModes(),
                        fetchRestaurantBadges(),
                        fetchQuantityUnits(),
                    ]);
                    if (!isCancelled) {
                        batch(() => {
                            dispatch({
                                type: SET_RESTAURANT_CATALOGUE,
                                payload: responses[0].categories,
                            });
                            dispatch({
                                type: SET_ORDERING_MODES,
                                payload: responses[1].ordering_modes,
                            });

                            dispatch({
                                type: SET_RESTAURANT_BADGES,
                                payload: responses[2].badges,
                            });
                            dispatch({
                                type: SET_QUANTITY_UNITS,
                                payload: responses[3].quantity_units,
                            });
                        });

                        setUiState((prevState) => ({
                            ...prevState,
                            isLoading: false,
                            error: null,
                        }));
                    }

                    return responses;
                } catch (error) {
                    setUiState((prevState) => ({
                        ...prevState,
                        isLoading: false,
                        error: error,
                    }));
                }
            })();
        }

        return () => {
            isCancelled = true;
        };
    }, [isOutletMenu, selectedOutletId, languageId, dispatch]);

    if (uiState.isLoading) {
        return <EuiLoadingContent lines={5} />;
    }

    return <MenuEditorTab />;
};

export default React.memo(Catalogue);
