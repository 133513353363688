import React, { useEffect, useState } from 'react';
import { getTimeAgo } from '../../helper/getPrettyTime';

const TimerComponent = (props) => {
    const { time } = props;
    const [timer, setTimer] = useState();

    useEffect(() => {
        if (time) {
            var interval = setInterval(() => {
                setTimer(getTimeAgo(time));
            }, 60000);
        }
        return () => {
            clearInterval(interval);
        };
    }, [time]);

    const timeInMins = () => {
        return getTimeAgo(time);
    };

    return <>{timer ? timer : timeInMins()}</>;
};

export default TimerComponent;
