import React, { useState, useCallback } from 'react';
import { MainLayout } from '../../components/layout/mainLayout/mainLayout';
import { EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiButtonEmpty, EuiPanel, EuiText } from '@elastic/eui';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { PastOrderCsvDownload } from '../../components/pastOrderCsv/pastOrderCsvDownload';
import LiveOrdersTableForPast from '../../components/liveOrders/liveOrdersTableForPast';
import LiveOrderDetailsForPast from '../../components/liveOrders/liveOrderDetailsPastOrders';
import '../../components/liveOrders/style.css';
import CustomDatePicker from '../../components/UIComponent/CustomDatePicker';
import useSingleScreenHeightCalc from '../../hooks/useSingleScreenHeightCalc';

const FailedOrdersPage = () => {
    const [startDateTime, setStartDateTime] = useState(moment());
    const [endDateTime, setEndDateTime] = useState(moment());
    const [csvState, setCsvState] = useState(false);
    const [state, setState] = useState({
        apiDataLoaded: false,
        last_page: 0,
        current_page: 0,
        page_size: 10,
        loaderPage: false,
        totalCounts: 0,
    });

    const failedOrders = useSelector((state) => state.orders.failedOrders);

    const { screenHeight } = useSingleScreenHeightCalc();

    const csvStateCallback = useCallback((value) => {
        setCsvState(value);
    }, []);

    return (
        <MainLayout isSingleScreen backgroundColorChange={true}>
            <EuiFlexGroup justifyContent='spaceBetween' alignItems='center'>
                <EuiFlexItem grow={false}>
                    <CustomDatePicker
                        startDate={startDateTime}
                        setStartDate={setStartDateTime}
                        endDate={endDateTime}
                        setEndDate={setEndDateTime}
                        displayFormat='DD-MM-YYYY'
                    />
                </EuiFlexItem>

                {failedOrders?.length ? (
                    <div>
                        <EuiButtonEmpty onClick={() => setCsvState(true)} style={{ marginRight: '10px' }}>
                            Download CSV
                        </EuiButtonEmpty>
                        <EuiText>({state.page_size} items from below page)</EuiText>
                    </div>
                ) : null}

                {csvState && <PastOrderCsvDownload orders={failedOrders} csvStateCallback={csvStateCallback} />}
            </EuiFlexGroup>
            <EuiSpacer />
            <EuiFlexGroup>
                <EuiFlexItem grow={6}>
                    <>
                        <EuiPanel paddingSize='s' style={{ height: screenHeight }} className=' scrollbar-sm'>
                            <LiveOrdersTableForPast
                                startDateTime={startDateTime}
                                endDateTime={endDateTime}
                                state={state}
                                setState={setState}
                                isFailedOrders={true}
                            />
                        </EuiPanel>
                    </>
                </EuiFlexItem>
                <EuiFlexItem grow={4}>
                    <div>
                        <EuiPanel paddingSize='s' style={{ height: screenHeight }} className=' scrollbar-sm'>
                            <LiveOrderDetailsForPast
                                startDateTime={startDateTime}
                                endDateTime={endDateTime}
                                state={state}
                                isFailedOrders={true}
                            />
                        </EuiPanel>
                    </div>
                </EuiFlexItem>
            </EuiFlexGroup>
        </MainLayout>
    );
};

export default FailedOrdersPage;
