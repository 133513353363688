import React, { useCallback, useState } from 'react';
import {
    EuiFieldText,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow,
    EuiSelect,
    EuiSpacer,
    EuiText,
    EuiTextArea,
} from '@elastic/eui';
import TextField from '../../../../../components/Form/TextField';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import ItemDetailsCollaspibleNav from './ItemDetailsCollaspibleNav';
import { ReactComponent as BasicDetailsIcon } from '../../../../../assets/img/svg/BasicDetails.svg';
import _ from 'lodash';

const options = [
    { value: 'non-vegetarian', text: 'Non Veg' },
    { value: 'vegetarian', text: 'Veg' },
    { value: 'egg', text: 'Eggs' },
];

const ItemBasicDetails = ({ variantConditionForPrice }) => {
    const {
        control,
        formState: { errors },
        watch,
        setValue,
    } = useFormContext();
    const [isNavOpen, setIsNavOpen] = useState(false);
    const isOutletMenu = useSelector((state) => state.menuReducer.isOutletMenu);
    const internal_name = useWatch({
        control,
        name: 'internal_name',
    });
    const editItem = useSelector((state) => state.menuReducer.activeItemDetails.details);
    const availableLanguages = useSelector((state) => state.language.availableLanguages);
    const isDescriptionAvailable = availableLanguages.find(
        ({ language_id }) => editItem?.translations?.description[language_id] && watch(`description${language_id}`)
    );
    const isTitleAvailable = availableLanguages.find(
        ({ language_id }) => editItem?.translations?.title[language_id] && watch(`title${language_id}`)
    );
    const themeMode = useSelector((state) => state.theme.theme);

    const onChangeFoodType = useCallback(
        (value) => {
            setValue('food_type', value);
        },
        [setValue]
    );

    return (
        <>
            <ItemDetailsCollaspibleNav
                cardTitle='Basic Details'
                isNavOpen={isNavOpen}
                cardDescription={
                    <EuiFlexGroup
                        direction='column'
                        justifyContent='spaceBetween'
                        alignItems='flexStart'
                        gutterSize='xs'
                    >
                        <EuiFlexItem>
                            {!internal_name ? (
                                <EuiFlexItem>
                                    <EuiText size='xs'>Edit basic details for the selected item</EuiText>
                                </EuiFlexItem>
                            ) : null}
                            <EuiSpacer size='xs' />
                            <EuiText size='xs' style={{ fontWeight: 600 }}>
                                Internal Name: <span style={{ fontWeight: 400 }}> {internal_name} </span>
                            </EuiText>
                            <>
                                <EuiSpacer size='xs' />
                                <EuiText size='xs' style={{ fontWeight: 600, display: 'inline' }}>
                                    {isTitleAvailable ? 'Display Name: ' : null}
                                    {availableLanguages.map((language, index) => {
                                        return (
                                            <EuiText key={index.toString()} size='xs' style={{ display: 'inline' }}>
                                                {watch(`title${language.language_id}`)}
                                                {isTitleAvailable &&
                                                    availableLanguages?.length > 1 &&
                                                    availableLanguages?.length !== index + 1 &&
                                                    ' || '}
                                            </EuiText>
                                        );
                                    })}
                                </EuiText>

                                <EuiSpacer size='xs' />
                                <EuiText size='xs' style={{ fontWeight: 600, display: 'inline' }}>
                                    {isDescriptionAvailable ? 'Description: ' : null}
                                    {availableLanguages.map((language, index) => {
                                        return (
                                            <React.Fragment key={index.toString()}>
                                                {watch(`description${language.language_id}`) ? (
                                                    <EuiText
                                                        size='xs'
                                                        style={{ wordBreak: 'break-all', display: 'inline' }}
                                                    >
                                                        {_.truncate(watch(`description${language.language_id}`), {
                                                            length: 125,
                                                        })}
                                                        {availableLanguages?.length > 1 &&
                                                            availableLanguages?.length !== index + 1 &&
                                                            ' ||'}{' '}
                                                    </EuiText>
                                                ) : null}
                                            </React.Fragment>
                                        );
                                    })}
                                </EuiText>
                            </>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                }
                setIsNavOpen={setIsNavOpen}
                toggleText='Edit Basic Details'
                icon={<BasicDetailsIcon fill={themeMode === 'light' ? 'black' : 'white'} />}
            >
                {variantConditionForPrice() ? (
                    <>
                        <EuiFormRow fullWidth label='Food type'>
                            <Controller
                                render={() => (
                                    <EuiSelect
                                        fullWidth
                                        options={options}
                                        value={watch('food_type') ? watch('food_type') : options[1].value}
                                        onChange={(e) => onChangeFoodType(e.target.value)}
                                        disabled={isOutletMenu}
                                    />
                                )}
                                name='food_type'
                                control={control}
                            />
                        </EuiFormRow>

                        <EuiSpacer />
                    </>
                ) : null}

                <EuiFlexGroup direction='row' justifyContent='center' alignItems='flexStart'>
                    {/* Internal Name */}

                    <EuiFlexItem component='div' grow={10}>
                        <TextField
                            fullWidth
                            errors={errors}
                            label={'Internal name - Title'}
                            name={'internal_name'}
                            placeholder='Internal name'
                            rules={{
                                required: 'Please enter internal name',
                            }}
                            control={control}
                            disabled={isOutletMenu}
                        />
                    </EuiFlexItem>
                </EuiFlexGroup>

                {/* Multiple Title Fields */}
                <EuiFlexGroup
                    justifyContent='flexStart'
                    gutterSize='none'
                    alignItems='flexStart'
                    direction='column'
                    responsive
                    wrap
                    style={{ minWidth: '95%' }}
                >
                    <EuiFlexItem>
                        {availableLanguages?.length > 1 ? (
                            <>
                                <EuiSpacer size='s' />
                                <EuiText size='xs' style={{ fontWeight: 600, lineHeight: '190%' }}>
                                    Display Name
                                </EuiText>
                            </>
                        ) : null}
                    </EuiFlexItem>
                    <EuiFlexItem style={{ minWidth: '100%' }}>
                        <EuiFlexGroup
                            gutterSize='s'
                            justifyContent={availableLanguages?.length > 1 ? 'center' : 'flexStart'}
                            alignItems='center'
                            direction='row'
                            responsive
                            wrap
                            style={{ minWidth: '100%' }}
                        >
                            {availableLanguages?.length > 1 ? (
                                availableLanguages?.map((language) => {
                                    return (
                                        <EuiFlexItem style={{ minWidth: 150 }} key={language.language_id.toString()}>
                                            <EuiFlexItem grow={5}>
                                                <EuiFormRow
                                                    error={
                                                        errors['title' + language.language_id]
                                                            ? errors['title' + language.language_id].message
                                                            : ''
                                                    }
                                                    isInvalid={errors['title' + language.language_id]}
                                                >
                                                    <Controller
                                                        render={({ field }) => (
                                                            <EuiFieldText
                                                                value={field.value}
                                                                disabled={isOutletMenu}
                                                                onChange={field.onChange}
                                                                placeholder={`${language.language_label}`}
                                                            />
                                                        )}
                                                        name={`title${language.language_id}`}
                                                        placeholder='Item title'
                                                        control={control}
                                                        rules={{
                                                            required: 'Please enter item title',
                                                        }}
                                                    />
                                                </EuiFormRow>
                                            </EuiFlexItem>
                                        </EuiFlexItem>
                                    );
                                })
                            ) : (
                                <EuiFlexItem grow={10}>
                                    <EuiSpacer size='s' />
                                    <EuiFormRow
                                        error={
                                            errors['title' + availableLanguages[0].language_id]
                                                ? errors['title' + availableLanguages[0].language_id].message
                                                : ''
                                        }
                                        fullWidth
                                        isInvalid={errors['title' + availableLanguages[0].language_id]}
                                        label={`Display Name - ` + availableLanguages[0]?.language_label}
                                    >
                                        <Controller
                                            render={({ field }) => (
                                                <EuiFieldText
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    fullWidth
                                                    disabled={isOutletMenu}
                                                    placeholder={`${availableLanguages[0].language_label}`}
                                                />
                                            )}
                                            name={`title${availableLanguages[0].language_id}`}
                                            placeholder='Item title'
                                            control={control}
                                            rules={{
                                                required: 'Please enter item title',
                                            }}
                                        />
                                    </EuiFormRow>
                                </EuiFlexItem>
                            )}
                        </EuiFlexGroup>
                    </EuiFlexItem>
                </EuiFlexGroup>

                {/* Multiple Description Fields */}

                <EuiFlexGroup
                    gutterSize='s'
                    justifyContent='center'
                    alignItems='center'
                    direction='row'
                    wrap
                    responsive
                >
                    {availableLanguages.map((language) => {
                        return (
                            <EuiFlexItem style={{ minWidth: 150 }} key={language.language_id.toString()}>
                                <EuiSpacer />
                                <EuiFlexItem grow={5}>
                                    <EuiFormRow
                                        fullWidth={availableLanguages?.length === 1}
                                        label={`${language.language_label} - Description`}
                                    >
                                        <Controller
                                            render={({ field }) => (
                                                <EuiTextArea
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    disabled={isOutletMenu}
                                                    fullWidth
                                                />
                                            )}
                                            name={`description${language.language_id}`}
                                            placeholder='Category description'
                                            control={control}
                                            disabled={true}
                                        />
                                    </EuiFormRow>
                                </EuiFlexItem>
                            </EuiFlexItem>
                        );
                    })}
                </EuiFlexGroup>
            </ItemDetailsCollaspibleNav>
        </>
    );
};

export default React.memo(ItemBasicDetails);
