import APIV3 from '../../../api/axios/APIV3';

export const createSection = async (outletId, sectionData) => {
    let response;

    try {
        response = await APIV3.post(`restaurants/:restaurantId/outlets/${outletId}/outlet-sections`, sectionData);
    } catch (error) {
        response = error;
    }

    return response;
};

export const updateSection = async (outletId, section_id, updatedData) => {
    let response;

    try {
        response = await APIV3.put(
            `restaurants/:restaurantId/outlets/${outletId}/outlet-sections/${section_id}`,
            updatedData
        );
    } catch (error) {
        response = error;
    }

    return response;
};

export const deleteSection = async (outletId, section_id) => {
    let response;

    try {
        response = await APIV3.delete(`restaurants/:restaurantId/outlets/${outletId}/outlet-sections/${section_id}`);
    } catch (error) {
        response = error;
    }

    return response;
};
