import React, { useState } from 'react';
import { EuiDragDropContext, EuiDroppable, EuiFlexItem, EuiSpacer } from '@elastic/eui';
import { useSelector } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import CategoryRow from './categoryRow copy';
import './style.css';

import useSingleScreenHeightCalc from '../../../hooks/useSingleScreenHeightCalc';

LoadingOverlay.propTypes = undefined;

const CategoryList = ({ isOutletMenu }) => {
    const categories = useSelector((state) => state.menuReducer.restaurantCategories);
    const [loaderOverlay, setLoaderOverlay] = useState(false);
    const { screenHeight } = useSingleScreenHeightCalc(400);

    return (
        <EuiFlexItem>
            <EuiDragDropContext>
                <LoadingOverlay active={loaderOverlay} spinner text='Re-arranging sequence'>
                    <EuiDroppable
                        style={{ height: screenHeight }}
                        className=' scrollbar-sm'
                        droppableId='DROPPABLE_AREA_CATEGORY_LIST'
                    >
                        {categories
                            .filter((category) => {
                                return category.parent_id === null;
                            })
                            .map((category, index) => {
                                return (
                                    <CategoryRow
                                        key={category.category_id}
                                        isOutletMenu={isOutletMenu}
                                        index={index}
                                        category={category}
                                    />
                                );
                            })}
                        <EuiSpacer size='s' />
                    </EuiDroppable>
                </LoadingOverlay>
            </EuiDragDropContext>
        </EuiFlexItem>
    );
};

export default CategoryList;
