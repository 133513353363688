import React from 'react';
import moment from 'moment';
import './style.css';
import { isEmpty } from 'lodash';
import { capitalize } from '../../helper/capitalize';

export default class KotPrint extends React.Component {
    getTotalItem = (items) => {
        let count = 0;
        items.forEach((item) => {
            count = count + item.quantity;
        });
        return count;
    };

    getPaymentMethod = (mode) => {
        if (mode === 'CASH_ON_DELIVERY') {
            return 'Cash on delivery';
        } else {
            return 'Online';
        }
    };

    renderItemRow = (orderItem, index) => {
        return (
            <span key={index}>
                <span style={{ textAlign: 'initial', display: 'inline-block' }}>
                    <b>{orderItem.item.translations.title[this.props.languageId]}</b>

                    {!isEmpty(orderItem.variant_option_information) &&
                    !isEmpty(orderItem.variant_option_information.variant_group_information) ? (
                        <span style={{ display: 'block' }}>
                            - {orderItem.variant_option_information.translations.title[this.props.languageId]}
                        </span>
                    ) : null}

                    {orderItem.customizations &&
                        orderItem.customizations.map((addons, index) => (
                            <span style={{ display: 'block' }} key={index.toString()}>
                                - {addons?.translations?.name?.[this.props.languageId]}
                            </span>
                        ))}
                </span>
            </span>
        );
    };
    render() {
        const center = {
            textAlign: 'center',
        };
        if (isEmpty(this.props.order)) return;

        return (
            <div>
                <table cellPadding={0} cellSpacing={0} style={{ borderCollapse: 'collapse', fontSize: 14 }}>
                    <thead>
                        <tr>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{ borderStyle: 'solid', border: '0px', borderBottom: '1px' }}>
                            <td>&nbsp;</td>
                        </tr>

                        <tr>
                            <td colSpan={2} style={center}>
                                {this.props.order?.outlet?.translations?.name[this.props.languageId]}
                            </td>
                        </tr>

                        <tr>
                            <td colSpan={2} style={center}>
                                &nbsp;{moment(this.props.order.placed_at).format('Do MMMM h:mm a')}
                            </td>
                        </tr>

                        {this.props?.order?.order_label ? (
                            <tr>
                                <td colSpan={2} style={center}>
                                    Order#-&nbsp;{this.props.order.order_label}
                                </td>
                            </tr>
                        ) : null}

                        {this.props.order?.table_name ? (
                            <tr>
                                <td colSpan={2} style={center}>
                                    Table No:&nbsp;{this.props.order?.table_name}
                                </td>
                            </tr>
                        ) : null}
                        {this.props?.order?.payment_method ? (
                            <tr>
                                <td colSpan={2} style={center}>
                                    {this.getPaymentMethod(this.props.order.payment_method.payment_method)}
                                </td>
                            </tr>
                        ) : null}
                        <tr>
                            <td colSpan={2} style={center}>
                                {capitalize(this.props.order.ordering_mode)}
                            </td>
                        </tr>

                        <tr>
                            <td colSpan={2}>
                                <hr />
                            </td>
                        </tr>

                        <tr>
                            <td colSpan={2} style={center}>
                                {this.props?.order?.customer?.name}
                            </td>
                        </tr>
                        {this.props?.order?.customer?.mobile_number ? (
                            <tr>
                                <td colSpan={2} style={center}>
                                    {this.props?.order?.customer?.country_code}{' '}
                                    {this.props?.order?.customer?.mobile_number}
                                </td>
                            </tr>
                        ) : null}

                        {this.props?.order?.customer?.email ? (
                            <tr style={center}>
                                <td colSpan={2} style={center}>
                                    {this.props.order.customer.email}
                                </td>
                            </tr>
                        ) : null}

                        <tr>
                            <td colSpan={2}>
                                <hr />
                            </td>
                        </tr>

                        {/* <tr>
                            <td>
                                Total Items:&nbsp;<b>{this.getTotalItem(this.props.order.cart.cart_items)}</b>
                            </td>
                        </tr> */}

                        {this.props.order?.cart?.special_instructions && (
                            <tr>
                                <td colSpan={2} style={center}>
                                    {this.props.order.cart.special_instructions}
                                </td>
                            </tr>
                        )}

                        <tr>
                            <th colSpan={1} style={{ textAlign: 'initial', fontWeight: 400 }}>
                                Item
                            </th>
                            <th colSpan={1} style={{ textAlign: 'end', fontWeight: 400 }}>
                                Qty
                            </th>
                        </tr>

                        {this.props.order.cart.cart_items?.length ? (
                            <>
                                {this.props.order.cart.cart_items.map((orderItem, index) => {
                                    return (
                                        <>
                                            <tr>
                                                <td
                                                    // colSpan={1}
                                                    key={index.toString() + orderItem?.id?.toString()}
                                                    style={{ maxWidth: '60%' }}
                                                >
                                                    {this.renderItemRow(orderItem, index)}
                                                </td>
                                                <td
                                                    colSpan={1}
                                                    style={{
                                                        verticalAlign: 'initial',
                                                        textAlign: 'middle',
                                                        display: 'inline-block',
                                                    }}
                                                >
                                                    &nbsp; &nbsp;x{orderItem?.quantity}
                                                </td>
                                            </tr>
                                            {orderItem?.children?.length ? (
                                                <tr>
                                                    {orderItem.children.map((childOrderItem, childIndex) => {
                                                        return (
                                                            <>
                                                                <td
                                                                    colSpan={1}
                                                                    key={
                                                                        childIndex.toString() +
                                                                        childOrderItem?.id?.toString()
                                                                    }
                                                                    style={{ maxWidth: '60%' }}
                                                                >
                                                                    {this.renderItemRow(
                                                                        childOrderItem,
                                                                        'child-' + childIndex
                                                                    )}
                                                                </td>
                                                                {childOrderItem?.quantity ? (
                                                                    <td
                                                                        colSpan={1}
                                                                        style={{
                                                                            verticalAlign: 'initial',
                                                                            textAlign: 'middle',
                                                                            display: 'inline-block',
                                                                        }}
                                                                    >
                                                                        &nbsp; &nbsp;x{childOrderItem?.quantity}
                                                                    </td>
                                                                ) : null}
                                                            </>
                                                        );
                                                    })}
                                                </tr>
                                            ) : null}
                                            {index > 0 ? (
                                                <tr>
                                                    <td>&nbsp;</td>
                                                </tr>
                                            ) : null}
                                        </>
                                    );
                                })}
                            </>
                        ) : null}
                        <tr>
                            <td colSpan={2}>
                                <hr />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2} style={center}>
                                Powered By Fab Systems
                            </td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}
