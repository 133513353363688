import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    EuiButton,
    EuiFlexGroup,
    EuiFlexItem,
    EuiModal,
    EuiModalBody,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiSpacer,
    EuiTitle,
} from '@elastic/eui';
import { isEmpty } from 'lodash';
import { userHasPermission } from '../../../helper/auth';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { baseApiUrl } from '../../../envConfig';
import { useDropzone } from 'react-dropzone';
import Resizer from 'react-image-file-resizer';

const UploadImageDropZoneNotification = ({
    handleUploadImageModalToggle,
    imageType = 'categories',
    fetchImages,
    aspectRatio,
    isPreviewOn,
}) => {
    const onDrop = useCallback((acceptedFiles) => {
        let files;
        if (acceptedFiles.dataTransfer) {
            files = acceptedFiles.dataTransfer.files;
        } else if (acceptedFiles?.length) {
            files = acceptedFiles[0];
        }

        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
        };
        setImageValue({ ...files, filename: files?.name });
        reader.readAsDataURL(files);
    }, []);

    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
        onDrop: onDrop,
        maxFiles: 1,
    });
    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    const [image, setImage] = useState(null);
    const restaurantID = useSelector((state) => state.auth.selectedRestaurantId);
    const [cropper, setCropper] = useState();
    const [imageValue, setImageValue] = useState();
    const [buttonDisable, setButtonDisable] = useState(false);
    const permissions = useSelector((state) => state.permission.permission);
    const history = useHistory();
    const token = localStorage.getItem('authToken');
    let cropperRef = useRef(null);

    const onChange = useCallback((e) => {
        e.preventDefault();
        let files;
        setImageValue({ ...e, filename: e?.target?.files?.[0]?.name });
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
        };

        reader.readAsDataURL(files[0]);
    }, []);

    const handleDeletion = useCallback(() => {
        setImageValue(null);
    }, []);

    const dataURLtoFile = useCallback((uri, filename) => {
        var arr = uri.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }, []);

    const handleUploadAction = useCallback(async () => {
        setButtonDisable(true);
        const data = cropper.getCroppedCanvas().toDataURL();
        let formData = new FormData();

        resizeFile(dataURLtoFile(data, imageValue?.filename)).then((data) => {
            formData.append('image', data);
            formData.append('image_type', imageType);
            formData.append('original_file_name', data?.name);
            fetch(`${baseApiUrl}/restaurants/${restaurantID}/uploads`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            })
                .then((response) => {
                    setButtonDisable(false);
                    handleUploadImageModalToggle();
                    fetchImages(imageType);
                })
                .catch((error) => {
                    setButtonDisable(false);
                    window.alert(error);
                });
        });
    }, [
        cropper,
        dataURLtoFile,
        imageValue?.filename,
        imageType,
        restaurantID,
        token,
        handleUploadImageModalToggle,
        fetchImages,
    ]);

    const resizeFile = (data) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                data,
                512,
                256,
                'PNG',
                100,
                0,
                (uri) => {
                    resolve(dataURLtoFile(uri, imageValue?.filename));
                },
                'base64'
            );
        });

    return (
        <>
            <EuiModal onClose={handleUploadImageModalToggle}>
                <EuiModalHeader>
                    <EuiModalHeaderTitle>
                        <h1>Upload Image</h1>
                    </EuiModalHeaderTitle>
                </EuiModalHeader>
                <EuiModalBody style={{ minWidth: '780px', overflowY: 'scroll' }} className='scrollbar'>
                    <div>
                        <div style={{ width: '100%' }}>
                            {isEmpty(imageValue) ? (
                                <section style={{ maxWidth: '97%' }}>
                                    <div {...getRootProps({ style })}>
                                        <input {...getInputProps()} onChange={onChange} accept='image/gif , image/png , image/jpeg  , image/jpg' />
                                        <p>Drag 'n' drop some files here, or click to select files</p>
                                        <p>Accepted sizes: 512px x 256px or 1024px x 512px </p>
                                        <p>Accepted file types: .jpeg, .jpg, .png Max file size: 300kB</p>
                                    </div>
                                </section>
                            ) : null}
                            <EuiSpacer />
                            {!isEmpty(imageValue) ? (
                                <Cropper
                                    style={{ maxHeight: 400, width: '100%' }}
                                    // zoomTo={0.5}
                                    initialAspectRatio={aspectRatio}
                                    aspectRatio={aspectRatio}
                                    preview='.img-preview'
                                    src={image}
                                    minCropBoxHeight={100}
                                    minCropBoxWidth={100}
                                    background={false}
                                    responsive={true}
                                    autoCropArea={1}
                                    checkOrientation={false}
                                    onInitialized={(instance) => {
                                        setCropper(instance);
                                    }}
                                    maxLength={5242880}
                                    guides={true}
                                    ref={cropperRef}
                                />
                            ) : null}
                        </div>
                        {!isEmpty(imageValue) && isPreviewOn ? (
                            <>
                                <EuiFlexGroup gutterSize='none' alignItems='center' justifyContent='center'>
                                    <EuiFlexItem>
                                        <div className='box' style={{ width: '50%', float: 'right' }}>
                                            <EuiTitle size='s'>
                                                <h5>Preview</h5>
                                            </EuiTitle>
                                            <div
                                                className='img-preview'
                                                style={{ width: '100%', float: 'left', height: '300px' }}
                                            />
                                        </div>
                                    </EuiFlexItem>
                                </EuiFlexGroup>
                            </>
                        ) : null}
                    </div>
                    {!isEmpty(imageValue) ? (
                        <>
                            <EuiFlexGroup style={{ margin: 10, width: '100%' }} alignItems='center'>
                                <EuiFlexItem
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-around',
                                    }}
                                >
                                    <>
                                        <EuiFlexItem grow={false} style={{ marginRight: '10px' }}>
                                            <EuiButton
                                                isLoading={
                                                    buttonDisable ||
                                                    !userHasPermission(permissions, history.location.pathname, 'write')
                                                }
                                                fill
                                                onClick={() => {
                                                    handleUploadAction();
                                                }}
                                            >
                                                Upload
                                            </EuiButton>
                                        </EuiFlexItem>
                                        <EuiFlexItem grow={false}>
                                            <EuiButton
                                                fill
                                                color='danger'
                                                iconType='trash'
                                                disabled={buttonDisable}
                                                onClick={handleDeletion}
                                            >
                                                Remove
                                            </EuiButton>
                                        </EuiFlexItem>
                                    </>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </>
                    ) : null}
                </EuiModalBody>
            </EuiModal>
        </>
    );
};

export default React.memo(UploadImageDropZoneNotification);

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
};

const focusedStyle = {
    borderColor: '#2196f3',
};

const acceptStyle = {
    borderColor: '#00e676',
};

const rejectStyle = {
    borderColor: '#ff1744',
};