import * as types from '../types/petPoojaLink';

const initialState = {
    linkRecord: [],
    petPoojaItems: [],
    petPoojaVariants: [],
    linkedItems: [],
    discardChanges: '',
    unLinkedItems: [],
    petPoojaAddons: [],
    petPoojaLink: [],
    petPoojaTaxes: [],
    petPoojaRestaurant: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.SET_LINK_RECORD:
            return {
                ...state,
                linkRecord: action.payload.linkRecord,
            };
        case types.SET_PET_POOJA_ITEMS:
            return {
                ...state,
                petPoojaItems: action.payload.petPoojaItems,
            };
        case types.SET_PET_POOJA_VARIANTS:
            return {
                ...state,
                petPoojaVariants: action.payload.petPoojaVariants,
            };
        case types.SET_LINKED_ITEMS:
            return {
                ...state,
                linkedItems: action.payload.linkedItems,
            };
        case types.SET_DISCARD_CHANGES:
            return {
                ...state,
                discardChanges: action.payload.discardChanges,
            };
        case types.SET_UNLINK_ITEMS:
            return {
                ...state,
                unLinkedItems: action.payload.unLinkedItems,
            };
        case types.SET_PET_POOJA_ADDONS:
            return {
                ...state,
                petPoojaAddons: action.payload.petPoojaAddons,
            };
        case types.SET_PET_POOJA_TAXES:
            return {
                ...state,
                petPoojaTaxes: action.payload.petPoojaTaxes,
            };
        case types.SET_PETPOOJA_RESTAURANT:
            return {
                ...state,
                petPoojaRestaurant: action.payload,
            };
        default:
            return state;
    }
};
