import React, { useRef } from 'react';
import { EuiFormRow, EuiFieldNumber } from '@elastic/eui';
import { Controller } from 'react-hook-form';

const NumberField = (props) => {
    const numberRef = useRef(null);
    const onWheel = () => {
        numberRef && numberRef.current && numberRef.current.blur();
    };
    const { label, name, placeholder, rules, errors, isInvalid, control, fullWidth, defaultValue, helpText } = props;

    return (
        <Controller
            render={({ field }) => (
                <EuiFormRow
                    label={label ? label : ''}
                    style={props?.labelStyles}
                    isInvalid={isInvalid ? isInvalid : errors[name]}
                    error={errors[name] ? errors[name].message : ''}
                    fullWidth={fullWidth ? fullWidth : false}
                    helpText={helpText ? helpText : ''}
                >
                    <EuiFieldNumber
                        inputRef={numberRef}
                        onWheel={onWheel}
                        {...props}
                        placeholder={props.placeholder}
                        value={field.value}
                        onChange={field.onChange}
                    />
                </EuiFormRow>
            )}
            name={name}
            rules={rules}
            control={control}
            fullWidth={true}
            placeholder={placeholder}
            inputRef={numberRef}
            onWheel={onWheel}
            defaultValue={defaultValue ? defaultValue : ''}
        />
    );
};

export default NumberField;
