import { EuiSpacer, EuiText } from '@elastic/eui';
import React from 'react';
import { paymentMethod } from '../liveOrders/liveOrdersTable.js';
import RupeeSymbol from '../rupeeComponent.js';

const OrderRowValue = ({ value, item }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>
                <EuiText size='s'>
                    <RupeeSymbol size={'14px'} />
                    {value}
                </EuiText>
            </div>
            {item?.order_details?.payment_method ? (
                <>
                    <EuiSpacer size='xs' />
                    <div
                        style={{
                            border:
                                item?.order_details?.payment_method?.payment_method == 'CASH_ON_DELIVERY'
                                    ? '1px solid rgb(246, 131, 15)'
                                    : '1px solid rgb(14, 145, 140)',
                        }}
                    >
                        <EuiText style={{ margin: '0 2px' }} size='xs'>
                            {paymentMethod(item?.order_details?.payment_method)}
                        </EuiText>
                    </div>
                </>
            ) : null}
        </div>
    );
};

export default OrderRowValue;
