import { EuiText, EuiIcon, EuiCard, EuiFlexItem, EuiSpacer, EuiButton } from '@elastic/eui';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { liverOrderStatusChange } from '../../api/liveOrders/liverOrderStatusChange';
import { getLiveOrders } from '../../api/liveOrders/getLiveOrderNewAPI';

const ActionRequiredErrorCard = (props) => {
    const { selectedOrder } = props;

    const currentOrderState = useSelector((state) => state.orders.currentOrderState);
    const dispatch = useDispatch();

    const calculateElapsedTime = () => {
        if (selectedOrder?.status?.restaurant_ready_at) {
            let date1 = new Date(selectedOrder?.status?.restaurant_ready_at);
            let date2 = new Date();
            let differenceInMins = (date2.getTime() - date1.getTime()) / 60000;

            return differenceInMins > 5;
        }

        return false;
    };

    const updateOrderStatusCall = (order, orderId, status, time = 0) => {
        dispatch(liverOrderStatusChange({ status: status, kitchen_preparation_time: time })).then((res) => {
            if (res.success) {
                dispatch(getLiveOrders(currentOrderState));
            } else {
            }
        });
    };

    return (
        <>
            {selectedOrder.ordering_mode === 'delivery' ? (
                <>
                    {selectedOrder.rider_assignment_action_required ? (
                        <>
                            <EuiCard
                                display='plain'
                                layout='horizontal'
                                paddingSize='s'
                                style={{ backgroundColor: '#ff00002e' }}
                                title={
                                    <EuiFlexItem style={{ alignItems: 'center', flexDirection: 'row' }}>
                                        <EuiIcon color='rgb(189, 39, 30)' type='alert' />
                                        <div style={{ marginLeft: '10px', color: 'rgb(189, 39, 30)' }}>
                                            <span>Action required</span>
                                        </div>
                                    </EuiFlexItem>
                                }
                                description='Rider has rejected the order. Please assign new rider.'
                            />
                            <EuiSpacer />
                        </>
                    ) : (
                        ''
                    )}
                </>
            ) : (
                <>
                    {selectedOrder.ordering_mode === 'takeaway' ? (
                        <>
                            {calculateElapsedTime() ? (
                                <>
                                    <EuiCard
                                        display='plain'
                                        layout='horizontal'
                                        paddingSize='s'
                                        style={{ backgroundColor: '#ff00002e' }}
                                        title={
                                            <EuiFlexItem style={{ alignItems: 'center', flexDirection: 'row' }}>
                                                <EuiIcon color='rgb(189, 39, 30)' type='alert' />
                                                <div style={{ marginLeft: '10px', color: 'rgb(189, 39, 30)' }}>
                                                    <span>Action required</span>
                                                </div>
                                            </EuiFlexItem>
                                        }
                                        description={
                                            <>
                                                <EuiText size='s'>
                                                    The takeaway order has been ready for a long time. Please call
                                                    customer or complete the order.
                                                </EuiText>
                                                <EuiSpacer size='s' />
                                                <div>
                                                    <EuiButton
                                                        onClick={() =>
                                                            updateOrderStatusCall(
                                                                selectedOrder,
                                                                selectedOrder.order_id,
                                                                'customer_picked_up'
                                                            )
                                                        }
                                                        fill
                                                        size='s'
                                                    >
                                                        Complete order
                                                    </EuiButton>
                                                </div>
                                            </>
                                        }
                                    />
                                    <EuiSpacer />
                                </>
                            ) : (
                                ''
                            )}
                        </>
                    ) : null}
                </>
            )}
        </>
    );
};

export default ActionRequiredErrorCard;
