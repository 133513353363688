import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userHasPermission } from '../../../helper/auth';
import { authModule } from '../../../helper/authModulePermission';

export default ({ component: Component, ...rest }) => {
    // Steps to check to show a PrivateRoute.

    // 1. Check if user is logged in, if not then redirect to login page.
    const user = useSelector((state) => state.auth.user);
    if (!user) {
        return <Route {...rest} render={(props) => <Redirect to='/login' />} />;
    }

    // 2. Check if there is a default selected restaurant.
    // If not let's redirect user to restaurant selection page.
    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    if (!selectedRestaurantId) {
        return <Route {...rest} render={(props) => <Redirect to='/select-restaurant' />} />;
    }

    // 3. Check if user has permission to access the current route.
    const permissions = useSelector((state) => state.permission.permission);

    // Get current location
    const location = useLocation();
    // If user does not have permission, redirect him to the error page.
    if (location.pathname === '/credits') {
        if (
            !authModule(permissions, 'read', 'infrastructure_credit') &&
            !authModule(permissions, 'read', 'service_credit')
        ) {
            return <Route {...rest} render={(props) => <Redirect to='/error' />} />;
        }
    } else if (!userHasPermission(permissions, location.pathname, 'read')) {
        return <Route {...rest} render={(props) => <Redirect to='/error' />} />;
    }

    return <Route {...rest} render={(props) => <Component {...props} />} />;
};
