import {
    EuiFlexGroup,
    EuiFlexItem,
    EuiTitle,
    EuiText,
    EuiDataGrid,
    EuiPagination,
    EuiLoadingContent,
} from '@elastic/eui';
import moment from 'moment';
import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCreditTransactions } from '../../../api/topup/TransactionHistory';
import CustomDatePicker from '../../../components/UIComponent/CustomDatePicker';
import { isEmpty } from 'lodash';
import CreditsSettings from '../Components/CreditsSettings';
import AddCredits from '../Components/AddCredits';
import ContextMenu from '../../../components/Global/Pagination/ContextMenu';
import { popOverItems } from '../../../components/Global/Pagination/utils';
import { additionalDateActions } from '../Utils/Utils';

const DataContext = createContext();
const typeOfTransaction = 'infrastructure';

const InfraCredits = (props) => {
    const dispatch = useDispatch();
    const [transactionData, setTransactionData] = useState([]);
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment());
    const [availableCredits, setAvailableCredits] = useState(0);
    const [loader, setLoader] = useState(true);
    const [alertAmount, setAlertAmount] = useState(50);
    const [updateTopupData, setUpdateTopupData] = useState(false);
    const lastUpdated = useSelector((state) => state.creditAlerts.last_update);
    const updatedOrderList = useSelector((state) => state.creditAlerts.transaction_list);

    useEffect(() => {
        if (!isEmpty(updatedOrderList)) {
            setTransactionData(updatedOrderList.transactions.data);
            setTotalRedcords(updatedOrderList?.transactions?.total);
            setAvailableCredits(updatedOrderList?.total_service_credit);
            setAlertAmount(updatedOrderList?.low_infrastructure_credit_alert_amount);
            setLoader(false);
        }
    }, [updatedOrderList]);

    // ** Pagination config
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
    const onChangePage = useCallback(
        (pageIndex) => setPagination((pagination) => ({ ...pagination, pageIndex })),
        [setPagination]
    );

    // ** Sorting config
    const [sortingColumns, setSortingColumns] = useState([]);
    const onSort = useCallback(
        (sortingColumns) => {
            setSortingColumns(sortingColumns);
        },
        [setSortingColumns]
    );

    const [totalRecords, setTotalRedcords] = useState(0);

    // fetch transactions list;
    useEffect(() => {
        setLoader(true);
        dispatch(fetchCreditTransactions(pagination, typeOfTransaction, startDate, endDate)).then((res) => {
            setTransactionData(res?.transactions?.data);
            setTotalRedcords(res?.transactions?.total);
            setAvailableCredits(res?.total_service_credit);
            setAlertAmount(res?.low_infrastructure_credit_alert_amount);
            setLoader(false);
        });
    }, [startDate, endDate, pagination, lastUpdated]);

    useEffect(() => {
        if (updateTopupData) {
            setLoader(true);
            dispatch(fetchCreditTransactions(pagination, typeOfTransaction, startDate, endDate)).then((res) => {
                setTransactionData(res?.transactions?.data);
                setTotalRedcords(res?.transactions?.total);
                setAvailableCredits(res?.total_service_credit);
                setAlertAmount(res?.low_infrastructure_credit_alert_amount);
                setLoader(false);
            });
        }
    }, [updateTopupData]);

    const [rawData, setRawData] = useState([]);

    useEffect(() => {
        let raw_data = [];

        if (transactionData?.length > 0) {
            transactionData.forEach((data) =>
                raw_data.push({
                    reference_id: <>{<EuiText>{data.reference_id}</EuiText>}</>,
                    type: <>{<EuiText>{data.type}</EuiText>}</>,
                    service: (
                        <>
                            {
                                <EuiText>
                                    {data.third_party_api_name
                                        ? data.third_party_api_name
                                        : 'sms credit and google geocode api'}
                                </EuiText>
                            }
                        </>
                    ),
                    status: <>{<EuiText>{data.status}</EuiText>}</>,
                    amount: <>{<EuiText>{data.amount}</EuiText>}</>,
                    date: <>{<EuiText> {data.date}</EuiText>}</>,
                })
            );
        }

        setRawData(raw_data);
    }, [transactionData]);

    const PAGE_COUNT = transactionData?.length > 0 && Math.ceil(totalRecords / pagination.pageSize);

    const onChangeRowSize = useCallback(
        (row_size) => {
            setPagination({
                ...pagination,
                pageSize: row_size,
            });
            onChangePage();
        },
        [onChangePage, pagination]
    );

    const columns = [
        {
            id: 'reference_id',
            displayAsText: 'Reference ID',
            defaultSortDirection: 'asc',
            // isSortable: false,
        },
        {
            id: 'type',
            defaultSortDirection: 'asc',
            initialWidth: 125,
        },

        {
            id: 'service',
            isSortable: false,
        },
        {
            id: 'status',
            defaultSortDirection: 'asc',
            isResizable: false,
            initialWidth: 125,
        },
        {
            id: 'amount',
            initialWidth: 125,
        },
        {
            id: 'date',
            actions: {
                additional: additionalDateActions({
                    setStartDate,
                    startDate,
                    setEndDate,
                    endDate,
                    setTransactionData,
                    pagination,
                    typeOfTransaction,
                    dispatch,
                    setPagination,
                }),
            },
        },
    ];

    const [visibleColumns, setVisibleColumns] = useState(() => columns.map(({ id }) => id)); // initialize to the full set of columns

    const renderCellValue = useMemo(() => {
        return ({ rowIndex, columnId, setCellProps }) => {
            return rawData.hasOwnProperty(rowIndex) ? rawData[rowIndex][columnId] : null;
        };
    }, [rawData]);

    return (
        <>
            <EuiFlexGroup justifyContent='spaceBetween' style={{ width: '100%' }} alignItems='center'>
                <EuiFlexItem>
                    <EuiText
                        size='s'
                        style={{ marginBottom: '26px' }}
                        children={
                            <h2>
                                <b> {props.title}</b>
                            </h2>
                        }
                    />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <div>
                        <AddCredits
                            setUpdateTopupData={setUpdateTopupData}
                            setLoader={setLoader}
                            setPaymentLoader={props.setPaymentLoader}
                        />
                        <CreditsSettings
                            alertType='infrastructure'
                            alertAmount={alertAmount}
                            setUpdateTopupData={setUpdateTopupData}
                        />
                    </div>
                </EuiFlexItem>
            </EuiFlexGroup>
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'end',
                    marginBottom: '16px',
                }}
            >
                <div
                    justifyContent='spaceBetween'
                    grow={false}
                    style={{
                        width: '28%',
                        padding: '32px 26px',
                        boxShadow:
                            '0 0 12px -1px rgb(65 78 101 / 20%), 0 0 4px -1px rgb(65 78 101 / 20%), 0 0 2px 0 rgb(65 78 101 / 20%)',
                    }}
                    gutterSize='xs'
                    direction='row'
                    alignItems='center'
                >
                    <EuiFlexItem>
                        {loader ? (
                            <EuiLoadingContent lines={2} />
                        ) : (
                            <EuiFlexGroup direction='column'>
                                <EuiText size='s' children={<p>Available Credits</p>} />
                                <EuiTitle size='l' children={<h1>{availableCredits}</h1>} />
                                <EuiText size='xs' children={<span>These Credits are not real time.</span>} />
                            </EuiFlexGroup>
                        )}
                    </EuiFlexItem>
                </div>
                <EuiFlexItem grow={false}>
                    <CustomDatePicker
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        isLoading={loader}
                    />
                </EuiFlexItem>
            </div>
            <EuiFlexItem>
                <DataContext.Provider value={rawData}>
                    <EuiDataGrid
                        aria-label='Customer List Datagrid'
                        columns={columns}
                        columnVisibility={{ visibleColumns, setVisibleColumns }}
                        rowCount={rawData.length}
                        inMemory={{ level: 'sorting' }}
                        renderCellValue={renderCellValue}
                        sorting={{ columns: sortingColumns, onSort }}
                        height={rawData?.length > 0 && rawData?.length < 5 ? '40vh' : 'auto'}
                        width='100%'
                    />
                    <EuiFlexGroup justifyContent='spaceBetween' alignItems='center'>
                        <EuiFlexItem grow={false}>
                            <ContextMenu rowSize={pagination.pageSize} popOverItems={popOverItems(onChangeRowSize)} />
                        </EuiFlexItem>

                        <EuiFlexItem grow={false}>
                            <EuiPagination
                                aria-label='Custom pagination example'
                                pageCount={PAGE_COUNT}
                                activePage={pagination.pageIndex}
                                onPageClick={onChangePage}
                            />
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </DataContext.Provider>
            </EuiFlexItem>
        </>
    );
};

export default InfraCredits;
