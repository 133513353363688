import { useEffect } from 'react';

export const useSetHeaderFixed = () => {
    useEffect(() => {
        document.body.classList.add('euiBody--headerIsFixed--double');

        return () => {
            document.body.classList.remove('euiBody--headerIsFixed--double');
        };
    }, []);
};
