import * as types from '../types/item';

const initialState = {
    items: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.SET_ITEMS:
            return {
                ...state,
                items: action.payload.items,
            };
        case types.CLEAR_ITEMS:
            return {
                ...state,
                items: action.payload.items,
            };
        default:
            return state;
    }
};
