import { EuiButton } from '@elastic/eui';

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import AddonGroupCatalogueFlyout from '../../../../components/menuComponentsRefactorCopy/addOnGroup/AddonGroupCatalogueFlyout';
import { SET_ADDON_MAIN_FLYOUT } from '../../../../reduxStore/types/addon';

const AddonGroupAction = () => {
    const [state, setState] = useState({
        isOpen: false,
    });

    const dispatch = useDispatch();

    const handleAddonFlyout = () => {
        setState({ ...state, isOpen: !state.isOpen });
    };

    const closeFlyout = () => {
        setState({
            ...state,
            isOpen: false,
            selectedGroup: 0,
        });
        dispatch({
            type: SET_ADDON_MAIN_FLYOUT,
            payload: {
                addOnMainflyout: false,
            },
        });
    };

    return (
        <>
            {state.isOpen ? <AddonGroupCatalogueFlyout closeFlyout={closeFlyout} state={state} /> : null}

            <EuiButton onClick={handleAddonFlyout} iconType='indexEdit' aria-label='any'>
                Addon Groups
            </EuiButton>
        </>
    );
};

export default AddonGroupAction;
