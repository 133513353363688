import { EuiFlexItem, EuiText } from '@elastic/eui';
import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import ItemDetailsCollaspibleNav from './ItemDetailsCollaspibleNav';
import { ReactComponent as ItemImageIcon } from '../../../../../assets/img/svg/ItemImage.svg';
import LanguageBannerImage from '../../../../Banners/Components/LanguageBannerImage';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

const ItemImage = () => {
    const themeMode = useSelector((state) => state.theme.theme);

    const [isNavOpen, setIsNavOpen] = useState(false);
    const { control, setValue, watch } = useFormContext({});
    const [selectedImages, setSelectedImages] = useState({});
    const itemImage = useWatch({
        control,
        name: 'itemImage',
    });

    useEffect(() => {
        if (!isEmpty(itemImage)) {
            setSelectedImages(itemImage);
        } else {
            setSelectedImages({});
        }
    }, [itemImage]);

    return (
        <>
            <ItemDetailsCollaspibleNav
                cardTitle='Image Selection'
                isNavOpen={isNavOpen}
                cardDescription={<EuiText size='xs'>Select good quality image of this item</EuiText>}
                setIsNavOpen={setIsNavOpen}
                toggleText='Edit Basic Details'
                icon={<ItemImageIcon fill={themeMode === 'light' ? 'black' : 'white'} />}
            >
                <EuiFlexItem
                    style={
                        !isEmpty(selectedImages)
                            ? { alignItems: 'center', padding: '25px', paddingTop: 0 }
                            : { padding: 0, alignItems: 'center' }
                    }
                >
                    <LanguageBannerImage
                        buttonLabel={'Select an image'}
                        selectedImgButtonLabel={'Change item image'}
                        imageType={'items'}
                        selectedImages={selectedImages}
                        withRemove={true}
                        name='itemImage'
                        defaultImageValue={watch(`itemImage`)}
                        setValue={setValue}
                        // label='Brand Logo'
                        aspectRatio={{
                            width: 1,
                            height: 1,
                        }}
                        setSelectedImages={setSelectedImages}
                    />
                </EuiFlexItem>
            </ItemDetailsCollaspibleNav>
        </>
    );
};

export default React.memo(ItemImage);
