import {
    EuiButton,
    EuiFlyout,
    EuiFlyoutBody,
    EuiFlyoutFooter,
    EuiFlyoutHeader,
    EuiSpacer,
    EuiStat,
    EuiTitle,
    htmlIdGenerator,
} from '@elastic/eui';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import API from '../../../api/axios/API';
import { SET_OUTLET_BANNER_LOADER } from '../../../reduxStore/types/banner';
import { toastsErrorMessage, toastSuccessMessage } from '../../../utils/toasts';
import BannerLinkOutletList from './BannerLinkOutletList';
import { fetchGlobalBanners } from './fetchGlobalBanners';

const BannerLinkFlyout = ({ toggleFlyout, bannerLinkFlyout }) => {
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);
    const [outletFilteredBanners, setOutletFilteredBanners] = useState([]);
    const [bannerLinkStatus, setBannerLinkStatus] = useState({
        link: [],
        unlink: [],
    });
    const [selectedBanner, setSelectedBanner] = useState({});
    const [loadingState, setLoadingState] = useState(false);
    const banners = useSelector((state) => state.banner.banners);
    const currentTab = useSelector((state) => state.banner.bannerType);
    const [outletBannerList, setOutletBannerList] = useState(null);
    const dispatch = useDispatch();
    const outletList = useSelector((state) => state.outlet.outletList);
    const bannerOutletLinkedCount = outletList?.filter((outlet) =>
        selectedBanner?.outlet?.some((bannerOutlet) => bannerOutlet.outlet_id === outlet.outlet_data.outlet_id)
    )?.length;

    useEffect(() => {
        if (banners?.length) {
            let updatedSelectedBanner = {};
            for (const banner of banners) {
                if (bannerLinkFlyout?.banner?.id === banner.id) {
                    updatedSelectedBanner = {
                        ...banner,
                    };
                }
            }
            setSelectedBanner(updatedSelectedBanner);
        }

        return function cleanup() {
            setBannerLinkStatus({
                link: [],
                unlink: [],
            });
        };
    }, [bannerLinkFlyout?.banner?.id, banners]);

    useEffect(() => {
        // set filtered outlet to link/unlink
        if (outletBannerList?.length) {
            if (currentTab === 'secondary') {
                setOutletFilteredBanners([...outletBannerList.filter((banner) => banner.is_secondary)]);
            } else if (currentTab === 'tertiary') {
                setOutletFilteredBanners([...outletBannerList.filter((banner) => banner.is_tertiary)]);
            } else {
                setOutletFilteredBanners([
                    ...outletBannerList.filter((banner) => !banner.is_secondary && !banner.is_tertiary),
                ]);
            }
        }
    }, [currentTab, outletBannerList]);

    // method for update banners
    const updateBannerData = useCallback(
        async (outletID) => {
            dispatch({
                type: SET_OUTLET_BANNER_LOADER,
                payload: {
                    outletBannerLoader: { loader: true, error: false },
                },
            });
            let bannerList;
            try {
                bannerList = await API.get(`restaurants/:restaurantId/outlets/${outletID}/banners`);
                if (bannerList.success) {
                    setOutletBannerList(bannerList.banners);

                    dispatch({
                        type: SET_OUTLET_BANNER_LOADER,
                        payload: {
                            outletBannerLoader: { loader: false, error: false },
                        },
                    });
                }
            } catch (error) {
                dispatch({
                    type: SET_OUTLET_BANNER_LOADER,
                    payload: {
                        outletBannerLoader: { loader: false, error: true },
                    },
                });
            }
        },
        [dispatch]
    );

    useEffect(() => {
        if (selectedOutletId) {
            updateBannerData(selectedOutletId);
        }
    }, [selectedOutletId, updateBannerData]);

    const handleUnlink = useCallback(
        async (payload) => {
            setLoadingState(true);
            try {
                await API.patch(`restaurants/:restaurantId/banners/${selectedBanner.id}/un-link`, {
                    outlet_ids: payload,
                }).then((response) => {
                    if (response.success) {
                        setLoadingState(false);
                        // updateBannerData(outlet.outlet_data.outlet_id);
                        dispatch(fetchGlobalBanners());
                        toastSuccessMessage('Banner Un-Linked Successfully', dispatch);
                    }
                });
                // await updateBannerData(outlet);
            } catch (e) {
                setLoadingState(false);
                toastsErrorMessage('Opps, There was an error.', dispatch);
            }
        },
        [dispatch, selectedBanner.id]
    );

    const handleBannerLink = useCallback(
        async (payload) => {
            try {
                setLoadingState(true);
                await API.patch(`restaurants/:restaurantId/banners/${selectedBanner.id}/link`, {
                    outlet_ids: payload,
                }).then((response) => {
                    if (response.success) {
                        setLoadingState(false);
                        dispatch(fetchGlobalBanners());
                        toastSuccessMessage('Banner Linked Successfully', dispatch);
                    }
                });
            } catch (e) {
                setLoadingState(false);
                toastsErrorMessage('Opps, There was an error.', dispatch);
            }
        },
        [dispatch, selectedBanner.id]
    );

    const handleBannerLinkChanges = useCallback(async () => {
        if (bannerLinkStatus.link?.length) {
            handleBannerLink(bannerLinkStatus.link);
        }
        if (bannerLinkStatus.unlink?.length) {
            handleUnlink(bannerLinkStatus.unlink);
        }
    }, [bannerLinkStatus.link, bannerLinkStatus.unlink, handleBannerLink, handleUnlink]);

    const Header = useMemo(() => {
        return (
            <EuiFlyoutHeader hasBorder>
                <EuiTitle>
                    <h2 id={htmlIdGenerator()()}>Link banner to outlet</h2>
                </EuiTitle>
            </EuiFlyoutHeader>
        );
    }, []);

    const LinkStat = useMemo(() => {
        return (
            <>
                <EuiStat title={bannerOutletLinkedCount} description='Linked Outlets' />
                <EuiSpacer />
            </>
        );
    }, [bannerOutletLinkedCount]);

    return (
        <>
            {bannerLinkFlyout.display ? (
                <EuiFlyout onClose={toggleFlyout}>
                    {Header}
                    <EuiFlyoutBody>
                        {LinkStat}

                        {outletList?.length
                            ? outletList.map((outlet, idx) => {
                                  return (
                                      <BannerLinkOutletList
                                          banner={selectedBanner}
                                          outlet={outlet}
                                          key={idx.toString()}
                                          loadingState={loadingState}
                                          setBannerLinkStatus={setBannerLinkStatus}
                                          bannerLinkStatus={bannerLinkStatus}
                                      />
                                  );
                              })
                            : null}
                    </EuiFlyoutBody>
                    <EuiFlyoutFooter>
                        <EuiButton
                            fill
                            fullWidth
                            onClick={handleBannerLinkChanges}
                            disabled={!bannerLinkStatus.link.length && !bannerLinkStatus.unlink.length}
                        >
                            Save Changes
                        </EuiButton>
                    </EuiFlyoutFooter>
                </EuiFlyout>
            ) : null}
        </>
    );
};

export default React.memo(BannerLinkFlyout);
