import React from 'react';
import { EuiFormRow, EuiRadioGroup } from '@elastic/eui';
import { Controller } from 'react-hook-form';

const RadioField = (props) => {
    const { label, name, options, idSelected, control, fullWidth, defaultValue } = props;
    return (
        <Controller
            render={({}) => (
                <EuiFormRow fullWidth={fullWidth ? fullWidth : false}>
                    <EuiRadioGroup
                        options={options}
                        idSelected={idSelected}
                        onChange={props.onChange}
                        legend={{
                            children: <span> {label} </span>,
                        }}
                        defaultValue={defaultValue ? defaultValue : ''}
                    />
                </EuiFormRow>
            )}
            name={name}
            control={control}
            fullWidth={true}
            defaultValue={defaultValue ? defaultValue : ''}
        />
    );
};

export default RadioField;
