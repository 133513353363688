export const baseApiUrl = process.env.REACT_APP_BASE_URL;

export const firebaseConfig = {
    apiKey: process.env.REACT_APP_apiKey,
    projectId: process.env.REACT_APP_projectId,
    messagingSenderId: process.env.REACT_APP_messagingSenderId,
    appId: process.env.REACT_APP_appId,
};

// export const firebaseConfig = {
//     apiKey: 'AIzaSyANZwH0JE7oFDZJ151oopC19kLLVtjRkLQ',
//     // authDomain: 'fab-delivery-staging.firebaseapp.com',
//     // databaseURL: 'https://fab-delivery-staging.firebaseio.com',
//     projectId: 'fab-systems',
//     // storageBucket: 'fab-delivery-staging.appspot.com',
//     messagingSenderId: '393655120375',
//     appId: '1:393655120375:web:93b58483f3733a556f7ef2',
//     // measurementId: 'G-D3PWR4ZJ27',
// };
