import API from '../axios/API';

export const getOrderEstimationTime = (rider, orderId) => {
    return async () => {
        let response;

        try {
            response = API.get(`/restaurants/:restaurantId/${rider}/get-estimation?order_id=${orderId}`);
        } catch (error) {
            response = error;
        }

        return response;
    };
};
