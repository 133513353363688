import moment from 'moment';
import { getNextWorkingDayTime } from '../../../helper/getNextWorkingDayTime';

export const toggleButtonsCompressed = [
    {
        id: `turned_off`,
        label: '',
    },
    {
        id: `partial_state`,
        label: '',
    },
    {
        id: `turned_on`,
        label: '',
    },
];

export const dayName = (dayIndex) => {
    let dayName = null;
    if (dayIndex == 1) {
        dayName = 'Monday';
    } else if (dayIndex == 2) {
        dayName = 'Tuesday';
    } else if (dayIndex == 3) {
        dayName = 'Wednesday';
    } else if (dayIndex == 4) {
        dayName = ' Thursday';
    } else if (dayIndex == 5) {
        dayName = 'Friday';
    } else if (dayIndex == 6) {
        dayName = 'Saturday';
    } else if (dayIndex == 7) {
        dayName = 'Sunday';
    }
    return dayName;
};

export const nextAvailableTimeCheck = (radioIdSelected, startDate, outletSelectedDetails) => {
    if (radioIdSelected === 'category_item_availability_0') {
        return null;
    } else if (radioIdSelected === 'category_item_availability_1') {
        return startDate ? moment(startDate._d).format('YYYY-MM-DD HH:mm:ss') : null;
    } else if (radioIdSelected === 'category_item_availability_2') {
        return moment().add(2, 'hours').format('YYYY-MM-DD HH:mm:ss');
    } else if (radioIdSelected === 'category_item_availability_3') {
        return moment().add(4, 'hours').format('YYYY-MM-DD HH:mm:ss');
    } else if (radioIdSelected === 'category_item_availability_4') {
        return getNextWorkingDayTime(outletSelectedDetails.settings.schedules);
    }
    return null;
};
