import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    EuiFlexItem,
    EuiForm,
    EuiFormRow,
    EuiSpacer,
    EuiButton,
    EuiSuperSelect,
    EuiLoadingContent,
    EuiFlexGroup,
} from '@elastic/eui';
import { Controller, FormProvider } from 'react-hook-form';
import * as types from '../../../../../reduxStore/types/menu';
import { isEmpty } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import CategoryVisibility from './CategoryVisibility';
import { FormGroupDescription } from '../../../../../components/formGroupDescription/formGroupDescription';
import DeleteModal from '../../../../../components/menuComponentsRefactorCopy/menuEditorTab/deleteModal';
import SwitchField from '../../../../../components/Form/SwitchField';
import API from '../../../../../api/axios/API';
import CategoryImage from './CategoryImage';
import { onDeleteCategory, positionOptions } from './Utils/utils';
import CategoryBasicDetails from './CategoryBasicDetails';
import { toastsErrorMessage, toastSuccessMessage } from '../../../../../utils/toasts';
import { fetchCategoryData } from './Api/fetchCategoryData';
import { MainLayout } from '../../../../../components/layout/mainLayout';
import useFormActionsHandler from '../../../../../hooks/useFormActionsHandler';
import CategoryTimings from '../ItemDetails/CategoryTimings';

const CategoryCreateEditPage = () => {
    const editCategory = useSelector((state) => state.menu.editCategory);
    const addCategory = useSelector((state) => state.menu.addCategory);
    const availableLanguages = useSelector((state) => state.language.availableLanguages);
    const showDeleteConfirmationModal = useSelector((state) => state.menu.showDeleteConfirmationModal);
    const selectedCategoryId = useParams()?.categoryId;
    const orderingModes = useSelector((state) => state.menuReducer.restaurantOrderingModes);
    const dispatch = useDispatch();
    const history = useHistory();

    const [isCategoryLoading, setIsCategoryLoading] = useState(false);
    const [defaultValues, setDefaultValues] = useState({});
    const [stateUpdate, setStateUpdate] = useState(false);

    const loadingArray = Array.from({ length: 5 }, () => Math.floor(Math.random() * (5 - 2 + 1)) + 2);

    useEffect(() => {
        if (selectedCategoryId) {
            setIsCategoryLoading(true);
            dispatch(fetchCategoryData(selectedCategoryId)).finally(() => setIsCategoryLoading(false));
        }

        return () => {
            dispatch({
                type: types.SET_ADD_CATEGORY_ID,
                payload: {
                    addCategory: null,
                },
            });
            dispatch({
                type: types.EDIT_CATEGORY,
                payload: {
                    editCategory: {},
                },
            });
        };
    }, [selectedCategoryId, dispatch]);

    function convertTimeFormat(timeString) {
        if (!timeString) {
            return ''; // Handle undefined or empty input
        }

        // Check if the timeString is already in the "H:MM" format
        const timePattern = /^\d{1,2}:\d{2}$/;
        if (timePattern.test(timeString)) {
            return timeString; // Return the string as is if it's already formatted
        }

        const [hours, minutes] = timeString.split(':');

        // Ensure minutes always have two digits
        const formattedMinutes = (minutes || '00').padStart(2, '0');

        // Format the result as "H:MM"
        return `${hours}:${formattedMinutes}`;
    }
    const updateFormValues = useCallback(
        (data) => {
            console.log('updateFormValues <====>', data);
            if (!isEmpty(data)) {
                let defaultValueObject = {};

                data[`timer_for_category`] = 'same_day_of_the_week';
                for (const element of data?.ordering_modes) {
                    defaultValueObject[`mode_${element.restaurant_ordering_mode_id}`] = true;
                }
                let isAllSameDays = data?.ordering_modes.every(
                    (item) => item?.time_slot_setting == 'same_time_slot_for_all_days'
                );
                let isAllDaysOfWeek = data?.ordering_modes.every((item) => item?.time_slot_setting == 'no_time_slots');
                let isDifferentDaysOfWeek = data?.ordering_modes.every(
                    (item) => item?.time_slot_setting == 'different_time_slots_for_different_days'
                );
                let isDifferentTimeSlotDifferent = [];
                const defaultSchedules = data?.ordering_modes[0]?.timeslots;
                for (let index = 0; index < data.ordering_modes.length; index++) {
                    const element = data.ordering_modes[index];
                    const sameSchedules = element?.timeslots.filter(
                        (defaultSchedule) =>
                            !defaultSchedules.some(
                                (modeSchedules) =>
                                    modeSchedules.start_time === defaultSchedule.start_time &&
                                    modeSchedules.end_time === defaultSchedule.end_time
                            )
                    );
                    if (!sameSchedules?.length) {
                        isDifferentTimeSlotDifferent.push('disabled');
                    } else {
                        isDifferentTimeSlotDifferent.push('enabled');
                    }
                }
                let isDifferentTimeSlot =
                    isDifferentTimeSlotDifferent?.find((status) => status === 'enabled') == 'enabled' ? true : false;
                data[`is_enabled_same_time_slot_details_for_all_ordering_modes`] = isDifferentTimeSlot;

                if (isAllSameDays && !isDifferentTimeSlot) {
                    data[`timer_for_category`] = 'same_time_for_all_days';
                    data['time'] = data?.ordering_modes[0]?.timeslots
                        .filter((timeslot) => timeslot.day_of_week === 1)
                        .map((timeslot) => ({
                            ...timeslot,
                            start_time: convertTimeFormat(timeslot.start_time),
                            end_time: convertTimeFormat(timeslot.end_time),
                        }));
                } else if (isAllDaysOfWeek && !isDifferentTimeSlot) {
                    data[`timer_for_category`] = 'same_day_of_the_week';
                } else if (isDifferentDaysOfWeek && !isDifferentTimeSlot) {
                    data[`timer_for_category`] = 'different_time_for_different_days';
                    let days = {};
                    data.ordering_modes[0].timeslots.map((item) => {
                        if (item.day_of_week == 1) {
                            days = { ...days, monday: [{ ...item, start_time: convertTimeFormat(item.start_time) }] };
                        }
                        if (item.day_of_week == 2) {
                            days = { ...days, tuesday: [{ ...item, start_time: convertTimeFormat(item.start_time) }] };
                        }
                        if (item.day_of_week == 3) {
                            days = {
                                ...days,
                                wednesday: [{ ...item, start_time: convertTimeFormat(item.start_time) }],
                            };
                        }
                        if (item.day_of_week == 4) {
                            days = { ...days, thursday: [{ ...item, start_time: convertTimeFormat(item.start_time) }] };
                        }
                        if (item.day_of_week == 5) {
                            days = { ...days, friday: [{ ...item, start_time: convertTimeFormat(item.start_time) }] };
                        }
                        if (item.day_of_week == 6) {
                            days = { ...days, saturday: [{ ...item, start_time: convertTimeFormat(item.start_time) }] };
                        }
                        if (item.day_of_week == 7) {
                            days = { ...days, sunday: [{ ...item, start_time: convertTimeFormat(item.start_time) }] };
                        }
                    });
                    data[`week`] = days;
                } else {
                    // data['is_enabled_same_time_slot_details_for_all_ordering_modes'] = false;
                    data?.ordering_modes.map((mode) => {
                        if (mode.time_slot_setting == 'no_time_slots') {
                            data[
                                `timer_for_category_${mode.restaurant_ordering_mode_id}_${mode.restaurant_ordering_mode_name}`
                            ] = `same_day_of_the_week_${mode.restaurant_ordering_mode_id}`;
                        }
                        if (mode?.timeslots?.length && mode.time_slot_setting == 'same_time_slot_for_all_days') {
                            data[
                                `timer_for_category_${mode.restaurant_ordering_mode_id}_${mode.restaurant_ordering_mode_name}`
                            ] = `same_time_for_all_days_${mode.restaurant_ordering_mode_id}`;
                            data[`time_${mode.restaurant_ordering_mode_id}_${mode.restaurant_ordering_mode_name}`] =
                                mode?.timeslots
                                    .filter((timeslot) => timeslot.day_of_week === 1)
                                    .map((timeslot) => ({
                                        ...timeslot,
                                        start_time: convertTimeFormat(timeslot.start_time),
                                        end_time: convertTimeFormat(timeslot.end_time),
                                    }));
                        }
                        if (
                            mode?.timeslots?.length &&
                            mode.time_slot_setting == 'different_time_slots_for_different_days'
                        ) {
                            data[
                                `timer_for_category_${mode.restaurant_ordering_mode_id}_${mode.restaurant_ordering_mode_name}`
                            ] = `different_time_for_different_days_${mode.restaurant_ordering_mode_id}`;
                            let days = {};
                            mode.timeslots.map((item) => {
                                if (item.day_of_week == 1) {
                                    days = {
                                        ...days,
                                        [`monday_${mode.restaurant_ordering_mode_id}_${mode.restaurant_ordering_mode_name}`]:
                                            [
                                                {
                                                    ...item,
                                                    start_time: convertTimeFormat(item.start_time),
                                                    end_time: convertTimeFormat(item.end_time),
                                                },
                                            ],
                                    };
                                }
                                if (item.day_of_week == 2) {
                                    days = {
                                        ...days,
                                        [`tuesday_${mode.restaurant_ordering_mode_id}_${mode.restaurant_ordering_mode_name}`]:
                                            [
                                                {
                                                    ...item,
                                                    start_time: convertTimeFormat(item.start_time),
                                                    end_time: convertTimeFormat(item.end_time),
                                                },
                                            ],
                                    };
                                }
                                if (item.day_of_week == 3) {
                                    days = {
                                        ...days,
                                        [`wednesday_${mode.restaurant_ordering_mode_id}_${mode.restaurant_ordering_mode_name}`]:
                                            [
                                                {
                                                    ...item,
                                                    start_time: convertTimeFormat(item.start_time),
                                                    end_time: convertTimeFormat(item.end_time),
                                                },
                                            ],
                                    };
                                }
                                if (item.day_of_week == 4) {
                                    days = {
                                        ...days,
                                        [`thursday_${mode.restaurant_ordering_mode_id}_${mode.restaurant_ordering_mode_name}`]:
                                            [
                                                {
                                                    ...item,
                                                    start_time: convertTimeFormat(item.start_time),
                                                    end_time: convertTimeFormat(item.end_time),
                                                },
                                            ],
                                    };
                                }
                                if (item.day_of_week == 5) {
                                    days = {
                                        ...days,
                                        [`friday_${mode.restaurant_ordering_mode_id}_${mode.restaurant_ordering_mode_name}`]:
                                            [
                                                {
                                                    ...item,
                                                    start_time: convertTimeFormat(item.start_time),
                                                    end_time: convertTimeFormat(item.end_time),
                                                },
                                            ],
                                    };
                                }
                                if (item.day_of_week == 6) {
                                    days = {
                                        ...days,
                                        [`saturday_${mode.restaurant_ordering_mode_id}_${mode.restaurant_ordering_mode_name}`]:
                                            [
                                                {
                                                    ...item,
                                                    start_time: convertTimeFormat(item.start_time),
                                                    end_time: convertTimeFormat(item.end_time),
                                                },
                                            ],
                                    };
                                }
                                if (item.day_of_week == 7) {
                                    days = {
                                        ...days,
                                        [`sunday_${mode.restaurant_ordering_mode_id}_${mode.restaurant_ordering_mode_name}`]:
                                            [
                                                {
                                                    ...item,
                                                    start_time: convertTimeFormat(item.start_time),
                                                    end_time: convertTimeFormat(item.end_time),
                                                },
                                            ],
                                    };
                                }
                            });
                            data['week'] = { ...(data['week'] ?? []), ...days };
                        }
                    });
                }

                return {
                    ...data,
                    hide_category_tile_details: data?.hide_category_tile_details,
                    upload_image: {
                        id: data?.category_image_url?.id,
                        src: data?.category_image_url?.image_url,
                        image_url: data?.category_image_url?.image_url,
                        result: null,
                    },
                    editCategory: {
                        theme: data?.theme,
                        ordering_modes: data?.ordering_modes,
                        category_image_url: data?.category_image_url,
                        ...defaultValueObject,
                    },
                };
            } else {
                let obj = {
                    hidden: '',
                    hide_category_tile_details: '',
                    internal_name: '',
                    size: '',
                    upload_image: { id: '', src: '', image_url: '', result: null },
                    tile_details_position: '',
                    gradient_position: '',
                    timer_for_category: 'same_day_of_the_week',
                    is_enabled_same_price_details_for_all_ordering_modes: false,
                    is_enabled_same_time_slot_details_for_all_ordering_modes: false,
                    time: [],
                    week: {
                        sunday: [],
                        monday: [],
                        tuesday: [],
                        wednesday: [],
                        thursday: [],
                        friday: [],
                        saturday: [],
                    },
                    ordering_modes: orderingModes,
                };
                availableLanguages.forEach((item) => {
                    obj['description' + item.language_id] = '';
                    obj['title' + item.language_id] = '';
                    obj['size' + item.language_id] = '';
                    obj['serves' + item.language_id] = '';
                });
                orderingModes.forEach((item) => {
                    obj[`timer_for_category_${item.id}_${item.display_name}`] = `same_day_of_the_week_${item.id}`;
                });
                return obj;
            }
        },
        [availableLanguages]
    );

    useEffect(() => {
        setDefaultValues(updateFormValues(editCategory));
    }, [editCategory]);

    let updatedOrderingModeData = (data) => {
        let timeSlotSettings;
        let orderModeData;
        if (data.is_enabled_same_time_slot_details_for_all_ordering_modes) {
            orderModeData = data.editCategory?.ordering_modes.map((item) => {
                if (
                    data[
                        `timer_for_category_${item.restaurant_ordering_mode_id}_${item.restaurant_ordering_mode_name}`
                    ] == `same_day_of_the_week_${item.restaurant_ordering_mode_id}`
                ) {
                    delete item?.timeslots;
                    return {
                        ...item,
                        time_slot_setting: 'no_time_slots',
                    };
                }
                if (
                    data[
                        `timer_for_category_${item.restaurant_ordering_mode_id}_${item.restaurant_ordering_mode_name}`
                    ] == `same_time_for_all_days_${item.restaurant_ordering_mode_id}`
                ) {
                    delete item.timeslots;
                    return {
                        ...item,
                        time_slot_setting: 'same_time_slot_for_all_days',
                        schedules: [
                            {
                                days_of_week: [1, 2, 3, 4, 5, 6, 7],
                                time_slots: [
                                    ...data[
                                        `time_${item.restaurant_ordering_mode_id}_${item.restaurant_ordering_mode_name}`
                                    ],
                                ],
                            },
                        ],
                    };
                }
                if (
                    data[
                        `timer_for_category_${item.restaurant_ordering_mode_id}_${item.restaurant_ordering_mode_name}`
                    ] == `different_time_for_different_days_${item.restaurant_ordering_mode_id}`
                ) {
                    let timeSlot = [];
                    if (
                        data.week[`sunday_${item.restaurant_ordering_mode_id}_${item.restaurant_ordering_mode_name}`]
                            ?.length
                    ) {
                        timeSlot = [
                            ...timeSlot,

                            {
                                days_of_week: [7],
                                time_slots: [
                                    ...data.week[
                                        `sunday_${item.restaurant_ordering_mode_id}_${item.restaurant_ordering_mode_name}`
                                    ],
                                ],
                            },
                        ];
                    }
                    if (
                        data.week[`monday_${item.restaurant_ordering_mode_id}_${item.restaurant_ordering_mode_name}`]
                            ?.length
                    ) {
                        timeSlot = [
                            ...timeSlot,

                            {
                                days_of_week: [1],
                                time_slots: [
                                    ...data.week[
                                        `monday_${item.restaurant_ordering_mode_id}_${item.restaurant_ordering_mode_name}`
                                    ],
                                ],
                            },
                        ];
                    }
                    if (
                        data.week[`tuesday_${item.restaurant_ordering_mode_id}_${item.restaurant_ordering_mode_name}`]
                            ?.length
                    ) {
                        timeSlot = [
                            ...timeSlot,

                            {
                                days_of_week: [2],
                                time_slots: [
                                    ...data.week[
                                        `tuesday_${item.restaurant_ordering_mode_id}_${item.restaurant_ordering_mode_name}`
                                    ],
                                ],
                            },
                        ];
                    }
                    if (
                        data.week[`wednesday_${item.restaurant_ordering_mode_id}_${item.restaurant_ordering_mode_name}`]
                            ?.length
                    ) {
                        timeSlot = [
                            ...timeSlot,

                            {
                                days_of_week: [3],
                                time_slots: [
                                    ...data.week[
                                        `wednesday_${item.restaurant_ordering_mode_id}_${item.restaurant_ordering_mode_name}`
                                    ],
                                ],
                            },
                        ];
                    }
                    if (
                        data.week[`thursday_${item.restaurant_ordering_mode_id}_${item.restaurant_ordering_mode_name}`]
                            ?.length
                    ) {
                        timeSlot = [
                            ...timeSlot,

                            {
                                days_of_week: [4],
                                time_slots: [
                                    ...data.week[
                                        `thursday_${item.restaurant_ordering_mode_id}_${item.restaurant_ordering_mode_name}`
                                    ],
                                ],
                            },
                        ];
                    }
                    if (
                        data.week[`friday_${item.restaurant_ordering_mode_id}_${item.restaurant_ordering_mode_name}`]
                            ?.length
                    ) {
                        timeSlot = [
                            ...timeSlot,

                            {
                                days_of_week: [5],
                                time_slots: [
                                    ...data.week[
                                        `friday_${item.restaurant_ordering_mode_id}_${item.restaurant_ordering_mode_name}`
                                    ],
                                ],
                            },
                        ];
                    }
                    if (
                        data.week[`saturday_${item.restaurant_ordering_mode_id}_${item.restaurant_ordering_mode_name}`]
                            ?.length
                    ) {
                        timeSlot = [
                            ...timeSlot,

                            {
                                days_of_week: [6],
                                time_slots: [
                                    ...data.week[
                                        `saturday_${item.restaurant_ordering_mode_id}_${item.restaurant_ordering_mode_name}`
                                    ],
                                ],
                            },
                        ];
                    }
                    delete item.timeslots;
                    return {
                        ...item,
                        time_slot_setting: 'different_time_slots_for_different_days',
                        schedules: timeSlot?.map((timeslot) => {
                            return {
                                ...timeslot,
                                time_slots: timeslot.time_slots?.map((slot) => ({
                                    ...slot,
                                    start_time: convertTimeFormat(slot.start_time),
                                    end_time: convertTimeFormat(slot.end_time),
                                })),
                            };
                        }),
                    };
                }
            });
        } else {
            if (data[`timer_for_category`] == `same_day_of_the_week`) {
                timeSlotSettings = 'no_time_slots';
                orderModeData = data.editCategory?.ordering_modes.map((item) => {
                    delete item?.timeslots;
                    return { ...item, time_slot_setting: timeSlotSettings };
                });
            }
            if (data[`timer_for_category`] == `same_time_for_all_days`) {
                let schedules = [
                    {
                        days_of_week: [1, 2, 3, 4, 5, 6, 7],
                        time_slots: [...data[`time`]],
                    },
                ];
                timeSlotSettings = 'same_time_slot_for_all_days';
                orderModeData = data.editCategory?.ordering_modes.map((item) => {
                    delete item.timeslots;
                    return { ...item, schedules, time_slot_setting: timeSlotSettings };
                });
            }
            if (data[`timer_for_category`] == `different_time_for_different_days`) {
                let timeSlot = [];
                if (data.week[`sunday`].length) {
                    timeSlot = [
                        ...timeSlot,
                        {
                            days_of_week: [7],
                            time_slots: [...data.week[`sunday`]],
                        },
                    ];
                }
                if (data.week[`monday`].length) {
                    timeSlot = [
                        ...timeSlot,

                        {
                            days_of_week: [1],
                            time_slots: [...data.week[`monday`]],
                        },
                    ];
                }
                if (data.week[`tuesday`].length) {
                    timeSlot = [
                        ...timeSlot,

                        {
                            days_of_week: [2],
                            time_slots: [...data.week[`tuesday`]],
                        },
                    ];
                }
                if (data.week[`wednesday`].length) {
                    timeSlot = [
                        ...timeSlot,

                        {
                            days_of_week: [3],
                            time_slots: [...data.week[`wednesday`]],
                        },
                    ];
                }
                if (data.week[`thursday`].length) {
                    timeSlot = [
                        ...timeSlot,

                        {
                            days_of_week: [4],
                            time_slots: [...data.week[`thursday`]],
                        },
                    ];
                }
                if (data.week[`friday`].length) {
                    timeSlot = [
                        ...timeSlot,
                        {
                            days_of_week: [5],
                            time_slots: [...data.week[`friday`]],
                        },
                    ];
                }
                if (data.week[`saturday`].length) {
                    timeSlot = [
                        ...timeSlot,
                        {
                            days_of_week: [6],
                            time_slots: [...data.week[`saturday`]],
                        },
                    ];
                }
                timeSlotSettings = 'different_time_slots_for_different_days';
                orderModeData = data.editCategory?.ordering_modes.map((item) => {
                    return { ...item, schedules: timeSlot, time_slot_setting: timeSlotSettings };
                });
            }
        }
        return { timeSlotSettings, orderModeData };
    };

    const onFormSaveApi = useCallback(
        async (data) => {
            let titles = {};
            let descriptions = {};
            let sizes = {};
            let serves = {};

            availableLanguages.map((language) => {
                titles[language.language_id] = data[`title${language.language_id}`];
                descriptions[language.language_id] = data[`description${language.language_id}`];
                sizes[language.language_id] = data[`size${language.language_id}`];
                serves[language.language_id] = data[`serves${language.language_id}`];
                return null;
            });
            let translationObject = {
                title: titles,
                description: descriptions,
                size: sizes,
                serves: serves,
            };

            let response = null;
            if (!selectedCategoryId) {
                response = await handleCategoryAdditionAction(data, translationObject);
            } else {
                response = await handleCategoryEditAction(data, translationObject);
            }

            return response;
        },
        [availableLanguages, selectedCategoryId]
    );

    const { errors, control, watch, reset, setValue, isDirty } = useFormActionsHandler({
        onFormSaveApi,
        defaultValues,
    });

    const methods = {
        control,
        watch,
        reset,
        setValue,
        formState: { isDirty, errors },
    };

    const handleCategoryEditAction = async (data, translationObject) => {
        let res;
        let orderingModeWithTiming = updatedOrderingModeData(data);
        try {
            res = await API.put(`/restaurants/:restaurantId/categories/${data?.id ? data.id : editCategory.id}`, {
                category_image_id: data?.editCategory?.category_image_url
                    ? data.editCategory.category_image_url.id
                    : null,
                apply_mask: data?.apply_mask ? data.apply_mask : 0,
                category_menu_image_upload_id: data.menuImage ? data.menuImage.id : null,
                hidden: data?.hidden ? 1 : 0,
                hide_category_tile_details: data.hide_category_tile_details,
                dine_in: data.dine_in ? 1 : 0,
                takeaway: data.takeaway ? 1 : 0,
                delivery: data.delivery ? 1 : 0,
                internal_name: data.internal_name,
                translations: translationObject,
                parent_id: data.parent_id ? data.parent_id : null,
                theme: data.editCategory?.theme ? data.editCategory.theme : null,
                ordering_modes: orderingModeWithTiming?.orderModeData
                    ? orderingModeWithTiming.orderModeData
                    : data.editCategory?.ordering_modes
                    ? data.editCategory.ordering_modes
                    : [],
                delete_ordering_modes: data?.delete_ordering_modes ? data.delete_ordering_modes : [],
                tile_details_position: data.tile_details_position,
                gradient_position: data.gradient_position,
            });
        } catch (error) {
            res = error;
        }

        if (res.success) {
            dispatch({
                type: types.SET_CATEGORY_STATE,
                payload: {
                    categoryState: {},
                },
            });

            history.goBack();

            toastSuccessMessage('Category successfully edited', dispatch);
        } else {
            apiResponseErrors(res);
        }

        return res;
    };

    const handleCategoryAdditionAction = async (data, translationObject) => {
        let res;
        let orderingModeWithTiming = updatedOrderingModeData(data);
        try {
            res = await API.post(`/restaurants/:restaurantId/categories`, {
                category_image_id: data?.editCategory?.category_image_url
                    ? data.editCategory.category_image_url.id
                    : null,
                apply_mask: data?.apply_mask ? data.apply_mask : 0,
                category_menu_image_upload_id: data.menuImage ? data.menuImage.id : null,
                hidden: data?.hidden ? 1 : 0,
                hide_category_tile_details: data?.hide_category_tile_details ? data.hide_category_tile_details : 0,
                dine_in: data.dine_in ? 1 : 0,
                takeaway: data.takeaway ? 1 : 0,
                delivery: data.delivery ? 1 : 0,
                internal_name: data.internal_name,
                translations: translationObject,
                parent_id: addCategory,
                theme: data.editCategory?.theme ? data.editCategory.theme : null,
                ordering_modes: orderingModeWithTiming?.orderModeData
                    ? orderingModeWithTiming.orderModeData
                    : data.editCategory?.ordering_modes
                    ? data.editCategory.ordering_modes
                    : [],
                delete_ordering_modes: data?.delete_ordering_modes ? data.delete_ordering_modes : [],
                tile_details_position: data.tile_details_position,
                gradient_position: data.gradient_position,
                ...(orderingModeWithTiming?.timeSlotSettings && {
                    time_slot_setting: orderingModeWithTiming?.timeSlotSettings,
                }),
            });
        } catch (error) {
            res = error;
        }

        if (res.success) {
            dispatch({
                type: types.SET_CATEGORY_STATE,
                payload: {
                    categoryState: {},
                },
            });

            history.goBack();

            toastSuccessMessage('Category successfully added', dispatch);
        } else {
            apiResponseErrors(res);
        }

        return res;
    };

    const apiResponseErrors = useCallback(
        (res) => {
            if (!isEmpty(res?.errors?.category_image_id)) {
                toastsErrorMessage('Please Select Image', dispatch);
            } else if (!isEmpty(res?.errors?.internal_name)) {
                toastsErrorMessage(res.errors.internal_name, dispatch);
            } else {
                toastsErrorMessage('Failed to edit category', dispatch);
            }
        },
        [dispatch]
    );

    return (
        <>
            <MainLayout title='Category' historyBack={true}>
                {showDeleteConfirmationModal ? <DeleteModal /> : null}

                {isCategoryLoading ? (
                    <>
                        {loadingArray.map((loadingLine, index) => {
                            return (
                                <React.Fragment key={index.toString() + loadingLine.toString()}>
                                    <EuiFlexGroup>
                                        <EuiFlexItem grow={4}>
                                            <EuiLoadingContent lines={2} />
                                        </EuiFlexItem>
                                        <EuiFlexItem grow={6}>
                                            <EuiLoadingContent lines={loadingLine} />
                                        </EuiFlexItem>
                                    </EuiFlexGroup>
                                    <EuiSpacer />
                                </React.Fragment>
                            );
                        })}
                    </>
                ) : (
                    <>
                        {selectedCategoryId ? (
                            <EuiFlexItem style={{ alignItems: 'flex-end' }}>
                                <EuiButton
                                    onClick={() => onDeleteCategory(dispatch, selectedCategoryId)}
                                    style={{ width: '200px' }}
                                    size='s'
                                    iconType='trash'
                                    fill
                                    color='danger'
                                >
                                    Delete
                                </EuiButton>
                            </EuiFlexItem>
                        ) : null}
                        <FormProvider {...methods}>
                            <EuiForm component='form'>
                                <CategoryImage />

                                <CategoryBasicDetails />

                                {!addCategory ? (
                                    <>
                                        <FormGroupDescription title='Tile Details Position'>
                                            <Controller
                                                render={({ field }) => {
                                                    return (
                                                        <EuiSuperSelect
                                                            options={positionOptions}
                                                            valueOfSelected={field.value}
                                                            onChange={(value) => {
                                                                setValue('tile_details_position', value, {
                                                                    shouldDirty: true,
                                                                });
                                                                setValue('gradient_position', value, {
                                                                    shouldDirty: true,
                                                                });
                                                            }}
                                                        />
                                                    );
                                                }}
                                                control={control}
                                                name='tile_details_position'
                                            />
                                        </FormGroupDescription>
                                        <FormGroupDescription title='Gradient Position'>
                                            <Controller
                                                render={({ field }) => {
                                                    return (
                                                        <EuiSuperSelect
                                                            options={positionOptions}
                                                            valueOfSelected={field.value}
                                                            onChange={field.onChange}
                                                            disabled
                                                        />
                                                    );
                                                }}
                                                control={control}
                                                name='gradient_position'
                                            />
                                        </FormGroupDescription>

                                        <FormGroupDescription title='Theme'>
                                            <EuiFlexItem>
                                                <Controller
                                                    render={({ field }) => {
                                                        return (
                                                            <EuiFormRow
                                                                label={'Select Theme'}
                                                                isInvalid={errors['theme']}
                                                                error={
                                                                    errors['theme']
                                                                        ? errors['Select Theme'].message
                                                                        : ''
                                                                }
                                                                fullWidth={true}
                                                            >
                                                                <EuiSuperSelect
                                                                    options={[
                                                                        {
                                                                            value: 'full_size_image',
                                                                            inputDisplay: 'Full Size Image',
                                                                        },
                                                                        {
                                                                            value: 'square_image',
                                                                            inputDisplay: 'Square Image',
                                                                        },
                                                                    ]}
                                                                    valueOfSelected={field.value}
                                                                    onChange={field.onChange}
                                                                />
                                                            </EuiFormRow>
                                                        );
                                                    }}
                                                    name={'editCategory.theme'}
                                                    control={control}
                                                    fullWidth
                                                />
                                            </EuiFlexItem>
                                            {<EuiSpacer />}
                                        </FormGroupDescription>

                                        <FormGroupDescription title='Category Visibility'>
                                            <CategoryVisibility control={control} setValue={setValue} />
                                        </FormGroupDescription>

                                        <FormGroupDescription title='Category Timings'>
                                            <CategoryTimings stateUpdate={stateUpdate} />
                                        </FormGroupDescription>
                                    </>
                                ) : (
                                    <FormGroupDescription title='Category Visibility'>
                                        <CategoryVisibility control={control} setValue={setValue} />
                                    </FormGroupDescription>
                                )}

                                <FormGroupDescription title={'Hidden category'}>
                                    <SwitchField
                                        name={'hidden'}
                                        defaultValue={watch('editCategory.hidden') ? true : false}
                                        control={control}
                                        setValue={setValue}
                                        label=''
                                    />
                                </FormGroupDescription>
                                {!addCategory ? (
                                    <FormGroupDescription title='Hide Category tile'>
                                        <SwitchField
                                            name={'hide_category_tile_details'}
                                            defaultValue={
                                                watch('editCategory.hide_category_tile_details') ? true : false
                                            }
                                            control={control}
                                            setValue={setValue}
                                            label=''
                                        />
                                    </FormGroupDescription>
                                ) : null}
                            </EuiForm>
                        </FormProvider>
                    </>
                )}
            </MainLayout>
        </>
    );
};

export default React.memo(CategoryCreateEditPage);
