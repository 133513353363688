import { EuiFlexItem, EuiRadio, EuiText } from '@elastic/eui';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import NumberField from '../../Form/NumberField';

const MinMaxAddonSelectionForm = ({ defaultValue, setValue }) => {
    const [minimumRadioSelected, setMinimumRadioSelected] = useState(
        !defaultValue['minimum'] ? minimumRadio[0].id : minimumRadio[1].id
    );

    const [maximumRadioSelected, setMaximumRadioSelected] = useState(
        !defaultValue['maximum'] ? maximumRadio[0].id : maximumRadio[1].id
    );

    const {
        formState: { errors },
        control,
    } = useFormContext();

    useEffect(() => {
        return function cleanup(params) {
            setMinimumRadioSelected('minimumRadio1');
            setMaximumRadioSelected('maximumRadio1');
        };
    }, []);

    return (
        <>
            <EuiText>
                <strong>Minimum Selection</strong>
            </EuiText>
            <EuiFlexItem style={{ padding: '10px' }}>
                <EuiRadio
                    id={'minimumRadio1'}
                    label={<EuiText>There is no minimum addOn Selection</EuiText>}
                    checked={minimumRadioSelected === 'minimumRadio1' ? true : false}
                    onChange={(e) => {
                        if (e.target.id) {
                            setValue('minimum', 0, { shouldDirty: true });
                        }
                        setMinimumRadioSelected(e.target.id);
                    }}
                />

                <EuiRadio
                    id={'minimumRadio2'}
                    label={<EuiText>Set minimum addOn selection</EuiText>}
                    checked={minimumRadioSelected === 'minimumRadio2' ? true : false}
                    onChange={(e) => setMinimumRadioSelected(e.target.id)}
                />

                {minimumRadioSelected === 'minimumRadio2' && (
                    <NumberField
                        label='Minimum Selection'
                        errors={errors}
                        isInvalid={errors.hasOwnProperty('minimum')}
                        name={'minimum'}
                        min={0}
                        control={control}
                        placeholder='Minimum selection'
                        rules={{
                            required: 'Please enter minimum selection',
                        }}
                    />
                )}
            </EuiFlexItem>

            <EuiText>
                <strong>Maximum Selection</strong>
            </EuiText>
            <EuiFlexItem style={{ padding: '10px' }}>
                <EuiRadio
                    id={'maximumRadio1'}
                    label={<EuiText>There is no maximum addOn Selection</EuiText>}
                    checked={maximumRadioSelected === 'maximumRadio1' ? true : false}
                    onChange={(e) => {
                        if (e.target.id) {
                            setValue('maximum', 0, { shouldDirty: true });
                        }
                        setMaximumRadioSelected(e.target.id);
                    }}
                />

                <EuiRadio
                    id={'maximumRadio2'}
                    label={<EuiText>Set maximum addOn selection</EuiText>}
                    checked={maximumRadioSelected === 'maximumRadio2' ? true : false}
                    onChange={(e) => setMaximumRadioSelected(e.target.id)}
                />

                {maximumRadioSelected === 'maximumRadio2' && (
                    <NumberField
                        label='Maximum Selection'
                        errors={errors}
                        isInvalid={errors.hasOwnProperty('maximum')}
                        name={'maximum'}
                        min={0}
                        control={control}
                        placeholder='Maximum selection'
                        rules={{
                            required: 'Please enter maximum selection',
                        }}
                    />
                )}
            </EuiFlexItem>
        </>
    );
};

export default React.memo(MinMaxAddonSelectionForm);
const minimumRadio = [
    {
        id: 'minimumRadio1',
        label: <EuiText>There is no minimum addOn Selection</EuiText>,
    },
    {
        id: 'minimumRadio2',
        label: <EuiText>Set minimum addOn selection</EuiText>,
    },
];

const maximumRadio = [
    {
        id: 'maximumRadio1',
        label: <EuiText>There is no maximum addOn Selection</EuiText>,
    },
    {
        id: 'maximumRadio2',
        label: <EuiText>Set maximum addOn selection</EuiText>,
    },
];
