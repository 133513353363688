import React from 'react';
import IncomingOrderSound from '../../assets/sound/IncomingOrderSound.wav';
import Sound from 'react-sound';
import { useSelector } from 'react-redux';

const SoundComponent = (props) => {
    const ordersCount = useSelector((state) => state.orders.ordersCount);
    let notificationSoundLoop = localStorage.getItem('notificationSoundLoop');

    return (
        <Sound
            url={IncomingOrderSound}
            playStatus={
                ordersCount.placed && (notificationSoundLoop == null || notificationSoundLoop == '1')
                    ? Sound.status.PLAYING
                    : Sound.status.STOPPED
            }
            loop={Boolean(ordersCount?.placed)}
        />
    );
};

export default SoundComponent;
