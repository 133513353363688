import { EuiButtonIcon, EuiConfirmModal, EuiOverlayMask } from '@elastic/eui';
import React, { useState } from 'react';
import API from '../../../api/axios/API';
import { fetchImageList } from '../Api/fetchImageList';

const MediaDeletion = ({ image, setImageState, activePage, rowSize }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleOnImageDeletion = () => {
        setIsOpen(true);
    };

    const handleConfirmImageDeletion = async () => {
        setIsOpen(false);

        try {
            await API.delete(`/restaurants/:restaurantId/uploads/${image.id}`);

            fetchImageList(image.image_type, setImageState, activePage + 1, rowSize);
        } catch (error) {
            // todo add fallback
        }
    };

    return (
        <>
            {isOpen ? (
                <EuiOverlayMask>
                    <EuiConfirmModal
                        title={<p style={{ maxWidth: 320 }}>Are you sure you want to delete this image?</p>}
                        onCancel={() => setIsOpen(false)}
                        onConfirm={handleConfirmImageDeletion}
                        cancelButtonText='Cancel'
                        confirmButtonText='Delete'
                        buttonColor='danger'
                        defaultFocusedButton='cancel'
                    >
                        <p>You&rsquo;re about to delete this image.</p>
                    </EuiConfirmModal>
                </EuiOverlayMask>
            ) : null}

            <EuiButtonIcon onClick={handleOnImageDeletion} iconType='trash' color='danger' />
        </>
    );
};

export default MediaDeletion;
