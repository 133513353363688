import { EuiSpacer, EuiText } from '@elastic/eui';
import { isEmpty } from 'lodash';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import LanguageBannerImage from './LanguageBannerImage';

const ImageSelectionZone = ({
    language,
    aspectRatio,
    buttonLabel = 'Add an image',
    selectedImgButtonLabel = 'Change banner image',
}) => {
    const [selectedImages, setSelectedImages] = useState({});
    const {
        setValue,
        watch,
        formState: { isDirty },
    } = useFormContext();

    useEffect(() => {
        if (!isEmpty(watch(`image.${language.language_id}`))) {
            setSelectedImages(watch(`image.${language.language_id}`));
        } else {
            setSelectedImages({});
        }
    }, [language.language_id, watch, isDirty]);

    return (
        <>
            <EuiText size='xs' style={{ fontWeight: 600 }}>
                {language.language_label}
            </EuiText>
            <EuiSpacer size='xs' />
            <LanguageBannerImage
                buttonLabel={buttonLabel}
                selectedImgButtonLabel={selectedImgButtonLabel}
                imageType={aspectRatio.imageType}
                selectedImages={selectedImages}
                name={`image.${language.language_id}`}
                defaultImageValue={watch(`image.${language.language_id}`)}
                setValue={setValue}
                // label='Brand Logo'
                aspectRatio={aspectRatio}
                setSelectedImages={setSelectedImages}
            />
            <EuiSpacer size='s' />
        </>
    );
};

export default React.memo(ImageSelectionZone);
