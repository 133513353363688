import React from 'react';
import { EuiEmptyPrompt } from '@elastic/eui';

const ErrorMessage = () => {
    return (
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
            <EuiEmptyPrompt
                iconType='help'
                title={<h2>Unable to perform action</h2>}
                body={<p>Please refresh the page.</p>}
            />
        </div>
    );
};

export default ErrorMessage;
