export const itemOnSubmitStructureFormate = (data, category_id, menu_links) => {
    if (data[`item_${category_id}`]) {
        if (data[`item_${category_id}`]?.length) {
            for (const categoryItem of data[`item_${category_id}`]) {
                if (categoryItem?.ordering_modes?.length) {
                    for (const orderingMode of categoryItem.ordering_modes) {
                        if (orderingMode?.selected_item?.length) {
                            const selectedItem = orderingMode.selected_item[0];
                            menu_links.push({
                                module_id: selectedItem?.module_id,
                                module_name: 'item',
                                third_party_service_provider: 'pet-pooja',
                                third_party_module_id: selectedItem?.third_party_module_id,
                                outlet_id: selectedItem?.outlet_id,
                                title: selectedItem?.title,
                                uuid: selectedItem?.uuid?.toString(),
                                linkedItem: selectedItem?.linkedItem,
                                restaurant_ordering_mode_id: orderingMode?.restaurant_ordering_mode_id,
                                itemid: selectedItem.itemid.toString(),
                                tax: selectedItem.tax,
                            });
                        }
                    }
                }

                // VARIANT PAYLOAD

                if (categoryItem?.variants?.length) {
                    for (const option of categoryItem?.variants?.[0]?.options) {
                        if (option?.ordering_modes?.length) {
                            for (const orderingMode of option.ordering_modes) {
                                if (orderingMode?.selected_item?.length) {
                                    const selectedVariant = orderingMode.selected_item[0];
                                    menu_links.push({
                                        module_id: selectedVariant?.module_id,
                                        // module_name: selectedVariant?.module_name,
                                        module_name: 'variant_item',
                                        third_party_service_provider: 'pet-pooja',
                                        third_party_module_id: selectedVariant?.id,

                                        outlet_id: selectedVariant?.outlet_id,
                                        linkedItem: selectedVariant?.linkedItem,
                                        restaurant_ordering_mode_id: orderingMode.restaurant_ordering_mode_id,
                                        title: selectedVariant.name,
                                    });
                                    menu_links.push({
                                        module_id: selectedVariant?.module_id,
                                        // module_name: selectedVariant?.module_name,
                                        module_name: 'variant_option',
                                        third_party_service_provider: 'pet-pooja',
                                        third_party_module_id: selectedVariant?.variationid,
                                        linkedItem: selectedVariant?.linkedItem,
                                        outlet_id: selectedVariant?.outlet_id,
                                        restaurant_ordering_mode_id: orderingMode.restaurant_ordering_mode_id,
                                        title: selectedVariant.name,
                                    });
                                }
                            }
                        }
                    }
                }
            }
        }
    }
};
