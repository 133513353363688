import { EuiSpacer, EuiTab, EuiTabs } from '@elastic/eui';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOutletOrderingModes } from '../../api/outlet/fetchOutletOrderingModes';
import AddonAvailabilityScreen from '../../modules/ItemAvailability/Tabs/AddonAvailabilityScreen';
import ItemAvailabilityScreen from '../../modules/ItemAvailability/Tabs/ItemAvailabilityScreen';

const AvailabilityTabSelection = () => {
    const dispatch = useDispatch();
    const outletId = useSelector((state) => state.outlet.selectedOutletId);
    const [state, setState] = useState({
        currentTab: 'item_availability',
        activeScreen: <ItemAvailabilityScreen />,
        loading: true,
    });

    useEffect(() => {
        dispatch(fetchOutletOrderingModes(outletId));
    }, [outletId, dispatch]);

    const handleTabSelection = useCallback((label) => {
        setState((prevState) => ({
            ...prevState,
            currentTab: label,
            activeScreen: label === 'addon_availability' ? <AddonAvailabilityScreen /> : <ItemAvailabilityScreen />,
        }));
    }, []);

    return (
        <>
            <EuiTabs>
                <EuiTab
                    isSelected={state.currentTab === 'item_availability'}
                    onClick={() => handleTabSelection('item_availability')}
                >
                    Item Availability
                </EuiTab>
                <EuiTab
                    isSelected={state.currentTab === 'addon_availability'}
                    onClick={() => handleTabSelection('addon_availability')}
                >
                    Addon Availability
                </EuiTab>
            </EuiTabs>

            <EuiSpacer />

            {state.activeScreen}
        </>
    );
};

export default React.memo(AvailabilityTabSelection);
