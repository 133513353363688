import React from 'react';
import { MainLayout } from '../../components/layout/mainLayout';
import BillPaymentScreen from './Components/BillPaymentScreen';

const BillPaymentPage = () => {
    return (
        <MainLayout
            quickNavigations={[
                {
                    href: '/table-management',
                    label: 'Table Managament',
                },
                {
                    href: '/live-orders',
                    label: 'Live Orders',
                },
            ]}
            title='Bill Payments'
            outlet={true}
            isSingleScreen={true}
            backgroundColorChange={true}
        >
            <BillPaymentScreen />
        </MainLayout>
    );
};

export default BillPaymentPage;
