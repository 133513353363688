import { EuiFlexGroup, EuiFlexItem, EuiHorizontalRule, EuiText } from '@elastic/eui';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { agmarkSign } from '../../../../helper/agmarkSign';
import AvailabilitySwitch from '../../ItemAvailabilityScreen/Components/AvailabilitySwitch';

export const checkChoiceGroupStatus = (choice) => {
    const status =
        choice.in_stock_choice_ordering_mode_count > 0 &&
        choice.total_addon_ordering_modes > 0 &&
        choice.in_stock_choice_ordering_mode_count !== choice.total_addon_ordering_modes
            ? 'partial_state'
            : choice.in_stock_choice_ordering_mode_count > 0 &&
              choice.total_addon_ordering_modes > 0 &&
              choice.in_stock_choice_ordering_mode_count === choice.total_addon_ordering_modes
            ? 'turned_on'
            : choice.in_stock_choice_ordering_mode_count === 0 && choice.total_addon_ordering_modes === 0
            ? 'turned_off'
            : 'turned_off';

    return status;
};

const AddonChoice = ({ choice, handleAvailablityModal, fetchAddonChoices }) => {
    const languageId = useSelector((state) => state.language.languageId);
    const [toggleCompressedIdSelected, setToggleCompressedIdSelected] = useState('');
    // availability switch logic

    const defaultButtonColor =
        choice.in_stock_choice_ordering_mode_count > 0 &&
        choice.total_addon_ordering_modes > 0 &&
        choice.in_stock_choice_ordering_mode_count !== choice.total_addon_ordering_modes
            ? 'warning'
            : choice.in_stock_choice_ordering_mode_count > 0 &&
              choice.total_addon_ordering_modes > 0 &&
              choice.in_stock_choice_ordering_mode_count === choice.total_addon_ordering_modes
            ? 'success'
            : choice.in_stock_choice_ordering_mode_count === 0 && choice.total_addon_ordering_modes === 0
            ? 'danger'
            : 'danger';

    useEffect(() => {
        setToggleCompressedIdSelected(checkChoiceGroupStatus(choice));
    }, [choice]);

    const [buttonColor, setButtonColor] = useState(defaultButtonColor);

    useEffect(() => {
        setButtonColor(defaultButtonColor);
    }, [defaultButtonColor]);

    const handleAvailabilitySwitchChange = useCallback(
        (optionId, isCategory, selectedElement) => {
            let callbackData = {
                showModal: true,
            };
            if (!optionId === 'partial_state') setToggleCompressedIdSelected(optionId);
            if (optionId === 'turned_off') {
                setButtonColor('danger');
            } else if (optionId === 'turned_on') {
                setButtonColor('success');
            }

            if (optionId === 'turned_off' || optionId === 'turned_on') {
                callbackData.modalResponse = {
                    data: selectedElement,
                    isActive: optionId === 'turned_off' ? 0 : 1,
                };
            }

            handleAvailablityModal({
                showModal: callbackData.showModal,
                modalResponse: callbackData.modalResponse,
                choice_id: choice.id,
                current_tab_addon: true,
                clearSwitchStateOnClose: () => {
                    setToggleCompressedIdSelected(toggleCompressedIdSelected);
                    setButtonColor(buttonColor);
                },
                fetchUpdatedAddonChoice: () => {
                    fetchAddonChoices();
                },
            });
        },
        [buttonColor, choice.id, handleAvailablityModal, fetchAddonChoices, toggleCompressedIdSelected]
    );

    return (
        <React.Fragment>
            <EuiHorizontalRule margin='xs' />
            <EuiFlexGroup gutterSize='xs' justifyContent='spaceBetween' alignItems='center'>
                <EuiFlexItem>
                    <EuiFlexGroup gutterSize='xs' justifyContent='flexStart' alignItems='center'>
                        <EuiFlexItem grow={false} style={{ marginRight: '10px' }}>
                            {choice.food_type ? agmarkSign(choice.food_type) : ''}
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiText>{choice.translations.name[languageId]}</EuiText>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <AvailabilitySwitch
                        buttonColor={buttonColor}
                        handleAvailabilitySwitchChange={handleAvailabilitySwitchChange}
                        toggleCompressedIdSelected={toggleCompressedIdSelected}
                        buttonSize={'compressed'}
                        selectedElement={choice}
                        name={choice.id.toString()}
                    />
                </EuiFlexItem>
            </EuiFlexGroup>
        </React.Fragment>
    );
};

export default React.memo(AddonChoice);
