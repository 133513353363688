import APIV3 from '../../api/axios/APIV3';
import * as outletTypes from '../types/outlet';

export const fetchUserOutletInfo = () => {
    return async (dispatch) => {
        let outletData = null;
        try {
            outletData = APIV3.get(`restaurants/:restaurantId/users/outlets`);

            let outletArray = [];
            const languageId = localStorage.getItem('language');
            outletData.outlets.forEach((outlet) => {
                outletArray.push({
                    value: outlet.outlet_id,
                    inputDisplay: outlet.translations.name[languageId],
                    outlet_data: outlet,
                });
            });
            dispatch({
                type: outletTypes.SET_OUTLET_LIST,
                payload: {
                    outletList: outletArray,
                },
            });

            if (outletArray.length) {
                dispatch({
                    type: outletTypes.SET_SELECTED_OUTLET_ID,
                    payload: {
                        selectedOutletId: outletArray[0].value,
                    },
                });
                dispatch({
                    type: outletTypes.SET_SELECTED_OUTLET,
                    payload: {
                        selectedOutlet: outletArray[0],
                    },
                });
            }
        } catch (error) {
            outletData = error;
        }

        return outletData;
    };
};
