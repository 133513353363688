import { EuiButton, EuiFlexGroup, EuiFlexItem } from '@elastic/eui';
import React, { useCallback } from 'react';
import { QRCode } from 'react-qrcode-logo';
import { useDispatch } from 'react-redux';
import { toastsErrorMessage } from '../../utils/toasts';

const TableQrCode = ({ value, label }) => {
    const dispatch = useDispatch();

    const downloadQR = useCallback(async () => {
        try {
            const canvas = document.getElementById(value);
            const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
            let downloadLink = document.createElement('a');
            downloadLink.href = pngUrl;
            downloadLink.download = `${value}.png`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        } catch (error) {
            toastsErrorMessage(`Error in downloading QR Code`, dispatch);
        }
    }, [value, dispatch]);

    return (
        <EuiFlexGroup gutterSize='s' wrap direction='column' alignItems='center' justifyContent='center'>
            <EuiFlexItem>
                <label style={{ fontSize: 14, fontWeight: 600 }} htmlFor=''>
                    {label}
                </label>
            </EuiFlexItem>
            <EuiFlexItem>
                <QRCode
                    ecLevel={'H'}
                    qrStyle={'squares'}
                    value={value?.toString()}
                    id={value}
                    // logoImage={FabSystemsLogo}
                    logoWidth={60}
                    logoHeight={60}
                />
            </EuiFlexItem>
            <EuiFlexItem style={{ width: '97%' }}>
                <EuiButton onClick={downloadQR}>Download QR Code</EuiButton>
            </EuiFlexItem>
        </EuiFlexGroup>
    );
};

export default React.memo(TableQrCode);
