import React, { useRef } from 'react';
import {
    EuiFlexGroup,
    EuiToast,
    EuiFlexItem,
    EuiText,
    EuiButton,
    EuiSpacer,
    EuiHorizontalRule,
    EuiCallOut,
    EuiButtonIcon,
    EuiBadge,
} from '@elastic/eui';
// import { UserInfoModal } from '../userInfoModal';
import { OrderRating } from '../orderDetailsCard/orderRating';
import { RiderRating } from '../orderDetailsCard/riderRating';
import { TimmingDetails } from '../orderDetailsCard/timmingDetails';
import { useSelector } from 'react-redux';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import KotPrint from '../print/KotPrint';
import InvoicePrint from '../print/InvoicePrint';
import { userHasPermission } from '../../helper/auth';
import RupeeSymbol from '../rupeeComponent.js';
import { isEmpty } from 'lodash';

export const OrderRatingDetailCard = (props) => {
    const kotPrinterRef = useRef(null);
    const invoicePrinterRef = useRef(null);
    const { order, setUserInfoModal, setRiderInfoModal } = props;
    const permissions = useSelector((state) => state.permission.permission);
    const languageId = useSelector((state) => state.language.languageId);

    const openUserInfoModal = () => {
        setUserInfoModal((prevState) => !prevState);
        return null;
    };

    const getTotalItem = (items) => {
        let count = 0;
        items.map((item) => (count = count + item.quantity));
        return count;
    };

    const offersListDiscountAmount = (order) => {
        let offers = 0;

        if (order.cart.offers && order.cart.offers.length > 0) {
            order.cart.offers.map((offer) => {
                if (offer.offer_type !== 'free_delivery') {
                    offers += offer.discount_amount;
                }
                return null;
            });
        }

        return offers;
    };

    const offersList = (order) => {
        let offers = '';

        if (order.cart.offers && order.cart.offers.length > 0) {
            order.cart.offers.map((offer) => {
                if (offer.offer_type !== 'free_delivery' && offer.coupon_code) {
                    offers += offer.coupon_code + ', ';
                }
                return null;
            });

            if (offers) {
                offers = '[' + offers.substring(0, offers.length - 2) + ']';
            }
        }

        return offers;
    };

    let restaurantList = useSelector((state) => state.auth.restaurantList);
    let selectedRestuarantId = useSelector((state) => state.auth.selectedRestaurantId);
    return (
        <div>
            <div style={{ display: 'none' }}>
                <KotPrint order={order} languageId={languageId} ref={kotPrinterRef} />
                <InvoicePrint
                    order={order}
                    languageId={languageId}
                    ref={invoicePrinterRef}
                    restaurantList={restaurantList}
                    selectedRestuarantId={selectedRestuarantId}
                />
            </div>
            {/* <RiderManageModal
                closeRiderModal={closeRiderModal}
                riderModalOpen={state.riderModalOpen}
                setAssignRider={setAssignRider}
                orderId={order.order_id}
                isPast={props.isPast}
            /> */}
            {/* <OrderRatingUserInfoModal
                userModalOpen={state.userModalOpen}
                closeUserInfoModal={closeUserInfoModal}
                userName={order.customer.name}
                inputAddess={
                    order.customer_address &&
                    order.customer_address.block_number + ', ' + order.customer_address.land_mark
                }
                locationPin={
                    order.customer_address && order.customer_address.latitude + ', ' + order.customer_address.longitude
                }
                phoneNumber={order.customer.mobile_number}
            /> */}
            <div className='order-custom-toast'>
                <EuiToast color='primary' className='no-shadow border-radius-4'>
                    <>
                        <div className='order-details-card'>
                            <div style={{ paddingRight: '12px' }}>
                                <EuiFlexGroup
                                    responsive={false}
                                    justifyContent='spaceBetween'
                                    gutterSize='none'
                                    style={{ paddingRight: '20px' }}
                                >
                                    <EuiFlexItem grow={false}>
                                        {(props.isPast || props.isCustomerOrder) &&
                                            (order.status.delivery_status === 'restaurant_cancelled' ? (
                                                <EuiBadge
                                                    className='custom-badge'
                                                    color='danger'
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <EuiText size='s' style={{ color: 'white' }}>
                                                        Cancelled Order
                                                    </EuiText>
                                                </EuiBadge>
                                            ) : (
                                                <EuiBadge
                                                    className='custom-badge'
                                                    color='primary'
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <EuiText size='s' style={{ color: 'white' }}>
                                                        Delivered
                                                    </EuiText>
                                                </EuiBadge>
                                            ))}
                                        <EuiText size='s'>
                                            <p>Order #{order.order_label}</p>
                                        </EuiText>
                                        <EuiText size='s' color='secondary' className='text-uppercase'>
                                            Outlet: <b>{order.outlet.translations.name[languageId]}</b>
                                        </EuiText>
                                        <EuiText size='xs'>
                                            <p>
                                                {getTotalItem(order.cart.cart_items)} ITEMS | <RupeeSymbol />{' '}
                                                {order.net_total.toFixed(2)}
                                            </p>
                                        </EuiText>
                                    </EuiFlexItem>
                                    <EuiFlexItem grow={false} style={{ flexDirection: 'row' }}>
                                        <EuiText size='s'>
                                            <p>
                                                {/* {order.status.placed_at} */}
                                                {moment(order.status.placed_at).format('DD MMM yyy hh:mm:ss A')}
                                            </p>
                                        </EuiText>
                                        <EuiFlexItem>
                                            <ReactToPrint
                                                copyStyles={false}
                                                trigger={() => (
                                                    <EuiButtonIcon
                                                        aria-labelledby='kot print'
                                                        iconType='apmTrace'
                                                        aria-label='Next'
                                                    />
                                                )}
                                                content={() => kotPrinterRef.current}
                                            />
                                        </EuiFlexItem>
                                        <EuiFlexItem>
                                            <ReactToPrint
                                                copyStyles={false}
                                                trigger={() => (
                                                    <EuiButtonIcon
                                                        aria-labelledby='invoice print'
                                                        iconType='documents'
                                                        aria-label='Next print'
                                                    />
                                                )}
                                                content={() => invoicePrinterRef.current}
                                            />
                                        </EuiFlexItem>
                                    </EuiFlexItem>
                                </EuiFlexGroup>
                                <EuiHorizontalRule margin='s' />
                                {order.cart.cart_items.map((orderItem, index) => (
                                    <div key={index}>
                                        <OrderItem
                                            key={orderItem.item_id}
                                            title={orderItem.item.translations.title[languageId]}
                                            categoryTitle={orderItem.category.translations.title[languageId]}
                                            quantity={orderItem.quantity}
                                            amount={orderItem.price.toFixed(2)}
                                            customizations={orderItem.customizations}
                                        >
                                            {!isEmpty(orderItem.variant_option_information) ? (
                                                <EuiText key={orderItem.variant_option_information.id}>
                                                    <p>
                                                        -{' '}
                                                        {
                                                            orderItem.variant_option_information
                                                                ?.variant_group_information?.translations?.title[
                                                                languageId
                                                            ]
                                                        }
                                                        :{' '}
                                                        <b>
                                                            {
                                                                orderItem.variant_option_information.translations.title[
                                                                    languageId
                                                                ]
                                                            }
                                                        </b>
                                                    </p>
                                                </EuiText>
                                            ) : (
                                                ''
                                            )}

                                            {orderItem.customizations &&
                                                orderItem.customizations.map((addons, i) => (
                                                    <EuiText key={i}>
                                                        <p>
                                                            - {addons.translations.group_name[languageId]}:{' '}
                                                            <b>
                                                                {addons.translations.name[languageId]} [<RupeeSymbol />
                                                                {addons.price.toFixed(2)}]
                                                            </b>
                                                        </p>
                                                    </EuiText>
                                                ))}
                                        </OrderItem>
                                    </div>
                                ))}

                                <EuiSpacer size='xs' />
                                {order.cart.special_instructions !== null && (
                                    <EuiCallOut title='cooking instructions' color='warning' iconType='help'>
                                        <p>{order.cart.special_instructions}</p>
                                    </EuiCallOut>
                                )}

                                <EuiHorizontalRule margin='s' />
                                <EuiFlexGroup
                                    responsive={false}
                                    justifyContent='spaceBetween'
                                    gutterSize='none'
                                    style={{ paddingRight: '20px' }}
                                >
                                    <EuiFlexItem grow={false}>
                                        <EuiText size='xs'>
                                            <p>Item Total</p>
                                        </EuiText>
                                        <EuiText>
                                            <h2>
                                                <b>
                                                    <RupeeSymbol />
                                                    {order.gross_total.toFixed(2)}
                                                </b>
                                            </h2>
                                        </EuiText>
                                    </EuiFlexItem>
                                    <EuiFlexItem grow={false} className='flex-horizontal-center'>
                                        <EuiText>
                                            <h2>
                                                <b>x{getTotalItem(order.cart.cart_items)}</b>
                                            </h2>
                                        </EuiText>
                                    </EuiFlexItem>
                                </EuiFlexGroup>
                                <EuiHorizontalRule margin='s' />

                                {offersListDiscountAmount(order) ? (
                                    <EuiText>
                                        <p>
                                            Discount {offersList(order)}{' '}
                                            <b>
                                                <RupeeSymbol size={'17px'} />{' '}
                                                {parseFloat(offersListDiscountAmount(order)).toFixed(2)}
                                            </b>
                                        </p>
                                    </EuiText>
                                ) : (
                                    ''
                                )}

                                {order.cart &&
                                !isEmpty(order.cart.reward_points) &&
                                order.cart.reward_points.used_reward_points.value ? (
                                    <EuiText>
                                        <p>
                                            Cashback{' '}
                                            <b>
                                                <RupeeSymbol size={'17px'} />
                                                {order.cart.reward_points.used_reward_points.value}[
                                                {order.cart.reward_points.used_reward_points.points} points]
                                            </b>
                                        </p>
                                    </EuiText>
                                ) : (
                                    ''
                                )}

                                <EuiText>
                                    <p>
                                        Gross Total:{' '}
                                        <b>
                                            <RupeeSymbol />
                                            {order.gross_total.toFixed(2)}
                                        </b>
                                    </p>
                                </EuiText>
                                <EuiText>
                                    <p>
                                        Other Charges & Taxes:{' '}
                                        <b>
                                            <RupeeSymbol />
                                            {(
                                                order.gst_amount +
                                                order.cart.packaging_charge +
                                                order.cart.convenience_charge
                                            ).toFixed(2)}
                                        </b>
                                    </p>
                                </EuiText>
                                <EuiText>
                                    <p>
                                        Delivery Charge:{' '}
                                        <b>
                                            <RupeeSymbol />
                                            {order.delivery_charge.toFixed(2)}
                                        </b>
                                    </p>
                                </EuiText>
                                <EuiText>
                                    <p>
                                        <b>
                                            Net Total: <RupeeSymbol />
                                            {order.net_total.toFixed(2)}
                                        </b>
                                    </p>
                                </EuiText>

                                <>
                                    <EuiHorizontalRule margin='s' />
                                    <OrderRating data={props} order={order} />
                                    {order.ordering_mode === 'delivery' && <RiderRating data={props} order={order} />}
                                    <TimmingDetails order={order} />
                                    <EuiSpacer margin='s' />
                                </>
                            </div>
                        </div>
                        {userHasPermission(permissions, '/live-orders', 'read') && (
                            <EuiFlexGroup responsive={false}>
                                <EuiFlexItem grow={5}>
                                    <EuiButton
                                        aria-label='customer info'
                                        iconType='user'
                                        onClick={() => openUserInfoModal()}
                                    >
                                        Customer Info
                                    </EuiButton>
                                </EuiFlexItem>
                                <EuiFlexItem grow={5}>
                                    <EuiButton
                                        aria-label='rider info'
                                        iconType='reporter'
                                        color='primary'
                                        onClick={() => setRiderInfoModal((prevState) => !prevState)}
                                        disabled={order.rider && order.rider_order ? false : true}
                                    >
                                        Rider Info
                                    </EuiButton>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        )}
                    </>
                </EuiToast>
            </div>
            <EuiSpacer size='s' />
        </div>
    );
};
const customizationTotal = (customization) => {
    let total = 0;
    if (customization) {
        customization.map((addons) => (total += parseFloat(addons.price.toFixed(2))));

        return total;
    }

    return 0;
};

const OrderItem = (props) => {
    return (
        <>
            <EuiFlexGroup justifyContent='spaceBetween' responsive={false} style={{ paddingRight: '20px' }}>
                <EuiFlexItem grow={false}>
                    <EuiText>
                        <h2>{props.title}</h2>
                    </EuiText>
                    <EuiText size='xs' color='subdued'>
                        <p>{props.categoryTitle}</p>
                    </EuiText>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiText>
                        <h2>x{props.quantity}</h2>
                    </EuiText>
                </EuiFlexItem>
            </EuiFlexGroup>
            {props.children}
            <EuiText>
                <h2>
                    <RupeeSymbol />
                    {parseInt(props.amount) + customizationTotal(props.customizations)}
                </h2>
            </EuiText>
            <EuiSpacer size='s' />
        </>
    );
};
