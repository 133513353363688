import React from 'react';
import { EuiSwitch } from '@elastic/eui';
import { Controller } from 'react-hook-form';

const SwitchField = (props) => {
    const { name, defaultValue, control, setValue, label } = props;
    return (
        <Controller
            render={({ field }) => (
                <EuiSwitch
                    onChange={(e) => {
                        field.onChange(e.target.checked);
                        setValue(name, e.target.checked);
                    }}
                    checked={Boolean(field.value)}
                    compressed
                    label={label ? label : ''}
                />
            )}
            name={name}
            control={control}
            defaultValue={defaultValue}
        />
    );
};

export default SwitchField;
