import { SET_ORDERING_MODES } from '../../reduxStore/types/outlet';
import APIV3 from '../axios/APIV3';

export const fetchOrderingModes = () => {
    return async (dispatch, getState) => {
        const { selectedOutletId } = getState().outlet;

        let response;

        try {
            response = await APIV3.get(`/restaurants/:restaurantId/outlets/${selectedOutletId}/outlet-ordering-modes`);
            dispatch({
                type: SET_ORDERING_MODES,
                payload: response.outlet_ordering_modes,
            });
        } catch (error) {}

        return response;
    };
};
