import React from 'react';
import { EuiFlexItem, EuiModal, EuiModalBody } from '@elastic/eui';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useState } from 'react';
import { useEffect } from 'react';

const BannerImagesModal = ({ bannerImagesModal, handleBannerModal }) => {
    const [images, setImages] = useState([]);

    useEffect(() => {
        if (bannerImagesModal?.images?.translations?.image) {
            const bannerImages = bannerImagesModal?.images?.translations?.image;
            let imageArray = [];
            for (const objectKey of Object.keys(bannerImages)) {
                // image cleanup
                if (
                    bannerImages[objectKey] === null ||
                    bannerImages[objectKey] === '' ||
                    bannerImages[objectKey] === 'null' ||
                    bannerImages[objectKey] === undefined
                ) {
                    delete bannerImages[objectKey];
                }
            }
            for (const key in bannerImages) {
                let value = bannerImages[key];
                imageArray.push(
                    <img
                        alt={`banner_${key}`}
                        key={key}
                        style={{ objectFit: 'cover' }}
                        src={value?.image_url ? value.image_url : ''}
                    />
                );
            }
            if (imageArray?.length) {
                setImages(imageArray);
            }
        }
    }, [bannerImagesModal?.images]);

    return (
        <>
            {bannerImagesModal.display ? (
                <EuiModal onClose={handleBannerModal}>
                    <EuiModalBody>
                        <EuiFlexItem style={{ paddingTop: '12px' }}>
                            <Carousel
                                showThumbs={images?.length > 1 ? true : false}
                                // showIndicators={images?.length > 1 ? true : false}
                                showStatus={false}
                            >
                                {images}
                            </Carousel>
                        </EuiFlexItem>
                    </EuiModalBody>
                </EuiModal>
            ) : null}
        </>
    );
};

export default React.memo(BannerImagesModal);
