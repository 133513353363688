import React, { useEffect, useState } from 'react';
import {
    EuiCard,
    EuiFlexGroup,
    EuiFlexItem,
    EuiLoadingContent,
    EuiPanel,
    EuiTablePagination,
    EuiTitle,
} from '@elastic/eui';
import MediaSelectionImage from '../../../components/UIComponent/ImageSelection/MediaSelectionImage';
import { fetchImageList } from '../Api/fetchImageList';
import UploadMedia from './UploadMedia';

const MediaInventory = ({ sidebarItems, selectedTab }) => {
    const [state, setState] = useState({
        images: [],
        isLoading: false,
        showUploadImageModal: false,
    });

    const [activePage, setActivePage] = useState(0);
    const [rowSize, setRowSize] = useState(10);
    const [totalEntries, setTotalEntries] = useState(1);
    const [pageCount, setPageCount] = useState(Math.ceil(totalEntries / 10));

    const fetchImages = async (imageType) => {
        await fetchImageList(imageType, setState, activePage + 1, rowSize, setTotalEntries);
    };

    useEffect(() => {
        fetchImages(selectedTab);
    }, [selectedTab, activePage, rowSize]);

    const aspectRatio = sidebarItems.filter((item) => item.id === selectedTab)?.[0]?.aspectRatio;

    const goToPage = (pageNumber) => {
        setActivePage(pageNumber);
    };
    const changeItemsPerPage = (pageSize) => {
        setPageCount(Math.ceil(totalEntries / pageSize));
        setRowSize(pageSize);
        setActivePage(0);
    };

    useEffect(() => {
        setPageCount(Math.ceil(totalEntries / 10));
    }, [totalEntries]);

    return (
        <>
            {state.isLoading ? (
                <>
                    <EuiFlexItem>
                        <EuiPanel>
                            <EuiLoadingContent lines={4} />
                        </EuiPanel>
                    </EuiFlexItem>
                </>
            ) : (
                <EuiFlexItem>
                    <EuiPanel>
                        <EuiFlexGroup direction='column'>
                            <EuiFlexItem>
                                <EuiFlexGroup justifyContent='spaceBetween' alignItems='flexEnd'>
                                    <EuiFlexItem grow={false}>
                                        <EuiTitle size='s'>
                                            <h4>
                                                {sidebarItems.map((item) =>
                                                    item.id === selectedTab ? item.name : null
                                                )}
                                            </h4>
                                        </EuiTitle>
                                    </EuiFlexItem>
                                    <UploadMedia
                                        fetchImages={fetchImages}
                                        selectedTab={selectedTab}
                                        aspectRatio={aspectRatio}
                                    />
                                </EuiFlexGroup>
                            </EuiFlexItem>
                            {state.images?.length ? (
                                <EuiFlexItem>
                                    <EuiFlexGroup wrap responsive direction='row' alignItems='flexStart' gutterSize='l'>
                                        {state.images.map((image) => {
                                            return (
                                                <MediaSelectionImage
                                                    isModuleComponent
                                                    key={image.id.toString()}
                                                    image={image}
                                                    setImageState={setState}
                                                    activePage={activePage}
                                                    rowSize={rowSize}
                                                />
                                            );
                                        })}
                                    </EuiFlexGroup>
                                </EuiFlexItem>
                            ) : (
                                <EuiFlexItem>
                                    <EuiCard title='No Images Found' titleSize='xs' />
                                </EuiFlexItem>
                            )}

                            {/* {state.images?.length ? ( */}
                            <EuiTablePagination
                                aria-label='Image Table pagination'
                                pageCount={pageCount}
                                activePage={activePage}
                                onChangePage={goToPage}
                                itemsPerPage={rowSize}
                                onChangeItemsPerPage={changeItemsPerPage}
                                itemsPerPageOptions={[10, 50, 100]}
                            />
                            {/* ) : null} */}
                        </EuiFlexGroup>
                    </EuiPanel>
                </EuiFlexItem>
            )}
        </>
    );
};

export default MediaInventory;
