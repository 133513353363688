import moment from 'moment';
import { fetchCreditTransactions } from '../../../api/topup/TransactionHistory';

export const additionalDateActions = ({
    setStartDate,
    setEndDate,
    setPagination,
    setTransactionData,
    pagination,
    typeOfTransaction,
    startDate,
    endDate,
    dispatch,
}) => {
    return [
        {
            label: 'Today',
            onClick: () => {
                setStartDate(moment());
                setEndDate(moment());
                setPagination({ pageIndex: 0, pageSize: 10 });
                dispatch(fetchCreditTransactions(pagination, typeOfTransaction, startDate, endDate)).then((res) => {
                    setTransactionData(res.transactions.data);
                });
            },
            iconType: 'calendar',
            size: 'xs',
            color: 'text',
        },
        {
            label: 'Yesterday',
            onClick: () => {
                setStartDate(moment().subtract(1, 'day'));
                setEndDate(moment().subtract(1, 'day'));
                setPagination({ pageIndex: 0, pageSize: 10 });
                dispatch(fetchCreditTransactions(pagination, typeOfTransaction, startDate, endDate)).then((res) => {
                    setTransactionData(res.transactions.data);
                });
            },
            iconType: 'calendar',
            size: 'xs',
            color: 'text',
        },
        {
            label: 'Last 7 days',
            onClick: () => {
                setStartDate(moment().subtract(7, 'day'));
                setEndDate(moment());
                setPagination({ pageIndex: 0, pageSize: 10 });
                dispatch(fetchCreditTransactions(pagination, typeOfTransaction, startDate, endDate)).then((res) => {
                    setTransactionData(res.transactions.data);
                });
            },
            iconType: 'calendar',
            size: 'xs',
            color: 'text',
        },
        {
            label: 'Last 30 days',
            onClick: () => {
                setStartDate(moment().subtract(1, 'month'));
                setEndDate(moment());
                setPagination({ pageIndex: 0, pageSize: 10 });
                dispatch(fetchCreditTransactions(pagination, typeOfTransaction, startDate, endDate)).then((res) => {
                    setTransactionData(res.transactions.data);
                });
            },
            iconType: 'calendar',
            size: 'xs',
            color: 'text',
        },
        {
            label: 'This month',
            onClick: () => {
                setStartDate(moment().clone().startOf('month'));
                setEndDate(moment());
                setPagination({ pageIndex: 0, pageSize: 10 });
                dispatch(fetchCreditTransactions(pagination, typeOfTransaction, startDate, endDate)).then((res) => {
                    setTransactionData(res.transactions.data);
                });
            },
            iconType: 'calendar',
            size: 'xs',
            color: 'text',
        },
        {
            label: 'Last month',
            onClick: () => {
                setStartDate(moment().subtract(1, 'month').startOf('month'));
                setEndDate(moment().subtract(1, 'month').endOf('month'));
                setPagination({ pageIndex: 0, pageSize: 10 });
                dispatch(fetchCreditTransactions(pagination, typeOfTransaction, startDate, endDate)).then((res) => {
                    setTransactionData(res.transactions.data);
                });
            },
            iconType: 'calendar',
            size: 'xs',
            color: 'text',
        },
    ];
};
