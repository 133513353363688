import { EuiFlexGroup, EuiLoadingSpinner, EuiOverlayMask, EuiText } from '@elastic/eui';
import React from 'react';
import { useSelector } from 'react-redux';

const RestaurantLoader = () => {
    const restaurantLoader = useSelector((state) => state.auth.restaurantLoader);

    return (
        <>
            {restaurantLoader ? (
                <EuiOverlayMask>
                    <EuiFlexGroup direction='column' alignItems='center'>
                        <EuiLoadingSpinner size='l' />
                        <EuiText>
                            <h2> Loading Restaurant... </h2>
                        </EuiText>
                    </EuiFlexGroup>
                </EuiOverlayMask>
            ) : null}
        </>
    );
};

export default React.memo(RestaurantLoader);
