import React, { useCallback } from 'react';
import { EuiSpacer, EuiText } from '@elastic/eui';
import { Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '../../../components/Form/TextField';
import { updateOutletOrderingModes as saveBillSettings } from '../../../components/settings/outlet-form/utils';
import AceEditor from 'react-ace';
import useOutletFormValues from '../Hooks/useOutletFormValues';
import useFormActionsHandler from '../../../hooks/useFormActionsHandler';

const BillSetting = () => {
    const dispatch = useDispatch();
    const outletId = useSelector((state) => state.outlet.selectedOutletId);

    const updateFormValues = useCallback((data) => {
        return {
            legal_name: data?.legal_name,
            bill_header: data?.settings?.bill_header,
            bill_footer: data?.settings?.bill_footer,
        };
    }, []);

    const { defaultValues, setDefaultValues, onSuccessCallback } = useOutletFormValues({ updateFormValues });

    const onFormSaveApi = useCallback(
        async (data) => {
            const response = await saveBillSettings(outletId, data, 'bill_payment_settings');
            if (response.success) setDefaultValues(updateFormValues(response.outlet));
            return response;
        },
        [dispatch, outletId]
    );

    const { control, errors } = useFormActionsHandler({ onFormSaveApi, defaultValues, onSuccessCallback });

    return (
        <>
            <TextField
                label='Legal Name'
                placeholder='Enter Legal Name'
                errors={errors}
                name={`legal_name`}
                control={control}
            />
            <EuiSpacer />
            <EuiText size='s' style={{ fontWeight: 'bold', fontSize: 12 }}>
                Bill Header
            </EuiText>
            <Controller
                control={control}
                name='bill_header'
                render={({ field }) => {
                    return (
                        <AceEditor
                            style={codeEditorStyles}
                            placeholder='Add Bill Header'
                            mode='plain_text'
                            theme='github'
                            onChange={field.onChange}
                            value={field.value}
                            name='bill_header'
                            height='180px'
                            showGutter={false}
                            wrapEnabled={true}
                            highlightActiveLine={false}
                            editorProps={{ $blockScrolling: true }}
                            enableBasicAutocompletion={true}
                            enableLiveAutocompletion={true}
                            setOptions={{
                                enableSnippets: true,
                                fontSize: '10pt',
                            }}
                        />
                    );
                }}
            />
            <EuiSpacer />
            <EuiText size='s' style={{ fontWeight: 'bold', fontSize: 12 }}>
                Bill Footer
            </EuiText>
            <Controller
                control={control}
                name='bill_footer'
                render={({ field }) => {
                    return (
                        <AceEditor
                            style={codeEditorStyles}
                            placeholder='Add Bill Footer'
                            mode='plain_text'
                            theme='github'
                            onChange={field.onChange}
                            value={field.value}
                            name='bill_footer'
                            height='180px'
                            showGutter={false}
                            wrapEnabled={true}
                            highlightActiveLine={false}
                            editorProps={{ $blockScrolling: true }}
                            enableBasicAutocompletion={true}
                            enableLiveAutocompletion={true}
                            setOptions={{
                                enableSnippets: true,
                                fontSize: '10pt',
                            }}
                        />
                    );
                }}
            />
        </>
    );
};

export default React.memo(BillSetting);
export const codeEditorStyles = {
    border: `1px solid rgb(17 43 134 / 10%)`,
    borderRadius: '6px',
    background: '#fbfcfd',
};
