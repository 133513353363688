export const getFlatToTreeStructureNew = (categories) => {
    let treeArray = [];
    let treeChildrenObject = {};

    categories.map((category) => {
        category.id = category.category_id;
        if (!category.parent_id) {
            treeArray.push(category);
        } else {
            if (treeChildrenObject[category.parent_id]) {
                treeChildrenObject[category.parent_id] = [...treeChildrenObject[category.parent_id], category];
            } else {
                treeChildrenObject[category.parent_id] = [category];
            }
        }
    });

    treeArray.map((category) => {
        if (treeChildrenObject[category.category_id]) {
            category.children = treeChildrenObject[category.category_id];
            category.sub_categories = treeChildrenObject[category.category_id];
            category.expanded = true;
        }
    });

    return treeArray;
};
