import React from 'react';
import { EuiAccordion, EuiPanel, EuiSpacer, EuiText, htmlIdGenerator } from '@elastic/eui';

const OfferSection = ({ title, Component, initialIsOpen }) => {
    return (
        <>
            <EuiSpacer size='s' />
            <EuiPanel>
                <EuiAccordion
                    id={htmlIdGenerator()()}
                    initialIsOpen={initialIsOpen}
                    buttonContent={
                        <EuiText>
                            <h3>{title}</h3>
                        </EuiText>
                    }
                >
                    <EuiSpacer />
                    {Component}
                    <EuiSpacer />
                </EuiAccordion>
            </EuiPanel>
            <EuiSpacer size='s' />
        </>
    );
};

export default React.memo(OfferSection);
