import React, { useCallback, useMemo, useState } from 'react';
import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui';
import MediaInventory from './MediaInventory';
import MediaLibrarySideNavigation from './MediaLibrarySideNavigation';

const MediaLibraryScreen = () => {
    const [selectedTab, setSelectedTab] = useState('categories');

    const onChangeTab = useCallback((tab) => {
        setSelectedTab(tab);
    }, []);

    const sidebarItems = useMemo(() => {
        return [
            {
                id: 'categories',
                name: 'Categories',
                onClick: () => onChangeTab('categories'),
                isSelected: selectedTab === 'categories',
                aspectRatio: 1 / 1.066,
            },
            {
                id: 'items',
                name: 'Items',
                onClick: () => onChangeTab('items'),
                isSelected: selectedTab === 'items',
                aspectRatio: 1,
            },
            {
                id: 'banner',
                name: 'Banner',
                onClick: () => onChangeTab('banner'),
                isSelected: selectedTab === 'banner',
                aspectRatio: 1 / 1.066,
            },
            {
                id: 'variant_options',
                name: 'Variant Options',
                onClick: () => onChangeTab('variant_options'),
                isSelected: selectedTab === 'variant_options',
                aspectRatio: 1,
            },
            {
                id: 'notifications',
                name: 'Notifications',
                onClick: () => onChangeTab('notifications'),
                isSelected: selectedTab === 'notifications',
                aspectRatio: 2 / 1,
            },
            {
                id: 'intro_logo_image',
                name: 'Intro Logo Image',
                onClick: () => onChangeTab('intro_logo_image'),
                isSelected: selectedTab === 'intro_logo_image',
                aspectRatio: 1 / 1.066,
            },
            {
                id: 'footer_image',
                name: 'Footer Image ',
                onClick: () => onChangeTab('footer_image'),
                isSelected: selectedTab === 'footer_image',
                aspectRatio: 1 / 1.066,
            },
            {
                id: 'riders',
                name: 'Riders',
                onClick: () => onChangeTab('riders'),
                isSelected: selectedTab === 'riders',
                aspectRatio: 1 / 1.066,
            },
            {
                id: 'primary_banner',
                name: 'Primary Banner',
                onClick: () => onChangeTab('primary_banner'),
                isSelected: selectedTab === 'primary_banner',
                aspectRatio: 1 / 0.572,
            },
            {
                id: 'secondary_banner',
                name: 'Secondary Banner',
                onClick: () => onChangeTab('secondary_banner'),
                isSelected: selectedTab === 'secondary_banner',
                aspectRatio: 26 / 9.624,
            },
            {
                id: 'tertiary_banner',
                name: 'Tertiary Banner',
                onClick: () => onChangeTab('tertiary_banner'),
                isSelected: selectedTab === 'tertiary_banner',
                aspectRatio: 26 / 9.624,
            },
        ];
    }, [onChangeTab, selectedTab]);

    const sideNav = useMemo(() => {
        return [
            {
                name: '',
                id: 0,
                items: sidebarItems,
            },
        ];
    }, [sidebarItems]);

    return (
        <EuiFlexGroup
            wrap
            responsive
            direction='row'
            justifyContent='spaceBetween'
            alignItems='flexStart'
            gutterSize='l'
        >
            <EuiFlexItem grow={false}>
                <MediaLibrarySideNavigation sideNav={sideNav} />
            </EuiFlexItem>

            <MediaInventory sidebarItems={sidebarItems} selectedTab={selectedTab} />
        </EuiFlexGroup>
    );
};

export default MediaLibraryScreen;
