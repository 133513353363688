import {
    EuiButton,
    EuiCard,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFlyout,
    EuiFlyoutBody,
    EuiFlyoutFooter,
    EuiFlyoutHeader,
    EuiLoadingContent,
    EuiTitle,
    EuiTablePagination,
} from '@elastic/eui';
import { capitalize, isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { fetchImageList } from '../../../modules/MediaLibrary/Api/fetchImageList';
import UploadImageDropZone from '../../../modules/MediaLibrary/Components/UploadImageDropZone';
import UploadMedia from '../../../modules/MediaLibrary/Components/UploadMedia';
import MediaSelectionImage from './MediaSelectionImage';

const ImageSelectionContainer = ({
    imageType,
    selectedImages,
    handleImageSelection,
    toggleModal,
    handleOnSave,
    aspectRatio,
}) => {
    const [state, setState] = useState({
        images: [],
        isLoading: false,
        showUploadImageModal: false,
    });

    const [activePage, setActivePage] = useState(0);
    const [rowSize, setRowSize] = useState(10);
    const [totalEntries, setTotalEntries] = useState(1);
    const [pageCount, setPageCount] = useState(Math.ceil(totalEntries / 10));

    const fetchImages = useCallback(
        async (imageType) => {
            await fetchImageList(imageType, setState, activePage + 1, rowSize, setTotalEntries);
        },
        [activePage, rowSize]
    );

    const handleUploadImageModalToggle = useCallback(() => {
        setState((prevState) => ({
            ...prevState,
            showUploadImageModal: !prevState.showUploadImageModal,
        }));
    }, []);

    useEffect(() => {
        fetchImages(imageType);
    }, [fetchImages, imageType, activePage, rowSize]);

    const goToPage = (pageNumber) => {
        setActivePage(pageNumber);
    };
    const changeItemsPerPage = (pageSize) => {
        setPageCount(Math.ceil(totalEntries / pageSize));
        setRowSize(pageSize);
        setActivePage(0);
    };

    useEffect(() => {
        setPageCount(Math.ceil(totalEntries / 10));
    }, [totalEntries]);

    return (
        <>
            {state.showUploadImageModal ? (
                <UploadImageDropZone
                    imageType={imageType}
                    handleUploadImageModalToggle={handleUploadImageModalToggle}
                    fetchImages={fetchImages}
                    aspectRatio={aspectRatio}
                />
            ) : null}
            <EuiFlyout size={'100%'} maxWidth onClose={toggleModal} style={{ zIndex: 1000 }}>
                <EuiFlyoutHeader style={{ marginTop: '12px' }}>
                    <EuiFlexGroup justifyContent='spaceBetween'>
                        <EuiFlexItem>
                            <EuiTitle size='m'>
                                <h2> Image Selection for {capitalize(imageType.replaceAll('_', ' '))}</h2>
                            </EuiTitle>
                        </EuiFlexItem>
                        <UploadMedia fetchImages={fetchImages} selectedTab={imageType} aspectRatio={aspectRatio} />
                        {/* <EuiFlexItem
                            onClick={handleUploadImageModalToggle}
                            style={{ paddingRight: '20px' }}
                            grow={false}
                        >
                            <UploadButton />
                        </EuiFlexItem> */}
                    </EuiFlexGroup>
                </EuiFlyoutHeader>
                <EuiFlyoutBody>
                    <EuiFlexGroup
                        wrap
                        responsive
                        direction='row'
                        justifyContent='spaceBetween'
                        alignItems='flexStart'
                        gutterSize='l'
                    >
                        {state.isLoading ? (
                            <>
                                <EuiFlexItem>
                                    <EuiLoadingContent lines={4} />
                                </EuiFlexItem>
                            </>
                        ) : (
                            <>
                                {state.images?.length ? (
                                    <EuiFlexItem>
                                        <EuiFlexGroup
                                            wrap
                                            responsive
                                            direction='row'
                                            // justifyContent='spaceBetween'
                                            alignItems='stretch'
                                            gutterSize='l'
                                        >
                                            {state.images.map((image) => {
                                                return (
                                                    <MediaSelectionImage
                                                        handleImageSelection={handleImageSelection}
                                                        key={image.id.toString()}
                                                        selectedImages={selectedImages}
                                                        image={image}
                                                    />
                                                );
                                            })}
                                        </EuiFlexGroup>
                                    </EuiFlexItem>
                                ) : (
                                    <EuiFlexItem>
                                        <EuiCard title='No Images Found' />
                                    </EuiFlexItem>
                                )}
                            </>
                        )}
                    </EuiFlexGroup>
                    <EuiTablePagination
                        aria-label='Image Table pagination'
                        pageCount={pageCount}
                        activePage={activePage}
                        onChangePage={goToPage}
                        itemsPerPage={rowSize}
                        onChangeItemsPerPage={changeItemsPerPage}
                        itemsPerPageOptions={[10, 50, 100]}
                    />
                </EuiFlyoutBody>
                <EuiFlyoutFooter>
                    <EuiFlexGroup>
                        <EuiFlexItem>
                            <EuiButton isDisabled={isEmpty(selectedImages)} onClick={handleOnSave}>
                                Add Selected Images
                            </EuiButton>
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <EuiButton onClick={toggleModal}>Close</EuiButton>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlyoutFooter>
            </EuiFlyout>
        </>
    );
};

export default React.memo(ImageSelectionContainer);
