import React from 'react';
import { Notification } from '../../components/notification/notification';
import { MainLayout } from '../../components/layout/mainLayout/mainLayout';

const NotificationPage = () => {
    return (
        <MainLayout title='Send Notification'>
            <Notification />
        </MainLayout>
    );
};

export default NotificationPage;
