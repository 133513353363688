import React, { useCallback } from 'react';
import FormGroupDescription from '../../../components/formGroupDescription';
import { useSelector } from 'react-redux';
import TextField from '../../../components/Form/TextField';
import { EuiSpacer, EuiForm } from '@elastic/eui';
import { useDispatch } from 'react-redux';
import { outletSettingsAPI } from '../../../api/outlet/outletSettingsAPI';
import MobileNumber from '../../../components/Form/MobileNumber';
import { isEmpty } from 'lodash';
import useOutletFormValues from '../Hooks/useOutletFormValues';
import useFormActionsHandler from '../../../hooks/useFormActionsHandler';

const ContactDetails = () => {
    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);
    const languages = useSelector((state) => state.language.availableLanguages);

    const dispatch = useDispatch();

    const updateFormValues = useCallback((data) => {
        let defaultValue = {};
        let translationObject = {
            name: {},
        };

        for (const languageId in data.translations.name) {
            translationObject.name[languageId] = data.translations.name[languageId];
        }

        defaultValue.translations = translationObject;

        if (data.outlet_address.contact_number) {
            let country_code = data.outlet_address.country_code.slice(1, data.outlet_address.country_code.length);
            defaultValue.contact_number = `${country_code} ${data.outlet_address.contact_number}`;
            defaultValue.country_code = data.outlet_address.country_code;
        }

        return defaultValue;
    }, []);

    const { defaultValues, setDefaultValues, onSuccessCallback } = useOutletFormValues({ updateFormValues });

    const onFormSaveApi = useCallback(
        async (data) => {
            const name = {};

            for (const property in data.translations.name) {
                name[parseInt(property)] = data.translations.name[property];
            }

            data.translations.name = name;
            data.country_code = !isEmpty(data.selectedCountryNumber)
                ? `+${data.selectedCountryNumber.dialCode}`
                : watch('country_code');
            data.contact_number = !isEmpty(data.selectedCountryNumber)
                ? data.selectedCountryNumber.number
                : data.contact_number.split(' ')[1];

            const response = await dispatch(outletSettingsAPI('contact', data));
            if (response.success) setDefaultValues(updateFormValues(response.outlet));
            return response;
        },
        [dispatch, updateFormValues]
    );

    const { control, errors, watch, setValue } = useFormActionsHandler({
        onFormSaveApi,
        defaultValues,
        onSuccessCallback,
    });

    return (
        <>
            <EuiForm component='form'>
                <FormGroupDescription
                    title={restaurantDescriptions?.outlet_name?.display_name}
                    description={restaurantDescriptions?.outlet_name?.description}
                >
                    {languages?.map((item, index) => (
                        <React.Fragment key={index.toString()}>
                            <TextField
                                errors={errors}
                                isInvalid={errors?.translations?.name?.hasOwnProperty(item.language_id)}
                                error={
                                    errors?.translations?.name?.hasOwnProperty(item.language_id) &&
                                    errors.translations.name[item.language_id]
                                        ? errors.translations.name[item.language_id].message
                                        : ''
                                }
                                label={index === 0 ? <strong>Name</strong> : ''}
                                name={`translations.name.${item.language_id}`}
                                placeholder={`Enter the name of the outlet in ${item.language_label}`}
                                rules={{ required: 'Please enter name' }}
                                control={control}
                            />
                            <EuiSpacer />
                        </React.Fragment>
                    ))}
                </FormGroupDescription>

                <FormGroupDescription
                    title={restaurantDescriptions?.contact_number_of_your_outlet?.display_name}
                    description={restaurantDescriptions?.contact_number_of_your_outlet?.description}
                >
                    <MobileNumber
                        watch={watch}
                        setValue={setValue}
                        label={'Contact number'}
                        isInvalid={errors['contact_number']}
                        errors={errors}
                        placeholder='Enter Customer number'
                        name={'contact_number'}
                        control={control}
                        rules={true}
                    />
                </FormGroupDescription>
            </EuiForm>
            <EuiSpacer />
        </>
    );
};

export default ContactDetails;
