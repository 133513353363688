import React from 'react';
import { MainLayout } from '../../components/layout/mainLayout/mainLayout';
import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui';
import { CustomerOrderList } from '../../components/customers/customerOrderList';

const CustomerOrdersPage = () => {
    return (
        <>
            <MainLayout backgroundColorChange={true}>
                <EuiFlexGroup direction='column'>
                    <EuiFlexItem>
                        <CustomerOrderList />
                    </EuiFlexItem>
                </EuiFlexGroup>
            </MainLayout>
        </>
    );
};

export default CustomerOrdersPage;
