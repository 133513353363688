import { EuiButton } from '@elastic/eui';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { userHasPermission } from '../../helper/auth';
import AddUserFlyout from './addUserFlyout';

const AddUser = ({ setPermissionUpdate }) => {
    const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
    const permissions = useSelector((state) => state.permission.permission);

    return (
        <>
            {isFlyoutVisible && (
                <AddUserFlyout setIsFlyoutVisible={setIsFlyoutVisible} setPermissionUpdate={setPermissionUpdate} />
            )}
            {userHasPermission(permissions, '/users', 'write') && (
                <EuiButton onClick={() => setIsFlyoutVisible(true)}>Add user</EuiButton>
            )}
        </>
    );
};

export default AddUser;
