import { EuiFlexGroup, EuiFlexItem, EuiSpacer } from '@elastic/eui';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import FormGroupDescription from '../../../../../components/formGroupDescription';
import LanguageBannerImage from '../../../../Banners/Components/LanguageBannerImage';

const CategoryImage = () => {
    const {
        control,
        watch,
        setValue,
        formState: { isDirty },
    } = useFormContext();
    const [selectedImages, setSelectedImages] = useState({});

    const imageUrl = useWatch({
        control,
        name: `editCategory.category_image_url`,
    });

    useEffect(() => {
        if (!isEmpty(imageUrl)) {
            setSelectedImages(imageUrl);
        } else {
            setSelectedImages({});
        }
    }, [watch, isDirty, imageUrl]);

    return (
        <>
            <FormGroupDescription title='Category image'>
                {!isEmpty(selectedImages) ? (
                    <>
                        <EuiFlexGroup>
                            <EuiFlexItem grow={false}>
                                <Controller
                                    control={control}
                                    name='apply_mask'
                                    render={({ field }) => {
                                        return (
                                            <input
                                                type='checkbox'
                                                label='Apply mask'
                                                id='apply_mask'
                                                checked={field.value}
                                                onChange={field.onChange}
                                            />
                                        );
                                    }}
                                />
                            </EuiFlexItem>
                            <EuiFlexItem grow={false}>
                                <label htmlFor='apply_mask'>Apply Mask</label>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </>
                ) : null}
                <EuiSpacer size='s' />
                <LanguageBannerImage
                    withRemove={true}
                    buttonLabel={'Select an image'}
                    selectedImgButtonLabel='Change Category Image'
                    imageType={'categories'}
                    selectedImages={selectedImages}
                    name='editCategory.category_image_url'
                    defaultImageValue={watch(`editCategory.category_image_url`)}
                    setValue={setValue}
                    buttonPosition='default'
                    aspectRatio={{
                        width: 1,
                        height: 1.066,
                    }}
                    setSelectedImages={setSelectedImages}
                />
            </FormGroupDescription>
        </>
    );
};

export default React.memo(CategoryImage);
