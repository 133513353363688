export const flatToHierarchy = (flat) => {
    let treeStructeredData = [];

    flat.map((category) => {
        let innerObject = {
            id: category.category_id,
            translations: category.translations,
            internal_name: category.internal_name,
            items: category.items,
            in_stock: category.in_stock,
            hidden: category.hidden,
        };

        let innerArray = [];

        if (category.sub_categories.length !== undefined) {
            category.sub_categories.map((subCategories) =>
                innerArray.push({
                    id: subCategories.sub_category_id,
                    translations: subCategories.translations,
                    internal_name: subCategories.internal_name,
                    items: subCategories.items,
                    in_stock: subCategories.in_stock,
                    hidden: subCategories.hidden,
                })
            );
        }

        if (innerArray.length !== 0) {
            innerObject['children'] = innerArray;
        }

        treeStructeredData.push(innerObject);
        return null;
    });

    return treeStructeredData;
};
