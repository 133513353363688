import React, { useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import API from '../../api/axios/API';
import { userHasPermission } from '../../helper/auth';
import { EuiDataGrid, EuiText, EuiButtonEmpty } from '@elastic/eui';

export const NotificationTable = ({ setRefetch, openFly, getAllNotificationsDetails }) => {
    // all state
    const dispatch = useDispatch();
    const languageID = useSelector((state) => state.language.languageId);
    const permissions = useSelector((state) => state.permission.permission);

    let columns = [
        {
            id: 'title',
            display: <div className='eui-textTruncate'>Title</div>,
            defaultSortDirection: 'asc',
        },
        {
            id: 'description',
            display: <div className='eui-textTruncate'>Description</div>,
        },
        {
            id: 'time',
            display: <div className='eui-textTruncate'>Time</div>,
        },
        {
            id: 'screen',
            display: <div className='eui-textTruncate'>Screen</div>,
        },
        {
            id: 'edit',
            display: <div className='eui-textTruncate'>Edit / Resend</div>,
        },
    ];

    if (!userHasPermission(permissions, '/notifications', 'write')) {
        columns = [
            {
                id: 'title',
                display: <div className='eui-textTruncate'>Title</div>,
                defaultSortDirection: 'asc',
            },
            {
                id: 'description',
                display: <div className='eui-textTruncate'>Description</div>,
            },
            {
                id: 'time',
                display: <div className='eui-textTruncate'>Time</div>,
            },
            {
                id: 'screen',
                display: <div className='eui-textTruncate'>Screen</div>,
            },
        ];
    }

    // ** Sorting config
    const [sortingColumns, setSortingColumns] = useState([]);
    const onSort = useCallback(
        (sortingColumns) => {
            setSortingColumns(sortingColumns);
        },
        [setSortingColumns]
    );

    // method for set resend id on reducer
    const handleReset = (id) => {
        dispatch({
            type: 'set-reset-id',
            payload: {
                resendID: id,
            },
        });
        openFly();
    };
    // method for edit id on reducer
    const handleEdit = (id) => {
        dispatch({
            type: 'set-edit-id',
            payload: {
                editID: id,
            },
        });
        openFly();
    };

    // delete notification
    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this offer ?')) {
            try {
                const deleteNotification = await API.delete(`/restaurants/:restaurantId/notifications/${id}`);
                if (deleteNotification.success) {
                    alert('Notification successfully delete');
                }
                setRefetch((preState) => !preState);
            } catch (e) {
                window.alert('Not deleted, please try later.');
            }
        }
    };

    // Column visibility
    const [visibleColumns, setVisibleColumns] = useState(() => columns.map(({ id }) => id)); // initialize to the full set of columns
    let notificationData = getAllNotificationsDetails
        ? getAllNotificationsDetails.notifications.data.map((item) => {
              return {
                  title: <>{<EuiText key={item.id + '_title'}>{item.translations.title[languageID]}</EuiText>}</>,
                  description: (
                      <>
                          {
                              <EuiText key={item.id + '_description'}>
                                  {item.translations.description[languageID]}
                              </EuiText>
                          }
                      </>
                  ),
                  time: (
                      <>
                          {
                              <EuiText key={item.id + 'time'}>
                                  {moment(item.time_to_send).format('DD-MM-YYYY  hh:mm a')}
                              </EuiText>
                          }
                      </>
                  ),
                  screen: <>{<EuiText key={item.id + '_coupon_code'}>{item.screen}</EuiText>}</>,
                  edit: (
                      <>
                          {
                              <EuiText key={item.id + '_edit'}>
                                  {' '}
                                  {moment(item.time_to_send).isBefore(moment()) ? (
                                      <EuiButtonEmpty
                                          size='s'
                                          iconType='documentEdit'
                                          onClick={() => handleReset(item.id)}
                                      >
                                          {' '}
                                          Resend{' '}
                                      </EuiButtonEmpty>
                                  ) : (
                                      <EuiButtonEmpty
                                          size='s'
                                          iconType='documentEdit'
                                          onClick={() => handleEdit(item.id)}
                                      >
                                          Edit
                                      </EuiButtonEmpty>
                                  )}
                                  <EuiButtonEmpty size='s' iconType='cross' onClick={() => handleDelete(item.id)}>
                                      Delete
                                  </EuiButtonEmpty>
                              </EuiText>
                          }
                      </>
                  ),
              };
          })
        : [];

    const renderCellValue = useMemo(() => {
        return ({ rowIndex, columnId, setCellProps }) => {
            return notificationData.hasOwnProperty(rowIndex) ? notificationData[rowIndex][columnId] : null;
        };
    }, [notificationData]);

    // set all notification
    dispatch({
        type: 'set-all-notification',
        payload: {
            allNotification: getAllNotificationsDetails.notifications.data,
        },
    });

    return (
        <>
            <EuiDataGrid
                aria-label='All Notificatin List'
                columns={columns}
                columnVisibility={{ visibleColumns, setVisibleColumns }}
                rowCount={notificationData.length}
                renderCellValue={renderCellValue}
                inMemory={{ level: 'sorting' }}
                sorting={{ columns: sortingColumns, onSort }}
                height={notificationData?.length >= 1 ? '70vh' : 'auto'}
            />
        </>
    );
};
