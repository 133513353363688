import API from './axios/API';

export const getDashboardCompareData = (outletString, startDate, endDate) => {
    return async (dispatch, getState) => {
        let response;

        try {
            response = await API.get(
                `/restaurants/:restaurantId/dashboard?${outletString}&start_date=${startDate.format(
                    'YYYY-MM-DD'
                )}&end_date=${endDate.format('YYYY-MM-DD')}`
            );
        } catch (error) {
            response = error;
        }

        return response;
    };
};
