import API from '../axios/API';

export const bannerOutletSequence = (outletId, data) => {
    return async (dispatch, getState) => {
        let response;

        try {
            response = await API.patch(`restaurants/:restaurantId/outlets/${outletId}/banners/change/sequence`, data);
        } catch (error) {
            response = error;
        }

        return response;
    };
};
