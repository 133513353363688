/* global localStorage */
import * as types from '../types/theme';

const themeInit = (localStorage.getItem('theme') || 'light').toString();
const initialState = {
    theme: themeInit,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.SET_THEME:
            localStorage.setItem('theme', action.payload);
            return {
                theme: action.payload,
            };
        default:
            return state;
    }
};
