import React from 'react';
import { MainLayout } from '../../components/layout/mainLayout/mainLayout';
import CustomerList from './Components/CustomerList';

const CustomersPage = () => {
    return (
        <>
            <MainLayout title='Customers Information'>
                <CustomerList />
            </MainLayout>
        </>
    );
};

export default CustomersPage;
