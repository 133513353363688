import React from 'react';
import { EuiButton, EuiModal, EuiModalBody, EuiModalHeader, EuiModalHeaderTitle, EuiText } from '@elastic/eui';

const UploadImageErrorMsg = ({ message, onClose }) => (
    <EuiModal onClose={onClose}>
        <EuiModalHeader>
            <EuiModalHeaderTitle>
                <h1>Error</h1>
            </EuiModalHeaderTitle>
        </EuiModalHeader>
        <EuiModalBody>
            <EuiText>
                <p>{message}</p>
            </EuiText>
            <EuiButton onClick={onClose} style={{ marginTop: '16px' }} fill>
                Close
            </EuiButton>
        </EuiModalBody>
    </EuiModal>
);

export default UploadImageErrorMsg;
