import { EuiFlexGroup, EuiFlexItem, EuiPanel, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

const UnlinkedItems = ({ validationFailedItems }) => {
    const languageId = useSelector((state) => state.language.languageId);

    const goToViolation = (id) => {
        const violation = document.getElementById(id);
        if (violation) {
            violation.scrollIntoView();
        }
    };

    useEffect(() => {
        goToViolation(`item_${validationFailedItems?.items?.id}`);
        goToViolation(`variant_${validationFailedItems?.variants?.id}`);
        goToViolation(`addon_${validationFailedItems?.addon?.addonGroupId}`);
    }, [validationFailedItems]);

    return (
        <>
            <EuiSpacer size='xs' />
            <EuiPanel>
                <EuiFlexGroup direction='column' gutterSize='xs' alignItems='flexStart' justifyContent='center'>
                    {validationFailedItems?.items && (
                        <EuiFlexItem grow={false}>
                            <EuiTitle size='xs'>
                                <h3 style={{ color: '#FF6961' }}>Items are not linked</h3>
                            </EuiTitle>
                        </EuiFlexItem>
                    )}
                    {validationFailedItems?.category?.internal_name && (
                        <EuiFlexItem grow={false}>
                            <EuiTitle size='s'>
                                <h1>Unlinked Item Category: {validationFailedItems.category.internal_name}</h1>
                            </EuiTitle>
                        </EuiFlexItem>
                    )}
                    {validationFailedItems?.items?.internal_name && (
                        <EuiFlexItem grow={false}>
                            <EuiText>Unlinked Item: {validationFailedItems.items.internal_name}</EuiText>
                        </EuiFlexItem>
                    )}
                    {validationFailedItems?.variants && (
                        <EuiFlexItem grow={false}>
                            <EuiTitle size='xs'>
                                <h3 style={{ color: '#FF6961' }}>Variants are not linked</h3>
                            </EuiTitle>
                        </EuiFlexItem>
                    )}
                    {validationFailedItems?.variants?.translations && (
                        <EuiFlexItem grow={false}>
                            <EuiText>
                                Unlinked Variants: {validationFailedItems?.variants?.translations.title[languageId]}
                            </EuiText>
                        </EuiFlexItem>
                    )}
                    {!isEmpty(validationFailedItems?.addon) && (
                        <>
                            <EuiFlexItem grow={false}>
                                <EuiTitle size='xs'>
                                    <h3 style={{ color: '#FF6961' }}>Addon is not linked</h3>
                                </EuiTitle>
                            </EuiFlexItem>
                            <EuiFlexItem grow={false}>
                                <EuiText>
                                    Unlinked Addon Group:{' '}
                                    {validationFailedItems?.addon?.label
                                        ? validationFailedItems.addon.label
                                        : validationFailedItems?.addon?.internal_name}
                                </EuiText>
                            </EuiFlexItem>
                        </>
                    )}
                </EuiFlexGroup>
            </EuiPanel>
        </>
    );
};

export default React.memo(UnlinkedItems);
