import React from 'react';
import { useSelector } from 'react-redux';
import { MainLayout } from '../../components/layout/mainLayout/mainLayout';
import TableManagementScreen from '../../components/TableManagement/TableManagementScreen';
import { NoOutletMessage } from '../../utils/noOutletMessage';

const TableManagementPage = () => {
    const outletId = useSelector((state) => state.outlet.selectedOutletId);
    return (
        <MainLayout
            title='Table Management'
            outlet
            backgroundColorChange={true}
            quickNavigations={[
                {
                    href: '/bill-payment',
                    label: 'Bill Payments',
                },
                {
                    href: '/live-orders',
                    label: 'Live Orders',
                },
                {
                    href: '/table-management/past-table-session',
                    label: 'Past Table Session',
                },
            ]}
        >
            {outletId ? <TableManagementScreen /> : <NoOutletMessage />}
        </MainLayout>
    );
};

export default TableManagementPage;
