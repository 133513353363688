import { isEmpty } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { MainLayout } from '../../components/layout/mainLayout/mainLayout';
import Reports from '../../components/reports/Reports';
import { authModule } from '../../helper/authModulePermission';
import { NoOutletMessage } from '../../utils/noOutletMessage';

const ReportsPage = () => {
    const selectedOutlet = useSelector((state) => state.outlet.selectedOutlet);
    const permissions = useSelector((state) => state.permission.permission);

    return (
        <MainLayout title='Reports' allowAllOutletsSelection={true} backgroundColorChange={true}>
            {authModule(permissions, 'read', 'reports') && !isEmpty(selectedOutlet) ? <Reports /> : <NoOutletMessage />}
        </MainLayout>
    );
};

export default ReportsPage;
