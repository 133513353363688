import { EuiText } from '@elastic/eui';
import React from 'react';

const RupeeSymbol = (props) => {
    const { size } = props;

    //  fontFamily: 'Roboto',
    return (
        <EuiText size='xs' style={{ display: 'inline-block' }}>
            ₹
        </EuiText>
    );
};

export default RupeeSymbol;
