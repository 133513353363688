import React from 'react';
import { EuiFormRow, EuiFieldText } from '@elastic/eui';
import { Controller } from 'react-hook-form';

const ColorField = ({ label, name, placeholder, rules, errors, control, fullWidth, defaultValue }) => {
    return (
        <Controller
            render={({ field }) => (
                <EuiFormRow
                    label={label}
                    isInvalid={errors.name}
                    error={errors.name ? errors.name.message : ''}
                    fullWidth={fullWidth ? fullWidth : false}
                >
                    <EuiFieldText value={field.value} type='color' onChange={field.onChange} />
                </EuiFormRow>
            )}
            name={name}
            control={control}
            rules={rules}
            fullWidth={true}
            placeholder={placeholder}
            defaultValue={defaultValue ? defaultValue : '#000000'}
        />
    );
};

export default ColorField;
