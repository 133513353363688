export const SET_OUTLET_LIST = 'set-out-list';
export const SET_CATEGORY_OPTIONS = 'set-category-options';
export const SET_CATEGORY_LIST = 'set-outlet-category-list';
export const SET_SELECTED_OUTLET = 'set-selected-outlet';
export const SET_OUTLET_ACTIVE_CATEGORY_ID = 'set-outlet-active-category-id';
export const SET_ITEM_LIST = 'set-item-list';
export const SET_CATEGORY_MAIN_LIST = 'set-category-main-list';
export const SET_OUTLET_ACTIVE_ITEM_ID = 'set-outlet-active-item-id';
export const SET_OUTLET_SELECTED_ITEM = 'set-outlet-selected-item';
export const SET_EDIT_OUTLET_ID = 'set-edit-outlet-id';
export const SET_SELECTED_OUTLET_ID = 'set-selected-outlet-id';
export const SET_SUBMIT_LOADER = 'set-submit-loader';
export const SET_ALL_OUTLETS = 'set_all_outlets';
export const GET_OUTLET_ORDERING_MODES = 'get_outlet_ordering_modes';
export const SET_ORDERING_MODES = 'set_ordering_modes';
export const SET_OUTLET_DATA = 'set_outlet_data';
export const ALL_OUTLET = 'all_outlet';
