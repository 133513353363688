import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOutletOrderingModes } from '../../../../api/outlet/fetchOutletOrderingModes';
import { updateOutletOrderingModes } from '../../../../components/settings/outlet-form/utils';
import {
    EuiCard,
    EuiDragDropContext,
    euiDragDropReorder,
    EuiDraggable,
    EuiDroppable,
    EuiFlexGroup,
    EuiFlexItem,
    EuiIcon,
    EuiPanel,
    EuiSpacer,
    EuiText,
} from '@elastic/eui';
import FormGroupDescription from '../../../../components/formGroupDescription';
import useOutletFormValues from '../../Hooks/useOutletFormValues';
import useFormActionsHandler from '../../../../hooks/useFormActionsHandler';

function findUnique(arr, predicate) {
    let found = {};
    arr.forEach((d) => {
        if (d.is_enabled) {
            found[predicate(d)] = d;
        }
    });
    return Object.keys(found).map((key) => found[key]);
}

const DisplayRankModes = () => {
    const dispatch = useDispatch();
    const outletId = useSelector((state) => state.outlet.selectedOutletId);
    const outletOrderingModes = useSelector((state) => state?.outlet?.outletOrderingModes?.ordering_modes);
    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);
    const discardState = useSelector((state) => state.uiPortalStateReducer.portalState.discardStateHandler);
    const [list, setList] = useState([]);

    const updateFormValues = useCallback(() => {
        if (outletOrderingModes?.length) {
            const uniqueOrderingModes = findUnique(outletOrderingModes, (d) => d.tab);
            // const sortByDisplayRank = uniqueOrderingModes.sort((a, b) => (a.display_rank > b.display_rank ? 1 : -1));
            setList(uniqueOrderingModes);

            return {
                ordering_modes: uniqueOrderingModes,
            };
        }
    }, [outletOrderingModes]);

    const getOrderingModes = useCallback(async () => {
        dispatch(fetchOutletOrderingModes(outletId));
    }, [dispatch, outletId]);

    const { defaultValues, setDefaultValues, onSuccessCallback } = useOutletFormValues({
        updateFormValues,
        sideEffectForReset: outletOrderingModes,
    });

    useEffect(() => {
        getOrderingModes();
    }, [getOrderingModes]);

    const onFormSaveApi = useCallback(
        async (data) => {
            let structuredModes = [];

            data.ordering_modes.forEach((orderingMode, index) => {
                let obj = {
                    restaurant_ordering_mode_id: orderingMode.restaurant_ordering_mode_id,
                    display_rank: index + 1,
                    is_enabled: orderingMode.is_enabled,
                    translations: orderingMode.translations,
                    show_on_home_screen: true,
                };

                structuredModes.push(obj);
            });
            const response = await updateOutletOrderingModes(
                outletId,
                { ordering_modes: structuredModes },
                'ordering_mode'
            );

            if (response.success) setDefaultValues(updateFormValues(response.outlet));
            return response;
        },
        [dispatch, getOrderingModes, outletId]
    );

    const { setValue } = useFormActionsHandler({ onFormSaveApi, defaultValues, onSuccessCallback });

    useEffect(() => {
        if (outletOrderingModes?.length >= 0) {
            const uniqueOrderingModes = findUnique(outletOrderingModes, (d) => d.tab);
            setList(uniqueOrderingModes);
        }
    }, [discardState]);

    const onDragEnd = useCallback(
        ({ source, destination }) => {
            if (source && destination) {
                const items = euiDragDropReorder(list, source.index, destination.index);

                setValue('ordering_modes', items, { shouldDirty: true });

                setList(items);
            }
        },
        [list, setValue]
    );

    return (
        <FormGroupDescription
            title={restaurantDescriptions?.ordering_mode?.display_name}
            description={restaurantDescriptions?.ordering_mode?.description}
        >
            <EuiDragDropContext onDragEnd={onDragEnd}>
                <EuiDroppable droppableId='CUSTOM_HANDLE_DROPPABLE_AREA' spacing='m' withPanel>
                    {list?.length ? (
                        list.map((mode, idx) => {
                            return (
                                <EuiDraggable
                                    spacing='m'
                                    key={mode.id.toString()}
                                    index={idx}
                                    draggableId={mode.id.toString()}
                                    customDragHandle={true}
                                    hasInteractiveChildren={true}
                                >
                                    {(provided) => (
                                        <>
                                            <EuiSpacer size={`${idx !== 0 ? 's' : null}`} />
                                            <EuiPanel paddingSize='s'>
                                                <EuiFlexGroup
                                                    justifyContent='spaceBetween'
                                                    alignItems='center'
                                                    gutterSize='s'
                                                >
                                                    <EuiFlexItem>
                                                        <EuiFlexGroup
                                                            justifyContent='spaceBetween'
                                                            alignItems='center'
                                                            gutterSize='s'
                                                        >
                                                            {' '}
                                                            <EuiFlexItem grow={false}>
                                                                <EuiFlexItem
                                                                    color='transparent'
                                                                    paddingSize='s'
                                                                    {...provided.dragHandleProps}
                                                                    aria-label='Drag Handle'
                                                                >
                                                                    <EuiIcon type='grab' />
                                                                </EuiFlexItem>
                                                            </EuiFlexItem>
                                                            <EuiFlexItem>
                                                                <EuiText style={{ textTransform: 'capitalize' }}>
                                                                    {mode.tab?.includes('_')
                                                                        ? mode.tab.replace('_', ' ')
                                                                        : mode.tab}
                                                                </EuiText>
                                                            </EuiFlexItem>
                                                        </EuiFlexGroup>
                                                    </EuiFlexItem>
                                                </EuiFlexGroup>
                                            </EuiPanel>
                                        </>
                                    )}
                                </EuiDraggable>
                            );
                        })
                    ) : (
                        <EuiCard title='Ordering modes not found for this outlet' />
                    )}
                </EuiDroppable>
            </EuiDragDropContext>
        </FormGroupDescription>
    );
};

export default React.memo(DisplayRankModes);
