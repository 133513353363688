import {
    EuiCard,
    EuiCollapsibleNavGroup,
    EuiFlexGroup,
    EuiFlexItem,
    EuiIcon,
    EuiLink,
    EuiSpacer,
    EuiText,
    EuiTitle,
} from '@elastic/eui';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import CheckBoxField from '../../../../components/Form/CheckBoxField';
import NumberField from '../../../../components/Form/NumberField';
import SwitchField from '../../../../components/Form/SwitchField';

const EarningsDelay = (props) => {
    const { setValue, control, watch, errors, orderingModes } = props;
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [cardDescription, setCardDescription] = useState([]);
    const ordering_mode_cashback_configurations = useWatch({
        control,
        name: 'ordering_mode_cashback_configurations',
    });
    const cashback_settlement_delay_in_hrs = useWatch({
        control,
        name: 'cashback_settlement_delay_in_hrs',
    });

    const cashback_settlement_delay_in_mins = useWatch({
        control,
        name: 'cashback_settlement_delay_in_mins',
    });

    const is_same_config_for_earnings_delay = useWatch({
        control,
        name: 'is_same_config_for_earnings_delay',
    });

    useEffect(() => {
        if (ordering_mode_cashback_configurations?.length) {
            let show_earnings_delay = false;
            for (const orderingMode of ordering_mode_cashback_configurations) {
                if (orderingMode.cashback_settlement_delay) {
                    show_earnings_delay = true;
                }
            }
            setValue('show_earnings_delay', show_earnings_delay);
            setIsNavOpen(show_earnings_delay);
        }
    }, [ordering_mode_cashback_configurations]);

    useEffect(() => {
        if (is_same_config_for_earnings_delay) {
            setCardDescription((prevState) => [
                `${cashback_settlement_delay_in_hrs} Hours and ${cashback_settlement_delay_in_mins} Minutes Delay for all modes`,
            ]);
        } else {
            setCardDescription((prevState) => [`Delay active for different ordering modes`]);
        }
    }, [is_same_config_for_earnings_delay]);

    return (
        <>
            <EuiCard
                layout='horizontal'
                title={
                    <EuiFlexGroup direction='row' justifyContent='spaceBetween' alignItems='center' gutterSize='xs'>
                        <EuiFlexItem grow={10}>
                            <EuiFlexGroup justifyContent='flexStart' alignItems='center' direction='row' gutterSize='s'>
                                {props?.icon ? <EuiFlexItem grow={false}>{props.icon}</EuiFlexItem> : null}
                                <EuiFlexItem grow={10}>
                                    <EuiFlexGroup
                                        justifyContent='flexStart'
                                        direction='column'
                                        alignItems='flexStart'
                                        gutterSize='xs'
                                    >
                                        <EuiFlexItem>
                                            <EuiFlexGroup
                                                direction='row'
                                                justifyContent='flexStart'
                                                alignItems='center'
                                                gutterSize='s'
                                            >
                                                <EuiFlexItem
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => setIsNavOpen((prevState) => !prevState)}
                                                >
                                                    <EuiTitle size='s'>
                                                        <h2>Earnings Delay</h2>
                                                    </EuiTitle>
                                                </EuiFlexItem>
                                                <EuiFlexItem grow={false}>
                                                    {!isNavOpen ? (
                                                        <EuiLink
                                                            style={{ fontSize: '12px' }}
                                                            onClick={() => {
                                                                setIsNavOpen((prevState) => !prevState);
                                                            }}
                                                        >
                                                            <SwitchField
                                                                name='show_earnings_delay'
                                                                control={control}
                                                                label=''
                                                                setValue={setValue}
                                                            />
                                                        </EuiLink>
                                                    ) : null}
                                                </EuiFlexItem>
                                            </EuiFlexGroup>
                                        </EuiFlexItem>
                                        <EuiFlexItem>
                                            {cardDescription?.map((description, idx) => (
                                                <EuiText style={{ display: 'inline-flex' }} size='xs' key={idx}>
                                                    {description}
                                                </EuiText>
                                            ))}
                                        </EuiFlexItem>
                                    </EuiFlexGroup>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </EuiFlexItem>
                        <EuiFlexItem>
                            {isNavOpen ? (
                                <EuiFlexItem
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setIsNavOpen((prevState) => !prevState);
                                        setValue('show_earnings_delay', false, { shouldDirty: true });
                                    }}
                                >
                                    <EuiIcon type='cross' color='danger' />
                                </EuiFlexItem>
                            ) : null}
                        </EuiFlexItem>
                    </EuiFlexGroup>
                }
            >
                <EuiCollapsibleNavGroup
                    style={{ marginBottom: isNavOpen ? '0' : '-35px' }}
                    title={<></>}
                    titleSize='xs'
                    isCollapsible={true}
                    paddingSize='none'
                    initialIsOpen={isNavOpen}
                    forceState={isNavOpen ? 'open' : 'closed'}
                    arrowDisplay={'none'}
                >
                    <CheckBoxField
                        name='is_same_config_for_earnings_delay'
                        control={control}
                        label='Same Configurations for all modes'
                        setValue={setValue}
                    />
                    <EuiSpacer size='s' />
                    {!watch('is_same_config_for_earnings_delay') ? (
                        <>
                            {/* {watch('ordering_mode_cashback_configurations')?.map( */}
                            {orderingModes?.map((orderingMode, index) => {
                                return (
                                    <React.Fragment key={orderingMode.restaurant_ordering_mode_id.toString()}>
                                        <EuiFlexGroup
                                            style={{ marginTop: '12px', maxWidth: '30vw' }}
                                            direction='column'
                                            justifyContent='flexStart'
                                            alignItems='flexStart'
                                            gutterSize='s'
                                        >
                                            <EuiFlexItem>
                                                <EuiFlexGroup>
                                                    <EuiFlexItem>
                                                        <EuiText style={{ fontWeight: 700 }} size='xs'>
                                                            {`Earnings delay for ${
                                                                orderingMode.display_name?.includes('_')
                                                                    ? orderingMode.display_name.replace('_', ' ')
                                                                    : orderingMode.display_name
                                                            }`}
                                                        </EuiText>
                                                    </EuiFlexItem>
                                                </EuiFlexGroup>
                                            </EuiFlexItem>
                                            <EuiFlexItem>
                                                <EuiFlexGroup>
                                                    <EuiFlexItem>
                                                        <NumberField
                                                            label={''}
                                                            errors={errors}
                                                            // name='delay_time_hrs'
                                                            name={`orderingModes[${index}].cashback_settlement_delay_in_hrs`}
                                                            // rules={{
                                                            //     required: 'Please Enter Intervention Time',
                                                            // }}
                                                            control={control}
                                                            className='input-remove-hyphen'
                                                            prepend='Hours'
                                                            min={0}
                                                        />
                                                    </EuiFlexItem>
                                                    <EuiFlexItem>
                                                        <NumberField
                                                            label={''}
                                                            errors={errors}
                                                            // name='delay_time_mins'
                                                            name={`orderingModes[${index}].cashback_settlement_delay_in_mins`}
                                                            // rules={{
                                                            //     required: 'Please Enter Intervention Time',
                                                            // }}
                                                            control={control}
                                                            className='input-remove-hyphen'
                                                            prepend='Minutes'
                                                            min={0}
                                                        />
                                                    </EuiFlexItem>
                                                </EuiFlexGroup>
                                            </EuiFlexItem>
                                        </EuiFlexGroup>
                                        {/* <NumberField
                                        labelStyles={{ textTransform: 'capitalize' }}
                                        key={orderingMode.restaurant_ordering_mode_id.toString()}
                                        label={`Redeem cashback for  ${
                                            orderingMode.tab?.includes('_')
                                                ? orderingMode.tab.replace('_', ' ')
                                                : orderingMode.tab
                                        }`}
                                        errors={errors}
                                        // append='%'
                                        // name={`ordering_mode_cashback_configurations[${index}].cashback_redeem_percentage`}
                                        name={`tabOrderingModes[${index}].cashback_settlement_delay`}
                                        defaultValue={`${orderingMode?.cashback_settlement_delay}`}
                                        placeholder='Eg. 2h'
                                        rules={{
                                            required: 'Please Enter Earnings Settlement Delay ',
                                            // validate: validatePercentage,
                                        }}
                                        step='any'
                                        control={control}
                                    /> */}
                                    </React.Fragment>
                                );
                            })}
                        </>
                    ) : (
                        <>
                            <EuiFlexGroup
                                style={{ marginTop: '12px', maxWidth: '30vw' }}
                                direction='column'
                                justifyContent='flexStart'
                                alignItems='flexStart'
                                gutterSize='s'
                            >
                                <EuiFlexItem>
                                    <EuiText style={{ fontWeight: 700 }} size='xs'>
                                        Earnings delay
                                    </EuiText>
                                </EuiFlexItem>
                                <EuiFlexItem>
                                    <EuiFlexGroup>
                                        <EuiFlexItem>
                                            <NumberField
                                                label={''}
                                                errors={errors}
                                                // name='delay_time_hrs'
                                                name={`cashback_settlement_delay_in_hrs`}
                                                // rules={{
                                                //     required: 'Please Enter Intervention Time',
                                                // }}
                                                control={control}
                                                className='input-remove-hyphen'
                                                prepend='Hours'
                                                min={0}
                                            />
                                        </EuiFlexItem>
                                        <EuiFlexItem>
                                            <NumberField
                                                label={''}
                                                errors={errors}
                                                // name='delay_time_mins'
                                                name={`cashback_settlement_delay_in_mins`}
                                                // rules={{
                                                //     required: 'Please Enter Intervention Time',
                                                // }}
                                                control={control}
                                                className='input-remove-hyphen'
                                                prepend='Minutes'
                                                min={0}
                                            />
                                        </EuiFlexItem>
                                    </EuiFlexGroup>
                                </EuiFlexItem>
                            </EuiFlexGroup>

                            {/* <NumberField
                            label={'Redeem cashback'}
                            errors={errors}
                            name={`cashback_settlement_delay`}
                            placeholder='Eg. 2h'
                            rules={{
                                required: 'Please Enter Earnings Settlement Delay',
                                // validate: validatePercentage,
                            }}
                            // append='%'
                            step='any'
                            control={control}
                        /> */}
                        </>
                    )}
                </EuiCollapsibleNavGroup>
            </EuiCard>
        </>
    );
};

export default EarningsDelay;
