import * as types from '../types/user';

const initialState = {
    editUser: null,
    petPoojaSynced: { synced: true, message: '' },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.EDIT_USER:
            return {
                ...state,
                editUser: action.payload.editUser,
            };
        case types.SET_PET_POOJA_SYNCED:
            return {
                ...state,
                petPoojaSynced: action.payload.petPoojaSynced,
            };
        default:
            return state;
    }
};
