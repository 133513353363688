import React from 'react';
import { MainLayout } from '../../components/layout/mainLayout/mainLayout';
import Offer from '../../components/offer/offer';

const OffersPage = () => {
    return (
        <MainLayout backgroundColorChange={true} title='Offers'>
            <Offer />
        </MainLayout>
    );
};

export default OffersPage;
