import React, { useState, memo } from 'react';
import { Chart, BarSeries, Axis, Settings } from '@elastic/charts';
import { EUI_CHARTS_THEME_DARK, EUI_CHARTS_THEME_LIGHT } from '@elastic/eui/dist/eui_charts_theme';
import {
    EuiText,
    EuiFlexItem,
    EuiSpacer,
    EuiFlexGroup,
    EuiFilterButton,
    EuiFilterGroup,
    EuiHorizontalRule,
} from '@elastic/eui';
import { orderBy } from 'lodash';
import '@elastic/charts/dist/theme_light.css';
import { useSelector } from 'react-redux';

const TopOrdersChart = (props) => {
    const themeMode = useSelector((state) => state.theme.theme);
    const euiChartTheme = themeMode === 'light' ? EUI_CHARTS_THEME_LIGHT : EUI_CHARTS_THEME_DARK;
    const languageId = useSelector((state) => state.language.languageId);

    const [isCategoryFilterOn, setIsCategoryFilterOn] = useState(true);
    const [isRevenueFilterOn, setIsRevenueFilterOn] = useState(true);

    const toggleCategoryFilter = () => {
        setIsCategoryFilterOn(!isCategoryFilterOn);
    };

    const toggleRevenueFilter = () => {
        setIsRevenueFilterOn(!isRevenueFilterOn);
    };

    const formattedData = () => {
        let iterator = null;
        let field = '';

        if (isCategoryFilterOn) {
            field = isRevenueFilterOn ? 'total_price' : 'total_quantity';
            iterator = props.outlet.highest_selling_categories;
        } else {
            field = isRevenueFilterOn ? 'total_price' : 'item_quantity';
            iterator = props.outlet.highest_selling_items;
        }

        let innerArray = [];
        iterator.map((items) => {
            return innerArray.push({
                item: items.titles[languageId],
                total_sales: parseFloat(items[`${field}`] ? items[`${field}`] : 0),
            });
        });

        innerArray = orderBy(innerArray, ['total_sales'], ['desc']);
        return innerArray;
    };

    return (
        <>
            <EuiFlexGroup direction='column'>
                <EuiFlexItem>
                    <EuiText>
                        <h3>Top Selling</h3>
                    </EuiText>
                    <EuiSpacer size='s' />
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiFilterGroup size='s'>
                        <EuiFilterButton>Filter</EuiFilterButton>
                        <EuiFilterButton withNext hasActiveFilters={isCategoryFilterOn} onClick={toggleCategoryFilter}>
                            Category
                        </EuiFilterButton>
                        <EuiFilterButton hasActiveFilters={!isCategoryFilterOn} onClick={toggleCategoryFilter}>
                            Item
                        </EuiFilterButton>

                        <EuiFilterButton withNext hasActiveFilters={isRevenueFilterOn} onClick={toggleRevenueFilter}>
                            Revenue
                        </EuiFilterButton>
                        <EuiFilterButton hasActiveFilters={!isRevenueFilterOn} onClick={toggleRevenueFilter}>
                            Count
                        </EuiFilterButton>
                    </EuiFilterGroup>
                </EuiFlexItem>
                <EuiHorizontalRule />
                <EuiFlexItem>
                    <Chart size={{ height: 250 }}>
                        <Settings theme={euiChartTheme} rotation={90} showLegend={false} />
                        <BarSeries
                            id='orders'
                            name='Order Revenue'
                            data={formattedData()}
                            xAccessor='item'
                            yAccessors={['total_sales']}
                        />
                        <Axis id='bottom-axis' position={'left'} showGridLines={false} />
                        <Axis id='left-axis' position={'bottom'} />
                    </Chart>
                </EuiFlexItem>
            </EuiFlexGroup>
        </>
    );
};

export default memo(TopOrdersChart);
