import { EuiDataGrid, EuiFlexItem } from '@elastic/eui';
import React, { useMemo, useState } from 'react';
import Pagination from '../Form/Pagination';
import ContextMenu from '../Global/Pagination/ContextMenu';

const ReportsDataGrid = (props) => {
    const { onSort, setPagination, pagination, appState } = props;

    const handlePageChange = (page) => {
        setPagination({
            ...pagination,
            pageIndex: page,
        });
    };

    // Column visibility
    const [visibleColumns, setVisibleColumns] = useState(() => props.column.map(({ id }) => id)); // initialize to the full set of columns
    const renderCellValue = useMemo(() => {
        return ({ rowIndex, columnId, setCellProps }) => {
            return props.reportsData?.hasOwnProperty(rowIndex) ? props.reportsData[rowIndex][columnId] : null;
        };
    }, [props.reportsData]);

    const popOverItems = useMemo(() => {
        return [
            {
                key: '10 rows',
                onClick: (closePopover) => {
                    closePopover();
                    props.onChangeItemsPerPage(10);
                },
                iconSize: 10,
            },
            {
                key: '20 rows',
                onClick: (closePopover) => {
                    closePopover();
                    props.onChangeItemsPerPage(20);
                },
                iconSize: 20,
            },
            {
                key: '50 rows',
                onClick: (closePopover) => {
                    closePopover();
                    props.onChangeItemsPerPage(50);
                },
                iconSize: 50,
            },
            {
                key: '100 rows',
                onClick: (closePopover) => {
                    closePopover();
                    props.onChangeItemsPerPage(100);
                },
                iconSize: 100,
            },
        ];
    }, [props]);

    return (
        <>
            <EuiDataGrid
                aria-label='Data grid table for reports summary'
                columns={props.column}
                columnVisibility={{ visibleColumns, setVisibleColumns }}
                rowCount={props.reportsData.length}
                renderCellValue={renderCellValue}
                inMemory={{ level: 'sorting' }}
                sorting={{ columns: props.sortingColumns, onSort }}
                height={props.reportsData.length >= 1 && '60vh'}
            />
            {appState.resportsData.summary.total > appState.resportsData.summary.per_page && (
                <Pagination
                    totalRecord={appState.resportsData.summary.total}
                    pageSize={appState.resportsData.summary.per_page}
                    activePage={appState.resportsData.summary.current_page - 1}
                    handlePageChange={handlePageChange}
                />
            )}
            <EuiFlexItem grow={false}>
                <ContextMenu rowSize={pagination.pageSize} popOverItems={popOverItems} />
            </EuiFlexItem>
        </>
    );
};

export default ReportsDataGrid;
