import * as types from '../../reduxStore/types/outlet';
import APIV3 from '../axios/APIV3';

export const getOutletList = () => {
    return async (dispatch, getState) => {
        let response;
        let { language } = getState();

        try {
            response = await APIV3.get('restaurants/:restaurantId/users/outlets');
        } catch (error) {
            response = error;
        }

        if (response.success) {
            let outletArray = [];

            response.outlets.forEach((outlet) => {
                outletArray.push({
                    value: outlet.outlet_id,
                    inputDisplay: outlet.translations.name[language.languageId],
                    outlet_data: outlet,
                });
            });

            dispatch({
                type: types.SET_OUTLET_LIST,
                payload: {
                    outletList: outletArray,
                },
            });
        }

        return response;
    };
};
