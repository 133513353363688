import React, { useCallback } from 'react';
import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui';
import EltRider from '../../../../components/settings/outlet-form/elt_rider';
import DunzoRider from '../../../../components/settings/outlet-form/dunzo_rider';
import WeFastRider from '../../../../components/settings/outlet-form/wefast_rider';
import PorterRider from '../../../../components/settings/outlet-form/porter_rider';
import ShadowFaxRiders from '../../../../components/settings/outlet-form/shadow_fax_rider';
import { FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { outletSettingsAPI } from '../../../../api/outlet/outletSettingsAPI';
import FormGroupDescription from '../../../../components/formGroupDescription';
import ButtonGroup from '../../../../components/Form/ButtonGroup';
import useOutletFormValues from '../../Hooks/useOutletFormValues';
import useFormActionsHandler from '../../../../hooks/useFormActionsHandler';

const ThirdPartyRider = () => {
    const dispatch = useDispatch();
    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);

    const updateFormValues = useCallback((data) => {
        let defaultValue = {};

        defaultValue.enable_own_rider_service = data.settings.enable_own_rider_service;

        //WeFast rider info
        defaultValue.wefast_auth_token = data.third_party_rider_setting
            ? data.third_party_rider_setting.wefast_auth_token
            : '';
        defaultValue.wefast_call_back_url = data.third_party_rider_setting
            ? data.third_party_rider_setting.wefast_call_back_url
            : '';
        defaultValue.wefast_integration_available = data.third_party_rider_setting
            ? data.third_party_rider_setting.wefast_integration_available
                ? 1
                : 0
            : 0;

        //Dunzo Rider info
        defaultValue.dunzo_client_secret = data.third_party_rider_setting
            ? data.third_party_rider_setting.dunzo_client_secret
            : '';
        defaultValue.dunzo_client_id = data.third_party_rider_setting
            ? data.third_party_rider_setting.dunzo_client_id
            : '';
        defaultValue.dunzo_call_back_url = data.third_party_rider_setting
            ? data.third_party_rider_setting.dunzo_call_back_url
            : '';
        defaultValue.dunzo_integration_available = data.third_party_rider_setting
            ? data.third_party_rider_setting.dunzo_integration_available
                ? 1
                : 0
            : 0;

        //ELT rider info
        defaultValue.elt_api_key = data.third_party_rider_setting ? data.third_party_rider_setting.elt_api_key : '';
        defaultValue.elt_primary_address_id = data.third_party_rider_setting
            ? data.third_party_rider_setting.elt_primary_address_id
            : '';
        defaultValue.elt_call_back_url = data.third_party_rider_setting
            ? data.third_party_rider_setting.elt_call_back_url
            : '';
        defaultValue.elt_integration_available = data.third_party_rider_setting
            ? data.third_party_rider_setting.elt_integration_available
                ? 1
                : 0
            : 0;

        //porter
        defaultValue.porter_token = data.third_party_rider_setting ? data.third_party_rider_setting.porter_token : '';
        defaultValue.is_porter_integration_enabled = data.third_party_rider_setting
            ? data.third_party_rider_setting.is_porter_integration_enabled
                ? 1
                : 0
            : 0;

        // Shadow fax rider info
        defaultValue.shadowfax_token = data.third_party_rider_setting
            ? data.third_party_rider_setting.shadowfax_token
            : '';

        defaultValue.shadowfax_client_code = data.third_party_rider_setting
            ? data.third_party_rider_setting.shadowfax_client_code
            : '';

        defaultValue.is_shadowfax_integration_enabled = data.third_party_rider_setting
            ? parseInt(data.third_party_rider_setting.is_shadowfax_integration_enabled)
                ? 1
                : 0
            : 0;

        defaultValue.use_global_shadowfax_configurations =
            data?.third_party_rider_setting?.use_global_shadowfax_configurations;
        defaultValue.use_global_wefast_configurations =
            data?.third_party_rider_setting?.use_global_wefast_configurations;
        defaultValue.use_global_dunzo_configurations = data?.third_party_rider_setting?.use_global_dunzo_configurations;

        return defaultValue;
    }, []);

    const { defaultValues, setDefaultValues, onSuccessCallback } = useOutletFormValues({ updateFormValues });

    const onFormSaveApi = useCallback(
        async (data) => {
            if (data.use_global_dunzo_configurations) {
                delete data.dunzo_integration_available;
            }

            if (data.use_global_shadowfax_configurations) {
                delete data.is_shadowfax_integration_enabled;
            }

            if (data.use_global_wefast_configurations) {
                delete data.wefast_integration_available;
            }

            if (!data.is_shadowfax_integration_enabled && !data.use_global_shadowfax_configurations) {
                data.shadowfax_token = null;
                data.shadowfax_client_code = null;
            }

            if (!data.wefast_integration_available && !data.use_global_wefast_configurations) {
                data.wefast_auth_token = null;
                data.wefast_call_back_url = null;
            }

            if (!data.dunzo_integration_available && !data.use_global_dunzo_configurations) {
                data.dunzo_client_secret = null;
                data.dunzo_client_id = null;
                data.dunzo_call_back_url = null;
            }

            const response = await dispatch(outletSettingsAPI('rider_settings', data));
            if (response.success) setDefaultValues(updateFormValues(response.outlet));
            return response;
        },
        [dispatch, updateFormValues]
    );

    const { errors, control, watch, setValue, reset, isDirty } = useFormActionsHandler({
        onFormSaveApi,
        defaultValues,
        onSuccessCallback,
    });

    const methods = {
        watch,
        control,
        setValue,
        reset,
        formState: {
            errors,
            isDirty,
        },
    };

    return (
        <FormProvider {...methods}>
            <EuiFlexGroup direction='column'>
                <EuiFlexItem>
                    <FormGroupDescription
                        title={restaurantDescriptions?.in_house_riders?.display_name}
                        description={restaurantDescriptions?.in_house_riders?.description}
                    >
                        <ButtonGroup
                            idSelected={watch('enable_own_rider_service') ? '1' : '0'}
                            color='primary'
                            control={control}
                            setValue={setValue}
                            name='enable_own_rider_service'
                            watch={watch}
                            label={'Enable own rider service'}
                        />
                    </FormGroupDescription>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EltRider />
                </EuiFlexItem>
                <EuiFlexItem>
                    <DunzoRider />
                </EuiFlexItem>
                <EuiFlexItem>
                    <WeFastRider />
                </EuiFlexItem>
                <EuiFlexItem>
                    <PorterRider />
                </EuiFlexItem>
                <EuiFlexItem>
                    <ShadowFaxRiders />
                </EuiFlexItem>
            </EuiFlexGroup>
        </FormProvider>
    );
};

export default ThirdPartyRider;
