const initialState = {
    totalRequest: 0,
};

export const loading = (state = initialState, action) => {
    switch (action.type) {
        case 'START_LOADING':
            state.totalRequest++;
            return state;
        case 'FINISH_LOADING':
            state.totalRequest--;
            return state;
        default:
            return state;
    }
};

export const namespaced = true;
export default loading;
