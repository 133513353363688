import React, { useState, useCallback, useEffect } from 'react';
import { EuiText, EuiFlexGroup, EuiFlexItem, EuiCheckbox } from '@elastic/eui';
import { agmarkSign } from '../../../helper/agmarkSign';
import { useSelector, useDispatch } from 'react-redux';
import * as type from '../../../reduxStore/types/menu';
import { isEmpty } from 'lodash';

const CheckableItem = (props) => {
    const { item, isOutletMenu, isMapItem, upSellingSelection, itemList } = props;
    const languageId = useSelector((state) => state.language.languageId);
    const shiftItemList = useSelector((state) => state.menu.shiftItemList);
    const mapList = useSelector((state) => state.menu.mapItemList);
    const selectedItemEditUpsellingList = useSelector((state) => state.menu.selectedItemEditUpsellingList);
    const [itemSelected, setItemSelected] = useState(false);
    const removedItemEditUpsellingList = useSelector((state) => state.menu.removedItemEditUpsellingList);
    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedItemEditUpsellingList?.length && upSellingSelection) {
            const isItemSelected = selectedItemEditUpsellingList.filter(
                (upSellingItem) => upSellingItem?.item_id === item.item_id
            );

            if (isItemSelected?.length) {
                setItemSelected(true);
            }
        }
    }, [upSellingSelection]);

    const onChange = useCallback(
        (e, selectedOption) => {
            setItemSelected(e.target.checked);
            if (upSellingSelection) {
                let selectedList = [...selectedItemEditUpsellingList];

                if (e.target.checked) {
                    let objectExist = false;
                    if (!isEmpty(selectedOption)) {
                        itemList?.map((upSellingItem) => {
                            if (selectedOption.item_id == upSellingItem.item_id) {
                                selectedItemEditUpsellingList.map((item) => {
                                    if (item.item_id == selectedOption.item_id) {
                                        objectExist = true;
                                    }
                                });
                                if (!objectExist) {
                                    selectedList.push(upSellingItem);
                                }
                            }
                        });
                    }
                    if (selectedList?.length) {
                        const uniqueItem = selectedList.reduce(
                            (acc, curr) => (acc.find((v) => v.item_id === curr.item_id) ? acc : [...acc, curr]),
                            []
                        );

                        dispatch({
                            type: type.SET_UPSELLING_ITEM_EDIT_SELECTED_LIST,
                            payload: {
                                selectedItemEditUpsellingList: uniqueItem,
                            },
                        });
                    }
                } else {
                    let upSellingItemArray = [...selectedItemEditUpsellingList];

                    const remaningUpSellingItems = upSellingItemArray.filter(
                        (upSellingItem) => upSellingItem?.item_id !== selectedOption.item_id
                    );

                    dispatch({
                        type: type.REMOVE_UPSELLING_ITEM_EDIT_SELECTED_LIST,
                        payload: {
                            removedItemEditUpsellingList: [...removedItemEditUpsellingList, selectedOption?.item_id],
                        },
                    });
                    dispatch({
                        type: type.SET_UPSELLING_ITEM_EDIT_SELECTED_LIST,
                        payload: {
                            selectedItemEditUpsellingList: remaningUpSellingItems,
                        },
                    });
                }
            } else if (!isMapItem) {
                let itemList = [...shiftItemList];
                if (e.target.checked) {
                    let selectedMoveItem = {
                        item_id: item.item_id,
                        source_category_id: item.category_id,
                    };
                    itemList.push(selectedMoveItem);
                    dispatch({
                        type: type.SET_SHIFT_ITEM_LIST,
                        payload: {
                            shiftItemList: itemList,
                        },
                    });
                } else {
                    let items = itemList.filter(({ item_ids }) => {
                        return item_ids !== item.item_id;
                    });
                    dispatch({
                        type: type.SET_SHIFT_ITEM_LIST,
                        payload: {
                            shiftItemList: items,
                        },
                    });
                }
            } else {
                let itemList = [...mapList];
                if (e.target.checked) {
                    itemList.push(item.item_id);
                    dispatch({
                        type: type.SET_MAP_ITEM_LIST,
                        payload: itemList,
                    });
                } else {
                    let items = itemList.filter((id) => id !== item.item_id);
                    dispatch({
                        type: type.SET_MAP_ITEM_LIST,
                        payload: items,
                    });
                }
            }
        },
        [
            dispatch,
            isMapItem,
            item.category_id,
            item.item_id,
            itemList,
            mapList,
            removedItemEditUpsellingList,
            selectedItemEditUpsellingList,
            shiftItemList,
            upSellingSelection,
        ]
    );

    return (
        <EuiFlexGroup style={{ borderTop: '1px solid #D3DAE6' }}>
            <EuiFlexItem style={{ flexDirection: 'row' }}>
                <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                    {item?.price ? <div>₹{item.price}</div> : null}
                    <EuiCheckbox
                        id={`${item.category_id}_${item.item_id}`}
                        label=''
                        checked={itemSelected}
                        onChange={(e) => onChange(e, item)}
                    />
                    <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                        <EuiText>
                            <label htmlFor={`${item.category_id}_${item.item_id}`} style={{ cursor: 'pointer' }}>
                                {isOutletMenu ? item.translations.title[languageId] : item.internal_name}
                            </label>
                        </EuiText>
                        <div style={{ marginRight: '10px' }}>{item.food_type ? agmarkSign(item.food_type) : ''}</div>
                    </div>
                </div>
            </EuiFlexItem>
        </EuiFlexGroup>
    );
};

export default React.memo(CheckableItem);
