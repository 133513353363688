import { EuiCheckbox, EuiFlexGroup, EuiFlexItem, EuiText, htmlIdGenerator } from '@elastic/eui';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { agmarkSign } from '../../../helper/agmarkSign';

const AddonVariantLink = ({ option, handleVariantLinkChange, selectedAddon }) => {
    const isOutletMenu = useSelector((state) => state.menuReducer.isOutletMenu);
    const languageId = useSelector((state) => state.language.languageId);
    const [variantSelected, setVariantSelected] = useState(false);

    useEffect(() => {
        // init option checkbox status
        if (selectedAddon.linked_variants?.length) {
            for (const linked_variant of selectedAddon.linked_variants) {
                if (linked_variant === option.id) {
                    setVariantSelected(true);
                }
            }
        }
    }, [option.id, selectedAddon]);

    useEffect(() => {}, []);

    const onChange = useCallback(
        (event) => {
            const isChecked = event.target.checked;

            setVariantSelected(isChecked);

            handleVariantLinkChange(isChecked, option);
        },
        [handleVariantLinkChange, option]
    );

    return (
        <>
            <EuiFlexGroup gutterSize='s' alignItems='center' justifyContent='spaceBetween'>
                <EuiFlexItem grow={10} style={{ width: '100%' }}>
                    <EuiFlexGroup style={{ width: '100%' }}>
                        <EuiFlexItem grow={false}>
                            {option?.food_type ? agmarkSign(option.food_type) : null}
                        </EuiFlexItem>
                        <EuiFlexItem grow={10}>
                            <EuiText size='s'>{option.translations.title[languageId]}</EuiText>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiCheckbox
                        id={htmlIdGenerator()()}
                        label=''
                        checked={variantSelected}
                        disabled={isOutletMenu}
                        onChange={onChange}
                    />
                </EuiFlexItem>
            </EuiFlexGroup>
        </>
    );
};

export default React.memo(AddonVariantLink);
