import APIV3 from '../../../api/axios/APIV3';
import { SET_ORDERS_COUNT } from '../../../reduxStore/types/orders';
import { SET_TABLE_ORDER_DETAILS } from '../../../reduxStore/types/tableManagement';

export const updateTable = async (tableId, udpatedTable, outletId) => {
    let response;

    try {
        response = await APIV3.put(
            `restaurants/:restaurantId/outlets/${outletId}/outlet-tables/${tableId}`,
            udpatedTable
        );
    } catch (error) {
        response = error;
    }
    return response;
};

export const deleteTable = async (outledId, tableId) => {
    let response;

    try {
        response = await APIV3.delete(`restaurants/:restaurantId/outlets/${outledId}/outlet-tables/${tableId}`);
    } catch (error) {
        response = error;
    }

    return response;
};

export const createTable = async (outletId, data) => {
    let resposne;
    try {
        resposne = await APIV3.post(`restaurants/:restaurantId/outlets/${outletId}/outlet-tables`, data);
    } catch (error) {
        resposne = error;
    }

    return resposne;
};

export const fetchTableOrderDetails = (filterType) => {
    return async (dispatch, getState) => {
        let response;
        const { selectedOutletId } = getState().outlet;
        const activeTableId = getState().tableManagement.activeTableId;

        if (activeTableId) {
            try {
                if (!filterType) {
                    response = await APIV3.get(
                        `restaurants/:restaurantId/outlets/${selectedOutletId}/outlet-tables/${activeTableId}`
                    );
                } else {
                    response = await APIV3.get(
                        `restaurants/:restaurantId/outlets/${selectedOutletId}/outlet-tables/${activeTableId}?status=${filterType}`
                    );
                }

                dispatch({
                    type: SET_ORDERS_COUNT,
                    payload: {
                        all: parseInt(response.all_order_count),
                        placed: parseInt(response.new_order_count),
                        restaurant_accepted: parseInt(response.preparing_order_count),
                        restaurant_ready: parseInt(response.ready_order_count),
                        delivered_order_count: parseInt(response.delivered_order_count),
                    },
                });

                dispatch({
                    type: SET_TABLE_ORDER_DETAILS,
                    payload: {
                        orders: response?.orders?.data?.length ? response?.orders?.data : [],
                        current_session: {
                            ...response.current_session,
                            net_amount: response?.net_amount,
                            paid_amount: response?.paid_amount,
                            to_pay_amount: response?.to_pay_amount,
                        },
                        ordering_mode_type: response.ordering_mode_type,
                    },
                });
            } catch (error) {
                response = error;
            }
        }
        return response;
    };
};

export const fetchPastTableOrderDetails = (sessionId) => {
    return async (dispatch, getState) => {
        let response;
        const { selectedOutletId } = getState().outlet;

        try {
            response = await APIV3.get(
                `restaurants/:restaurantId/outlets/${selectedOutletId}/outlet-table-sessions/${sessionId}/orders`
            );

            dispatch({
                type: SET_TABLE_ORDER_DETAILS,
                payload: {
                    orders: response?.orders?.data?.length ? response?.orders?.data : [],
                    current_session: {
                        ...response.session,
                        net_amount: response?.session?.net_amount,
                        paid_amount: response?.session?.paid_amount,
                        to_pay_amount: response?.session?.to_pay_amount,
                    },
                    ordering_mode_type: response.ordering_mode_type,
                },
            });
        } catch (error) {
            response = error;
        }
        return response;
    };
};
