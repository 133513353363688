import { EuiButtonEmpty, EuiContextMenuItem, EuiContextMenuPanel, EuiPopover } from '@elastic/eui';
import React, { useState } from 'react';

const ContextMenu = ({
    rowSize,
    buttonLabel = 'Rows per page:',
    popOverItems = [],
    // isPopoverOpen = false,
    // onButtonClick,
    // closePopover,
}) => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const onButtonClick = () => setIsPopoverOpen((isPopoverOpen) => !isPopoverOpen);
    const closePopover = () => setIsPopoverOpen(false);

    const rowsPerPageButton = (
        <EuiButtonEmpty size='s' color='text' iconType='arrowDown' iconSide='right' onClick={onButtonClick}>
            {buttonLabel} {rowSize}
        </EuiButtonEmpty>
    );

    const getIconType = (size) => {
        return size === rowSize ? 'check' : 'empty';
    };

    const items = popOverItems.map((item) => {
        return (
            <EuiContextMenuItem
                key={item.key}
                icon={getIconType(item.iconSize)}
                onClick={() => item.onClick(closePopover)}
            >
                {item.key}
            </EuiContextMenuItem>
        );
    });

    return (
        <EuiPopover
            button={rowsPerPageButton}
            isOpen={isPopoverOpen}
            closePopover={closePopover}
            panelPaddingSize='none'
        >
            <EuiContextMenuPanel items={items} />
        </EuiPopover>
    );
};

export default React.memo(ContextMenu);
