import { EuiButton } from '@elastic/eui';
import React, { useState } from 'react';
import OrderingModeModal from '../../../../components/settings/RestaurantSettings/OrderingModeModal';

const CreateOrderingModeAction = ({ updateOrderingModes }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => setIsModalVisible(true);
    const closeModal = () => setIsModalVisible(false);

    return (
        <>
            <OrderingModeModal
                closeModal={closeModal}
                isModalVisible={isModalVisible}
                updateOrderingModes={updateOrderingModes}
            />

            <EuiButton onClick={showModal}>Create an ordering mode</EuiButton>
        </>
    );
};

export default CreateOrderingModeAction;
