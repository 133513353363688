import React, { useCallback } from 'react';
import { EuiFlexGroup, EuiText, EuiFlexItem, EuiButtonIcon, EuiCollapsibleNavGroup, EuiButton } from '@elastic/eui';
import { useSelector } from 'react-redux';
import AddonChoiceForm from './AddonChoiceForm';

export const AddOnChoices = (props) => {
    const { control, errors, setValue, watch } = props;
    const { fields, append, remove } = props.fieldArray;
    const availableLanguages = useSelector((state) => state.language.availableLanguages);

    const onRemove = useCallback(
        (index) => {
            remove(index);
        },
        [remove]
    );

    const onAppend = useCallback(() => {
        let newChoice = {};
        availableLanguages.forEach((language) => {
            newChoice[`choice_title${language.language_id}`] = '';
        });
        newChoice['price'] = 0;
        newChoice['compare_price'] = 0;
        newChoice['gst_slab'] = '0';
        newChoice['show_agmark'] = '';
        newChoice['food_type'] = 'vegetarian';
        newChoice['image'] = null;
        newChoice['choice_id'] = null;
        append(newChoice);
    }, [append, availableLanguages]);

    return (
        <EuiFlexGroup direction='column'>
            <EuiFlexItem>
                <EuiButton fill onClick={() => onAppend()}>
                    Add Choice
                </EuiButton>
            </EuiFlexItem>

            {fields.map((field, index) => {
                return (
                    <EuiCollapsibleNavGroup
                        key={field.id}
                        background='light'
                        title={
                            <EuiText>
                                <h3>{`Choice ${index + 1}`}</h3>
                            </EuiText>
                        }
                        isCollapsible={true}
                        initialIsOpen={false}
                        arrowDisplay={'left'}
                        extraAction={
                            <EuiFlexItem style={{ marginRight: '10px' }}>
                                <EuiButtonIcon
                                    aria-label='cross'
                                    onClick={() => onRemove(index)}
                                    title='Delete this choice'
                                    iconType='cross'
                                />
                            </EuiFlexItem>
                        }
                    >
                        <AddonChoiceForm
                            control={control}
                            watch={watch}
                            setValue={setValue}
                            errors={errors}
                            field={field}
                            key={index}
                            index={index}
                        />
                    </EuiCollapsibleNavGroup>
                );
            })}
        </EuiFlexGroup>
    );
};
