import { EuiDatePicker, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiRadioGroup, EuiSpacer } from '@elastic/eui';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useWatch, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import APIV3 from '../../../api/axios/APIV3';
import { outletSettingsV4 } from '../../../api/outlet/outletSettingsAPI';
import NumberField from '../../../components/Form/NumberField';
import SwitchField from '../../../components/Form/SwitchField';
import VariableDiscount from '../../../components/settings/outlet-form/VariableDiscount';
import { fetchOutletOrderingModes } from '../../../api/outlet/fetchOutletOrderingModes';
import ConvenienceCharge from '../../../components/settings/outlet-form/ConvenienceCharge';
import FormRadioGroup from '../../../components/Form/FormRadioGroup';
import useOutletFormValues from '../Hooks/useOutletFormValues';
import useFormActionsHandler from '../../../hooks/useFormActionsHandler';

const BillPaymentConfiguration = () => {
    const dispatch = useDispatch();

    const outletId = useSelector((state) => state.outlet.selectedOutletId);
    const [offerConfigurations, setOfferConfigurations] = useState({});
    const outletSettings = useSelector((state) => state?.outlet?.outletOrderingModes?.settings);

    const updateFormValues = useCallback(() => {
        const data = offerConfigurations;
        return {
            fixed_discount: data?.fixed_discount ? data.fixed_discount : null,
            minimum_payment_amount: data.minimum_payment_amount ? data.minimum_payment_amount : null,
            show_minimum_amount: data.minimum_payment_amount ? 'show_min_amount' : 'hide_min_amount',
            discount_upto_amount: data.discount_upto_amount,
            discount_upto_amount__: data.discount_upto_amount
                ? 'show_discount_upto_amount'
                : 'hide_discount_upto_amount',
            custom_discount_type: outletSettings?.is_bill_payment_enabled
                ? data?.discount_below_threshold || data?.discount_below_threshold || data?.threshold_amount
                    ? 'variable_discount'
                    : 'simple_discount'
                : 'simple_discount',
            discount_below_threshold: data.discount_below_threshold ? data.discount_below_threshold : null,
            discount_above_threshold: data.discount_above_threshold ? data.discount_above_threshold : null,
            threshold_amount: data.threshold_amount ? data.threshold_amount : null,
            is_bill_payment_enabled: outletSettings?.is_bill_payment_enabled,
            bill_payment_convenience_charge_type: outletSettings?.bill_payment_convenience_charge_type
                ? outletSettings?.bill_payment_convenience_charge_type
                : 'percentage_charge',
            bill_payment_convenience_charge: outletSettings?.bill_payment_convenience_charge,
            is_convenience_charge_enable: outletSettings?.bill_payment_convenience_charge > 0 ? true : false,
            amount: data.amount,
            type: data?.type ? data.type : 'percentage',
            is_discount_enable: data.type ? true : false,
            max_usage_count: data.max_usage_count,
            max_usage_count__: data.max_usage_count ? 'show_max_usage_count' : 'hide_max_usage_count',
            max_usage_count_per_user: data.max_usage_count_per_user,
            max_usage_count_per_user__: data.max_usage_count_per_user
                ? 'show_max_usage_count_per_user'
                : 'hide_max_usage_count_per_user',
            validate_from: data.validate_from ? moment(data.validate_from) : null,
            validate_to: data.validate_to ? moment(data.validate_to) : null,
        };
    }, [offerConfigurations, outletSettings]);

    const { defaultValues, setDefaultValues, onSuccessCallback } = useOutletFormValues({
        updateFormValues,
        sideEffectForReset: offerConfigurations,
    });

    const onFormSaveApi = useCallback(
        async (data) => {
            if (data.custom_discount_type === 'simple_discount') {
                data.threshold_amount = 0;
                data.discount_above_threshold = 0;
                data.discount_below_threshold = 0;
            } else if (data.custom_discount_type === 'variable_discount') {
                data.amount = 0;
            }
            data.validate_from = data.validate_from ? moment(data.validate_from).format('YYYY-MM-DD') : null;
            data.validate_to = data.validate_to ? moment(data.validate_to).format('YYYY-MM-DD') : null;

            if (data.type === 'flat') {
                data.discount_upto_amount = null;
            }

            if (data.discount_upto_amount__ === 'hide_discount_upto_amount') {
                data.discount_upto_amount = 0;
            }

            if (data.max_usage_count__ === 'hide_max_usage_count') {
                data.max_usage_count = 0;
            }

            if (data.max_usage_count_per_user__ === 'hide_max_usage_count_per_user') {
                data.max_usage_count_per_user = 0;
            }

            if (!data.is_convenience_charge_enable) {
                data.bill_payment_convenience_charge = 0;
                delete data?.bill_payment_convenience_charge_type;
            }

            if (!data.is_bill_payment_enabled || !data.is_discount_enable) {
                data.amount = 0;
                data.threshold_amount = 0;
                data.discount_above_threshold = 0;
                data.discount_below_threshold = 0;
                data.discount_upto_amount = 0;
                data.max_usage_count = 0;
                data.max_usage_count_per_user = 0;
            }

            if (!data.is_bill_payment_enabled) {
                data.bill_payment_convenience_charge = 0;
            }

            if (data.is_discount_enable && data.is_bill_payment_enabled) {
                if (isEmpty(offerConfigurations)) {
                    // create offer
                    await APIV3.post(`/restaurants/:restaurantId/outlets/${outletId}/bill-payment-offers`, data);
                } else {
                    // update offer
                    await APIV3.put(
                        `/restaurants/:restaurantId/outlets/${outletId}/bill-payment-offers/${offerConfigurations.id}`,
                        data
                    );
                }
                // update the payment configurations
            }
            if (data.is_discount_enable) {
                // enable bill payment
                await dispatch(outletSettingsV4('bill_payment_offer_settings', data));
            } else {
                await dispatch(outletSettingsV4('bill_payment_offer_settings', data));
                if (!isEmpty(offerConfigurations)) {
                    // delete offer
                    await APIV3.delete(
                        `/restaurants/:restaurantId/outlets/${outletId}/bill-payment-offers/${offerConfigurations.id}`
                    );
                }
            }
            // if (data.is_bill_payment_enabled) {
            const response = dispatch(
                outletSettingsV4('bill_payment_offer_settings', {
                    is_bill_payment_enabled: data.is_bill_payment_enabled,
                    bill_payment_convenience_charge_type: data.bill_payment_convenience_charge_type,
                    bill_payment_convenience_charge: data.bill_payment_convenience_charge,
                })
            );
            dispatch(fetchOutletOrderingModes(outletId, 'bill_payment_offer_settings'));
            // }

            fetchPaymentConfigurations();

            if (response.success) setDefaultValues(updateFormValues(response.outlet));
            return response;
        },
        [dispatch, offerConfigurations, outletId]
    );
    const { errors, control, watch, setValue, reset, isDirty } = useFormActionsHandler({
        onFormSaveApi,
        defaultValues,
        onSuccessCallback,
    });

    const methods = {
        watch,
        control,
        setValue,
        reset,
        formState: {
            errors,
            isDirty,
        },
    };

    const isBillPaymentEnable = useWatch({ control, name: 'is_bill_payment_enabled' });
    const isFixedAmountSelected = useWatch({ control, name: 'type' }) === 'flat';

    const fetchPaymentConfigurations = useCallback(async () => {
        await APIV3.get(`restaurants/:restaurantId/outlets/${outletId}/bill-payment-offers`).then((response) => {
            if (!isEmpty(response?.outlet_bill_payment_offers?.[0])) {
                setOfferConfigurations({ ...response?.outlet_bill_payment_offers?.[0] });
            } else {
                setOfferConfigurations({});
            }
        });
    }, [outletId]);

    useEffect(() => {
        if (outletSettings.is_bill_payment_enabled) {
            fetchPaymentConfigurations();
        } else {
            setOfferConfigurations({});
        }
    }, [outletSettings.is_bill_payment_enabled, fetchPaymentConfigurations, outletId]);

    useEffect(() => {
        if (!isFixedAmountSelected) {
            setValue('fixed_discount', 0);
        }
    }, [isFixedAmountSelected, setValue]);

    const custom_discount_type = useWatch({
        control,
        name: 'custom_discount_type',
    });

    const type = useWatch({
        control,
        name: 'type',
    });

    useEffect(() => {
        if (custom_discount_type === 'variable_discount') {
            setValue('show_minimum_amount', 'hide_min_amount', { shouldDirty: true });
        }
    }, [custom_discount_type]);

    useEffect(() => {
        if (type === 'flat') {
            setValue('discount_upto_amount__', 'hide_discount_upto_amount', { shouldDirty: true });
        }
    }, [type]);

    return (
        <FormProvider {...methods}>
            <SwitchField
                label='Enable/Disable Bill Payment'
                setValue={setValue}
                control={control}
                name='is_bill_payment_enabled'
            />
            <EuiSpacer />
            {isBillPaymentEnable ? (
                <>
                    <SwitchField
                        label='Enable/Disable Discount'
                        setValue={setValue}
                        control={control}
                        name='is_discount_enable'
                    />
                    {watch('is_discount_enable') ? (
                        <EuiFlexItem style={{ marginLeft: '24px' }}>
                            <EuiFlexItem grow={false} style={{ flexGrow: false }}>
                                <EuiSpacer />
                                <Controller
                                    control={control}
                                    name='custom_discount_type'
                                    rules={{
                                        required: 'Please select any option to continue',
                                    }}
                                    render={({ field }) => (
                                        <EuiFormRow
                                            isInvalid={errors['custom_discount_type']}
                                            error={
                                                errors['custom_discount_type']
                                                    ? errors['custom_discount_type'].message
                                                    : ''
                                            }
                                        >
                                            <EuiRadioGroup
                                                options={[
                                                    { id: 'simple_discount', label: 'Simple Discount' },
                                                    { id: 'variable_discount', label: 'Variable Discount' },
                                                ]}
                                                idSelected={field.value}
                                                onChange={field.onChange}
                                                legend={{
                                                    children: <span> {'Select a Customizable type'} </span>,
                                                }}
                                            />
                                        </EuiFormRow>
                                    )}
                                />

                                <EuiSpacer />
                                {watch('custom_discount_type') === 'variable_discount' ? <VariableDiscount /> : null}
                            </EuiFlexItem>
                            <FormRadioGroup
                                label='Select a type of Discount'
                                name='type'
                                rules={{
                                    required: 'Please select any option to continue',
                                }}
                                isInvalid={errors['type']}
                                error={errors['type'] ? errors['type'].message : ''}
                                options={[
                                    { id: 'percentage', label: 'Percentage Discount' },
                                    { id: 'flat', label: 'Fixed Discount' },
                                ]}
                            />
                            {watch('custom_discount_type') !== 'variable_discount' ? (
                                <>
                                    {watch('type') === 'percentage' ? (
                                        <>
                                            <EuiSpacer />
                                            <NumberField
                                                errors={errors}
                                                label='Percentage Amount'
                                                control={control}
                                                // name='percentage_discount'
                                                name='amount'
                                                rules={{ required: 'Please Enter Percentage Amount' }}
                                                placeholder='Enter Percentage Discount'
                                            />
                                        </>
                                    ) : null}

                                    {isFixedAmountSelected ? (
                                        <>
                                            <EuiSpacer />
                                            <NumberField
                                                errors={errors}
                                                label='Fixed Amount'
                                                control={control}
                                                // name='fixed_discount'
                                                name='amount'
                                                placeholder='Enter Fixed Discount'
                                                rules={{
                                                    required: 'Please Enter Fixed Discount',
                                                    validate: () =>
                                                        watch('amount') > 0 || 'Amount Should be greater than zero',
                                                }}
                                            />
                                        </>
                                    ) : null}
                                </>
                            ) : null}

                            {watch('custom_discount_type') === 'variable_discount' ? (
                                <>
                                    <NumberField
                                        errors={errors}
                                        label='Above Threshold'
                                        control={control}
                                        name='discount_above_threshold'
                                        placeholder='Enter Above Threshold'
                                        rules={{
                                            required: 'Please Enter Above Threshold',
                                        }}
                                    />
                                    <NumberField
                                        errors={errors}
                                        label='Below Threshold'
                                        control={control}
                                        name='discount_below_threshold'
                                        placeholder='Enter Below Threshold'
                                        rules={{
                                            required: 'Please Enter Below Threshold',
                                        }}
                                    />
                                </>
                            ) : null}

                            <FormRadioGroup
                                label='Would you like to add minimum amount?'
                                name='show_minimum_amount'
                                disabled={watch('custom_discount_type') === 'variable_discount'}
                                options={[
                                    { id: 'show_min_amount', label: 'Yes' },
                                    { id: 'hide_min_amount', label: 'No' },
                                ]}
                            />

                            {watch('show_minimum_amount') === 'show_min_amount' ? (
                                <NumberField
                                    errors={errors}
                                    label='Minimum Amount'
                                    control={control}
                                    disabled={watch('custom_discount_type') === 'variable_discount'}
                                    name='minimum_payment_amount'
                                    placeholder='Enter Minimum Amount'
                                    rules={{
                                        validate: (value) => {
                                            if (watch('threshold_amount')) {
                                                return (
                                                    watch('threshold_amount') >= parseInt(value) ||
                                                    `Amount Shouldn't be more than Threshold Amount`
                                                );
                                            }
                                        },
                                    }}
                                />
                            ) : null}

                            <FormRadioGroup
                                label='Would you like to add discount upto amount?'
                                disabled={watch('type') === 'flat'}
                                name='discount_upto_amount__'
                                options={[
                                    { id: 'show_discount_upto_amount', label: 'Yes' },
                                    { id: 'hide_discount_upto_amount', label: 'No' },
                                ]}
                            />

                            {watch('discount_upto_amount__') === 'show_discount_upto_amount' ? (
                                <NumberField
                                    errors={errors}
                                    label='Discount Upto Amount'
                                    disabled={watch('type') === 'flat'}
                                    control={control}
                                    name='discount_upto_amount'
                                    placeholder='Enter Discount Upto Amount'
                                />
                            ) : null}

                            <FormRadioGroup
                                label='Would you like to add max usage count?'
                                name='max_usage_count__'
                                options={[
                                    { id: 'show_max_usage_count', label: 'Yes' },
                                    { id: 'hide_max_usage_count', label: 'No' },
                                ]}
                            />

                            {watch('max_usage_count__') === 'show_max_usage_count' ? (
                                <NumberField
                                    errors={errors}
                                    label='Max Usage Count'
                                    control={control}
                                    name='max_usage_count'
                                    placeholder='Enter Max Usage Count'
                                />
                            ) : null}

                            <FormRadioGroup
                                label='Would you like to add max usage count per user?'
                                name='max_usage_count_per_user__'
                                options={[
                                    { id: 'show_max_usage_count_per_user', label: 'Yes' },
                                    { id: 'hide_max_usage_count_per_user', label: 'No' },
                                ]}
                            />

                            {watch('max_usage_count_per_user__') === 'show_max_usage_count_per_user' ? (
                                <NumberField
                                    errors={errors}
                                    label='Max Usage Count Per User'
                                    control={control}
                                    name='max_usage_count_per_user'
                                    placeholder='Enter Max Usage Count Per User'
                                />
                            ) : null}

                            <EuiSpacer />
                            <EuiFlexGroup style={{ marginBottom: '' }}>
                                <EuiFlexItem>
                                    <EuiFormRow
                                        label='Coupon valid from'
                                        fullWidth={true}
                                        isInvalid={errors.validate_from}
                                        error={errors.validate_from ? errors.validate_from.message : null}
                                    >
                                        <Controller
                                            render={({ field }) => (
                                                <EuiDatePicker
                                                    selected={field.value}
                                                    onChange={(value) => field.onChange(value)}
                                                    style={{ zIndex: 10000 }}
                                                />
                                            )}
                                            name='validate_from'
                                            control={control}
                                            defaultValue={watch('validate_from') ? watch('validate_from') : null}
                                            placeholder='select starting date'
                                            isInvalid={errors.validate_from}
                                            fullWidth={true}
                                        />
                                    </EuiFormRow>
                                </EuiFlexItem>
                                <EuiFlexItem>
                                    <EuiFormRow
                                        label='Coupon valid till'
                                        fullWidth={true}
                                        isInvalid={errors.validate_to}
                                        error={errors.validate_to ? errors.validate_to.message : ''}
                                    >
                                        <Controller
                                            render={({ field }) => (
                                                <EuiDatePicker
                                                    selected={field.value}
                                                    onChange={(value) => field.onChange(value)}
                                                />
                                            )}
                                            name='validate_to'
                                            control={control}
                                            defaultValue={watch('validate_to') ? watch('validate_to') : null}
                                            placeholder='select ending date'
                                            isInvalid={errors.validate_to}
                                            fullWidth={true}
                                        />
                                    </EuiFormRow>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </EuiFlexItem>
                    ) : null}
                    <ConvenienceCharge />
                </>
            ) : null}
        </FormProvider>
    );
};

export default React.memo(BillPaymentConfiguration);
