import { EuiButton, EuiFlexGroup, EuiFlexItem } from '@elastic/eui';
import React, { useState } from 'react';
import API from '../../api/axios/API';
import { useDispatch, useSelector } from 'react-redux';
import { getLiveOrders } from '../../api/liveOrders/getLiveOrderNewAPI';
import { getLiveOrderDetailsAPI } from '../../api/liveOrders/getLiveOrderDetailsAPI';
import * as types from '../../reduxStore/types/orders';

export default function PidgeServices(props) {
    const { tabState, availablePidgeServices, orderId, closeModal, isOrderAccepted } = props;

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);
    const hasRestaurantAcceptedOrder = useSelector((state) => state.orders.hasRestaurantAcceptedOrder);
    const [isLoadingAssigning, setIsLoadingAssigning] = useState(false);

    const selectedService = availablePidgeServices.find((el) => el.network_id == tabState);

    const dispatch = useDispatch();

    const assignRider = async () => {
        setIsLoadingAssigning(true);

        try {
            const response = await API.post(`/restaurants/${selectedRestaurantId}/pidge/assign-order`, {
                order_id: orderId,
                network_id: tabState,
                network_name: selectedService?.network_name,
                service: selectedService?.service,
                price: selectedService?.price,
                distance: selectedService?.distance,
                pickup: selectedService?.pickup,
                drop: selectedService?.drop,
                pickup_min: selectedService?.pickup_min,
                base_delivery_charge: selectedService?.base_delivery_charge,
                total_gst_amount: selectedService?.total_gst_amount,
                surge: selectedService?.surge,
            });

            if (response?.success) {
                const fulfillResponse =
                    isOrderAccepted || hasRestaurantAcceptedOrder
                        ? API.post(`/restaurants/${selectedRestaurantId}/pidge/fulfill-order`, {
                              order_id: orderId,
                              selected_service: tabState,
                          })
                        : {};
                closeModal();
                setIsLoadingAssigning(false);
                alert('Rider assign successfully!!');
                dispatch(getLiveOrders());
                dispatch(getLiveOrderDetailsAPI());
                props.setRiderModalOpen();
                dispatch({
                    type: types.HAS_RESTAURANT_ACCEPTED_ORDER,
                    payload: false,
                });
            }
        } catch (error) {
            console.log('error : ', error);
            
            alert(error?.message ?? 'Error in assign rider');
            setIsLoadingAssigning(false);
        }
    };

    return (
        <>
            <EuiFlexGroup direction='column'>
                <EuiFlexItem>
                    <>
                        <table>
                            <tbody>
                                <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                    <td style={{ borderRight: '1px solid #D3DAE6' }}>Base Delivery Charge</td>
                                    <td>
                                        <b>{selectedService?.base_delivery_charge ?? '-'}</b>
                                    </td>
                                </tr>

                                <tr style={{ padding: 5, borderBottom: '1px solid #D3DAE6' }}>
                                    <td style={{ borderRight: '1px solid #D3DAE6' }}>GST Charge</td>
                                    <td>
                                        <b>{selectedService?.total_gst_amount ?? '-'}</b>
                                    </td>
                                </tr>

                                <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                    <td style={{ borderRight: '1px solid #D3DAE6' }}>Pickup Time</td>

                                    <td>
                                        <b>{selectedService?.pickup_min ?? '-'}</b>
                                    </td>
                                </tr>

                                <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                    <td style={{ borderRight: '1px solid #D3DAE6' }}>Surge Amount</td>
                                    <td>
                                        <b>{selectedService?.surge}</b>
                                    </td>
                                </tr>
                                <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                                    <td style={{ borderRight: '1px solid #D3DAE6' }}>Total Amount</td>
                                    <td>
                                        <b>{selectedService?.price}</b>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </>
                </EuiFlexItem>

                <EuiFlexItem>
                    <EuiButton isLoading={isLoadingAssigning} onClick={() => assignRider()} fill>
                        Assign
                    </EuiButton>
                </EuiFlexItem>
            </EuiFlexGroup>
        </>
    );
}
