// return agmark sign with color
import React from 'react';
import { ReactComponent as Agmark } from '../assets/img/Agmark.svg';

export const agmarkSign = (food_type) => {
    let color = '';

    if (food_type === 'vegetarian') {
        color = 'green';
    } else if (food_type === 'non-vegetarian') {
        color = 'red';
    } else {
        color = '#FFAD00';
    }

    return <Agmark fill={color} height={10} width={10} />;
};
