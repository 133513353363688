import APIV3 from '../axios/APIV3';

export const fetchCustomerPayments = (startDate, endDate, currentPage, outletId) => {
    return async () => {
        let response;

        try {
            response = await APIV3.get(
                `/restaurants/:restaurantId/outlets/${outletId}/customer-bill-payments?start_date=${startDate?.format(
                    'YYYY-MM-DD'
                )}&end_date=${endDate?.format('YYYY-MM-DD')}&per_page=10&page=${currentPage}`
            );
        } catch (error) {
            response = { ...error, isError: true };
        }

        return response;
    };
};
