import APIV3 from '../axios/APIV3';

export const fetchSelectedBillDetails = async (billID, outletId) => {
    let response;

    try {
        response = await APIV3.get(`restaurants/:restaurantId/outlets/${outletId}/customer-bill-payments/${billID}`);
    } catch (error) {
        response = { ...error, isError: true };
    }

    return response;
};
