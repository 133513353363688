import { getHeaderToken } from '../helper/getHeaderToken';
import { baseApiUrl } from '../envConfig';
const request = require('superagent-use')(require('superagent'));

export const setSuperAgent = (store) => {
    request.use((req) => {
        req.header = getHeaderToken(store.getState().auth.token);
        req.url = `${baseApiUrl}${req.url}`;
        return req;
    });
};

// Setup some defaults

export default request;
