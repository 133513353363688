import moment from 'moment';

export const getNextWorkingDayTime = (timeSlots) => {
    let timeObj = {};
    let weekDay = moment().weekday();
    let nextDayTime = '';
    let nextWeekDay = '';

    timeSlots.map((slot) => {
        if (timeObj[slot.time_slots.day_of_week]) {
            timeObj[slot.time_slots.day_of_week] = [...timeObj[slot.time_slots.day_of_week], slot.time_slots];
        } else {
            timeObj[slot.time_slots.day_of_week] = [slot.time_slots];
        }
    });

    for (let key in timeObj) {
        timeObj[key] = sortingTimeSlots(timeObj[key]);

        if (key > weekDay && !nextDayTime) {
            nextDayTime = timeObj[key][0].start_time;
            nextWeekDay = key;
        }
    }

    if (nextDayTime) {
        let nextBussinessTime = `${moment().weekday(nextWeekDay).format('YYYY-MM-DD')} ${nextDayTime}`;

        return moment(nextBussinessTime).format('YYYY-MM-DD HH:mm:ss');
    } else {
        return '';
    }
};

const sortingTimeSlots = (arr) => {
    var len = arr.length;

    for (var i = len - 1; i >= 0; i--) {
        for (var j = 1; j <= i; j++) {
            if (arr[j - 1].start_time > arr[j].start_time) {
                var temp = arr[j - 1];
                arr[j - 1] = arr[j];
                arr[j] = temp;
            }
        }
    }
    return arr;
};
