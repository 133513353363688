import APIV3 from '../axios/APIV3';

export const petPoojaSyncValidate = () => {
    return async (dispatch, getState) => {
        let response;
        let { outlet } = getState();
        try {
            response = await APIV3.get(
                `restaurants/:restaurantId/outlets/${outlet.selectedOutletId}/pet-pooja/sync-check`
            );
        } catch (error) {
            response = error;
        }

        return response;
    };
};
