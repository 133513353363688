import API from '../axios/API';
import * as types from '../../reduxStore/types/banner';

export const getRestaurantBanners = () => {
    return async (dispatch, getState) => {
        dispatch({
            type: types.SET_BANNER_LOADER,
            payload: {
                bannerLoader: { loader: true, error: false },
            },
        });
        let response;

        try {
            response = await API.get(`restaurants/:restaurantId/banners`);
        } catch (error) {
            response = error;
        }

        if (response.success) {
            dispatch({
                type: types.SET_BANNER,
                payload: {
                    banners: response.banners,
                },
            });
            dispatch({
                type: types.SET_BANNER_LOADER,
                payload: {
                    bannerLoader: { loader: false, error: false },
                },
            });
        } else {
            dispatch({
                type: types.SET_BANNER_LOADER,
                payload: {
                    bannerLoader: { loader: false, error: true },
                },
            });
        }

        return response;
    };
};
