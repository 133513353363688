import React from 'react';
import { MainLayout } from '../../components/layout/mainLayout/mainLayout';
import AbandonedCartComponent from '../../components/abandonedCarts/abandonedCartComponent';
import { useSelector } from 'react-redux';
import { NoOutletMessage } from '../../utils/noOutletMessage';

const AbandonedCartPage = () => {
    const selectedOutlet = useSelector((state) => state.outlet.selectedOutletId);

    return (
        <>
            <MainLayout title='Abandoned carts' outlet={true} isSingleScreen={true} backgroundColorChange={true}>
                {selectedOutlet ? <AbandonedCartComponent /> : <NoOutletMessage />}
            </MainLayout>
        </>
    );
};

export default AbandonedCartPage;
