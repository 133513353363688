import React, { useState } from 'react';
import TextField from '../../Form/TextField';
import FormGroupDescription from '../../formGroupDescription';
import ButtonGroup from '../../Form/ButtonGroup';
import { useDispatch, useSelector } from 'react-redux';
import {
EuiFlyout,
EuiFlyoutBody,
EuiFlyoutHeader,
EuiSpacer,
EuiText,
EuiTitle,
EuiButton,
EuiFlexItem,
} from '@elastic/eui';
import { useFormContext } from 'react-hook-form';
import { requestForRiderService } from '../../../api/requestThirdPartyRiderService';
import { getOutletDetails } from '../../../api/outlet/getOutletDetails';

const PorterRiders = () => {
const {
formState: { errors },
control,
watch,
setValue,
} = useFormContext();
const outletId = useSelector((state) => state.outlet.selectedOutletId);
const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);
const dispatch = useDispatch();
const outlet = useSelector((state) => state.outlet.selectedOutletDetails);
const approvalDate = outlet?.third_party_rider_setting?.porter_request?.approval_date;
const serviceStatus = outlet?.third_party_rider_setting?.porter_request?.status;

const startService = () => {
dispatch(requestForRiderService('porter', 'rider service request for Porter')).then((res) => {
dispatch(getOutletDetails(outletId));
});
};

let flyout;
if (isFlyoutVisible) {
flyout = (
<EuiFlyout
    ownFocus
    onClose={() => {
setIsFlyoutVisible(!isFlyoutVisible);
}}
aria-labelledby={'simpleFlyoutTitle'}
>
<EuiFlyoutHeader hasBorder>
    <EuiTitle size='m'>
        <h2>Porter riders</h2>
    </EuiTitle>
</EuiFlyoutHeader>
<EuiFlyoutBody>
    <ButtonGroup
        idSelected={watch('is_porter_integration_enabled') ? '1' : '0'}
    color='primary'
    control={control}
    setValue={setValue}
    name='is_porter_integration_enabled'
    watch={watch}
    label={'Porter integration'}
    />

    {watch('is_porter_integration_enabled') ? (
    <>
    <TextField
        errors={errors}
        label='Porter token'
        name='porter_token'
        placeholder='Enter Porter token'
        rules={{ required: 'Please Enter Porter token' }}
    control={control}
    />
</>
) : (
''
)}
</EuiFlyoutBody>
</EuiFlyout>
);
}

return (
<div style={{ maxWidth: '650px' }}>
<FormGroupDescription
    title={restaurantDescriptions?.porter_riders?.display_name}
    description={restaurantDescriptions?.porter_riders?.description}
>
    <EuiFlexItem>
        <EuiButton
            style={{
            backgroundColor: serviceStatus === 'approved' && '#228B22',
        color: serviceStatus === 'approved' && 'white',
        }}
        fill
        onClick={startService}
        disabled={serviceStatus ? true : false}
        >
        {serviceStatus ? serviceStatus : 'START SERVICE'}
        </EuiButton>
    </EuiFlexItem>
    <EuiSpacer style={{ marginTop: '6px' }}>
    <EuiText textAlign='center' size='xs'>
        ------ OR ------
    </EuiText>
    </EuiSpacer>
    <EuiFlexItem>
        <EuiButton onClick={() => setIsFlyoutVisible(!isFlyoutVisible)}>CONNECT ACCOUNT</EuiButton>
    </EuiFlexItem>
    {flyout}
</FormGroupDescription>
{serviceStatus === 'pending' && (
<EuiText
    style={{
    padding: '8px 12px',
background: '#BBB',
color: 'white',
marginBottom: '12px',
borderRadius: '4px',
// width: '65%',
}}
>
<p> Your request has been received and it shall be activated by {approvalDate} </p>
</EuiText>
)}
</div>
);
};

export default PorterRiders;
