import React, { useCallback } from 'react';
import { EuiForm } from '@elastic/eui';
import FormGroupDescription from '../../../components/formGroupDescription';
import TextField from '../../../components/Form/TextField';
import ButtonGroup from '../../../components/Form/ButtonGroup';
import { useDispatch } from 'react-redux';
import useOutletFormValues from '../Hooks/useOutletFormValues';
import { outletSettingsAPI } from '../../../api/outlet/outletSettingsAPI';
import useFormActionsHandler from '../../../hooks/useFormActionsHandler';

const ThirdPartyPOS = () => {
    const dispatch = useDispatch();
    const updateFormValues = useCallback((data) => {
        return {
            is_pet_pooja_order_assignment_enabled: data?.outlet_third_party_menu_setting
                ?.is_pet_pooja_order_assignment_enabled
                ? 1
                : 0,
            is_pet_pooja_integration_enabled: data?.outlet_third_party_menu_setting?.is_pet_pooja_integration_enabled
                ? 1
                : 0,
            app_key: data?.outlet_third_party_menu_setting?.app_key,
            app_secret: data?.outlet_third_party_menu_setting?.app_secret,
            access_token: data?.outlet_third_party_menu_setting?.access_token,
            restaurant_id: data?.outlet_third_party_menu_setting?.restaurant_id,
            is_bill_berry_integration_enabled: data?.outlet_third_party_menu_setting?.is_bill_berry_integration_enabled
                ? 1
                : 0,
            billberry_api_key: data?.outlet_third_party_menu_setting?.billberry_api_key,
            billberry_store_id: data?.outlet_third_party_menu_setting?.billberry_store_id,
        };
    }, []);

    const { defaultValues, setDefaultValues, onSuccessCallback } = useOutletFormValues({ updateFormValues });

    const onFormSaveApi = useCallback(
        async (data) => {
            const response = await dispatch(outletSettingsAPI('menu_settings', data));
            if (response.success) setDefaultValues(updateFormValues(response.outlet));
            return response;
        },
        [dispatch, updateFormValues]
    );

    const { errors, control, watch, setValue } = useFormActionsHandler({
        onFormSaveApi,
        defaultValues,
        onSuccessCallback,
    });
    return (
        <>
            <EuiForm component='form'>
                <FormGroupDescription
                    title={'Enable Pet Pooja integration'}
                    description={'Enable to start integrating pej pooja menu'}
                >
                    <ButtonGroup
                        idSelected={watch('is_pet_pooja_integration_enabled') ? '1' : '0'}
                        color='primary'
                        control={control}
                        setValue={setValue}
                        name='is_pet_pooja_integration_enabled'
                        watch={watch}
                        label={'Third party pos enable'}
                    />
                </FormGroupDescription>

                {watch('is_pet_pooja_integration_enabled') == 1 ? (
                    <>
                        <FormGroupDescription title={'App key'} description={''}>
                            <TextField
                                errors={errors}
                                label='App Key'
                                name='app_key'
                                placeholder='Please enter app key'
                                rules={{ required: 'Please enter app key' }}
                                control={control}
                            />
                        </FormGroupDescription>

                        <FormGroupDescription title={'App secret'} description={''}>
                            <TextField
                                errors={errors}
                                label='App secret'
                                name='app_secret'
                                placeholder='Please enter app secret'
                                rules={{ required: 'Please enter app secret' }}
                                control={control}
                            />
                        </FormGroupDescription>

                        <FormGroupDescription title={'Access token'} description={''}>
                            <TextField
                                errors={errors}
                                label='Access token'
                                name='access_token'
                                placeholder='Please enter access token'
                                rules={{ required: 'Please enter access token' }}
                                control={control}
                            />
                        </FormGroupDescription>

                        <FormGroupDescription title={'Restaurant ID'} description={''}>
                            <TextField
                                errors={errors}
                                label='Restaurant ID'
                                name='restaurant_id'
                                placeholder='Please enter restaurant id'
                                rules={{ required: 'Please enter restaurant id' }}
                                control={control}
                            />
                        </FormGroupDescription>
                    </>
                ) : (
                    ''
                )}

                <FormGroupDescription
                    title={'Enable Pet Pooja orders'}
                    description={'Enable to start getting orders to pet pooja'}
                >
                    <ButtonGroup
                        idSelected={watch('is_pet_pooja_order_assignment_enabled') ? '1' : '0'}
                        color='primary'
                        control={control}
                        setValue={setValue}
                        name='is_pet_pooja_order_assignment_enabled'
                        watch={watch}
                        label={'Third party pos orders enable'}
                    />
                </FormGroupDescription>

                <FormGroupDescription
                    title={'Enable Bill Berry integration'}
                    description={'Enable to start integrating bill berry menu'}
                >
                    <ButtonGroup
                        idSelected={watch('is_bill_berry_integration_enabled') ? '1' : '0'}
                        color='primary'
                        control={control}
                        setValue={setValue}
                        name='is_bill_berry_integration_enabled'
                        watch={watch}
                        label={'Third party pos enable'}
                    />
                </FormGroupDescription>
                {watch('is_bill_berry_integration_enabled') == 1 ? (
                    <>
                        <FormGroupDescription title={'Bill Berry Api key'} description={''}>
                            <TextField
                                errors={errors}
                                label='Api Key'
                                name='billberry_api_key'
                                placeholder='Please enter api key'
                                rules={{ required: 'Please enter api key' }}
                                control={control}
                            />
                        </FormGroupDescription>
                        <FormGroupDescription title={'Bill Berry Store Id'} description={''}>
                            <TextField
                                errors={errors}
                                label='Store Id'
                                name='billberry_store_id'
                                placeholder='Please enter store Id'
                                rules={{ required: 'Please enter store Id' }}
                                control={control}
                            />
                        </FormGroupDescription>
                    </>
                ) : (
                    ''
                )}
            </EuiForm>
        </>
    );
};

export default ThirdPartyPOS;
