import APIV3 from '../../../api/axios/APIV3';
import { SET_CATALOGUE_ALLERGENS } from '../../../components/menuComponentsRefactorCopy/Store/MenuTypes';

export const fetchAllergens = () => {
    return async (dispatch) => {
        let response = null;

        try {
            response = await APIV3.get(`/restaurants/:restaurantId/allergens`);

            dispatch({
                type: SET_CATALOGUE_ALLERGENS,
                payload: response.allergens,
            });
        } catch (error) {}

        return response;
    };
};
