import { EuiFlexItem, EuiPopover, EuiSuperSelect, EuiText } from '@elastic/eui';
import React from 'react';
import { useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { TOGGLE_UNSAVED_PORTAL } from '../../../reduxStore/types/Global/Global';
import { ALL_OUTLET, SET_SELECTED_OUTLET, SET_SELECTED_OUTLET_ID } from '../../../reduxStore/types/outlet';
import FormActionPortal from '../../UIComponent/FormActionPortal';
import TabSwitchErrorPortal from '../../UIComponent/TabSwitchErrorPortal';

const OutletSelection = (props) => {
    const dispatch = useDispatch();
    const outletList = useSelector((state) => state.outlet.outletList);
    const showFormHandlerPortal = useSelector((state) => state.uiPortalStateReducer.portalState.showFormHandlerPortal);
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);
    const showUnsavedWarningPortal = useSelector(
        (state) => state.uiPortalStateReducer.portalState.showUnsavedDataWaringPortal
    );

    const [options] = useState(
        props.allowAllOutletsSelection && outletList?.length >= 2
            ? [
                  {
                      inputDisplay: 'All Outlets',
                      value: ALL_OUTLET,
                  },
                  ...outletList,
              ]
            : outletList
    );

    const onChangeOutlet = (value) => {
        let outlet = outletList.find((outlet) => outlet.value == value);

        if (showFormHandlerPortal) {
            dispatch({
                type: TOGGLE_UNSAVED_PORTAL,
                payload: true,
            });
        } else {
            if (props.allowAllOutletsSelection) {
                if (outlet === undefined && value === ALL_OUTLET) {
                    outlet = {
                        value: ALL_OUTLET,
                        inputDisplay: 'All Outlets',
                        outlet_data: null,
                    };
                }
            }

            batch(() => {
                dispatch({
                    type: SET_SELECTED_OUTLET_ID,
                    payload: {
                        selectedOutletId: value,
                    },
                });

                dispatch({
                    type: SET_SELECTED_OUTLET,
                    payload: {
                        selectedOutlet: outlet,
                    },
                });
            });
        }
    };

    return (
        <>
            {(props.outlet || props.allowAllOutletsSelection) && (
                <EuiFlexItem
                    grow={10}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-end',
                        justifyContent: 'flex-end',
                    }}
                >
                    <EuiPopover
                        button={
                            <EuiSuperSelect
                                style={{ width: '250px' }}
                                options={options}
                                valueOfSelected={selectedOutletId ? selectedOutletId : ''}
                                onChange={onChangeOutlet}
                            />
                        }
                        isOpen={!selectedOutletId}
                        closePopover={() => {}}
                    >
                        <EuiText>
                            <p>select store from here</p>
                        </EuiText>
                    </EuiPopover>
                </EuiFlexItem>
            )}
            {showUnsavedWarningPortal && <TabSwitchErrorPortal />}
            {showFormHandlerPortal && <FormActionPortal />}
        </>
    );
};

export default React.memo(OutletSelection);
