import APIV3 from '../../../../api/axios/APIV3';

export const fetchSearchedAddons = async (outletId, keyword) => {
    let response;

    try {
        response = await APIV3.get(`restaurants/:restaurantId/outlets/${outletId}/addon/search?keyword=${keyword}`);
    } catch (error) {
        response = {
            error: error,
        };
    }

    return response;
};
