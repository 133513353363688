// /restaurants/:restaurantId/orders/${orderId}/change-status
import API from '../axios/API';

export const liverOrderStatusChange = (data) => {
    return async (dispatch, getState) => {
        let response;
        let { orders } = getState();

        try {
            response = await API.patch(
                `/restaurants/:restaurantId/orders/${orders.selectedOrderId}/change-status`,
                data
            );
        } catch (error) {
            response = error;
        }

        return response;
    };
};
