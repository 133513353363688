import * as types from '../types/auth';
import * as outletTypes from '../types/outlet';
import API from '../../api/axios/API';
import { isEmpty } from 'lodash';
import APIV3 from '../../api/axios/APIV3';

export const initializeRestaurant = (restaurantId, history) => {
    return async (dispatch) => {
        localStorage.removeItem('selectedOutlets');
        dispatch({
            type: 'set-restaurant-loader',
            payload: {
                restaurantLoader: true,
            },
        });

        const responses = await Promise.all([
            API.get(`/restaurant-descriptions`),
            API.get(`/users/restaurants`),
            API.get(`restaurants/${restaurantId}/user/permissions`),
            API.get(`restaurants/${restaurantId}/languages`),
            APIV3.get(`restaurants/${restaurantId}/users/outlets`),
            API.get(`restaurants/${restaurantId}/user/pet-pooja/sync-check`),
            API.get(`restaurants/${restaurantId}/low-credit-alert`),
        ]);

        let descriptionResponse = responses[0];

        dispatch({
            type: 'set-restaurant-descriptions',
            payload: {
                restaurantDescriptions:
                    descriptionResponse.success && !isEmpty(descriptionResponse.restaurant)
                        ? descriptionResponse.restaurant
                        : {},
            },
        });

        //Restaurant fetch
        let restaurantList = responses[1];

        let selectedRestaurant = restaurantList.restaurants.filter((restaurant) => restaurant.id == restaurantId);
        dispatch({
            type: 'set-redeme-points-on-purchase',
            payload: {
                redemePointValueOnPurchase: selectedRestaurant[0].settings.redeem_point_value_on_purchase,
            },
        });
        restaurantList &&
            restaurantList.restaurants.map((restaurant) => {
                restaurant.value = restaurant.id;
                restaurant.text = restaurant.name;
                return null;
            });
        dispatch({
            type: 'set-restaurant-list',
            payload: {
                restaurantList: restaurantList,
            },
        });

        // As user has selected the restaurant, Let's fetch users permission for restaurant.
        let userPermissions = responses[2];

        let permissionsMap = {};
        userPermissions.user_permissions.forEach((permission) => {
            permissionsMap.hasOwnProperty(permission.name)
                ? permissionsMap[permission.name].push(permission.permission)
                : (permissionsMap[permission.name] = [permission.permission]);
        });
        // Let's store this permission in reducer.
        dispatch({
            type: 'set-permission',
            payload: {
                permission: permissionsMap,
            },
        });

        // User has selected the Rstaurant let's get all the restaurant language.
        // Fetch All Languages
        let languages = responses[3];

        dispatch({
            type: 'set-available-languages',
            payload: {
                availableLanguages: languages.languages,
            },
        });
        let language_id = localStorage.getItem('language');
        if (!language_id) {
            let id = null;
            languages.languages.map((language) => {
                if (language.language === 'en') {
                    dispatch({
                        type: 'set-language-id',
                        payload: {
                            languageId: language.language_id,
                        },
                    });
                    localStorage.setItem('language', language.language_id);
                }
                return null;
            });
            if (!id) {
                dispatch({
                    type: 'set-language-id',
                    payload: {
                        languageId: languages.languages[0].language_id,
                    },
                });
                localStorage.setItem('language', languages.languages[0].language_id);
            }
        } else {
            let idState = false;
            languages.languages.map((language) => {
                if (language.language_id === language_id) {
                    dispatch({
                        type: 'set-language-id',
                        payload: {
                            languageId: language.language_id,
                        },
                    });
                    localStorage.setItem('language', language.language_id);
                    idState = true;
                }
                return null;
            });
            if (!idState) {
                languages.languages.map((language) => {
                    if (language.language === 'en') {
                        dispatch({
                            type: 'set-language-id',
                            payload: {
                                languageId: language.language_id,
                            },
                        });
                        localStorage.setItem('language', language.language_id);
                    }
                    return null;
                });
            }
        }

        // outlet list
        let outlets = responses[4];

        let outletArray = [];
        let languageId = localStorage.getItem('language');
        outlets.outlets.forEach((outlet) => {
            outletArray.push({
                value: outlet.outlet_id,
                inputDisplay: outlet.translations.name[languageId],
                outlet_data: outlet,
            });
        });
        dispatch({
            type: outletTypes.SET_OUTLET_LIST,
            payload: {
                outletList: outletArray,
            },
        });
        if (outletArray.length) {
            dispatch({
                type: outletTypes.SET_SELECTED_OUTLET_ID,
                payload: {
                    selectedOutletId: outletArray[0].value,
                },
            });
            dispatch({
                type: outletTypes.SET_SELECTED_OUTLET,
                payload: {
                    selectedOutlet: outletArray[0],
                },
            });
        }
        dispatch({
            type: types.SET_RESTAURANT_ID,
            payload: {
                restaurantId: restaurantId,
            },
        });
        dispatch({
            type: 'set-restaurant-loader',
            payload: {
                restaurantLoader: false,
            },
        });

        let notificationSoundLoop = localStorage.getItem('notificationSoundLoop');
        if (notificationSoundLoop == null) {
            localStorage.setItem('notificationSoundLoop', 1);
        }

        let petPoojaSync = responses[5];

        dispatch({
            type: 'set-pet-pooja-synced',
            payload: {
                petPoojaSynced: { synced: petPoojaSync.success, message: petPoojaSync.message },
            },
        });

        let credit_alerts = responses[6];

        if (!isEmpty(credit_alerts)) {
            dispatch({ type: 'get_credit_alerts', payload: credit_alerts });
        }

        history?.push('/');
    };
};
