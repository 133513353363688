import { EuiFlexGroup, EuiFlexItem, EuiHorizontalRule } from '@elastic/eui';
import { capitalize } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import AvailabilitySwitch from './Components/AvailabilitySwitch';

const VariantOption = ({ option, handleAvailablityModal, fetchItemVariantsData }) => {
    const defaultButtonColor =
        option.in_stock_ordering_mode > 0 &&
        option.total_ordering_modes > 0 &&
        option.in_stock_ordering_mode !== option.total_ordering_modes
            ? 'warning'
            : option.in_stock_ordering_mode > 0 &&
              option.total_ordering_modes > 0 &&
              option.in_stock_ordering_mode === option.total_ordering_modes
            ? 'success'
            : option.in_stock_ordering_mode === 0 && option.total_ordering_modes === 0
            ? 'danger'
            : 'danger';

    const buttonPosition =
        option.in_stock_ordering_mode > 0 &&
        option.total_ordering_modes > 0 &&
        option.in_stock_ordering_mode !== option.total_ordering_modes
            ? 'partial_state'
            : option.in_stock_ordering_mode > 0 &&
              option.total_ordering_modes > 0 &&
              option.in_stock_ordering_mode === option.total_ordering_modes
            ? 'turned_on'
            : option.in_stock_ordering_mode === 0 && option.total_ordering_modes === 0
            ? 'turned_off'
            : 'turned_off';

    const [toggleCompressedIdSelected, setToggleCompressedIdSelected] = useState(buttonPosition);
    const [buttonColor, setButtonColor] = useState(defaultButtonColor);

    const languageId = useSelector((state) => state.language.languageId);

    const handleAvailabilitySwitchChange = useCallback(
        (optionId, isCategory, selectedElement) => {
            let callbackData = {
                showModal: true,
            };
            setToggleCompressedIdSelected(optionId);
            if (optionId === 'turned_off') {
                setButtonColor('danger');
            } else if (optionId === 'partial_state') {
                setButtonColor('warning');
            } else if (optionId === 'turned_on') {
                setButtonColor('success');
            }

            if (optionId === 'turned_off' || optionId === 'turned_on') {
                callbackData.modalResponse = {
                    data: selectedElement,
                    isActive: optionId === 'turned_off' ? 0 : 1,
                    option_id: selectedElement.id,
                };
            }

            handleAvailablityModal({
                showModal: true,
                modalResponse: callbackData.modalResponse,
                variant_option_id: option.id,
                clearSwitchStateOnClose: () => {
                    setToggleCompressedIdSelected(buttonPosition);
                    setButtonColor(buttonColor);
                },
                fetchUpdatedVariantOption: () => {
                    fetchItemVariantsData();
                },
            });
        },
        [buttonColor, buttonPosition, fetchItemVariantsData, handleAvailablityModal, option.id]
    );

    return (
        <React.Fragment>
            <EuiHorizontalRule size='full' margin='xs' />
            <EuiFlexGroup responsive={false} style={{ marginLeft: 0 }} alignItems='center'>
                <EuiFlexItem className='flex-horizontal-center'>
                    <EuiFlexGroup responsive={false} gutterSize='none'>
                        <EuiFlexItem>
                            <span>{capitalize(option.translations.title[languageId])}</span>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>
                <EuiFlexItem className='flex-horizontal-center' grow={false}>
                    <AvailabilitySwitch
                        buttonColor={buttonColor}
                        handleAvailabilitySwitchChange={handleAvailabilitySwitchChange}
                        toggleCompressedIdSelected={toggleCompressedIdSelected}
                        buttonSize={'compressed'}
                        isCategory={false}
                        selectedElement={option}
                        name={option.id.toString()}
                    />
                </EuiFlexItem>
            </EuiFlexGroup>
        </React.Fragment>
    );
};

export default React.memo(VariantOption);
