import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import RiderList from '../../../components/riders/riderList';
import { NoOutletMessage } from '../../../utils/noOutletMessage';
import AddRiderAction from './AddRiderAction';

const RiderDataGridList = () => {
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);
    const [refetch, setRefetch] = useState(false);

    const setRefetchCallback = useCallback((value) => {
        setRefetch(value);
    }, []);

    return (
        <>
            <AddRiderAction setRefetch={setRefetch} />
            {selectedOutletId ? (
                <RiderList setRefetch={setRefetchCallback} refetch={refetch} id={selectedOutletId} />
            ) : (
                <NoOutletMessage />
            )}
        </>
    );
};

export default RiderDataGridList;
