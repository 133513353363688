import { EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiSwitch, EuiText } from '@elastic/eui';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import APIV3 from '../../api/axios/APIV3';
import { getOutletList } from '../../api/outlet/getOutletList';
import { userHasPermission } from '../../helper/auth';
import OutletAvailabiliyPopup from '../settings/outlet-mode-setting/outletAvailabiliyPopup';

const OrdersAvailability = ({ orderingMode, outeltData }) => {
    const permissions = useSelector((state) => state.permission.permission);
    const [orderModeSwitchState, setOrderModeSwitchState] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (orderingMode.is_available) {
            setOrderModeSwitchState(orderingMode.is_available);
        }
    }, [orderingMode.is_available]);

    const onChangeSwitchState = useCallback(async () => {
        await APIV3.patch(`restaurants/:restaurantId/outlets/${outeltData.outlet_id}/update-mode`, {
            next_available_time: null,
            restaurant_ordering_mode_id: orderingMode.restaurant_ordering_mode_id,
            is_available: 1,
        }).then((res) => {
            if (res.success) {
                setOrderModeSwitchState(true);
                dispatch(getOutletList());
                return;
            }
            if (res.message) {
                alert(res.message);
            }
        });
    }, [dispatch, orderingMode.restaurant_ordering_mode_id, outeltData.outlet_id]);

    return (
        <>
            <EuiSpacer />
            <EuiFlexGroup direction='row' justifyContent='spaceBetween' alignItems='center'>
                <EuiFlexItem grow={8}>
                    <EuiText size='xs' style={{ textTransform: 'capitalize' }}>
                        {orderingMode?.display_name} On/Off
                    </EuiText>
                </EuiFlexItem>
                <EuiFlexItem grow={2}>
                    <EuiSwitch
                        disabled={!userHasPermission(permissions, '/outlet-mode', 'write')}
                        label={orderModeSwitchState ? 'on' : 'off'}
                        checked={orderModeSwitchState ? true : false}
                        onChange={(e) => (orderModeSwitchState ? setIsModalVisible(true) : onChangeSwitchState())}
                        compressed
                    />
                </EuiFlexItem>
            </EuiFlexGroup>
            {isModalVisible && (
                <OutletAvailabiliyPopup
                    closeModal={() => setIsModalVisible(false)}
                    setStateUpdate={setOrderModeSwitchState}
                    restaurant_ordering_mode_id={orderingMode.restaurant_ordering_mode_id}
                    outletId={outeltData.outlet_id}
                    outlet_details={outeltData}
                />
            )}
        </>
    );
};

export default OrdersAvailability;
