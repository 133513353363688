export const SET_CATEGORY_LIST = 'set-category-list';
export const CHANGE_SELECTED_CATEGORY = 'change-selected-category';
export const SET_CATEGORY_TREE = 'set-category-tree';
export const SET_ADDON_LIST = 'set-addon-list';
export const SET_SELECTED_ADDON = 'set-selected-addon';
export const ACTIVE_CATEGORY = 'active-category';
export const ACTIVE_EDIT_CATEGORY_FLYOUT = 'active-edit-category-flyout';
export const ACTIVE_CATEGORY_ITEM_LIST = 'active-category-item-list';
export const SET_LANGUAGE_ID = 'set-language-id';
export const SET_MENU_LIST = 'set-menu-list';
export const SET_ACTIVE_CATEGORY_ID = 'set-active-category-id';
export const SET_ACTIVE_ITEM_ID = 'set-active-item-id';
export const SET_CATEGORIES = 'set-categories';
export const EDIT_CATEGORY = 'edit-category';
export const EDIT_ITEM = 'edit-item';
export const SET_DISABLE_BUTTON = 'set-disable-button';
export const SET_CATEGORY_TREE_STRUCTURE = 'set-category-tree-structure';
export const SET_ADDON = 'set-addon';
export const SET_ADDON_SELECTED_LIST = 'set-addon-selected-list';
export const SET_ADDON_ITEM_EDIT_SELECTED_LIST = 'set-addon-item-edit-selected-list';
export const SET_DELETE_CONFIRMATION_MODAL = 'set-delete-confirmation-modal';
export const SET_DELETE_CATEGORY_ID = 'set-delete-category-id';
export const SET_CATEGORY_FLAG = 'set-category-flag';
export const SET_CATEGORY_STATE = 'set-category-state';
export const SET_ADDON_STATE = 'set-addon-state';
export const SET_VARIANT_ADDON_LINK = 'set-variant-addon-link';
export const SET_VARIANT_GROUP = 'set-variant-group';
export const SET_VARIANT_GROUP_TRANSLATIONS = 'set-variant-group-translations';
export const SET_VARIANT_MODAL_SAVED = 'set-variant-modal-saved';
export const SET_ADD_CATEGORY_ID = 'set-add-category';
export const SET_SHIFT_ITEM_LIST = 'set-shift-item-list';
export const SET_NEW_ITEM_ID_DELETE = 'set-new-item-id-delete';
export const SET_MAP_ITEM_LIST = 'set_map_item_list';
export const SET_UPSELLING_ITEM_EDIT_SELECTED_LIST = 'set-upselling-item-edit-selected-list';
export const REMOVE_UPSELLING_ITEM_EDIT_SELECTED_LIST = 'remove-upselling-item-edit-selected-list';
