import { EuiButton, EuiFlexItem, EuiPopover, EuiSuperSelect } from '@elastic/eui';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { userHasPermission } from '../../../helper/auth';
import { offerTypes } from '../../../components/offer/Utils/utils';

const OfferSelection = () => {
    const permissions = useSelector((state) => state.permission.permission);
    const restaurantOrderingModes = useSelector((state) => state.restaurantReducer.orderingModes);

    const history = useHistory();

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const isDeliveryUnavailable = restaurantOrderingModes.filter(
        (orderingMode) => !orderingMode.status && orderingMode.type === 'delivery'
    )?.length;

    const options = [
        {
            value: '0',
            inputDisplay: 'Select offer',
        },
        ...offerTypes.filter((type) => {
            type.inputDisplay = type.displayName;

            return !isDeliveryUnavailable ? type : type.value !== 'free_delivery';
        }),
    ];

    const onClosePopover = () => {
        setIsPopoverOpen(!isPopoverOpen);
    };

    const onChangeOffer = (value) => {
        setIsPopoverOpen(false);

        if (value !== '0') {
            history.push(`/offers/create?offerType=${value}`);
        }
    };

    return (
        <>
            {userHasPermission(permissions, '/offers', 'write') && (
                <EuiFlexItem grow={false}>
                    <EuiPopover
                        button={
                            <EuiButton fill iconType='plusInCircleFilled' onClick={() => setIsPopoverOpen(true)}>
                                Add Offer
                            </EuiButton>
                        }
                        isOpen={isPopoverOpen}
                        closePopover={onClosePopover}
                    >
                        <EuiFlexItem style={{ width: 300 }}>
                            <EuiSuperSelect options={options} value={options[0].value} onChange={onChangeOffer} />
                        </EuiFlexItem>
                    </EuiPopover>
                </EuiFlexItem>
            )}
        </>
    );
};

export default OfferSelection;
