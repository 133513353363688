import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiTab, EuiTabs, EuiText } from '@elastic/eui';
import { userHasPermission } from '../../helper/auth';
import { getRestaurantBanners } from '../../api/banner/getRestaurantBanners';
import BannerCatalogue from '../../modules/Banners/Tabs/BannerCatalogue';
import { useHistory } from 'react-router-dom';
import OutletBanners from '../../modules/Banners/Tabs/OutletBanners';

const Banner = (props) => {
    const { setShowOutlet } = props;
    const [selectedTab, setSelectedTab] = useState('banner_catalog');
    const permissions = useSelector((state) => state.permission.permission);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (userHasPermission(permissions, '/restaurant-settings', 'read')) {
            setSelectedTab('banner_catalog');
        } else {
            setSelectedTab('outlet_banners');
        }
    }, [permissions]);

    const onChangeTab = useCallback(
        (id) => {
            setSelectedTab(id);

            if (id == 'link_banners' || id == 'outlet_banners') {
                setShowOutlet(true);
            } else {
                setShowOutlet(false);
            }
        },
        [setShowOutlet]
    );

    // state for api calll

    const fetchRecords = useCallback(async () => {
        await dispatch(getRestaurantBanners());
    }, [dispatch]);

    useEffect(() => {
        fetchRecords();
    }, [fetchRecords]);

    const selectedComponent = useCallback(() => {
        switch (selectedTab) {
            case 'banner_catalog':
                return <BannerCatalogue />;
            case 'outlet_banners':
                return <OutletBanners />;

            default:
        }
    }, [selectedTab]);

    const handleSubTabChange = useCallback(
        (tab) => {
            onChangeTab(tab);
        },
        [onChangeTab]
    );
    const handleAddBanner = useCallback(() => {
        history.push('/banners/create');
    }, [history]);

    return (
        <>
            <EuiFlexGroup direction='column'>
                <EuiFlexItem>
                    <EuiTabs size='s'>
                        <EuiFlexGroup>
                            <EuiFlexItem grow={false}>
                                <EuiTab
                                    isSelected={selectedTab === 'banner_catalog'}
                                    onClick={() => handleSubTabChange('banner_catalog')}
                                >
                                    Banner Catalogue
                                </EuiTab>
                            </EuiFlexItem>
                            <EuiFlexItem grow={false}>
                                <EuiTab
                                    isSelected={selectedTab === 'outlet_banners'}
                                    onClick={() => handleSubTabChange('outlet_banners')}
                                >
                                    Store Banners
                                </EuiTab>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                        <EuiFlexItem grow={false} style={{ marginBottom: '6px' }}>
                            {userHasPermission(permissions, '/banners', 'write') && selectedTab === 'banner_catalog' && (
                                <EuiButton fill iconType='plusInCircleFilled' onClick={handleAddBanner} size='s'>
                                    <EuiText size='s'>Add Banner</EuiText>
                                </EuiButton>
                            )}
                        </EuiFlexItem>
                    </EuiTabs>
                </EuiFlexItem>
                <EuiFlexItem>{selectedComponent()}</EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer size='s' />
        </>
    );
};

export default React.memo(Banner);
