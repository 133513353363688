import * as types from '../types/menu';
import { flatToHierarchy } from '../../helper/flatToHierarchy';

const initialState = {
    selectedCategory: null,
    categoryListTree: null,
    categoryList: [],
    addonList: [],
    selectedAddon: null,
    activeCategory: null,
    activeEditCategoryFlyout: { status: 'Add', state: false },
    activeCategoryItemList: [],
    languageId: '',
    menuList: {},
    activeCategoryId: false,
    activeItemId: false,
    categories: [],
    categoriesTreeStructure: '',
    editCategory: {},
    editItem: {},
    buttonDisable: false,
    addOnList: [],
    selectedAddOnList: [],
    selectedItemEditAddOnList: [],
    selectedItemEditUpsellingList: [],
    removedItemEditUpsellingList: [],
    showDeleteConfirmationModal: false,
    deleteCategoryId: null,
    isHaveSubCategory: true,
    categoryState: true,
    addonState: true,
    variantAddonLink: {},
    variantGroup: [],
    variantGroupTranslations: {},
    variantModalSaved: false,
    addCategory: null,
    shiftItemList: [],
    newIdItemDelete: false,
    mapItemList: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.SET_CATEGORY_LIST:
            let categories = flatToHierarchy(action.payload);
            return {
                ...state,
                categoryList: action.payload,
                categoryListTree: flatToHierarchy(action.payload),
                selectedCategory: state.selectedCategory
                    ? state.selectedCategory
                    : categories.length > 0
                    ? categories[0].id
                    : null,
            };
        case types.CHANGE_SELECTED_CATEGORY:
            return {
                ...state,
                selectedCategory: action.payload.selectedCategory,
            };
        case types.SET_CATEGORY_TREE:
            return {
                ...state,
                categoryListTree: action.payload.categoryListTree,
            };
        case types.SET_ADDON_LIST:
            return {
                ...state,
                addonList: action.payload.addonList,
            };
        case types.SET_SELECTED_ADDON:
            return {
                ...state,
                selectedAddon: action.payload.selectedAddon,
            };
        case types.ACTIVE_CATEGORY:
            return {
                ...state,
                activeCategory: action.payload.activeCategory,
            };
        case types.ACTIVE_EDIT_CATEGORY_FLYOUT:
            return {
                ...state,
                activeEditCategoryFlyout: action.payload.activeEditCategoryFlyout,
            };
        case types.ACTIVE_CATEGORY_ITEM_LIST:
            return {
                ...state,
                activeCategoryItemList: action.payload.activeCategoryItemList,
            };
        case types.SET_LANGUAGE_ID:
            return {
                ...state,
                languageId: action.payload.languageId,
            };
        case types.SET_MENU_LIST:
            return {
                ...state,
                menuList: action.payload.menuList,
            };
        case types.SET_ACTIVE_ITEM_ID:
            return {
                ...state,
                activeItemId: action.payload.activeItemId,
            };
        case types.SET_ACTIVE_CATEGORY_ID:
            return {
                ...state,
                activeCategoryId: action.payload.activeCategoryId,
            };
        case types.SET_CATEGORIES:
            return {
                ...state,
                categories: action.payload.categories,
            };
        case types.EDIT_CATEGORY:
            return {
                ...state,
                editCategory: action.payload.editCategory,
            };
        case types.EDIT_ITEM:
            return {
                ...state,
                editItem: action.payload.editItem,
            };
        case types.SET_DISABLE_BUTTON:
            return {
                ...state,
                buttonDisable: action.payload.buttonDisable,
            };
        case types.SET_CATEGORY_TREE_STRUCTURE:
            return {
                ...state,
                categoriesTreeStructure: action.payload.categoriesTreeStructure,
            };
        case types.SET_ADDON:
            return {
                ...state,
                addOnList: action.payload.addOnList,
            };
        case types.SET_ADDON_SELECTED_LIST:
            return {
                ...state,
                selectedAddOnList: action.payload.selectedAddOnList,
            };
        case types.SET_ADDON_ITEM_EDIT_SELECTED_LIST:
            return {
                ...state,
                selectedItemEditAddOnList: action.payload.selectedItemEditAddOnList,
            };
        case types.SET_UPSELLING_ITEM_EDIT_SELECTED_LIST:
            return {
                ...state,
                selectedItemEditUpsellingList: action.payload.selectedItemEditUpsellingList,
            };
        case types.REMOVE_UPSELLING_ITEM_EDIT_SELECTED_LIST:
            return {
                ...state,
                removedItemEditUpsellingList: action.payload.removedItemEditUpsellingList,
            };
        case types.SET_DELETE_CONFIRMATION_MODAL:
            return {
                ...state,
                showDeleteConfirmationModal: action.payload.showDeleteConfirmationModal,
            };
        case types.SET_DELETE_CATEGORY_ID:
            return {
                ...state,
                deleteCategoryId: action.payload.deleteCategoryId,
            };
        case types.SET_CATEGORY_FLAG:
            return {
                ...state,
                isHaveSubCategory: action.payload.isHaveSubCategory,
            };
        case types.SET_CATEGORY_STATE:
            return {
                ...state,
                categoryState: action.payload.categoryState,
            };
        case types.SET_ADDON_STATE:
            return {
                ...state,
                addonState: action.payload.addonState,
            };
        case types.SET_VARIANT_ADDON_LINK:
            return {
                ...state,
                variantAddonLink: action.payload.variantAddonLink,
            };
        case types.SET_VARIANT_GROUP:
            return {
                ...state,
                variantGroup: action.payload.variantGroup,
            };
        case types.SET_VARIANT_GROUP_TRANSLATIONS:
            return {
                ...state,
                variantGroupTranslations: action.payload.variantGroupTranslations,
            };
        case types.SET_VARIANT_MODAL_SAVED:
            return {
                ...state,
                variantModalSaved: action.payload.variantModalSaved,
            };
        case types.SET_ADD_CATEGORY_ID:
            return {
                ...state,
                addCategory: action.payload.addCategory,
            };
        case types.SET_SHIFT_ITEM_LIST:
            return {
                ...state,
                shiftItemList: action.payload.shiftItemList,
            };
        case types.SET_NEW_ITEM_ID_DELETE:
            return {
                ...state,
                newIdItemDelete: action.payload.newIdItemDelete,
            };
        case types.SET_MAP_ITEM_LIST:
            return {
                ...state,
                mapItemList: action.payload,
            };

        default:
            return state;
    }
};
