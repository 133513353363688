import React, { useCallback } from 'react';
import { EuiForm, EuiSpacer } from '@elastic/eui';
import FormGroupDescription from '../../../components/formGroupDescription';
import ButtonGroup from '../../../components/Form/ButtonGroup';
import { useDispatch, useSelector } from 'react-redux';
import { outletSettingsAPI } from '../../../api/outlet/outletSettingsAPI';
import useOutletFormValues from '../Hooks/useOutletFormValues';
import useFormActionsHandler from '../../../hooks/useFormActionsHandler';

const OtherSettings = () => {
    const dispatch = useDispatch();
    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);

    const updateFormValues = useCallback((data) => {
        return {
            enable_auto_accept_order: data.settings.enable_auto_accept_order,
            show_best_selling_category: data?.settings?.show_best_selling_category,
            show_offer_suggestion: data?.settings?.show_offer_suggestion,
        };
    }, []);

    const { defaultValues, setDefaultValues, onSuccessCallback } = useOutletFormValues({ updateFormValues });

    const onFormSaveApi = useCallback(
        async (data) => {
            const response = await dispatch(outletSettingsAPI('other_settings', data));
            if (response.success) setDefaultValues(updateFormValues(response.outlet));
            return response;
        },
        [dispatch, updateFormValues]
    );

    const { control, watch, setValue } = useFormActionsHandler({ onFormSaveApi, defaultValues, onSuccessCallback });

    return (
        <EuiForm component='form'>
            <FormGroupDescription
                title={restaurantDescriptions?.auto_accept?.display_name}
                description={restaurantDescriptions?.auto_accept?.description}
            >
                <ButtonGroup
                    idSelected={watch('enable_auto_accept_order') ? '1' : '0'}
                    color='primary'
                    control={control}
                    setValue={setValue}
                    name='enable_auto_accept_order'
                    watch={watch}
                    label={'Enable auto accept order'}
                />
            </FormGroupDescription>
            <EuiSpacer />
            <FormGroupDescription
                title={'Show Best Seller Category'}
                description={'Show Best Seller Category In Hom-Screen'}
            >
                <ButtonGroup
                    idSelected={watch('show_best_selling_category') ? '1' : '0'}
                    color='primary'
                    control={control}
                    setValue={setValue}
                    name='show_best_selling_category'
                    watch={watch}
                    label={'Enable Best Seller Category'}
                />
            </FormGroupDescription>
            <EuiSpacer />
            <FormGroupDescription
                title={'Show Offer Suggestions'}
                description={'Show Offer Suggestions In Category detail Screen'}
            >
                <ButtonGroup
                    idSelected={watch('show_offer_suggestion') ? '1' : '0'}
                    color='primary'
                    control={control}
                    setValue={setValue}
                    name='show_offer_suggestion'
                    watch={watch}
                    label={'Enable Offer Suggestions'}
                />
            </FormGroupDescription>
            <EuiSpacer />
        </EuiForm>
    );
};

export default OtherSettings;
