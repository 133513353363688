export const userHasPermission = (permissionMap, routeName, accesslevel) => {
    let moduleName = null;
    switch (routeName) {
        case '/':
            moduleName = 'dashboard';
            break;

        case '/users':
            moduleName = 'users';
            break;

        case '/menu':
            moduleName = 'menu';
            break;

        case '/menu-copy':
            moduleName = 'menu';
            break;

        case '/category':
            moduleName = 'menu';
            break;

        case '/item_availability':
            moduleName = 'item_availability';
            break;

        case '/notifications':
            moduleName = 'notifications';
            break;

        case '/live-orders':
            moduleName = 'live_orders';
            break;

        case '/past-orders':
            moduleName = 'past_orders';
            break;

        case '/failed-orders':
            moduleName = 'live_orders';
            break;

        case '/abandoned-carts':
            moduleName = 'customers';
            break;

        case '/offers':
            moduleName = 'offers';
            break;

        case '/banners':
            moduleName = 'banners';
            break;

        case '/restaurant-settings':
            moduleName = 'restaurant';
            break;

        case '/rider-details':
            moduleName = 'rider';
            break;

        case '/outlet-settings':
            moduleName = 'outlet';
            break;

        case '/order-rating':
            moduleName = 'order-rating';
            break;

        case '/outlet-mode':
            moduleName = 'outlet-mode';
            break;

        case '/pet-pooja-link':
            moduleName = 'menu';
            break;

        case '/cashback':
            moduleName = 'customer_reward_points';
            break;

        case '/printers':
            moduleName = 'device-settings';
            break;

        case '/device-settings':
            moduleName = 'device-settings';
            break;
        case '/credits':
            moduleName = 'infrastructure_credit';
            break;
        case '/reports':
            moduleName = 'reports';
            break;
        case '/media-library':
            moduleName = 'media_library';
            break;
        case '/bill-payment':
            moduleName = 'bill_payment';
            break;

        case '/table-management':
            moduleName = 'table_management';
            break;

        case '/offers/edit':
        case '/offers/create': {
            moduleName = 'offers';
            break;
        }

        case '/banners/edit':
        case '/banners/create': {
            moduleName = 'banners';
            break;
        }

        default:
            break;
    }

    if (routeName.includes('/table-management')) {
        moduleName = 'table_management';
    }
    if (routeName.includes('/offers/edit')) {
        moduleName = 'offers';
    }
    if (routeName.includes('/banners/edit')) {
        moduleName = 'banners';
    }
    if (routeName.includes('/customer')) {
        moduleName = 'customers';
    }
    if (routeName.includes('/rider')) {
        moduleName = 'rider';
    }

    if (routeName.includes('/category')) {
        moduleName = 'menu';
    }

    if (!moduleName) {
        return false;
    }

    if (moduleName === 'device-settings') return true;

    if (permissionMap && !permissionMap.hasOwnProperty(moduleName)) {
        return false;
    }

    return permissionMap !== null ? permissionMap[moduleName].includes(accesslevel) : '';
};
