import { EuiLink } from '@elastic/eui';
import React, { useState } from 'react';
import BannerLinkFlyout from '../../../../components/banner/BannerCatalogue/BannerLinkFlyout';

const LinkBanner = ({ banner }) => {
    const [bannerLinkFlyout, setBannerLinkFlyout] = useState({
        banner: null,
        display: false,
    });

    const toggleFlyout = (banner) => {
        setBannerLinkFlyout((prevState) => ({
            display: !prevState.display,
            banner: banner ? banner : null,
        }));
    };

    return (
        <>
            <BannerLinkFlyout bannerLinkFlyout={bannerLinkFlyout} toggleFlyout={toggleFlyout} />
            <EuiLink size='s' onClick={() => toggleFlyout(banner)}>
                Link
            </EuiLink>
        </>
    );
};

export default LinkBanner;
