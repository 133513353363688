import { EuiFlexGroup, EuiFlexItem, EuiPanel, EuiSpacer, EuiText } from '@elastic/eui';
import React from 'react';
import { useSelector } from 'react-redux';
import VariantLinkOrderingModes from './VariantLinkOrderingModes';

const ItemVariantsList = ({
    option,
    selectedParentOption,
    variantOptions,
    optionIndex,
    category_id,
    fieldIndex,
    handleVariantOptions,
    item,
    selectedItemForVariants,
}) => {
    const languageId = useSelector((state) => state.language.languageId);
    const outletOrderingModesEnabled = useSelector((state) => state.outlet.orderingModes)?.filter(
        (orderingMode) => orderingMode?.is_enabled
    );

    return (
        <div style={{ marginLeft: '20px' }} key={option.id}>
            <EuiPanel hasShadow={true}>
                <EuiFlexGroup direction='column' justifyContent='spaceBetween' gutterSize='s' alignItems='flexStart'>
                    <EuiFlexItem>
                        <EuiText size='s' style={{ fontWeight: 'bold' }}>
                            {option.translations.title[languageId]} - {item.internal_name}
                        </EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <EuiFlexGroup wrap alignItems='flexStart'>
                            {option?.ordering_modes?.map((orderingMode, modeIndex) => {
                                return outletOrderingModesEnabled?.map((enabledMode) => {
                                    return (
                                        <React.Fragment key={enabledMode.restaurant_ordering_mode_id.toString()}>
                                            {enabledMode.restaurant_ordering_mode_id ===
                                            orderingMode.restaurant_ordering_mode_id ? (
                                                <EuiFlexItem style={{ minWidth: 240 }}>
                                                    <VariantLinkOrderingModes
                                                        key={orderingMode.restaurant_ordering_mode_id.toString()}
                                                        optionOrderingMode={orderingMode}
                                                        category_id={category_id}
                                                        fieldIndex={fieldIndex}
                                                        modeIndex={modeIndex}
                                                        uniqueVariantOptions={
                                                            variantOptions[orderingMode.restaurant_ordering_mode_id]
                                                        }
                                                        selectedParentOption={selectedParentOption[item.item_id]}
                                                        selectedItemForVariants={
                                                            selectedItemForVariants[
                                                                `${item.item_id}_${orderingMode.restaurant_ordering_mode_id}`
                                                            ]
                                                        }
                                                        option={option}
                                                        item={item}
                                                        optionIndex={optionIndex}
                                                        handleVariantOptions={handleVariantOptions}
                                                    />
                                                </EuiFlexItem>
                                            ) : null}
                                        </React.Fragment>
                                    );
                                });
                            })}
                        </EuiFlexGroup>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiPanel>
            <EuiSpacer siz='s' />
        </div>
    );
};

export default React.memo(ItemVariantsList);
