import React, { Fragment, useEffect, useState, memo, useCallback } from 'react';
import OfferList from '../../../components/offer/offerList';
import Pagination from '../../../components/Form/Pagination';
import API from '../../../api/axios/API';
import { EuiLoadingContent } from '@elastic/eui';
import ErrorMessage from '../../../components/UIComponent/ErrorMessage';
import { useSelector } from 'react-redux';

const OfferTable = () => {
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const refetchOffers = useSelector((state) => state.offersReducer.refetchOffers);

    const [offers, setOffers] = useState({});

    const fetchOffers = useCallback(async () => {
        setLoading(true);
        try {
            const offers = await API.get(
                `restaurants/:restaurantId/offers?page=${pagination.pageIndex + 1}&per_page=${pagination.pageSize}`
            );
            setOffers(offers);
            setLoading(false);
        } catch (e) {
            setError(e);
            setLoading(false);
        }
    }, [pagination.pageIndex, pagination.pageSize]);

    const handlePageChange = useCallback(
        (page) => {
            setPagination({
                ...pagination,
                pageIndex: page,
            });
        },
        [pagination]
    );

    useEffect(() => {
        fetchOffers();
    }, [refetchOffers, pagination.pageIndex, fetchOffers]);

    if (loading) return <EuiLoadingContent lines={4} />;
    if (error) return <ErrorMessage />;

    return (
        <Fragment>
            <OfferList offerList={offers} fetchOffers={fetchOffers} />
            {offers.offers.total > offers.offers.per_page && (
                <Pagination
                    totalRecord={offers.offers.total}
                    pageSize={offers.offers.per_page}
                    activePage={offers.offers.current_page - 1}
                    handlePageChange={handlePageChange}
                />
            )}
        </Fragment>
    );
};

export default memo(OfferTable);
