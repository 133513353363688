/* global localStorage */
import API from '../api/axios/API';

export const logoutCall = async (token) => {
    let deviceId = localStorage.getItem('deviceId');
    let res = await API.post('/logout', {
        device_id: deviceId,
    });

    if (res.success) {
        localStorage.removeItem('authToken');
        localStorage.removeItem('user');
        localStorage.removeItem('selectedRestaurant');
        localStorage.removeItem('holdOrder');
    }
};
