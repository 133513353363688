import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import TextField from '../../../components/Form/TextField';
import TextAreaField from '../../../components/Form/TextAreaField';
import SelectField from '../../../components/Form/SelectField';
import { allCountry } from '../../../components/settings/allCountry';
import { EuiSpacer, EuiForm } from '@elastic/eui';
import FormGroupDescription from '../../../components/formGroupDescription';
import { useDispatch } from 'react-redux';
import { outletSettingsAPI } from '../../../api/outlet/outletSettingsAPI';
import useOutletFormValues from '../Hooks/useOutletFormValues';
import useFormActionsHandler from '../../../hooks/useFormActionsHandler';

const AddressDetails = () => {
    const languages = useSelector((state) => state.language.availableLanguages);
    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);
    const outletId = useSelector((state) => state.outlet.selectedOutletId);
    let countryCode = [];
    for (const [key, value] of Object.entries(allCountry)) {
        countryCode.push({
            value: key,
            inputDisplay: value,
        });
    }

    const dispatch = useDispatch();

    const updateFormValues = useCallback((data) => {
        let defaultValue = {};

        let translationObject = {
            city: {},
            state: {},
            address_line_1: {},
            address_line_2: {},
        };

        for (const languageId in data.outlet_address.translations.city) {
            translationObject.city[languageId] = data.outlet_address.translations.city[languageId];
        }
        for (const languageId in data.outlet_address.translations.state) {
            translationObject.state[languageId] = data.outlet_address.translations.state[languageId];
        }
        for (const languageId in data.outlet_address.translations.address_line_1) {
            translationObject.address_line_1[languageId] = data.outlet_address.translations.address_line_1[languageId];
        }
        for (const languageId in data.outlet_address.translations.address_line_2) {
            translationObject.address_line_2[languageId] = data.outlet_address.translations.address_line_2[languageId];
        }

        defaultValue.translations = translationObject;
        defaultValue.city = data.outlet_address.city;
        defaultValue.state = data.outlet_address.state;
        defaultValue.iso_country_code = data.outlet_address.iso_country_code;

        return defaultValue;
    }, []);

    const { defaultValues, setDefaultValues, onSuccessCallback } = useOutletFormValues({ updateFormValues });

    const onFormSaveApi = async (data) => {
        const city = {};
        const state = {};
        const address_line_1 = {};
        const address_line_2 = {};
        data.id = null;
        if (outletId) {
            data.id = outletId;
        }

        for (const property in data.translations.city) {
            city[parseInt(property)] = data.translations.city[property];
        }
        for (const property in data.translations.state) {
            state[parseInt(property)] = data.translations.state[property];
        }
        for (const property in data.translations.address_line_1) {
            address_line_1[parseInt(property)] = data.translations.address_line_1[property];
        }
        for (const property in data.translations.address_line_2) {
            address_line_2[parseInt(property)] = data.translations.address_line_2[property];
        }

        data.translations.city = city;
        data.translations.state = state;
        data.translations.address_line_1 = address_line_1;
        data.translations.address_line_2 = address_line_2;

        data.address = {
            iso_country_code: data.iso_country_code,
            translations: {
                city: data.translations.city,
                state: data.translations.state,
                address_line_1: data.translations.address_line_1,
                address_line_2: data.translations.address_line_2,
            },
        };
        data.settings = {
            enable_auto_accept_order: data.enable_auto_accept_order,
        };
        delete data.translations.city;
        delete data.translations.state;
        delete data.translations.address_line_1;
        delete data.translations.address_line_2;

        const response = await dispatch(outletSettingsAPI('address', data));
        if (response.success) setDefaultValues(updateFormValues(response.outlet));
        return response;
    };

    const { control, errors, setValue, watch } = useFormActionsHandler({
        onFormSaveApi,
        defaultValues,
        onSuccessCallback,
    });

    return (
        <EuiForm component='form' style={{ marginTop: 20 }}>
            <FormGroupDescription
                title={restaurantDescriptions?.outlet_address?.display_name}
                description={restaurantDescriptions?.outlet_address?.description}
            >
                {languages?.map((item, index) => (
                    <TextAreaField
                        key={index}
                        errors={errors}
                        isInvalid={errors?.translations?.address_line_1?.hasOwnProperty(item.language_id)}
                        error={
                            errors?.translations?.address_line_1?.hasOwnProperty(item.language_id) &&
                            errors.translations.address_line_1[item.language_id]
                                ? errors.translations.address_line_1[item.language_id].message
                                : ''
                        }
                        label={index === 0 ? <strong>Address Line 1</strong> : ''}
                        name={`translations.address_line_1.${item.language_id}`}
                        placeholder='Write address line 1'
                        rules={{ required: 'Please write address line 1' }}
                        control={control}
                        rows={4}
                    />
                ))}

                <EuiSpacer margin='m' />

                {languages?.map((item, index) => (
                    <TextAreaField
                        key={index}
                        errors={errors}
                        isInvalid={
                            errors.translations &&
                            errors.translations.address_line_2 &&
                            errors.translations.address_line_2.hasOwnProperty(item.language_id)
                        }
                        error={
                            errors.translations &&
                            errors.translations.address_line_2 &&
                            errors.translations.address_line_2.hasOwnProperty(item.language_id) &&
                            errors.translations.address_line_2[item.language_id]
                                ? errors.translations.address_line_2[item.language_id].message
                                : ''
                        }
                        label={index === 0 ? <strong>Address Line 2</strong> : ''}
                        name={`translations.address_line_2.${item.language_id}`}
                        placeholder='Write address line 1'
                        rules={{ required: 'Please write address line 1' }}
                        control={control}
                        rows={4}
                    />
                ))}

                <EuiSpacer margin='m' />

                {languages?.map((item, index) => (
                    <React.Fragment key={index}>
                        <TextField
                            errors={errors}
                            isInvalid={
                                errors.translations &&
                                errors.translations.city &&
                                errors.translations.city.hasOwnProperty(item.language_id)
                            }
                            error={
                                errors.translations &&
                                errors.translations.city &&
                                errors.translations.city.hasOwnProperty(item.language_id) &&
                                errors.translations.city[item.language_id]
                                    ? errors.translations.city[item.language_id].message
                                    : ''
                            }
                            label={index === 0 ? <strong>City</strong> : ''}
                            name={`translations.city.${item.language_id}`}
                            placeholder='Please Enter city'
                            rules={{ required: 'Please enter city' }}
                            control={control}
                        />
                        <EuiSpacer />
                    </React.Fragment>
                ))}

                <EuiSpacer size='m' />

                {languages?.map((item, index) => (
                    <React.Fragment key={index}>
                        <TextField
                            errors={errors}
                            isInvalid={
                                errors.translations &&
                                errors.translations.state &&
                                errors.translations.state.hasOwnProperty(item.language_id)
                            }
                            error={
                                errors.translations &&
                                errors.translations.state &&
                                errors.translations.state.hasOwnProperty(item.language_id) &&
                                errors.translations.state[item.language_id]
                                    ? errors.translations.state[item.language_id].message
                                    : ''
                            }
                            label={index === 0 ? <strong>State</strong> : ''}
                            name={`translations.state.${item.language_id}`}
                            placeholder='Please Enter state'
                            rules={{ required: 'Please enter state' }}
                            control={control}
                        />
                        <EuiSpacer />
                    </React.Fragment>
                ))}
                <SelectField
                    errors={errors}
                    label={<b>Country</b>}
                    name='iso_country_code'
                    options={countryCode}
                    valueOfSelected={watch('iso_country_code') ? watch('iso_country_code') : 'IN'}
                    onChange={(value) => setValue('iso_country_code', value)}
                    placeholder='Please enter your country'
                    rules={{ required: 'Please Enter your country' }}
                    control={control}
                    defaultValue={watch('iso_country_code') ? watch('iso_country_code') : 'IN'}
                />
                <EuiSpacer />
            </FormGroupDescription>
        </EuiForm>
    );
};

export default React.memo(AddressDetails);
