import React from 'react';
import { useSelector } from 'react-redux';
import { EuiFlexItem, EuiText, EuiButtonIcon, EuiDraggable, EuiPanel, EuiIcon } from '@elastic/eui';

export default (props) => {
    const languageId = useSelector((state) => state.language.languageId);

    return (
        <>
            {props.addOnList.map((addOn, index) => {
                return (
                    <EuiDraggable
                        spacing='m'
                        key={index}
                        index={index}
                        draggableId={index.toString()}
                        customDragHandle={true}
                    >
                        {(provided) => (
                            <EuiPanel className='custom' paddingSize='m' style={{ width: '280px' }}>
                                {!props.disabled && (
                                    <EuiFlexItem grow={false} style={{ justifyContent: 'center' }}>
                                        <div {...provided.dragHandleProps}>
                                            <EuiIcon type='grab' />
                                        </div>
                                    </EuiFlexItem>
                                )}

                                <EuiFlexItem grow={1} style={{ flexDirection: 'row' }}>
                                    <EuiFlexItem>
                                        <EuiText>
                                            <h4>{addOn.translations.group_name[languageId.toString()]}</h4>
                                        </EuiText>
                                        <EuiText size='xs'>
                                            <span>Optional | Max All Selections</span>
                                        </EuiText>
                                    </EuiFlexItem>
                                    {!props.disabled && (
                                        <>
                                            <EuiButtonIcon
                                                aria-label='trash'
                                                color={'danger'}
                                                // style={{ height: '0px' }}
                                                onClick={() => props.addOnDelete(index)}
                                                iconType='trash'
                                            />
                                        </>
                                    )}
                                </EuiFlexItem>
                                <EuiFlexItem grow={2} style={{ overflowY: 'auto' }} className='scrollbar-sm'>
                                    {addOn.choices.map((choice, index) => {
                                        return (
                                            <div
                                                style={{
                                                    padding: '8px',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                }}
                                                key={index}
                                            >
                                                <div
                                                    style={{
                                                        height: '10px',
                                                        width: '10px',
                                                        background: '#e1e2e4',
                                                    }}
                                                ></div>
                                                <EuiText size='m' style={{ paddingLeft: '10px' }}>
                                                    {choice.translations.name[languageId.toString()]} ₹{choice.price}
                                                </EuiText>
                                            </div>
                                        );
                                    })}
                                </EuiFlexItem>
                            </EuiPanel>
                        )}
                    </EuiDraggable>
                    // </div>
                );
            })}
        </>
    );
};
