import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { EuiText, EuiBasicTable } from '@elastic/eui';
import OfferActions from '../../modules/Offers/Components/OfferActions';
import LinkOfferAction from '../../modules/Offers/Components/OfferList/LinkOfferAction';
import { offerTypes } from './Utils/utils';

const OfferList = ({ offerList, fetchOffers }) => {
    const currentLanguage = useSelector((state) => state.language.languageId);

    const columns = useMemo(() => {
        return [
            {
                field: 'coupon_code',
                name: 'Code',
                render: (code) => <EuiText size='s'>{code}</EuiText>,
            },
            {
                field: 'offer_type',
                name: 'Type',
                align: 'center',
                render: (type) => {
                    return <EuiText size='s'>{type}</EuiText>;
                },
            },
            {
                field: 'title',
                name: 'Title',
                align: 'center',
                render: (title) => {
                    return <EuiText size='s'>{title}</EuiText>;
                },
            },
            {
                field: 'description',
                name: 'Description',
                align: 'center',
                width: '25%',
            },
            {
                field: 'link_offer',
                name: 'Link Offer',
                align: 'center',
                render: (offer) => {
                    return <LinkOfferAction fetchOffers={fetchOffers} offer={offer} />;
                },
            },
            {
                field: 'offer_actions',
                name: 'Actions',
                align: 'right',
                render: (actions) => {
                    return <OfferActions actions={actions} />;
                },
            },
        ];
    }, []);

    const items = useMemo(() => {
        return offerList?.offers?.data?.length
            ? offerList?.offers?.data.map((item) => {
                  return {
                      offer_type: offerTypes.map((offer) => {
                          return offer.value === item.offer_type ? offer.displayName : null;
                      }),
                      title: item?.translations?.title?.[currentLanguage],
                      description: item?.translations?.description?.[currentLanguage],
                      coupon_code: item.coupon_code,
                      offer_actions: item,
                      link_offer: item,
                  };
              })
            : [];
    }, [currentLanguage, offerList?.offers?.data]);

    return (
        <EuiBasicTable tableCaption='Offers Entries' items={items} rowHeader='firstName' columns={columns} responsive />
    );
};

export default React.memo(OfferList);
