import React, { useMemo, useState, memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { EuiText, EuiDataGrid, EuiLink, EuiPagination, EuiFlexItem, EuiFlexGroup } from '@elastic/eui';
import * as types from '../../../reduxStore/types/customer';
import moment from 'moment';
import RupeeSymbol from '../../../components/rupeeComponent.js/index.js';
import { numberFormat } from '../../../utils/numberFormat';
import ContextMenu from '../../../components/Global/Pagination/ContextMenu';

const CustomerDataGrid = (props) => {
    const { getAllCustomer, activePage, rowSize } = props;

    const history = useHistory();
    const dispatch = useDispatch();

    const columns = useMemo(() => {
        return [
            {
                id: 'name',
                display: 'Name',
                displayAsText: 'Name',
                isSortable: true,
            },
            {
                id: 'contact_number',
                display: 'Contact Number',
                displayAsText: 'Contact Number',
                isSortable: false,
            },
            {
                id: 'email',
                display: 'Email',
                displayAsText: 'Email',
                isSortable: false,
            },
            {
                id: 'cashback',
                display: 'Cashback',
                displayAsText: 'Cashback',
            },
            {
                id: 'total_orders',
                display: 'Total Orders',
                displayAsText: 'Total Orders',
            },
            {
                id: 'first_order_date',
                display: 'First Order Date',
                displayAsText: 'First Order Date',
            },
            {
                id: 'last_order_date',
                display: 'Last Order Date',
                displayAsText: 'Last Order Date',
            },
            {
                id: 'join_date',
                display: 'Join Date',
                displayAsText: 'Join Date',
            },
            {
                id: 'life_time_value',
                display: 'Life Time value',
                displayAsText: 'Life Time value',
            },
            {
                id: 'order_ratings',
                display: 'Order Ratings',
                displayAsText: 'Order Ratings',
            },
        ];
    }, []);

    const customeOrderRedirect = useCallback(
        (customer) => {
            dispatch({
                type: types.SET_CUSTOMER_DETAILS,
                payload: {
                    customerDetails: customer,
                },
            });
            history.push(`/customer/${customer.id}`);
        },
        [dispatch, history]
    );

    const [visibleColumns, setVisibleColumns] = useState(() => columns.map(({ id }) => id));

    let mappedItems = useMemo(() => {
        return getAllCustomer?.customers?.data?.length > 0
            ? getAllCustomer.customers.data.map((item) => {
                  return {
                      name: (
                          <EuiText key={item.id + 'name'} onClick={() => customeOrderRedirect(item)}>
                              <EuiLink> {item.name} </EuiLink>
                          </EuiText>
                      ),

                      contact_number: (
                          <>
                              {
                                  <EuiText key={item.id + 'contact_number'} onClick={() => customeOrderRedirect(item)}>
                                      {' '}
                                      <EuiLink> {item.mobile_number} </EuiLink>{' '}
                                  </EuiText>
                              }
                          </>
                      ),
                      email: (
                          <>
                              {item.email ? (
                                  <EuiText key={item.id + 'email'} onClick={() => customeOrderRedirect(item)}>
                                      {' '}
                                      <EuiLink> {item.email} </EuiLink>{' '}
                                  </EuiText>
                              ) : (
                                  '--'
                              )}
                          </>
                      ),
                      cashback: (
                          <>{<EuiText key={item.id + 'cashback'}> {item.cashback ? item.cashback : 0}</EuiText>}</>
                      ),
                      total_orders: <>{<EuiText key={item.id + 'total_orders'}> {item.order_count}</EuiText>}</>,
                      first_order_date: (
                          <>
                              {
                                  <EuiText key={item.id + 'first_order_date'}>
                                      {' '}
                                      {item.first_order
                                          ? moment(item.first_order).format('DD-MM-YYYY hh:mm:ss A')
                                          : '--'}
                                  </EuiText>
                              }
                          </>
                      ),
                      last_order_date: (
                          <>
                              {
                                  <EuiText key={item.id + 'last_order_date'}>
                                      {' '}
                                      {item.last_order ? moment(item.last_order).format('DD-MM-YYYY hh:mm:ss A') : '--'}
                                  </EuiText>
                              }
                          </>
                      ),
                      join_date: (
                          <>
                              {
                                  <EuiText key={item.id + 'join_date'}>
                                      {' '}
                                      {item.join_date ? moment(item.join_date).format('DD-MM-YYYY hh:mm:ss A') : '--'}
                                  </EuiText>
                              }
                          </>
                      ),

                      life_time_value: (
                          <>
                              {
                                  <EuiText key={item.id + 'life_time_value'}>
                                      {item.life_time_value ? (
                                          <>
                                              <RupeeSymbol size='16' />
                                              {numberFormat(item.life_time_value)}
                                          </>
                                      ) : (
                                          <>
                                              <RupeeSymbol size='16' />
                                              <span>0.00</span>
                                          </>
                                      )}
                                  </EuiText>
                              }
                          </>
                      ),
                      order_ratings: (
                          <>
                              {
                                  <EuiText key={item.id + 'order_ratings'}>
                                      {' '}
                                      {item.order_ratings ? Number(item.order_ratings).toFixed(2) : '--'}
                                  </EuiText>
                              }
                          </>
                      ),
                      delivery_ratings: (
                          <>
                              {
                                  <EuiText key={item.id + 'delivery_ratings'}>
                                      {' '}
                                      {item.delivery_ratings ? Number(item.delivery_ratings).toFixed(2) : '--'}
                                  </EuiText>
                              }
                          </>
                      ),
                  };
              })
            : [];
    }, [customeOrderRedirect, getAllCustomer?.customers?.data]);

    const renderCellValue = useMemo(() => {
        return ({ rowIndex, columnId, setCellProps }) => {
            return mappedItems.hasOwnProperty(rowIndex) ? mappedItems[rowIndex][columnId] : null;
        };
    }, [mappedItems]);

    const PAGE_COUNT = getAllCustomer && Math.ceil(getAllCustomer.customers.total / rowSize);

    const onChangeRowSize = useCallback(
        (row_size) => {
            // setActivePage(0);
            props.onPageChange(0);
            // setRowSize(row_size);
            props.onRowSizeChange(row_size);
        },
        [props]
    );

    const goToPage = (pageNumber) => props.onPageChange(pageNumber);

    const popOverItems = useMemo(() => {
        return [
            {
                key: '10 rows',
                onClick: (closePopover) => {
                    closePopover();
                    onChangeRowSize(10);
                },
                iconSize: 10,
            },
            {
                key: '20 rows',
                onClick: (closePopover) => {
                    closePopover();
                    onChangeRowSize(20);
                },
                iconSize: 20,
            },
            {
                key: '50 rows',
                onClick: (closePopover) => {
                    closePopover();
                    onChangeRowSize(50);
                },
                iconSize: 50,
            },
            {
                key: '100 rows',
                onClick: (closePopover) => {
                    closePopover();
                    onChangeRowSize(100);
                },
                iconSize: 100,
            },
        ];
    }, [onChangeRowSize]);

    return (
        <>
            <EuiDataGrid
                aria-label='Customer List Datagrid'
                columns={columns}
                columnVisibility={{ visibleColumns, setVisibleColumns }}
                rowCount={mappedItems.length}
                // inMemory={{ level: 'sorting' }}
                renderCellValue={renderCellValue}
                // sorting={{ columns: sortingColumns, onSort }}
            />
            <EuiFlexGroup justifyContent='spaceBetween' alignItems='center'>
                <EuiFlexItem grow={false}>
                    <ContextMenu rowSize={rowSize} popOverItems={popOverItems} />
                </EuiFlexItem>

                <EuiFlexItem grow={false}>
                    <EuiPagination
                        aria-label='Custom pagination example'
                        pageCount={PAGE_COUNT ? PAGE_COUNT : ''}
                        // PAGE_COUNT
                        activePage={activePage}
                        onPageClick={goToPage}
                    />
                </EuiFlexItem>
            </EuiFlexGroup>
        </>
    );
};
export default memo(CustomerDataGrid);
