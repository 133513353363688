import API from '../axios/API';

export const getRewardPointsList = (outletId, activePage, rowSize, startDate, endDate) => {
    return async (dispatch, getState) => {
        let response;

        try {
            response = API.get(
                `restaurants/:restaurantId/outlets/${outletId}/reward-point-logs?page=${activePage}&per_page=${rowSize}&filters[date_range][from_date]=${startDate.format(
                    'YYYY-MM-DD'
                )}&filters[date_range][to_date]=${endDate.format('YYYY-MM-DD')}`
            );
        } catch (error) {
            response = error;
        }

        return response;
    };
};
