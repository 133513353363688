import React from 'react';
import { EuiFormRow, EuiFieldText } from '@elastic/eui';
import { Controller } from 'react-hook-form';

const TextField = (props) => {
    const {
        label,
        name,
        placeholder,
        rules,
        errors,
        control,
        isInvalid,
        error,
        fullWidth,
        defaultValue,
        style,
        disabled,
    } = props;
    return (
        <>
            <Controller
                render={({ field }) => (
                    <EuiFormRow
                        label={label}
                        isInvalid={isInvalid ? isInvalid : errors[name]}
                        error={error ? error : errors[name] ? errors[name].message : ''}
                        fullWidth={fullWidth ? fullWidth : false}
                        style={style}
                    >
                        <EuiFieldText
                            disabled={disabled ? disabled : false}
                            placeholder={props.placeholder}
                            value={field.value}
                            onChange={field.onChange}
                            fullWidth={fullWidth ? fullWidth : false}
                        />
                    </EuiFormRow>
                )}
                name={name}
                control={control}
                rules={rules}
                fullWidth={true}
                placeholder={placeholder}
                defaultValue={defaultValue ? defaultValue : ''}
            />
        </>
    );
};

export default TextField;
