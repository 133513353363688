import { EuiFlexItem, EuiSwitch, EuiFlexGroup, EuiText } from '@elastic/eui';
import { capitalize } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cashToggleForOutlet } from '../../api/outlet/cashToggleForOutletAPI';
import { getOutletList } from '../../api/outlet/getOutletList';
import { userHasPermission } from '../../helper/auth';

export const PaymentMode = (props) => {
    const { payment, outletId, display_name } = props;
    const [paymentEnable, setPaymentEnable] = useState(false);

    const permissions = useSelector((state) => state.permission.permission);
    const dispatch = useDispatch();

    useEffect(() => {
        setPaymentEnable(payment.active ? true : false);
    }, [payment]);

    const onChange = (e) => {
        setPaymentEnable(e.target.checked);
        dispatch(cashToggleForOutlet(outletId, payment.id, e.target.checked)).then((response) => {
            if (response.success) {
                dispatch(getOutletList());
            }
        });
    };

    return (
        <EuiFlexGroup alignItems='center'>
            <EuiFlexItem grow={8}>
                <EuiText size='s'>{capitalize(display_name)}</EuiText>
            </EuiFlexItem>

            <EuiFlexItem grow={2}>
                <EuiSwitch
                    disabled={!userHasPermission(permissions, '/outlet-mode', 'write')}
                    label={''}
                    checked={paymentEnable}
                    onChange={(e) => onChange(e)}
                />
            </EuiFlexItem>
        </EuiFlexGroup>
    );
};
