import { EuiButtonEmpty } from '@elastic/eui';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import AddRiderForm from './AddRiderForm';

const EditRiderAction = ({ rider, setRefetch }) => {
    const dispatch = useDispatch();
    const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);

    const editRider = async (id) => {
        dispatch({
            type: 'set-edit-rider-id',
            payload: {
                edit_rider_id: id,
            },
        });
        setIsFlyoutVisible(true);
    };

    const closeFlyout = () => {
        setIsFlyoutVisible(false);
        dispatch({
            type: 'set-edit-rider-id',
            payload: {
                edit_rider_id: null,
            },
        });
    };

    return (
        <>
            {isFlyoutVisible && <AddRiderForm setRefetch={setRefetch} closeFlyout={closeFlyout} />}
            <EuiButtonEmpty iconType='documentEdit' size='s' onClick={() => editRider(rider.rider_id)}>
                Edit
            </EuiButtonEmpty>
        </>
    );
};

export default EditRiderAction;
