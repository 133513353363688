import * as types from '../types/notification';

const initialState = {
    pushedNotification: [],
    allNotification: [],
    resendID: null,
    editID: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.SET_PUSHED_NOTIFICATION:
            return {
                ...state,
                pushedNotification: action.payload,
            };
        case types.SET_ALL_Notification:
            return {
                ...state,
                allNotification: action.payload.allNotification,
            };
        case types.SET_RESET_NOTIFICATION:
            return {
                ...state,
                resendID: action.payload.resendID,
            };
        case types.SET_EDIT_NOTIFICATION:
            return {
                ...state,
                editID: action.payload.editID,
            };
        default:
            return state;
    }
};
