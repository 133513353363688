import { EuiButton, EuiFlexItem, EuiSpacer } from '@elastic/eui';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SET_CURRENT_ORDER_STATE } from '../../reduxStore/types/orders';
import { fetchTableOrderDetails } from './Api/tableOperations';

const FilterOrderStatusWise = ({ setSelectedOrder }) => {
    const currentOrderState = useSelector((state) => state.orders.currentOrderState);
    const ordersCount = useSelector((state) => state.orders.ordersCount);
    const dispatch = useDispatch();

    const onStatusChange = (type) => {
        dispatch({
            type: SET_CURRENT_ORDER_STATE,
            payload: type,
        });

        dispatch(fetchTableOrderDetails(type)).then((response) => {
            setSelectedOrder(response?.orders?.data?.[0] ? response?.orders?.data[0] : {});
        });
    };

    return (
        <>
            <EuiFlexItem
                grow={1}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    height: '32px',
                    backgroundColor: 'rgba(105, 112, 125, 0.2)',
                }}
            >
                <EuiFlexItem
                    style={{
                        backgroundColor: currentOrderState === 'all' ? '#0071c2' : 'rgba(105, 112, 125, 0.2)',
                        justifyContent: 'center',
                        width: '20%',
                        boxShadow: currentOrderState === 'all' ? '0 0 7px 0 rgba(0, 0, 0, 0.5)' : '',
                        borderRadius: currentOrderState === 'all' ? '3px' : '',
                    }}
                >
                    <EuiButton
                        onClick={() => onStatusChange('all')}
                        size='s'
                        style={{
                            fontSize: '12px',
                            textDecorationLine: 'none',
                            border: 'none',
                            boxShadow: 'none',
                            background: 'transparent',
                            color: currentOrderState === 'all' ? '#FFFFFF' : '#5a606b',
                        }}
                    >
                        All ({ordersCount.all})
                    </EuiButton>
                </EuiFlexItem>

                <EuiFlexItem
                    style={{
                        backgroundColor: currentOrderState === 'placed' ? '#0071c2' : 'rgba(105, 112, 125, 0.2)',
                        justifyContent: 'center',
                        width: '20%',
                        boxShadow: currentOrderState === 'placed' ? '0 0 7px 0 rgba(0, 0, 0, 0.5)' : '',
                        borderRadius: currentOrderState === 'placed' ? '3px' : '',
                    }}
                >
                    <EuiButton
                        onClick={() => onStatusChange('placed')}
                        style={{
                            fontSize: '12px',
                            textDecorationLine: 'none',
                            border: 'none',
                            boxShadow: 'none',
                            background: 'transparent',
                            color: currentOrderState === 'placed' ? '#FFFFFF' : '#5a606b',
                        }}
                    >
                        Pending ({ordersCount.placed ? ordersCount.placed : 0})
                    </EuiButton>
                </EuiFlexItem>

                <EuiFlexItem
                    style={{
                        backgroundColor:
                            currentOrderState === 'restaurant_accepted' ? '#0071c2' : 'rgba(105, 112, 125, 0.2)',
                        justifyContent: 'center',
                        width: '20%',
                        boxShadow: currentOrderState === 'restaurant_accepted' ? '0 0 7px 0 rgba(0, 0, 0, 0.5)' : '',
                        borderRadius: currentOrderState === 'restaurant_accepted' ? '3px' : '',
                    }}
                >
                    <EuiButton
                        onClick={() => onStatusChange('restaurant_accepted')}
                        style={{
                            fontSize: '12px',
                            textDecorationLine: 'none',
                            border: 'none',
                            boxShadow: 'none',
                            background: 'transparent',
                            color: currentOrderState === 'restaurant_accepted' ? '#FFFFFF' : '#5a606b',
                        }}
                    >
                        Preparing ({ordersCount.restaurant_accepted ? ordersCount.restaurant_accepted : 0})
                    </EuiButton>
                </EuiFlexItem>

                <EuiFlexItem
                    style={{
                        backgroundColor:
                            currentOrderState === 'restaurant_ready' ? '#0071c2' : 'rgba(105, 112, 125, 0.2)',
                        justifyContent: 'center',
                        width: '20%',
                        boxShadow: currentOrderState === 'restaurant_ready' ? '0 0 7px 0 rgba(0, 0, 0, 0.5)' : '',
                        borderRadius: currentOrderState === 'restaurant_ready' ? '3px' : '',
                    }}
                >
                    <EuiButton
                        onClick={() => onStatusChange('restaurant_ready')}
                        style={{
                            fontSize: '12px',
                            textDecorationLine: 'none',
                            border: 'none',
                            boxShadow: 'none',
                            background: 'transparent',
                            color: currentOrderState === 'restaurant_ready' ? '#FFFFFF' : '#5a606b',
                        }}
                    >
                        Ready ({ordersCount.restaurant_ready ? ordersCount.restaurant_ready : 0})
                    </EuiButton>
                </EuiFlexItem>

                <EuiFlexItem
                    style={{
                        backgroundColor: currentOrderState === 'delivered' ? '#0071c2' : 'rgba(105, 112, 125, 0.2)',
                        justifyContent: 'center',
                        width: '20%',
                        boxShadow: currentOrderState === 'delivered' ? '0 0 7px 0 rgba(0, 0, 0, 0.5)' : '',
                        borderRadius: currentOrderState === 'delivered' ? '3px' : '',
                    }}
                >
                    <EuiButton
                        onClick={() => onStatusChange('delivered')}
                        style={{
                            fontSize: '12px',
                            textDecorationLine: 'none',
                            border: 'none',
                            boxShadow: 'none',
                            background: 'transparent',
                            color: currentOrderState === 'delivered' ? '#FFFFFF' : '#5a606b',
                        }}
                    >
                        Delivered ({ordersCount.delivered_order_count ? ordersCount.delivered_order_count : 0})
                    </EuiButton>
                </EuiFlexItem>
            </EuiFlexItem>

            <EuiSpacer size='s' />
        </>
    );
};

export default FilterOrderStatusWise;
