import API from '../axios/API';
import * as types from '../../reduxStore/types/orders';

export const getLiveOrders = () => {
    return async (dispatch, getState) => {
        let liveOrderResponse;
        let { orders } = getState();
        let orderState = orders.currentOrderState;
        let orderingMode = orders.currentOrderingMode;
        let selectedOrderId = orders.selectedOrderId;

        try {
            liveOrderResponse = await API.get(
                `${process.env.REACT_APP_BASE_URL_V3}/restaurants/:restaurantId/orders?ordering_mode=${orderingMode}&status=${orderState}`
            );

            const filteredOrder = liveOrderResponse.orders.filter((data) => data.order_id === selectedOrderId);

            if (filteredOrder.length < 1 && liveOrderResponse?.orders?.length) {
                dispatch({
                    type: types.SET_SELECTED_ORDER,
                    payload: liveOrderResponse.orders[0].order_id,
                });
            }

            if (!liveOrderResponse?.orders?.length) {
                dispatch({
                    type: types.SET_SELECTED_ORDER,
                    payload: '',
                });
                dispatch({
                    type: types.SET_SELECTED_ORDER_DETAILS,
                    payload: {},
                });
            }

            dispatch({
                type: types.SET_LIVE_ORDERS,
                payload: liveOrderResponse.orders,
            });

            dispatch({
                type: types.SET_ORDERS_COUNT,
                payload: {
                    all: parseInt(liveOrderResponse.all_order_count),
                    placed: parseInt(liveOrderResponse.new_order_count),
                    restaurant_accepted: parseInt(liveOrderResponse.preparing_order_count),
                    restaurant_ready: parseInt(liveOrderResponse.ready_order_count),
                    rider_picked_up: parseInt(liveOrderResponse.dispatched_order_count),
                },
            });
        } catch (error) {
            liveOrderResponse = error;
        }

        return liveOrderResponse;
    };
};
