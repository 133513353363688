import {
    EuiButton,
    EuiFlexGroup,
    EuiFlexItem,
    EuiForm,
    EuiModal,
    EuiModalBody,
    EuiModalFooter,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiOverlayMask,
    EuiText,
} from '@elastic/eui';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { saveCreditAlert } from '../../api/CreditAlert/PutCredit';
import { authModule } from '../../helper/authModulePermission';
import NumberField from '../Form/NumberField';
import './credits.css';

const CreditsAlertModal = (props) => {
    const {
        handleSubmit,
        formState: { errors },
        control,
    } = useForm();
    const dispatch = useDispatch();
    const permissions = useSelector((state) => state.permission.permission);

    const saveAlert = (data) => {
        const amount = data.alert_amount;
        dispatch(saveCreditAlert(amount, props.alertType)).then((res) => {
            if (res.success) props.setUpdateTopupData(true);
        });
        props.closeModal();
    };

    return (
        <EuiOverlayMask>
            <EuiModal onClose={props.closeModal}>
                <EuiModalHeader>
                    <EuiModalHeaderTitle>
                        <EuiText size='l'>
                            <h3> Manage Credits Alert</h3>
                        </EuiText>
                        <div style={{ marginTop: '6px' }}>
                            <EuiText style={{ color: '#6A6A6A' }}>
                                You will receive an alert when your balance goes below the amount set in the alerts.
                            </EuiText>
                        </div>
                    </EuiModalHeaderTitle>
                </EuiModalHeader>
                <EuiForm component='form' onSubmit={handleSubmit(saveAlert)}>
                    <EuiModalBody>
                        <EuiFlexGroup
                            style={{
                                width: '100%',
                                background: 'rgba(195, 217, 235)',
                                marginTop: '16px',
                                padding: '4px 0',
                                borderTop: '2px',
                                borderBottom: '2px',
                                borderStyle: 'solid',
                                borderLeft: 'none',
                                borderRight: 'none',
                                borderColor: '#CCC',
                            }}
                            justifyContent='spaceAround'
                            alignItems='center'
                        >
                            <EuiFlexItem>
                                <EuiText>Credit Type</EuiText>
                            </EuiFlexItem>
                            <EuiFlexItem grow={false}>
                                <EuiText>Alert</EuiText>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                        <EuiFlexGroup
                            style={{
                                width: '100%',
                                background: 'rgba(195, 217, 235, .4)',
                                marginBottom: '16px',
                                marginTop: '12px',
                            }}
                            justifyContent='spaceAround'
                            alignItems='center'
                        >
                            <EuiFlexItem>
                                <EuiText>Fee Credit</EuiText>
                            </EuiFlexItem>
                            <EuiFlexItem style={{ width: '22%' }} grow={false}>
                                <NumberField
                                    label={''}
                                    errors={errors}
                                    name='alert_amount'
                                    rules={{
                                        required: 'Please enter alert amount',
                                    }}
                                    control={control}
                                    defaultValue={props.alertAmount}
                                    className='input-remove-hyphen'
                                    prepend='₹'
                                    min={50}
                                    placeholder={50}
                                />
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </EuiModalBody>

                    <EuiModalFooter>
                        {authModule(
                            permissions,

                            'write',
                            props.alertType === 'infrastructure'
                                ? 'infrastructure_credit'
                                : props.alertType === 'service'
                                ? 'service_credit'
                                : null
                        ) && (
                            <EuiButton fill type='submit'>
                                Save
                            </EuiButton>
                        )}
                    </EuiModalFooter>
                </EuiForm>
            </EuiModal>
        </EuiOverlayMask>
    );
};

export default CreditsAlertModal;
