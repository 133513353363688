import {
    EuiBadge,
    EuiButton,
    EuiDragDropContext,
    euiDragDropReorder,
    EuiDraggable,
    EuiDroppable,
    EuiFlexGroup,
    EuiFlexItem,
    EuiHorizontalRule,
    EuiIcon,
    EuiSpacer,
    EuiText,
} from '@elastic/eui';
import { capitalize } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import API from '../../../api/axios/API';
import { bannerOutletSequence } from '../../../api/banner/bannerOutletSequence';
import { fetchOutletOrderingModes } from '../../../api/outlet/fetchOutletOrderingModes';
import { userHasPermission } from '../../../helper/auth';
import { SET_OUTLET_BANNER_LOADER } from '../../../reduxStore/types/banner';
import { NoOutletMessage } from '../../../utils/noOutletMessage';
import { getBannerAvailableTimeSlots } from '../../../components/banner/BannerCatalogue/getBannerAvailableTimeSlots';
import ShowImages from '../Components/StoreBanners/ShowImages';

const OutletBanners = () => {
    const [outletBannerList, setOutletBannerList] = useState([]);
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);
    const dispatch = useDispatch();
    const currentTab = useSelector((state) => state.banner.bannerType);
    const [outletFilteredBanners, setOutletFilteredBanners] = useState([]);
    const permissions = useSelector((state) => state.permission.permission);
    const outletOrderingModes = useSelector((state) => state.outlet?.outletOrderingModes?.ordering_modes);
    const languageId = useSelector((state) => state.language.languageId);

    const onDragEnd = useCallback(
        async ({ source, destination }) => {
            const items = euiDragDropReorder(outletFilteredBanners, source.index, destination.index);

            await dispatch(bannerOutletSequence(selectedOutletId, { banners: items })).then((response) => {
                if (response.success) {
                    setOutletBannerList(items);
                } else {
                    alert(response.message);
                }
            });

            setOutletFilteredBanners([...items]);
        },
        [dispatch, outletFilteredBanners, selectedOutletId]
    );

    useEffect(() => {
        // set filtered outlet to link/unlink
        if (outletBannerList?.length >= 0) {
            if (currentTab === 'secondary') {
                setOutletFilteredBanners([...outletBannerList.filter((banner) => banner.is_secondary)]);
            } else if (currentTab === 'tertiary') {
                setOutletFilteredBanners([...outletBannerList.filter((banner) => banner.is_tertiary)]);
            } else {
                setOutletFilteredBanners([
                    ...outletBannerList.filter((banner) => !banner.is_secondary && !banner.is_tertiary),
                ]);
            }
        }
    }, [currentTab, outletBannerList]);

    const fetchOutletBanner = useCallback(
        async (outletID) => {
            dispatch({
                type: SET_OUTLET_BANNER_LOADER,
                payload: {
                    outletBannerLoader: { loader: true, error: false },
                },
            });
            let bannerList;
            try {
                bannerList = await API.get(`restaurants/:restaurantId/outlets/${outletID}/banners`);
                if (bannerList.success) {
                    setOutletBannerList(bannerList.banners);

                    dispatch({
                        type: SET_OUTLET_BANNER_LOADER,
                        payload: {
                            outletBannerLoader: { loader: false, error: false },
                        },
                    });
                }
            } catch (error) {
                dispatch({
                    type: SET_OUTLET_BANNER_LOADER,
                    payload: {
                        outletBannerLoader: { loader: false, error: true },
                    },
                });
            }
        },
        [dispatch]
    );

    // method handle's for banner un-link
    const handleUnlink = useCallback(
        async (id) => {
            try {
                await API.patch(`restaurants/:restaurantId/banners/${id}/un-link`, {
                    outlet_ids: [selectedOutletId],
                }).then((response) => {
                    if (response.success) {
                        fetchOutletBanner(selectedOutletId);
                    }
                });
                // await updateBannerData(outlet);
            } catch (e) {
                console.log(e);
            }
        },
        [fetchOutletBanner, selectedOutletId]
    );

    useEffect(() => {
        if (selectedOutletId) {
            fetchOutletBanner(selectedOutletId);
        }
    }, [selectedOutletId, fetchOutletBanner]);

    useEffect(() => {
        if (selectedOutletId) {
            dispatch(fetchOutletOrderingModes(selectedOutletId));
        }
    }, [dispatch]);

    return (
        <div>
            {!selectedOutletId ? (
                <NoOutletMessage />
            ) : (
                <EuiFlexGroup gutterSize='none' direction='column'>
                    <EuiFlexItem>
                        <EuiFlexGroup>
                            {colHeader.map((header, idx) => {
                                return (
                                    <EuiFlexItem key={idx}>
                                        <EuiText size='xs' style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                            {header}
                                        </EuiText>
                                    </EuiFlexItem>
                                );
                            })}
                        </EuiFlexGroup>
                        <EuiSpacer size='s' />
                        <EuiHorizontalRule margin='none' />
                    </EuiFlexItem>
                    <EuiFlexItem>
                        {outletFilteredBanners?.length ? (
                            <EuiDragDropContext onDragEnd={onDragEnd}>
                                <EuiDroppable droppableId='DROPPABLE_AREA' spacing='none' withPanel={false}>
                                    {outletFilteredBanners?.map((banner, idx) => {
                                        return (
                                            <EuiFlexItem key={idx.toString()}>
                                                <EuiSpacer size='s' />
                                                <EuiDraggable
                                                    spacing='none'
                                                    key={banner.id.toString()}
                                                    index={idx}
                                                    draggableId={banner.id.toString()}
                                                >
                                                    {(provided) => (
                                                        <>
                                                            <EuiFlexGroup alignItems='center'>
                                                                <EuiFlexItem>
                                                                    <EuiFlexGroup alignItems='center'>
                                                                        {userHasPermission(
                                                                            permissions,
                                                                            '/banners',
                                                                            'write'
                                                                        ) && (
                                                                            <EuiFlexItem
                                                                                grow={false}
                                                                                {...provided.dragHandleProps}
                                                                                aria-label='Drag Handle'
                                                                            >
                                                                                <EuiIcon type='grab' />
                                                                            </EuiFlexItem>
                                                                        )}
                                                                        <EuiFlexItem>
                                                                            <EuiText>{banner.title}</EuiText>
                                                                        </EuiFlexItem>
                                                                    </EuiFlexGroup>
                                                                </EuiFlexItem>
                                                                <EuiFlexItem>
                                                                    <EuiText>
                                                                        {banner?.from_date
                                                                            ? moment(banner.from_date).format(
                                                                                  'DD-MM-YYYY HH:mm'
                                                                              )
                                                                            : ''}
                                                                    </EuiText>
                                                                </EuiFlexItem>
                                                                <EuiFlexItem>
                                                                    <EuiText>
                                                                        {banner?.to_date
                                                                            ? moment(banner.to_date).format(
                                                                                  'DD-MM-YYYY HH:mm'
                                                                              )
                                                                            : ''}
                                                                    </EuiText>
                                                                </EuiFlexItem>
                                                                <EuiFlexItem>
                                                                    <EuiText>
                                                                        {banner?.screen
                                                                            ? capitalize(banner.screen)
                                                                            : '--'}
                                                                    </EuiText>
                                                                </EuiFlexItem>
                                                                <EuiFlexItem>
                                                                    <EuiFlexGroup
                                                                        alignItems='center'
                                                                        justifyContent='center'
                                                                        direction='row'
                                                                        responsive
                                                                        gutterSize='s'
                                                                        wrap
                                                                    >
                                                                        {outletOrderingModes
                                                                            ?.filter(
                                                                                (orderingMode) =>
                                                                                    orderingMode.is_enabled
                                                                            )
                                                                            ?.map(
                                                                                (
                                                                                    { translations: { display_name } },
                                                                                    index
                                                                                ) => {
                                                                                    return (
                                                                                        <EuiFlexItem key={index}>
                                                                                            <EuiBadge
                                                                                                style={{
                                                                                                    wordWrap:
                                                                                                        'break-all',
                                                                                                    textTransform:
                                                                                                        'capitalize',
                                                                                                }}
                                                                                            >
                                                                                                <EuiText
                                                                                                    size='xs'
                                                                                                    style={{
                                                                                                        textAlign:
                                                                                                            'center',
                                                                                                    }}
                                                                                                >
                                                                                                    {
                                                                                                        display_name[
                                                                                                            languageId
                                                                                                        ]
                                                                                                    }
                                                                                                </EuiText>
                                                                                            </EuiBadge>
                                                                                        </EuiFlexItem>
                                                                                    );
                                                                                }
                                                                            )}
                                                                    </EuiFlexGroup>
                                                                </EuiFlexItem>
                                                                <EuiFlexItem>
                                                                    <EuiText>
                                                                        {banner?.time_slot_setting !==
                                                                            'no_time_slots' &&
                                                                        getBannerAvailableTimeSlots(banner)
                                                                            ? getBannerAvailableTimeSlots(banner)
                                                                            : '--'}
                                                                    </EuiText>
                                                                </EuiFlexItem>
                                                                <EuiFlexItem>
                                                                    <ShowImages banner={banner} />
                                                                </EuiFlexItem>
                                                                {userHasPermission(
                                                                    permissions,
                                                                    '/banners',
                                                                    'delete'
                                                                ) && (
                                                                    <EuiFlexItem>
                                                                        <EuiButton
                                                                            size='s'
                                                                            onClick={() => handleUnlink(banner.id)}
                                                                        >
                                                                            <EuiText size='s'> Unlink</EuiText>
                                                                        </EuiButton>
                                                                    </EuiFlexItem>
                                                                )}
                                                            </EuiFlexGroup>
                                                        </>
                                                    )}
                                                </EuiDraggable>
                                                <EuiSpacer size='s' />
                                                <EuiHorizontalRule margin='none' />
                                            </EuiFlexItem>
                                        );
                                    })}
                                </EuiDroppable>
                            </EuiDragDropContext>
                        ) : (
                            <EuiText
                                style={{ fontSize: 14, textAlign: 'center', fontWeight: 'bold', margin: '18px 0' }}
                            >
                                No records found
                            </EuiText>
                        )}
                    </EuiFlexItem>
                </EuiFlexGroup>
            )}
        </div>
    );
};

const colHeader = [
    'Title',
    'Valid From',
    'Valid Till',
    'Target Screen',
    'Available Modes',
    'Available Time Slots',
    'Banner Images',
    'Un-Link Banner',
];

export default React.memo(OutletBanners);
