import APIV3 from '../../../../../api/axios/APIV3';

export const fetchItemAvailabilityCategories = async (selectedOutletId) => {
    let categories;
    try {
        categories = await APIV3.get(`/restaurants/:restaurantId/outlets/${selectedOutletId}/categories/stock`);
    } catch (error) {
        categories = error;
    }

    return categories;
};

export const fetchItemAvailabilityItems = async (selectedOutletId, categoryId) => {
    let categories;
    try {
        categories = await APIV3.get(
            `/restaurants/:restaurantId/outlets/${selectedOutletId}/categories/${categoryId}/items/stock`
        );
    } catch (error) {
        categories = error;
    }

    return categories;
};
