import APIV3 from '../../../../api/axios/APIV3';
import { SET_RESTAURANT_ORDERING_MODES } from '../../../../reduxStore/types/RestaurantActionTypes';

export const fetchOrderingModes = async () => {
    let response;

    response = await APIV3.get(`restaurants/:restaurantId/restaurant-ordering-modes`);

    return response;
};

export const updateOrderingMode = async (changes) => {
    let response;

    response = await APIV3.patch(`restaurants/:restaurantId/restaurant-ordering-modes`, { ordering_modes: changes });

    return response;
};

export const createOrderingMode = async (data) => {
    let response;

    response = await APIV3.post(`restaurants/:restaurantId/restaurant-ordering-modes`, data);

    return response;
};

export const fetchRestaurantOrderingModes = () => {
    return async (dispatch) => {
        let response;

        response = await APIV3.get(`restaurants/:restaurantId/restaurant-ordering-modes`);

        if (response.success) {
            dispatch({
                type: SET_RESTAURANT_ORDERING_MODES,
                payload: response.ordering_modes,
            });
        }
        return response;
    };
};
