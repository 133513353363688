import {
    EuiComboBox,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow,
    EuiLink,
    EuiSpacer,
    EuiText,
    EuiTextColor,
} from '@elastic/eui';
import { isEmpty, parseInt } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { autoSuggestionItems } from './utils/autoSuggestionItems';
import { getErrorMesage, getItem, getTaxAmount } from './utils/utils';

const ItemLinkOrderingModes = ({
    orderingMode,
    category_id,
    fieldIndex,
    modeIndex,
    itemsOptions,
    setSelectedItem,
    item,
    setVariantOptions,
    setSelectedItemForVariants,
}) => {
    const [suggestionItems, setSuggestionItems] = useState([]);
    const [amountError, setAmountError] = useState([]);
    const petPoojaTaxes = useSelector((state) => state.petPoojaReducer.petPoojaTaxes);
    const petPoojaRestaurant = useSelector((state) => state.petPoojaReducer.petPoojaRestaurant);
    const optionCount = item?.variants[0]?.options?.length;
    const variantCount = item?.variants?.length;
    const linkedItems = useSelector((state) => state.petPoojaReducer.petPoojaLinkedItems);
    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);
    const petPoojaItems = useSelector((state) => state.petPoojaReducer.petPoojaItems);
    const languageId = useSelector((state) => state.language.languageId);
    const selectedOutlet = useSelector((state) => state.outlet.selectedOutlet);
    const {
        setValue,
        control,
        formState: { errors, isDirty },
        watch,
        setError,
        clearErrors,
    } = useFormContext();
    // const [isDisabled] = useState(
    //     item.category_ids.findIndex((element) => element === category_id) > 0 && item.is_mapped_item ? true : false
    // );

    useEffect(() => {
        if (!isDirty) {
            setAmountError([]);
        }
        // console.log(
        //     `HALF WAY THERE`,
        // errors?.[`item_${category_id}`]?.[fieldIndex]?.['ordering_modes']?.[modeIndex]?.['selected_item']?.[
        //     'message'
        // ]
        // );
        // console.log(
        //     `V2HALF WAY THERE`,
        //     errors?.[`item_${category_id}`]?.[fieldIndex]?.['ordering_modes']?.[modeIndex]?.['selected_item']
        // );

        if (
            errors?.[`item_${category_id}`]?.[fieldIndex]?.['ordering_modes']?.[modeIndex]?.['selected_item']?.[
                'message'
            ]
        ) {
            // console.log(`else if`);
            setAmountError((prevState) => [
                ...prevState,
                errors?.[`item_${category_id}[${fieldIndex}.ordering_modes[${modeIndex}].selected_item]`]?.message,
            ]);
        }
    }, [isDirty, category_id, fieldIndex, modeIndex, errors]);

    useEffect(() => {
        if (petPoojaItems?.length) {
            const items = autoSuggestionItems(orderingMode, item, petPoojaTaxes, petPoojaItems, languageId).items;
            setSuggestionItems(items);
        }
    }, [selectedOutletId]);

    const handleRegisterSuggestionAction = useCallback(
        (selectedItem) => {
            if (!isEmpty(selectedItem)) {
                // console.log(`selectedItem`, selectedItem);
                const isInvalid = checkItemValidation([selectedItem], orderingMode.restaurant_ordering_mode_id);

                if (isInvalid) {
                    // setError(`item_${category_id}[${fieldIndex}.ordering_modes[${modeIndex}].selected_item]`, {
                    //     type: 'custom',
                    //     message: amountError?.[0] ? amountError[0] : amountError,
                    // });
                } else {
                    clearErrors(`item_${category_id}[${fieldIndex}.ordering_modes[${modeIndex}].selected_item]`);
                }

                selectedItem.third_party_module_id = selectedItem.itemid;
                selectedItem.id = selectedItem?.third_party_module_id;
                selectedItem.restaurant_ordering_mode_id = orderingMode.restaurant_ordering_mode_id;
                selectedItem.label = selectedItem.itemname;
                selectedItem.module_id = item.item_id;
                // title: selectedOption[0].label,
                selectedItem.title = selectedItem.itemname || selectedItem.label;

                setValue(
                    `item_${category_id}[${fieldIndex}.ordering_modes[${modeIndex}].selected_item]`,
                    [selectedItem],
                    {
                        shouldDirty: true,
                    }
                );

                let filterItems = petPoojaItems.filter((item) => item?.itemid == selectedItem?.third_party_module_id);

                if (filterItems?.length) {
                    let variants = [];
                    filterItems[0]?.variation?.forEach((option) => {
                        variants.push({
                            id: option.variationid,
                            label: option.name,
                            module_name: 'variant_option',
                            outlet_id: selectedOutletId,
                            third_party_service_provider: 'pet-pooja',
                            third_party_module_id: option.variationid,
                            variationid: option.variationid,
                            // module_id: item.item_id,

                            ...option,
                        });
                    });
                    setVariantOptions((prevState) => ({
                        ...prevState,
                        [orderingMode.restaurant_ordering_mode_id]: variants,
                    }));
                    setSelectedItemForVariants((prevState) => ({
                        ...prevState,
                        [`${item.item_id}_${orderingMode.restaurant_ordering_mode_id}`]: filterItems,
                    }));
                }
            }
        },
        [category_id, fieldIndex, modeIndex, orderingMode.restaurant_ordering_mode_id, setValue, item, amountError]
    );

    const itemVerification = useCallback(
        (selectedOption) => {
            let itemResponse = getItem(selectedOption[0].itemid, petPoojaItems);
            let { taxArrayObj, taxAmount } = getTaxAmount(itemResponse, petPoojaTaxes, orderingMode?.price);

            selectedOption[0] = {
                ...selectedOption[0],
                tax: taxArrayObj,
            };
            let fabSystemTaxAmount = (Number(orderingMode?.price) * Number(orderingMode?.gst_slab)) / 100;
            let errorArray = [];
            let errorCheck = false;
            if (!item?.variants?.length) {
                if (item?.ordering_modes?.length) {
                    for (const orderingMode of item.ordering_modes) {
                        if (
                            orderingMode?.restaurant_ordering_mode_id ===
                            orderingMode?.selected_item?.[0]?.restaurant_ordering_mode_id
                        ) {
                            // selectedOption[0].restaurant_ordering_mode_id
                            if (orderingMode?.price != itemResponse?.price) {
                                errorCheck = true;
                                errorArray.push(
                                    `Fab system price ${orderingMode?.price} ≠ Pet Pooja price ${itemResponse?.price}`
                                );
                            }
                            if (
                                orderingMode?.packaging_charge != itemResponse?.item_packingcharges &&
                                petPoojaRestaurant?.[0]?.details?.packaging_applicable_on === 'ITEM'
                            ) {
                                errorCheck = true;
                                errorArray.push(
                                    `Packaging Fab System ${orderingMode?.packaging_charge} ≠ Pacakging Pet Pooja ${itemResponse?.item_packingcharges}`
                                );
                            }
                            if (parseInt(fabSystemTaxAmount) != parseInt(taxAmount)) {
                                errorCheck = true;
                                errorArray.push(
                                    `Tax Fab System ${parseInt(fabSystemTaxAmount)} ≠ Tax Pet Pooja ${parseInt(
                                        taxAmount
                                    )}`
                                );
                            }
                        }
                    }
                    const filteredErrors = errorArray.reduce(
                        (acc, curr) => (acc?.find((error) => error === curr) ? acc : [...acc, curr]),
                        []
                    );
                    setAmountError(filteredErrors);
                }
            }

            if (
                selectedOption.length &&
                !(
                    ((optionCount >= 0 ? optionCount : variantCount) >= 0 && 0 <= itemResponse?.variation?.length) ||
                    ((optionCount >= 0 ? optionCount : variantCount) === 0 && itemResponse?.variation?.length === 0)
                )
            ) {
                errorCheck = true;
                setAmountError([
                    ...errorArray,
                    `Variants mismatch Fab System ${optionCount >= 0 ? optionCount : variantCount} ≠ Pet Pooja ${
                        itemResponse?.variation?.length
                    }`,
                ]);
                errorArray.push(
                    `Variants mismatch Fab System ${optionCount >= 0 ? optionCount : variantCount} ≠ Pet Pooja ${
                        itemResponse?.variation?.length
                    }`
                );
            }

            return { taxArrayObj, errorCheck, errorArray };
        },
        [item, petPoojaItems, petPoojaRestaurant, petPoojaTaxes]
    );

    const checkForVariantValidation = useCallback(
        (petpoojaItem, fabItem) => {
            let error = false;
            let errorArray = [];
            const petPoojaVariantCount = petpoojaItem[0]?.variation?.length;
            const fabVariantCount = fabItem?.variants?.[0]?.options?.length
                ? fabItem?.variants?.[0]?.options.length
                : 0;

            if (!(petPoojaVariantCount >= fabVariantCount)) {
                error = true;
                setAmountError([
                    ...errorArray,
                    `Variants mismatch Fab System ${fabVariantCount} ≠ Variants Pet Pooja ${petPoojaVariantCount}`,
                ]);
                errorArray.push(
                    `Variants mismatch Fab System ${fabVariantCount} ≠ Variants Pet Pooja ${petPoojaVariantCount}`
                );
            } else if (petPoojaVariantCount !== 0) {
                if (fabVariantCount < 1) {
                    error = true;
                    setAmountError([
                        ...errorArray,
                        `Variants mismatch Fab System ${fabVariantCount} ≠ Variants Pet Pooja ${petPoojaVariantCount}`,
                    ]);
                    errorArray.push(
                        `Variants mismatch Fab System ${fabVariantCount} ≠ Variants Pet Pooja ${petPoojaVariantCount}`
                    );
                }
            } else if (petpoojaItem[0]?.variation.length > 0 && fabItem?.variants.length === 0) {
                setAmountError([
                    ...errorArray,
                    `Variants mismatch Fab System ${fabVariantCount} ≠ Variants Pet Pooja ${petPoojaVariantCount}`,
                ]);
                errorArray.push(
                    `Variants mismatch Fab System ${fabVariantCount} ≠ Variants Pet Pooja ${petPoojaVariantCount}`
                );
                error = true;
            }

            // setError(`item_${category_id}[${fieldIndex}.ordering_modes[${modeIndex}].selected_item]`, {
            //     type: 'custom',
            //     message: errorArray?.[0] ? errorArray?.[0] : errorArray,
            // });
            return error;
        },
        [category_id, fieldIndex, modeIndex, setError]
    );

    const checkItemValidation = useCallback(
        (selectedOption, orderingModeId) => {
            let isItemValid = false;
            if (!selectedOption?.length) {
                isItemValid = true;
                setSelectedItem((prevState) => ({
                    ...prevState,
                    [item.item_id]: selectedOption,
                }));
                setSelectedItemForVariants((prevState) => ({
                    ...prevState,
                    [`${item.item_id}_${orderingMode.restaurant_ordering_mode_id}`]: selectedOption,
                }));
                setAmountError([]);
            } else {
                let { taxArrayObj, errorCheck, errorArray } = itemVerification(selectedOption);
                selectedOption[0].id = selectedOption[0]?.third_party_module_id;
                selectedOption[0].restaurant_ordering_mode_id = orderingModeId;
                let innerArray = [];
                innerArray.push({
                    from: 'item',
                    fromId: item.item_id,
                    selectedLinkId: selectedOption[0]?.id,
                    title: selectedOption[0]?.label,
                    tax: taxArrayObj,
                    packaging_charge: selectedOption[0]?.item_packingcharges,
                    price: selectedOption[0]?.price,
                    restaurant_ordering_mode_id: orderingModeId,
                });

                selectedOption[0] = {
                    ...selectedOption[0],
                    ...innerArray[0],
                };

                setSelectedItem((prevState) => ({
                    ...prevState,
                    [item.item_id]: selectedOption,
                }));

                // const petPoojaItem = '';
                const petPoojaItem = getItem(selectedOption[0]?.itemid, petPoojaItems);
                // variantSet({ id: selectedOption[0].id }); for variants
                let variantError = checkForVariantValidation([petPoojaItem], item);

                if (errorCheck || variantError) {
                    isItemValid = false;
                } else {
                    setAmountError([]);
                    isItemValid = true;
                }
                if (errorArray?.length) {
                    isItemValid = false;
                } else {
                    isItemValid = true;
                }
            }

            return isItemValid;
        },
        [amountError, checkForVariantValidation, item, itemVerification, petPoojaItems, setSelectedItem]
    );

    useEffect(() => {
        const filterSelectedPetPoojaItem = linkedItems.filter((itemData) => {
            return (
                itemData.module_id == item.item_id &&
                (itemData.module_name === 'item' || itemData.module_name === 'variant_item')
            );
        });
        let filterItems = petPoojaItems?.filter(
            (item) => item?.itemid == filterSelectedPetPoojaItem?.[0]?.third_party_module_id
        );

        if (filterItems?.length) {
            let variants = [];
            filterItems[0]?.variation?.forEach((option) => {
                variants.push({
                    id: option.variationid,
                    label: option.name,
                    module_name: 'variant_option',
                    outlet_id: selectedOutletId,
                    third_party_service_provider: 'pet-pooja',
                    third_party_module_id: option.variationid,
                    variationid: option.variationid,
                    // module_id: item.item_id,

                    ...option,
                });
            });
            setVariantOptions((prevState) => ({
                ...prevState,
                [orderingMode.restaurant_ordering_mode_id]: variants,
            }));
            setSelectedItemForVariants((prevState) => ({
                ...prevState,
                [`${item.item_id}_${orderingMode.restaurant_ordering_mode_id}`]: filterItems,
            }));
        }
    }, [selectedOutletId]);

    const handleItemLinkChange = useCallback(
        (selectedValue, selectedOption) => {
            if (selectedValue?.[0]?.ordering_modes) {
                delete selectedValue[0]?.ordering_modes;
            }

            setValue(`item_${category_id}[${fieldIndex}.ordering_modes[${modeIndex}].selected_item]`, selectedValue, {
                shouldDirty: true,
            });
            let filterItems = petPoojaItems.filter((item) => item?.itemid == selectedValue?.[0]?.third_party_module_id);
            if (filterItems?.length) {
                let variants = [];
                filterItems[0]?.variation?.forEach((option) => {
                    variants.push({
                        id: option.variationid,
                        label: option.name,
                        module_name: 'variant_option',
                        outlet_id: selectedOutletId,
                        third_party_service_provider: 'pet-pooja',
                        third_party_module_id: option.variationid,
                        variationid: option.variationid,
                        // module_id: item.item_id,

                        ...option,
                    });
                });
                setVariantOptions((prevState) => ({
                    ...prevState,
                    [orderingMode.restaurant_ordering_mode_id]: variants,
                }));
                setSelectedItemForVariants((prevState) => ({
                    ...prevState,
                    [`${item.item_id}_${orderingMode.restaurant_ordering_mode_id}`]: filterItems,
                }));
            }

            // UNLINK LOGIC
            if (!selectedValue?.length && selectedOption?.length && linkedItems?.length) {
                const currentRemovedItem = selectedOption[0];
                console.log('currentRemovedItem', currentRemovedItem);
                console.log(`& watch('menu_unlinks')`, watch('menu_unlinks'));
                for (const linkedItem of linkedItems) {
                    if (
                        linkedItem.third_party_module_id === currentRemovedItem.third_party_module_id &&
                        linkedItem.restaurant_ordering_mode_id === orderingMode.restaurant_ordering_mode_id &&
                        currentRemovedItem.module_id === linkedItem.module_id
                    ) {
                        if (watch('menu_unlinks')?.length) {
                            setValue('menu_unlinks', [...watch('menu_unlinks'), currentRemovedItem], {
                                shouldDirty: true,
                            });
                        } else {
                            setValue('menu_unlinks', [currentRemovedItem], { shouldDirty: true });
                        }
                    }
                }
            }
            // REMOVE UNLINK ITEMS FROM UNLINK STATE IF LINKED BACK

            if (selectedValue?.length && watch('menu_unlinks')?.length) {
                const removeItemFromUnlinkState = watch('menu_unlinks')?.filter((menu_unlink) => {
                    return !(
                        menu_unlink.third_party_module_id === selectedValue[0].third_party_module_id &&
                        menu_unlink.restaurant_ordering_mode_id === orderingMode.restaurant_ordering_mode_id &&
                        selectedValue[0].module_id === menu_unlink.module_id
                    );
                });

                setValue('menu_unlinks', removeItemFromUnlinkState, { shouldDirty: true });
            }
        },
        [
            setValue,
            category_id,
            fieldIndex,
            modeIndex,
            petPoojaItems,
            linkedItems,
            watch,
            setVariantOptions,
            setSelectedItemForVariants,
            selectedOutletId,
            orderingMode.restaurant_ordering_mode_id,
            item,
        ]
    );

    const label = useMemo(() => {
        return variantCount ? `` : `(${orderingMode?.price} ${selectedOutlet.outlet_data?.settings?.currency_code})`;
    }, [orderingMode?.price, selectedOutlet.outlet_data?.settings?.currency_code, variantCount]);

    return (
        <div id={`item_${item.item_id}`}>
            <EuiFormRow
                label={`${orderingMode.restaurant_ordering_mode_name} ${orderingMode?.price >= 0 ? label : ``}`}
                display={errors.message}
                error={
                    errors[`item_${category_id}[${fieldIndex}.ordering_modes[${modeIndex}].selected_item]`]
                        ? errors[`item_${category_id}[${fieldIndex}.ordering_modes[${modeIndex}].selected_item]`]
                              .message
                        : null
                }
            >
                <Controller
                    control={control}
                    name={`item_${category_id}[${fieldIndex}.ordering_modes[${modeIndex}].selected_item]`}
                    rules={{
                        validate: {
                            positive: (selectedOption) => {
                                // return !checkItemValidation(selectedOption, orderingMode.restaurant_ordering_mode_id)
                                //     ? amountError
                                //     : true;
                                checkItemValidation(selectedOption, orderingMode.restaurant_ordering_mode_id);

                                return true;
                            },
                        },
                    }}
                    render={({ field }) => {
                        return (
                            <EuiComboBox
                                options={itemsOptions}
                                placeholder='Select Item'
                                // isDisabled={isDisabled}
                                isClearable={true}
                                singleSelection={true}
                                selectedOptions={field.value}
                                onBlur={field.onBlur}
                                onChange={(selectedValue) => {
                                    field.onChange(selectedValue);
                                    handleItemLinkChange(selectedValue, field.value);
                                }}
                            />
                        );
                    }}
                />
            </EuiFormRow>
            {/* 
            {isDisabled && (
                <EuiText size='xs'>
                    Above is a mapped item. <br />
                </EuiText>
            )} */}

            <EuiSpacer size='s' />

            {!isEmpty(amountError) ? (
                <EuiTextColor siz='s' color='danger'>
                    {getErrorMesage(amountError)}
                </EuiTextColor>
            ) : null}

            {suggestionItems?.length ? (
                <>
                    <EuiSpacer size='s' />
                    <EuiFlexItem grow={false}>
                        <EuiText size='xs' style={{ fontWeight: 600 }}>
                            Suggested Match
                        </EuiText>
                    </EuiFlexItem>
                    <EuiFlexGroup style={{ maxWidth: 300 }} wrap>
                        {suggestionItems?.slice(0, 2)?.map(({ item }, index) => {
                            return (
                                <EuiFlexItem
                                    key={index.toString() + item.itemname}
                                    onClick={() => handleRegisterSuggestionAction(item)}
                                    grow={false}
                                >
                                    <EuiLink style={{ cursor: 'pointer' }}>
                                        {item.itemname}{' '}
                                        <>
                                            {!variantCount && (
                                                <>
                                                    ({item?.price} {selectedOutlet.outlet_data?.settings?.currency_code}
                                                    )
                                                </>
                                            )}
                                        </>
                                    </EuiLink>
                                </EuiFlexItem>
                            );
                        })}
                    </EuiFlexGroup>
                    <EuiSpacer size='xs' />
                </>
            ) : null}
        </div>
    );
};

export default React.memo(ItemLinkOrderingModes);
