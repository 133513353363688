import React, { useEffect, useState } from 'react';
import {
    EuiOverlayMask,
    EuiFlexGroup,
    EuiFlexItem,
    EuiTitle,
    EuiModal,
    EuiModalHeader,
    EuiModalBody,
    EuiButtonGroup,
    EuiLoadingContent,
} from '@elastic/eui';
import './style.css';
import { ETLDeliveryManagement } from '../orderDetailsCard/eltRiderManagemnet';
import { FabDeliveryManagment } from '../orderDetailsCard/fabDeliveryManagement';
import { DunzoRiderTab } from '../orderDetailsCard/dunzoRider';
import { WeFastRiderTab } from '../orderDetailsCard/weFastRider';
import { ScoutRiderTab } from '../orderDetailsCard/scoutRider';
import { AhoyRiderTab } from '../orderDetailsCard/ahoyRider';
import { ShadowFaxRiderTab } from '../orderDetailsCard/shadowFaxRider';
import { PorterRiderTab } from '../orderDetailsCard/porterRider';
import { useSelector } from 'react-redux';
import API from '../../api/axios/API';
import PidgeServices from '../orderDetailsCard/PidgeServices';
import { orderBy } from 'lodash';

export const RiderManageModal = (props) => {
    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);
    const [tabState, setTabState] = useState('bolt-rider');

    const [deliveryServices, setDeliveryServices] = useState([]);

    const [isPidgeServiceSelected, setIsPidgeServiceSelected] = useState(false);

    const [isLoadingPidgeRider, setIsLoadingPidgeRider] = useState(false);

    // [{id: 'bolt', label: 'Bolt Riders'}, {id: 'elt-riders', label: 'ELT Riders'}, {id: 'dunzo-riders', label: 'Dunzo Riders'}, {id: 'we-fast-riders', label: 'Wefast Riders'}, {id: 'scout-riders', label: 'Scout Riders'}, {id: 'ahoy-riders', label: 'Ahoy Riders'}]
    const [availablePidgeServices, setAvailablePidgeServices] = useState([]);

    const closeModal = () => {
        props.closeRiderModal();
    };

    useEffect(() => {
        if (
            props.order.outlet &&
            props.order.outlet.settings &&
            !props.order.outlet.settings.enable_own_rider_service
        ) {
            if (props.order.outlet && props.order.outlet.third_party_rider_setting) {
                riderEnableCheck();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.order.outlet]);

    useEffect(async () => {
        if (props.order.outlet && props.order.outlet.settings) {
            let innerArray = [];
            if (props.order.outlet.settings.enable_own_rider_service) {
                innerArray.push({ id: 'bolt-rider', label: 'Bolt Riders' });
            }
            if (props.order.outlet.settings?.pidge_service_enabled) {
                const pidgeServices = await getQuotation();
                setDeliveryServices([
                    ...innerArray,
                    ...orderBy(
                        pidgeServices?.map((el) => ({
                            id: `${el.network_id}`,
                            label: el.network_name,
                            params: el,
                            isDisabled: !el.pickup_now,
                        })) || [],
                        ['pickup_now']
                    ),
                ]);
                return;
            }

            if (props.order.outlet?.third_party_rider_setting?.elt_integration_available) {
                innerArray.push({ id: 'elt-rider', label: 'ELT Riders' });
            }

            if (props.order.outlet?.third_party_rider_setting?.dunzo_integration_available) {
                innerArray.push({ id: 'dunzo-rider', label: 'Dunzo Riders' });
            }

            if (props.order.outlet?.third_party_rider_setting?.wefast_integration_available) {
                innerArray.push({ id: 'we-fast-rider', label: 'Wefast Riders' });
            }

            if (props.order.outlet?.third_party_rider_setting?.scout_runner_integration_available) {
                innerArray.push({ id: 'scout-rider', label: 'Scout Riders' });
            }

            if (props.order.outlet?.third_party_rider_setting?.ahoy_integration_available) {
                innerArray.push({ id: 'ahoy-rider', label: 'Ahoy Riders' });
            }

            if (props.order.outlet?.third_party_rider_setting?.is_porter_integration_enabled) {
                innerArray.push({ id: 'porter-rider', label: 'Porter rider' });
            }

            if (props.order.outlet?.third_party_rider_setting?.is_shadowfax_integration_enabled) {
                innerArray.push({ id: 'shadow-fax-rider', label: 'Shadow fax Riders' });
            }

            setDeliveryServices(innerArray);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.order.outlet]);

    const getQuotation = async () => {
        try {
            setIsLoadingPidgeRider(true);
            const response = await API.post(`/restaurants/${selectedRestaurantId}/pigde/get-quotation`, {
                order_id: props.order.order_id,
            });

            if (response.success) {
                setIsLoadingPidgeRider(false);
                setAvailablePidgeServices([...response?.response?.service_details]);

                return response?.response?.service_details;
            }
        } catch (error) {
            return [];
        }
    };

    const riderEnableCheck = () => {
        switch (1) {
            case props.order.outlet.third_party_rider_setting.elt_integration_available:
                setTabState('elt-rider');
                return;
            case props.order.outlet.third_party_rider_setting.dunzo_integration_available:
                setTabState('dunzo-rider');
                return;
            case props.order.outlet.third_party_rider_setting.wefast_integration_available:
                setTabState('we-fast-rider');
                return;
            case props.order.outlet.third_party_rider_setting.scout_runner_integration_available:
                setTabState('scout-rider');
                return;
            case props.order.outlet.third_party_rider_setting.ahoy_integration_available:
                setTabState('ahoy-rider');
                return;
            case parseInt(props.order.outlet.third_party_rider_setting.is_shadowfax_integration_enabled):
                setTabState('shadow-fax-rider');
                return;
            case parseInt(props.order.outlet.third_party_rider_setting.is_porter_integration_enabled):
                setTabState('porter-rider');
                return;
            default:
                return;
        }
    };

    const serviceCheck = () => {
        let msg = riderServiceCheckSwitch();

        return (
            <EuiFlexItem style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <b>{msg}</b>
            </EuiFlexItem>
        );
    };

    const riderServiceCheckSwitch = () => {
        switch (1) {
            case props.order.outlet.settings.enable_own_rider_service:
            case props.order.outlet.third_party_rider_setting.elt_integration_available:
            case props.order.outlet.third_party_rider_setting.dunzo_integration_available:
            case props.order.outlet.third_party_rider_setting.wefast_integration_available:
            case props.order.outlet.third_party_rider_setting.scout_runner_integration_available:
            case props.order.outlet.third_party_rider_setting.ahoy_integration_available:
            case props.order.outlet.third_party_rider_setting.is_porter_integration_enabled:
            case parseInt(props.order.outlet.third_party_rider_setting.is_shadowfax_integration_enabled):
                return '';
            default:
                return 'Please select atleast one Rider service';
        }
    };

    const onChangeDeliveryService = (value) => {
        setTabState(value);

        const isPidgeService = availablePidgeServices?.some((el) => el?.network_id == value);

        setIsPidgeServiceSelected(isPidgeService);
    };

    return (
        <>
            {props.riderModalOpen && (
                <EuiOverlayMask>
                    <EuiModal
                        onClose={() => {
                            closeModal();
                        }}
                        style={{ zIndex: 6000, maxWidth: 300 }}
                    >
                        <EuiModalHeader>
                            <EuiTitle size='m'>
                                <h2>Rider Manage</h2>
                            </EuiTitle>
                        </EuiModalHeader>
                        {isLoadingPidgeRider ? (
                            <EuiLoadingContent
                                style={{ paddingLeft: 24, paddingRight: 24, marginBottom: 32 }}
                                lines={4}
                            />
                        ) : (
                            <EuiModalBody>
                                <EuiFlexGroup
                                    alignItems='center'
                                    justifyContent='spaceBetween'
                                    style={{
                                        overflowX: 'scroll',
                                    }}
                                >
                                    <EuiFlexItem>
                                        <EuiButtonGroup
                                            options={deliveryServices}
                                            idSelected={tabState}
                                            onChange={onChangeDeliveryService}
                                            color='primary'
                                        />
                                    </EuiFlexItem>
                                </EuiFlexGroup>

                                {tabState === 'elt-rider' &&
                                props.order.outlet &&
                                props.order.outlet.third_party_rider_setting &&
                                props.order.outlet.third_party_rider_setting.elt_integration_available ? (
                                    <ETLDeliveryManagement {...props} />
                                ) : (
                                    ''
                                )}
                                {tabState === 'bolt-rider' &&
                                props.order.outlet &&
                                props.order.outlet.settings &&
                                props.order.outlet.settings.enable_own_rider_service ? (
                                    <FabDeliveryManagment {...props} />
                                ) : (
                                    ''
                                )}
                                {tabState === 'dunzo-rider' &&
                                props.order.outlet &&
                                props.order.outlet.third_party_rider_setting &&
                                props.order.outlet.third_party_rider_setting.dunzo_integration_available ? (
                                    <DunzoRiderTab {...props} />
                                ) : (
                                    ''
                                )}
                                {tabState === 'we-fast-rider' &&
                                props.order.outlet &&
                                props.order.outlet.third_party_rider_setting &&
                                props.order.outlet.third_party_rider_setting.wefast_integration_available ? (
                                    <WeFastRiderTab {...props} />
                                ) : (
                                    ''
                                )}
                                {tabState === 'scout-rider' &&
                                props.order.outlet &&
                                props.order.outlet.third_party_rider_setting &&
                                props.order.outlet.third_party_rider_setting.scout_runner_integration_available ? (
                                    <ScoutRiderTab {...props} />
                                ) : (
                                    ''
                                )}
                                {tabState === 'ahoy-rider' &&
                                props.order.outlet &&
                                props.order.outlet.third_party_rider_setting &&
                                props.order.outlet.third_party_rider_setting.ahoy_integration_available ? (
                                    <AhoyRiderTab {...props} />
                                ) : (
                                    ''
                                )}
                                {tabState === 'shadow-fax-rider' &&
                                parseInt(
                                    props?.order?.outlet?.third_party_rider_setting?.is_shadowfax_integration_enabled
                                ) ? (
                                    <ShadowFaxRiderTab {...props} />
                                ) : (
                                    ''
                                )}

                                {isPidgeServiceSelected && (
                                    <PidgeServices
                                        closeModal={closeModal}
                                        orderId={props.order.order_id}
                                        tabState={tabState}
                                        availablePidgeServices={availablePidgeServices}
                                        isOrderAccepted={props?.order?.status?.restaurant_accepted_at}
                                        {...props}
                                    />
                                )}

                                {tabState === 'porter-rider' &&
                                parseInt(
                                    props?.order?.outlet?.third_party_rider_setting?.is_porter_integration_enabled
                                ) ? (
                                    <PorterRiderTab {...props} />
                                ) : (
                                    ''
                                )}
                                {serviceCheck()}
                            </EuiModalBody>
                        )}
                        {/* <EuiModalFooter>
                            <EuiButton onClick={() => closeModal()} fill>
                                Close
                            </EuiButton>
                        </EuiModalFooter> */}
                    </EuiModal>
                </EuiOverlayMask>
            )}
        </>
    );
};
