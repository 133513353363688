import API from './axios/API';

export const riderOrderCancel = (deliveryService, orderId) => {
    return async (dispatch, getState) => {
        let response;

        try {
            response = await API.post(`/restaurants/:restaurantId/${deliveryService}/cancel-order`, {
                orderId: orderId,
                cancellation_reason: 'customer cancel',
            });
        } catch (error) {
            response = error;
        }

        return response;
    };
};
