import APIV3 from '../../../api/axios/APIV3';
import { SET_SECTION_LIST } from '../../../reduxStore/types/tableManagement';

export const fetchOutletSections = () => {
    return async (dispatch, getState) => {
        let resposne;
        const { selectedOutletId } = getState().outlet;

        try {
            resposne = await APIV3.get(`restaurants/:restaurantId/outlets/${selectedOutletId}/outlet-sections`);

            dispatch({
                type: SET_SECTION_LIST,
                payload: resposne.sections,
            });
        } catch (error) {
            resposne = error;
        }

        return resposne;
    };
};
