import { EuiIcon } from '@elastic/eui';
import React, { useState } from 'react';
import CreditsAlertModal from '../../../components/Credits/CreditsAlertModal';

const CreditsSettings = ({ alertType, alertAmount, setUpdateTopupData }) => {
    const [isOpen, setIsOpen] = useState(false);

    const onClose = () => {
        setIsOpen(false);
    };

    return (
        <>
            {isOpen && (
                <CreditsAlertModal
                    alertAmount={alertAmount}
                    alertType={alertType}
                    closeModal={onClose}
                    setUpdateTopupData={setUpdateTopupData}
                />
            )}

            <EuiIcon
                onClick={() => setIsOpen(true)}
                type='gear'
                style={{ marginLeft: '18px', cursor: 'pointer' }}
                size='l'
            />
        </>
    );
};

export default CreditsSettings;
