import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EuiFieldNumber } from '@elastic/eui';
import APIV4 from '../../api/axios/APIV4';
import Components from './Components/Components';
import APIV3 from '../../api/axios/APIV3';
import { toastsErrorMessage } from '../../utils/toasts';

const MOV = () => {
    const [orderingModes, setOrderingModes] = useState([]);

    const [showSaveFooter, setShowSaveFooter] = useState(false);

    const dispatch = useDispatch();

    const outletId = useSelector((state) => state.outlet.selectedOutletId);

    const restaurantID = useSelector((state) => state.auth.selectedRestaurantId);

    const outletOrderingModes = useSelector((state) => state?.outlet?.outletOrderingModes?.ordering_modes);

    useEffect(() => {
        if (outletOrderingModes?.length) {
            const list = outletOrderingModes.map((el) => {
                return {
                    restaurant_ordering_mode_id: el.restaurant_ordering_mode_id,
                    display_name: el.display_name,
                    is_enabled: el.is_enabled,
                };
            });
            setOrderingModes(list);
            setModesWithMov(list);
        }
    }, [outletOrderingModes]);

    const [modesWithMov, setModesWithMov] = useState([]);

    const getMovData = async () => {
        try {
            const response = await APIV4.get(`restaurants/${restaurantID}/outlets/${outletId}`);

            let movValue = [];
            if (response.outlet.minimum_ording_values.length === 0) {
                movValue = orderingModes.map((el) => {
                    return {
                        restaurant_ordering_mode_id: el.restaurant_ordering_mode_id,
                        minimum_ordering_value: null,
                    };
                });
            } else {
                movValue = response.outlet.minimum_ording_values.map((el) => {
                    return {
                        restaurant_ordering_mode_id: el.restaurant_ordering_mode_id,
                        minimum_ordering_value: el.minimum_ordering_value,
                    };
                });
            }

            const updatedModes = orderingModes.map((mode) => {
                const movItem = movValue.find(
                    (item) => item.restaurant_ordering_mode_id === mode.restaurant_ordering_mode_id
                );
                if (movItem) {
                    return { ...mode, minimum_ordering_value: movItem.minimum_ordering_value };
                }
                return mode;
            });

            setModesWithMov(updatedModes);
        } catch (error) {
            console.log('error : ', error);
        }
    };

    useEffect(() => {
        if (orderingModes?.length) {
            getMovData();
        }
    }, [outletId, orderingModes]);

    const onSave = async () => {
        const data = modesWithMov.map((el) => {
            return {
                restaurant_ordering_mode: el.restaurant_ordering_mode_id,
                minimum_ordering_value: el.minimum_ordering_value ?? 0,
            };
        });

        const formattedData = {
            section: 'minimum_order_value_configuration',
            minimum_ordering_values: data,
        };

        try {
            const res = await APIV3.put(`restaurants/${restaurantID}/outlets/${outletId}`, {
                ...formattedData,
            });

            setShowSaveFooter(false);

            if (res.success == 'false') {
                toastsErrorMessage('Oops, There was error.', dispatch);
            }
        } catch (error) {
            console.log('error : ', error);
            toastsErrorMessage('Oops, There was error.', dispatch);
        }
    };

    const onDiscard = () => {
        getMovData();
        setShowSaveFooter(false);
    };

    const handleMovChange = (index, value) => {
        setShowSaveFooter(true);

        const updatedModes = [...modesWithMov];

        updatedModes[index].minimum_ordering_value = value;

        setModesWithMov(updatedModes);
    };

    return (
        <>
            {modesWithMov?.map((el, index) => {
                if (!el.is_enabled) return null;

                return (
                    <React.Fragment key={index}>
                        <p style={{ fontSize: '15px', marginTop: '25px', marginBottom: '12px' }}>
                            {`Set the value of ${el.display_name}`} for MOV
                        </p>
                        <EuiFieldNumber
                            placeholder='Please enter MOV value'
                            value={el.minimum_ordering_value || ''}
                            onChange={(e) => handleMovChange(index, e.target.value)}
                        />
                    </React.Fragment>
                );
            })}

            {showSaveFooter && <Components onDiscard={onDiscard} onSave={onSave} />}
        </>
    );
};
export default MOV;
