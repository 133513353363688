import { SET_DELETE_CATEGORY_ID, SET_DELETE_CONFIRMATION_MODAL } from '../../../../../../reduxStore/types/menu';

export const defaultValueSet = (defaultObject, item) => {
    for (let key in item.title) {
        defaultObject[`title${key}`] = item.title[key];
    }

    for (let key in item.description) {
        defaultObject[`description${key}`] = item.description[key];
    }

    for (let key in item.size) {
        defaultObject[`size${key}`] = item.size[key];
    }

    for (let key in item.serves) {
        defaultObject[`serves${key}`] = item.serves[key];
    }
};

export const positionOptions = [
    {
        value: 'top-left',
        inputDisplay: 'Top Left',
    },
    {
        value: 'top-right',
        inputDisplay: 'Top Right',
    },
    {
        value: 'bottom-left',
        inputDisplay: 'Bottom Left',
    },
    {
        value: 'bottom-right',
        inputDisplay: 'Bottom Right',
    },
];

export const onDeleteCategory = (dispatch, id) => {
    dispatch({
        type: SET_DELETE_CONFIRMATION_MODAL,
        payload: {
            showDeleteConfirmationModal: true,
        },
    });

    dispatch({
        type: SET_DELETE_CATEGORY_ID,
        payload: {
            deleteCategoryId: id,
        },
    });
};
