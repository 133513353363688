import React, { useEffect, useState } from 'react';
import { EuiFlexItem, EuiDescribedFormGroup, EuiForm, EuiFormRow, EuiButtonGroup, EuiSpacer } from '@elastic/eui';
import ButtonGroup from '../Form/ButtonGroup';
import NumberField from '../Form/NumberField';
import { isEmpty } from 'lodash';
import { Controller } from 'react-hook-form';
import RadioField from '../Form/RadioField';
import { CategoryDrag } from './categoryDrag';
import useOutletFormValues from '../../modules/OutletSettings/Hooks/useOutletFormValues';
import useFormActionsHandler from '../../hooks/useFormActionsHandler';
import ComboBoxField from './Components/ComboBoxField';
import SelectField from './Components/SelectField';

const PrinterComponent = (props) => {
    const { outletId } = props;

    const [printerOptions, setPrinteroptions] = useState([]);

    const [updateForm, setUpdateForm] = useState({});

    const updateFormValues = () => {};

    const { defaultValues, setDefaultValues, onSuccessCallback } = useOutletFormValues({ updateFormValues });

    const { ipcRenderer } = window;

    const getPrinterList = async () => {
        try {
            const invokeIpcRanderer = await ipcRenderer.invoke('get-printers');

            const printerList = await invokeIpcRanderer;

            const formattedPrinterList = printerList.map((el) => {
                return { label: el.displayName, value: el.name, inputDisplay: el.displayName };
            });

            setPrinteroptions(formattedPrinterList);
        } catch (error) {
            console.error('Error fetching printers:', error);
        }
    };

    useEffect(() => {
        getPrinterList();
    }, []);

    const onFormSaveApi = async (data) => {
        data.outlet_id = outletId;
        localStorage.removeItem('printer_configurations');

        localStorage.setItem('printer_configurations', JSON.stringify(data));
        setUpdateForm({ ...data });
        setDefaultValues(updateFormValues());
        return {
            success: true,
        };
    };

    const { control, errors, watch, setValue, reset } = useFormActionsHandler({
        onFormSaveApi,
        defaultValues,
        onSuccessCallback,
    });

    useEffect(() => {
        setValue('default_kot_printer', '');
        setValue('default_kot_printers_list', []);
    }, [watch('kot_radio')]);

    useEffect(() => {
        setValue('default_bill_printer', '');
        setValue('default_bill_printers_list', []);
    }, [watch('bill_radio')]);

    return (
        <EuiForm component='form'>
            <EuiFlexItem>
                <EuiDescribedFormGroup title={<h3>Use Printer</h3>} description='Set use printer functionality'>
                    <Controller
                        render={({ field }) => (
                            <EuiFormRow label={''}>
                                <EuiButtonGroup
                                    options={[
                                        { id: '1', label: 'Yes, I want to use printer', iconType: 'check' },
                                        { id: '0', label: "No, I doesn't want to use printer", iconType: 'cross' },
                                    ]}
                                    idSelected={field.value}
                                    onChange={field.onChange}
                                    name='use_printer'
                                    color='primary'
                                    legend='this is printing button'
                                />
                            </EuiFormRow>
                        )}
                        name='use_printer'
                        control={control}
                    />
                </EuiDescribedFormGroup>
            </EuiFlexItem>

            <EuiSpacer />

            <EuiFlexItem>
                <EuiDescribedFormGroup title={<h3>Auto print</h3>} description='Enable auto print functionality'>
                    <ButtonGroup
                        color='primary'
                        control={control}
                        name='auto_print'
                        legend='this is auto print functionality'
                    />
                </EuiDescribedFormGroup>
            </EuiFlexItem>

            <EuiSpacer />

            {watch('use_printer') === '1' && (
                <>
                    <EuiFlexItem>
                        <EuiDescribedFormGroup
                            title={<h3>Select BILL Printer</h3>}
                            description='Select default printer'
                        >
                            <RadioField
                                name='bill_radio'
                                label='Billing'
                                options={[
                                    { id: 'single', label: 'Single printer setup' },
                                    { id: 'multiple', label: 'Multiple printer setup' },
                                ]}
                                idSelected={watch('bill_radio')}
                                onChange={(id) => setValue('bill_radio', id)}
                                control={control}
                                defaultValue={'single'}
                            />
                        </EuiDescribedFormGroup>
                    </EuiFlexItem>

                    <EuiSpacer />

                    {watch('bill_radio') === 'single' ? (
                        <EuiFlexItem>
                            <EuiDescribedFormGroup
                                title={<h3>Select BILL Printer</h3>}
                                description='Select default printer'
                            >
                                <SelectField
                                    errors={errors}
                                    label=''
                                    name='default_bill_printer'
                                    options={printerOptions}
                                    onChange={(value) => setValue('default_bill_printer', value, { shouldDirty: true })}
                                    placeholder='Please enter your BILL printer'
                                    control={control}
                                    watch={watch}
                                    rules={{ required: 'Please select a printer' }}
                                    defaultValue={watch('default_bill_printer') ? watch('default_bill_printer') : null}
                                />
                            </EuiDescribedFormGroup>

                            <EuiSpacer />

                            {/* <EuiFlexItem>
                                <EuiDescribedFormGroup
                                    title={<h3>Number of copies for bill</h3>}
                                    description='Enter number of copies you want to print for each bill'
                                >
                                    <NumberField
                                        label={''}
                                        errors={errors}
                                        name={`bill_print_count`}
                                        placeholder='Enter BILL count'
                                        control={control}
                                        rules={{ required: 'Please bill count' }}
                                    />
                                </EuiDescribedFormGroup>
                            </EuiFlexItem> */}
                        </EuiFlexItem>
                    ) : (
                        <EuiFlexItem>
                            <EuiDescribedFormGroup
                                title={<h3>Select BILL Printer</h3>}
                                description='Select default printer'
                            >
                                <ComboBoxField
                                    options={printerOptions.map((el, index) => {
                                        return { label: el.label, value: el.value, id: index };
                                    })}
                                    selectedOptions={
                                        watch(`default_bill_printers_list`) ? watch(`default_bill_printers_list`) : []
                                    }
                                    onChange={(selectedOptions) => {
                                        console.log(selectedOptions, 'selectedOptions'),
                                            setValue('default_bill_printers_list', selectedOptions, {
                                                shouldDirty: true,
                                            });
                                    }}
                                    errors={errors}
                                    label=''
                                    name={`default_bill_printers_list`}
                                    placeholder='Select printers'
                                    control={control}
                                    rules={{ required: 'Please bill count' }}
                                />
                            </EuiDescribedFormGroup>

                            <EuiSpacer />
                            {/* 
                            {watch('default_bill_printers_list') &&
                                watch('default_bill_printers_list').map((printer, index) => (
                                    <EuiFlexItem key={index}>
                                        <EuiDescribedFormGroup
                                            title={<h3>Number of copies for {printer.label}</h3>}
                                            description='Enter number of copies you want to print for each bill'
                                        >
                                            <NumberField
                                                label={''}
                                                errors={errors}
                                                name={`bill_print_count_${printer.value}`}
                                                placeholder='Enter BILL count'
                                                control={control}
                                                rules={{ required: 'Please bill count' }}
                                            />
                                        </EuiDescribedFormGroup>
                                    </EuiFlexItem>
                                ))} */}
                        </EuiFlexItem>
                    )}

                    <EuiFlexItem>
                        <EuiDescribedFormGroup title={<h3>Select KOT Printer</h3>} description='Select default printer'>
                            <RadioField
                                name='kot_radio'
                                label='Kot'
                                options={[
                                    { id: 'singleKot', label: 'Single printer setup' },
                                    { id: 'multipleKot', label: 'Multiple printer setup' },
                                ]}
                                idSelected={watch('kot_radio')}
                                onChange={(id) => setValue('kot_radio', id, { shouldDirty: true })}
                                control={control}
                                defaultValue={'singleKot'}
                            />
                        </EuiDescribedFormGroup>
                    </EuiFlexItem>

                    <EuiSpacer />

                    {watch('kot_radio') === 'singleKot' ? (
                        <EuiFlexItem>
                            <EuiDescribedFormGroup
                                title={<h3>Select KOT Printer</h3>}
                                description='Select default printer'
                            >
                                <SelectField
                                    errors={errors}
                                    label=''
                                    name='default_kot_printer'
                                    options={printerOptions}
                                    valueOfSelected={watch('default_kot_printer') ? watch('default_kot_printer') : null}
                                    onChange={(value) => setValue('default_kot_printer', value, { shouldDirty: true })}
                                    placeholder='Please enter your KOT printer'
                                    control={control}
                                    watch={watch}
                                    defaultValue={watch('default_kot_printer') ? watch('default_kot_printer') : null}
                                    rules={{ required: 'Please select printer' }}
                                />
                            </EuiDescribedFormGroup>

                            {/* <EuiDescribedFormGroup
                                title={<h3>Number of copies for print KOT</h3>}
                                description='Enter number of copies you want to print for each bill'
                            >
                                <NumberField
                                    label={''}
                                    errors={errors}
                                    name='kot_print_count'
                                    placeholder='Enter KOT count'
                                    control={control}
                                    rules={{ required: 'Please enter count' }}
                                />
                            </EuiDescribedFormGroup> */}
                        </EuiFlexItem>
                    ) : (
                        <>
                            <EuiFlexItem>
                                <EuiDescribedFormGroup
                                    title={<h3>Select KOT Printer</h3>}
                                    description='Select default printer'
                                >
                                    <ComboBoxField
                                        options={printerOptions.map((el, index) => {
                                            return { label: el.label, value: el.value, id: index };
                                        })}
                                        selectedOptions={
                                            watch(`default_kot_printers_list`) ? watch(`default_kot_printers_list`) : []
                                        }
                                        errors={errors}
                                        label=''
                                        name={`default_kot_printers_list`}
                                        placeholder='Select printers'
                                        control={control}
                                        rules={{ required: 'Please select printer' }}
                                    />
                                </EuiDescribedFormGroup>
                            </EuiFlexItem>

                            <EuiSpacer />

                            {/* {watch('default_kot_printers_list') &&
                                watch('default_kot_printers_list').map((printer, index) => (
                                    <EuiFlexItem>
                                        {console.log('printer==>', printer)}
                                        <EuiDescribedFormGroup
                                            title={<h3>Number of copies for {printer.label}</h3>}
                                            description='Enter number of copies you want to print for each bill'
                                        >
                                            <NumberField
                                                label={''}
                                                errors={errors}
                                                name={`kot_print_count_.${index}.count`}
                                                placeholder='Enter BILL count'
                                                control={control}
                                                rules={{ required: 'Please enter number' }}
                                            />
                                        </EuiDescribedFormGroup>
                                    </EuiFlexItem>
                                ))} */}

                            {/* {watch('kot_radio') === 'multipleKot' ? (
                                <EuiFlexItem>
                                    <EuiDescribedFormGroup
                                        title={<h3>Split kot by category</h3>}
                                        description='Enable to split incoming order from different printers'
                                    >
                                        <ButtonGroup
                                            idSelected={watch('split_kot') ? '1' : '0'}
                                            color='primary'
                                            control={control}
                                            setValue={setValue}
                                            name='split_kot'
                                            label={''}
                                            legend='this is spilt KOT'
                                        />
                                    </EuiDescribedFormGroup>
                                </EuiFlexItem>
                            ) : (
                                ''
                            )} */}

                            {/* {watch('default_kot_printers_list') &&
                            watch('default_kot_printers_list').length &&
                            watch('split_kot') &&
                            watch('kot_radio') === 'multipleKot' ? (
                                <EuiFlexItem style={{ display: 'flex', flexDirection: 'column' }}>
                                    <CategoryDrag
                                        printerList={watch('default_kot_printers_list')}
                                        watch={watch}
                                        setValue={setValue}
                                        errors={errors}
                                        control={control}
                                        outletId={outletId}
                                    />
                                    {watch('default_kot_printers_list').map((printer) => (
                                        <EuiFlexItem style={{ display: 'none' }}>
                                            <ComboBoxField
                                                options={[]}
                                                selectedOptions={
                                                    watch(`printers.${printer.value}`)
                                                        ? watch(`printers.${printer.value}`)
                                                        : []
                                                }
                                                onChangeData={(value) =>
                                                    setValue(`printers.${printer.value}`, value, {
                                                        shouldDirty: true,
                                                    })
                                                }
                                                errors={errors}
                                                label=''
                                                name={`printers.${printer.value}.data`}
                                                placeholder='Select Categoryies'
                                                control={control}
                                                watch={watch}
                                                rules={{ required: 'Please select category' }}
                                            />
                                        </EuiFlexItem>
                                    ))}
                                </EuiFlexItem>
                            ) : (
                                ''
                            )} */}
                        </>
                    )}
                </>
            )}
        </EuiForm>
    );
};

export default PrinterComponent;
