import { EuiButtonEmpty } from '@elastic/eui';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { downloadPastOrderCsvFile } from '../../../api/pastOrder/downloadPastOrderCsvFile';

const DownloadCSV = ({ startDateTime, endDateTime, selectedOutletId }) => {
    const languageId = useSelector((state) => state.language.languageId);
    const outletList = useSelector((state) => state.outlet.outletList);
    const selectedOutlet =
        selectedOutletId == 'all_outlet' ? outletList.map((outlet) => outlet.value) : [selectedOutletId];
    const dispatch = useDispatch();

    const downloadPastOrder = async () => {
        await dispatch(downloadPastOrderCsvFile(startDateTime, endDateTime, languageId, selectedOutlet));
    };

    return (
        <>
            {' '}
            <EuiButtonEmpty onClick={downloadPastOrder} style={{ marginRight: '10px' }}>
                Download CSV
            </EuiButtonEmpty>
        </>
    );
};

export default DownloadCSV;
