import { EuiBottomBar, EuiButton, EuiButtonEmpty, EuiFlexItem, EuiPortal } from '@elastic/eui';
import React from 'react';

export default function Components(props) {
    const { onDiscard, onSave } = props;

    return (
        <EuiPortal>
            <EuiBottomBar style={{ backgroundColor: 'white', zIndex: 999 }}>
                <EuiFlexItem style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <EuiFlexItem
                        grow={2}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <EuiFlexItem style={{ marginRight: '15px' }}>
                            <EuiButtonEmpty onClick={onDiscard} size='s' color='danger'>
                                Discard changes
                            </EuiButtonEmpty>
                        </EuiFlexItem>

                        <EuiFlexItem>
                            <EuiButton onClick={onSave} size='s' fill={true}>
                                Save
                            </EuiButton>
                        </EuiFlexItem>
                    </EuiFlexItem>
                </EuiFlexItem>
            </EuiBottomBar>
        </EuiPortal>
    );
}
