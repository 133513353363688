import React, { useCallback } from 'react';
import { EuiForm, EuiSpacer } from '@elastic/eui';
import TextField from '../../../components/Form/TextField';
import FormGroupDescription from '../../../components/formGroupDescription';
import { useDispatch, useSelector } from 'react-redux';
import { outletSettingsAPI } from '../../../api/outlet/outletSettingsAPI';
import useOutletFormValues from '../Hooks/useOutletFormValues';
import useFormActionsHandler from '../../../hooks/useFormActionsHandler';

const FoodLicence = () => {
    const dispatch = useDispatch();
    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);

    const updateFormValues = useCallback((data) => {
        return {
            fssai_licence: data?.fssai_licence?.licence,
        };
    }, []);

    const { defaultValues, setDefaultValues, onSuccessCallback } = useOutletFormValues({ updateFormValues });

    const onFormSaveApi = useCallback(
        async (data) => {
            const response = await dispatch(outletSettingsAPI('licence', data));
            if (response.success) setDefaultValues(updateFormValues(response.outlet));
            return response;
        },
        [dispatch, updateFormValues]
    );

    const { control, errors } = useFormActionsHandler({ onFormSaveApi, defaultValues, onSuccessCallback });

    return (
        <EuiForm component='form'>
            <FormGroupDescription
                title={restaurantDescriptions?.license_number?.display_name}
                description={restaurantDescriptions?.license_number?.description}
            >
                <TextField
                    errors={errors}
                    label={<b>Licence</b>}
                    name='fssai_licence'
                    placeholder='Please enter fssai licence'
                    control={control}
                />
                <EuiSpacer />
            </FormGroupDescription>
        </EuiForm>
    );
};

export default FoodLicence;
