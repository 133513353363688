import { EuiCollapsibleNavGroup, EuiText } from '@elastic/eui';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchCategoryItemsForAddonLink } from '../catalogue/Api/FetchCatalogueInfo';
import AddonLinkItemList from './AddonLinkItemList';

const AddonLinkSubCategoryItemList = ({
    category,
    handleLinkChange,
    selectedAddon,
    handleVariantLinkChange,
    addonVariantLinkStatus,
}) => {
    const isOutletMenu = useSelector((state) => state.menuReducer.isOutletMenu);
    const [listDetails, setListDetails] = useState({
        items: [],
        isLoading: false,
    });
    const languageId = useSelector((state) => state.language.languageId);

    const itemListRender = useCallback(
        (itemList) => {
            let list = [];
            if (itemList?.length) {
                itemList.forEach((item) => {
                    list.push(
                        <AddonLinkItemList
                            key={item.item_id.toString()}
                            item={item}
                            addonVariantLinkStatus={addonVariantLinkStatus}
                            handleLinkChange={handleLinkChange}
                            itemList={itemList}
                            selectedAddon={selectedAddon}
                            handleVariantLinkChange={handleVariantLinkChange}
                        />
                    );
                });
            }

            return list;
        },
        [handleLinkChange, selectedAddon, handleVariantLinkChange, addonVariantLinkStatus]
    );

    const handleCategoryClick = useCallback(
        (selectedCategory) => {
            setListDetails((prevState) => ({ ...prevState, isLoading: true }));
            let items = [];
            fetchCategoryItemsForAddonLink(selectedCategory.category_id, languageId)
                .then((response) => {
                    if (response.success) {
                        items.push(...response.items);
                        setListDetails((prevState) => ({ ...prevState, items: items, isLoading: false }));
                    } else {
                        setListDetails((prevState) => ({ ...prevState, isLoading: false }));
                    }
                })
                .catch((err) => {
                    setListDetails((prevState) => ({ ...prevState, isLoading: false }));
                });
        },
        [languageId]
    );

    return (
        <>
            <EuiCollapsibleNavGroup
                key={category.category_id.toString()}
                background='light'
                title={
                    <EuiText>
                        <h3>{isOutletMenu ? category?.translations?.title[languageId] : category?.internal_name}</h3>
                    </EuiText>
                }
                isCollapsible={true}
                initialIsOpen={false}
                arrowDisplay={'left'}
                isLoading={listDetails.isLoading}
                onToggle={(isOpen) => {
                    if (isOpen && !listDetails.items.length) {
                        handleCategoryClick(category);
                    }
                }}
            >
                {itemListRender(listDetails.items)}
            </EuiCollapsibleNavGroup>
        </>
    );
};

export default React.memo(AddonLinkSubCategoryItemList);
