import { EuiFlexGroup, EuiFlexItem, EuiLoadingContent, EuiSpacer } from '@elastic/eui';
import _, { debounce, isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AvailabilityTimeModal from '../../../components/menuComponentsRefactor/itemAvailability/Treeview/AvailabilityTimeModal';
import FilterAvailability from '../../../components/ItemAvailability/FilterAvailability';
import FilterSearchedItems from '../../../components/ItemAvailability/FilterSearchedItems';
import { fetchAddonGroupsAvailability } from '../../../components/ItemAvailability/AddonAvailabilityScreen/Api/FetchAddonGroupsAvailability';
import { fetchSearchedAddons } from '../../../components/ItemAvailability/AddonAvailabilityScreen/Api/FetchSearchedAddons';
import AddonGroupRow, {
    checkAddonGroupStatus,
} from '../../../components/ItemAvailability/AddonAvailabilityScreen/Components/AddonGroupRow';

const AddonAvailabilityScreen = () => {
    const dispatch = useDispatch();
    const languageId = useSelector((state) => state.language.languageId);
    const outletId = useSelector((state) => state.outlet.selectedOutletId);
    const [filteredAddonGroups, setFilteredAddonGroups] = useState([]);
    const [addonGroupsState, setAddonGroupsState] = useState({
        groups: [],
    });
    const [state, setState] = useState({
        availabilityModalData: {},
        loading: true,
    });

    useEffect(() => {
        setFilteredAddonGroups(addonGroupsState.groups);
    }, [addonGroupsState.groups]);

    const fetchAddonGroups = useCallback(async () => {
        setState((prevState) => ({
            ...prevState,
            loading: true,
        }));
        await dispatch(fetchAddonGroupsAvailability()).then((response) => {
            setState((prevState) => ({
                ...prevState,
                loading: false,
            }));
            if (!response?.isError) {
                setAddonGroupsState((prevState) => ({
                    ...prevState,
                    groups: response.addon_groups,
                }));
            }
        });
    }, [dispatch]);

    useEffect(() => {
        fetchAddonGroups();
    }, [outletId]);

    const handleAvailablityModal = useCallback((modalData) => {
        setState((prevState) => ({
            ...prevState,
            availabilityModalData: modalData,
        }));
    }, []);

    const handleFilterChange = useCallback(
        (value) => {
            if (value === 'out_of_stock') {
                let outOfStockAddonGroups = [];
                for (const group of addonGroupsState.groups) {
                    let group_status = checkAddonGroupStatus(group);
                    if (group_status === 'turned_off' || group_status === 'partial_state') {
                        outOfStockAddonGroups.push(group);
                    }
                }
                setFilteredAddonGroups(outOfStockAddonGroups?.length ? outOfStockAddonGroups : []);
            } else if (value === 'all_items') {
                setFilteredAddonGroups(addonGroupsState.groups);
            }
        },
        [addonGroupsState.groups]
    );

    const debouncedSearch = debounce(async (text, languageId) => {
        setState((prevState) => ({
            ...prevState,
            loading: true,
        }));
        await fetchSearchedAddons(outletId, text).then((response) => {
            if (response?.restaurant_addon_groups?.length) {
                let matched_addons = [];
                for (const existingCategory of filteredAddonGroups) {
                    for (const searchedAddon of response.restaurant_addon_groups) {
                        if (existingCategory.id === searchedAddon.addon_group_id) {
                            matched_addons.push({ ...existingCategory, choices: searchedAddon?.choices });
                        }
                    }
                }
                setFilteredAddonGroups(matched_addons);
            }
            setState((prevState) => ({
                ...prevState,
                loading: false,
            }));
        });
    }, 1000);

    const handleSearchedItems = useCallback(
        (query) => {
            if (!_.isEmpty(query)) {
                debouncedSearch(query, languageId);
            } else {
                setFilteredAddonGroups(addonGroupsState.groups);
            }
        },
        [addonGroupsState.groups, debouncedSearch, languageId]
    );

    useEffect(() => {
        return function cleanup() {
            debouncedSearch.cancel();
        };
    }, [debouncedSearch]);

    return (
        <>
            <EuiFlexGroup justifyContent='spaceBetween' direction='row' alignItems='flexEnd'>
                <EuiFlexItem style={{ minWidth: 280 }} grow={false}>
                    <FilterSearchedItems isLoading={state.loading} handleSearchedItems={handleSearchedItems} />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <FilterAvailability isLoading={state.loading} handleFilterChange={handleFilterChange} />
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer />
            {state.loading ? (
                <React.Fragment>
                    <EuiLoadingContent lines={7} />
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {state.availabilityModalData?.showModal && !isEmpty(state.availabilityModalData?.modalResponse) ? (
                        <AvailabilityTimeModal
                            state={state.availabilityModalData}
                            handleAvailablityModal={handleAvailablityModal}
                        />
                    ) : null}

                    {filteredAddonGroups.map((group) => {
                        return (
                            <AddonGroupRow
                                handleAvailablityModal={handleAvailablityModal}
                                key={group.id.toString()}
                                group={group}
                                fetchAddonGroups={fetchAddonGroups}
                                searchedChoices={group?.choices}
                            />
                        );
                    })}
                </React.Fragment>
            )}
        </>
    );
};

export default React.memo(AddonAvailabilityScreen);
