import { SET_RESTAURANT_ORDERING_MODES } from '../types/RestaurantActionTypes';

const initialState = {
    orderingModes: [],
};

export default function restaurantReducer(state = initialState, action) {
    switch (action.type) {
        case SET_RESTAURANT_ORDERING_MODES:
            return {
                ...state,
                orderingModes: action.payload,
            };

        default:
            return state;
    }
}
