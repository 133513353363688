import React from 'react';
import { MainLayout } from '../../components/layout/mainLayout/mainLayout';
import { useSelector } from 'react-redux';
import { NoOutletMessage } from '../../utils/noOutletMessage';
import { EuiPanel } from '@elastic/eui';
import AvailabilityTabSelection from '../../components/ItemAvailability/AvailabilityTabSelection';

const ItemAvailabilityPage = () => {
    const selectedOutlet = useSelector((state) => state.outlet.selectedOutletId);

    return (
        <>
            <MainLayout title='Item Availability' outlet={true} backgroundColorChange={true}>
                {selectedOutlet ? (
                    <EuiPanel>
                        <AvailabilityTabSelection />
                    </EuiPanel>
                ) : (
                    <NoOutletMessage />
                )}
            </MainLayout>
        </>
    );
};

export default ItemAvailabilityPage;
