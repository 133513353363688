import axios from 'axios';
import {
    interceptorsRequest,
    interceptorsRequestError,
    interceptorsResponse,
    interceptorsResponseError,
} from './interceptors';

let APIV4 = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL_V4,
    responseType: 'json',
});

APIV4.defaults.headers.post['content-type'] = 'application/json';
APIV4.defaults.headers.get['Accept'] = 'application/json';

APIV4.interceptors.request.use(
    (request) => {
        const interceptorsReq = interceptorsRequest(request);
        return interceptorsReq;
    },
    (error) => {
        const promiseError = interceptorsRequestError(error);
        return promiseError;
    }
);

APIV4.interceptors.response.use(
    (response) => {
        // Dispatch that we finished loading.
        const interceptorsRes = interceptorsResponse(response);
        return interceptorsRes;
    },
    (error) => {
        const responseError = interceptorsResponseError(error);
        return responseError;
    }
);

export default APIV4;
