import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOutletDetails } from '../../../api/outlet/getOutletDetails';

const useGetOutletSettings = () => {
    const dispatch = useDispatch();
    const outletId = useSelector((state) => state.outlet.selectedOutletId);
    const [uiState, setUiState] = useState({
        isLoading: true,
        error: false,
    });

    const fetchOutletById = useCallback(
        async (outletId) => {
            await dispatch(getOutletDetails(outletId)).then((response) => {
                if (response.success) {
                    response = {
                        ...uiState,
                        isLoading: false,
                        error: false,
                    };
                } else {
                    response = {
                        ...uiState,
                        isLoading: false,
                        error: true,
                    };
                }
                setUiState(response);
            });
        },
        [dispatch, uiState]
    );

    useEffect(() => {
        fetchOutletById(outletId);
    }, [outletId]);

    return uiState;
};

export default useGetOutletSettings;
