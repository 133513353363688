import React from 'react';
import { EuiConfirmModal } from '@elastic/eui';
import { useDispatch, useSelector } from 'react-redux';
import API from '../../../api/axios/API';
import * as type from '../../../reduxStore/types/menu';
import { useHistory } from 'react-router-dom';

const DeleteModal = (props) => {
    const { itemDeleteId, showItemModal, setShowItemModal } = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const deleteCategoryId = useSelector((state) => state.menu.deleteCategoryId);
    const showDeleteConfirmationModal = useSelector((state) => state.menu.showDeleteConfirmationModal);
    const categoryList = useSelector((state) => state.menu.categoriesTreeStructure);
    const activeCategoryId = useSelector((state) => state.menu.activeCategoryId);
    const activeItemId = useSelector((state) => state.menu.activeItemId);

    const onConfirmDeleteCategory = async () => {
        let deleteCategory = await API.delete(`restaurants/:restaurantId/categories/${deleteCategoryId}`);

        if (deleteCategory.success) {
            history.goBack();
            dispatch({
                type: type.SET_CATEGORY_STATE,
                payload: {
                    categoryState: {},
                },
            });
            if (categoryList.length === 1) {
                dispatch({
                    type: type.SET_CATEGORY_TREE_STRUCTURE,
                    payload: {
                        categoriesTreeStructure: '',
                    },
                });
            }
            if (deleteCategoryId === activeCategoryId) {
                dispatch({
                    type: type.SET_ACTIVE_CATEGORY_ID,
                    payload: {
                        activeCategoryId: false,
                    },
                });
                dispatch({
                    type: type.SET_CATEGORY_FLAG,
                    payload: {
                        isHaveSubCategory: false,
                    },
                });

                dispatch({
                    type: type.ACTIVE_CATEGORY_ITEM_LIST,
                    payload: {
                        activeCategoryItemList: [],
                    },
                });

                dispatch({
                    type: type.SET_ACTIVE_ITEM_ID,
                    payload: {
                        activeItemId: '',
                    },
                });

                dispatch({
                    type: type.EDIT_ITEM,
                    payload: {
                        editItem: {},
                    },
                });
            }
        }

        dispatch({
            type: type.SET_DELETE_CONFIRMATION_MODAL,
            payload: {
                showDeleteConfirmationModal: false,
            },
        });
    };

    const onConfirmItemDelete = async () => {
        let deleteItem = await API.delete(
            `restaurants/:restaurantId/categories/${activeCategoryId}/items/${itemDeleteId}`
        );

        if (deleteItem.success) {
            dispatch({
                type: type.SET_CATEGORY_STATE,
                payload: {
                    categoryState: {},
                },
            });
            if (itemDeleteId === activeItemId) {
                dispatch({
                    type: type.EDIT_ITEM,
                    payload: {
                        editItem: {},
                    },
                });
            }
        }

        setShowItemModal(false);
    };

    return (
        <EuiConfirmModal
            title={<p>Are you sure you want to delete this {showDeleteConfirmationModal ? 'category?' : 'item?'}</p>}
            onCancel={() => {
                showDeleteConfirmationModal &&
                    dispatch({
                        type: type.SET_DELETE_CONFIRMATION_MODAL,
                        payload: {
                            showDeleteConfirmationModal: false,
                        },
                    });

                showItemModal && setShowItemModal(false);
            }}
            onConfirm={showDeleteConfirmationModal ? onConfirmDeleteCategory : onConfirmItemDelete}
            cancelButtonText="No, don't do it"
            confirmButtonText='Yes, do it'
            buttonColor='danger'
            defaultFocusedButton='cancel'
        >
            <p>You&rsquo;re about to delete this {showDeleteConfirmationModal ? 'category' : 'item'}</p>
            <p>Are you sure you want to do this?</p>
        </EuiConfirmModal>
    );
};

export default DeleteModal;
