import { isEmpty } from 'lodash';
import { getSelectedCategoryDetails } from '../../../../../../components/menuComponentsRefactorCopy/catalogue/Api/FetchCatalogueInfo';
import { defaultValueSet } from '../Utils/utils';
import * as types from '../../../../../../reduxStore/types/menu';

export const fetchCategoryData = (selectedCategoryId) => {
    return async (dispatch) => {
        let category = null;

        try {
            let defaultValueObject = {};
            category = await dispatch(getSelectedCategoryDetails(selectedCategoryId));
            if (!isEmpty(category)) {
                defaultValueSet(defaultValueObject, category.translations);
                defaultValueObject['id'] = category.category_id;
                defaultValueObject['category_image_url'] = category.category_image_url;
                defaultValueObject['category_menu_image_url'] = category.category_menu_image_url;
                defaultValueObject['internal_name'] = category.internal_name;
                defaultValueObject['hidden'] = category.hidden;
                defaultValueObject['hide_category_tile_details'] = category.hide_category_tile_details ? true : false;
                defaultValueObject['apply_mask'] = category.apply_mask;
                defaultValueObject['ordering_modes'] = category.ordering_modes;
                defaultValueObject['theme'] = category?.theme ? category.theme : 'square_image';
                defaultValueObject['tile_details_position'] = category?.tile_details_position
                    ? category.tile_details_position
                    : '';
                defaultValueObject['gradient_position'] = category?.gradient_position ? category.gradient_position : '';

                for (const element of category?.ordering_modes) {
                    defaultValueObject[`mode_${element.restaurant_ordering_mode_id}`] = true;
                }

                if (category.parent_id) {
                    defaultValueObject['parent_id'] = category.parent_id;
                }
                if (category.addon_groups !== undefined && category?.addon_groups?.length) {
                    dispatch({
                        type: types.SET_ADDON_SELECTED_LIST,
                        payload: {
                            selectedAddOnList: category.addon_groups,
                        },
                    });
                } else {
                    dispatch({
                        type: types.SET_ADDON_SELECTED_LIST,
                        payload: {
                            selectedAddOnList: [],
                        },
                    });
                }
            }

            dispatch({
                type: types.EDIT_CATEGORY,
                payload: {
                    editCategory: defaultValueObject,
                },
            });
        } catch (error) {
            category = error;
        }

        return category;
    };
};
