import {
    EuiButton,
    EuiComboBox,
    EuiFieldText,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFlyout,
    EuiFlyoutBody,
    EuiFlyoutHeader,
    EuiForm,
    EuiFormRow,
    EuiSpacer,
    EuiTitle,
} from '@elastic/eui';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { userHasPermission } from '../../helper/auth';
import { fetchOutletSections } from './Api/fetchOutletSections';
import { createTable, deleteTable, updateTable } from './Api/tableOperations';
import TableQrCode from './TableQrCode';

const TableCreationFlyout = ({ closeFlyout, tableState, selectedTable }) => {
    const {
        handleSubmit,
        control,
        formState: { errors },
        reset,
    } = useForm();
    const dispatch = useDispatch();
    const permission = useSelector((state) => state.permission.permission);
    const location = useLocation();
    const outletId = useSelector((state) => state.outlet.selectedOutletId);
    const languageId = useSelector((state) => state.language.languageId);
    const [sectionOptions, setSectionOptions] = useState([]);
    const sections = useSelector((state) => state.tableManagement.sectionList);

    useEffect(() => {
        if (sections?.length) {
            let modes = [];
            for (const section of sections) {
                modes.push({
                    label: section.internal_name,
                    value: section.id,
                });
            }
            setSectionOptions(modes);
        }
    }, [languageId, sections]);

    useEffect(() => {
        if (tableState === 'update') {
            const outlet_section_id = sectionOptions.filter(
                (orderingMode) => orderingMode.value == selectedTable.outlet_section_id
            );

            let existingData = {
                outlet_section_id: outlet_section_id,
                internal_name: selectedTable.internal_name,
                capacity: selectedTable.capacity,
                deeplink: selectedTable?.deeplink,
            };

            reset({ ...existingData });
        }
    }, [reset, sectionOptions, selectedTable, tableState]);

    const handleTableDeletion = useCallback(async () => {
        closeFlyout();
        await deleteTable(outletId, selectedTable.id).then((response) => {
            if (response.success) {
                dispatch(fetchOutletSections());
            }
        });
    }, [closeFlyout, dispatch, selectedTable?.id]);
    const onSubmit = useCallback(
        async (data) => {
            closeFlyout();
            let tableData = {
                outlet_section_id: data.outlet_section_id[0].value,
                internal_name: data.internal_name,
                capacity: data.capacity,
                deeplink: data.deeplink,
            };
            if (tableState === 'update') {
                await updateTable(selectedTable.id, { ...tableData, code: selectedTable.code }, outletId).then(
                    (response) => {
                        if (response.success) {
                            dispatch(fetchOutletSections());
                        }
                    }
                );
            } else {
                await createTable(outletId, tableData).then((response) => {
                    if (response.success) {
                        dispatch(fetchOutletSections());
                    }
                });
            }
        },
        [closeFlyout, dispatch, outletId, selectedTable, tableState]
    );

    return (
        <>
            <EuiFlyout onClose={closeFlyout}>
                <EuiForm component='form' onSubmit={handleSubmit(onSubmit)}>
                    <EuiFlyoutHeader hasBorder>
                        <EuiTitle>
                            <h2>{tableState === 'update' ? 'Edit' : 'Create a'} Table</h2>
                        </EuiTitle>
                    </EuiFlyoutHeader>
                    <EuiFlyoutBody>
                        <Controller
                            render={({ field }) => (
                                <EuiFormRow
                                    label={'Internal Name'}
                                    isInvalid={
                                        errors.internal_name ? errors.internal_name.message : errors['internal_name']
                                    }
                                    error={errors['internal_name'] ? errors['internal_name'].message : ''}
                                >
                                    <EuiFieldText
                                        placeholder={'Internal Name'}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                </EuiFormRow>
                            )}
                            name={'internal_name'}
                            control={control}
                            fullWidth={true}
                            rules={{ required: 'Please Enter Internal Name' }}
                        />
                        <Controller
                            render={({ field }) => (
                                <EuiFormRow
                                    label={'Sections'}
                                    isInvalid={
                                        errors.outlet_section_id
                                            ? errors.outlet_section_id.message
                                            : errors['outlet_section_id']
                                    }
                                    error={errors['outlet_section_id'] ? errors['outlet_section_id'].message : ''}
                                >
                                    <EuiComboBox
                                        placeholder='Select Section'
                                        options={sectionOptions}
                                        onChange={field.onChange}
                                        selectedOptions={field.value}
                                        singleSelection={true}
                                        isClearable={true}
                                    />
                                </EuiFormRow>
                            )}
                            rules={{ required: 'Please select a section' }}
                            name={'outlet_section_id'}
                            control={control}
                            fullWidth={true}
                        />
                        <EuiSpacer />
                        <Controller
                            render={({ field }) => (
                                <EuiFormRow
                                    label={'Table Capacity'}
                                    isInvalid={errors.capacity ? errors.capacity.message : errors['capacity']}
                                    error={errors['capacity'] ? errors['capacity'].message : ''}
                                >
                                    <EuiFieldText
                                        placeholder={'Capacity'}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                </EuiFormRow>
                            )}
                            name={'capacity'}
                            control={control}
                            fullWidth={true}
                        />
                        <Controller
                            render={({ field }) => (
                                <EuiFormRow
                                    label={'Custom URL'}
                                    isInvalid={errors.deeplink ? errors.deeplink.message : errors['deeplink']}
                                    error={errors['deeplink'] ? errors['deeplink'].message : ''}
                                >
                                    <EuiFieldText
                                        placeholder={'Deeplink URL'}
                                        value={field.value}
                                        onChange={field.onChange}
                                    />
                                </EuiFormRow>
                            )}
                            name={'deeplink'}
                            control={control}
                            fullWidth={true}
                        />
                        {tableState === 'update' ? (
                            <>
                                <EuiSpacer />
                                <EuiFlexGroup gutterSize='l' wrap alignItems='flexStart'>
                                    {selectedTable.code ? (
                                        <EuiFlexItem>
                                            <TableQrCode label={'Table QR Code'} value={selectedTable.code} />
                                        </EuiFlexItem>
                                    ) : null}
                                    {selectedTable?.deeplink ? (
                                        <EuiFlexItem>
                                            <TableQrCode label='Deeplink QR Code' value={selectedTable?.deeplink} />
                                        </EuiFlexItem>
                                    ) : null}
                                </EuiFlexGroup>
                            </>
                        ) : null}
                        <EuiSpacer />
                        <EuiFlexGroup direction='row'>
                            <EuiFlexItem>
                                <EuiButton
                                    isDisabled={!userHasPermission(permission, location.pathname, 'write')}
                                    type='submit'
                                    fill
                                >
                                    Save
                                </EuiButton>
                            </EuiFlexItem>
                            {tableState === 'update' ? (
                                <EuiFlexItem>
                                    <EuiButton
                                        color='danger'
                                        onClick={handleTableDeletion}
                                        isDisabled={!userHasPermission(permission, location.pathname, 'delete')}
                                        fill
                                    >
                                        Delete
                                    </EuiButton>
                                </EuiFlexItem>
                            ) : null}
                        </EuiFlexGroup>
                    </EuiFlyoutBody>
                </EuiForm>
            </EuiFlyout>
        </>
    );
};

export default React.memo(TableCreationFlyout);
