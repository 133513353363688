import APIV3 from '../axios/APIV3';

export const outletItemListSequenceChange = (id, items) => {
    return async () => {
        let response;

        try {
            response = await APIV3.patch(`restaurants/:restaurantId/outlets/${id}/items/change-sequence`, {
                items: items,
            });
        } catch (error) {
            response = error;
        }

        return response;
    };
};
