export const SET_LIVE_ORDERS = 'set-live-orders';
export const SET_PAST_ORDERS = 'set-past-orders';
export const SET_LIVE_ORDER_STATE = 'set-live-order-state';
export const SET_CURRENT_ORDER_STATE = 'set-current-order-state';
export const SET_ORDERS_COUNT = 'set-orders-count';
export const SET_SELECTED_ORDER = 'set-selected-order-id';
export const SET_ABANDONED_CARTS = 'set-abandoned-carts';
export const SET_CURRENT_ORDER_MODE = 'set-current-order-mode';
export const SET_TABLE_LOADER = 'set-table-loader';
export const SET_NEW_ORDERS = 'set-new-orders';
export const SET_SELECTED_ORDER_DETAILS = 'set-selected-order-details';
export const SET_ORDER_DETAILS_LOADER = 'set-order-details-loader';
export const SET_ORDER_FILTER_DATE = 'set-order-filter-date';
export const SET_ORDER_CURRENT_PAGE = 'set-order-current-page';
export const SET_FAILED_ORDERS = 'set-failed-orders';
export const HAS_RESTAURANT_ACCEPTED_ORDER = 'has-restaurant-accepted-order';
