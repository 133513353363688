import React, { useState, useEffect } from 'react';
import { MainLayout } from '../../components/layout/mainLayout/mainLayout';
import { useDispatch } from 'react-redux';
import { fetchAllergens } from './Api/fetchAllergens';
import MenuScreen from './MenuScreen';

const MenuPage = () => {
    const [showOutlet, setShowOutlet] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            await dispatch(fetchAllergens());
        })();
    }, [dispatch]);

    return (
        <MainLayout title='Menu' outlet={showOutlet} isSingleScreen={true} backgroundColorChange>
            <MenuScreen setShowOutlet={setShowOutlet} />
        </MainLayout>
    );
};
export default MenuPage;
