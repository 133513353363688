import React, { useCallback, useState, useEffect } from 'react';
import { EuiFieldSearch, EuiFlexItem, EuiButtonEmpty, EuiSuperSelect, EuiLoadingContent } from '@elastic/eui';
import API from '../../../api/axios/API';
import _ from 'lodash';
import CustomerDataGrid from './CustomerDataGrid';
import CustomerCsvDownload from './CustomerCsvDownload';
import { toastsErrorMessage } from '../../../utils/toasts';
import { useDispatch } from 'react-redux';
import { filterDirection, filterOptions } from '../Utils/utils';

const CustomerList = () => {
    const dispatch = useDispatch();
    const [activePage, setActivePage] = useState(0);
    const [rowSize, setRowSize] = useState(10);
    const [csvState, setCsvState] = useState(false);

    const [selectedFilterColumn, setSelectedFilterColumn] = useState(filterOptions[3].value);
    const [selectedFilterDirection, setSelectedFilterDirection] = useState(filterDirection[1].value);

    const onPageChange = (value) => {
        setActivePage(value);
    };

    const onRowSizeChange = (value) => {
        setRowSize(value);
    };

    const csvStateCallback = useCallback((value) => {
        setCsvState(value);
    }, []);

    const [getAllCustomer, setAllCustomer] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const fetchCustomerInfo = useCallback(
        async ({ query = '', activePage = 0, rowSize = 10, filterColumn = 'name', filterDirection = 'asc' }) => {
            let response = null;
            setIsLoading(true);

            try {
                response = await API.get(
                    `restaurants/:restaurantId/customers${query ? `?search_query=${query}&` : `?`}page=${
                        activePage + 1
                    }&per_page=${rowSize}&filter[column]=${filterColumn}&filter[direction]=${filterDirection}`
                );
                setIsLoading(false);
            } catch (error) {
                response = error;
                setIsLoading(false);
            }

            if (query) {
                setActivePage(0);
            }

            if (response.success) {
                setAllCustomer(response);
            } else {
                toastsErrorMessage('Error in customer fetch', dispatch);
            }
        },
        [dispatch]
    );

    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        fetchCustomerInfo({
            query: '',
            activePage,
            rowSize,
            filterColumn: selectedFilterColumn,
            filterDirection: selectedFilterDirection,
        });
    }, [activePage, fetchCustomerInfo, rowSize, selectedFilterColumn, selectedFilterDirection]);

    const debounce = _.debounce((value, selectedFilterColumn, selectedFilterDirection) => {
        fetchCustomerInfo({
            query: value,
            filterColumn: selectedFilterColumn,
            filterDirection: selectedFilterDirection,
        });
    }, 500);

    const onChangeSerachText = (value) => {
        setSearchText(value);
        debounce(value, selectedFilterColumn, selectedFilterDirection);
    };

    const onColumnFilterChange = (value) => {
        setSelectedFilterColumn(value);
        fetchCustomerInfo({
            query: searchText,
            value,
            filterDirection: selectedFilterDirection,
            filterColumn: value,
        });
    };

    const onDirectionFilterChange = (value) => {
        setSelectedFilterDirection(value);
        fetchCustomerInfo({
            query: searchText,
            filterColumn: selectedFilterColumn,
            value,
            filterDirection: value,
        });
    };

    if (isLoading) {
        return <EuiLoadingContent lines={10} />;
    }

    return (
        <>
            <EuiFlexItem
                style={{
                    paddingBottom: '10px',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '90%',
                    marginBottom: '10px',
                }}
            >
                <div style={{ width: '25%' }}>
                    <b style={{ fontSize: '12px' }}>Search by</b>
                    <EuiFieldSearch
                        placeholder='Search by user or number'
                        value={searchText}
                        onChange={(e) => onChangeSerachText(e.target.value)}
                        isClearable={true}
                        aria-label='search user'
                    />
                </div>
                <div style={{ width: '25%' }}>
                    <b style={{ fontSize: '12px' }}>Sort by</b>
                    <EuiSuperSelect
                        options={filterOptions}
                        valueOfSelected={selectedFilterColumn}
                        onChange={(value) => onColumnFilterChange(value)}
                        fullWidth={true}
                    />
                </div>
                <div style={{ width: '25%' }}>
                    <b style={{ fontSize: '12px' }}>Sort by</b>
                    <EuiSuperSelect
                        options={filterDirection}
                        valueOfSelected={selectedFilterDirection}
                        onChange={(value) => onDirectionFilterChange(value)}
                        fullWidth={true}
                    />
                </div>
                <EuiButtonEmpty onClick={() => setCsvState(true)}>Download CSV</EuiButtonEmpty>
                {csvState && (
                    <CustomerCsvDownload
                        csvStateCallback={csvStateCallback}
                        searchText={searchText}
                        getAllCustomer={getAllCustomer}
                    />
                )}
            </EuiFlexItem>
            <EuiFlexItem>
                <CustomerDataGrid
                    getAllCustomer={getAllCustomer}
                    activePage={activePage}
                    onPageChange={onPageChange}
                    rowSize={rowSize}
                    onRowSizeChange={onRowSizeChange}
                />
            </EuiFlexItem>
        </>
    );
};

export default CustomerList;
