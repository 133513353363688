import API from '../axios/API';

export const assignRider = (rider, orderId) => {
    return async () => {
        let response;

        try {
            response = API.post(`/restaurants/:restaurantId/${rider}/assign-order`, {
                order_id: orderId,
            });
        } catch (error) {
            response = error;
        }

        return response;
    };
};
