import React, { useState, useCallback, Fragment } from 'react';
import { EuiPopover, EuiButton, EuiSpacer, EuiCard, EuiHorizontalRule } from '@elastic/eui';
import { useSelector } from 'react-redux';
import { OutletSwitch } from './OutletSwitch';
import './style.css';
import { userHasPermission } from '../../helper/auth';
import { ReactComponent as DropDownIcon } from '../../assets/img/svg/arrow_drop_down_black_24dp.svg';
import OrdersAvailability from './OrdersAvailability';

export const OutletToggleDropDown = () => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const outletList = useSelector((state) => state.outlet.outletList);
    const languageId = useSelector((state) => state.language.languageId);
    const permissions = useSelector((state) => state.permission.permission);
    const themeMode = useSelector((state) => state.theme.theme);

    const getOutletListMessage = useCallback(() => {
        let outletMessage = '';
        let color;

        let outletStatus = outletList.filter((outlet) => {
            let data = outlet.outlet_data;
            if (data.settings.is_open) {
                return true;
            } else {
                return false;
            }
        });

        let enabledMode = [];
        let availableMode = [];
        outletList?.forEach((outlet) => {
            outlet.outlet_data.ordering_modes.forEach((mode) => {
                if (mode.is_enabled) {
                    enabledMode.push(mode);
                }
                if (mode.is_available && mode.is_enabled) {
                    availableMode.push(mode);
                }
            });
        });

        if (!outletStatus.length) {
            color = '#ef6464';
            outletMessage = 'Not Accepting Orders';
        } else if (outletStatus.length !== outletList.length) {
            color = '#f5a140';
            outletMessage = 'Accepting Some Orders';
        } else {
            if (enabledMode.length === availableMode.length) {
                color = '#7de2b5';
                outletMessage = 'Accepting All Orders';
            } else if (enabledMode.length !== availableMode.length) {
                color = '#f5a140';
                outletMessage = 'Accepting Some Orders';
            } else {
                color = '#ef6464';
                outletMessage = 'Not Accepting Orders';
            }
        }

        return (
            <div
                style={{
                    padding: '5px 0',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <div
                    style={{
                        background: color,
                        clipPath: 'circle(40% at 50% 50%)',
                        height: '14px',
                        width: '14px',
                    }}
                    size='s'
                />
                <div
                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', overflow: 'hidden' }}
                    onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                >
                    <EuiButton
                        size='s'
                        style={{
                            border: 'none',
                            boxShadow: 'none',
                            background: 'none',
                            textDecoration: 'none',
                            overflow: 'hidden',
                            transform: 'translateY(0)',
                        }}
                    >
                        {outletMessage}
                    </EuiButton>
                    <DropDownIcon
                        fill={themeMode === 'dark' ? '#fff' : undefined}
                        style={{
                            transition: 'all',
                            transitionDuration: '300ms',
                            transform: isPopoverOpen ? 'rotate(180deg)' : 'rotate(0)',
                        }}
                    />
                </div>
            </div>
        );
    }, [isPopoverOpen, outletList, themeMode]);

    return (
        <>
            {userHasPermission(permissions, '/outlet-mode', 'read') ? (
                <EuiPopover
                    panelPaddingSize='none'
                    style={{ maxHeight: '90%', overflow: 'hidden' }}
                    button={getOutletListMessage()}
                    isOpen={isPopoverOpen}
                    closePopover={() => setIsPopoverOpen(!isPopoverOpen)}
                    anchorPosition='downCenter'
                    panelClassName='scrollbar'
                >
                    {outletList.map((outlet, index) => {
                        const outeltData = outlet.outlet_data;
                        return (
                            <React.Fragment key={index}>
                                <EuiCard
                                    style={{ margin: '8px 16px' }}
                                    titleSize='xs'
                                    layout='horizontal'
                                    title={outeltData.translations.name[languageId]}
                                    description={
                                        <>
                                            <OutletSwitch outlet={outeltData} />

                                            {outeltData.ordering_modes.map((orderingMode) => {
                                                return (
                                                    <React.Fragment
                                                        key={
                                                            orderingMode.restaurant_ordering_mode_id.toString() +
                                                            index.toString()
                                                        }
                                                    >
                                                        {orderingMode.is_enabled ? (
                                                            <OrdersAvailability
                                                                outeltData={outeltData}
                                                                orderingMode={orderingMode}
                                                            />
                                                        ) : null}
                                                    </React.Fragment>
                                                );
                                            })}
                                        </>
                                    }
                                />
                                {index < outletList.length - 1 ? (
                                    <>
                                        <EuiSpacer size='xs' />
                                        <EuiHorizontalRule margin='none' />
                                        <EuiSpacer size='xs' />
                                    </>
                                ) : null}
                            </React.Fragment>
                        );
                    })}
                </EuiPopover>
            ) : null}
        </>
    );
};
