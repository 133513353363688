import React from 'react';
import { MainLayout } from '../../../components/layout/mainLayout/mainLayout';
import BannerCreateEditForm from './BannerCreateEditForm';

const BannerCreateEditPage = () => {
    return (
        <>
            <MainLayout title='Banner' historyBack={true}>
                <BannerCreateEditForm />
            </MainLayout>
        </>
    );
};

export default BannerCreateEditPage;
