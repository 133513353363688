import { useSelector } from 'react-redux';

const useSingleScreenHeightCalc = (maxSubtractionHeight = 250) => {
    const creditAlerts = useSelector((state) => state.creditAlerts.alerts);
    const screenHeight =
        creditAlerts.low_credit_outlets_message && creditAlerts.low_credit_restaurants_message
            ? `calc(100vh - ${maxSubtractionHeight}px)`
            : creditAlerts.low_credit_outlets_message
            ? `calc(100vh - ${maxSubtractionHeight - 50}px)`
            : creditAlerts.low_credit_restaurants_message
            ? `calc(100vh - ${maxSubtractionHeight - 50}px)`
            : `calc(100vh - ${maxSubtractionHeight - 100}px)`;

    return { screenHeight };
};

export default useSingleScreenHeightCalc;
