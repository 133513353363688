import { EuiPanel, EuiSideNav } from '@elastic/eui';
import React, { useState } from 'react';

const MediaLibrarySideNavigation = ({ sideNav }) => {
    const [isSideNavOpenOnMobile, setisSideNavOpenOnMobile] = useState(false);

    const toggleOpenOnMobile = () => {
        setisSideNavOpenOnMobile(!isSideNavOpenOnMobile);
    };

    return (
        <EuiPanel>
            <EuiSideNav
                aria-label='Media Types'
                mobileTitle='Navigate'
                toggleOpenOnMobile={toggleOpenOnMobile}
                isOpenOnMobile={isSideNavOpenOnMobile}
                style={{
                    padding: '8px',
                }}
                items={sideNav}
            />
        </EuiPanel>
    );
};

export default MediaLibrarySideNavigation;
