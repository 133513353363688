import React, { memo, useState } from 'react';
import { VariableSizeList as List } from 'react-window';
import { EuiCheckableCard, EuiFlexGroup, EuiFlexItem, EuiText, EuiButtonIcon, EuiSpacer, EuiCard } from '@elastic/eui';
import { userHasPermission } from '../../../helper/auth';
import { useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';

const AddOnGroupList = (props) => {
    const { addOnList, setCurrentPage, lastPage, isLoading, currentPage, height, setHeight } = props;
    const selectedLanguage = useSelector((state) => state.language.languageId);
    const permissions = useSelector((state) => state.permission.permission);
    const itemCount = addOnList?.length || 0;

    const getItemSize = (index) => {
        return 100;
    };
    const onScrollItem = ({ scrollOffset }) => {
        if (lastPage <= 1) {
            return;
        }
        if (lastPage == currentPage) {
            return;
        }
        if (scrollOffset > height) {
            setHeight(height + 400);
            setCurrentPage((previousPage) => previousPage + 1);
        }
    };

    const Loader = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <ClipLoader color='#2196F3' cssOverride={{ marginRight: 10 }} loading size={20} speedMultiplier={3} />
            </div>
        );
    };
    const Row = React.memo(({ index, style }) => {
        const restaurant_addon_group = addOnList[index];

        return (
            <div style={style}>
                <EuiCheckableCard
                    id={restaurant_addon_group.addon_group_id.toString()}
                    label={
                        <EuiFlexGroup responsive={false} justifyContent='spaceBetween'>
                            <EuiFlexItem grow={false}>
                                <EuiText>
                                    <p>
                                        {restaurant_addon_group.translations.group_name[`${selectedLanguage}`]} |{' '}
                                        {restaurant_addon_group.internal_name}{' '}
                                    </p>
                                </EuiText>
                            </EuiFlexItem>
                            <EuiFlexItem grow={false}>
                                <EuiFlexGroup responsive={false} gutterSize='none'>
                                    <EuiFlexItem grow={2}>
                                        {userHasPermission(permissions, '/menu', 'write') && (
                                            <EuiButtonIcon
                                                color='primary'
                                                onClick={() => {
                                                    props.toggleFlyout(restaurant_addon_group);
                                                }}
                                                iconType='link'
                                                aria-label='Next'
                                            />
                                        )}
                                    </EuiFlexItem>
                                    <EuiFlexItem grow={false} className='mr-left-10'>
                                        {userHasPermission(permissions, '/menu', 'write') && (
                                            <EuiButtonIcon
                                                color='primary'
                                                onClick={() => props.editGroup(restaurant_addon_group.addon_group_id)}
                                                iconType='pencil'
                                                aria-label='Next'
                                            />
                                        )}
                                    </EuiFlexItem>
                                    <EuiFlexItem grow={false} className='mr-left-10'>
                                        {userHasPermission(permissions, '/menu', 'delete') && (
                                            <EuiButtonIcon
                                                color='danger'
                                                onClick={() => props.deleteGroup(restaurant_addon_group.addon_group_id)}
                                                iconType='trash'
                                                aria-label='Next'
                                            />
                                        )}
                                    </EuiFlexItem>
                                </EuiFlexGroup>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    }
                    name='add-on-groups'
                    checked={restaurant_addon_group.addon_group_id === props.selectedGroup}
                    onChange={() => props.onChangeSelectedGroup(restaurant_addon_group.addon_group_id)}
                />
                <EuiSpacer size={'s'} />
            </div>
        );
    });

    return itemCount ? (
        <>
            <List
                height={500} // Set your desired height
                itemCount={itemCount}
                itemSize={getItemSize}
                width={'100%'}
                onScroll={onScrollItem}
            >
                {Row}
            </List>
            {isLoading && <Loader />}
        </>
    ) : (
        <EuiCard title='Addon Groups Not Found.' />
    );
};
export default memo(AddOnGroupList);
