import { EuiLink, EuiText } from '@elastic/eui';
import React, { useState } from 'react';
import BannerImagesModal from '../../../../components/banner/BannerCatalogue/BannerImagesModal';

const ShowImages = ({ banner }) => {
    const [showBannerImagesModal, setShowBannerImagesModal] = useState(false);
    const handleBannerModal = (banner) => {
        setShowBannerImagesModal((prevState) => !prevState);
    };

    return (
        <>
            <BannerImagesModal
                bannerImagesModal={{
                    images: banner,
                    display: showBannerImagesModal,
                }}
                handleBannerModal={handleBannerModal}
            />

            <EuiLink size='s' onClick={() => handleBannerModal(banner)}>
                <EuiText size='s'>Show Images</EuiText>
            </EuiLink>
        </>
    );
};

export default ShowImages;
