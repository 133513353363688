import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
    EuiButton,
    EuiFlexGroup,
    EuiFlexItem,
    EuiModal,
    EuiModalBody,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiSpacer,
    EuiTitle,
} from '@elastic/eui';
import { isEmpty } from 'lodash';
import { userHasPermission } from '../../../helper/auth';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { baseApiUrl } from '../../../envConfig';
import { useDropzone } from 'react-dropzone';
import Resizer from 'react-image-file-resizer';
import UploadImageErrorMsg from './UploadImageErrorMsg';

const UploadImageDropZone = ({
                                 handleUploadImageModalToggle,
                                 imageType = 'categories',
                                 fetchImages,
                                 aspectRatio,
                                 isPreviewOn,
                             }) => {
    const onDrop = useCallback(
        (acceptedFiles) => {
            const aspectRatioStr = aspectRatio.toString();
            const aspectRatioFristTwoNumber = parseInt(aspectRatioStr.slice(0, 2));
            const file = acceptedFiles[0];
            if (!file) return;

            const handleFile = (file) => {
                if (file.type === 'image/gif') {
                    const img = new Image();
                    img.src = URL.createObjectURL(file);
                    img.onload = () => {
                        const fileAspectRatio = img.width / img.height;
                        if (fileAspectRatio === aspectRatio) {
                            setImage(URL.createObjectURL(file));
                            setImageValue({ file, filename: file.name, fileType: file.type });
                        } else {
                            window.alert(`The GIF aspect ratio must be ${aspectRatioFristTwoNumber}`);
                        }
                    };
                } else {
                    const reader = new FileReader();
                    reader.onload = () => {
                        console.log(reader.result);
                        setImage(reader.result);
                        setImageValue({ file, filename: file.name, fileType: file.type });
                    };
                    reader.readAsDataURL(file);
                }
            };

            handleFile(file);
        },
        [aspectRatio]
    );

    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
        onDrop: onDrop,
        maxFiles: 1,
    });

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    const [image, setImage] = useState(null);
    const restaurantID = useSelector((state) => state.auth.selectedRestaurantId);
    const [cropper, setCropper] = useState();
    const [imageValue, setImageValue] = useState();
    const [buttonDisable, setButtonDisable] = useState(false);
    const permissions = useSelector((state) => state.permission.permission);
    const history = useHistory();
    const token = localStorage.getItem('authToken');
    const [errorMessage, setErrorMessage] = useState('');
    const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);
    let cropperRef = useRef(null);

    const onChange = useCallback(
        (e) => {
            e.preventDefault();
            const file = e.target.files[0];
            if (!file) return;

            const errorCheckWidth = 400;
            const errorCheckHeight = errorCheckWidth / aspectRatio;

            const handleFile = (file) => {
                if (file.type === 'image/gif') {
                    const img = new Image();
                    img.src = URL.createObjectURL(file);
                    img.onload = () => {
                        const fileAspectRatio = img?.width / img?.height;
                        const expectedAspectRatio = aspectRatio;

                        if (Math.abs(fileAspectRatio - expectedAspectRatio) < 0.01) {
                            setImage(URL.createObjectURL(file));
                            setImageValue({ file, filename: file.name, fileType: file.type });
                        } else {
                            setErrorMessage(`The GIF size must be approximate : {widht : ${errorCheckWidth} / height : ${errorCheckHeight}}
                                            And your GIF size is : {width : ${img?.width} / height : ${img?.height}}`);
                            setIsErrorModalVisible(true);
                        }
                    };
                } else {
                    const reader = new FileReader();
                    reader.onload = () => {
                        setImage(reader.result);
                        setImageValue({ file, filename: file.name, fileType: file.type });
                    };
                    reader.readAsDataURL(file);
                }
            };

            handleFile(file);
        },
        [aspectRatio]
    );

    const handleDeletion = useCallback(() => {
        setImageValue(null);
        setImage(null);
        setCropper(null);
    }, []);

    const dataURLtoFile = useCallback((dataurl, filename) => {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }, []);

    const resizeFile = (data) =>
        new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
                const maxWidth = 2048;
                const maxHeight = 1024;

                if (img.width > maxWidth || img.height > maxHeight) {
                    Resizer.imageFileResizer(
                        data,
                        maxWidth,
                        maxHeight,
                        'PNG',
                        100,
                        0,
                        (uri) => {
                            resolve(dataURLtoFile(uri, imageValue?.filename));
                        },
                        'base64',
                        (err) => {
                            reject(err);
                        }
                    );
                } else {
                    resolve(data);
                }
            };
            img.onerror = reject;
            img.src = URL.createObjectURL(data);
        });

    const submitFormData = useCallback(
        (formData) => {
            fetch(`${baseApiUrl}/restaurants/${restaurantID}/uploads`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            })
                .then((response) => {
                    setButtonDisable(false);
                    handleUploadImageModalToggle();
                    fetchImages(imageType);
                })
                .catch((error) => {
                    setButtonDisable(false);
                    window.alert(error);
                });
        },
        [handleUploadImageModalToggle, fetchImages, imageType, restaurantID, token]
    );

    const handleUploadAction = useCallback(async () => {
        setButtonDisable(true);
        let formData = new FormData();

        if (imageValue?.fileType === 'image/gif') {
            formData.append('image', imageValue.file);
            formData.append('image_type', imageType);
            formData.append('original_file_name', imageValue?.filename);
            submitFormData(formData);
        } else {
            try {
                const data = cropper.getCroppedCanvas().toDataURL();
                console.log('Cropped image data URL:', data);

                const resizedFile = await resizeFile(dataURLtoFile(data, imageValue?.filename));
                console.log('Resized file:', resizedFile);

                formData.append('image', resizedFile);
                formData.append('image_type', imageType);
                formData.append('original_file_name', imageValue?.filename);

                submitFormData(formData);
            } catch (error) {
                console.error('Error cropping or resizing image:', error);
                setButtonDisable(false);
                setErrorMessage('Error cropping or resizing image.');
                setIsErrorModalVisible(true);
            }
        }
    }, [cropper, imageValue, imageType, resizeFile, dataURLtoFile, submitFormData]);

    return (
        <>
            <EuiModal onClose={handleUploadImageModalToggle}>
                <EuiModalHeader>
                    <EuiModalHeaderTitle>
                        <h1>Upload Image</h1>
                    </EuiModalHeaderTitle>
                </EuiModalHeader>
                <EuiModalBody style={{ minWidth: '780px', overflowY: 'scroll' }} className='scrollbar'>
                    <div>
                        <div style={{ width: '100%' }}>
                            {isEmpty(imageValue) ? (
                                <section style={{ maxWidth: '97%' }}>
                                    <div {...getRootProps({ style })}>
                                        <input
                                            {...getInputProps()}
                                            onChange={onChange}
                                            accept='image/gif , image/png , image/jpeg , image/jpg'
                                        />
                                        <p>Drag 'n' drop some files here, or click to select files</p>
                                        <p>Accepted file types: .jpeg, .jpg, .png , .gif Max file size: 5MB</p>
                                    </div>
                                </section>
                            ) : null}
                            <EuiSpacer />
                            {!isEmpty(imageValue) && imageValue?.fileType !== 'image/gif' ? (
                                <Cropper
                                    style={{ maxHeight: 400, width: '100%' }}
                                    // zoomTo={0.5}
                                    initialAspectRatio={aspectRatio}
                                    aspectRatio={aspectRatio}
                                    preview='.img-preview'
                                    src={image}
                                    viewMode={1}
                                    minCropBoxHeight={10}
                                    minCropBoxWidth={10}
                                    background={false}
                                    responsive={true}
                                    autoCropArea={1}
                                    checkOrientation={false}
                                    onInitialized={(instance) => {
                                        setCropper(instance);
                                    }}
                                    maxLength={5242880}
                                    guides={true}
                                    ref={cropperRef}
                                />
                            ) : (
                                imageValue?.fileType === 'image/gif' &&
                                image && <img src={image} alt='Preview' style={{ maxHeight: '400px', width: '100%' }} />
                            )}
                        </div>
                        {!isEmpty(imageValue) && isPreviewOn && imageValue?.fileType !== 'image/gif' ? (
                            <>
                                <EuiFlexGroup gutterSize='none' alignItems='center' justifyContent='center'>
                                    <EuiFlexItem>
                                        <div className='box' style={{ width: '50%', float: 'right' }}>
                                            <EuiTitle size='s'>
                                                <h5>Preview</h5>
                                            </EuiTitle>
                                            <div
                                                className='img-preview'
                                                style={{ width: '100%', float: 'left', height: '300px' }}
                                            />
                                        </div>
                                    </EuiFlexItem>
                                </EuiFlexGroup>
                            </>
                        ) : null}
                    </div>
                    {!isEmpty(imageValue) ? (
                        <>
                            <EuiFlexGroup style={{ margin: 10, width: '100%' }} alignItems='center'>
                                <EuiFlexItem
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-around',
                                    }}
                                >
                                    <>
                                        <EuiFlexItem grow={false} style={{ marginRight: '10px' }}>
                                            <EuiButton
                                                isLoading={
                                                    buttonDisable ||
                                                    !userHasPermission(permissions, history.location.pathname, 'write')
                                                }
                                                fill
                                                onClick={handleUploadAction}
                                            >
                                                Upload
                                            </EuiButton>
                                        </EuiFlexItem>
                                        <EuiFlexItem grow={false}>
                                            <EuiButton
                                                fill
                                                color='danger'
                                                iconType='trash'
                                                disabled={buttonDisable}
                                                onClick={handleDeletion}
                                            >
                                                Remove
                                            </EuiButton>
                                        </EuiFlexItem>
                                    </>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </>
                    ) : null}
                </EuiModalBody>
            </EuiModal>
            {isErrorModalVisible && (
                <UploadImageErrorMsg message={errorMessage} onClose={() => setIsErrorModalVisible(false)} />
            )}
        </>
    );
};

export default React.memo(UploadImageDropZone);

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
};

const focusedStyle = {
    borderColor: '#2196f3',
};

const acceptStyle = {
    borderColor: '#00e676',
};

const rejectStyle = {
    borderColor: '#ff1744',
};
