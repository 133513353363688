import firebase from 'firebase';
import { firebaseConfig } from '../envConfig';

const config = {
    ...firebaseConfig,
};

firebase.initializeApp(config);

export default firebase;
