import { EuiBottomBar, EuiButtonIcon, EuiFlexItem, EuiPortal, EuiText } from '@elastic/eui';
import React from 'react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { TOGGLE_UNSAVED_PORTAL } from '../../reduxStore/types/Global/Global';
import Expire from '../Form/Expire';

const TabSwitchErrorPortal = () => {
    const dispatch = useDispatch();
    const showUnsavedWarningPortal = useSelector(
        (state) => state.uiPortalStateReducer.portalState.showUnsavedDataWaringPortal
    );

    const onClosePortal = useCallback(() => {
        dispatch({
            type: TOGGLE_UNSAVED_PORTAL,
            payload: false,
        });
    }, [dispatch]);

    return (
        <Expire delay='3000'>
            <EuiPortal>
                <EuiBottomBar
                    style={{
                        backgroundColor: showUnsavedWarningPortal ? 'red' : '',
                        padding: '20px',
                        zIndex: 10000,
                    }}
                >
                    <EuiFlexItem style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <EuiFlexItem>
                            <EuiButtonIcon onClick={onClosePortal} color='#ffffff' iconType='crossInACircleFilled' />
                        </EuiFlexItem>
                        <EuiFlexItem style={{ marginLeft: '10px' }}>
                            <EuiText>There are some unsaved changes, please save them.</EuiText>
                        </EuiFlexItem>
                    </EuiFlexItem>
                </EuiBottomBar>
            </EuiPortal>
        </Expire>
    );
};

export default React.memo(TabSwitchErrorPortal);
