import React, { useEffect, useState } from 'react';
import {
    EuiCard,
    EuiComboBox,
    EuiFieldNumber,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow,
    EuiSelect,
    EuiSpacer,
    EuiSuperSelect,
    EuiText,
    EuiTitle,
} from '@elastic/eui';
import { Controller } from 'react-hook-form';
import TextField from '../../Form/TextField';
import { useSelector } from 'react-redux';

const OrderingModePrices = ({
    index,
    orderingMode,
    errors,
    control,
    priceRef,
    comparePriceRef,
    packagingPriceRef,
    gstOptions,
    setValue,
}) => {
    const editItem = useSelector((state) => state.menuReducer.activeItemDetails.details);

    const isOutletMenu = useSelector((state) => state.menuReducer.isOutletMenu);
    const quantityUnits = useSelector((state) => state.menuReducer.quantityUnits);

    const priceInputName = `ordering_modes[${index}].price`;
    const comparePriceInputName = `ordering_modes[${index}].compare_price`;
    const packagingPriceInputName = `ordering_modes[${index}].packaging_charge`;

    const gstInputName = `ordering_modes[${index}].gst_slab`;
    const quantity = `ordering_modes[${index}].quantity`;
    const quantity_unit_id = `ordering_modes[${index}].quantity_unit_id`;
    const serves = `ordering_modes[${index}].serves`;
    const serves_unit = `ordering_modes[${index}].serves_unit`;

    const caloriesInputName = `ordering_modes[${index}].calories`;
    const allergensInputName = `ordering_modes[${index}].allergens`;

    const allergensOptions = useSelector((state) => state.menuReducer.allergens)?.filter(
        (allergen) => (allergen.label = allergen.internal_name)
    );

    const [quantityIdOptions, setQuantityIdOptions] = useState([]);

    const handleAllergensDeletion = (options) => {
        if (editItem?.ordering_modes?.[index]?.allergens?.length) {
            const removedAllergens = editItem.ordering_modes?.[index]?.allergens.filter(
                (allergen) => !options.some((option) => option.id === allergen.id)
            );
            setValue(`ordering_modes[${index}].delete_allergens`, removedAllergens);
        } else {
            setValue(`ordering_modes[${index}].delete_allergens`, []);
        }
    };

    useEffect(() => {
        if (quantityUnits?.length) {
            let units = [];
            for (const element of quantityUnits) {
                units.push({
                    value: element.id,
                    inputDisplay: element.unit,
                });
            }
            setQuantityIdOptions(units);
        }
    }, [quantityUnits]);

    return (
        <>
            <EuiSpacer />
            <EuiCard
                hasBorder
                title={
                    <EuiTitle style={{ textAlign: 'left' }} size='xs'>
                        <h5>{orderingMode.restaurant_ordering_mode_name}</h5>
                    </EuiTitle>
                }
                description={
                    <>
                        <EuiSpacer size='s' />
                        <EuiFlexGroup
                            gutterSize='s'
                            direction='row'
                            justifyContent='flexStart'
                            alignItems='flexStart'
                            wrap
                            responsive
                        >
                            <EuiFlexItem
                                style={{
                                    minWidth: 120,
                                }}
                            >
                                <EuiFormRow
                                    label={`Price`}
                                    error={errors[priceInputName] ? errors[priceInputName].message : ''}
                                    isInvalid={errors[priceInputName]}
                                >
                                    <Controller
                                        render={({ field }) => {
                                            return (
                                                <EuiFieldNumber
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    placeholder='Item price'
                                                />
                                            );
                                        }}
                                        name={priceInputName}
                                        min={0}
                                        step={'any'}
                                        control={control}
                                        inputRef={priceRef}
                                        rules={{
                                            required: 'Please enter item price',
                                        }}
                                        defaultValue={orderingMode[priceInputName]}
                                        isInvalid={errors[priceInputName]}
                                        disabled={isOutletMenu}
                                    />
                                </EuiFormRow>
                                <EuiText
                                    size='xs'
                                    style={{
                                        color: '#b8b9bd',
                                    }}
                                >
                                    <span>Excluding All taxes</span>
                                </EuiText>
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <EuiFormRow
                                    style={{
                                        minWidth: 120,
                                    }}
                                    label={`Compare Price`}
                                >
                                    <Controller
                                        render={({ field }) => (
                                            <EuiFieldNumber
                                                value={field.value}
                                                onChange={field.onChange}
                                                placeholder='Item compare price'
                                            />
                                        )}
                                        name={comparePriceInputName}
                                        min={0.0}
                                        step={'any'}
                                        control={control}
                                        defaultValue={orderingMode[comparePriceInputName]}
                                        inputRef={comparePriceRef}
                                        disabled={isOutletMenu}
                                    />
                                </EuiFormRow>
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <EuiFormRow
                                    style={{
                                        minWidth: 120,
                                    }}
                                    label={`Packaging Charge`}
                                >
                                    <Controller
                                        render={({ field }) => (
                                            <EuiFieldNumber
                                                value={field.value}
                                                onChange={field.onChange}
                                                placeholder='Item packaging'
                                            />
                                        )}
                                        name={packagingPriceInputName}
                                        min={0.0}
                                        step={'any'}
                                        control={control}
                                        inputRef={packagingPriceRef}
                                        defaultValue={
                                            orderingMode[packagingPriceInputName]
                                                ? orderingMode[packagingPriceInputName]
                                                : 0
                                        }
                                        disabled={isOutletMenu}
                                    />
                                </EuiFormRow>
                                <EuiText
                                    size='xs'
                                    style={{
                                        color: '#b8b9bd',
                                    }}
                                >
                                    <span>Including All taxes</span>
                                </EuiText>
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <EuiFormRow
                                    label={`GST `}
                                    style={{
                                        minWidth: 120,
                                    }}
                                >
                                    <Controller
                                        render={({ field }) => {
                                            return (
                                                <EuiSelect
                                                    options={gstOptions}
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    disabled={isOutletMenu}
                                                />
                                            );
                                        }}
                                        defaultValue={Number(orderingMode[gstInputName])}
                                        name={gstInputName}
                                        control={control}
                                    />
                                </EuiFormRow>
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <TextField
                                    label={`Quantity `}
                                    name={quantity}
                                    control={control}
                                    defaultValue={orderingMode[quantity]}
                                    disabled={isOutletMenu}
                                    errors={errors}
                                    placeholder='Item Quantity'
                                    style={{
                                        minWidth: 120,
                                    }}
                                />
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <EuiFormRow
                                    label={`Quantity Unit Id `}
                                    style={{
                                        minWidth: 120,
                                    }}
                                >
                                    <Controller
                                        render={({ field }) => {
                                            return (
                                                <EuiSuperSelect
                                                    options={quantityIdOptions}
                                                    valueOfSelected={field.value}
                                                    onChange={field.onChange}
                                                />
                                            );
                                        }}
                                        name={quantity_unit_id}
                                        min={0}
                                        step={'any'}
                                        control={control}
                                        defaultValue={orderingMode[quantity_unit_id]}
                                        disabled={isOutletMenu}
                                    />
                                </EuiFormRow>
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <TextField
                                    label={`Serves `}
                                    name={serves}
                                    control={control}
                                    defaultValue={orderingMode[serves]}
                                    disabled={isOutletMenu}
                                    errors={errors}
                                    placeholder='Item Serves'
                                    style={{
                                        minWidth: 120,
                                    }}
                                />
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <TextField
                                    label={`Serves Unit `}
                                    name={serves_unit}
                                    control={control}
                                    defaultValue={orderingMode[serves_unit]}
                                    disabled={isOutletMenu}
                                    errors={errors}
                                    placeholder='Item Serves Unit'
                                    style={{
                                        minWidth: 120,
                                    }}
                                />
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <EuiFormRow
                                    style={{
                                        minWidth: 120,
                                    }}
                                    label={`Calories`}
                                >
                                    <Controller
                                        render={({ field }) => (
                                            <EuiFieldNumber
                                                value={field.value}
                                                onChange={field.onChange}
                                                placeholder='Eg. 450 Kcal'
                                                append={'Kcal'}
                                            />
                                        )}
                                        name={caloriesInputName}
                                        step={'any'}
                                        control={control}
                                        defaultValue={orderingMode[caloriesInputName]}
                                        disabled={isOutletMenu}
                                    />
                                </EuiFormRow>
                            </EuiFlexItem>
                            <EuiFlexItem
                                style={{
                                    minWidth: 220,
                                }}
                            >
                                <Controller
                                    render={({ field }) => {
                                        return (
                                            <EuiFormRow label={`Allergens `}>
                                                <EuiComboBox
                                                    options={allergensOptions}
                                                    selectedOptions={field?.value}
                                                    onChange={(options) => {
                                                        handleAllergensDeletion(options);
                                                        field.onChange(options);
                                                    }}
                                                    placeholder='Select allergens'
                                                    isDisabled={isOutletMenu}
                                                />
                                            </EuiFormRow>
                                        );
                                    }}
                                    name={allergensInputName}
                                    control={control}
                                />
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </>
                }
            />
        </>
    );
};

export default React.memo(OrderingModePrices);
