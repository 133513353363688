export const SET_RESTAURANT_CATALOGUE = 'set_restaurant_catalogue';
export const SET_ADDONS = 'set_addons';
export const SET_ACTIVE_ITEM_ID = 'set_active_item_id';
export const SET_ACTIVE_ADDON_GROUP_ID = 'set_active_addon_group_id';
export const SET_ACTIVE_ITEM_DETAILS = 'set_active_item_details';
export const SET_CATEGORY_ITEM_ACTIONS = 'set_category_item_actions';
export const SET_ACTIVE_CATEGORY_ID = 'set_active_category_id';
export const ADD_SELECTED_ITEM_ADDON_GROUP = 'add_selected_item_addon_group';
export const SET_RESTAURANT_BADGES = 'set_restaurant_badges';
export const SET_ORDERING_MODES = 'set_ordering_modes';
export const SET_BADGES_IDS = 'set_badges_ids';
export const SET_SAVED_VARIANT_GROUP = 'set_saved_variant_group';
export const SET_QUANTITY_UNITS = 'set_quantity_units';
export const UPDATE_ITEM_LIST = 'update_item_list';
export const UNLINK_BADGES_IDS = 'unlink_badges_ids';
export const SET_OUTLET_MENU = 'set_outlet_menu';
export const SET_CATALOGUE_ITEMS = 'set_catalogue_items';
export const SET_CATALOGUE_ALLERGENS = 'set_catalogue_allergens';
export const SET_CATALOGUE_ITEM_LIST = 'set_catalogue_item_list';
export const REMOVE_CATALOGUE_ITEM_LIST = 'remove_catalogue_item_list';
export const SET_CATALOGUE_LINK_LOADER = 'set_catalogue_link_loader';
export const SET_SELECTED_ADDON_GROUP_CHOICES = 'set_selected_addon_group_choices';
export const SET_SELECTED_ADDON_GROUP_ID = 'set_selected_addon_group_id';
