import {
    EuiText,
    EuiIcon,
    EuiCard,
    EuiFlexItem,
    EuiSpacer,
    EuiButton,
    EuiFlexGroup,
    EuiBasicTable,
    EuiLoadingContent,
} from '@elastic/eui';
import React, { useEffect, useState } from 'react';
import { userHasPermission } from '../../helper/auth';
import { useSelector, useDispatch } from 'react-redux';
import { getPastOrders } from '../../api/pastOrder/getPastOrders';
import { refundAmount } from '../../api/pastOrder/refundAmount';
import { isEmpty } from 'lodash';
import API from '../../api/axios/API';

const RefundStatusCard = (props) => {
    const { selectedOrder } = props;
    const [refundLoader, setRefundLoader] = useState(false);
    const permissions = useSelector((state) => state.permission.permission);
    const currentOrderingMode = useSelector((state) => state.orders.currentOrderingMode);
    const dispatch = useDispatch();

    const [refundDetails, setRefundDetails] = useState([]);

    const refundAmountAPI = () => {
        setRefundLoader(true);
        dispatch(refundAmount(selectedOrder.order_id)).then((response) => {
            if (response.success) {
                dispatch(getPastOrders(props.startDateTime, props.endDateTime, props.state, currentOrderingMode));
                setRefundLoader(false);
                return;
            }
            setRefundLoader(false);
            alert(response.message);
        });
    };

    const handlerefundDetails = async () => {
        try {
            const response = await API.get(
                `${process.env.REACT_APP_BASE_URL}/restaurants/:restaurantId/orders/${selectedOrder?.order_id}/refund-status?refund_id=${selectedOrder?.refunds[0]?.reference_id}`
            );
            if (response.success) {
                response.refund_response && setRefundDetails(response.refund_response.body);
            }
        } catch (error) {
            setRefundDetails([]);
            console.log('error <====>', error);
        }
    };

    useEffect(() => {
        handlerefundDetails();
    }, [selectedOrder]);

    const ccavenueRefundDetails = selectedOrder?.refunds?.[0];
    const refundCard = () => {
        const refunds = [];

        selectedOrder.refunds.forEach((refund) => {
            const customer = !isEmpty(refund?.paytm_transaction)
                ? refund.paytm_transaction?.customer
                : !isEmpty(refund?.stripe_transaction)
                ? refund.stripe_transaction?.customer
                : !isEmpty(refund?.ccavenue_transaction)
                ? refund?.ccavenue_transaction?.customer
                : null;

            if (refund.refunded_at) {
                refunds.push(
                    <>
                        <EuiCard
                            display='plain'
                            layout='horizontal'
                            paddingSize='s'
                            style={{ backgroundColor: '#74decef2' }}
                            title={
                                <EuiFlexItem style={{ alignItems: 'center', flexDirection: 'row' }}>
                                    <EuiIcon color='#347e73' type='alert' />
                                    <div style={{ marginLeft: '10px', color: '#347e73' }}>
                                        <span>Refund Succeeded</span>
                                    </div>
                                </EuiFlexItem>
                            }
                            description={
                                <>
                                    <EuiFlexGroup direction='column'>
                                        <EuiFlexItem>
                                            <EuiText size='s'>Refund process completed successfully.</EuiText>
                                        </EuiFlexItem>
                                        {customer ? (
                                            <>
                                                <EuiFlexItem>
                                                    <EuiText size='s'>Customer Name: {customer.name}</EuiText>
                                                </EuiFlexItem>
                                                <EuiFlexItem>
                                                    <EuiText size='s'>
                                                        Customer Mobile Number: {customer.mobile_number}
                                                    </EuiText>
                                                </EuiFlexItem>
                                                {refund?.ccavenue_transaction && (
                                                    <>
                                                        <EuiFlexItem>
                                                            <EuiText size='s'>
                                                                <p>
                                                                    Bank Ref. No. :{' '}
                                                                    {
                                                                        ccavenueRefundDetails.ccavenue_transaction
                                                                            .bank_ref_no
                                                                    }
                                                                </p>
                                                            </EuiText>
                                                        </EuiFlexItem>
                                                        <EuiFlexItem>
                                                            <EuiText size='s'>
                                                                <p>Refunded at : {ccavenueRefundDetails.refunded_at}</p>
                                                            </EuiText>
                                                        </EuiFlexItem>
                                                        <EuiFlexItem>
                                                            <EuiText size='s'>
                                                                <p>
                                                                    Order Status :{' '}
                                                                    {
                                                                        ccavenueRefundDetails.ccavenue_transaction
                                                                            .order_status
                                                                    }
                                                                </p>
                                                            </EuiText>
                                                        </EuiFlexItem>
                                                    </>
                                                )}
                                            </>
                                        ) : null}
                                    </EuiFlexGroup>
                                </>
                            }
                        />
                        <EuiSpacer size='s' />
                    </>
                );
            }
            if (refund.initiated_at && !refund.failed_at && !refund.refunded_at) {
                refunds.push(
                    <>
                        <EuiCard
                            display='plain'
                            layout='horizontal'
                            paddingSize='s'
                            style={{ backgroundColor: '#F5A700' }}
                            title={
                                <EuiFlexItem style={{ alignItems: 'center', flexDirection: 'row' }}>
                                    <EuiIcon color='#FFFFF0' type='alert' />
                                    <div style={{ marginLeft: '10px', color: '#FFFFF0' }}>
                                        <span>Refund Inititated</span>
                                    </div>
                                </EuiFlexItem>
                            }
                            description={
                                <>
                                    <EuiFlexGroup direction='column' gutterSize='none'>
                                        <EuiFlexItem>
                                            <EuiText size='s'>Refund process inititated successfully.</EuiText>
                                        </EuiFlexItem>
                                        {customer ? (
                                            <>
                                                <EuiFlexItem>
                                                    <EuiText size='s'>Customer Name: {customer.name}</EuiText>
                                                </EuiFlexItem>
                                                <EuiFlexItem>
                                                    <EuiText size='s'>
                                                        Customer Mobile Number: {customer.mobile_number}
                                                    </EuiText>
                                                </EuiFlexItem>
                                                {refund?.ccavenue_transaction && (
                                                    <>
                                                        <EuiFlexItem>
                                                            <EuiText size='s'>
                                                                <p>
                                                                    Bank Ref. No. :
                                                                    {
                                                                        ccavenueRefundDetails.ccavenue_transaction
                                                                            .bank_ref_no
                                                                    }{' '}
                                                                </p>
                                                            </EuiText>
                                                        </EuiFlexItem>
                                                    </>
                                                )}
                                            </>
                                        ) : null}
                                    </EuiFlexGroup>
                                </>
                            }
                        />
                        <EuiSpacer size='s' />
                    </>
                );
            }

            if (refund.failed_at) {
                refunds.push(
                    <>
                        <EuiCard
                            display='plain'
                            layout='horizontal'
                            paddingSize='s'
                            style={{ backgroundColor: '#ff00002e' }}
                            title={
                                <EuiFlexItem style={{ alignItems: 'center', flexDirection: 'row' }}>
                                    <EuiIcon color='rgb(189, 39, 30)' type='alert' />
                                    <div style={{ marginLeft: '10px', color: 'rgb(189, 39, 30)' }}>
                                        <span>Refund Failed</span>
                                    </div>
                                </EuiFlexItem>
                            }
                            description={
                                <>
                                    <EuiFlexGroup direction='column' gutterSize='none'>
                                        <EuiFlexItem>
                                            <EuiText size='s'>
                                                Refund process couldn't able to complete. Please retry refund.
                                            </EuiText>
                                        </EuiFlexItem>
                                        {customer ? (
                                            <>
                                                <EuiFlexItem>
                                                    <EuiText size='s'>Customer Name: {customer.name}</EuiText>
                                                </EuiFlexItem>
                                                <EuiFlexItem>
                                                    <EuiText size='s'>
                                                        Customer Mobile Number: {customer.mobile_number}
                                                    </EuiText>
                                                </EuiFlexItem>
                                            </>
                                        ) : null}
                                    </EuiFlexGroup>

                                    <EuiSpacer size='s' />
                                    {userHasPermission(permissions, '/past-orders', 'delete') && props.isPast && (
                                        <EuiFlexItem>
                                            <EuiButton fill size='s' onClick={refundAmountAPI} isLoading={refundLoader}>
                                                Retry
                                            </EuiButton>
                                        </EuiFlexItem>
                                    )}
                                </>
                            }
                        />
                        <EuiSpacer size='s' />
                    </>
                );
            }
        });

        return refunds;
    };

    return (
        <>
            {selectedOrder?.refunds?.length ? (
                <>
                    {refundCard()}
                    <EuiSpacer size='s' />
                    {refundDetails ? (
                        Object.keys(refundDetails).length > 0 ? (
                            <EuiCard style={{ marginBottom: '5px' }}>
                                <EuiBasicTable
                                    style={{ backgroundColor: '#74decef2', marginBottom: '5px' }}
                                    columns={[
                                        {
                                            field: 'key',
                                            name: 'Title',
                                            width: '40%',
                                        },
                                        {
                                            field: 'value',
                                            name: 'Description',
                                            width: '60%',
                                        },
                                    ]}
                                    items={[
                                        { key: 'Result Code', value: refundDetails.resultInfo?.resultCode ?? '---' },
                                        { key: 'Result Message', value: refundDetails.resultInfo?.resultMsg ?? '---' },
                                        {
                                            key: 'Result Status',
                                            value: refundDetails.resultInfo?.resultStatus ?? '---',
                                        },
                                        ...(refundDetails.refundDetailInfoList?.flatMap((detail) => [
                                            { key: 'Card Scheme', value: detail.cardScheme ?? '---' },
                                            { key: 'Payment Method', value: detail.payMethod ?? '---' },
                                            { key: 'Refund Amount', value: detail.refundAmount ?? '---' },
                                            { key: 'Refund Type', value: detail.refundType ?? '---' },
                                            { key: 'RRN', value: detail.rrn ?? '---' },
                                        ]) || []),
                                    ]}
                                />
                            </EuiCard>
                        ) : (
                            <EuiLoadingContent lines={3} />
                        )
                    ) : null}
                </>
            ) : null}
        </>
    );
};

export default RefundStatusCard;
