import API from '../axios/API';
import * as types from '../../reduxStore/types/orders';

export const getLiveOrderDetailsAPI = () => {
    return async (dispatch, getState) => {
        let liveOrderDetailsResponse;
        let { orders } = getState();

        if (!orders.selectedOrderId) return {};

        try {
            liveOrderDetailsResponse = await API.get(
                `${process.env.REACT_APP_BASE_URL_V3}/restaurants/:restaurantId/orders/${orders.selectedOrderId}`
            );

            dispatch({
                type: types.SET_SELECTED_ORDER_DETAILS,
                payload: liveOrderDetailsResponse.order,
            });
        } catch (error) {
            liveOrderDetailsResponse = error;
        }

        if (liveOrderDetailsResponse.success) {
        }

        return liveOrderDetailsResponse;
    };
};
