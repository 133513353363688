import { EuiCard, EuiFlexGroup, EuiSpacer } from '@elastic/eui';
import { isEmpty } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import Section from './Section';
import TableStatusColorInformer from './TableStatusColorInformer';

const Sections = ({ isEditTableMode }) => {
    const sections = useSelector((state) => state.tableManagement.sectionList);
    const outletOrderingModes = useSelector((state) => state.outlet.orderingModes)?.find(
        (mode) => mode?.restaurant_ordering_mode?.tab === 'dine_in' && mode?.restaurant_ordering_mode?.status
    );

    if (!sections?.length || isEmpty(outletOrderingModes)) {
        return (
            <>
                {!sections?.length ? (
                    <>
                        <EuiSpacer />
                        <EuiCard title='No Section Found' description='Please create a new one' />
                    </>
                ) : null}
                {isEmpty(outletOrderingModes) ? (
                    <>
                        <EuiSpacer />
                        <EuiCard
                            title='No Ordering Modes Found'
                            description='Seems there are no ordering modes enabled for this outlet'
                        />
                    </>
                ) : null}
            </>
        );
    }

    return (
        <>
            <EuiSpacer />

            <TableStatusColorInformer />

            <EuiSpacer />

            <EuiFlexGroup direction='column'>
                {sections?.map((section) => {
                    return (
                        <Section isEditTableMode={isEditTableMode} section={section} key={section?.id?.toString()} />
                    );
                })}
            </EuiFlexGroup>
        </>
    );
};

export default React.memo(Sections);
