import { EuiFlyout, EuiFlyoutBody, EuiFlyoutHeader, EuiLoadingContent, EuiTitle } from '@elastic/eui';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchVariantsOptions } from './Api/fetchVariantsOptions';
import VariantOption from './VariantOption';

const VariantFlyout = ({ item, closeFlyout, handleAvailablityModal }) => {
    const outletId = useSelector((state) => state.outlet.selectedOutletId);
    const [variantChoices, setVariantChoices] = useState({
        loading: true,
        options: null,
    });

    const fetchItemVariantsData = useCallback(async () => {
        setVariantChoices((prevState) => ({
            ...prevState,
            loading: true,
        }));
        fetchVariantsOptions(outletId, item.id).then((response) => {
            if (!response?.isEror) {
                setVariantChoices((prevState) => ({
                    ...prevState,
                    options: response.variant_options,
                }));
            }
            setVariantChoices((prevState) => ({
                ...prevState,
                loading: false,
            }));
        });
    }, [outletId, item]);

    useEffect(() => {
        fetchItemVariantsData();
    }, []);

    return (
        <>
            <EuiFlyout onClose={closeFlyout}>
                <EuiFlyoutHeader>
                    <EuiTitle size='m'>
                        <h2>Availability of variants</h2>
                    </EuiTitle>
                </EuiFlyoutHeader>
                <EuiFlyoutBody>
                    {variantChoices.loading ? (
                        <EuiLoadingContent lines={5} />
                    ) : (
                        <>
                            {variantChoices.options?.map((option) => {
                                return (
                                    <VariantOption
                                        handleAvailablityModal={handleAvailablityModal}
                                        key={option.id.toString()}
                                        option={option}
                                        fetchItemVariantsData={fetchItemVariantsData}
                                    />
                                );
                            })}
                        </>
                    )}
                </EuiFlyoutBody>
            </EuiFlyout>
        </>
    );
};

export default React.memo(VariantFlyout);
