import React from 'react';
import { EuiEmptyPrompt } from '@elastic/eui';
import { MainLayout } from '../../components/layout/mainLayout/mainLayout';

const PermissionErrorPage = () => {
    return (
        <MainLayout>
            <EuiEmptyPrompt
                iconType='eyeClosed'
                style={{ marginTop: '22vh' }}
                title={<h2>You don't have permission to access this page, please contact admin</h2>}
            />
        </MainLayout>
    );
};

export default PermissionErrorPage;
