import API from '../axios/API';

export const toggleOutletAvailability = (outletId, is_open) => {
    return async (dispatch, getState) => {
        let response;

        try {
            response = await API.patch(`restaurants/:restaurantId/outlets/${outletId}/update/status`, { is_open });
        } catch (error) {
            response = error;
        }

        return response;
    };
};
