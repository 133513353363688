import APIV3 from '../../../../api/axios/APIV3';

export const fetchSearchedCategories = async (keyword, languageId, outlet_id) => {
    let url = `restaurants/:restaurantId/categories/internal-name?language_id=${languageId}&keyword=${keyword}`;

    if (outlet_id) {
        url += `&outlet_id=${outlet_id}`;
    }

    const response = await APIV3.get(url);

    return response;
};
