import React, { useState } from 'react';
import { EuiFlexItem, EuiSwitch, EuiText } from '@elastic/eui';
import API from '../../api/axios/API';
import { useDispatch, useSelector } from 'react-redux';
import { userHasPermission } from '../../helper/auth';
import FormGroupDescription from '../formGroupDescription';

export const NotificationToggle = () => {
    const permissions = useSelector((state) => state.permission.permission);
    const dispatch = useDispatch();
    const authUser = useSelector((state) => state.auth.user);
    let defaultValue = true;
    if (localStorage.getItem('user')) {
        defaultValue = JSON.parse(localStorage.getItem('user'));
        defaultValue = defaultValue.accepting_orders ? true : false;
    }
    const [toggleOn, setToggleOn] = useState(defaultValue);

    const onChange = async (e) => {
        let toggleBool = e.target.checked;
        let toggleNotification = '';
        try {
            toggleNotification = await API.patch(`/restaurants/:restaurantId/devices/toggle-accepting-orders`, {
                device_id: localStorage.getItem('deviceId'),
            });
        } catch (error) {
            toggleNotification = error;
        }

        if (toggleNotification.success) {
            setToggleOn(toggleBool);

            dispatch({
                type: 'set-auth',
                payload: {
                    ...authUser,
                    accepting_orders: toggleBool ? 1 : 0,
                },
            });

            if (!toggleBool) {
                dispatch({
                    type: 'set-live-orders',
                    payload: [],
                });
            }
            let localValue = JSON.parse(localStorage.getItem('user'));
            localValue['accepting_orders'] = toggleBool ? 1 : 0;
            localStorage.setItem('user', JSON.stringify(localValue));
        } else {
            alert('Error in update..');
        }
    };
    return (
        <>
            {userHasPermission(permissions, '/live-orders', 'read') && (
                <FormGroupDescription title='Toggle Notifications' description=''>
                    <EuiFlexItem
                        style={{ padding: '3px', justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }}
                    >
                        <EuiFlexItem style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            {toggleOn && <EuiText>Show Orders</EuiText>}

                            <div style={{ margin: '0 5px' }}>
                                <EuiSwitch label='' checked={toggleOn} onChange={(e) => onChange(e)} />
                            </div>

                            {!toggleOn && <EuiText>Hide Orders</EuiText>}
                        </EuiFlexItem>
                    </EuiFlexItem>
                </FormGroupDescription>
            )}
        </>
    );
};
