import { EuiCard, EuiFlexItem, EuiIcon, EuiSpacer } from '@elastic/eui';
import React from 'react';

const OrderFailureReasonCard = ({ order }) => {
    const { order_failure_reason } = order;

    return order_failure_reason ? (
        <>
            <EuiCard
                display='plain'
                layout='horizontal'
                paddingSize='s'
                style={{ backgroundColor: 'red' }}
                title={
                    <EuiFlexItem style={{ alignItems: 'center', flexDirection: 'row' }}>
                        <EuiIcon color='#FFFFF0' type='crossInACircleFilled' />
                        <div style={{ marginLeft: '10px', color: '#FFFFF0' }}>
                            <span>Order Failed</span>
                        </div>
                    </EuiFlexItem>
                }
                description={order_failure_reason}
            />
            <EuiSpacer />
        </>
    ) : null;
};

export default OrderFailureReasonCard;
