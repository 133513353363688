import React, { useCallback, useEffect, useState } from 'react';
import { EuiForm } from '@elastic/eui';
import FormGroupDescription from '../../../components/formGroupDescription';
import TextAreaField from '../../../components/Form/TextAreaField';
import { useDispatch, useSelector } from 'react-redux';
import { restaurantSettingsAPI } from '../../../api/restaurantSettings/restaurantSettingsAPI';
import useFormActionsHandler from '../../../hooks/useFormActionsHandler';
import { toastsErrorMessage } from '../../../utils/toasts';

const ContactUs = (props) => {
    const { restaurantResponse, fetchRestaurant } = props;
    const [defaultValues, setDefaultValues] = useState({});
    const dispatch = useDispatch();
    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);

    const updateFormValues = useCallback((data) => {
        let defaultValue = {};
        defaultValue.languages = data.languages.map((item) => ({
            label: item.language_label,
            language: item.language,
            language_label: item.language_label,
        }));

        let translationObject = {
            contact_us: {},
        };

        for (const language_label in data.translations.contact_us) {
            translationObject.contact_us[language_label] = data.translations.contact_us[language_label];
        }

        defaultValue.translations = translationObject;

        return defaultValue;
    }, []);

    useEffect(() => {
        if (restaurantResponse?.restaurant) {
            setDefaultValues(updateFormValues(restaurantResponse.restaurant));
        }
    }, [restaurantResponse?.restaurant]);

    const onFormSaveApi = useCallback(
        async (data) => {
            const response = await dispatch(restaurantSettingsAPI('contact_us', data));
            if (response.success) {
                fetchRestaurant();
            } else {
                toastsErrorMessage(response?.message, dispatch);
            }

            if (response.success) setDefaultValues(updateFormValues(response.restaurant));
            return response;
        },
        [dispatch, fetchRestaurant, updateFormValues]
    );

    const { errors, control, watch } = useFormActionsHandler({
        onFormSaveApi,
        defaultValues,
    });

    return (
        <>
            <EuiForm component='form'>
                <FormGroupDescription
                    title={restaurantDescriptions?.contact_us?.display_name}
                    description={restaurantDescriptions?.contact_us?.description}
                >
                    {watch('languages') &&
                        watch('languages').length > 0 &&
                        watch('languages').map((item, index) => (
                            <TextAreaField
                                errors={errors}
                                isInvalid={
                                    errors.translations &&
                                    errors.translations.contact_us &&
                                    errors.translations.contact_us.hasOwnProperty(item.language)
                                }
                                error={
                                    errors.translations &&
                                    errors.translations.contact_us &&
                                    errors.translations.contact_us.hasOwnProperty(item.language) &&
                                    errors.translations.contact_us[item.language]
                                        ? errors.translations.contact_us[item.language].message
                                        : ''
                                }
                                label={index === 0 ? <strong>Contact us</strong> : ''}
                                name={`translations.contact_us.${item.language}`}
                                placeholder={`Write contact us: ${item.language_label}`}
                                rules={{ required: 'Please write contact us' }}
                                control={control}
                                watch={watch}
                                rows={4}
                            />
                        ))}
                </FormGroupDescription>
            </EuiForm>
        </>
    );
};

export default React.memo(ContactUs);
