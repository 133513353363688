import API from '../../../../api/axios/API';
import APIV3 from '../../../../api/axios/APIV3';
import { baseApiUrl } from '../../../../envConfig';

export const fetchOutletCatalogue = async (selectedOutletId, languageId) => {
    let categories;
    try {
        categories = await APIV3.get(
            `/restaurants/:restaurantId/outlets/${selectedOutletId}/categories/internal-name?language_id=${languageId}`
        );
    } catch (error) {
        categories = error;
    }

    return categories;
};

export const fetchRestaurantCatalogue = async (languageId) => {
    let categories;

    try {
        categories = await APIV3.get(`/restaurants/:restaurantId/categories/internal-name?language_id=${languageId}`);
    } catch (error) {
        categories = error;
    }

    return categories;
};

export const fetchAddons = async ({ pageNumber, keyword }) => {
    let addons;

    try {
        addons = await API.get(
            `/restaurants/:restaurantId/restaurant-addon-groups?keyword=${keyword}&per_page=10&page=${pageNumber}`
        );
    } catch (error) {
        addons = error;
    }

    return addons;
};

// CRUD Badges

export const fetchRestaurantBadges = async () => {
    let badges;

    try {
        badges = await APIV3.get(`/restaurants/:restaurantId/badges`);
    } catch (error) {
        badges = error;
    }

    return badges;
};

export const createNewBadge = async (newBadge) => {
    let createBadge;

    try {
        createBadge = await APIV3.post(`/restaurants/:restaurantId/badges`, newBadge);
    } catch (error) {
        createBadge = error;
    }

    return createBadge;
};

export const updateBadge = async (updatedBadge, badgeId) => {
    let response;

    try {
        response = await APIV3.put(`restaurants/:restaurantId/badges/${badgeId}`, updatedBadge);
    } catch (error) {
        response = error;
    }

    return response;
};

export const deleteBadges = async (badgeId) => {
    let response;

    try {
        response = await APIV3.delete(`restaurants/:restaurantId/badges/${badgeId}`);
    } catch (error) {
        response = error;
    }

    return response;
};

export const fetchCategoryItem = async (categoryId, languageId) => {
    let categoryItem;

    try {
        categoryItem = await APIV3.get(
            `/restaurants/:restaurantId/categories/${categoryId}/items/internal-name?language_id=${languageId}`
        );
    } catch (error) {
        categoryItem = error;
    }

    return categoryItem;
};

export const fetchCategoryItemsForAddonLink = async (categoryId, languageId) => {
    let categoryItem;

    try {
        categoryItem = await APIV3.get(
            `/restaurants/:restaurantId/categories/${categoryId}/items?language_id=${languageId}`
        );
    } catch (error) {
        categoryItem = error;
    }

    return categoryItem;
};

export const fetchCategoryItemsForCoupledOffer = async (categoryId, languageId) => {
    let categoryItem;

    try {
        categoryItem = await APIV3.get(
            `/restaurants/:restaurantId/categories/${categoryId}/items?language_id=${languageId}&is_recursive=1`
        );
    } catch (error) {
        categoryItem = error;
    }

    return categoryItem;
};

export const fetchOutletCategoryItem = async (categoryId, languageId, outletId) => {
    let categoryItem;

    try {
        categoryItem = await APIV3.get(
            `/restaurants/:restaurantId/outlets/${outletId}/categories/${categoryId}/items/internal-name?language_id=${languageId}`
        );
    } catch (error) {
        categoryItem = error;
    }

    return categoryItem;
};

export const fetchActiveItemDetails = async (itemId) => {
    let itemDetails;

    try {
        itemDetails = await APIV3.get(`/restaurants/:restaurantId/items/${itemId}`);
    } catch (error) {
        itemDetails = error;
    }

    return itemDetails;
};

export const uploadItemImage = async (formData, token, restaurantID) => {
    let imageUpload = await fetch(`${baseApiUrl}/restaurants/${restaurantID}/uploads`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        body: formData,
    });

    return imageUpload;
};

export const getSelectedCategoryDetails = (categoryId) => {
    return async (dispatch) => {
        let response;

        try {
            response = await API.get(`restaurants/:restaurantId/categories/${categoryId}`);
            response = response?.category;
        } catch (error) {
            response = error;
        }

        return response;
    };
};

export const fetchSearchedCategoryItem = async (query, languageId) => {
    let response;
    response = await APIV3.get(
        `/restaurants/:restaurantId/categories/search?language_id=${languageId}&keyword=${query}`
    );

    return response;
};

export const fetchSearchedItems = async (query, languageId) => {
    let response;
    try {
        response = await API.get(`/restaurants/:restaurantId/items?limit=10&keyword=${query}`);
    } catch (error) {
        response = {
            error: error,
        };
    }

    return response;
};

// Sequence Change

export const updateCategorySequence = async (categories) => {
    let response;

    try {
        response = await APIV3.patch(`restaurants/:restaurantId/categories/change-sequence`, {
            categories: categories,
        });
    } catch (error) {
        response = error;
    }

    return response;
};

export const updateItemsSequence = async (items) => {
    let response;

    try {
        response = await APIV3.patch(`restaurants/:restaurantId/items/change-sequence`, { items: items });
    } catch (error) {
        response = error;
    }

    return response;
};

export const updateCategorySequenceForOutlet = async (outlet_id, categories) => {
    let response;

    try {
        response = await APIV3.patch(`restaurants/:restaurantId/outlets/${outlet_id}/categories/change-sequence`, {
            categories: categories,
        });
    } catch (error) {
        response = error;
    }

    return response;
};

export const updateItemsSequenceForOulet = async (outlet_id, items) => {
    let response;

    try {
        response = await APIV3.patch(`restaurants/:restaurantId/outlets/${outlet_id}/items/change-sequence`, {
            items: items,
        });
    } catch (error) {
        response = error;
    }

    return response;
};

// fetch ordering modes

export const fetchOrderingModes = async () => {
    let response;

    response = await APIV3.get(`restaurants/:restaurantId/restaurant-ordering-modes`);

    return response;
};

export const fetchQuantityUnits = async () => {
    let response;

    try {
        response = await APIV3.get(`restaurants/:restaurantId/quantity_units`);
    } catch (error) {
        response = {
            error: error,
        };
    }

    return response;
};
