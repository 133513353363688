import APIV3 from '../../../api/axios/APIV3';

export const linkItemToPetPooja = async (data, selectedOutletId) => {
    let response;

    try {
        response = await APIV3.post(`restaurants/:restaurantId/outlets/${selectedOutletId}/pet-pooja/menu-mapping`, {
            external_menu_links: data.external_menu_links,
            external_menu_unlink: data.external_menu_unlink,
        });
    } catch (error) {
        response = {
            error: error,
        };
    }

    return response;
};
