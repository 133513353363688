export const FLAT_DISCOUNT = 'flat_discount';
export const PERCENTAGE_DISCOUNT = 'percentage_discount';
export const FREE_ITEMS = 'free_items';
export const BUY_X_GET_X = 'buy_x_get_y';
export const FREE_DELIVERY = 'free_delivery';
export const MENU_DISCOUNT = 'menu_discount';
export const BUY_ONE_GET_ONE_FREE = 'buy_one_get_one_free';
export const COUPLED_OFFER = 'coupled_offer';
export const COMBO_ITEM_PRICE_DEAL = 'combo_item_price_deal';
export const BOGO_WITH_VIEW = 'bogo_with_view';
