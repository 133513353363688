import React, { memo, useEffect, useState } from 'react';
import {
    EuiTable,
    EuiTableHeader,
    EuiTableHeaderCell,
    EuiCheckbox,
    EuiTableRowCell,
    EuiTableRow,
    htmlIdGenerator,
    EuiTableBody,
} from '@elastic/eui';
import { EuiLoadingContent } from '@elastic/eui';
import API from '../../api/axios/API';
import { useSelector } from 'react-redux';
import APIV3 from '../../api/axios/APIV3';

const UserOutletPermission = ({ user, useSideEffect, setUseSideEffect }) => {
    const languageId = useSelector((state) => state.language.languageId);

    const [isLoading, setIsLoading] = useState(true);
    const [outletData, setOutletData] = useState([]);
    const [error, setError] = useState(false);
    // const [useSideEffect, setUseSideEffect] = useState({});

    const [permissionAccessLoading, setPermissionAccessLoading] = useState(true);
    const [permission, setPermission] = useState([]);
    const [permissionError, setPermissionError] = useState(false);

    // get all outle of restaurant
    const getOutletData = async () => {
        setIsLoading(true);
        await APIV3.get(`restaurants/:restaurantId/users/outlets`)
            .then((res) => {
                setOutletData(res);
            })
            .catch(() => {
                setError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const getPermission = async () => {
        setPermissionAccessLoading(true);
        await API.get(`restaurants/:restaurantId/users/${user.user.id}/outlet-permission`)
            .then((res) => {
                setPermission(res);
                setPermissionAccessLoading(false);
            })
            .catch(() => setPermissionError(true));
    };

    const onChangePermission = async (e, outlet_id) => {
        setUseSideEffect({});
        if (e.target.checked) {
            await API.patch(`restaurants/:restaurantId/outlets/${outlet_id}/users/${user.user.id}/link`).then(() =>
                setUseSideEffect('changes')
            );
        } else {
            await API.patch(`restaurants/:restaurantId/outlets/${outlet_id}/users/${user.user.id}/un-link`).then(() =>
                setUseSideEffect('changes')
            );
        }
    };

    useEffect(() => {
        getPermission();
        getOutletData();
    }, [useSideEffect]);

    if (isLoading || permissionAccessLoading) {
        return <EuiLoadingContent lines={10} />;
    }
    if (error || permissionError) {
        return <h1> Error </h1>;
    }

    let object = {};
    outletData.outlets.map((item, index) => (object[item.outlet_id] = false));

    permission.userPermission.map((item, index) => (object[item.outlet.outlet_id] = true));

    return (
        <>
            <EuiTable>
                <EuiTableHeader>
                    <EuiTableHeaderCell key='outlet_list'> Outlet List </EuiTableHeaderCell>
                    <EuiTableHeaderCell key='permission'> permission </EuiTableHeaderCell>
                </EuiTableHeader>

                <EuiTableBody>
                    {outletData.outlets.map((item, index) => {
                        return (
                            <EuiTableRow key={item.outlet_id}>
                                <EuiTableRowCell> {item.translations.name[languageId]} </EuiTableRowCell>
                                <EuiTableRowCell>
                                    <EuiCheckbox
                                        id={htmlIdGenerator()()}
                                        checked={object[item.outlet_id]}
                                        onChange={(e) => onChangePermission(e, item.outlet_id)}
                                    />
                                </EuiTableRowCell>
                            </EuiTableRow>
                        );
                    })}
                </EuiTableBody>
            </EuiTable>
        </>
    );
};
export default memo(UserOutletPermission);
