import { EuiButtonEmpty, EuiFlexGroup, EuiFlexItem, EuiPanel, EuiText, EuiTitle } from '@elastic/eui';
import React, { useState } from 'react';
import SectionCreationFlyout from './SectionCreationFlyout';
import TableInfo from './TableInfo';

const Section = ({ section, isEditTableMode }) => {
    const [showFlyout, setShowFlyout] = useState(false);
    const [selectedSection, setSelectedSection] = useState(null);

    const handleFlyoutState = async (section) => {
        setShowFlyout((prevState) => !prevState);
        setSelectedSection(section);
    };

    return (
        <>
            <EuiFlexItem>
                <EuiPanel hasShadow={false}>
                    <EuiFlexGroup justifyContent='flexStart' alignItems='center'>
                        <EuiFlexItem grow={false}>
                            <EuiTitle size='s'>
                                <h2>{section.internal_name}</h2>
                            </EuiTitle>
                        </EuiFlexItem>
                        {isEditTableMode ? (
                            <EuiFlexItem
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleFlyoutState(section)}
                                grow={false}
                            >
                                <EuiFlexGroup gutterSize='s' alignItems='center'>
                                    <EuiButtonEmpty size='s' color='success' iconType={'pencil'} iconSize='s'>
                                        <EuiText size='xs'>Edit</EuiText>
                                    </EuiButtonEmpty>
                                </EuiFlexGroup>
                            </EuiFlexItem>
                        ) : null}
                    </EuiFlexGroup>
                    {section?.tables?.length ? (
                        <TableInfo isEditTableMode={isEditTableMode} tables={section.tables} />
                    ) : null}
                </EuiPanel>
            </EuiFlexItem>
            {showFlyout ? (
                <SectionCreationFlyout
                    closeFlyout={handleFlyoutState}
                    selectedSection={selectedSection}
                    sectionState='update'
                />
            ) : null}
        </>
    );
};

export default Section;
