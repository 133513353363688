import {
    EuiCard,
    EuiEmptyPrompt,
    EuiFlexGroup,
    EuiFlexItem,
    EuiLoadingContent,
    EuiPagination,
    EuiSpacer,
} from '@elastic/eui';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import API from '../../../api/axios/API';
import { petPoojaSyncValidate } from '../../../api/menu/petPoojaSyncValidate';
import { fetchOrderingModes } from '../../../api/outlet/fetchOrderingModes';
import { getFlatToTreeStructureNew } from '../../../helper/getFlatToTreeStructureNew';
import {
    GET_PET_POOJA_ITEMS,
    GET_PET_POOJA_LINKED_ITEMS,
    GET_PET_POOJA_RESTAURANT,
    GET_PET_POOJA_TAXES,
    GET_RESTAURANT_ITEMS,
    GET_SYSTEM_CATEGORIES,
    SET_PET_POOJA_VARIANTS,
} from '../../../reduxStore/types/PetPoojaItemTypes';
import UnlinkedItems from '../../../components/PetPoojaLink/UnlinkedItems';
import { linkItemToPetPooja } from '../../../components/PetPoojaLink/Api/linkSelectedToPetPooja';
import PetPoojaSynceButton from '../../../components/PetPoojaLink/PetPoojaSynceButton';
import { itemLinkingResetValue } from '../../../components/PetPoojaLink/utils/itemLinkingResetValue';
import { itemOnSubmitStructureFormate } from '../../../components/PetPoojaLink/utils/itemOnSubmitStructureFormate';
import { toastsErrorMessage, toastSuccessMessage } from '../../../utils/toasts';
import useFormActionsHandler from '../../../hooks/useFormActionsHandler';
import { fetchUpdatedLinkedItems } from '../Utils/utils';
import AutoSizer from 'react-virtualized-auto-sizer';
import 'react-virtualized/styles.css';
import { VariableSizeList as List } from 'react-window';
import CategoryLinkRow from '../Components/PetPoojaLink/CategoryLinkRow';
import Category from '../../../components/PetPoojaLink/Category';
import { TOGGLE_UNSAVED_PORTAL } from '../../../reduxStore/types/Global/Global';

const PetPoojaItemLink = () => {
    const dispatch = useDispatch();
    const outletId = useSelector((state) => state.outlet.selectedOutletId);
    const [isPetpoojaLinkEnable, setPetpoojaLinkEnable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isMounting, setIsMounting] = useState(true);
    const [petPoojaSyncLoader, setPetPoojaSyncLoader] = useState(false);
    const [categories, setCategories] = useState([]);
    const [totalCategoryItems, setTotalCategoryItems] = useState([]);
    const petPoojaItems = useSelector((state) => state.petPoojaReducer.petPoojaItems);
    const loadingLines = new Array(3).fill([]);
    const linkedItems = useSelector((state) => state.petPoojaReducer.petPoojaLinkedItems);
    const petPoojaTaxes = useSelector((state) => state.petPoojaReducer.petPoojaTaxes);
    const selectedOutlet = useSelector((state) => state.outlet.selectedOutlet);
    const showFormHandlerPortal = useSelector((state) => state.uiPortalStateReducer.portalState.showFormHandlerPortal);
    const [validationFailedItems, setValidationFailedItem] = useState({
        items: {},
        category: {},
        variants: {},
        addon: {},
    });
    const [pagination, setPagination] = useState({
        category_length: 5,
        active_page: 0,
        total_pages: 0,
        last_category_length: 0,
    });

    const [defaultValues, setDefaultValues] = useState({});

    const clearValidationFormError = useCallback(() => {
        setValidationFailedItem({ items: {}, category: {}, variants: {}, addon: {} });
    }, []);

    const fetchRecords = useCallback(async () => {
        setLoading(true);
        dispatch(fetchOrderingModes());
        const responses = await Promise.all([
            API.get(`restaurants/:restaurantId/outlets/${outletId}/categories/list`),
            API.get(`restaurants/:restaurantId/outlets/${outletId}/pet-pooja/menu-fetch`),
            API.get(`restaurants/:restaurantId/outlets/${outletId}/pet-pooja/menu-links`),
            API.get(`${process.env.REACT_APP_BASE_URL_V4}/restaurants/:restaurantId/outlets/${outletId}/items`),
        ]);
        if (responses?.length) {
            if (responses[0].success) {
                const treeStructuredCategories = getFlatToTreeStructureNew(responses[0].category);
                // console.log(`treeStructuredCategories`, treeStructuredCategories);
                setPagination((prevState) => ({
                    ...prevState,
                    total_pages: Math.round(treeStructuredCategories?.length / pagination.category_length) + 1,
                }));
                dispatch({
                    type: GET_SYSTEM_CATEGORIES,
                    payload: responses[0].category,
                });
                setCategories(getFlatToTreeStructureNew(responses[0].category));
            }
            if (responses[1]?.success && responses[1]?.pet_pooja_menu?.success != '0') {
                dispatch({
                    type: GET_PET_POOJA_ITEMS,
                    payload: responses[1].pet_pooja_menu.items,
                });
                const itemsVariations = [];
                responses[1]?.pet_pooja_menu?.items?.forEach((item) => {
                    if (item.variation?.length) {
                        itemsVariations.push(...item.variation);
                    }
                });

                const variations = itemsVariations?.reduce(
                    (acc, curr) =>
                        acc.find(
                            (variant) =>
                                variant.variationid == curr.variationid &&
                                variant.price == curr.price &&
                                variant.name == curr.name
                        )
                            ? acc
                            : [...acc, curr],
                    []
                );

                dispatch({
                    type: SET_PET_POOJA_VARIANTS,
                    payload: variations,
                });
                dispatch({
                    type: GET_PET_POOJA_TAXES,
                    payload: responses[1].pet_pooja_menu.taxes,
                });
                dispatch({
                    type: GET_PET_POOJA_RESTAURANT,
                    payload: responses[1].pet_pooja_menu.restaurants,
                });
            } else if (responses[1]?.pet_pooja_menu?.success == '0') {
                toastsErrorMessage(responses[1]?.pet_pooja_menu?.message, dispatch);
            }

            if (responses[2].success) {
                dispatch({
                    type: GET_PET_POOJA_LINKED_ITEMS,
                    payload: responses[2].order_response,
                });
            }

            if (responses[3].success) {
                dispatch({
                    type: GET_RESTAURANT_ITEMS,
                    payload: responses[3].items,
                });
            }

            setLoading(false);
        }
    }, [dispatch, outletId]);

    useEffect(() => {
        if (categories?.length === totalCategoryItems?.length && isMounting) {
            setIsMounting(false);
        }
    }, [totalCategoryItems, categories, isMounting]);

    useEffect(() => {
        if (isPetpoojaLinkEnable) {
            fetchRecords();
        }

        return function cleanup() {
            setTotalCategoryItems([]);
            clearValidationFormError();
        };
    }, [outletId, fetchRecords, clearValidationFormError, isPetpoojaLinkEnable]);

    useEffect(() => {
        if (!selectedOutlet?.outlet_data?.outlet_third_party_menu_setting?.is_pet_pooja_integration_enabled) {
            setPetpoojaLinkEnable(false);
            return;
        }

        setPetpoojaLinkEnable(true);
    }, [outletId, selectedOutlet]);

    const validateSync = useCallback(() => {
        setPetPoojaSyncLoader(true);
        dispatch(petPoojaSyncValidate()).then((response) => {
            if (response.success) {
                clearValidationFormError();
                toastSuccessMessage('This outlet Fully Synced', dispatch);
            } else {
                setValidationFailedItem({
                    ...validationFailedItems,
                    items: response?.unlinked_items,
                    variants: response?.unLinked_item_variant_option,
                    addon: response?.unlinked_item_addon_groups?.restaurant_addon_group_id,
                });
                toastsErrorMessage('This outlet is not Fully Synced', dispatch);
            }
            setPetPoojaSyncLoader(false);
        });
    }, [clearValidationFormError, dispatch, validationFailedItems]);

    const updatedLinkedItems = useCallback(async () => {
        await fetchUpdatedLinkedItems(dispatch, outletId);
    }, [dispatch, outletId]);

    const updateFormValues = useCallback(
        (data) => {
            let defaultValue = {};
            if (categories?.length) {
                for (const category of categories) {
                    itemLinkingResetValue(
                        defaultValue,
                        category,
                        totalCategoryItems,
                        linkedItems,
                        petPoojaItems,
                        petPoojaTaxes
                    );
                    if (category?.sub_categories?.length) {
                        for (const subCategory of category?.sub_categories) {
                            itemLinkingResetValue(
                                defaultValue,
                                subCategory,
                                totalCategoryItems,
                                linkedItems,
                                petPoojaItems,
                                petPoojaTaxes
                            );
                        }
                    }
                }
            }

            return defaultValue;
        },
        [linkedItems, petPoojaItems, petPoojaTaxes, totalCategoryItems]
    );

    useEffect(() => {
        if (categories?.length) {
            setDefaultValues(updateFormValues());
        }
    }, [categories, updateFormValues]);

    const onFormSaveApi = useCallback(
        async (data) => {
            if (!isEmpty(errors)) {
                return;
            }
            if (categories?.length) {
                let menu_links = [];
                for (const category of categories) {
                    itemOnSubmitStructureFormate(data, category.category_id, menu_links);
                    if (category?.sub_categories?.length) {
                        for (const subCategory of category?.sub_categories) {
                            itemOnSubmitStructureFormate(data, subCategory.category_id, menu_links);
                        }
                    }
                }

                const uniqueArr = menu_links?.reduce(
                    (acc, curr) =>
                        acc?.find(
                            (item) =>
                                item.third_party_module_id === curr.third_party_module_id &&
                                curr.module_name === item.module_name &&
                                curr.module_id === item.module_id &&
                                curr.restaurant_ordering_mode_id === item.restaurant_ordering_mode_id
                        )
                            ? acc
                            : [...acc, curr],
                    []
                );

                const removedAlreadyLinkedItems = uniqueArr?.filter((item) => !item?.linkedItem);

                data.external_menu_links = removedAlreadyLinkedItems?.length ? removedAlreadyLinkedItems : [];

                // REMOVE DUPLICATE UNLINK ITEMS
                const uniqueUnlinkedItems = data?.menu_unlinks?.reduce(
                    (acc, curr) =>
                        acc?.find(
                            (item) =>
                                item.third_party_module_id === curr.third_party_module_id &&
                                curr.module_id === item.module_id &&
                                curr.module_name === item.module_name &&
                                curr.restaurant_ordering_mode_id === item.restaurant_ordering_mode_id
                        )
                            ? acc
                            : [...acc, curr],
                    []
                );

                data.external_menu_unlink = uniqueUnlinkedItems?.length ? uniqueUnlinkedItems : [];
                const response = await linkItemToPetPooja(data, outletId);

                if (!response?.error) {
                    toastSuccessMessage('Item Saved Successfully', dispatch);
                }

                return response;
            }
        },
        [categories, outletId, dispatch, totalCategoryItems]
    );

    const { errors, control, watch, reset, setValue, isDirty, setError, clearErrors } = useFormActionsHandler({
        onFormSaveApi,
        defaultValues,
        mode: 'all',
        onSuccessCallback: () => updatedLinkedItems(),
    });

    const methods = {
        control,
        watch,
        reset,
        setValue,
        formState: { isDirty, errors },
        clearErrors,
        setError,
    };

    const onPageChange = (activePage) => {
        // console.log(`activePage`, activePage);
        // setPagination((prevState) => ({
        //     ...prevState,
        //     active_page: activePage,
        //     last_category_length: prevState.last_category_length + prevState.category_length,
        // }));
        if (showFormHandlerPortal) {
            dispatch({
                type: TOGGLE_UNSAVED_PORTAL,
                payload: true,
            });
        } else {
            if (activePage) {
                setPagination((prevState) => ({
                    ...prevState,
                    active_page: activePage,
                    last_category_length: prevState.category_length * activePage,
                }));
            } else {
                setPagination((prevState) => ({
                    ...prevState,
                    active_page: activePage,
                    last_category_length: 0,
                }));
            }
        }
    };

    // console.log('pagination state', pagination);
    // console.log(
    //     'show categories',
    //     categories.slice(pagination.last_category_length, pagination.last_category_length + pagination.category_length)
    // );
    // console.log('all categories', categories);

    if (!isPetpoojaLinkEnable) {
        return (
            <EuiFlexItem
                component='div'
                style={{ position: 'absolute', top: '50%', left: '50%', transform: `translate(-50%, -50%)` }}
            >
                <EuiEmptyPrompt
                    iconType='editorStrike'
                    title={<h2>PetPooja Settings</h2>}
                    body={<p>Please enable this from outlet setings - Third party POS.</p>}
                />
            </EuiFlexItem>
        );
    }

    if (loading || isMounting) {
        return (
            <EuiFlexGroup direction='column'>
                {loadingLines.map((line, index) => {
                    return (
                        <EuiFlexItem key={index} grow={false}>
                            <EuiLoadingContent lines={3} />
                        </EuiFlexItem>
                    );
                })}
            </EuiFlexGroup>
        );
    }

    return (
        <FormProvider {...methods}>
            <EuiFlexGroup style={{ height: '100%' }} alignItems='flexEnd' justifyContent='flexStart' direction='column'>
                <EuiFlexItem grow={false}>
                    <PetPoojaSynceButton
                        categories={categories}
                        isLoading={petPoojaSyncLoader}
                        handleOnClick={validateSync}
                    />
                </EuiFlexItem>
                {(!isEmpty(validationFailedItems.items) || !isEmpty(validationFailedItems.variants)) && (
                    <EuiFlexItem grow={false} style={{ width: '95%' }}>
                        <UnlinkedItems validationFailedItems={validationFailedItems} />
                    </EuiFlexItem>
                )}
                <EuiFlexItem grow={10} style={{ width: '97%' }}>
                    <EuiFlexGroup direction='column'>
                        <EuiFlexItem as='div' style={{ height: '100%', flex: 1 }}>
                            {categories?.length &&
                            categories.slice(
                                pagination.last_category_length,
                                pagination.last_category_length + pagination.category_length
                            ).length ? (
                                <>
                                    {categories
                                        .slice(
                                            pagination.last_category_length,
                                            pagination.last_category_length + pagination.category_length
                                        )
                                        .map((category, index) => {
                                            return (
                                                <Category
                                                    // rowRef={rowRef}
                                                    // isLoading={isLoading}
                                                    category={category}
                                                    key={category.category_id.toString() + index.toString()}
                                                    setTotalCategoryItems={setTotalCategoryItems}
                                                />
                                            );
                                        })}
                                </>
                            ) : (
                                <EuiCard title='Categories Not Found.' />
                            )}
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <EuiPagination
                                aria-label={'item-linking-pet-pooja'}
                                pageCount={pagination.total_pages}
                                activePage={pagination.active_page}
                                onPageClick={onPageChange}
                            />
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer />
        </FormProvider>
    );
};

export default React.memo(PetPoojaItemLink);
