import { EuiFlexItem, EuiSpacer } from '@elastic/eui';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import TextAreaField from '../../../../../components/Form/TextAreaField';
import TextField from '../../../../../components/Form/TextField';
import FormGroupDescription from '../../../../../components/formGroupDescription';

const CategoryBasicDetails = () => {
    const availableLanguages = useSelector((state) => state.language.availableLanguages);
    const editCategory = useSelector((state) => state.menu.editCategory);
    const addCategory = useSelector((state) => state.menu.addCategory);

    const {
        formState: { errors },
        control,
    } = useFormContext();

    return (
        <>
            <FormGroupDescription title='Internal name'>
                <EuiFlexItem>
                    <TextField
                        errors={errors}
                        label=''
                        name='internal_name'
                        placeholder='Please enter internal name'
                        rules={{ required: 'Please enter Internal name' }}
                        control={control}
                        defaultValue={editCategory.internal_name}
                    />
                </EuiFlexItem>
            </FormGroupDescription>

            <FormGroupDescription title='Title'>
                {availableLanguages.map((language, index) => {
                    return (
                        <React.Fragment key={index.toString()}>
                            <EuiFlexItem>
                                <TextField
                                    errors={errors}
                                    label={index === 0 ? 'Title' : ''}
                                    name={`title${language.language_id}`}
                                    defaultValue={
                                        editCategory[`title${language.language_id}`]
                                            ? editCategory[`title${language.language_id}`]
                                            : ''
                                    }
                                    placeholder={`Category title - ${language.language_label}`}
                                    rules={{
                                        required: 'Please enter category title',
                                    }}
                                    control={control}
                                />
                            </EuiFlexItem>
                            {availableLanguages.length - 1 !== index && <EuiSpacer />}
                        </React.Fragment>
                    );
                })}
            </FormGroupDescription>

            {!addCategory ? (
                <>
                    <FormGroupDescription title='Description'>
                        {availableLanguages.map((language, index) => (
                            <React.Fragment key={index.toString()}>
                                <EuiFlexItem>
                                    <TextAreaField
                                        errors={errors}
                                        label={index === 0 ? 'Description' : ''}
                                        name={`description${language.language_id}`}
                                        defaultValue={
                                            editCategory[`description${language.language_id}`]
                                                ? editCategory[`description${language.language_id}`]
                                                : ''
                                        }
                                        placeholder={`Description - ${language.language_label}`}
                                        control={control}
                                    />
                                </EuiFlexItem>
                                {availableLanguages.length - 1 !== index && <EuiSpacer />}
                            </React.Fragment>
                        ))}
                    </FormGroupDescription>

                    <FormGroupDescription title='Size'>
                        {availableLanguages.map((language, index) => (
                            <React.Fragment key={index.toString()}>
                                <EuiFlexItem>
                                    <TextField
                                        errors={errors}
                                        label={index === 0 ? 'Size' : ''}
                                        name={`size${language.language_id}`}
                                        defaultValue={
                                            editCategory[`size${language.language_id}`]
                                                ? editCategory[`size${language.language_id}`]
                                                : ''
                                        }
                                        placeholder={`Category size - ${language.language_label}`}
                                        control={control}
                                    />
                                </EuiFlexItem>
                                {availableLanguages.length - 1 !== index && <EuiSpacer />}
                            </React.Fragment>
                        ))}
                    </FormGroupDescription>

                    <FormGroupDescription title='Serves'>
                        {availableLanguages.map((language, index) => (
                            <React.Fragment key={index.toString()}>
                                <EuiFlexItem>
                                    <TextField
                                        errors={errors}
                                        label={index === 0 ? 'Serves' : ''}
                                        name={`serves${language.language_id}`}
                                        defaultValue={
                                            editCategory[`serves${language.language_id}`]
                                                ? editCategory[`serves${language.language_id}`]
                                                : ''
                                        }
                                        placeholder={`Category serves - ${language.language_label}`}
                                        control={control}
                                    />
                                </EuiFlexItem>
                                {availableLanguages.length - 1 !== index && <EuiSpacer />}
                            </React.Fragment>
                        ))}
                    </FormGroupDescription>
                </>
            ) : null}
        </>
    );
};

export default React.memo(CategoryBasicDetails);
