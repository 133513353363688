import React, { useCallback, useEffect, useState } from 'react';
import NumberField from '../../../components/Form/NumberField';
import FormGroupDescription from '../../../components/formGroupDescription';
import { EuiSpacer, EuiForm, EuiFlexItem, EuiPanel } from '@elastic/eui';
import { useDispatch, useSelector } from 'react-redux';
import { outletSettingsV4 } from '../../../api/outlet/outletSettingsAPI';
import useOutletFormValues from '../Hooks/useOutletFormValues';
import useFormActionsHandler from '../../../hooks/useFormActionsHandler';
import CheckBoxField from '../../../components/Form/CheckBoxField';
import { useWatch } from 'react-hook-form';
import { fetchOutletOrderingModes } from '../../../api/outlet/fetchOutletOrderingModes';
import { fetchRestaurantOrderingModes } from '../../../components/settings/RestaurantSettings/Utils/utils';

const OtherCharges = () => {
    const dispatch = useDispatch();
    const outletId = useSelector((state) => state.outlet.selectedOutletId);
    const orderingModes = useSelector((state) => state.outlet.outletOrderingModes?.ordering_modes);
    const [outletOrderingModes, setOutletOrderingModes] = useState([]);
    const restaurantOrderingModes = useSelector((state) => state.restaurantReducer.orderingModes);
    const restaurantDescriptions = useSelector((state) => state.auth.restaurantDescriptions);

    const updateFormValues = useCallback(
        (data) => {
            const ordering_modes = orderingModes?.filter((orderingMode, index) =>
                restaurantOrderingModes.some(
                    (mode) =>
                        mode.id === orderingMode.restaurant_ordering_mode_id && mode.status && orderingMode.is_enabled
                )
            );

            const additional_charges_ordering_modes = data?.ordering_mode_wise_additional_charges.filter(
                (orderingMode) =>
                    ordering_modes?.some(
                        (mode) => mode.restaurant_ordering_mode_id === orderingMode.restaurant_ordering_mode_id
                    )
            );
            const firstIndexPackagingCharge = data?.ordering_mode_wise_additional_charges?.[0]?.packaging_charge;
            const firstIndexConvenienceCharge = data?.ordering_mode_wise_additional_charges?.[0]?.convenience_charge;
            const firstIndexEnablePackagingCharge =
                data?.ordering_mode_wise_additional_charges?.[0]?.is_packaging_charge_applicable;
            const firstIndexEnableConvenienceCharge =
                data?.ordering_mode_wise_additional_charges?.[0]?.is_convenience_charge_applicable;

            const isSameConfig = data?.ordering_mode_wise_additional_charges?.every(
                (config) =>
                    config.packaging_charge === firstIndexPackagingCharge &&
                    config.convenience_charge === firstIndexConvenienceCharge
            );

            for (const additional_charges_ordering_mode of additional_charges_ordering_modes) {
                for (const ordering_mode of ordering_modes) {
                    if (
                        ordering_mode.restaurant_ordering_mode_id ===
                        additional_charges_ordering_mode.restaurant_ordering_mode_id
                    ) {
                        additional_charges_ordering_mode.display_name = ordering_mode.display_name;
                    }
                }
            }

            setOutletOrderingModes(
                additional_charges_ordering_modes?.length ? additional_charges_ordering_modes : ordering_modes
            );

            return {
                ordering_modes: additional_charges_ordering_modes?.length
                    ? additional_charges_ordering_modes
                    : ordering_modes,
                is_enabled_same_price_details_for_all_ordering_modes: isSameConfig,
                packaging_charge: firstIndexPackagingCharge,
                convenience_charge: firstIndexConvenienceCharge,
                is_convenience_charge_applicable: firstIndexEnableConvenienceCharge,
                is_packaging_charge_applicable: firstIndexEnablePackagingCharge,
            };
        },
        [orderingModes, restaurantOrderingModes]
    );

    const { defaultValues, setDefaultValues, onSuccessCallback } = useOutletFormValues({ updateFormValues });
    let onFormSaveApi = useCallback(
        async (data) => {
            data.convenience_charge = data?.convenience_charge ? parseInt(data?.convenience_charge) : 0;
            data.packaging_charge = data?.packaging_charge ? parseInt(data.packaging_charge) : 0;
            if (data.is_enabled_same_price_details_for_all_ordering_modes) {
                for (const ordering_mode of data.ordering_modes) {
                    ordering_mode.convenience_charge =
                        data.convenience_charge && data.is_convenience_charge_applicable ? data.convenience_charge : 0;
                    ordering_mode.packaging_charge =
                        data.packaging_charge && data.is_packaging_charge_applicable ? data.packaging_charge : 0;
                    ordering_mode.is_packaging_charge_applicable = data.is_packaging_charge_applicable;
                    ordering_mode.is_packaging_charge_applicable = data.is_packaging_charge_applicable
                        ? data.is_packaging_charge_applicable
                        : false;
                    ordering_mode.is_convenience_charge_applicable = data.is_convenience_charge_applicable
                        ? data.is_convenience_charge_applicable
                        : false;
                }
            } else {
                for (const ordering_mode of data.ordering_modes) {
                    ordering_mode.convenience_charge =
                        ordering_mode.convenience_charge && ordering_mode.is_convenience_charge_applicable
                            ? parseInt(ordering_mode.convenience_charge)
                            : 0;

                    ordering_mode.packaging_charge =
                        ordering_mode.packaging_charge && ordering_mode.is_packaging_charge_applicable
                            ? parseInt(ordering_mode.packaging_charge)
                            : 0;

                    ordering_mode.is_packaging_charge_applicable = ordering_mode.is_packaging_charge_applicable
                        ? ordering_mode.is_packaging_charge_applicable
                        : false;
                    ordering_mode.is_convenience_charge_applicable = ordering_mode.is_convenience_charge_applicable
                        ? ordering_mode.is_convenience_charge_applicable
                        : false;
                }
            }

            data.ordering_mode_wise_additional_charges = data.ordering_modes;

            const response = await dispatch(outletSettingsV4('other_charges', data));
            if (response.success) setDefaultValues(updateFormValues(response.outlet));
            return response;
        },
        [dispatch, updateFormValues]
    );

    const { errors, control, setValue, watch } = useFormActionsHandler({
        onFormSaveApi,
        defaultValues,
        onSuccessCallback,
    });

    const is_enabled_same_price_details_for_all_ordering_modes = useWatch({
        control,
        name: 'is_enabled_same_price_details_for_all_ordering_modes',
    });

    const getOrderingModes = useCallback(async () => {
        dispatch(fetchOutletOrderingModes(outletId));
        dispatch(fetchRestaurantOrderingModes());
    }, [dispatch, outletId]);

    useEffect(() => {
        getOrderingModes();
    }, [getOrderingModes, outletId]);

    return (
        <>
            <EuiForm component='form' style={{ minWidth: 700 }}>
                <EuiFlexItem>
                    <CheckBoxField
                        name='is_enabled_same_price_details_for_all_ordering_modes'
                        label='Configure same price for all ordering modes.'
                        control={control}
                        setValue={setValue}
                    />
                </EuiFlexItem>

                <EuiSpacer />

                {outletOrderingModes?.length && !is_enabled_same_price_details_for_all_ordering_modes ? (
                    <>
                        {outletOrderingModes.map((mode, index) => {
                            return (
                                <React.Fragment key={mode.restaurant_ordering_mode_id.toString()}>
                                    <EuiPanel>
                                        <FormGroupDescription
                                            title={restaurantDescriptions?.other_charges?.display_name}
                                            description={restaurantDescriptions?.other_charges?.description}
                                            mode_name={mode.display_name}
                                        >
                                            <CheckBoxField
                                                name={`ordering_modes[${index}].is_packaging_charge_applicable`}
                                                label='Do you want to enable packaging charge?'
                                                control={control}
                                                setValue={setValue}
                                            />
                                            {watch(`ordering_modes[${index}].is_packaging_charge_applicable`) ? (
                                                <>
                                                    <EuiSpacer size='s' />
                                                    <NumberField
                                                        label={`Packaging charge for ${mode.display_name}`}
                                                        errors={errors}
                                                        name={`ordering_modes[${index}].packaging_charge`}
                                                        placeholder='Please enter packaging charge'
                                                        step='any'
                                                        control={control}
                                                        helpText='This will be charged per order.'
                                                    />
                                                </>
                                            ) : null}
                                            <EuiSpacer />
                                            <CheckBoxField
                                                name={`ordering_modes[${index}].is_convenience_charge_applicable`}
                                                label='Do you want to enable convenience charge?'
                                                control={control}
                                                setValue={setValue}
                                            />
                                            {watch(`ordering_modes[${index}].is_convenience_charge_applicable`) ? (
                                                <>
                                                    <EuiSpacer size='s' />
                                                    <NumberField
                                                        label={`Convinience charge for ${mode.display_name}`}
                                                        errors={errors}
                                                        name={`ordering_modes[${index}].convenience_charge`}
                                                        placeholder='Please enter convenience charge'
                                                        step='any'
                                                        control={control}
                                                    />
                                                </>
                                            ) : null}
                                        </FormGroupDescription>
                                    </EuiPanel>
                                    <EuiSpacer />
                                </React.Fragment>
                            );
                        })}
                    </>
                ) : (
                    <EuiPanel>
                        <FormGroupDescription
                            title={restaurantDescriptions?.other_charges?.display_name}
                            description={restaurantDescriptions?.other_charges?.description}
                        >
                            <CheckBoxField
                                name={`is_packaging_charge_applicable`}
                                label='Do you want to enable packaging charge?'
                                control={control}
                                setValue={setValue}
                            />
                            {watch(`is_packaging_charge_applicable`) ? (
                                <>
                                    <EuiSpacer size='s' />{' '}
                                    <NumberField
                                        label='Packaging charge'
                                        errors={errors}
                                        name='packaging_charge'
                                        placeholder='Please enter packaging charge'
                                        step='any'
                                        control={control}
                                        helpText='This will be charged per order.'
                                    />
                                </>
                            ) : null}
                            <EuiSpacer />

                            <CheckBoxField
                                name={`is_convenience_charge_applicable`}
                                label='Do you want to enable convenience charge?'
                                control={control}
                                setValue={setValue}
                            />

                            {watch(`is_convenience_charge_applicable`) ? (
                                <>
                                    <EuiSpacer size='s' />{' '}
                                    <NumberField
                                        label='Convinience charge'
                                        errors={errors}
                                        name='convenience_charge'
                                        placeholder='Please enter convenience charge'
                                        step='any'
                                        control={control}
                                    />
                                </>
                            ) : null}
                        </FormGroupDescription>
                    </EuiPanel>
                )}
            </EuiForm>
        </>
    );
};

export default OtherCharges;
