import { EuiButtonIcon, EuiFlexGroup, EuiFlexItem, EuiText, EuiTextColor } from '@elastic/eui';
import { isEmpty } from 'lodash';
import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

const AddonFormErrors = ({ showError, setShowError, errorMessage, setErrorMessage }) => {
    const {
        formState: { errors },
    } = useFormContext();

    const displayErrorServer = useCallback(() => {
        let errorsList = [];

        if (!isEmpty(errorMessage)) {
            for (var key in errorMessage) {
                errorsList.push(
                    <EuiTextColor key={key} color='danger'>
                        {errorMessage[key]}
                    </EuiTextColor>
                );
            }
        }
        return errorsList;
    }, [errorMessage]);

    const displayErrorMessage = useCallback((errors) => {
        let innerArray = [];
        if (!isEmpty(errors)) {
            for (var key in errors) {
                if (key === 'choices') {
                    for (var choiceKey in errors[key]) {
                        for (var innerValidation in errors[key][choiceKey]) {
                            innerArray.push(
                                <EuiText>
                                    <ul>
                                        <li>
                                            {errors[key][choiceKey][innerValidation].message} -{' '}
                                            {parseInt(choiceKey) + 1}
                                        </li>
                                    </ul>
                                </EuiText>
                            );
                        }
                    }
                } else {
                    innerArray.push(
                        <EuiText>
                            <ul>
                                <li>{errors[key].message}</li>
                            </ul>
                        </EuiText>
                    );
                }
            }
        }
        return innerArray;
    }, []);

    return (
        <>
            {' '}
            {!isEmpty(errors) && showError && (
                <EuiFlexGroup
                    direction='column'
                    style={{ backgroundColor: '#f8e9e9', padding: '15px', margin: '10px' }}
                >
                    <EuiFlexItem
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <EuiTextColor color='danger' style={{ paddingBottom: '10px' }}>
                            <b>Please resolve below validations</b>
                        </EuiTextColor>
                        <EuiButtonIcon
                            color={'warning'}
                            onClick={() => setShowError(false)}
                            iconType='cross'
                            aria-label='cross'
                        />
                    </EuiFlexItem>
                    {displayErrorMessage(errors)}
                </EuiFlexGroup>
            )}
            {!isEmpty(errorMessage) && showError && (
                <EuiFlexGroup
                    direction='column'
                    style={{ backgroundColor: '#f8e9e9', padding: '15px', margin: '10px' }}
                >
                    <EuiFlexItem
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <EuiTextColor color='danger' style={{ paddingBottom: '10px' }}>
                            <b>Please resolve below validations</b>
                        </EuiTextColor>
                        <EuiButtonIcon
                            color={'warning'}
                            onClick={() => {
                                setErrorMessage('');
                                setShowError(false);
                            }}
                            iconType='cross'
                            aria-label='cross'
                        />
                    </EuiFlexItem>
                    <EuiFlexItem>{displayErrorServer()}</EuiFlexItem>
                </EuiFlexGroup>
            )}
        </>
    );
};

export default React.memo(AddonFormErrors);
