import API from '../axios/API';

export const cashToggleForOutlet = (outletId, paymentId, status) => {
    return async (dispatch, getState) => {
        let response;

        try {
            response = await API.patch(
                `restaurants/:restaurantId/outlets/${outletId}/payment-methods/${paymentId}/is-active`,
                {
                    active: status,
                }
            );
        } catch (error) {
            response = error;
        }

        return response;
    };
};
