import React from 'react';
import {
    EuiButton,
    EuiModal,
    EuiModalBody,
    EuiModalFooter,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiOverlayMask,
    EuiBadge,
} from '@elastic/eui';
import { userHasPermission } from '../../helper/auth';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { riderOrderCancel } from '../../api/riderOrderCancel';
import { getLiveOrders } from '../../api/liveOrders/getLiveOrderNewAPI';
import _ from 'lodash';
import API from '../../api/axios/API';

export const RiderInfoModal = (props) => {
    const { order, closeRiderModal } = props;
    const permissions = useSelector((state) => state.permission.permission);

    const dispatch = useDispatch();

    const selectedRestaurantId = useSelector((state) => state.auth.selectedRestaurantId);

    const cancelPidgeOrder = async () => {
        try {
            const response = await API.post(`restaurants/${selectedRestaurantId}/pidge/cancel-order`, {
                order_id: order?.order_id,
            });

            if (response?.success) {
                dispatch(getLiveOrders());
                closeRiderModal();
            } else {
                alert('Error in cancelling WeFast order');
            }
        } catch (error) {
            console.log('error : ', error);
        }
    };

    const onCancelOrder = () => {
        if (!_.isEmpty(order?.pidge_order)) {
            cancelPidgeOrder(order);
            return;
        }

        if (order.rider_order) {
            props.unAssignRider(order.order_id);
        } else {
            if (order.wefast_order) {
                dispatch(riderOrderCancel('wefast', order.wefast_order.order_id)).then((response) => {
                    if (response.success) {
                        dispatch(getLiveOrders());
                        closeRiderModal();
                    } else {
                        alert('Error in cancelling WeFast order');
                    }
                });
            } else if (order.dunzo_order) {
                dispatch(riderOrderCancel('dunzo', order.dunzo_order.order_id)).then((response) => {
                    if (response.success) {
                        dispatch(getLiveOrders());
                        closeRiderModal();
                    } else {
                        alert('Error in cancelling Dunzo order');
                    }
                });
            } else if (order.scout_order) {
                dispatch(riderOrderCancel('scout', order.scout_order.order_id)).then((response) => {
                    if (response.success) {
                        dispatch(getLiveOrders());
                        closeRiderModal();
                    } else {
                        alert('Error in cancelling Scout order');
                    }
                });
            }
        }
    };

    const thirdPartyRiderInfo = () => {
        let response;
        if (order.elt_order) {
            if (order.elt_order.rider_name) {
                response = (
                    <table>
                        <tr>
                            <td>ELT order id </td>
                            <td> : </td>
                            <td>
                                <b>{order.elt_order.elt_order_id}</b>
                            </td>
                        </tr>
                        <tr>
                            <td>Rider Name </td>
                            <td> : </td>
                            <td>
                                <b>{order.elt_order.rider_name}</b>
                            </td>
                        </tr>
                        <tr>
                            <td>Rider Number </td>
                            <td> : </td>
                            <td>
                                <b>{order.elt_order.rider_phone_number}</b>
                            </td>
                        </tr>
                        <tr>
                            <td>Discount amount </td>
                            <td> : </td>
                            <td>
                                <b>{order.elt_order.coupon_discount}</b>
                            </td>
                        </tr>
                        <tr>
                            <td>Total amount </td>
                            <td> : </td>
                            <td>
                                <b>{order.elt_order.fee_amount}</b>
                            </td>
                        </tr>
                    </table>
                );
            } else {
                response = (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <table>
                            <tr>
                                <td>ELT order id </td>
                                <td> : </td>
                                <td>
                                    <b>{order.elt_order.elt_order_id}</b>
                                </td>
                            </tr>
                            <tr>
                                <td>Discount amount </td>
                                <td> : </td>
                                <td>
                                    <b>{order.elt_order.coupon_discount}</b>
                                </td>
                            </tr>
                            <tr>
                                <td>Total amount </td>
                                <td> : </td>
                                <td>
                                    <b>{order.elt_order.fee_amount}</b>
                                </td>
                            </tr>
                        </table>
                        <div style={{ paddingTop: '10px' }}>
                            <b>Waiting for elt to assign rider..</b>
                        </div>
                    </div>
                );
            }
        } else if (order.dunzo_order) {
            if (order.dunzo_order.rider_name) {
                response = (
                    <table>
                        <tr>
                            <td>Dunzo order id </td>
                            <td> : </td>
                            <td>
                                <b>{order.dunzo_order.order_id}</b>
                            </td>
                        </tr>
                        <tr>
                            <td>Rider Name </td>
                            <td> : </td>
                            <td>
                                <b>{order.dunzo_order.rider_name}</b>
                            </td>
                        </tr>
                        <tr>
                            <td>Rider Number </td>
                            <td> : </td>
                            <td>
                                <b>{order.dunzo_order.rider_phone_number}</b>
                            </td>
                        </tr>
                        <tr>
                            <td>Total time </td>
                            <td> : </td>
                            <td>
                                <b>{order.dunzo_order.total_time}</b>
                            </td>
                        </tr>
                        <tr>
                            <td>Total amount </td>
                            <td> : </td>
                            <td>
                                <b>{order.dunzo_order.price}</b>
                            </td>
                        </tr>
                        <tr>
                            <td>COD amount collected </td>
                            <td> : </td>
                            <td>
                                <b>{order.dunzo_order.cod_amount_collected}</b>
                            </td>
                        </tr>
                    </table>
                );
            } else {
                response = (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <table>
                            <tr>
                                <td>Dunzo order id </td>
                                <td> : </td>
                                <td>
                                    <b>{order.dunzo_order.order_id}</b>
                                </td>
                            </tr>
                            <tr>
                                <td>Estimated amount </td>
                                <td> : </td>
                                <td>
                                    <b>{order.dunzo_order.estimated_price}</b>
                                </td>
                            </tr>
                        </table>
                        <div style={{ paddingTop: '10px' }}>
                            <b>Waiting for dunzo to assign rider..</b>
                        </div>
                    </div>
                );
            }
        } else if (order.wefast_order) {
            response = (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <table>
                        <tr>
                            <td>WeFast order id </td>
                            <td> : </td>
                            <td>
                                <b> {order.wefast_order.order_id}</b>
                            </td>
                        </tr>
                        <tr>
                            <td>Rider Name </td>
                            <td> : </td>
                            <td>
                                <b> {order.wefast_order.rider_name}</b>
                            </td>
                        </tr>
                        <tr>
                            <td>Rider Number </td>
                            <td> : </td>
                            <td>
                                <b> {order.wefast_order.rider_phone_number}</b>
                            </td>
                        </tr>

                        <tr>
                            <td>Pick up time </td>
                            <td> : </td>
                            <td>
                                <b> {moment(order.wefast_order.pickup).format('DD-MM-YYYY HH:mm:ss')}</b>
                            </td>
                        </tr>

                        <tr>
                            <td>Drop off time </td>
                            <td> : </td>
                            <td>
                                <b> {moment(order.wefast_order.dropoff).format('DD-MM-YYYY HH:mm:ss')}</b>
                            </td>
                        </tr>

                        <tr>
                            <td>Total amount </td>
                            <td> : </td>
                            <td>
                                <b> {order.wefast_order.delivery_fee_amount}</b>
                            </td>
                        </tr>
                    </table>
                    {!order.wefast_order.rider_name && (
                        <div style={{ paddingTop: '10px' }}>
                            <b>Waiting for WeFast to assign rider..</b>
                        </div>
                    )}
                </div>
            );
        } else if (order.wefast_order) {
            response = (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <table>
                        <tr>
                            <td>Scout order id </td>
                            <td> : </td>
                            <td>
                                <b> {order.wefast_order.order_id}</b>
                            </td>
                        </tr>
                        <tr>
                            <td>Rider Name </td>
                            <td> : </td>
                            <td>
                                <b> {order.wefast_order.rider_name}</b>
                            </td>
                        </tr>
                        <tr>
                            <td>Rider Number </td>
                            <td> : </td>
                            <td>
                                <b> {order.wefast_order.rider_phone_number}</b>
                            </td>
                        </tr>

                        <tr>
                            <td>Drop off time </td>
                            <td> : </td>
                            <td>
                                <b> {moment(order.wefast_order.pickup).format('DD-MM-YYYY HH:mm:ss')}</b>
                            </td>
                        </tr>

                        <tr>
                            <td>Total amount </td>
                            <td> : </td>
                            <td>
                                <b> {order.scout_runner_order.estimated_price}</b>
                            </td>
                        </tr>
                    </table>
                    {!order.scout_runner_order.rider_name && (
                        <div style={{ paddingTop: '10px' }}>
                            <b>Waiting for Scout to assign rider..</b>
                        </div>
                    )}
                </div>
            );
        } else if (order.ahoy_order) {
            response = (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <table>
                        <tr>
                            <td>Ahoy order id </td>
                            <td> : </td>
                            <td>
                                <b> {order.ahoy_order.order_id}</b>
                            </td>
                        </tr>
                        <tr>
                            <td>Rider Name </td>
                            <td> : </td>
                            <td>
                                <b> {order.ahoy_order.rider_name}</b>
                            </td>
                        </tr>
                        <tr>
                            <td>Rider Number </td>
                            <td> : </td>
                            <td>
                                <b> {moment(order.wefast_order.dropoff).format('DD-MM-YYYY HH:mm:ss')}</b>
                            </td>
                        </tr>

                        <tr>
                            <td>Total amount </td>
                            <td> : </td>
                            <td>
                                <b> {order.wefast_order.delivery_fee_amount}</b>
                            </td>
                        </tr>
                    </table>
                    {!order.wefast_order.rider_name && (
                        <div style={{ paddingTop: '10px' }}>
                            <b>Waiting for WeFast to assign rider..</b>
                        </div>
                    )}
                </div>
            );
        }
        return response;
    };

    return (
        <>
            <EuiOverlayMask>
                <EuiModal
                    onClose={() => {
                        closeRiderModal();
                    }}
                >
                    <EuiModalHeader>
                        <EuiModalHeaderTitle>Rider Information</EuiModalHeaderTitle>
                    </EuiModalHeader>

                    <EuiModalBody>
                        <>
                            {order.rider ? (
                                <table>
                                    <tr>
                                        <td>Rider Name </td>
                                        <td> : </td>
                                        <td>
                                            <b>
                                                {order.rider.first_name} &nbsp; {order.rider.middle_name} &nbsp;{' '}
                                                {order.rider.last_name}
                                            </b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Mobile number </td>
                                        <td> : </td>
                                        <td>
                                            <b>{order.rider.mobile_number}</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Address </td>
                                        <td> : </td>
                                        <td>
                                            <b>{order.rider.address}</b>
                                        </td>
                                    </tr>
                                    {order.rider_order.payment_mode === 'cash_on_delivery' ? (
                                        <tr>
                                            <td>Rider to Restaurant settelment </td>
                                            <td> : </td>
                                            <td>
                                                <EuiBadge
                                                    className='custom-badge'
                                                    color='danger'
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <b style={{ color: 'white' }}>
                                                        {order.rider_order.rider_restaurant_cod_settlement}
                                                    </b>
                                                </EuiBadge>
                                            </td>
                                        </tr>
                                    ) : (
                                        <tr>
                                            <td>Restaurant to Rider settelment </td>
                                            <td> : </td>
                                            <td>
                                                <EuiBadge
                                                    className='custom-badge'
                                                    color='secondary'
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '30%',
                                                    }}
                                                >
                                                    <b style={{ color: 'white' }}>
                                                        {order.rider_order.rider_restaurant_online_settlement}
                                                    </b>
                                                </EuiBadge>
                                            </td>
                                        </tr>
                                    )}

                                    <tr>
                                        <td>Rider accepted at </td>
                                        <td> : </td>
                                        <td>
                                            <b>{order.status.rider_accept_at}</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Rider reached restaurant at </td>
                                        <td> : </td>
                                        <td>
                                            <b>{order.status.rider_reached_restaurant_at}</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Rider picked up at </td>
                                        <td> : </td>
                                        <td>
                                            <b>{order.status.rider_picked_up_at}</b>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Rider reached location at </td>
                                        <td> : </td>
                                        <td>
                                            <b>{order.status.rider_reached_to_location_at}</b>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Rider delivered at </td>
                                        <td> : </td>
                                        <td>
                                            <b>{order.status.rider_delivered_at}</b>
                                        </td>
                                    </tr>
                                </table>
                            ) : (
                                thirdPartyRiderInfo()
                            )}
                        </>
                    </EuiModalBody>

                    <EuiModalFooter>
                        <EuiButton onClick={() => closeRiderModal()} fill>
                            Close
                        </EuiButton>
                        {userHasPermission(permissions, '/live-orders', 'write') &&
                            !order.elt_order &&
                            !props.isPast && (
                                <EuiButton fill color='danger' onClick={() => onCancelOrder()}>
                                    Cancel rider assignment
                                </EuiButton>
                            )}
                    </EuiModalFooter>
                </EuiModal>
            </EuiOverlayMask>
        </>
    );
};
