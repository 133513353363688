import {
    EuiButtonIcon,
    EuiFlexGrid,
    EuiFlexGroup,
    EuiFlexItem,
    EuiLink,
    EuiLoadingSpinner,
    EuiPopover,
    EuiTitle,
} from '@elastic/eui';
import moment from 'moment';
import React, { useMemo, useState } from 'react';

const CommnlyUsedDateOptions = ({ setStartDate, setEndDate, isLoading }) => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const commonlyUsedOptions = useMemo(() => {
        return [
            {
                id: 1,
                title: 'Today',
                onClick: () => {
                    setStartDate(moment());
                    setEndDate(moment());
                },
            },
            {
                id: 2,
                title: 'Yesterday',
                onClick: () => {
                    setStartDate(moment().add(-1, 'days'));
                    setEndDate(moment().add(-1, 'days'));
                },
            },
            {
                id: 3,
                title: 'This week',
                onClick: () => {
                    setStartDate(moment().startOf('week'));
                    setEndDate(moment().endOf('week'));
                },
            },
            {
                id: 4,
                title: 'This month',
                onClick: () => {
                    setStartDate(moment().startOf('month'));
                    setEndDate(moment().endOf('month'));
                },
            },
            {
                id: 5,
                title: 'Week to date',
                onClick: () => {
                    setStartDate(moment().startOf('week'));
                    setEndDate(moment());
                },
            },
            {
                id: 6,
                title: 'Month to date',
                onClick: () => {
                    setStartDate(moment().startOf('month'));
                    setEndDate(moment());
                },
            },
            {
                id: 7,
                title: 'Same day last week',
                onClick: () => {
                    setStartDate(moment().subtract(7, 'days'));
                    setEndDate(moment().subtract(7, 'days'));
                },
            },
            {
                id: 8,
                title: 'Same date to last month',
                onClick: () => {
                    setStartDate(moment().subtract(1, 'month'));
                    setEndDate(moment());
                },
            },
        ];
    }, [setEndDate, setStartDate]);

    const onButtonClick = () => setIsPopoverOpen((isPopoverOpen) => !isPopoverOpen);
    const closePopover = () => setIsPopoverOpen(false);

    return (
        <>
            <EuiPopover
                button={
                    <EuiButtonIcon
                        size='m'
                        display='base'
                        iconType={'calendar'}
                        aria-label={'calendar'}
                        onClick={onButtonClick}
                    />
                }
                isOpen={isPopoverOpen}
                closePopover={closePopover}
            >
                <EuiFlexGroup direction='column' style={{ minWidth: 300 }} gutterSize='m'>
                    <EuiFlexItem>
                        <EuiFlexGroup gutterSize='s' alignItems='center' justifyContent='flexStart'>
                            <EuiFlexItem grow={false}>
                                <EuiTitle size='xxs'>
                                    <h3>Commonly used</h3>
                                </EuiTitle>
                            </EuiFlexItem>
                            {isLoading ? (
                                <EuiFlexItem grow={false}>
                                    <EuiLoadingSpinner size='s' />
                                </EuiFlexItem>
                            ) : null}
                        </EuiFlexGroup>
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <EuiFlexGrid columns={2} direction='column' gutterSize='m' style={{ maxWidth: 300 }}>
                            {commonlyUsedOptions.map((option) => {
                                return (
                                    <EuiFlexItem grow={false} key={option.id.toString()}>
                                        <EuiLink onClick={option.onClick}>{option.title}</EuiLink>
                                    </EuiFlexItem>
                                );
                            })}
                        </EuiFlexGrid>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiPopover>
        </>
    );
};

export default CommnlyUsedDateOptions;
