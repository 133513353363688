export const formatedData = (timeSlotData) => {
    let formatedSscheduleMap = {};
    for (let scheduleElement of timeSlotData) {
        if (formatedSscheduleMap.hasOwnProperty(scheduleElement.time_slots.day_of_week)) {
            formatedSscheduleMap[scheduleElement.time_slots.day_of_week].push({
                start_time: scheduleElement.time_slots.start_time,
                end_time: scheduleElement.time_slots.end_time,
            });
        } else {
            formatedSscheduleMap[scheduleElement.time_slots.day_of_week] = [
                {
                    start_time: scheduleElement.time_slots.start_time,
                    end_time: scheduleElement.time_slots.end_time,
                },
            ];
        }
    }
    return formatedSscheduleMap;
};
