import React from 'react';
import { EuiText, htmlIdGenerator } from '@elastic/eui';
import moment from 'moment';

export const TimmingDetails = (props) => {
    console.log(`props.order`, props.order);
    return (
        <div key={htmlIdGenerator()()} style={{ paddingLeft: '3px' }}>
            {/* <EuiCard
                display="plain"
                titleSize="xs"
                layout="horizontal"
                paddingSize="s"
                title={
                    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'row'}}>
                        <div style={{marginLeft: '10px'}}><span>Order Timing</span></div>
                    </div>
                }
                description={ */}
            <div style={{ paddingLeft: '3px' }}>
                <EuiText size='xs'>
                    <h3>Order Timing</h3>
                </EuiText>
            </div>
            <table style={{ width: '100%' }}>
                <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                    <td style={{ borderRight: '1px solid #D3DAE6' }}>
                        <EuiText size='s'>Order placed at</EuiText>
                    </td>
                    <td style={{ borderRight: '1px solid #D3DAE6' }}>
                        <EuiText size='s'>
                            {props.order.status.placed_at &&
                                moment(props.order.status.placed_at).format('DD-MM-YYYY hh:mm:ss A')}
                        </EuiText>
                    </td>
                </tr>

                <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                    <td style={{ borderRight: '1px solid #D3DAE6' }}>
                        <EuiText size='s'>Restaurant accepted at</EuiText>
                    </td>
                    <td style={{ borderRight: '1px solid #D3DAE6' }}>
                        <EuiText size='s'>
                            {props.order.status.restaurant_accepted_at &&
                                moment(props.order.status.restaurant_accepted_at).format('DD-MM-YYYY hh:mm:ss A')}
                        </EuiText>
                    </td>
                </tr>

                <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                    <td style={{ borderRight: '1px solid #D3DAE6' }}>
                        <EuiText size='s'>Restaurant ready at</EuiText>
                    </td>
                    <td style={{ borderRight: '1px solid #D3DAE6' }}>
                        <EuiText size='s'>
                            {props.order.status.restaurant_ready_at &&
                                moment(props.order.status.restaurant_ready_at).format('DD-MM-YYYY hh:mm:ss A')}
                        </EuiText>
                    </td>
                </tr>

                {props.order.status?.restaurant_cancelled_at && (
                    <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                        <td style={{ borderRight: '1px solid #D3DAE6' }}>
                            <EuiText size='s'>Restaurant cancelled at</EuiText>
                        </td>
                        <td style={{ borderRight: '1px solid #D3DAE6' }}>
                            <EuiText size='s'>
                                {props.order.status.restaurant_cancelled_at &&
                                    moment(props.order.status.restaurant_cancelled_at).format('DD-MM-YYYY hh:mm:ss A')}
                            </EuiText>
                        </td>
                    </tr>
                )}

                {props.order.ordering_mode === 'delivery' ? (
                    <>
                        <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                            <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                <EuiText size='s'>Rider accepted at</EuiText>
                            </td>
                            <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                <EuiText size='s'>
                                    {props.order.status.rider_accept_at &&
                                        moment(props.order.status.rider_accept_at).format('DD-MM-YYYY hh:mm:ss A')}
                                </EuiText>
                            </td>
                        </tr>

                        <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                            <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                <EuiText size='s'>Rider reached restaurant at</EuiText>
                            </td>
                            <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                <EuiText size='s'>
                                    {props.order.status.rider_reached_restaurant_at &&
                                        moment(props.order.status.rider_reached_restaurant_at).format(
                                            'DD-MM-YYYY hh:mm:ss A'
                                        )}
                                </EuiText>
                            </td>
                        </tr>

                        <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                            <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                <EuiText size='s'>Rider picked up at</EuiText>
                            </td>
                            <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                <EuiText size='s'>
                                    {props.order.status.rider_picked_up_at &&
                                        moment(props.order.status.rider_picked_up_at).format('DD-MM-YYYY hh:mm:ss A')}
                                </EuiText>
                            </td>
                        </tr>

                        <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                            <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                <EuiText size='s'>Rider reached location at</EuiText>
                            </td>
                            <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                <EuiText size='s'>
                                    {props.order.status.rider_reached_to_location_at &&
                                        moment(props.order.status.rider_reached_to_location_at).format(
                                            'DD-MM-YYYY hh:mm:ss A'
                                        )}
                                </EuiText>
                            </td>
                        </tr>

                        <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                            <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                <EuiText size='s'>Rider delivered at</EuiText>
                            </td>
                            <td style={{ borderRight: '1px solid #D3DAE6' }}>
                                <EuiText size='s'>
                                    {props.order.status.rider_delivered_at &&
                                        moment(props.order.status.rider_delivered_at).format('DD-MM-YYYY hh:mm:ss A')}
                                </EuiText>
                            </td>
                        </tr>
                    </>
                ) : (
                    <tr style={{ borderBottom: '1px solid #D3DAE6' }}>
                        <td style={{ borderRight: '1px solid #D3DAE6' }}>
                            <EuiText size='s'>Customer picked up at</EuiText>
                        </td>
                        <td style={{ borderRight: '1px solid #D3DAE6' }}>
                            <EuiText size='s'>
                                {props.order.ordering_mode === 'dine_in' && props.order.status.delivered_at ? (
                                    <>{moment(props.order.status.delivered_at).format('DD-MM-YYYY hh:mm:ss A')}</>
                                ) : (
                                    <>
                                        {props.order.status.customer_picked_up_at &&
                                            moment(props.order.status.customer_picked_up_at).format(
                                                'DD-MM-YYYY hh:mm:ss A'
                                            )}
                                    </>
                                )}
                            </EuiText>
                        </td>
                    </tr>
                )}
            </table>
            {/* }
            /> */}
        </div>
    );
};
