import {
    EuiButton,
    EuiComboBox,
    EuiDragDropContext,
    euiDragDropReorder,
    EuiDroppable,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow,
    EuiIconTip,
    EuiSelect,
    EuiText,
    EuiTextColor,
} from '@elastic/eui';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import AddOnCardSequence from '../../../../../components/menuComponentsRefactorCopy/menuEditorTab/addOnCardSequence';
import ItemDetailsCollaspibleNav from './ItemDetailsCollaspibleNav';
import { ReactComponent as ItemAddonGroup } from '../../../../../assets/img/svg/ItemAddonGroup.svg';
import { useDispatch } from 'react-redux';
import { SET_ADDON_ITEM_EDIT_SELECTED_LIST } from '../../../../../reduxStore/types/menu';
import VariantAddonLinkModal from '../../../../../components/menuComponentsRefactorCopy/menuEditorTab/varaintAddonLinkModal';
import { fetchAddons } from '../../../../../components/menuComponentsRefactorCopy/catalogue/Api/FetchCatalogueInfo';
import { SET_ADDONS } from '../../../../../components/menuComponentsRefactorCopy/Store/MenuTypes';
import Select from 'react-select';

import _, { debounce } from 'lodash';
import { ClipLoader } from 'react-spinners';

const ItemAddonGroups = ({ variantConditionForPrice }) => {
    const editItem = useSelector((state) => state.menuReducer.activeItemDetails.details);
    const selectedItemEditAddOnList = useSelector((state) => state.menu.selectedItemEditAddOnList);
    const isOutletMenu = useSelector((state) => state.menuReducer.isOutletMenu);
    const themeMode = useSelector((state) => state.theme.theme);
    const addOnList = useSelector((state) => state.menuReducer.addons);
    const [addonOptionsList, setAddonOptionsList] = useState([]);
    const languageId = useSelector((state) => state.language.languageId);
    const dispatch = useDispatch();

    const [isNavOpen, setIsNavOpen] = useState(false);
    const [addonSelected, setAddonSelected] = useState([]);
    const [addonOptions, setAddonOptions] = useState([]);
    const [varaintAddonLinkModalState, setVaraintAddonLinkModalState] = useState(false);

    const { control } = useFormContext();

    const onDragEnd = ({ source, destination }) => {
        if (source && destination) {
            const items = euiDragDropReorder(selectedItemEditAddOnList, source.index, destination.index);

            dispatch({
                type: SET_ADDON_ITEM_EDIT_SELECTED_LIST,
                payload: {
                    selectedItemEditAddOnList: items,
                },
            });
        }
    };

    const onChangeAddon = (value) => {
        const parsedValue = parseInt(value.value);
        let selectedList = [...selectedItemEditAddOnList];
        let objectExist = false;
        parsedValue !== 0 &&
            addonOptions?.forEach((addon) => {
                if (parsedValue === addon.value) {
                    selectedItemEditAddOnList.forEach((addon) => {
                        if (addon.addon_group_id === parsedValue) {
                            objectExist = true;
                        }
                    });
                    if (!objectExist) {
                        selectedList.push(addon.record);
                        dispatch({
                            type: SET_ADDON_ITEM_EDIT_SELECTED_LIST,
                            payload: {
                                selectedItemEditAddOnList: selectedList,
                            },
                        });
                    }
                }
            });

        setAddonSelected(value);
    };

    const onAddonDelete = (index) => {
        let addonArray = [...selectedItemEditAddOnList];
        addonArray.splice(index, 1);
        dispatch({
            type: SET_ADDON_ITEM_EDIT_SELECTED_LIST,
            payload: {
                selectedItemEditAddOnList: addonArray,
            },
        });
    };
    const [isLoading, setIsLoading] = useState(false);
    const [lastPage, setLastPage] = useState(0);
    const [currentPage, setcurrentPage] = useState(1);

    const fetchMoreData = async ({ nextpage, keyword }) => {
        try {
            setIsLoading(true);
            const response = await fetchAddons({ pageNumber: nextpage ?? currentPage, keyword: keyword ?? '' });
            if (response) {
                dispatch({
                    type: SET_ADDONS,
                    payload: response.restaurant_addon_groups.data,
                });
                setAddonOptionsList((perviosuState) => [...perviosuState, ...response.restaurant_addon_groups.data]);
                setLastPage(response.restaurant_addon_groups.last_page);
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    };
    const [query, setquery] = useState('');
    const handleScroll = () => {
        if (currentPage == lastPage) {
            setcurrentPage(lastPage);
            return;
        }

        setcurrentPage((prevPage) => prevPage + 1);
        const nextpage = currentPage + 1;
        fetchMoreData({ keyword: query, nextpage: nextpage });
    };

    const delayedCategoriesSearchQuery = useCallback(
        debounce(async (keyword) => {
            setcurrentPage(1);
            setquery(keyword);
            setAddonOptionsList([]);
            fetchMoreData({ keyword: keyword });
        }, 1000)
    );
    useEffect(() => {
        if (addonOptionsList?.length) {
            let addOnArray = [{ value: 0, label: 'Select addon', record: {} }];
            addonOptionsList?.map((addon) =>
                addOnArray.push({
                    value: addon.addon_group_id,
                    label: `${addon?.translations?.group_name[languageId]} - ${addon?.internal_name}`,
                    record: addon,
                })
            );
            if (addOnArray.length) {
                setAddonOptions(addOnArray);
            }
        }
    }, [addonOptionsList, languageId, query]);
    const CustomLoadingIndicator = () => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <ClipLoader color='#2196F3' cssOverride={{ marginRight: 10 }} loading size={20} speedMultiplier={3} />
        </div>
    );
    return (
        <>
            {varaintAddonLinkModalState && (
                <VariantAddonLinkModal
                    isOutletMenu={isOutletMenu}
                    control={control}
                    setVaraintAddonLinkModalState={setVaraintAddonLinkModalState}
                    editItem={editItem}
                    addonList={addonOptionsList}
                />
            )}
            <ItemDetailsCollaspibleNav
                cardTitle='Link Addon Groups'
                cardDescription={
                    <EuiFlexGroup wrap responsive direction='row' alignItems='center' gutterSize='xs'>
                        {!selectedItemEditAddOnList?.length ? (
                            <EuiText size='xs'>Add-on groups for this items</EuiText>
                        ) : (
                            <>
                                {!editItem?.variants?.length ? (
                                    <EuiFlexItem grow={false}>
                                        <EuiText size='xs'>Linekd Groups: </EuiText>
                                    </EuiFlexItem>
                                ) : null}
                                <EuiFlexItem grow={false}>
                                    <EuiFlexGroup wrap gutterSize='xs'>
                                        {editItem?.variants?.length ? (
                                            <EuiFlexItem grow={false} style={{ minWidth: '25%' }}>
                                                <EuiText size='xs' style={{ display: 'inline' }}>
                                                    {editItem?.variants?.[0]?.options?.[0]?.addons?.length
                                                        ? 'Addons are linked to item variants'
                                                        : 'Link addons to item variants'}
                                                </EuiText>
                                            </EuiFlexItem>
                                        ) : (
                                            <>
                                                {selectedItemEditAddOnList.map((item, index) => {
                                                    return (
                                                        <EuiFlexItem
                                                            grow={false}
                                                            key={item?.item_id?.toString() + index.toString()}
                                                            style={{ minWidth: '25%' }}
                                                        >
                                                            <EuiText size='xs' style={{ display: 'inline' }}>
                                                                {item.internal_name}
                                                                {selectedItemEditAddOnList?.length !== index + 1
                                                                    ? ',  '
                                                                    : null}
                                                            </EuiText>
                                                        </EuiFlexItem>
                                                    );
                                                })}
                                            </>
                                        )}
                                    </EuiFlexGroup>
                                </EuiFlexItem>
                            </>
                        )}
                    </EuiFlexGroup>
                }
                isNavOpen={isNavOpen}
                setIsNavOpen={setIsNavOpen}
                fetchAddons={fetchMoreData}
                toggleText='Edit Item Pricing'
                icon={<ItemAddonGroup fill={themeMode === 'light' ? 'black' : 'white'} />}
            >
                <EuiFlexItem style={{ paddingLeft: '10px' }}>
                    <EuiFlexItem>
                        <EuiText size='s'>
                            <span>You can offer customisation options like toppings, extras, addons for customer.</span>
                        </EuiText>
                        <EuiText size='s'>
                            <span>
                                You can also define if customer selction of these options is optional or mandatory.
                            </span>
                        </EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            padding: '15px 0px',
                        }}
                    >
                        <EuiTextColor
                            style={{
                                padding: '1.5px 4px 0 0',
                            }}
                            size='s'
                            color='secondary'
                        >
                            What's this{' '}
                        </EuiTextColor>

                        <EuiIconTip type='questionInCircle' content={<span>This is AddOns for Item</span>} />
                    </EuiFlexItem>

                    {/* Link variant with addons */}

                    {!variantConditionForPrice() ? (
                        <EuiFlexItem style={{ margin: '10px 0' }}>
                            <EuiButton disabled={isOutletMenu} onClick={() => setVaraintAddonLinkModalState(true)}>
                                Link Addon with Varaint
                            </EuiButton>
                        </EuiFlexItem>
                    ) : (
                        <>
                            <EuiFlexItem>
                                <EuiFormRow label='Add on Groups' fullWidth={true}>
                                    <Controller
                                        render={() => (
                                            <Select
                                                options={addonOptions}
                                                onMenuScrollToBottom={() => handleScroll()}
                                                onChange={(e) => onChangeAddon(e)}
                                                onInputChange={(e) => delayedCategoriesSearchQuery(e)}
                                                // components={{ MenuList: VirtualizedList }}
                                                isLoading={isLoading}
                                                filterOption={false}
                                                components={{ LoadingIndicator: CustomLoadingIndicator }}
                                                maxMenuHeight={250}
                                                menuPortalTarget={typeof window !== 'undefined' ? document.body : null}
                                                styles={{
                                                    menuPortal: (provided) => ({
                                                        ...provided,
                                                        zIndex: 9999,
                                                        backgroundColor: 'white',
                                                    }),
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        zIndex: 9999,
                                                        top: '100%',
                                                        backgroundColor: 'white',
                                                    }),
                                                }}
                                            />
                                        )}
                                        name='group_name'
                                        fullWidth={true}
                                        control={control}
                                    />
                                </EuiFormRow>
                            </EuiFlexItem>

                            <EuiFlexItem
                                style={{
                                    flexDirection: 'row',
                                    overflowY: 'auto',
                                    width: '100%',
                                }}
                                className='scrollbar-sm'
                            >
                                <EuiDragDropContext onDragEnd={onDragEnd}>
                                    <EuiDroppable
                                        droppableId='CUSTOM_HANDLE_DROPPABLE_AREA'
                                        spacing='m'
                                        withPanel
                                        direction='horizontal'
                                        style={{
                                            display: 'flex',
                                            width: '35vw',
                                            heigth: '200px',
                                            overflow: 'auto',
                                        }}
                                    >
                                        {selectedItemEditAddOnList?.length ? (
                                            <AddOnCardSequence
                                                addOnList={selectedItemEditAddOnList}
                                                addOnDelete={onAddonDelete}
                                                disabled={isOutletMenu}
                                            />
                                        ) : (
                                            <EuiText> No AddOns Found</EuiText>
                                        )}
                                    </EuiDroppable>
                                </EuiDragDropContext>
                            </EuiFlexItem>
                        </>
                    )}
                </EuiFlexItem>
            </ItemDetailsCollaspibleNav>
        </>
    );
};

export default React.memo(ItemAddonGroups);
