import { htmlIdGenerator } from '@elastic/eui';
import { SET_TOAST_STATE } from '../reduxStore/types/Global/Global';

export const toastSuccessMessage = (message, dispatch) => {
    dispatch({
        type: SET_TOAST_STATE,
        payload: {
            title: message,
            color: 'success',
            id: htmlIdGenerator()(),
        },
    });
};

export const toastsErrorMessage = (message, dispatch) => {
    dispatch({
        type: SET_TOAST_STATE,
        payload: {
            title: message,
            color: 'danger',
            iconType: 'help',
            id: htmlIdGenerator()(),
        },
    });
};
