import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { EuiFlexItem, EuiFlexGroup, EuiFormRow, EuiDatePicker, EuiRadioGroup, EuiSpacer } from '@elastic/eui';
import { MENU_DISCOUNT } from '../OfferTypes/OfferTypes';
import NumberField from '../../../../components/Form/NumberField';
import FormRadioGroup from '../../../../components/Form/FormRadioGroup';

const ValidityAndAcceptibility = ({ selectedOfferType }) => {
    const {
        control,
        watch,
        setValue,
        formState: { errors },
    } = useFormContext();

    const perpetual_offer = useWatch({
        control,
        name: 'perpetual_offer',
    });

    return (
        <>
            {selectedOfferType !== MENU_DISCOUNT && (
                <>
                    <EuiFlexGroup>
                        <EuiFlexItem>
                            <EuiFlexGroup alignItems='center'>
                                <EuiFlexItem grow={false}>
                                    <EuiFormRow isInvalid={errors.max_usage} fullWidth={true}>
                                        <Controller
                                            render={() => (
                                                <EuiRadioGroup
                                                    options={[
                                                        {
                                                            id: 'not_set_coupen_limit',
                                                            label: 'Do not set a limit',
                                                        },
                                                        {
                                                            id: 'set_coupen_limit',
                                                            label: 'Set a limit',
                                                        },
                                                    ]}
                                                    idSelected={
                                                        watch('max_usage_count')
                                                            ? 'set_coupen_limit'
                                                            : 'not_set_coupen_limit'
                                                    }
                                                    onChange={(id) =>
                                                        setValue(
                                                            'max_usage_count',
                                                            id === 'not_set_coupen_limit' ? false : true,
                                                            { shouldDirty: true }
                                                        )
                                                    }
                                                    legend={{
                                                        children: (
                                                            <span>
                                                                Decide the number of times the coupon can be used in
                                                                total.
                                                            </span>
                                                        ),
                                                    }}
                                                />
                                            )}
                                            name='max_usage_count'
                                            defaultValue={true}
                                            control={control}
                                            fullWidth={true}
                                        />
                                    </EuiFormRow>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                            <EuiSpacer size='s' />
                            {watch('max_usage_count') && (
                                <NumberField
                                    label='Number of coupen are you want to provide'
                                    isInvalid={errors.number_of_coupen_available}
                                    errors={errors}
                                    name='number_of_coupen_available'
                                    placeholder='Enter number of coupen available'
                                    rules={{
                                        required: 'Please enter number of coupen',
                                        validate: (value) => value >= 1 || 'Please enter value must be grater than 0',
                                    }}
                                    step='any'
                                    min={1}
                                    control={control}
                                />
                            )}
                        </EuiFlexItem>
                    </EuiFlexGroup>
                    <EuiFlexGroup>
                        <EuiFlexItem>
                            <EuiFlexGroup alignItems='center'>
                                <EuiFlexItem grow={false}>
                                    <EuiFormRow isInvalid={errors.max_usage} fullWidth={true}>
                                        <Controller
                                            render={() => (
                                                <EuiRadioGroup
                                                    options={[
                                                        {
                                                            id: 'not_set_limit_for_coupen_user',
                                                            label: 'No limit',
                                                        },
                                                        {
                                                            id: 'set_limit_for_coupen_user',
                                                            label: 'Set a limit',
                                                        },
                                                    ]}
                                                    idSelected={
                                                        watch('max_usage_count_per_user')
                                                            ? 'set_limit_for_coupen_user'
                                                            : 'not_set_limit_for_coupen_user'
                                                    }
                                                    onChange={(id) =>
                                                        setValue(
                                                            'max_usage_count_per_user',
                                                            id === 'not_set_limit_for_coupen_user' ? false : true,
                                                            { shouldDirty: true }
                                                        )
                                                    }
                                                    legend={{
                                                        children: (
                                                            <span>
                                                                Decide how many times an individual can use the coupon.
                                                            </span>
                                                        ),
                                                    }}
                                                />
                                            )}
                                            name='max_usage_count_per_user'
                                            defaultValue={true}
                                            control={control}
                                            fullWidth={true}
                                        />
                                    </EuiFormRow>
                                </EuiFlexItem>
                            </EuiFlexGroup>

                            <EuiFlexGroup>
                                <EuiFlexItem>
                                    <EuiSpacer size='s' />
                                    {watch('max_usage_count_per_user') && (
                                        <NumberField
                                            label='Number of user can use this offer'
                                            isInvalid={errors.number_of_coupen_available_per_user}
                                            errors={errors}
                                            name='number_of_coupen_available_per_user'
                                            placeholder='Enter number of user can use this offer'
                                            rules={{
                                                required: 'Please enter number of coupen',
                                                validate: (value) =>
                                                    value >= 1 || 'Please enter value must be grater than 0',
                                            }}
                                            step='any'
                                            min={1}
                                            control={control}
                                        />
                                    )}
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </>
            )}

            <EuiFlexGroup>
                <EuiFlexItem>
                    <NumberField
                        label='Minimum Required Order Counts'
                        isInvalid={errors.number_of_coupen_available_per_user}
                        errors={errors}
                        name='min_required_order_counts'
                        placeholder='0'
                        step='any'
                        control={control}
                    />
                </EuiFlexItem>
                <EuiFlexItem>
                    <NumberField
                        label='Maximum Required Order Counts'
                        isInvalid={errors.number_of_coupen_available_per_user}
                        errors={errors}
                        name='max_required_order_counts'
                        placeholder='0'
                        step='any'
                        control={control}
                    />
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer />
            <EuiFlexItem>
                <FormRadioGroup
                    label='Do you want to make this offer perpetual?'
                    name='perpetual_offer'
                    options={[
                        { id: 'with_out_expiry', label: 'Yes' },
                        { id: 'with_expiry', label: 'No' },
                    ]}
                />
            </EuiFlexItem>
            <EuiSpacer />

            {perpetual_offer === 'with_expiry' ? (
                <EuiFlexGroup style={{ marginBottom: '150px' }}>
                    <EuiFlexItem>
                        <EuiFormRow
                            label='Coupon valid from:'
                            fullWidth={true}
                            isInvalid={errors.validate_from}
                            error={errors.validate_from ? errors.validate_from.message : null}
                        >
                            <Controller
                                render={({ field }) => (
                                    <EuiDatePicker
                                        selected={field.value || undefined}
                                        onChange={(value) => field.onChange(value)}
                                    />
                                )}
                                name='validate_from'
                                control={control}
                                defaultValue={watch('validate_from') ? watch('validate_from') : null}
                                placeholder='select starting date'
                                isInvalid={errors.validate_from}
                                fullWidth={true}
                            />
                        </EuiFormRow>
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <EuiFormRow
                            label='Coupon valid till: '
                            fullWidth={true}
                            isInvalid={errors.validate_to}
                            error={errors.validate_to ? errors.validate_to.message : ''}
                        >
                            <Controller
                                render={({ field }) => (
                                    <EuiDatePicker
                                        selected={field.value || undefined}
                                        onChange={(value) => field.onChange(value)}
                                    />
                                )}
                                name='validate_to'
                                control={control}
                                defaultValue={watch('validate_to') ? watch('validate_to') : null}
                                placeholder='select ending date'
                                isInvalid={errors.validate_to}
                                fullWidth={true}
                            />
                        </EuiFormRow>
                    </EuiFlexItem>
                </EuiFlexGroup>
            ) : null}
        </>
    );
};

export default React.memo(ValidityAndAcceptibility);
