import * as types from '../types/category';

const initialState = {
    categories: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.SET_CATEGORIES:
            return {
                ...state,
                categories: action.payload.categories,
            };
        case types.CLEAR_CATEGORIES:
            return {
                ...state,
                categories: action.payload.categories,
            };
        default:
            return state;
    }
};
