import React, { useCallback, useEffect, useState } from 'react';
import { EuiSideNav, EuiFlexItem, EuiFlexGroup, EuiPanel, EuiLoadingContent } from '@elastic/eui';
import AddressDetails from '../../../modules/OutletSettings/Tabs/AddressDetails';
import ContactDetails from '../../../modules/OutletSettings/Tabs/ContactDetails';
import ServiceRadius from '../../../modules/OutletSettings/Tabs/ServiceRadius';
import PreparationTime from '../../../modules/OutletSettings/Tabs/PreparationTime';
import DeliveryCharges from '../../../modules/OutletSettings/Tabs/DeliveryCharges';
import OtherCharges from '../../../modules/OutletSettings/Tabs/OtherCharges';
import PaymentSetting from '../../../modules/OutletSettings/Tabs/PaymentSetting';
import PaymentMethods from '../../../modules/OutletSettings/Tabs/PaymentMethods';
import CurrencySettings from '../../../modules/OutletSettings/Tabs/CurrencySettings';
import FoodLicence from '../../../modules/OutletSettings/Tabs/FoodLicence';
import OtherSettings from '../../../modules/OutletSettings/Tabs/OtherSettings';
import ThirdPartyRider from '../../../modules/OutletSettings/Tabs/ThirdPartRiderSettings/ThirdPartyRider';
import OrderingMode from '../../../modules/OutletSettings/Tabs/OrderingMode/OrderingMode';
import ThirdPartyPOS from '../../../modules/OutletSettings/Tabs/ThirdPartyPOS';
import '../style.css';
import { useSelector, useDispatch } from 'react-redux';
import AutoAssignmentRider from '../../../modules/OutletSettings/Tabs/ThirdPartRiderSettings/AutoAssignmentRider';
import CODLimit from '../../../modules/OutletSettings/Tabs/CODLimit';
import OutletTimings from '../../../modules/OutletSettings/Tabs/OutletTimings';
import DisplayRankModes from '../../../modules/OutletSettings/Tabs/OrderingMode/DisplayRankModes';
import BillSetting from '../../../modules/OutletSettings/Tabs/BillSetting';
import BillPaymentConfiguration from '../../../modules/OutletSettings/Tabs/BillPaymentConfiguration';
import CategoryLayout from '../../../modules/OutletSettings/Tabs/Appearance/CategoryLayout';
import HomeScreenLayout from '../../../modules/OutletSettings/Tabs/Appearance/HomeScreenLayout';
import useGetOutletSettings from '../../../modules/OutletSettings/Hooks/useGetOutletSettings';
import OutletTaxes from '../../../modules/OutletSettings/Tabs/OutletTaxes';
import { TOGGLE_UNSAVED_PORTAL } from '../../../reduxStore/types/Global/Global';
import MOV from '../../../components/MOV/MOV';
import CODEnablement from '../../../modules/OutletSettings/Tabs/CODEnablement';

const OutletForm = () => {
    const dispatch = useDispatch();
    const showFormHandlerPortal = useSelector((state) => state.uiPortalStateReducer.portalState.showFormHandlerPortal);
    const [selectedTab, setSelectedTab] = useState('ordering_mode');
    const [selectedSubTab, setSelectedSubTab] = useState(null);
    const outletId = useSelector((state) => state.outlet.selectedOutletId);
    const [selectedSubTabModes, setSelectedSubTabModes] = useState(null);
    const isCentralPaymentEnable = useSelector(
        (state) => state.outlet.selectedOutletDetails?.settings?.use_central_payment_configurations
    );
    // const isCcavenueEnabled = useSelector((state) => state.outlet.selectedOutletDetails?.settings?.is_ccavenue_enabled);
    const isCcavenueEnabled = true;

    const { isLoading, error } = useGetOutletSettings();

    const onChangeTab = (id) => {
        if (showFormHandlerPortal) {
            dispatch({
                type: TOGGLE_UNSAVED_PORTAL,
                payload: true,
            });
        } else {
            setSelectedTab(id);
        }
    };

    const onChangeSubTab = useCallback(
        (id) => {
            if (showFormHandlerPortal) {
                dispatch({
                    type: TOGGLE_UNSAVED_PORTAL,
                    payload: true,
                });
            } else {
                setSelectedSubTab(id);
            }
        },
        [dispatch, showFormHandlerPortal]
    );

    let items = [
        {
            id: 'ordering_mode',
            name: 'Ordering mode',
            onClick: () => onChangeTab('ordering_mode'),
            isSelected: selectedTab === 'ordering_mode',
        },
        {
            id: 'appearance',
            name: 'Appearance',
            onClick: () => onChangeTab('appearance'),
            isSelected: selectedTab === 'appearance',
        },
        {
            id: 'address_details',
            name: 'Address',
            onClick: () => onChangeTab('address_details'),
            isSelected: selectedTab === 'address_details',
        },
        {
            id: 'bill_settings',
            name: 'Bill Settings',
            onClick: () => onChangeTab('bill_settings'),
            isSelected: selectedTab === 'bill_settings',
        },
        {
            id: 'bill_payments',
            name: 'Bill Payments',
            onClick: () => onChangeTab('bill_payments'),
            isSelected: selectedTab === 'bill_payments',
        },
        {
            id: 'contacts',
            name: 'Contacts',
            onClick: () => onChangeTab('contacts'),
            isSelected: selectedTab === 'contacts',
        },
        {
            id: 'service_radius',
            name: 'Service radius',
            onClick: () => onChangeTab('service_radius'),
            isSelected: selectedTab === 'service_radius',
        },
        {
            id: 'preparation_time_and_rush_hour',
            name: 'Preparation time ',
            onClick: () => onChangeTab('preparation_time_and_rush_hour'),
            isSelected: selectedTab === 'preparation_time_and_rush_hour',
        },
        {
            id: 'delivery_charge',
            name: 'Delivery charge',
            onClick: () => onChangeTab('delivery_charge'),
            isSelected: selectedTab === 'delivery_charge',
        },
        {
            id: 'payment_settings',
            name: 'Payment Settings',
            onClick: () => onChangeTab('payment_settings'),
            isSelected: selectedTab === 'payment_settings',
        },
        {
            id: 'payment_methods',
            name: 'Payment Methods',
            onClick: () => onChangeTab('payment_methods'),
            isSelected: selectedTab === 'payment_methods',
        },
        {
            id: 'currency',
            name: 'Currency',
            onClick: () => onChangeTab('currency'),
            isSelected: selectedTab === 'currency',
        },
        {
            id: 'timing',
            name: 'Timing',
            onClick: () => onChangeTab('timing'),
            isSelected: selectedTab === 'timing',
        },
        {
            id: 'third_party_rider_setting',
            name: 'Rider setting',
            onClick: () => onChangeTab('third_party_rider_setting'),
            isSelected: selectedTab === 'third_party_rider_setting',
        },
        {
            id: 'taxes',
            name: 'Taxes',
            onClick: () => onChangeTab('taxes'),
            isSelected: selectedTab === 'taxes',
        },
        {
            id: 'food_licence',
            name: 'Food licence',
            onClick: () => onChangeTab('food_licence'),
            isSelected: selectedTab === 'food_licence',
        },
        {
            id: 'other_settings',
            name: 'Other settings',
            onClick: () => onChangeTab('other_settings'),
            isSelected: selectedTab === 'other_settings',
        },
        {
            id: 'other_charges',
            name: 'Other charges',
            onClick: () => onChangeTab('other_charges'),
            isSelected: selectedTab === 'other_charges',
        },
        {
            id: 'third_party_pos',
            name: 'Third party POS',
            onClick: () => onChangeTab('third_party_pos'),
            isSelected: selectedTab === 'third_party_pos',
        },
        {
            id: 'cod_limit',
            name: 'COD Limit',
            onClick: () => onChangeTab('cod_limit'),
            isSelected: selectedTab === 'cod_limit',
        },
        {
            id: 'mov_limit',
            name: 'MOV Limit',
            onClick: () => onChangeTab('mov_limit'),
            isSelected: selectedTab === 'mov_limit',
        },
        {
            id: 'cod_enablement',
            name: 'COD Enablement',
            onClick: () => onChangeTab('cod_enablement'),
            isSelected: selectedTab === 'cod_enablement',
        },
    ];

    const sideNav = [
        {
            name: '',
            id: 0,
            items:
                isCentralPaymentEnable == 1
                    ? items.filter((item) => item.id !== 'payment_settings' && item.id !== 'payment_methods')
                    : items,
        },
    ];
    useEffect(() => {
        if (selectedTab === 'third_party_rider_setting') {
            setSelectedSubTab('rider_service');
        } else if (selectedTab === 'ordering_mode') {
            setSelectedSubTab('ordering_modes');
        } else if (selectedTab === 'appearance') {
            setSelectedSubTab('category_layout');
        } else {
            setSelectedSubTab(null);
        }
    }, [selectedTab]);

    const selectedComponent = () => {
        // eslint-disable-next-line default-case
        switch (selectedTab) {
            case 'appearance':
                return null;
            case 'address_details':
                return <AddressDetails />;
            case 'bill_settings':
                return <BillSetting />;
            case 'bill_payments':
                return <BillPaymentConfiguration />;
            case 'contacts':
                return <ContactDetails />;
            case 'service_radius':
                return <ServiceRadius />;
            case 'preparation_time_and_rush_hour':
                return <PreparationTime />;
            case 'delivery_charge':
                return <DeliveryCharges />;
            case 'other_charges':
                return <OtherCharges />;
            case 'payment_settings':
                return <PaymentSetting />;
            case 'payment_methods':
                return <PaymentMethods />;
            case 'currency':
                return <CurrencySettings />;
            case 'timing':
                return <OutletTimings />;
            case 'third_party_rider_setting':
                return '';
            case 'taxes':
                return <OutletTaxes />;
            case 'food_licence':
                return <FoodLicence />;
            case 'other_settings':
                return <OtherSettings />;
            case 'ordering_mode':
                return '';
            case 'third_party_pos':
                return <ThirdPartyPOS />;
            case 'cod_limit':
                return <CODLimit />;
            case 'mov_limit':
                return <MOV />;
            case 'cod_enablement':
                return <CODEnablement />;
        }
    };

    const subSideNav = [
        {
            name: '',
            id: 0,
            items: [
                {
                    id: 'rider_service',
                    name: 'Rider Service',
                    onClick: () => onChangeSubTab('rider_service'),
                    isSelected: selectedSubTab === 'rider_service',
                },
                {
                    id: 'rider_auto_assignment',
                    name: 'Rider Auto Assignment',
                    onClick: () => onChangeSubTab('rider_auto_assignment'),
                    isSelected: selectedSubTab === 'rider_auto_assignment',
                },
            ],
        },
    ];

    const subSideNavOrderingModes = [
        {
            name: '',
            id: 0,
            items: [
                {
                    id: 'ordering_modes',
                    name: 'Enable/Disable Ordering Modes',
                    onClick: () => onChangeSubTab('ordering_modes'),
                    isSelected: selectedSubTab === 'ordering_modes',
                },
                {
                    id: 'ordering_modes_display_rank',
                    name: 'Modify Display Sequence',
                    onClick: () => onChangeSubTab('ordering_modes_display_rank'),
                    isSelected: selectedSubTab === 'ordering_modes_display_rank',
                },
            ],
        },
    ];

    const subSideAppearanceTab = [
        {
            name: '',
            id: 0,
            items: [
                {
                    id: 'category_layout',
                    name: 'Category Layout',
                    onClick: () => onChangeSubTab('category_layout'),
                    isSelected: selectedSubTab === 'category_layout',
                },
                {
                    id: 'home_screen_layout',
                    name: 'Home Screen Layout',
                    onClick: () => onChangeSubTab('home_screen_layout'),
                    isSelected: selectedSubTab === 'home_screen_layout',
                },
            ],
        },
    ];

    const selectedSubComponent = useCallback(() => {
        switch (selectedSubTab) {
            case 'rider_service':
                return <ThirdPartyRider />;
            case 'rider_auto_assignment':
                return <AutoAssignmentRider />;
            case 'ordering_modes':
                return <OrderingMode />;
            case 'ordering_modes_display_rank':
                return <DisplayRankModes />;
            case 'home_screen_layout':
                return <HomeScreenLayout />;
            case 'category_layout':
                return <CategoryLayout />;

            default:
        }
    }, [outletId, selectedSubTab]);

    if (isLoading) return <EuiLoadingContent lines={10} />;
    if (error) return <h1>An Error Occured.</h1>;

    return (
        <EuiFlexGroup
            wrap
            responsive
            direction='row'
            justifyContent='spaceBetween'
            alignItems='flexStart'
            gutterSize='s'
        >
            <EuiFlexItem grow={1}>
                <EuiPanel>
                    <EuiSideNav
                        aria-label='Ordering Mode'
                        mobileTitle='Navigate'
                        style={{
                            padding: '8px',
                        }}
                        items={sideNav}
                    />
                </EuiPanel>
            </EuiFlexItem>
            {selectedTab === 'third_party_rider_setting' && (
                <>
                    <div style={{ backgroundColor: '#D3DAE6', width: '1.1px', margin: '0px 10px' }}></div>
                    <EuiFlexItem grow={1}>
                        <EuiPanel>
                            <EuiSideNav
                                aria-label='Ordering Mode'
                                mobileTitle='Navigate'
                                style={{
                                    padding: '8px',
                                }}
                                items={subSideNav}
                            />
                        </EuiPanel>
                    </EuiFlexItem>
                </>
            )}
            {selectedTab === 'ordering_mode' && (
                <>
                    <div style={{ backgroundColor: '#D3DAE6', width: '1.1px', margin: '0px 10px' }}></div>
                    <EuiFlexItem grow={1}>
                        <EuiPanel>
                            <EuiSideNav
                                aria-label='Ordering Mode'
                                mobileTitle='Navigate'
                                style={{
                                    // backgroundColor: themeMode === 'light' ? '#fafbfd' : '#1a1b20',
                                    padding: '8px',
                                }}
                                items={subSideNavOrderingModes}
                            />
                        </EuiPanel>
                    </EuiFlexItem>
                </>
            )}
            {selectedTab === 'appearance' && (
                <>
                    <div style={{ backgroundColor: '#D3DAE6', width: '1.1px', margin: '0px 10px' }}></div>
                    <EuiFlexItem grow={1}>
                        <EuiPanel>
                            <EuiSideNav
                                aria-label='Ordering Mode'
                                mobileTitle='Navigate'
                                style={{
                                    // backgroundColor: themeMode === 'light' ? '#fafbfd' : '#1a1b20',
                                    padding: '8px',
                                }}
                                items={subSideAppearanceTab}
                            />
                        </EuiPanel>
                    </EuiFlexItem>
                </>
            )}
            <div style={{ backgroundColor: '#D3DAE6', width: '1.1px', margin: '0px 10px' }}></div>
            {!(
                selectedSubTab === 'rider_service' ||
                selectedSubTab === 'rider_auto_assignment' ||
                selectedSubTab === 'ordering_modes' ||
                selectedSubTab === 'ordering_modes_display_rank' ||
                selectedSubTab === 'category_layout' ||
                selectedSubTab === 'home_screen_layout'
            ) && (
                <EuiFlexItem grow={8} className='outletSettingDetails'>
                    {selectedComponent()}
                </EuiFlexItem>
            )}
            <EuiFlexItem grow={8} className='outletSettingDetails'>
                {selectedSubTab &&
                    (selectedTab === 'third_party_rider_setting' ||
                        selectedTab === 'ordering_mode' ||
                        selectedTab === 'appearance') &&
                    selectedSubComponent()}
            </EuiFlexItem>
        </EuiFlexGroup>
    );
};

export default OutletForm;
