import React, { useEffect, useState } from 'react';
import {
    EuiButton,
    EuiFlyout,
    EuiFlyoutHeader,
    EuiTitle,
    EuiFlyoutBody,
    EuiFlyoutFooter,
    EuiFlexGroup,
    EuiFlexItem,
    EuiButtonEmpty,
    EuiPanel,
    EuiText,
    EuiSpacer,
} from '@elastic/eui';
import * as typesMenu from '../../../reduxStore/types/menu';
import { useSelector, useDispatch } from 'react-redux';
import { userHasPermission } from '../../../helper/auth';
import AddonGroupChoiceList from './AddonGroupChoiceList';
import { SET_SELECTED_ADDON_GROUP_CHOICES, SET_SELECTED_ADDON_GROUP_ID } from '../Store/MenuTypes';

const AddonGroupCatalogueFlyout = ({ state, closeFlyout }) => {
    const permissions = useSelector((state) => state.permission.permission);
    const addOnMainflyout = useSelector((state) => state.addon.addOnMainflyout);
    const addonState = useSelector((state) => state.menu.addonState);
    const dispatch = useDispatch();

    const [showAddNewAddonFlyout, setShowAddNewAddonFlyout] = useState(false);

    useEffect(() => {
        return function cleanup() {
            dispatch({
                type: typesMenu.SET_ADDON_STATE,
                payload: {
                    addonState: !addonState,
                },
            });
            dispatch({
                type: SET_SELECTED_ADDON_GROUP_CHOICES,
                payload: [],
            });
            dispatch({
                type: SET_SELECTED_ADDON_GROUP_ID,
                payload: null,
            });
        };
    }, [dispatch, addonState]);

    return (
        <>
            {(state.isOpen || addOnMainflyout) && (
                <EuiFlyout
                    onClose={closeFlyout}
                    hideCloseButton
                    aria-labelledby='flyoutComplicatedTitle'
                    className='full-screen-flyout'
                    style={{ zIndex: 6500 }}
                    headerzindexlocation='below'
                >
                    <EuiFlyoutHeader hasBorder>
                        <EuiTitle size='m'>
                            <h2 id='flyoutComplicatedTitle'>Manage Add on Group</h2>
                        </EuiTitle>
                    </EuiFlyoutHeader>
                    <EuiFlyoutBody style={{ overflowY: 'initial' }}>
                        <EuiFlexGroup>
                            <EuiFlexItem>
                                <div>
                                    <EuiPanel paddingSize='s'>
                                        <EuiFlexGroup responsive={false} justifyContent='spaceBetween'>
                                            <EuiFlexItem grow={false}>
                                                <EuiText>
                                                    <h3>Addon groups</h3>
                                                </EuiText>
                                            </EuiFlexItem>
                                            <EuiFlexItem grow={false}>
                                                {userHasPermission(permissions, '/menu', 'write') && (
                                                    <EuiButton
                                                        onClick={() => setShowAddNewAddonFlyout(true)}
                                                        iconType='plusInCircleFilled'
                                                        aria-label='any'
                                                        size='s'
                                                    >
                                                        Add New
                                                    </EuiButton>
                                                )}
                                            </EuiFlexItem>
                                        </EuiFlexGroup>
                                    </EuiPanel>
                                </div>
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <div>
                                    <EuiPanel paddingSize='s'>
                                        <EuiFlexGroup responsive={false} justifyContent='spaceBetween'>
                                            <EuiFlexItem grow={false}>
                                                <EuiText>
                                                    <h3>Addon Choices</h3>
                                                </EuiText>
                                            </EuiFlexItem>
                                            <EuiFlexItem grow={false}>
                                                {userHasPermission(permissions, '/menu', 'write') && (
                                                    <EuiButton
                                                        onClick={() => setShowAddNewAddonFlyout(true)}
                                                        iconType='plusInCircleFilled'
                                                        aria-label='any'
                                                        size='s'
                                                    >
                                                        Add New
                                                    </EuiButton>
                                                )}
                                            </EuiFlexItem>
                                        </EuiFlexGroup>
                                    </EuiPanel>
                                </div>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                        <EuiSpacer size='s' />
                        <AddonGroupChoiceList
                            showAddNewAddonFlyout={showAddNewAddonFlyout}
                            setShowAddNewAddonFlyout={setShowAddNewAddonFlyout}
                        />
                    </EuiFlyoutBody>
                    <EuiFlyoutFooter>
                        <EuiFlexGroup justifyContent='spaceBetween'>
                            <EuiFlexItem grow={false}>
                                <EuiButtonEmpty iconType='cross' onClick={closeFlyout} flush='left'>
                                    Close
                                </EuiButtonEmpty>
                            </EuiFlexItem>
                            <EuiFlexItem grow={false} />
                        </EuiFlexGroup>
                    </EuiFlyoutFooter>
                </EuiFlyout>
            )}
        </>
    );
};

export default AddonGroupCatalogueFlyout;
