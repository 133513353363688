import React, { useState, useMemo, useCallback } from 'react';
import { EuiDataGrid, EuiText, EuiLink } from '@elastic/eui';

const RiderOrderInformation = ({ allOrder, setIsFlyoutVisible, setSelectedOrder }) => {
    const columns = useMemo(() => {
        return [
            {
                id: 'order_id',
                display: <div className='eui-textTruncate'>Order ID</div>,
                defaultSortDirection: 'asc',
            },
            {
                id: 'name',
                display: <div className='eui-textTruncate'>Name</div>,
            },
            {
                id: 'phone',
                display: <div className='eui-textTruncate'>Contact number</div>,
            },
            {
                id: 'made_of_payment',
                display: <div className='eui-textTruncate'>Made of payment</div>,
            },
            {
                id: 'order_total',
                display: <div className='eui-textTruncate'>Order total</div>,
            },
            {
                id: 'kilometer_travel',
                display: <div className='eui-textTruncate'>Killometer travel</div>,
            },
            {
                id: 'extra_kilometer_travel',
                display: <div className='eui-textTruncate'>Extra killometer travel</div>,
            },
            {
                id: 'total_revenew',
                display: <div className='eui-textTruncate'>Total revenew</div>,
            },
            {
                id: 'normal_revenew',
                display: <div className='eui-textTruncate'>Normal revenew</div>,
            },
            {
                id: 'extra_revenew',
                display: <div className='eui-textTruncate'>Extra revenew</div>,
            },
        ];
    }, []);

    const setOrderOnFlyout = useCallback(
        (order) => {
            setSelectedOrder(order);
            setIsFlyoutVisible(true);
        },
        [setIsFlyoutVisible, setSelectedOrder]
    );

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const onChangeItemsPerPage = useCallback(
        (pageSize) =>
            setPagination((pagination) => ({
                ...pagination,
                pageSize,
                pageIndex: 0,
            })),
        [setPagination]
    );

    const onChangePage = useCallback(
        (pageIndex) => setPagination((pagination) => ({ ...pagination, pageIndex })),
        [setPagination]
    );
    // ** Sorting config
    const [sortingColumns, setSortingColumns] = useState([]);
    const onSort = useCallback(
        (sortingColumns) => {
            setSortingColumns(sortingColumns);
        },
        [setSortingColumns]
    );
    const [visibleColumns, setVisibleColumns] = useState(() => columns.map(({ id }) => id)); // initialize to the full set of columns

    let orderData = useMemo(() => {
        return allOrder?.rider_order_details?.rider_orders?.data?.length > 0
            ? allOrder.rider_order_details.rider_orders.data.map((item) => {
                  return {
                      order_id: <>{<EuiText key={item.id + '_order_id'}>{item.order_id}</EuiText>}</>,
                      name: (
                          <>
                              {
                                  <EuiText key={item.id + '_name'} onClick={() => setOrderOnFlyout(item.order)}>
                                      {' '}
                                      <EuiLink> {item.order.customer.name} </EuiLink>{' '}
                                  </EuiText>
                              }
                          </>
                      ),
                      phone: <>{<EuiText key={item.id + '_phone'}>{item.order.customer.mobile_number}</EuiText>}</>,
                      made_of_payment: <>{<EuiText key={item.id + '_made_of_payment'}>{item.payment_mode}</EuiText>}</>,
                      order_total: <>{<EuiText key={item.id + '_order_total'}>{item.order.net_total}</EuiText>}</>,
                      kilometer_travel: <>{<EuiText key={item.id + '_kilometer_charges'}>{item.distance}</EuiText>}</>,
                      extra_kilometer_travel: (
                          <>{<EuiText key={item.id + '_extra_kilometer_charges'}>{item.extra_distance}</EuiText>}</>
                      ),
                      total_revenew: (
                          <>
                              {
                                  <EuiText key={item.id + '_total_revenew'}>
                                      {item.delivery_charge + item.extra_delivery_charge}
                                  </EuiText>
                              }
                          </>
                      ),
                      normal_revenew: (
                          <>{<EuiText key={item.id + '_normal_revenew'}>{item.delivery_charge}</EuiText>}</>
                      ),
                      extra_revenew: (
                          <>{<EuiText key={item.id + '_extra_revenew'}>{item.extra_delivery_charge}</EuiText>}</>
                      ),
                  };
              })
            : [];
    }, [allOrder.rider_order_details.rider_orders.data, setOrderOnFlyout]);

    const renderCellValue = useMemo(() => {
        return ({ rowIndex, columnId, setCellProps }) => {
            return orderData.hasOwnProperty(rowIndex) ? orderData[rowIndex][columnId] : null;
        };
    }, [orderData]);

    return (
        <>
            <EuiDataGrid
                aria-label='Order information'
                columns={columns}
                columnVisibility={{ visibleColumns, setVisibleColumns }}
                rowCount={orderData.length}
                renderCellValue={renderCellValue}
                inMemory={{ level: 'sorting' }}
                sorting={{ columns: sortingColumns, onSort }}
                pagination={{
                    ...pagination,
                    pageSizeOptions: [10, 50, 100],
                    onChangeItemsPerPage: onChangeItemsPerPage,
                    onChangePage: onChangePage,
                }}
                style={{ maxHeight: '100vh' }}
                onColumnResize={(eventData) => {}}
            />
        </>
    );
};

export default React.memo(RiderOrderInformation);
