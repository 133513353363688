import {
    EuiDragDropContext,
    euiDragDropReorder,
    EuiDraggable,
    EuiDroppable,
    EuiFlexGroup,
    EuiFlexItem,
    EuiIcon,
    EuiPanel,
    EuiText,
} from '@elastic/eui';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { outletSettingsV4 } from '../../../../api/outlet/outletSettingsAPI';
import useFormActionsHandler from '../../../../hooks/useFormActionsHandler';
import useOutletFormValues from '../../Hooks/useOutletFormValues';

const HomeScreenLayout = () => {
    const dispatch = useDispatch();

    const [list, setList] = useState([]);

    const updateFormValues = useCallback((data) => {
        setList([...data?.outlet_screen_display_configurations]);

        return {
            outlet_screen_display_configurations: data.outlet_screen_display_configurations,
        };
    }, []);

    const { defaultValues, setDefaultValues, onSuccessCallback } = useOutletFormValues({ updateFormValues });

    const onFormSaveApi = useCallback(async () => {
        const response = await dispatch(
            outletSettingsV4('layout_settings', {
                outlet_screen_display_configurations: watch('outlet_screen_display_configurations'),
            })
        );

        if (response.success) setDefaultValues(updateFormValues(response.outlet));
        return response;
    }, [dispatch]);

    const { setValue, watch } = useFormActionsHandler({
        onFormSaveApi,
        defaultValues,
        onSuccessCallback,
    });

    const handleOnDragEnd = useCallback(
        ({ source, destination }) => {
            let items = euiDragDropReorder(list, source.index, destination.index);

            for (const [index, item] of items.entries()) {
                item.display_rank = index + 1;
            }

            setList(items);
            setValue('outlet_screen_display_configurations', items, { shouldDirty: true });
        },
        [list, setValue]
    );

    return (
        <EuiDragDropContext onDragEnd={handleOnDragEnd}>
            <EuiDroppable droppableId='DROPPABLE_AREA_FOR_ITEM_LIST'>
                {list?.map((config, idx) => {
                    return (
                        <EuiDraggable
                            spacing='m'
                            key={config.id}
                            index={idx}
                            draggableId={config.id.toString()}
                            customDragHandle={true}
                            id={idx}
                        >
                            {(provided) => (
                                <EuiPanel>
                                    <EuiFlexGroup alignItems='center' justifyContent='flexStart'>
                                        <EuiFlexItem grow={false} {...provided.dragHandleProps}>
                                            <EuiIcon type='grab' />
                                        </EuiFlexItem>
                                        <EuiFlexItem grow={false}>
                                            <EuiText>{config.section_name}</EuiText>
                                        </EuiFlexItem>
                                    </EuiFlexGroup>
                                </EuiPanel>
                            )}
                        </EuiDraggable>
                    );
                })}
            </EuiDroppable>
        </EuiDragDropContext>
    );
};

export default React.memo(HomeScreenLayout);
