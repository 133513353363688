import * as types from '../types/language';

const initialState = {
    language: 'en',
    availableLanguages: [],
    languageId: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.SET_LANGUAGE:
            return {
                ...state,
                language: action.payload,
            };
        case types.SET_AVAILABLE_LANGUAGES:
            return {
                ...state,
                availableLanguages: action.payload.availableLanguages,
            };
        case types.SET_LANGUAGE_ID:
            return {
                ...state,
                languageId: action.payload.languageId,
            };
        case types.SET_CLEAR_LANGUAGES:
            return {
                ...state,
                availableLanguages: action.payload.availableLanguages,
            };
        default:
            return state;
    }
};
