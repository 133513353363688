import { EuiBottomBar, EuiButton, EuiButtonEmpty, EuiFlexItem, EuiPortal } from '@elastic/eui';
import React from 'react';
import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    SET_DISCARD_UI_STATE,
    SET_SAVE_UI_STATE,
    TOGGLE_FORM_HANDLER_PORTAL,
} from '../../reduxStore/types/Global/Global';

const FormActionPortal = () => {
    const dispatch = useDispatch();
    const submitLoader = useSelector((state) => state.outlet.submitLoader);

    const onSave = useCallback(() => {
        dispatch({
            type: SET_SAVE_UI_STATE,
        });
    }, [dispatch]);

    const onDiscard = useCallback(() => {
        dispatch({
            type: SET_DISCARD_UI_STATE,
        });
        dispatch({
            type: TOGGLE_FORM_HANDLER_PORTAL,
            payload: false,
        });
    }, [dispatch]);

    return (
        <>
            <EuiPortal>
                <EuiBottomBar style={{ backgroundColor: 'white', zIndex: 999 }}>
                    <EuiFlexItem style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <EuiFlexItem
                            grow={2}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-end',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <EuiFlexItem style={{ marginRight: '15px' }}>
                                <EuiButtonEmpty onClick={onDiscard} size='s' color='danger'>
                                    Discard changes
                                </EuiButtonEmpty>
                            </EuiFlexItem>

                            <EuiFlexItem>
                                <EuiButton isLoading={submitLoader} onClick={onSave} size='s' fill={true}>
                                    Save
                                </EuiButton>
                            </EuiFlexItem>
                        </EuiFlexItem>
                    </EuiFlexItem>
                </EuiBottomBar>
            </EuiPortal>
        </>
    );
};

export default React.memo(FormActionPortal);
