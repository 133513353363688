import React from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiText } from '@elastic/eui';
import { weekDays } from './ItemTimings';
import { useWatch } from 'react-hook-form';

const ItemTimingCardDescription = ({ watch, control }) => {
    const timer_for_item = useWatch({
        control,
        name: 'timer_for_item',
    });

    return (
        <>
            {!watch('is_enabled_same_time_slot_details_for_all_ordering_modes') ? (
                <EuiFlexGroup>
                    {timer_for_item === 'same_day_of_the_week' ? (
                        <EuiFlexItem>
                            <EuiText size='xs'>Time slots are not selected for this item</EuiText>
                        </EuiFlexItem>
                    ) : (
                        <>
                            {timer_for_item === 'same_time_for_all_days' && watch(`time`)?.length ? (
                                <EuiFlexItem>
                                    <EuiText size='xs'>Item is available at same time for all days of the week</EuiText>
                                    <EuiSpacer size='xs' />
                                    <EuiFlexGroup wrap gutterSize='s'>
                                        {watch('time').map((time, index) => {
                                            return (
                                                <React.Fragment key={index.toString()}>
                                                    <EuiFlexItem style={{ minWidth: 100 }}>
                                                        <EuiFlexGroup>
                                                            <EuiFlexItem>
                                                                <EuiText size='xs'>
                                                                    Start Time:{time.start_time}
                                                                </EuiText>
                                                                <EuiText size='xs'>End Time:{time.end_time}</EuiText>
                                                            </EuiFlexItem>
                                                        </EuiFlexGroup>
                                                    </EuiFlexItem>
                                                </React.Fragment>
                                            );
                                        })}
                                    </EuiFlexGroup>
                                </EuiFlexItem>
                            ) : timer_for_item === 'different_time_for_different_days' ? (
                                <EuiFlexItem>
                                    <EuiText size='xs'>
                                        Item is available at specific time for specific days of the week
                                    </EuiText>
                                    {weekDays.map((day) => {
                                        return (
                                            <React.Fragment key={day}>
                                                {watch(`week[${day}]`)?.length ? (
                                                    <>
                                                        <EuiSpacer size='s' />
                                                        <EuiFlexGroup wrap gutterSize='s'>
                                                            {watch(`week[${day}]`).map((time, index) => {
                                                                return (
                                                                    <React.Fragment key={index.toString()}>
                                                                        <EuiFlexItem style={{ minWidth: 100 }}>
                                                                            <EuiFlexGroup>
                                                                                <EuiFlexItem>
                                                                                    <EuiText
                                                                                        size='xs'
                                                                                        style={{
                                                                                            fontWeight: 600,
                                                                                            textTransform: 'capitalize',
                                                                                        }}
                                                                                    >
                                                                                        {day}
                                                                                    </EuiText>
                                                                                    <EuiText size='xs'>
                                                                                        Start Time:
                                                                                        {time.start_time}
                                                                                    </EuiText>
                                                                                    <EuiText size='xs'>
                                                                                        End Time:
                                                                                        {time.end_time}
                                                                                    </EuiText>
                                                                                </EuiFlexItem>
                                                                            </EuiFlexGroup>
                                                                        </EuiFlexItem>
                                                                    </React.Fragment>
                                                                );
                                                            })}
                                                        </EuiFlexGroup>
                                                    </>
                                                ) : null}
                                            </React.Fragment>
                                        );
                                    })}
                                </EuiFlexItem>
                            ) : null}
                        </>
                    )}
                </EuiFlexGroup>
            ) : (
                <EuiText size='xs'>Active timing ordering mode wise</EuiText>
            )}
        </>
    );
};

export default React.memo(ItemTimingCardDescription);
