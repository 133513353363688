import React, { useEffect, useState } from 'react';
import FormGroupDescription from '../../components/formGroupDescription';
import { EuiButtonGroup } from '@elastic/eui';

const NotificationLoopSound = () => {
    const [options, setOptions] = useState([
        { id: '1', label: 'Enable', iconType: 'check' },
        { id: '0', label: 'Disable', iconType: 'cross' },
    ]);
    const [selectedOption, setSelectedOption] = useState('1');

    useEffect(() => {
        let loop = localStorage.getItem('notificationSoundLoop');
        if (loop == null || loop === '1') {
            setSelectedOption('1');
        } else {
            setSelectedOption('0');
        }
    }, []);

    const onChange = (value) => {
        let innerOptions = [...options];
        if (value) {
            innerOptions[0].label = 'Enabled';
            innerOptions[1].label = 'Disable';
        } else {
            innerOptions[1].label = 'Disabled';
            innerOptions[0].label = 'Enable';
        }
        setSelectedOption(value);
        localStorage.setItem('notificationSoundLoop', value);
        setOptions(innerOptions);
    };

    return (
        <FormGroupDescription
            title='Continuous Incoming tone'
            description='Enable or Disable continuous ring for new incoming orders'
        >
            <EuiButtonGroup
                options={options}
                idSelected={selectedOption}
                onChange={onChange}
                color='primary'
                value={selectedOption}
                legend='Text align'
            />
        </FormGroupDescription>
    );
};

export default NotificationLoopSound;
