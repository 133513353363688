import {
    SET_DISCARD_UI_STATE,
    SET_SAVE_UI_STATE,
    TOGGLE_FORM_HANDLER_PORTAL,
    TOGGLE_UNSAVED_PORTAL,
} from '../types/Global/Global';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
    portalState: {
        showFormHandlerPortal: false,
        showUnsavedDataWaringPortal: false,
        saveStateHandler: uuidv4(),
        discardStateHandler: uuidv4(),
    },
};

export default function uiPortalStateReducer(state = initialState, action) {
    switch (action.type) {
        case SET_DISCARD_UI_STATE:
            return {
                ...state,
                portalState: {
                    ...state.portalState,
                    discardStateHandler: uuidv4(),
                },
            };
        case SET_SAVE_UI_STATE:
            return {
                ...state,
                portalState: {
                    ...state.portalState,
                    saveStateHandler: uuidv4(),
                },
            };
        case TOGGLE_FORM_HANDLER_PORTAL:
            return {
                ...state,
                portalState: {
                    ...state.portalState,
                    showFormHandlerPortal: action.payload,
                },
            };
        case TOGGLE_UNSAVED_PORTAL:
            return {
                ...state,
                portalState: {
                    ...state.portalState,
                    showUnsavedDataWaringPortal: action.payload,
                },
            };

        default:
            return state;
    }
}
