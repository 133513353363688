import { EuiCheckbox, EuiFlexGroup, EuiFlexItem, EuiText, htmlIdGenerator } from '@elastic/eui';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

const CategoryVisibility = ({ control, setValue }) => {
    const orderingModes = useSelector((state) => state.menuReducer.restaurantOrderingModes);
    const editCategory = useSelector((state) => state.menu.editCategory);
    const [orderingModeState, setOrderingModeState] = useState([]);
    const [removedOrderingModes, setRemovedOrderingModes] = useState([]);
    const ordering_modes = useWatch({
        control,
        name: `editCategory.ordering_modes`,
    });

    useEffect(() => {
        if (ordering_modes?.length) {
            setOrderingModeState(ordering_modes);
        }
    }, [ordering_modes]);

    const updateOrderingMode = useCallback(
        (mode, event) => {
            const isChecked = event.target.checked;
            let selectedMode = {};
            selectedMode = {
                restaurant_ordering_mode_id: mode?.id,
                restaurant_ordering_mode_name: mode?.display_name,
            };

            if (!isChecked) {
                // removed ordering mode
                const doesModeExisted = editCategory?.ordering_modes?.filter(
                    (mode) => mode.restaurant_ordering_mode_id === selectedMode.restaurant_ordering_mode_id
                )?.[0];
                if (doesModeExisted) {
                    setRemovedOrderingModes([...removedOrderingModes, doesModeExisted?.restaurant_ordering_mode_id]);
                    const updatedOrderingMode = orderingModeState.filter(
                        (mode) => mode.restaurant_ordering_mode_id !== selectedMode.restaurant_ordering_mode_id
                    );
                    if (updatedOrderingMode.length) {
                        setOrderingModeState(updatedOrderingMode);
                        setValue(`editCategory.ordering_modes`, updatedOrderingMode);
                    }
                    setValue(`delete_ordering_modes`, [
                        ...removedOrderingModes,
                        doesModeExisted?.restaurant_ordering_mode_id,
                    ]);
                } else {
                    const removedFromOrderingState = orderingModeState.filter(
                        (mode) => mode.restaurant_ordering_mode_id !== selectedMode.restaurant_ordering_mode_id
                    );
                    setOrderingModeState(removedFromOrderingState);
                    setValue(`editCategory.ordering_modes`, removedFromOrderingState);
                }
            } else {
                setOrderingModeState((prevState) => [...prevState, selectedMode]);

                // check if the id is in removeState
                const destroyFromRemoveState = removedOrderingModes?.filter(
                    (id) => id != selectedMode.restaurant_ordering_mode_id
                );
                setRemovedOrderingModes(destroyFromRemoveState);
                setValue(`delete_ordering_modes`, destroyFromRemoveState);
                setValue(`editCategory.ordering_modes`, [...orderingModeState, selectedMode]);
                // setOrderingModeState([...orderingModeState, modeComeBack]);
            }
        },
        [editCategory?.ordering_modes, orderingModeState, removedOrderingModes, setValue]
    );

    return (
        <EuiFlexGroup
            alignItems='center'
            style={{ maxWidth: '95%' }}
            gutterSize='s'
            justifyContent='flexStart'
            wrap
            responsive
        >
            {orderingModes
                .filter((orderingMode) => orderingMode.status)
                .map((mode) => {
                    return (
                        <EuiFlexItem key={mode.id.toString()}>
                            <EuiFlexGroup direction='row' justifyContent='flexStart' alignItems='center'>
                                <EuiFlexItem grow={false}>
                                    <Controller
                                        render={({ field }) => (
                                            <EuiCheckbox
                                                onChange={(e) => {
                                                    field.onChange(e.target.checked);
                                                    updateOrderingMode(mode, e);
                                                    setValue(`mode_${mode.id}`, e.target.checked);
                                                }}
                                                id={htmlIdGenerator()()}
                                                checked={Boolean(field.value)}
                                                compressed
                                            />
                                        )}
                                        name={`editCategory.mode_${mode.id}`}
                                        control={control}
                                    />
                                </EuiFlexItem>
                                <EuiFlexItem grow={false}>
                                    <EuiText style={{ textTransform: 'capitalize' }}>{mode.display_name}</EuiText>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </EuiFlexItem>
                    );
                })}
        </EuiFlexGroup>
    );
};

export default React.memo(CategoryVisibility);
