import {
    EuiButton,
    EuiCard,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFlyout,
    EuiFlyoutBody,
    EuiFlyoutFooter,
    EuiFlyoutHeader,
    EuiLoadingContent,
    EuiPanel,
    EuiTitle,
} from '@elastic/eui';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { userHasPermission } from '../../helper/auth';
import { SET_CURRENT_ORDER_STATE, SET_ORDERS_COUNT, SET_SELECTED_ORDER } from '../../reduxStore/types/orders';
import { toastsErrorMessage, toastSuccessMessage } from '../../utils/toasts';
import { fetchOutletSections } from './Api/fetchOutletSections';
import { sessionSettle } from './Api/sessionSettle';
import OrdersTable from './OrdersTable';
import OrderDetialsTable from './OrderDetialsTable';
import TableOrdersBillPrint from './TableOrdersBillPrint';
import FilterOrderStatusWise from './FilterOrderStatusWise';
import OrderFlyoutHeader from './Components/OrderFlyoutHeader';

export const NoRunningOrders = () => {
    return (
        <EuiCard
            title={
                <EuiTitle size='s'>
                    <h2>No Running Orders</h2>
                </EuiTitle>
            }
        />
    );
};

const OrdersFlyout = ({ selectedTable, closeFlyout, isPastTableSession = false }) => {
    const history = useHistory();
    const permissions = useSelector((state) => state.permission.permission);
    const tableDetails = useSelector((state) => state.tableManagement.tableOrderDetails);
    const tableOrderDetails = useSelector((state) => state.tableManagement.tableOrderDetails?.orders);
    const tableOrderingMode = useSelector((state) => state.tableManagement.tableOrderDetails?.ordering_mode_type);
    const dispatch = useDispatch();
    const orderBillRef = useRef(null);
    const languageId = useSelector((state) => state.language.languageId);
    let restaurantList = useSelector((state) => state.auth.restaurantList);
    let selectedRestuarantId = useSelector((state) => state.auth.selectedRestaurantId);
    const [selectedOrder, setSelectedOrder] = useState({});
    const selectedOrderId = useSelector((state) => state.orders.selectedOrderId);

    useEffect(() => {
        if (selectedOrderId && tableOrderDetails?.length) {
            const selectedOrder = tableOrderDetails.filter(
                (order) => Number(order.order_id) === Number(selectedOrderId)
            );
            if (selectedOrder?.length) {
                setSelectedOrder(selectedOrder[0]);
            }
        }
    }, [tableOrderDetails, selectedOrderId]);

    useEffect(() => {
        if (tableOrderDetails?.length && !selectedOrderId) {
            setSelectedOrder(tableOrderDetails[0]);
            dispatch({
                type: SET_SELECTED_ORDER,
                payload: tableOrderDetails[0].order_id,
            });
        }
    }, [dispatch, selectedOrderId, tableOrderDetails]);

    useEffect(() => {
        if (!tableOrderDetails?.length) {
            setSelectedOrder({});
        }

        return function cleanup() {
            setSelectedOrder({});
        };
    }, [tableOrderDetails]);

    const handleSessionSettlement = useCallback(async () => {
        await sessionSettle(selectedTable.current_session.id).then((response) => {
            if (!response?.isError) {
                toastSuccessMessage(response.message, dispatch);
                closeFlyout();

                dispatch(fetchOutletSections());
            } else {
                toastsErrorMessage(response.message, dispatch);
            }
        });
    }, [dispatch, selectedTable, closeFlyout]);

    useEffect(() => {
        return () => {
            dispatch({
                type: SET_ORDERS_COUNT,
                payload: {},
            });
            dispatch({
                type: SET_CURRENT_ORDER_STATE,
                payload: 'all',
            });
        };
    }, [dispatch]);

    useEffect(() => {
        if (!tableOrderDetails?.length && tableOrderingMode === 'qsr') {
            closeFlyout();
        }
    }, [closeFlyout, tableOrderDetails?.length, tableOrderingMode]);

    if (isEmpty(tableDetails)) {
        <EuiLoadingContent lines={3} />;
    }

    return (
        <>
            <EuiFlyout size='l' style={{ width: '90%' }} onClose={closeFlyout}>
                <EuiFlyoutHeader style={{ paddingTop: 20, paddingBottom: 20 }} hasBorder>
                    <OrderFlyoutHeader selectedTable={selectedTable} isPastTableSession={isPastTableSession} />
                </EuiFlyoutHeader>
                <EuiFlyoutBody>
                    <EuiFlexGroup alignItems='stretch' style={{ maxHeight: '50%' }} className='scrollbar-sm'>
                        <EuiFlexItem grow={6}>
                            <EuiFlexGroup direction='column' gutterSize='none'>
                                {tableOrderDetails ? (
                                    <EuiFlexItem grow={6}>
                                        <EuiPanel style={{ height: '64vh' }} paddingSize='s' className='scrollbar'>
                                            {!isPastTableSession ? (
                                                <FilterOrderStatusWise
                                                    isPastTableSession={true}
                                                    setSelectedOrder={setSelectedOrder}
                                                />
                                            ) : null}
                                            <OrdersTable />
                                        </EuiPanel>
                                    </EuiFlexItem>
                                ) : null}
                            </EuiFlexGroup>
                        </EuiFlexItem>

                        <EuiFlexItem grow={4}>
                            {!isEmpty(selectedOrder) ? (
                                <OrderDetialsTable order={selectedOrder} isPastTableSession={isPastTableSession} />
                            ) : (
                                <>{tableOrderDetails?.length ? <EuiCard title='Select an order' /> : null}</>
                            )}
                            {selectedTable?.current_session ? (
                                <>{!tableOrderDetails?.length && !isPastTableSession && <NoRunningOrders />}</>
                            ) : (
                                <>{!isPastTableSession && <NoRunningOrders />}</>
                            )}
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlyoutBody>

                {tableOrderingMode !== 'qsr' ? (
                    <EuiFlyoutFooter>
                        <EuiFlexGroup wrap responsive direction='row' justifyContent='spaceBetween' alignItems='center'>
                            <EuiFlexItem>
                                <ReactToPrint
                                    copyStyles={false}
                                    trigger={() => <EuiButton fill>Print Bill</EuiButton>}
                                    content={() => orderBillRef.current}
                                />
                            </EuiFlexItem>
                            {!isPastTableSession ? (
                                <EuiFlexItem>
                                    <EuiButton
                                        fill
                                        disabled={
                                            !userHasPermission(permissions, history.location.pathname, 'write') ||
                                            !tableOrderDetails?.every((order) => order.status.delivered_at)
                                            //  ||
                                            // currentOrderState !== 'all'
                                        }
                                        onClick={handleSessionSettlement}
                                    >
                                        Settle Table
                                    </EuiButton>
                                </EuiFlexItem>
                            ) : null}
                        </EuiFlexGroup>
                    </EuiFlyoutFooter>
                ) : null}
            </EuiFlyout>

            {tableOrderDetails?.length ? (
                <div style={{ display: 'none' }}>
                    <TableOrdersBillPrint
                        details={tableOrderDetails}
                        languageId={languageId}
                        ref={(el) => (orderBillRef.current = el)}
                        restaurantList={restaurantList}
                        selectedRestaurantId={selectedRestuarantId}
                    />
                </div>
            ) : null}
        </>
    );
};

export default React.memo(OrdersFlyout);
