import React, { useCallback } from 'react';
import {
    EuiCard,
    EuiComboBox,
    EuiFieldNumber,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow,
    EuiSelect,
    EuiSpacer,
    EuiSuperSelect,
    EuiTitle,
} from '@elastic/eui';
import FieldArrayNumberField from '../../Form/FieldArrayNumberField';
import { Controller } from 'react-hook-form';
import { gstOptions } from './VariantPricing';
import TextField from '../../Form/TextField';
import { useSelector } from 'react-redux';

const VariantOrderingModePricing = ({
    index,
    errors,
    control,
    watch,
    setValue,
    quantityIdOptions,
    orderingMode,
    orderingModeIdx,
    fieldVariant,
}) => {
    const allergensOptions = useSelector((state) => state.menuReducer.allergens)?.filter(
        (allergen) => (allergen.label = allergen.internal_name)
    );

    const priceInputName = `variantGroup[${index}].ordering_modes[${orderingModeIdx}].price`;
    const priceError = errors?.variantGroup?.[index]?.ordering_modes?.[orderingModeIdx]?.price;
    const comparePriceInputName = `variantGroup[${index}].ordering_modes[${orderingModeIdx}].compare_price`;
    const comparePriceError = errors?.variantGroup?.[index]?.ordering_modes?.[orderingModeIdx]?.compare_price;
    const packagingPriceInputName = `variantGroup[${index}].ordering_modes[${orderingModeIdx}].packaging_charge`;
    const packagingPriceError = errors?.variantGroup?.[index]?.ordering_modes?.[orderingModeIdx]?.packaging_charge;
    const gstInputName = `variantGroup[${index}].ordering_modes[${orderingModeIdx}].gst_slab`;
    const quantity = `variantGroup[${index}].ordering_modes[${orderingModeIdx}].quantity`;
    const quantity_unit_id = `variantGroup[${index}].ordering_modes[${orderingModeIdx}].quantity_unit_id`;
    const serves = `variantGroup[${index}].ordering_modes[${orderingModeIdx}].serves`;
    const serves_unit = `variantGroup[${index}].ordering_modes[${orderingModeIdx}].serves_unit`;

    const allergens = `variantGroup[${index}].ordering_modes[${orderingModeIdx}].allergens`;
    const calories = `variantGroup[${index}].ordering_modes[${orderingModeIdx}].calories`;

    const handleAllergensDeletion = (options) => {
        if (fieldVariant?.ordering_modes[orderingModeIdx]?.allergens?.length) {
            const removedAllergens = fieldVariant?.ordering_modes[orderingModeIdx]?.allergens.filter(
                (allergen) => !options.some((option) => option.id === allergen.id)
            );
            setValue(`variantGroup[${index}].ordering_modes[${orderingModeIdx}].delete_allergens`, removedAllergens);
        } else {
            setValue(`variantGroup[${index}].ordering_modes[${orderingModeIdx}].delete_allergens`, []);
        }
    };

    const onChangeGST = useCallback(
        (value, name) => {
            setValue(name, value);
        },
        [setValue]
    );

    return (
        <>
            <EuiCard
                hasBorder
                title={
                    <EuiTitle style={{ textAlign: 'left' }} size='xs'>
                        <h5>{orderingMode?.restaurant_ordering_mode_name}</h5>
                    </EuiTitle>
                }
                description={
                    <>
                        <EuiFlexGroup
                            direction='row'
                            wrap
                            gutterSize='s'
                            style={{
                                //   marginLeft: '10px',
                                marginTop: orderingModeIdx === 0 ? 0 : 20,
                            }}
                        >
                            <EuiFlexItem style={{ minWidth: 150 }}>
                                <FieldArrayNumberField
                                    error={priceError ? priceError?.message : ''}
                                    isInvalid={priceError}
                                    label={`Price ${orderingMode?.restaurant_ordering_mode_name}`}
                                    name={priceInputName}
                                    placeholder='price'
                                    rules={{
                                        required: 'Please enter price',
                                    }}
                                    control={control}
                                    watch={watch}
                                    defaultValue={orderingMode[priceInputName]}
                                />
                            </EuiFlexItem>

                            <EuiFlexItem style={{ minWidth: 150 }}>
                                <FieldArrayNumberField
                                    error={comparePriceError ? comparePriceError?.message : ''}
                                    isInvalid={comparePriceError ? comparePriceError : ''}
                                    label={`Compare Price ${orderingMode?.restaurant_ordering_mode_name}`}
                                    placeholder='Compare price'
                                    name={comparePriceInputName}
                                    rules={{
                                        required: 'Please enter compare price',
                                    }}
                                    control={control}
                                    watch={watch}
                                    defaultValue={orderingMode[comparePriceInputName]}
                                />
                            </EuiFlexItem>

                            <EuiFlexItem style={{ minWidth: 150 }}>
                                <FieldArrayNumberField
                                    error={packagingPriceError ? packagingPriceError?.message : ''}
                                    isInvalid={packagingPriceError ? packagingPriceError : ''}
                                    label={`Packaging ${orderingMode?.restaurant_ordering_mode_name}`}
                                    name={packagingPriceInputName}
                                    placeholder='Compare price'
                                    rules={{
                                        required: 'Please enter packaging price',
                                    }}
                                    control={control}
                                    watch={watch}
                                    defaultValue={orderingMode[packagingPriceInputName]}
                                />
                            </EuiFlexItem>

                            <EuiFlexItem style={{ minWidth: 150 }}>
                                <EuiFormRow
                                    label={`GST ${orderingMode?.restaurant_ordering_mode_name}`}
                                    style={{ maxWidth: '150px' }}
                                >
                                    <Controller
                                        render={() => (
                                            <EuiSelect
                                                options={gstOptions}
                                                value={watch(gstInputName)}
                                                onChange={(e) => {
                                                    onChangeGST(e.target.value, gstInputName);
                                                }}
                                            />
                                        )}
                                        defaultValue={Number(orderingMode[gstInputName])}
                                        name={gstInputName}
                                        control={control}
                                    />
                                </EuiFormRow>
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <TextField
                                    label={`Quantity ${orderingMode?.restaurant_ordering_mode_name}`}
                                    name={quantity}
                                    control={control}
                                    defaultValue={orderingMode[quantity]}
                                    errors={errors}
                                    placeholder='Item Quantity'
                                    style={{
                                        minWidth: 150,
                                    }}
                                />
                            </EuiFlexItem>
                            <EuiFlexItem style={{ minWidth: 150 }}>
                                <EuiFormRow
                                    label={`Quantity Unit Id ${orderingMode?.restaurant_ordering_mode_name}`}
                                    style={{
                                        minWidth: 150,
                                    }}
                                >
                                    <Controller
                                        render={({ field }) => {
                                            return (
                                                <EuiSuperSelect
                                                    options={quantityIdOptions}
                                                    valueOfSelected={field.value}
                                                    onChange={field.onChange}
                                                />
                                            );
                                        }}
                                        name={quantity_unit_id}
                                        min={0}
                                        step={'any'}
                                        control={control}
                                        defaultValue={orderingMode[quantity_unit_id]}
                                    />
                                </EuiFormRow>
                            </EuiFlexItem>
                            <EuiFlexItem style={{ minWidth: 150 }}>
                                <TextField
                                    label={`Serves ${orderingMode?.restaurant_ordering_mode_name}`}
                                    name={serves}
                                    control={control}
                                    defaultValue={orderingMode[serves]}
                                    errors={errors}
                                    placeholder='Item Serves'
                                    style={{
                                        minWidth: 150,
                                    }}
                                />
                            </EuiFlexItem>
                            <EuiFlexItem style={{ minWidth: 150 }}>
                                <TextField
                                    label={`Serves Unit ${orderingMode?.restaurant_ordering_mode_name}`}
                                    name={serves_unit}
                                    control={control}
                                    defaultValue={orderingMode[serves_unit]}
                                    errors={errors}
                                    placeholder='Item Serves Unit'
                                    style={{
                                        minWidth: 150,
                                    }}
                                />
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <EuiFormRow
                                    style={{
                                        minWidth: 150,
                                    }}
                                    label={`Calories`}
                                >
                                    <Controller
                                        render={({ field }) => (
                                            <EuiFieldNumber
                                                value={field.value}
                                                onChange={field.onChange}
                                                placeholder='Eg. 450 Kcal'
                                                append={'Kcal'}
                                            />
                                        )}
                                        name={calories}
                                        control={control}
                                    />
                                </EuiFormRow>
                            </EuiFlexItem>
                            <EuiFlexItem
                                style={{
                                    minWidth: 150,
                                }}
                            >
                                <Controller
                                    render={({ field }) => {
                                        return (
                                            <EuiFormRow label={`Allergens`}>
                                                <EuiComboBox
                                                    options={allergensOptions}
                                                    selectedOptions={field?.value}
                                                    onChange={(options) => {
                                                        handleAllergensDeletion(options);
                                                        field.onChange(options);
                                                    }}
                                                    placeholder='Select allergens'
                                                />
                                            </EuiFormRow>
                                        );
                                    }}
                                    name={allergens}
                                    control={control}
                                />
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </>
                }
            />
            <EuiSpacer />
        </>
    );
};

export default VariantOrderingModePricing;
