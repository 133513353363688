import {
    EuiPanel,
    EuiFlexGroup,
    EuiFlexItem,
    EuiText,
    EuiDragDropContext,
    EuiDroppable,
    EuiDraggable,
    euiDragDropReorder,
    EuiIcon,
} from '@elastic/eui/';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { userHasPermission } from '../../../helper/auth';
import { outletCategorySequenceChange } from '../../../api/menu/outletCategorySequenceChange';
import LoadingOverlay from 'react-loading-overlay';
import { useCallback } from 'react';

const OutletSubCategoryList = (props) => {
    const { item, selectedCategory, outletBannerList, setOutletBannerList, outletId, handleCategorySelection } = props;
    const permissions = useSelector((state) => state.permission.permission);
    const dispatch = useDispatch();
    const [loaderOverlay, setLoaderOverlay] = useState(false);

    const onDragEnd = useCallback(
        ({ source, destination }) => {
            if (source && destination) {
                setLoaderOverlay(true);
                const items = euiDragDropReorder(item.sub_categories, source.index, destination.index);
                let innerOutletCategoryList = [...outletBannerList];
                innerOutletCategoryList.map((category) => {
                    // category.category_id = category.sub_category_id;
                    category.id = category.category_id;
                    if (category.category_id == item.category_id) {
                        items.map((subCategory) => {
                            subCategory.id = subCategory.category_id;
                        });
                        category.sub_categories = items;
                    }
                });

                dispatch(outletCategorySequenceChange(outletId, innerOutletCategoryList)).then((res) => {
                    if (res.success) {
                        setOutletBannerList(innerOutletCategoryList);
                    } else {
                        alert('Error in sequence change');
                    }
                    setLoaderOverlay(false);
                });
            }
        },
        [dispatch, item?.category_id, item?.sub_categories, outletBannerList, outletId, setOutletBannerList]
    );

    return (
        <>
            <EuiDragDropContext onDragEnd={onDragEnd}>
                <EuiDroppable
                    className='outleLinkStyleForItem'
                    droppableId='CUSTOM_HANDLE_DROPPABLE_SUB_CATEGORY'
                    spacing='m'
                >
                    <LoadingOverlay active={loaderOverlay} spinner text='Re-arranging sequence'>
                        {item.sub_categories.map((item, idx) => (
                            <EuiDraggable
                                spacing='m'
                                key={item?.category_id?.toString()}
                                index={idx}
                                draggableId={item?.category_id?.toString()}
                                customDragHandle={true}
                            >
                                {(provided) => (
                                    <EuiPanel
                                        className='custom'
                                        style={{
                                            cursor: 'pointer',
                                            backgroundColor: !isEmpty(selectedCategory)
                                                ? item?.category_id == selectedCategory?.category_id
                                                    ? '#c4d1fe'
                                                    : 'transparent'
                                                : 'transparent',
                                        }}
                                        paddingSize='m'
                                    >
                                        <EuiFlexGroup onClick={() => handleCategorySelection(item)}>
                                            <EuiFlexItem
                                                grow={false}
                                                style={{
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                {userHasPermission(permissions, '/menu', 'write') && (
                                                    <div {...provided.dragHandleProps}>
                                                        <EuiIcon type='grab' />
                                                    </div>
                                                )}
                                            </EuiFlexItem>

                                            <EuiFlexItem
                                                style={{
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <EuiText> {item.internal_name} </EuiText>
                                            </EuiFlexItem>
                                        </EuiFlexGroup>
                                    </EuiPanel>
                                )}
                            </EuiDraggable>
                        ))}
                    </LoadingOverlay>
                </EuiDroppable>
            </EuiDragDropContext>
        </>
    );
};

export default React.memo(OutletSubCategoryList);
