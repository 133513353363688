export const languages = [
    {
        language: 'en',
        label: 'English',
        language_label: 'English',
    },
    {
        language: 'mr',
        label: 'मराठी',
        language_label: 'मराठी',
    },
    {
        language: 'gu',
        label: 'ગુજરાતી',
        language_label: 'ગુજરાતી',
    },
    { language: 'as', label: 'অসমীয়া', language_label: 'অসমীয়া' },
    {
        language: 'bn',
        label: 'বাংলা',
        language_label: 'বাংলা',
    },
    {
        language: 'kn',
        label: 'ಕನ್ನಡ',
        language_label: 'ಕನ್ನಡ',
    },
    {
        language: 'ks',
        label: 'كشميري',
        language_label: 'كشميري',
    },
    {
        language: 'kok',
        label: 'Konkani',
        language_label: 'Konkani',
    },
    {
        language: 'ml',
        label: 'മലയാളം',
        language_label: 'മലയാളം',
    },
    {
        language: 'bpy',
        label: 'ইমার ঠার/বিষ্ণুপ্রিয়া মণিপুরী',
        language_label: 'ইমার ঠার/বিষ্ণুপ্রিয়া মণিপুরী',
    },
    {
        language: 'ne',
        label: 'नेपाली',
        language_label: 'नेपाली',
    },
    {
        language: 'or',
        label: 'ଓଡ଼ିଆ',
        language_label: 'ଓଡ଼ିଆ',
    },
    {
        language: 'pa',
        label: 'पंजाबी',
        language_label: 'पंजाबी',
    },
    {
        language: 'sa',
        label: 'संस्कृतम्',
        language_label: 'संस्कृतम्',
    },
    {
        language: 'sd',
        label: 'सिनधि',
        language_label: 'सिनधि',
    },
    {
        language: 'ta',
        label: 'தமிழ்',
        language_label: 'தமிழ்',
    },
    {
        language: 'te',
        label: 'తెలుగు',
        language_label: 'తెలుగు',
    },
    {
        language: 'ur',
        label: 'اردو',
        language_label: 'اردو',
    },
    {
        language: 'brx',
        label: 'Boro',
        language_label: 'Boro',
    },
    {
        language: 'sat',
        label: 'Santhali',
        language_label: 'Santhali',
    },
    {
        language: 'as',
        label: 'mai',
        language_label: 'mai',
    },
    {
        language: 'doi',
        label: 'Dogri',
        language_label: 'Dogri',
    },
];
