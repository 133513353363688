import React from 'react';
import { MainLayout } from '../../components/layout/mainLayout/mainLayout';
import { LiveOrdersComponent } from '../../components/liveOrders/liveOrders';

const LiveOrdersPage = () => {
    return (
        <MainLayout
            title='Live Orders'
            quickNavigations={[
                {
                    href: '/table-management',
                    label: 'Table Managament',
                },
                {
                    href: '/bill-payment',
                    label: 'Bill Payments',
                },
            ]}
            isSingleScreen={true}
            backgroundColorChange={true}
        >
            <LiveOrdersComponent />
        </MainLayout>
    );
};

export default LiveOrdersPage;
