import React, { memo } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiPanel, EuiText, EuiSpacer, EuiStat, EuiHorizontalRule } from '@elastic/eui';
import { numberFormat } from '../../../utils/numberFormat';

const SuccessOrders = (props) => {
    const externalRiderOrdersCount = () => {
        let total = props.outlet.elt_order_count + props.outlet.dunzo_order_count + props.outlet.wefast_order_count + props.outlet.porter_order_count;

        total = numberFormat(total);

        return total;
    };

    return (
        <>
            <EuiSpacer />
            <EuiFlexGroup direction={'column'}>
                <EuiFlexItem>
                    <EuiText>
                        <h4>Success orders count</h4>
                    </EuiText>
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiHorizontalRule margin={'s'} />
            <EuiFlexGroup>
                <EuiFlexItem>
                    <EuiPanel>
                        <EuiStat
                            title={numberFormat(props.outlet.takeaway_order_count, 0, 'en-IN')}
                            description='Takeaway orders'
                            isLoading={props.isLoading}
                        />
                    </EuiPanel>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiPanel>
                        <EuiStat
                            title={numberFormat(props.outlet.delivery_order_count, 0, 'en-IN')}
                            description='Delivered orders'
                            isLoading={props.isLoading}
                        />
                    </EuiPanel>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiPanel>
                        <EuiStat
                            title={
                                props.outlet.own_rider_order_count
                                    ? numberFormat(props.outlet.delivery_order_count, 0, 'en-IN')
                                    : 0
                            }
                            description='In house delivery'
                            isLoading={props.isLoading}
                        />
                    </EuiPanel>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiPanel>
                        <EuiStat
                            title={externalRiderOrdersCount()}
                            description='External delivery'
                            isLoading={props.isLoading}
                        />
                    </EuiPanel>
                </EuiFlexItem>
            </EuiFlexGroup>

            <EuiFlexGroup>
                <EuiFlexItem>
                    <EuiPanel>
                        <EuiStat
                            title={
                                props.outlet.elt_order_count
                                    ? numberFormat(props.outlet.elt_order_count, 0, 'en-IN')
                                    : 0
                            }
                            description='ELT orders'
                            isLoading={props.isLoading}
                        />
                    </EuiPanel>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiPanel>
                        <EuiStat
                            title={
                                props.outlet.dunzo_order_count
                                    ? numberFormat(props.outlet.dunzo_order_count, 0, 'en-IN')
                                    : 0
                            }
                            description='Dunzo orders'
                            isLoading={props.isLoading}
                        />
                    </EuiPanel>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiPanel>
                        <EuiStat
                            title={
                                props.outlet.wefast_order_count
                                    ? numberFormat(props.outlet.wefast_order_count, 0, 'en-IN')
                                    : 0
                            }
                            description='WeFast orders'
                            isLoading={props.isLoading}
                        />
                    </EuiPanel>
                </EuiFlexItem>

                <EuiFlexItem>
                    <EuiPanel>
                        <EuiStat
                            title={
                                props.outlet.porter_order_count
                                    ? numberFormat(props.outlet.porter_order_count, 0, 'en-IN')
                                    : 0
                            }
                            description='Porter orders'
                            isLoading={props.isLoading}
                        />
                    </EuiPanel>
                </EuiFlexItem>

                <EuiFlexItem>
                    <EuiPanel>
                        <EuiStat
                            title={
                                props.outlet.ahoy_order_count
                                    ? numberFormat(props.outlet.ahoy_order_count, 0, 'en-IN')
                                    : 0
                            }
                            description='Ahoy orders'
                            isLoading={props.isLoading}
                        />
                    </EuiPanel>
                </EuiFlexItem>
            </EuiFlexGroup>
        </>
    );
};

export default memo(SuccessOrders);
