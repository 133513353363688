export const GET_SYSTEM_CATEGORIES = 'get_system_categories';
export const GET_PET_POOJA_ITEMS = 'get_pet_pooja_items';
export const GET_PET_POOJA_LINKED_ITEMS = 'get_pet_pooja_linked_items';
export const GET_PET_POOJA_TAXES = 'get_pet_pooja_taxes';
export const GET_PET_POOJA_RESTAURANT = 'get_pet_pooja_restaurant';
export const SET_PET_POOJA_LINKED_ADDONS = 'set_pet_pooja_linked_addons';
export const SET_SYSTEM_ADDON_GROUPS = 'set_system_addon_groups';
export const SET_PET_POOJA_ADDON_GROUPS = 'set_pet_pooja_addon_groups';
export const SET_PET_POOJA_VARIANTS = 'set_pet_pooja_variants';
export const GET_RESTAURANT_ITEMS = 'get_restaurant_items';
