import API from './axios/API';

export const getFabDeliveryRiders = (outletId) => {
    return async () => {
        let response;

        try {
            response = await API.get(`restaurants/:restaurantId/outlets/${outletId}/riders`);
        } catch (error) {
            response = error;
        }

        return response;
    };
};
