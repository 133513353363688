import { EuiText } from '@elastic/eui';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import FormRadioGroup from '../../../../../components/Form/FormRadioGroup';
import ItemDetailsCollaspibleNav from './ItemDetailsCollaspibleNav';
import { ReactComponent as BestSellingCartIcon } from '../../../../../assets/img/svg/best-selling-category.svg';

const ItemBestSellCategory = () => {
    const isOutletMenu = useSelector((state) => state.menuReducer.isOutletMenu);
    const [isNavOpen, setIsNavOpen] = useState(false);
    const themeMode = useSelector((state) => state.theme.theme);

    return (
        <ItemDetailsCollaspibleNav
            cardTitle='Add this item to Best seller category'
            isNavOpen={isNavOpen}
            cardDescription={<EuiText size='s'> This item will be shown in the Best Seller Category.</EuiText>}
            setIsNavOpen={setIsNavOpen}
            icon={<BestSellingCartIcon fill={themeMode === 'light' ? 'black' : 'white'} />}
        >
            <FormRadioGroup
                name='is_best_selling'
                disabled={isOutletMenu}
                options={[
                    {
                        id: 'is_best_selling_yes',
                        label: 'Yes',
                        value: 'is_best_selling_yes',
                    },
                    {
                        id: 'is_best_selling_no',
                        label: 'No',
                        value: 'is_best_selling_no',
                    },
                ]}
            />
        </ItemDetailsCollaspibleNav>
    );
};

export default ItemBestSellCategory;
