import { EuiEmptyPrompt, EuiFlexGroup, EuiFlexItem, EuiSpacer } from '@elastic/eui';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCustomerPayments } from '../../../api/BillPayments/fetchCustomerPayments';
import BillPaymentTable from '../../../components/BillPayments/BillPaymentTable';
import CustomDatePicker from '../../../components/UIComponent/CustomDatePicker';
import { NoOutletMessage } from '../../../utils/noOutletMessage';

const BillPaymentScreen = () => {
    const dispatch = useDispatch();
    const [records, setRecords] = useState({});
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment());
    const [isLoading, setIsLoading] = useState(false);
    const selectedOutlet = useSelector((state) => state.outlet.selectedOutlet);

    const selectedOutletId = useSelector((state) => state.outlet.selectedOutletId);

    const fetchPayments = useCallback(
        async (startDate, endDate, currentPage) => {
            setIsLoading(true);
            await dispatch(fetchCustomerPayments(startDate, endDate, currentPage, selectedOutletId))
                .then((response) => {
                    setIsLoading(false);
                    if (response.success) {
                        setRecords(response.customer_bill_payments);
                    }
                })
                .catch((err) => setIsLoading(false));
        },
        [dispatch, selectedOutletId]
    );

    useEffect(() => {
        if (selectedOutletId && selectedOutlet?.outlet_data?.settings?.is_bill_payment_enabled) {
            fetchPayments(startDate, endDate, 1);
        }
    }, [
        endDate,
        fetchPayments,
        startDate,
        selectedOutletId,
        selectedOutlet?.outlet_data?.settings?.is_bill_payment_enabled,
    ]);

    if (!selectedOutletId) {
        return <NoOutletMessage />;
    }

    if (!selectedOutlet?.outlet_data?.settings?.is_bill_payment_enabled) {
        return (
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: `translate(-50%, -50%)` }}>
                <EuiEmptyPrompt
                    iconType='editorStrike'
                    title={<h2>Bill payment is disabled</h2>}
                    body={<p>Please enable this from store settings - Bill Payments</p>}
                />
            </div>
        );
    }

    return (
        <>
            <EuiFlexGroup justifyContent='spaceBetween' alignItems='center'>
                <EuiFlexItem grow={false}>
                    <CustomDatePicker
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        isLoading={isLoading}
                    />
                </EuiFlexItem>
            </EuiFlexGroup>

            <EuiSpacer />
            <BillPaymentTable records={records} fetchPayments={fetchPayments} startDate={startDate} endDate={endDate} />
        </>
    );
};

export default BillPaymentScreen;
