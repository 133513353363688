import API from '../axios/API';

export const unLinkCategoryFromOutlet = (outletId, categoryId) => {
    return async (dispatch, getState) => {
        let response;

        try {
            response = await API.patch(
                `restaurants/:restaurantId/categories/${categoryId}/outlets/${outletId}/un-link`
            );
        } catch (error) {
            response = error;
        }

        return response;
    };
};
