import {
    EuiBadge,
    EuiBasicTable,
    EuiButtonIcon,
    EuiFlexGroup,
    EuiFlexItem,
    EuiLoadingContent,
    EuiText,
} from '@elastic/eui';
import { capitalize } from 'lodash';
import moment from 'moment';
import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import API from '../../../api/axios/API';
import { getRestaurantBanners } from '../../../api/banner/getRestaurantBanners';
import { userHasPermission } from '../../../helper/auth';
import { getBannerAvailableTimeSlots } from '../../../components/banner/BannerCatalogue/getBannerAvailableTimeSlots';
import ShowImages from '../Components/StoreBanners/ShowImages';
import LinkBanner from '../Components/StoreBanners/LinkBanner';
import { toastsErrorMessage, toastSuccessMessage } from '../../../utils/toasts';

const BannerCatalogue = ({ areOutletBanners }) => {
    const [items, setItems] = useState([]);
    const [columns, setColumns] = useState([]);

    const currentTab = useSelector((state) => state.banner.bannerType);
    const permissions = useSelector((state) => state.permission.permission);
    const bannerLoader = useSelector((state) => state.banner.bannerLoader);
    const banners = useSelector((state) => state.banner.banners);
    const history = useHistory();
    const dispatch = useDispatch();
    const [filteredBanners, setFilteredBanners] = useState('');
    const restaurantOrderingModes = useSelector((state) => state.menuReducer.restaurantOrderingModes);
    const restaurantActiveOrderingModes = restaurantOrderingModes?.filter((orderingMode) => orderingMode.status);
    const outletOrderingModes = useSelector((state) => state.outlet?.outletOrderingModes?.ordering_modes);
    const outletActiveOrderingModes = outletOrderingModes?.filter((orderingMode) => orderingMode.is_enabled);
    const languageId = useSelector((state) => state.language.languageId);

    useEffect(() => {
        if (currentTab) {
            switch (currentTab) {
                case 'secondary':
                    setFilteredBanners('is_secondary');
                    break;
                case 'tertiary':
                    setFilteredBanners('is_tertiary');
                    break;
                case 'primary':
                    setFilteredBanners('');
                    break;
                default:
                    break;
            }
        }
    }, [currentTab]);

    const columnsData = useMemo(() => {
        return [
            {
                field: 'title',
                name: 'Title',
                sortable: true,
                render: (title) => {
                    return <EuiText size='s'>{title}</EuiText>;
                },
            },
            {
                field: 'valid_from',
                name: 'Valid From',
                sortable: true,
                align: 'center',
                render: (valid_from) => {
                    return <EuiText size='s'>{valid_from}</EuiText>;
                },
            },
            {
                field: 'valid_till',
                name: 'Valid Till',
                sortable: true,
                align: 'center',
                render: (valid_till) => {
                    return <EuiText size='s'>{valid_till}</EuiText>;
                },
            },
            {
                field: 'target_screen',
                name: 'Target Screen',
                sortable: true,
                align: 'center',
                render: (target_screen) => {
                    return <EuiText size='s'>{target_screen}</EuiText>;
                },
            },

            {
                field: 'available_modes',
                name: 'Available Modes',
                // width: '20%',
                align: 'center',
                render: (available_modes) => {
                    const restaurantAvailableModes = restaurantOrderingModes.filter((orderingMode) =>
                        available_modes.some(
                            (bannerMode) =>
                                bannerMode.restaurant_ordering_mode_id === orderingMode.id && orderingMode.status
                        )
                    );

                    return (
                        <>
                            <EuiFlexGroup
                                alignItems='center'
                                justifyContent='center'
                                direction='row'
                                responsive
                                gutterSize='s'
                                wrap
                            >
                                {restaurantAvailableModes.map(({ display_name }, index) => {
                                    return (
                                        <EuiFlexItem key={index}>
                                            <EuiBadge
                                                style={{
                                                    wordWrap: 'break-all',
                                                    textTransform: 'capitalize',
                                                }}
                                            >
                                                <EuiText size='xs' style={{ textAlign: 'center' }}>
                                                    {display_name}
                                                </EuiText>
                                            </EuiBadge>
                                        </EuiFlexItem>
                                    );
                                })}
                            </EuiFlexGroup>
                        </>
                    );
                },
            },
            {
                field: 'available_time_slots',
                name: 'Available Time Slots',
                sortable: true,
                align: 'center',
                width: '15%',
                render: (available_time_slots) => {
                    return <EuiText size='s'>{available_time_slots}</EuiText>;
                },
            },
            {
                field: 'banner_images',
                name: 'Banner Images',
                sortable: true,
                align: 'center',
                render: (banner) => {
                    return <ShowImages banner={banner} />;
                },
            },
            {
                field: 'link_to_outlet',
                name: 'Link Banner',
                sortable: true,
                align: 'center',
                render: (banner) => {
                    return <LinkBanner banner={banner} />;
                },
            },
            {
                field: 'actions',
                name: 'Actions',
                sortable: true,
                align: 'center',
                render: (banner) => {
                    return (
                        <>
                            {' '}
                            <EuiFlexItem grow={false}>
                                {userHasPermission(permissions, '/banners', 'write') && (
                                    <EuiButtonIcon
                                        onClick={() => handleEditBanner(banner.id)}
                                        iconType='pencil'
                                        aria-label='edit'
                                    />
                                )}
                            </EuiFlexItem>
                            <EuiFlexItem grow={false}>
                                {userHasPermission(permissions, '/banners', 'delete') && (
                                    <EuiButtonIcon
                                        iconType='trash'
                                        aria-label='delete'
                                        color='danger'
                                        onClick={() => handleDeleteBanner(banner.id)}
                                    />
                                )}
                            </EuiFlexItem>
                        </>
                    );
                },
            },
        ];
    }, [
        areOutletBanners,
        languageId,
        outletActiveOrderingModes?.length,
        outletOrderingModes,
        permissions,
        restaurantActiveOrderingModes?.length,
        restaurantOrderingModes,
    ]);

    useEffect(() => {
        setColumns(columnsData);
    }, [columnsData]);

    useEffect(() => {
        if (filteredBanners) {
            let recordItems = [];
            const withFilter = banners?.filter((banner) => banner[filteredBanners]);
            for (const banner of withFilter) {
                recordItems.push({
                    title: banner?.title,
                    valid_from: banner?.from_date ? moment(banner.from_date).format('DD-MM-YYYY HH:mm') : '',
                    valid_till: banner?.to_date ? moment(banner.to_date).format('DD-MM-YYYY HH:mm') : '',
                    target_screen: banner?.screen ? capitalize(banner.screen) : '--',
                    available_modes: banner?.ordering_modes,
                    available_time_slots:
                        banner?.time_slot_setting !== 'no_time_slots' && getBannerAvailableTimeSlots(banner)
                            ? getBannerAvailableTimeSlots(banner)
                            : '--',
                    link_to_outlet: banner,
                    actions: banner,
                    banner_images: banner,
                });
            }
            setItems(recordItems);
        } else if (banners?.filter((banner) => !banner.is_secondary && !banner.is_tertiary)?.length) {
            const resetBanners = banners.filter((banner) => !banner.is_secondary && !banner.is_tertiary);
            let recordItems = [];
            for (const banner of resetBanners) {
                recordItems.push({
                    title: banner?.title,
                    valid_from: banner?.from_date ? moment(banner.from_date).format('DD-MM-YYYY HH:mm') : '',
                    valid_till: banner?.to_date ? moment(banner.to_date).format('DD-MM-YYYY HH:mm') : '',
                    target_screen: banner?.screen ? capitalize(banner.screen) : '--',
                    available_modes: banner?.ordering_modes,
                    available_time_slots:
                        banner?.time_slot_setting !== 'no_time_slots' && getBannerAvailableTimeSlots(banner)
                            ? getBannerAvailableTimeSlots(banner)
                            : '--',
                    link_to_outlet: banner,
                    actions: banner,
                    banner_images: banner,
                });
            }
            setItems(recordItems);
        } else {
            setItems([]);
        }
    }, [filteredBanners, banners]);

    const getRowProps = useCallback((item) => {
        const { id } = item;
        return {
            'data-test-subj': `row-${id}`,
            className: 'customRowClass',
        };
    }, []);

    const handleEditBanner = useCallback(
        (id) => {
            history.push(`/banners/edit/${id}`);
        },
        [history]
    );

    const handleDeletion = useCallback(
        async (id) => {
            try {
                const deleteBanner = await API.delete(`restaurants/:restaurantId/banners/${id}`);
                if (deleteBanner.success) {
                    toastSuccessMessage('Banner deleted successfully...', dispatch);
                }

                dispatch(getRestaurantBanners());
            } catch (e) {
                toastsErrorMessage('Banner is not deleted, please try later', dispatch);
            }
        },
        [dispatch]
    );

    const handleDeleteBanner = useCallback(
        async (id) => {
            if (window.confirm('Are you sure you want to delete this record...?')) {
                await handleDeletion(id);
            }
        },
        [handleDeletion]
    );

    if (bannerLoader.loader) return <EuiLoadingContent lines={10} />;
    if (bannerLoader.error) return <span>An Error Has Occurred</span>;

    return (
        <>
            <EuiFlexGroup direction='column' justifyContent='flexEnd' alignItems='flexEnd'>
                <EuiFlexItem grow={10}>
                    <EuiBasicTable
                        items={items}
                        rowHeader='name'
                        columns={columns}
                        rowProps={getRowProps}
                        noItemsMessage='Records Not Found'
                    />
                </EuiFlexItem>
            </EuiFlexGroup>
        </>
    );
};

export default React.memo(BannerCatalogue);
