import React from 'react';
import { EuiFormRow, EuiComboBox } from '@elastic/eui';
import { Controller } from 'react-hook-form';

const ComboBoxField = (props) => {
    const {
        label,
        name,
        placeholder,
        rules,
        errors,
        control,
        options,
        selectedOptions,
        fullWidth,
        defaultValue,
        map,
        isDisabled,
        invalidMessage,
        singleSelection,
    } = props;

    return (
        <Controller
            render={({ field }) => {
                return (
                    <EuiFormRow
                        label={label}
                        isInvalid={invalidMessage ? invalidMessage : errors[name] ? true : false}
                        error={invalidMessage ? invalidMessage : errors[name] ? errors[name].message : ''}
                        fullWidth={fullWidth ? fullWidth : false}
                    >
                        <EuiComboBox
                            {...props}
                            options={options}
                            // selectedOptions={field?.value ? field.value : []}
                            selectedOptions={
                                field?.value?.length ? field.value : selectedOptions?.length ? selectedOptions : []
                            }
                            // selectedOptions
                            onChange={props.onChange ?? field.onChange}
                            // props.onChange
                            placeholder={placeholder}
                            isDisabled={isDisabled}
                            fullWidth
                            singleSelection={singleSelection}
                        />
                    </EuiFormRow>
                );
            }}
            name={name}
            control={control}
            isInvalid={errors[name]}
            rules={rules}
            defaultValue={defaultValue ? defaultValue : []}
        />
    );
};

export default ComboBoxField;
