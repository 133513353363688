import API from '../../../api/axios/API';

export const updateOutletOrderingModes = async (outletId, payload, section) => {
    let response;

    try {
        response = await API.put(
            `${process.env.REACT_APP_BASE_URL_V4}/restaurants/:restaurantId/outlets/${outletId}?section=${section}`,
            payload
        );
    } catch (error) {
        response = {
            isError: true,
        };
    }

    return response;
};

export const multipleOutletModeTimings = (orderMode, data) => {
    let time_slots;
    if (
        data[`timer_for_item_${orderMode.restaurant_ordering_mode_id}`] ===
        `same_day_of_the_week_${orderMode.restaurant_ordering_mode_id}`
    ) {
        time_slots = null;
        orderMode.time_slot_setting = 'no_time_slots';
    } else if (
        data[`timer_for_item_${orderMode.restaurant_ordering_mode_id}`] ===
        `same_time_for_all_days_${orderMode.restaurant_ordering_mode_id}`
    ) {
        orderMode.time_slot_setting = 'same_time_slot_for_all_days';
        let time = data[`time_${orderMode.restaurant_ordering_mode_id}`]?.filter((item, index) => {
            if (item.start_time && item.end_time) {
                return item;
            }
        });
        if (time) {
            time_slots = [
                {
                    days_of_week: [1, 2, 3, 4, 5, 6, 7],
                    time_slots: time,
                },
            ];
        } else {
            time_slots = [];
        }
    } else {
        time_slots = [];
        orderMode.time_slot_setting = 'different_time_slots_for_different_days';
        let sunday = data.week[`${'sunday'}_${orderMode.restaurant_ordering_mode_id}`]?.filter((item, index) => {
            if (item.start_time && item.end_time) {
                return item;
            }
        });

        sunday?.length &&
            time_slots.push({
                days_of_week: [7],
                time_slots: sunday,
            });
        let monday = data?.week[`${'monday'}_${orderMode.restaurant_ordering_mode_id}`]?.filter((item, index) => {
            if (item.start_time && item.end_time) {
                return item;
            }
        });

        monday?.length &&
            time_slots.push({
                days_of_week: [1],
                time_slots: monday,
            });
        let tuesday = data?.week[`${'tuesday'}_${orderMode.restaurant_ordering_mode_id}`]?.filter((item, index) => {
            if (item.start_time && item.end_time) {
                return item;
            }
        });
        tuesday?.length &&
            time_slots.push({
                days_of_week: [2],
                time_slots: tuesday,
            });
        let wednesday = data?.week[`${'wednesday'}_${orderMode.restaurant_ordering_mode_id}`]?.filter((item, index) => {
            if (item.start_time && item.end_time) {
                return item;
            }
        });

        wednesday?.length &&
            time_slots.push({
                days_of_week: [3],
                time_slots: wednesday,
            });
        let thursday = data?.week[`${'thursday'}_${orderMode.restaurant_ordering_mode_id}`]?.filter((item, index) => {
            if (item.start_time && item.end_time) {
                return item;
            }
        });

        thursday?.length &&
            time_slots.push({
                days_of_week: [4],
                time_slots: thursday,
            });
        let friday = data?.week[`${'friday'}_${orderMode.restaurant_ordering_mode_id}`]?.filter((item, index) => {
            if (item.start_time && item.end_time) {
                return item;
            }
        });
        friday?.length &&
            time_slots.push({
                days_of_week: [5],
                time_slots: friday,
            });
        let saturday = data?.week[`${'saturday'}_${orderMode.restaurant_ordering_mode_id}`]?.filter((item) => {
            if (item.start_time && item.end_time) {
                return item;
            }
        });
        saturday?.length > 0 &&
            time_slots.push({
                days_of_week: [6],
                time_slots: saturday,
            });
    }
    // data.time_slots = time_slots;
    orderMode.schedules = time_slots;
    orderMode.timeslots = time_slots;
};
