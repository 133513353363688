import { EuiLoadingContent, EuiPanel, EuiStat } from '@elastic/eui';
import React from 'react';

export default ({ activeUsers, isLoading }) => {
    return (
        <>
            <EuiPanel>
                {isLoading ? (
                    <EuiLoadingContent lines={2} />
                ) : (
                    <EuiStat title={activeUsers} description='Downloads' textAlign={'center'} />
                )}
            </EuiPanel>
        </>
    );
};
